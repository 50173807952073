import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import { useMediaQuery } from 'react-responsive';

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

import { omit } from "lodash";

// core components
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TableContainer from '@mui/material/TableContainer';
import CustomInput from "components/CustomInput/CustomInput";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import {
  GET_MARKETING_GOAL,
  UPDATE_MARKETING_GOAL
} from "queries/marketingGoals";
import { UPDATE_CUSTOMER_DATE, 
         LIST_CUSTOMERS, 
         UPDATE_CUSTOMER,
         GET_TOP_COMPETITORS,
         GET_TOP_PRODUCTS,
         GET_NO_FOCUS_PRODUCTS,
         GET_TARGET_AREAS,
         UPDATE_CUSTOMER_PRODUCTS,
         UPDATE_CUSTOMER_COMPETITORS,
         UPDATE_CUSTOMER_NO_FOCUS_PRODUCTS,
         UPDATE_CUSTOMER_TARGET_AREAS,
         REMOVE_CUSTOMER_COMPETITOR,
         REMOVE_CUSTOMER_PRODUCT,
         REMOVE_CUSTOMER_NO_FOCUS_PRODUCT,
         REMOVE_TARGET_AREA} from "queries/customers";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";
import { READ_ME } from "queries/users";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/modifyMarketingGoalsStyle";

const useStyles = makeStyles(styles);

const UpdateMarketingGoal = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [marketingGoal, setMarketingGoal] = useState({});
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [accountInfo, setAccountInfo] = useState([]);
  const [competitorData, setcompetitorData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [targetAreaData, settargetAreaData] = useState([]);
  const [noFocusProductData, setnoFocusProductData] = useState([]);
  const [highestCompetitorID, sethighestCompetitorID] = useState(1);
  const [highestProductID, sethighestProductID] = useState(1);
  const [highestNoFocusProductID, sethighestNoFocusProductID] = useState(1);
  const [highestTopTargetAreasID, sethighestTopTargetAreasID] = useState(1);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const config = isMobile ? { width: "100%", margin: "0 auto" } : { width: "70%", margin: "0 auto" };
  const float = isMobile ? { float: "left", display: "inline-block" } : { float: "left", display: "inline-block", marginRight: "50px" };

  const { data: me } = useQuery(READ_ME);

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  let { data: all_data, refetch } = useQuery(GET_TOP_COMPETITORS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: product_data, refetch: product_refetch } = useQuery(GET_TOP_PRODUCTS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: no_focus_product_data, refetch: no_focus_product_refetch } = useQuery(GET_NO_FOCUS_PRODUCTS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: target_areas_data, refetch: target_areas_data_refetch } = useQuery(GET_TARGET_AREAS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  customer_data = customer_data?.listCustomers[0];

  useEffect(() => {
    if (customer_data)
      setAccountInfo({
        "sunday_open_hours": customer_data?.sunday_open?.split(":")[0],
        "sunday_open_mins": customer_data?.sunday_open !== null ? customer_data?.sunday_open?.split(":")[1] : "00",
        "sunday_close_hours": customer_data?.sunday_close?.split(":")[0],
        "sunday_close_mins": customer_data?.sunday_close !== null ? customer_data?.sunday_close?.split(":")[1] : "00",
        "monday_open_hours": customer_data?.monday_open?.split(":")[0],
        "monday_open_mins": customer_data?.monday_open !== null ? customer_data?.monday_open?.split(":")[1] : "00",
        "monday_close_hours": customer_data?.monday_close?.split(":")[0],
        "monday_close_mins": customer_data?.monday_close !== null ? customer_data?.monday_close?.split(":")[1] : "00",
        "tuesday_open_hours": customer_data?.tuesday_open?.split(":")[0],
        "tuesday_open_mins": customer_data?.tuesday_open !== null ? customer_data?.tuesday_open?.split(":")[1] : "00",
        "tuesday_close_hours": customer_data?.tuesday_close?.split(":")[0],
        "tuesday_close_mins": customer_data?.tuesday_close !== null ? customer_data?.tuesday_close?.split(":")[1] : "00",
        "wednesday_open_hours": customer_data?.wednesday_open?.split(":")[0],
        "wednesday_open_mins": customer_data?.wednesday_open !== null ? customer_data?.wednesday_open?.split(":")[1] : "00",
        "wednesday_close_hours": customer_data?.wednesday_close?.split(":")[0],
        "wednesday_close_mins": customer_data?.wednesday_close !== null ? customer_data?.wednesday_close?.split(":")[1] : "00",
        "thursday_open_hours": customer_data?.thursday_open?.split(":")[0],
        "thursday_open_mins": customer_data?.thursday_open !== null ? customer_data?.thursday_open?.split(":")[1] : "00",
        "thursday_close_hours": customer_data?.thursday_close?.split(":")[0],
        "thursday_close_mins": customer_data?.thursday_close !== null ? customer_data?.thursday_close?.split(":")[1] : "00",
        "friday_open_hours": customer_data?.friday_open?.split(":")[0],
        "friday_open_mins": customer_data?.friday_open !== null ? customer_data?.friday_open?.split(":")[1] : "00",
        "friday_close_hours": customer_data?.friday_close?.split(":")[0],
        "friday_close_mins": customer_data?.friday_close !== null ? customer_data?.friday_close?.split(":")[1] : "00",
        "saturday_open_hours": customer_data?.saturday_open?.split(":")[0],
        "saturday_open_mins": customer_data?.saturday_open !== null ? customer_data?.saturday_open?.split(":")[1] : "00",
        "saturday_close_hours": customer_data?.saturday_close?.split(":")[0],
        "saturday_close_mins": customer_data?.saturday_close ? customer_data?.saturday_close?.split(":")[1] : "00"
      });

    if (competitorData?.length)
      sethighestCompetitorID(competitorData[competitorData.length - 1].id + 1)

    if (productData?.length)
      sethighestProductID(productData[productData.length - 1].id + 1)

    if (noFocusProductData?.length)
      sethighestNoFocusProductID(noFocusProductData[noFocusProductData.length - 1].id + 1)

    if (targetAreaData?.length)
      sethighestTopTargetAreasID(targetAreaData[targetAreaData.length - 1].id + 1)
  }, [customer_data, competitorData, productData, noFocusProductData, targetAreaData])

  let { loading, data } = useQuery(GET_MARKETING_GOAL, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
    fetchPolicy: "no-cache"
  });
  const [updateMarketingGoal, { loading: updatingMarketingGoal }] = useMutation(
    UPDATE_MARKETING_GOAL,
    {
      variables: {
        ...marketingGoal,
        customer_id: state.customers?.selected?.id
      },
      onCompleted: () => {
        formSubmitted();
      }
    }
  );
  const [updateCustomer, { loading: updatingCustomer }] = useMutation(
    UPDATE_CUSTOMER_DATE,
    {
      variables: {
        id: state.customers?.selected?.id,
        original_start_date: originalStartDate
      }
    }
  );

  const [updateCustomerHours, { loading: updatingCustomerHours }] = useMutation(
    UPDATE_CUSTOMER,
    {
      variables: {
        id: state.customers?.selected?.id,
        data: {
          ...accountInfo,
          id: undefined,
          __typename: undefined
        }
      }
    }
  );

  const [updateCustomerCompetitors, { loading: updatingCustomerCompetitors }] = useMutation(
    UPDATE_CUSTOMER_COMPETITORS,
    {
      skip: !state.customers?.selected?.id,
      skip: !competitorData,
      onCompleted: () => {
        refetch();
      }
    });

  const [updateCustomerProducts] = useMutation(
    UPDATE_CUSTOMER_PRODUCTS,
    {
      skip: !state.customers?.selected?.id,
      skip: !productData,
      onCompleted: () => {
        product_refetch();
      }
    });

  const [updateCustomerNoFocusProducts] = useMutation(
    UPDATE_CUSTOMER_NO_FOCUS_PRODUCTS,
    {
      skip: !state.customers?.selected?.id,
      skip: !productData,
      onCompleted: () => {
        no_focus_product_refetch();
      }
    });

  const [updateCustomerTargetAreas] = useMutation(
    UPDATE_CUSTOMER_TARGET_AREAS,
    {
      skip: !state.customers?.selected?.id,
      skip: !targetAreaData,
      onCompleted: () => {
        target_areas_data_refetch();
      }
    });

  const [RemoveCustomerCompetitor] = useMutation(
    REMOVE_CUSTOMER_COMPETITOR,
    {
      skip: !state.customers?.selected?.id,
      onCompleted: () => {
        refetch();
      }
    }
  );

  const [RemoveCustomerProduct] = useMutation(
    REMOVE_CUSTOMER_PRODUCT,
    {
      skip: !state.customers?.selected?.id,
      onCompleted: () => {
        product_refetch();
      }
    }
  );

  const [RemoveCustomerNoFocusProduct] = useMutation(
    REMOVE_CUSTOMER_NO_FOCUS_PRODUCT,
    {
      skip: !state.customers?.selected?.id,
      onCompleted: () => {
        no_focus_product_refetch();
      }
    }
  );

  const [RemoveCustomerTargetArea] = useMutation(
    REMOVE_TARGET_AREA,
    {
      skip: !state.customers?.selected?.id,
      onCompleted: () => {
        target_areas_data_refetch();
      }
    }
  );

  const classes = useStyles();

  const top_competitors_columns = [
    {
      Header: "Top Competitors",
      accessor: "customers_top_competitors"
    },
    {
      Header: "Top Competitor's Url",
      accessor: "top_competitors_url"
    },
    {
      Header: "Remove"
    }
  ];

  const top_products_columns = [
    {
      Header: "Top Products",
      accessor: "product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const no_focus_products_columns = [
    {
      Header: "No Focus Products",
      accessor: "no_product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const target_areas_columns = [
    {
      Header: "Target Area",
      accessor: "customer_top_target_area"
    },
    {
      Header: "Remove"
    }
  ];


  useEffect(() => {
    setcompetitorData(all_data?.getTopCompetitors || []);
    setproductData(product_data?.getTopProducts || []);
    setnoFocusProductData(no_focus_product_data?.getNoFocusProducts || []);
    settargetAreaData(target_areas_data?.getTargetAreas || []);
    setMarketingGoal(data?.getMarketingGoal || []);
    setOriginalStartDate(state.customers?.selected?.original_start_date || "");

  }, [all_data, product_data, no_focus_product_data, target_areas_data,
    data, state.customers]);


  const updateFieldHandler = (key, convert) => e => {
    setMarketingGoal({
      ...marketingGoal,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const AddCompetitor = () => {
    let NewArray = [];
    NewArray = Object.assign({}, {id: highestCompetitorID, customers_top_competitors: '', top_competitors_url: '', new: true })
    sethighestCompetitorID(highestCompetitorID + 1)
    setcompetitorData({ ...competitorData, [competitorData?.length || Object.keys(competitorData)?.length]: NewArray });
  }

  const AddProduct = () => {
    let NewArray = [];
    NewArray = Object.assign({}, {id: highestProductID, product_focus: '', new: true })
    sethighestProductID(highestProductID + 1)
    setproductData({ ...productData, [productData?.length || Object.keys(productData)?.length]: NewArray });
  }

  const AddNoFocusProduct = () => {
    let NewArray = [];
    NewArray = Object.assign({}, {id: highestNoFocusProductID, no_product_focus: '', new: true })
    sethighestNoFocusProductID(highestNoFocusProductID + 1)
    setnoFocusProductData({ ...noFocusProductData, [noFocusProductData?.length || Object.keys(noFocusProductData)?.length]: NewArray });
  }

  const AddTargetArea = () => {
    let NewArray = [];
    NewArray = Object.assign({}, {id: highestTopTargetAreasID, customer_top_target_area: '', new: true })
    sethighestTopTargetAreasID(highestTopTargetAreasID + 1)
    settargetAreaData({ ...targetAreaData, [targetAreaData?.length || Object.keys(targetAreaData)?.length]: NewArray });
  }

  const RemoveCompetitor = (key) => e => {
    RemoveCustomerCompetitor({
      variables: {
        id: key
      }
    });
    setcompetitorData(Object.keys(competitorData)?.filter((Competitor) => competitorData[Competitor].id !== key)?.map((Competitor) => competitorData[Competitor] ));
  };

  const RemoveProduct = (key) => e => {
    RemoveCustomerProduct({
      variables: {
        id: key
      }
    });
    setproductData(Object.keys(productData)?.filter((CurrentProduct) => productData[CurrentProduct].id !== key)?.map((CurrentProduct) => productData[CurrentProduct] ));
  };

  const RemoveNoFocusProduct = (key) => e => {
    RemoveCustomerNoFocusProduct({
      variables: {
        id: key
      }
    });
    setnoFocusProductData(Object.keys(noFocusProductData)?.filter((noFocusProduct) => noFocusProductData[noFocusProduct].id !== key)?.map((noFocusProduct) => noFocusProductData[noFocusProduct] ));    
  };

  const RemoveTargetArea = (key) => e => {
    RemoveCustomerTargetArea({
      variables: {
        id: key
      }
    });
    settargetAreaData(Object.keys(targetAreaData)?.filter((newTargetArea) => targetAreaData[newTargetArea].id !== key)?.map((newTargetArea) => targetAreaData[newTargetArea] ));
  };

  function updateTopCompetitors(index, key, e) {
    Object.keys(competitorData).map((item, i) => {
      if (index === i) {
        if (key === "customers_top_competitors") {
          competitorData[i] = Object.assign({}, competitorData[i], { customers_top_competitors: e.target.value });
        }
        else
          competitorData[i] = Object.assign({}, competitorData[i], { top_competitors_url: e.target.value });
      }
      setcompetitorData({ ...competitorData });
    })
  };

  function updateTopProducts(index, e) {
    Object.keys(productData).map((item, i) => {
      if (index === i) {
        productData[i] = Object.assign({}, productData[i], { product_focus: e.target.value });
      }
      setproductData({ ...productData });
    })
  };

  function updateNoFocusProducts(index, e) {
    Object.keys(noFocusProductData).map((item, i) => {
      if (index === i) {
        noFocusProductData[i] = Object.assign({}, noFocusProductData[i], { no_product_focus: e.target.value });
      }
      setnoFocusProductData({ ...noFocusProductData });
    })
  };

  function updateTargetAreas(index, e) {
    Object.keys(targetAreaData).map((item, i) => {
      if (index === i) {
        targetAreaData[i] = Object.assign({}, targetAreaData[i], { customer_top_target_area: e.target.value });
      }
      settargetAreaData({ ...targetAreaData });
    })
  };

  const updateCustomerFieldHandler = (key, convert) => e => {
    if (key === "sunday_open" || key === "sunday_close" ||
      key === "monday_open" || key === "monday_close" ||
      key === "tuesday_open" || key === "tuesday_close" ||
      key === "wednesday_open" || key === "wednesday_close" ||
      key === "thursday_open" || key === "thursday_close" ||
      key === "friday_open" || key === "friday_close" ||
      key === "saturday_open" || key === "saturday_close")
      e.target.value = parseInt(e.target.value)
    setAccountInfo({
      ...accountInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <div key={marketingGoal?.id} style={config}>
      <h2 className={classes.pageHeader}>Update Client Marketing Goals</h2>
      <div>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <form onSubmit={e => e.preventDefault()}>
              {me?.readMe?.user_level === "master" ||
                me?.readMe?.user_level === "campaign_consultant" ||
                me?.readMe?.user_level === "content_manager" ||
                me?.readMe?.user_level === "paid_ad_manager" ||
                me?.readMe?.user_level === "ad_consultant" ||
                me?.readMe?.user_level === "web_designer"
                && (<CustomInput
                  labelText="Original Start Date"
                  id="original_start_date"
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  inputProps={{
                    type: "date",
                    defaultValue: originalStartDate || 1,
                    onChange: e => setOriginalStartDate(e.target.value)
                  }}
                />
                )}
              {me?.readMe?.user_level === "master" ||
                me?.readMe?.user_level === "campaign_consultant" ||
                me?.readMe?.user_level === "content_manager" ||
                me?.readMe?.user_level === "paid_ad_manager" ||
                me?.readMe?.user_level === "ad_consultant" ||
                me?.readMe?.user_level === "web_designer"
                && (<CustomInput
                  labelText="Average Cost Per Lead"
                  id="avg_cost_per_lead"
                  formControlProps={{
                    fullWidth: true,
                    required: true
                  }}
                  inputProps={{
                    type: "integer",
                    defaultValue: marketingGoal?.cost_per_lead_goal,
                    onChange: updateFieldHandler(
                      "cost_per_lead_goal",
                      parseFloat
                    )
                  }}
                />
                )}
              <CustomInput
                labelText="Average Sale/Transaction"
                id="average_sale_goal"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "integer",
                  defaultValue: marketingGoal?.average_sale_goal,
                  onChange: updateFieldHandler(
                    "average_sale_goal",
                    parseInt
                  )
                }}
              />
              <CustomInput
                labelText="Average Liftime Value of a Customer"
                id="avg_lifetime_value"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "integer",
                  defaultValue: marketingGoal?.lifetime_value,
                  onChange: updateFieldHandler("lifetime_value", parseFloat)
                }}
              />
              {me?.readMe?.user_level === "master" ||
                me?.readMe?.user_level === "campaign_consultant" ||
                me?.readMe?.user_level === "content_manager" ||
                me?.readMe?.user_level === "paid_ad_manager" ||
                me?.readMe?.user_level === "ad_consultant" ||
                me?.readMe?.user_level === "web_designer"
                && (
                  <CustomInput
                    labelText="Monthly leads needed to get an ROI"
                    id="leads_needed_to_break_even"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "integer",
                      defaultValue:
                        marketingGoal?.monthly_leads_needed_breakeven,
                      onChange: updateFieldHandler(
                        "monthly_leads_needed_breakeven",
                        parseInt
                      )
                    }}
                  />
                )}
              {me?.readMe?.user_level === "master" ||
                me?.readMe?.user_level === "campaign_consultant" ||
                me?.readMe?.user_level === "content_manager" ||
                me?.readMe?.user_level === "paid_ad_manager" ||
                me?.readMe?.user_level === "ad_consultant" ||
                me?.readMe?.user_level === "web_designer"
                && (
                  <CustomInput
                    labelText="Leads Needed to Increase Budget"
                    id="leads_needed_to_increase_budget"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "integer",
                      defaultValue: marketingGoal?.leads_needed_increase_budget,
                      onChange: updateFieldHandler(
                        "leads_needed_increase_budget",
                        parseInt
                      )
                    }}
                  />
                )}
              <h4>Hours of Operations</h4>
              <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Time Zone:</label>
              <Select
                id="timezone"
                required={true}
                MenuProps={{
                  className: classes.selectMenu
                }}
                inputProps={{
                  type: "string",
                  onChange: updateCustomerFieldHandler("timezone"),
                  defaultValue: customer_data?.timezone || ""
                }}
              >
                <MenuItem value="Eastern Time">Eastern Time</MenuItem>
                <MenuItem value="Central Time">Central Time</MenuItem>
                <MenuItem value="Mountain Time">Mountain Time</MenuItem>
                <MenuItem value="Pacific Time">Pacific Time</MenuItem>
                <MenuItem value="Alaska Time">Alaska Time</MenuItem>
                <MenuItem value="Hawaii Time">Hawaii Time</MenuItem>
              </Select>
              <br />
              <br />
              <div>
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Sunday Open:</label>
                  <Select
                    id="sunday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("sunday_open_hours"),
                      defaultValue: customer_data?.sunday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="sunday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("sunday_open_mins"),
                      defaultValue: customer_data?.sunday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Sunday Close:</label>
                  <Select
                    id="sunday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("sunday_close_hours"),
                      defaultValue: customer_data?.sunday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="sunday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("sunday_close_mins"),
                      defaultValue: customer_data?.sunday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Monday Open:</label>
                  <Select
                    id="monday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("monday_open_hours"),
                      defaultValue: customer_data?.monday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="monday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("monday_open_mins"),
                      defaultValue: customer_data?.monday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Monday Close:</label>
                  <Select
                    id="monday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("monday_close_hours"),
                      defaultValue: customer_data?.monday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="monday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("monday_close_mins"),
                      defaultValue: customer_data?.monday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Tuesday Open:</label>
                  <Select
                    id="tuesday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("tuesday_open_hours"),
                      defaultValue: customer_data?.tuesday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="tuesday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("tuesday_open_mins"),
                      defaultValue: customer_data?.tuesday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Tuesday Close:</label>
                  <Select
                    id="tuesday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("tuesday_close_hours"),
                      defaultValue: customer_data?.tuesday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="tuesday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("tuesday_close_mins"),
                      defaultValue: customer_data?.tuesday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Wednesday Open:</label>
                  <Select
                    id="wednesday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("wednesday_open_hours"),
                      defaultValue: customer_data?.wednesday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="wednesday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("wednesday_open_mins"),
                      defaultValue: customer_data?.wednesday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Wednesday Close:</label>
                  <Select
                    id="wednesday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("wednesday_close_hours"),
                      defaultValue: customer_data?.wednesday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="wednesday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("wednesday_close_mins"),
                      defaultValue: customer_data?.wednesday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Thursday Open:</label>
                  <Select
                    id="thursday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("thursday_open_hours"),
                      defaultValue: customer_data?.thursday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="thursday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("thursday_open_mins"),
                      defaultValue: customer_data?.thursday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Thursday Close:</label>
                  <Select
                    id="thursday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("thursday_close_hours"),
                      defaultValue: customer_data?.thursday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="thursday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("thursday_close_mins"),
                      defaultValue: customer_data?.thursday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Friday Open:</label>
                  <Select
                    id="friday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("friday_open_hours"),
                      defaultValue: customer_data?.friday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="friday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("friday_open_mins"),
                      defaultValue: customer_data?.friday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Friday Close:</label>
                  <Select
                    id="friday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("friday_close_hours"),
                      defaultValue: customer_data?.friday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="friday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("friday_close_mins"),
                      defaultValue: customer_data?.friday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div style={{ float: "left", display: "inline-block" }}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Saturday Open:</label>
                  <Select
                    id="saturday_open_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("saturday_open_hours"),
                      defaultValue: customer_data?.saturday_open?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="saturday_open_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("saturday_open_mins"),
                      defaultValue: customer_data?.saturday_open?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>

                </div>
                <div style={float}>
                  <label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>Saturday Close:</label>
                  <Select
                    id="saturday_close_hours"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("saturday_close_hours"),
                      defaultValue: customer_data?.saturday_close?.split(":")[0] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                  </Select>
                  <Select
                    id="saturday_close_mins"
                    required={true}
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    inputProps={{
                      type: "string",
                      onChange: updateCustomerFieldHandler("saturday_close_mins"),
                      defaultValue: customer_data?.saturday_close?.split(":")[1] || "00"
                    }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="45">45</MenuItem>
                  </Select>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <label className={classes.selectFormControl}>What are the top products or services we should market?</label>
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {top_products_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} style={{ width: "120px", marginLeft: "50px" }}>{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {productData ? Object.keys(productData).map((keyName, index) => (
                    <TableRow key={productData[keyName]?.id}>
                      <TableCell style={{ padding: 0 }}>
                        <CustomInput
                          id={`customers_top_products${productData[keyName].id}`}
                          maxLength="455"
                          type="text"
                          key={`customers_top_products${productData[keyName].id}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event => { updateTopProducts(index, event) },
                            defaultValue: productData[keyName]?.product_focus ? productData[keyName].product_focus : ''
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                        <a style={{ cursor: "pointer" }} onClick={RemoveProduct(productData[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <a style={{ cursor: "pointer" }} onClick={AddProduct}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Top Products or Services We Should Market</a>
              <br />
              <br />
              <br />
              <br />
              <label className={classes.selectFormControl} >&nbsp;&nbsp;What products or services we should not market?</label>
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {no_focus_products_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {noFocusProductData ? Object.keys(noFocusProductData).map((keyName, index) => (
                    <TableRow key={noFocusProductData[keyName]?.id}>
                      <TableCell style={{ padding: 0 }}>
                        <CustomInput
                          id={`customers_no_focus_products${noFocusProductData[keyName].id}`}
                          maxLength="455"
                          type="text"
                          key={`customers_no_focus_products${noFocusProductData[keyName].id}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event => { updateNoFocusProducts(index, event) },
                            defaultValue: noFocusProductData[keyName]?.no_product_focus ? noFocusProductData[keyName].no_product_focus : ''
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                        <a style={{ cursor: "pointer" }} onClick={RemoveNoFocusProduct(noFocusProductData[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <a style={{ cursor: "pointer" }} onClick={AddNoFocusProduct}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Products or Services We Should Not Market</a>
              <br />
              <br />
              <br />
              <br />
              <label className={classes.selectFormControl}>What area should we market? (state, city, or radius)</label>
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {target_areas_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {targetAreaData ? Object.keys(targetAreaData).map((keyName, index) => (
                    <TableRow key={targetAreaData[keyName]?.id}>
                      <TableCell style={{ padding: 0 }}>
                        <CustomInput
                          id={`customers_target_areas${targetAreaData[keyName].id}`}
                          maxLength="455"
                          type="text"
                          key={`customers_target_areas${targetAreaData[keyName].id}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event => { updateTargetAreas(index, event) },
                            defaultValue: targetAreaData[keyName]?.customer_top_target_area ? targetAreaData[keyName].customer_top_target_area : ''
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                        <a style={{ cursor: "pointer" }} onClick={RemoveTargetArea(targetAreaData[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <a style={{ cursor: "pointer" }} onClick={AddTargetArea}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Target Area</a>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <label className={classes.selectFormControl} style={{ marginLeft: "50px" }}>&nbsp;&nbsp;Who are the top competitors?</label>
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {top_competitors_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {competitorData ? Object.keys(competitorData).map((keyName, index) => (
                    <TableRow key={competitorData[keyName]?.id}>
                      <TableCell style={{ padding: 0 }}>
                        <CustomInput
                          id={`customers_top_competitors${competitorData[keyName].id}`}
                          maxLength="455"
                          type="text"
                          key={competitorData[keyName].id}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event => { updateTopCompetitors(index, "customers_top_competitors", event) },
                            defaultValue: competitorData[keyName]?.customers_top_competitors ? competitorData[keyName].customers_top_competitors : ''
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: 0 }}>
                        <CustomInput
                          id={`competitorData[keyName]${competitorData[keyName].id}`}
                          maxLength="455"
                          type="text"
                          key={competitorData[keyName].id}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: event => { updateTopCompetitors(index, "top_competitors_url", event) },
                            defaultValue: competitorData[keyName]?.top_competitors_url ? competitorData[keyName].top_competitors_url : ''
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                        <a style={{ cursor: "pointer" }} onClick={RemoveCompetitor(competitorData[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <a style={{ cursor: "pointer" }} onClick={AddCompetitor}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Top Competitor</a>
              <br />
              <br />
            </form>
            <Button
              color="orange"
              type="submit"
              onClick={() => {
                updateMarketingGoal();
                updateCustomer();
                updateCustomerHours();
                Object.keys(competitorData).map((keyName, index) => {
                  updateCustomerCompetitors({
                    variables: {
                      customer_id: state.customers?.selected?.id,
                      data: omit(competitorData[keyName], ["__typename"])
                    }
                  })
                })
                Object.keys(productData).map((keyName, index) => {
                  updateCustomerProducts({
                    variables: {
                      customer_id: state.customers?.selected?.id,
                      data: omit(productData[keyName], ["__typename"])
                    }
                  })
                })
                Object.keys(noFocusProductData).map((keyName, index) => {
                  updateCustomerNoFocusProducts({
                    variables: {
                      customer_id: state.customers?.selected?.id,
                      data: omit(noFocusProductData[keyName], ["__typename"])
                    }
                  })
                })
                Object.keys(targetAreaData).map((keyName, index) => {
                  updateCustomerTargetAreas({
                    variables: {
                      customer_id: state.customers?.selected?.id,
                      data: omit(targetAreaData[keyName], ["__typename"])
                    }
                  })
                })
              }
              }
            >
              Submit
            </Button>
          </CardBody>
        </Card>
      </div>
    </div >
  );
};

export default withSubmissions(UpdateMarketingGoal);
