import React, { useContext } from "react";

import NoContentWrapper from "components/NoContentWrapper";
import { GET_GOOGLE_ADS_INFO_SUMMARY } from "queries/google";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../redux/store";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

const AwarenessNoContentWrapper = ({
  product,
  title,
  filter,
  type_filter,
  productLine,
  children
}) => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  const productLines = productLine
    .split(",")
    .map(prodLine => prodLine.split(":")[0]);
  let content = "";
  if (product === "BannerAds") {
    switch (filter) {
      case "Awareness":
        content = (
          <>
            With Water Bear Marketing's BannerAds, you are able to get in front
            of people, leveraging a display banner ad, on over 96% of the places
            people can see the banner ads. We eliminate wasted ad impressions by
            leveraging our technology and proprietary targeting products.
            <br />
            A benefit of banner ads is that, they can be designed and styled and
            are not limited to text only with character counts. Unlike
            traditional billboards, banner ads help you create brand awareness
            in a place where viewers can take immediate action, online.
            <br />
            What is BannerAds with GeoDemo®? GeoDemo® is a Water Bear Marketing
            proprietary targeting capability that helps our customers target
            based on a defined geographic area and demographics, so that we can
            hone in on your ideal customers while still casting your message to
            a much larger audience.
          </>
        );
        break;
      case "GeoIntention":
        content = (
          <>
            With Water Bear Marketing's BannerAds, you are able to get in front
            of people, leveraging a display banner ad, on over 96% of the places
            people can see the banner ads. We eliminate wasted ad impressions by
            leveraging our technology and proprietary targeting products.
            <br />
            A benefit of banner ads is that, they can be designed and styled and
            are not limited to text only with character counts. Unlike
            traditional billboards, banner ads help you create brand awareness
            in a place where viewers can take immediate action, online.
            <br />
            What is BannerAds with GeoIntention®? GeoIntention® is a Water Bear
            Marketing proprietary advertising targeting capability where we
            customize your advertising program by using a radius around a
            physical location, keyword research, your competitive landscape,
            products or services offered in your industry, and layers of
            demographic targeting such as age, or gender. Last but not least, we
            can add in targeting specific business types (hair salons, grocery
            stores, real estate offices) where people have visited in the last
            30 days.
          </>
        );
        break;
      case "ReEngage":
        content = (
          <>
            With Water Bear Marketing's BannerAds, you are able to get in front
            of people, leveraging a display banner ad, on over 96% of the places
            people can see the banner ads. We eliminate wasted ad impressions by
            leveraging our technology and proprietary targeting products.
            <br />
            A benefit of banner ads is that, they can be designed and styled and
            are not limited to text only with character counts. Unlike
            traditional billboards, banner ads help you create brand awareness
            in a place where viewers can take immediate action, online.
            <br />
            What is BannerAds with ReEngage®? ReEngage® is a Water Bear
            Marketing targeting capability that enables our customers to
            communicate with people who have previously engaged with them. Using
            BannerAds, we cover over 96% of ad spaces available, to help our
            clients advertise in front of people who have previously visited
            their website. Keep the conversation moving forward with ReEngage®.
          </>
        );
        break;
      default:
        break;
    }
  } else if (product === "OnlineVideo") {
    switch (filter) {
      case "Awareness":
        content = (
          <>
            With Water Bear Marketing's OnlineVideo, you are able to get in
            front of people, leveraging a commercial that can be linked back to
            your website or landing page, to over 70% of adults who are on
            mobile, desktop, or tablet devices. We eliminate wasted ad
            impressions by leveraging our technology and proprietary targeting
            products.
            <br />
            A benefit of online video is that, videos are a quick, easy, and
            entertaining way for you to reach viewers who you may not have
            captivated with other forms of online or offline marketing. 65% of
            your audience are visual learners and video marketing is an engaging
            way to grab their attention and turn them into customers.
            <br />
            What is OnlineVideo with GeoDemo®? GeoDemo® is a Water Bear
            Marketing proprietary targeting capability that helps our customers
            target based on a defined geographic area and demographics so that
            we can hone in on your ideal customers while still casting your
            message to a much larger audience.
          </>
        );
        break;
      case "GeoIntention":
        content = (
          <>
            With Water Bear Marketing's OnlineVideo, you are able to get in
            front of people, leveraging a commercial that can be linked back to
            your website or landing page, to over 70% of adults who are on
            mobile, desktop, or tablet devices. We eliminate wasted ad
            impressions by leveraging our technology and proprietary targeting
            products.
            <br />
            What is OnlineVideo with GeoIntention®? With GeoIntention®, we
            customize your advertising program by using a radius around a
            physical location, keyword research, your competitive landscape,
            products or services offered in your industry, and layers of
            demographic targeting such as age, or gender. Last but not least, we
            can add in targeting specific business types (hair salons, grocery
            stores, real estate offices) where people have visited in the last
            30 days.
          </>
        );
        break;
      case "ReEngage":
        content = (
          <>
            With Water Bear Marketing's OnlineVideo, you are able to get in
            front of people, leveraging a commercial that can be linked back to
            your website or landing page, to over 70% of adults who are on
            mobile, desktop, or tablet devices. We eliminate wasted ad
            impressions by leveraging our technology and proprietary targeting
            products.
            <br />
            What is OnlineVideo with ReEngage®? ReEngage® is a Water Bear
            Marketing targeting capability that enables our customers to
            communicate with people who have previously engaged with them. Using
            Online Video, we can reach over 70% of adults who are on mobile,
            desktop, or tablet devices. This allows us to help our clients
            advertise in front of people who have previously visited their
            website. Keep the conversation moving forward with ReEngage®.
          </>
        );
        break;
      default:
        break;
    }
  }
  return (
    <NoContentWrapper
      skip={!customer_id}      
      queries={[        
        ...productLines.map((pL, index) => ({
          name: `budgetData_${index}`,
          query: GET_BUDGET,
          options: { variables: { customer_id, product_line: pL } },
        }))
      ]}
      product={product}
      title={title}
      isLoading={({ ...budgetsData }) => {
        const budgetsDataKey = Object.keys(budgetsData);
        for (let i = 0; i < budgetsDataKey.length; i++) {
          if (budgetsData[budgetsDataKey[i]]?.loading) {
            return true;
          }
        }

        return false;
      }}
      hasNoData={({ ...budgetsData }) => {
        let bFound = false;
        const budgetsDataKey = Object.keys(budgetsData);
        for (let i = 0; i < budgetsDataKey.length; i++) {
          const budget =
            budgetsData[budgetsDataKey[i]]?.data?.getBudget?.monthly_budget ||
            0;
          if (budget !== 0) {
            bFound = true;
          }
        }
        return bFound;
      }}
      noContent={content}
    >
      <ThemeProvider theme={theme}>
      {children}
      </ThemeProvider>
    </NoContentWrapper>
  );
};

export default AwarenessNoContentWrapper;
