import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";

import { makeStyles } from "@mui/styles";

// @mui/icons-material
import PermIdentity from "@mui/icons-material/PermIdentity";

// core components
import Dropzone from "components/Dropzone";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Clearfix from "components/Clearfix/Clearfix";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardAvatar from "components/Card/CardAvatar";
import Avatar from "components/Avatar";
import Switch from '@mui/material/Switch';
import { READ_ME, UPDATE_ME, UPDATE_PASSWORD } from "queries/users";
import withSubmissions from "hocs/withSubmissions";

import {
  UPDATE_MUTED_NOTIFICATIONS,
  LIST_MUTED_NOTIFICATIONS
} from "queries/customers";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";

const useStyles = makeStyles(styles);

const UserProfile = ({ formSubmitted }) => {
  const me = useQuery(READ_ME)?.data?.readMe;
  const [userInfo, setUserInfo] = useState(me);
  const [PasswordReset, setPasswordReset] = useState([]);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const [MutedNotifications, setMutedNotifications] = useState([]);
  
  const [updateMe, { loading }] = useMutation(UPDATE_ME, {
    variables: userInfo,
    onCompleted: () => {
      formSubmitted();
    }
  });

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    variables: {
      new_password: PasswordReset.new_password
    },
    onCompleted: () => {
      formSubmitted();
    }
  });

  const [GetMutedNotifications] = useLazyQuery(LIST_MUTED_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    variables: {
      customer_id: me?.customer_id,
      user_id: me?.id
    },

    onCompleted: (data) => {
      setMutedNotifications(data?.listMutedNotifications);
    }
  });

  const [UpdateMutedNotifications] = useMutation(UPDATE_MUTED_NOTIFICATIONS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    onCompleted: () => {
      GetMutedNotifications();
    }
  });

  const classes = useStyles();

  const updateFieldHandler = (key, convert) => e => {
    setUserInfo({
      ...userInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const updatePasswordFieldHandler = (key, convert) => e => {
    setPasswordReset({
      ...PasswordReset,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  useEffect(() => {
    setUserInfo(me);
    GetMutedNotifications();
  }, [me]);

  return (
    <div>
      <GridContainer marginTop="20px">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon>
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit Profile - <small>Complete your profile</small>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer loading={loading}>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="First Name"
                    id="first_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: userInfo?.first_name || "",
                      onChange: updateFieldHandler("first_name")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Last Name"
                    id="last_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: userInfo?.last_name || "",
                      onChange: updateFieldHandler("last_name")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "email",
                      value: userInfo?.email || "",
                      onChange: updateFieldHandler("email")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Cell Phone Number"
                    id="cell_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: userInfo?.cell_phone || "",
                      onChange: updateFieldHandler("cell_phone", e => `${e}`)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Birthday"
                    id="birthday"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "date",
                      value:
                        moment(userInfo?.birthday).format("YYYY-MM-DD") || "",
                      onChange: updateFieldHandler("birthday")
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Office Phone Number"
                    id="office_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: userInfo?.office_phone || "",
                      onChange: updateFieldHandler("office_phone", e => `${e}`)
                    }}
                    value={me?.office_phone}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Office Extension"
                    id="office_extension"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "integer",
                      value: userInfo?.office_extension || "",
                      onChange: updateFieldHandler(
                        "office_extension",
                        e => `${e}`
                      )
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Switch
                    checked={MutedNotifications?.length}
                    onChange={async () => {
                      await UpdateMutedNotifications({
                        variables: {
                          customer_id: me?.customer_id,
                          user_id: me?.id
                        }
                      });
                    }}
                    color="primary"
                    name="budget_optimization"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Mute Notifications
                </GridItem>

              </GridContainer>
              <Button
                color="orange"
                className={classes.updateProfileButton}
                onClick={() => {
                  if (updateMe?.length === 0)
                    alert("No Changes Detected.");
                  else {
                    updateMe()
                  }
                }
                }
              >
                Update Profile
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardAvatar profile>
              <Dropzone
                path={`avatars/${me?.id}`}
                accept="image/png, image/jpeg"
                setuploadPercentage={setuploadPercentage}
              >
                {({ url, loading }) => (
                  <Avatar
                    userId={me?.id}
                    loading={loading}
                    url={url}
                    size={130}
                  />
                )}
              </Dropzone>
            </CardAvatar>
            <CardBody>
              <h6 className={classes.cardCategory}>
                Ideal image upload size is 400 x 400
              </h6>
              <div className={classes.description}>{userInfo?.email}</div>
              <h4 className={classes.cardTitle}>
                {userInfo?.first_name} {userInfo?.last_name}
              </h4>
              <h6 className={classes.cardCategory}>{userInfo?.cell_phone}</h6>
            </CardBody>
          </Card>
          <Card profile>
            <CardBody>
              <h6 className={classes.cardCategory}>
                Change Dashboard Password
              </h6>
              <form autoComplete="off">
                <CustomInput
                  labelText="Enter New Password"
                  id="new_password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    //value: userInfo?.office_extension || "",
                    onChange: updatePasswordFieldHandler(
                      "new_password",
                      e => `${e}`
                    )
                  }}
                />
                <CustomInput
                  labelText="Re-Enter New Password"
                  id="re_new_password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    //value: userInfo?.office_extension || "",
                    onChange: updatePasswordFieldHandler(
                      "re_new_password",
                      e => `${e}`
                    )
                  }}
                />
                <Button
                  color="orange"
                  className={classes.updateProfileButton}
                  onClick={() => {
                    if (PasswordReset?.length === 0 || PasswordReset?.new_password?.length === 0 || PasswordReset?.re_new_password?.length === 0)
                      alert("Password cannot be empty.");
                    else if (PasswordReset?.new_password !== PasswordReset?.re_new_password)
                      alert("Passwords do not match.");
                    else if (PasswordReset?.new_password?.length < 10)
                      alert("Passwords must be at least 10 characters.");
                    else {
                      updatePassword()
                    }
                  }
                  }
                >
                  Change Password
                </Button>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withSubmissions(UserProfile);