import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle";

const useStyles = makeStyles(styles);

const SelectStatic = ({
  label,
  name,
  value,
  onChange,
  data,
  required,
  validation,
  customOption,
  narrow
}) => {
  const classes = useStyles();
  let selectValue;
  if (value === undefined || value === null) {
    selectValue = value;
  } else if (customOption) {
    selectValue = (data.find(item => item.value === value) || { value: customOption }).value
  } else {
    selectValue = value;
  }
  return (
    <FormControl
      fullWidth
      className={narrow ? null : classes.selectFormControl}
      required={required}
    >
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        inputProps={{
          name,
          id: name
        }}
        value={selectValue || 0}
        onChange={e => {
          if (e.target.value === customOption) {
            onChange({ target: { value: "" } });
          } else {
            onChange(e);
          }
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
          value={0}
        >
          {label}
        </MenuItem>
        {data?.map(({ value, label }) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={value || undefined}
            key={value || Math.random()}
          >
            {label || value}
          </MenuItem>
        ))}
      </Select>
      {customOption && (selectValue !== value || value === customOption) && (
        <Input
          name={name}
          id={`input-${name}`}
          fullWidth
          required={required}
          inputProps={{
            type: "string",
            value: value || "",
            onChange,
            placeholder: "Input custom value here"
          }}
        />
      )}
      {validation && !value && (
        <FormHelperText>This is required</FormHelperText>
      )}
    </FormControl>
  );
};

SelectStatic.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  required: PropTypes.bool,
  validation: PropTypes.bool,
  onLoaded: PropTypes.func
};

SelectStatic.defaultProps = {
  value: null,
  data: [],
  required: false,
  hoverColor: "primary !important",
  validation: false
};

export default SelectStatic;
