import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";
import HelpTooltip from "components/HelpTooltip";
import Switch from '@mui/material/Switch';

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { GET_GOOGLE_CAMPAIGNS } from "queries/budget";
import { SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM } from "queries/formSubmission";
import { UPDATE_OPTIMIZATIONS } from "queries/formSubmission";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";
import AllBudgets from "./AllBudgets.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const CampaignBudgets = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [submissionData, setSubmissionData] = useState({});
  const [pageSize, setPageSize] = useState(20);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading: googlecampaignloading, data: googlecampaigndata, refetch } = useQuery(GET_GOOGLE_CAMPAIGNS, {
    skip: !customer_id,
    variables: { 
      customer_id: customer_id
    }
  });

  googlecampaigndata = googlecampaigndata?.getGoogleCampaigns || [];

  const [enableKeywordBudgetOptizimation, { loading: updateing_ticket_status }] = useMutation(UPDATE_OPTIMIZATIONS, {
    onCompleted: () => {
      refetch();
    }
  });
  
  const classes = useStyles();

  const handleChange = (event, id) => {
    if(event.target.name === "budget_optimization")
    {
      enableKeywordBudgetOptizimation({
        variables: {
          budget_optimization: event.target.checked === false ? 0 : 1,
          id: id
        }
      });
    }else if(event.target.name === "keyword_optimization")
    {
      enableKeywordBudgetOptizimation({
        variables: {
          keyword_optimization: event.target.checked === false ? 0 : 1,
          id: id
        }
      });
    }else if(event.target.name === "banner_ad_optimization")
    {
      enableKeywordBudgetOptizimation({
        variables: {
          banner_ad_optimization: event.target.checked === false ? 0 : 1,
          id: id
        }
      });
    }else if(event.target.name === "share_budget")
    {
      enableKeywordBudgetOptizimation({
        variables: {
          share_budget: event.target.checked === false ? 0 : 1,
          id: id
        }
      });
    }
  };
 
  return (
    <div>      
        <>
          <h2 className={classes.pageHeader}>Budgets</h2>
          <AllBudgets />
        </>
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Monthly Campaign Budgets
            <HelpTooltip>
                {`These budgets will optimize to spend in 30 days.`}
              </HelpTooltip>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={googlecampaigndata?.length}
              data={googlecampaigndata}
              loading={googlecampaignloading}
              sortable={true}
              filterable={true}
                            
              columns={[
                {
                  Header: <div>Account<br/>Name</div>,
                  accessor: "customer_name"
                },
                {
                  Header: "Campaign Type",
                  accessor: "campaign_type"
                },
                {
                  Header: "Campaign Name",
                  accessor: "campaign_name"
                },
                {
                  Header: "Status",
                  accessor: "status"
                },
                {
                  Header: "Monthly Budget",
                  accessor: "monthly_budget",
                  Cell: ({ original }) => {
                    return (
                      <div>{submissionData.id === original.id ? Math.round(submissionData.monthly_budget * 100) / 100 : original.monthly_budget ? Math.round((original.monthly_budget / 1000000) * 100) / 100 : ""}</div>
                      );
                  },
                },
                {
                  Header: "Update Monthly Budget",
                  accessor: "update_monthly_budget",                
                  Cell: ({ original }) => {           
                    const [textInput, setTextInput] = useState(null);

                    const [submitGoogleCampaignMonthlyBudgetForm, { loading: submitting }] = useMutation(
                      SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM,
                      {
                        variables: {
                          customerId: customer_id,
                          data: submissionData
                        },
                        onCompleted: () => {
                          refetch();
                        return true;
                        }
                      }
                    );

                    return (
                      <form
                        key={"form"+original.id}
                        onSubmit={e => {
                          e.preventDefault();
                          if(Number.isNaN(textInput) || textInput === "" || textInput === null){
                            setSubmissionData({});
                            return;
                          }
                          submissionData["monthly_budget"] = parseFloat(textInput);
                          submissionData["id"] = original.id;
                          
                          if(submissionData && submitGoogleCampaignMonthlyBudgetForm()){
                            refetch();
                            setSubmissionData({});
                            }  
                          }
                        }
                      >
                      <input
                        id={`monthly_budget${original.id}`}
                        type="text"
                        maxLength="12" 
                        size="8"
                        style={{ float: "left" }}
                        key={original.id}
                        onChange = {event => { setTextInput(event.target.value) }}
                        defaultValue={submissionData.id === original.id ? Math.round(submissionData.monthly_budget * 100) / 100 : original.monthly_budget ? Math.round((original.monthly_budget / 1000000) * 100) / 100 : ""}
                        inputprops={{
                          type: "string",
                          required: true,
                        }}
                      />
                      <Button
                        color="primary" 
                        type="submit"
                        style={{ float: "right" }}
                        >
                      Submit
                    </Button>
                  </form>
                    );
                  },
                },
                {
                  Header: "MAX CPC",
                  accessor: "max_cost_per_click",                
                  Cell: ({ original }) => {           
                    const [textInput, setTextInput] = useState(null);

                    const [submitGoogleCampaignMonthlyBudgetForm, { loading: submitting }] = useMutation(
                      SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM,
                      {
                        variables: {
                          customerId: customer_id,
                          data: submissionData
                        },
                        onCompleted: () => {
                          refetch();
                        return true;
                        }
                      }
                    );

                    return (
                      <form
                        key={"form_max_cost_per_click"+original.id}
                        onSubmit={e => {
                          e.preventDefault();
                          if(Number.isNaN(textInput) || textInput === "" || textInput === null){
                            setSubmissionData({});
                            return;
                          }
                          submissionData["max_cost_per_click"] = parseFloat(textInput);
                          submissionData["id"] = original.id;
                          
                          if(submissionData && submitGoogleCampaignMonthlyBudgetForm()){
                            refetch();
                            setSubmissionData({});
                            }  
                          }
                        }
                      >
                      <input
                        id={`max_cost_per_click${original.id}`}
                        type="text"
                        maxLength="12"
                        size="8"
                        style={{ float: "left" }}
                        key={original.id}
                        onChange = {event => { setTextInput(event.target.value) }}
                        defaultValue={submissionData.id === original.id ? submissionData.max_cost_per_click: original.max_cost_per_click}
                        inputprops={{
                          type: "string",
                          required: true,
                        }}
                      />
                      <Button
                        color="primary" 
                        type="submit"
                        style={{ float: "right" }}
                        >
                      Submit
                    </Button>
                  </form>
                    );
                  },
                },
                {
                  Header: <div><div>CPLG</div><HelpTooltip>Cost Per Lead Goal.</HelpTooltip></div>,
                  accessor: "cost_per_lead_goal",                
                  Cell: ({ original }) => {           
                    const [textInput, setTextInput] = useState(null);

                    const [submitGoogleCampaignMonthlyBudgetForm, { loading: submitting }] = useMutation(
                      SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM,
                      {
                        variables: {
                          customerId: customer_id,
                          data: submissionData
                        },
                        onCompleted: () => {
                          refetch();
                        return true;
                        }
                      }
                    );

                    return (
                      <form
                        key={"form_cost_per_lead_goal"+original.id}
                        onSubmit={e => {
                          e.preventDefault();
                          if(Number.isNaN(textInput) || textInput === "" || textInput === null){
                            setSubmissionData({});
                            return;
                          }
                          submissionData["cost_per_lead_goal"] = parseFloat(textInput);
                          submissionData["id"] = original.id;
                          
                          if(submissionData && submitGoogleCampaignMonthlyBudgetForm()){
                            refetch();
                            setSubmissionData({});
                            }  
                          }
                        }
                      >
                      <input
                        id={`cost_per_lead_goal${original.id}`}
                        type="text"
                        maxLength="12" 
                        size="8"
                        style={{ float: "left" }}
                        key={original.id}
                        onChange = {event => { setTextInput(event.target.value) }}
                        defaultValue={submissionData.id === original.id ? submissionData.cost_per_lead_goal: original.cost_per_lead_goal}
                        inputprops={{
                          type: "string",
                          required: true,
                        }}
                      />
                      <Button
                        color="primary" 
                        type="submit"
                        style={{ float: "right" }}
                        >
                      Submit
                    </Button>
                  </form>
                    );
                  },
                },
                {
                  Header: "Conversion Rate",
                  accessor: "conversion_rate",                
                  Cell: ({ original }) => {           
                    const [textInput, setTextInput] = useState(null);

                    const [submitGoogleCampaignMonthlyBudgetForm, { loading: submitting }] = useMutation(
                      SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM,
                      {
                        variables: {
                          customerId: customer_id,
                          data: submissionData
                        },
                        onCompleted: () => {
                          refetch();
                        return true;
                        }
                      }
                    );

                    return (
                      <form
                        key={"form_conversion_rate"+original.id}
                        onSubmit={e => {
                          e.preventDefault();
                          if(Number.isNaN(textInput) || textInput === "" || textInput === null){
                            setSubmissionData({});
                            return;
                          }
                          submissionData["conversion_rate"] = parseFloat(textInput);
                          submissionData["id"] = original.id;
                          
                          if(submissionData && submitGoogleCampaignMonthlyBudgetForm()){
                            refetch();
                            setSubmissionData({});
                            }  
                          }
                        }
                      >
                      <input
                        id={`conversion_rate${original.id}`}
                        type="text"
                        maxLength="12"
                        size="8"
                        style={{ float: "left" }}
                        key={original.id}
                        onChange = {event => { setTextInput(event.target.value) }}
                        defaultValue={submissionData.id === original.id ? submissionData.conversion_rate: original.conversion_rate}
                        inputprops={{
                          type: "string",
                          required: true,
                        }}
                      />
                      <Button
                        color="primary" 
                        type="submit"
                        style={{ float: "right" }}
                        >
                      Submit
                    </Button>
                  </form>
                    );
                  },
                },
                {
                  Header: "Cycle Start Date",
                  accessor: "cycle_start_time"
                },
                {
                  Header: "Keyword Optimization",
                  Cell: ({ original }) => {
                    if(original.campaign_type !== "DISPLAY" && original?.campaign_name?.indexOf("Video") === -1)
                      return (
                        <Switch
                          checked={original.keyword_optimization}
                          onChange={event => { handleChange(event, original.id)}}
                          color="primary"
                          name="keyword_optimization"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        );
                    else
                      return ""                  },
                },
                {
                  Header: "Budget Optimization",
                  Cell: ({ original }) => {
                    return (
                      <Switch
                      checked={original.budget_optimization}
                      onChange={event => { handleChange(event, original.id)}}
                      color="primary"
                      name="budget_optimization"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                      );
                  },
                },
                {
                  Header: "BannerAds Optimization",
                  Cell: ({ original }) => {
                    if(original.campaign_type !== "SEARCH")
                      return (
                        <Switch
                        checked={original.banner_ad_optimization}
                        onChange={event => { handleChange(event, original.id)}}
                        color="primary"
                        name="banner_ad_optimization"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      );
                    else
                      return ""
                  },
                },
                {
                  Header: "Share Budget",
                  Cell: ({ original }) => {
                      return (
                        <Switch
                        checked={original.share_budget}
                        onChange={event => { handleChange(event, original.id)}}
                        color="primary"
                        name="share_budget"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      );
                  },
                },
                {
                  Header: "Campaign Pacing",
                  Cell: ({ original }) => {
                    const [textInput, setTextInput] = useState(null);

                    const [submitGoogleCampaignMonthlyBudgetForm, { loading: submitting }] = useMutation(
                      SUBMIT_GOOGLE_CAMPAIGN_MONTHLY_BUDGET_FORM,
                      {
                        variables: {
                          customerId: customer_id,
                          data: submissionData
                        },
                        onCompleted: () => {
                          refetch();
                        return true;
                        }
                      }
                    );

                      return (
                        <Select
                          name="campaign_pacing"
                          id="campaign_pacing"
                          MenuProps={{
                            className: classes.selectMenu
                          }} 
                          inputProps={{
                            type: "string",
                            onChange: (event) => {
                              submissionData["campaign_pacing"] = parseFloat(event.target.value);
                              submissionData["id"] = original.id;
                              if(submissionData && submitGoogleCampaignMonthlyBudgetForm()){
                                refetch();
                                setSubmissionData({});
                                }
                            },
                            value: original?.campaign_pacing || "30"
                          }}
                      >
                        <MenuItem value={15}>15 days</MenuItem>
                        <MenuItem value={30}>30 days</MenuItem>
                        <MenuItem value={45}>45 days</MenuItem>
                        <MenuItem value={60}>60 days</MenuItem>
                        <MenuItem value={90}>90 days</MenuItem>
                      </Select>
                      );
                  },
                }
              ]}
              defaultPageSize={Math.min(pageSize, googlecampaigndata?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              onPageSizeChange={ps => setPageSize(ps)}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
};

export default withSubmissions(CampaignBudgets);
