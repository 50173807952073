import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

import BudgetCard from "components/BudgetCard";
import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { GET_GOOGLE_LSA_SUMMARY } from "queries/google";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const useStyles = makeStyles(styles);

const GoogleLSA = () => {
  const classes = useStyles();
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const customer_id = state.customers?.selected?.id;
  const { loading, data } = useQuery(
    GET_GOOGLE_LSA_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const { loading: loadingCompare, data: dataCompare } = useQuery(
    GET_GOOGLE_LSA_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const summary = data?.getGoogleLsaSummary;
  const summaryCompare = dataCompare?.getGoogleLsaSummary;

  return (
    <div>
      <h2 className={classes.pageHeader}>Google LSA</h2>
      <GridContainer>
        <StatsCard
          icon="attach_money"
          loading={loading}
          label="Total Spent"
          value={addMargin((summary?.cost || 0) / 1000000, state, true)}
          render="currency"
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? addMargin((summaryCompare?.cost || 0) / 1000000, state, true)
              : ""
          }
          previous
        />
        <StatsCard
          icon="dvr"
          loading={loading}
          label="Impressions"
          value={summary?.impressions || 0}
          render="integer"
          help="Impressions are the number of times that your advertisement has been displayed."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? summaryCompare?.impressions || 0
              : ""
          }
          previous
        />
        <StatsCard
          icon="donut_small"
          loading={loading}
          label="Clicks"
          value={summary?.clicks || 0}
          render="integer"
          help="Clicks are the count of the number of times users have clicked on your advertisement."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? summaryCompare?.clicks || 0
              : ""
          }
          previous
        />
        <StatsCard
          icon="scatter_plot"
          loading={loading}
          label="CTR"
          value={(summary?.clicks || 0) / (summary?.impressions || 1) / 0.01}
          render="percentage"
          help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? (summaryCompare?.clicks || 0) /
                (summaryCompare?.impressions || 1) /
                0.01
              : ""
          }
          previous
        />
        <StatsCard
          icon="image_aspect_ratio"
          loading={loading}
          label="CPC"
          value={addMargin(
            (summary?.cost || 0) / (summary?.clicks || 1) / 1000000,
            state,
            true
          )}
          render="currency"
          descending
          help="CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? addMargin(
                  (summaryCompare?.cost || 0) / (summaryCompare?.clicks || 1) / 1000000,
                  state,
                  true
                )
              : ""
          }
          previous
        />
        <BudgetCard productLine="local_services_ads" title="Google Local ServiceAds" />
      </GridContainer>
    </div>
  );
};

export default GoogleLSA;
