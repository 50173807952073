import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@mui/styles";

import Button from "components/CustomButtons/Button";

const styles = {
  actionButton: {
    float: "right"
  },
  fontBold: {
    fontWeight: "bold"
  },
  fieldBlock: {
    height: 50,
    padding: "10px 20px",
    border: "1px solid lightgray",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 3,
    "&[draggable]": {
      cursor: "move"
    }
  },
  verticalScroll: {
    overflowY: "auto",
    height: 300,
    padding: 10,
    width: "100%",
    "& > *": {
      padding: 0
    }
  }
};

const useStyles = makeStyles(styles);

const Mapping = ({ content, inputs, mapping, setMapping, header, setHeader }) => {
  const classes = useStyles();
  const [dragOver, setDragOver] = useState(null);
  const [dragStart, setDragStart] = useState(null);
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md={8}>
            <p>Drag fields from the right side picker to the left side boxes matching your data. Unmapped columns will not be imported into Hydrate.</p>
            <p className={classes.fontBold}>Spreadsheet columns</p>
            <div>
              <Checkbox color="primary" onClick={() => { setHeader(header => !header) }} checked={header} />
              <span>The first row in my file is a column header, do not import it.</span>
              <Button
                color="primary"
                className={classes.actionButton}
                onClick={() => setMapping({})}
                size="sm"
                simple
              >
                Reset
              </Button>
            </div>
            <Row
              className={classes.verticalScroll}
              onDragLeave={(e) => {
                if (e.target.className?.indexOf(classes.verticalScroll) !== -1) {
                  setDragOver(null);
                }        
              }}
            >
              <Col md={6}>
                {content[0]?.map((value, index) => (
                  <div
                    key={index}
                    className={classes.fieldBlock}
                    onDragOver={() => {
                      setDragOver(index);
                    }}
                    style={{ borderColor: dragOver === index ? "black" : "lightgray" }}
                  >
                    {header && <div className={classes.fontBold}>{value}</div>}
                    <div>{header ? content[1][index]: value }</div>
                  </div>
                ))}
              </Col>
              <Col md={6}>
                {content[0]?.map((value, index) => {
                  const input = inputs.find(({ field }) => mapping[index] === field);
                  return (
                    <div
                      key={index}
                      className={classes.fieldBlock}
                      onDragOver={() => {
                        setDragOver(index);
                      }}
                      onDragStart={() => {
                        if (input?.field) {
                          setDragStart(input?.field)
                        }
                      }}
                      onDragEnd={() => {
                        if (dragOver !== null && dragStart !== null) {
                          const swapField = mapping[dragOver];
                          setMapping({
                            ...mapping,
                            [index]: swapField,
                            [dragOver]: dragStart
                          });
                        } else if (dragStart !== null) {
                          //mapping[index] = undefined;
                          delete mapping[index];
                          setMapping(mapping);                          
                        }
                        
                        setDragStart(null);
                        setDragOver(null);
                      }}
                      style={{ backgroundColor: dragOver === index ? "#eeeeee" : "transparent" }}
                      draggable={Boolean(input)}
                    >
                      {input?.label}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Col>
          <Col md={4} style={{ paddingTop: 83 }}>
            <div className={classes.fontBold}>
              <span style={{ lineHeight: "40px" }}>Data fields</span>
              <Button
                color="primary"
                className={classes.actionButton}
                size="sm"
                simple
                onClick={() => {
                  const newMapping = {};
                  inputs.forEach(({ label, field }) => {
                    const index = content[0].findIndex(value => value === label);
                    if (index > -1) {
                      newMapping[index] = field;
                    }
                  });
                  setMapping(newMapping);
                }}
              >
                Exact Mapping
              </Button>
            </div>
            <Row className={classes.verticalScroll}>
              <Col md={12}>
                {inputs
                  .filter(({ field }) => {
                    return !Object.keys(mapping).some(key => field === mapping[key])
                  })
                  .map(({ label, field }, index) => (
                    <div
                      key={index}
                      className={classes.fieldBlock}
                      draggable
                      onDragStart={() => setDragStart(field)}
                      onDragEnd={() => {
                        if (dragOver !== null && dragStart !== null) {
                          setMapping({
                            ...mapping,
                            [dragOver]: dragStart
                          });
                        }
                        setDragStart(null);
                        setDragOver(null);
                      }}
                    >
                      {label}
                    </div>
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mapping;
