import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";


import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useMediaQuery } from 'react-responsive';

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import StatsCard from "components/StatsCard";
import WriteReview from "views/Dashboard/WriteReview";
import ReferFriend from "views/Dashboard/ReferFriend";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { GET_MARKETING_GOAL } from "queries/marketingGoals";
import {
  GET_GOOGLE_ADS_INFO_SUMMARY,
  GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
  GET_GOOGLE_LSA_SUMMARY
} from "queries/google";
import { LIST_CUSTOMERS,
  GET_TOP_COMPETITORS,
  GET_TOP_PRODUCTS,
  GET_NO_FOCUS_PRODUCTS,
  GET_TARGET_AREAS } from "queries/customers";
import { GET_BIRDEYE_CUSTOMERS_AND_LEADS } from "queries/birdeye";
import { GET_BING_CAMPAIGNS_SUMMARY } from "queries/bing";
import { GET_TOTAL_BUDGET_SPENT } from "queries/budget";
import { GET_NEURON_CAMPAIGN_SUMMARY } from "queries/neuron";
import { GET_TRACKED_LEADS_SUMMARY } from "queries/trackedLeads";
import { READ_ME } from "queries/users";
import { Context } from "../../redux/store";
import { addMargin } from "utils/margin";

import styles from "assets/jss/material-dashboard-pro-react/views/marketingGoalsStyle";

const useStyles = makeStyles(styles);

export default function PricingPage() {
  const [writeReviewDialogOpen, setWriteReviewDialogOpen] = useState(false);
  const [referralDialogOpen, setReferralDialogOpen] = useState(false);
  const [state] = useContext(Context);
  const [accountInfo, setAccountInfo] = useState({});
  const [competitorData, setcompetitorData] = useState({});
  const [productData, setproductData] = useState({});
  const [targetAreaData, settargetAreaData] = useState({});
  const [noFocusProductData, setnoFocusProductData] = useState({});
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const config = isMobile ? {width: "unset"} : {width: "70%", margin: "0 auto"};
  const dateRangeString = state.customers?.dateRangeString;

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  const end_date = moment()
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  const start_date = moment()
    .subtract(1, "M")
    .format("YYYY-MM-DD");
  const customer_id = state.customers?.selected?.id;
  const neuron_id = state.customers?.selected?.neuron_id;
  const call_rail_id = state.customers?.selected?.call_rail_id;

  const { data: me } = useQuery(READ_ME);

  let { data: all_data, refetch } = useQuery(GET_TOP_COMPETITORS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: product_data, refetch: product_refetch } = useQuery(GET_TOP_PRODUCTS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: no_focus_product_data, refetch: no_focus_product_refetch } = useQuery(GET_NO_FOCUS_PRODUCTS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: target_areas_data, refetch: target_areas_data_refetch } = useQuery(GET_TARGET_AREAS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  const { loading: loadingBirdeye, data: birdeye } = useQuery(
    GET_BIRDEYE_CUSTOMERS_AND_LEADS,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date,
        end_date
      }
    }
  );
  const { loading: leadsLoading, data: leadsData } = useQuery(
    GET_TRACKED_LEADS_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date,
        end_date
      }
    }
  );
  const { loading: adsLoading, data: adsData } = useQuery(
    GET_GOOGLE_ADS_INFO_SUMMARY,
    {
      skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
      variables: {
        customer_id: state.customers?.selected?.id,
        start_date,
        end_date,
        filter: "Awareness,TargetedBanner,ReEngage,GeoIntention",
        type_filter: "DISPLAY,VIDEO"
      }
    }
  );
  const { loading: googleLoading, data: googleData } = useQuery(
    GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
    {
      skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
      variables: {
        customer_id: state.customers?.selected?.id,
        start_date,
        end_date
      }
    }
  );
  const { loading: bingLoading, data: bingData } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
      variables: {
        customer_id: state.customers?.selected?.id,
        start_date,
        end_date
      }
    }
  );
  const {
    loading: totalBudgetSpentLoading,
    data: totalBudgetSpentData
  } = useQuery(GET_TOTAL_BUDGET_SPENT, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  const { loading: lsaLoading, data: lsaData } = useQuery(
    GET_GOOGLE_LSA_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date,
        end_date
      }
    }
  );
  const { loading: neuronLoading, data: neuronData } = useQuery(
    GET_NEURON_CAMPAIGN_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        neuron_id,
        start_date,
        end_date
      }
    }
  );
  let { loading, data } = useQuery(GET_MARKETING_GOAL, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  const classes = useStyles();

  const totalCost =
  addMargin((lsaData?.getGoogleLsaSummary?.cost || 0) / 1000000, state, true) +
    addMargin(
      (adsData?.getGoogleAdsInfoSummary?.cost || 0) / 1000000 +
      (googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
      
      (bingData?.getBingCampaignsSummary?.cost || 0) +
      (neuronData?.getNeuronCampaignSummary?.spent || 0),
      state
    ) + totalBudgetSpentData?.getTotalBudgetSpent || 0
  const totalLeads = (leadsData?.getTrackedLeadsSummary?.total_leads || 0) +
    (birdeye?.getBirdeyeCustomersAndLeads?.total || 0);

  const costPerLead =
    totalLeads === 0
      ? "-"
      : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(totalCost / totalLeads);

  customer_data = customer_data?.listCustomers[0];

  useEffect(() => {
    setcompetitorData(all_data?.getTopCompetitors);
  }, [all_data]);

  useEffect(() => {
    setproductData(product_data?.getTopProducts);
  }, [product_data]);

  useEffect(() => {
    setnoFocusProductData(no_focus_product_data?.getNoFocusProducts);
  }, [no_focus_product_data]);

  useEffect(() => {
    settargetAreaData(target_areas_data?.getTargetAreas);
  }, [target_areas_data]);

  const top_competitors_columns = [
    {
      Header: "Top Competitors",
      accessor: "customers_top_competitors"
    },
    {
      Header: "Top Competitor's Url",
      accessor: "top_competitors_url"
    }
  ];

  const top_products_columns = [
    {
      Header: "Products or Services",
      accessor: "product_focus"
    }
  ];

  const no_focus_products_columns = [
    {
      Header: "Products or Services *NOT* to Market",
      accessor: "no_product_focus"
    }
  ];

  const target_areas_columns = [
    {
      Header: "Target Areas",
      accessor: "customer_top_target_area"
    }
  ];

  return (
    <div>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8} lg={12}>
          <h2 className={classes.pageHeader}>Marketing Goals</h2>
          <h4 className={classes.titleDescription}>
            To update the information on this page, please contact your{" "}
            <Link to="/admin/advertising-consultant" className="myTeamLink">
              Advertising Consultant
            </Link>
          </h4>
        </GridItem>
      </GridContainer>
      <br />
      <br />
      <GridContainer justify="center">
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Original Start Date"
          value={state.customers?.selected?.original_start_date}
          render="date"
        />
        <StatsCard
          icon="content_copy"
          loading={
            loading ||
            leadsLoading ||
            adsLoading ||
            googleLoading ||
            bingLoading ||
            totalBudgetSpentLoading
          }
          label="Monthly average cost per lead goal"
          value={data?.getMarketingGoal?.cost_per_lead_goal || 0}
          render="currency"
          milestones={[
            { message: `Your last 30-day cost per lead is ${costPerLead}` }
          ]}
        />
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Your Avg. Sale"
          value={data?.getMarketingGoal?.average_sale_goal || 0}
          render="integer"
        />
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Your Avg. Lifetime Value of a Customer"
          value={data?.getMarketingGoal?.lifetime_value || 0}
          render="integer"
          help="The lifetime value of a customer, represents the total amount of money a customer is expected to spend in your business, or on your products, during their lifetime."
        />
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Monthly leads needed to get an ROI"
          value={data?.getMarketingGoal?.monthly_leads_needed_breakeven || 0}
          render="integer"
          help="Based on your cost per lead, this number represents the number of leads you need to achieve to receive a return on your investment."
          milestones={[{ message: `Last 30 Day Lead Count is ${totalLeads}` }]}
        />
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Monthly leads needed to increase your budget"
          value={data?.getMarketingGoal?.leads_needed_increase_budget || 0}
          render="integer"
          help="This is the number of leads that you identified would be needed for you to increase your campaign budget."
          milestones={[{ message: `Last 30 Day Lead Count is ${totalLeads}` }]}
        />
        {/* <StatsCard
          icon="content_copy"
          loading={loading}
          label="Monthly leads needed to write a review"
          value={data?.getMarketingGoal?.leads_needed_write_review || 0}
          render={(value) => {
            const valueRendered = new Intl.NumberFormat("en-US").format(value);
            if (value <= (data?.getMarketingGoal?.monthly_leads_needed_breakeven || 0) * 1.3) {
              return valueRendered;
            }
            return (
              <Link to="#" style={{ color: "#0071CE" }} onClick={() => { setWriteReviewDialogOpen(true); }}>
                {valueRendered}
              </Link>
            );
          }}
          help="This is the number of leads that you identified would be needed for you to write us a review about your campaign success."
          milestones={[{ message: `Last 30 Day Lead Count is ${totalLeads}` }]}
        />
        <StatsCard
          icon="content_copy"
          loading={loading}
          label="Monthly leads needed to give referrals"
          value={data?.getMarketingGoal?.leads_needed_referral || 0}
          render={(value) => {
            const valueRendered = new Intl.NumberFormat("en-US").format(value);
            if (value <= (data?.getMarketingGoal?.monthly_leads_needed_breakeven || 0) * 1.5) {
              return valueRendered;
            }
            return (
              <Link to="#" style={{ color: "#0071CE" }} onClick={() => { setReferralDialogOpen(true); }}>
                {valueRendered}
              </Link>
            );
          }}
          help="This is the number of leads that you identified would be needed for you to refer your friends to us based on your campaign success."
          milestones={[{ message: `Last 30 Day Lead Count is ${totalLeads}` }]}
        /> */}
        <Dialog
          open={writeReviewDialogOpen}
          onClose={() => setWriteReviewDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Write Review</DialogTitle>
          <DialogContent>
            <WriteReview />
          </DialogContent>
        </Dialog>
        <Dialog
          open={referralDialogOpen}
          onClose={() => setReferralDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Refer Friend</DialogTitle>
          <DialogContent>
            <ReferFriend />
          </DialogContent>
        </Dialog>
      </GridContainer>
      {(productData?.length > 0 || noFocusProductData?.length > 0 || targetAreaData?.length > 0 || competitorData?.length > 0) &&
        (me?.readMe?.user_level === "master" || me?.readMe?.user_level === "content_manager" ||
          me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "ad_consultant" ||
          me?.readMe?.user_level === "campaign_consultant") ?
           <div style={config}>
          <h2 className={classes.pageHeader}>Additional Marketing Information</h2>
          <Card>
            <CardBody>
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {top_products_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {productData?.length > 0 ? productData?.map((datarow, index) => (
                    <TableRow key={datarow?.id}>
                      <TableCell style={{ padding: 0, textAlign: "left" }}>
                        <label
                          id={`customers_top_products${datarow.id}`}
                          key={`customers_top_products${datarow.id}`}
                        >{datarow?.product_focus ? datarow.product_focus : ''}</label>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <br />
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {no_focus_products_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {noFocusProductData?.length > 0 ? noFocusProductData?.map((datarow, index) => (
                    <TableRow key={datarow?.id}>
                      <TableCell style={{ padding: 0, textAlign: "left" }}>
                        <label
                          id={`customers_no_focus_products${datarow.id}`}
                          key={`customers_no_focus_products${datarow.id}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        >{datarow?.no_product_focus ? datarow.no_product_focus : ''}</label>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <br />
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {target_areas_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {targetAreaData?.length > 0 ? targetAreaData?.map((datarow, index) => (
                    <TableRow key={datarow?.id}>
                      <TableCell style={{ padding: 0, textAlign: "left" }}>
                        <label
                          id={`customers_target_areas${datarow.id}`}
                          key={`customers_target_areas${datarow.id}`}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        >{datarow?.customer_top_target_area ? datarow.customer_top_target_area : ''}</label>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader>
                  <TableRow>
                    {top_competitors_columns.map((datacolumns) => (
                      <TableCell style={{ textAlign: "center" }}><label className={classes.selectFormControl} >{datacolumns?.Header}</label></TableCell>
                    ))}
                  </TableRow>
                  {competitorData?.length > 0 ? competitorData?.map((datarow, index) => (
                    <TableRow key={datarow?.id}>
                      <TableCell style={{ padding: 0, textAlign: "left" }}>
                        <label
                          id={`customers_top_competitors${datarow.id}`}
                          key={datarow.id}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        >{datarow?.customers_top_competitors ? datarow.customers_top_competitors : ''}</label>
                      </TableCell>
                      <TableCell style={{ padding: 0, textAlign: "left" }}>
                        <label
                          id={`datarow${datarow.id}`}
                          key={datarow.id}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        >{datarow?.top_competitors_url ? datarow.top_competitors_url : ''}</label>
                      </TableCell>
                    </TableRow>
                  )) : ""}
                </Table>
              </TableContainer>
              <br />
              <br />
            </CardBody>
          </Card>
          {customer_data?.timezone?.length > 0 ?
            <div style={{ margin: "0 auto" }}>
              <h2 className={classes.pageHeader}>Hours of Operation</h2>
              <h5 style={{ textAlign: "center" }}>Time Zone: {customer_data?.timezone}</h5>
              <Card>
                <CardBody>
                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                    <Table stickyHeader>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Sunday Open:  {customer_data?.sunday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Sunday Close:  {customer_data?.sunday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Monday Open:  {customer_data?.monday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Monday Close:  {customer_data?.monday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Tuesday Open:  {customer_data?.tuesday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Tuesday Close:  {customer_data?.tuesday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Wednesday Open:  {customer_data?.wednesday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Wednesday Close:  {customer_data?.wednesday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Thursday Open:  {customer_data?.thursday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Thursday Close:  {customer_data?.thursday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Friday Open:  {customer_data?.friday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Friday Close:  {customer_data?.friday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Friday Open:  {customer_data?.friday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Friday Close:  {customer_data?.friday_close}</label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0, textAlign: "left" }}>
                          <label>Saturday Open:  {customer_data?.saturday_open}</label>
                        </TableCell>
                        <TableCell style={{ padding: 0, textAlign: "right" }}>
                          <label>Saturday Close:  {customer_data?.saturday_close}</label>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </CardBody>
              </Card>
            </div> : ""}            
        </div> : ""}
    </div>    
  );
}
