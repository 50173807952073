import React, { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { sumBy } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import HydrateNoContentWrapper from "components/HydrateNoContentWrapper";
import StatsCard from "components/StatsCard";
import { Context } from "../../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import HydrateSub from "./HydrateSub";
import Hydrate from "./Hydrate";

import Assignment from "@mui/icons-material/Assignment";

import {
  LIST_HYDRATE_TEXT_REPORT,
  LIST_HYDRATE_MMS_REPORT,
  LIST_HYDRATE_VOICE_REPORT,
  LIST_HYDRATE_EMAIL_REPORT,
  LIST_HYDRATE_LETTER_REPORT,
  LIST_HYDRATE_POSTCARD_REPORT,
  LIST_HYDRATE_EMAIL_DETAIL_REPORT
} from "queries/hydrate";
import { formatPhone } from "utils/format";

const normalizeString = (str, postfix = "") => (str ? `${str}${postfix} ` : "");

const moment = require("moment");


styles.cardIconTitle = {
  ...cardTitle,
  marginTop: "15px",
  marginBottom: "0px"
};

const useStyles = makeStyles(styles);

const Summary = () => {
  const classes = useStyles();
  const [info, setInfo] = useState({});
  const [state] = useContext(Context);  
  const customer_id = state.customers?.selected?.id;
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const compareDateRange = getPrevious(dateRangeString, compareOption);

  const data = [
    {
      title: "Text",
      heading: "Text",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_TEXT_REPORT,
      queryKey: "listHydrateTextReport",
      help: "Text | approx $0.044 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "Message Parts",
          accessor: "message_parts",
          Cell: ({ original }) => (original?.message_parts ? "Yes" : "-")
        },
        {
          Header: "From",
          accessor: "from",
          Cell: ({ original }) => formatPhone(original?.from) || ""
        },
        {
          Header: "To",
          accessor: "to",
          Cell: ({ original }) => 
            original?.to ?
            <a
              href="#"
              rel="noopener noreferrer"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleOpen(original);
              }}
            >
              {formatPhone(original?.to)}
            </a>
            : <>&nbsp;</>
        },
        {
          Header: "Body",
          accessor: "body",
          width: 300
        },
        {
          Header: "Spend",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Status Text",
          accessor: "status_text"
        },
        {
          Header: "Error",
          accessor: "error_text",
          Cell: ({ original }) => original?.status === "Sent" ? "" : original?.error_text
        }
      ]
    },
    {
      title: "MMS",
      heading: "MMS",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_MMS_REPORT,
      queryKey: "listHydrateMMSReport",
      help: "MMS | approx $0.09 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total MMS Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "Message Parts",
          accessor: "message_parts",
          Cell: ({ original }) => (original?.message_parts ? "Yes" : "-")
        },
        {
          Header: "From",
          accessor: "from",
          Cell: ({ original }) => formatPhone(original?.from) || ""
        },
        {
          Header: "Phone Number",
          accessor: "to",
          Cell: ({ original }) => formatPhone(original?.to) || ""
        },
        {
          Header: "Message",
          accessor: "body",
          width: 300
        },
        {
          Header: "Spend",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        }
      ]
    },
    {
      title: "Voice",
      heading: "Voice",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_VOICE_REPORT,
      queryKey: "listHydrateVoiceReport",
      help: "Voice Message | approx $0.02 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Voice Messages",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date_created",
          width: 200,
          Cell: ({ original }) => moment.utc(original?.date_created).format("MM-DD-YYYY hh:mm:ss A") || ""
        },
        {
          Header: "From",
          accessor: "from_formatted",
          Cell: ({ original }) => original?.from_formatted || ""
        },
        {
          Header: "Phone Number",
          accessor: "to_formatted",
          Cell: ({ original }) => original?.to_formatted || ""
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Type",
          accessor: "direction"
        },
        {
          Header: "Duration in Seconds",
          accessor: "duration"
        },
        {
          Header: "Cost",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: original?.price_unit,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        }
      ]
    },
    {
      title: "Email",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_EMAIL_REPORT,
      queryKey: "listHydrateEmailReport",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "delivered",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Emails",
          name: "delivered",
          render: "integer"
        },
        {
          icon: "attach_money",
          label: "Bounces",
          name: "bounces",
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Opens",
          name: "opens",
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Clicks",
          name: "clicks",
          render: "integer"
        },
        {
          icon: "donut_small",
          label: "Unsubscribes",
          name: "unsubscribes",
          render: "integer"
        },
      ]
    },
    {
      heading: "Email",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_EMAIL_DETAIL_REPORT,
      queryKey: "listHydrateEmailDetailsReport",
      help: "Email | approx $0.004 ea",
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "From",
          accessor: "from_email"
        },
        {
          Header: "To",
          accessor: "to_email"
        },
        {
          Header: "Subject",
          accessor: "subject"
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Opens",
          accessor: "opens_count"
        },
        {
          Header: "Clicks",
          accessor: "clicks_count"
        },
        {
          Header: "Last Event",
          accessor: "last_event_time",
          width: 100,
          Cell: ({ original }) =>
            new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(original.last_event_time)
        }
      ]
    },
    {
      title: "Letter",
      heading: "Letter",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_LETTER_REPORT,
      queryKey: "listHydrateLetterReport",
      help: "Letter | approx. $1.24 ea. + color approx. $1.53 ea. + priority $.504",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "post_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Letters Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Name",
          accessor: "address_name",
          Cell: ({ original }) => normalizeString(original?.address_name)
        },
        {
          Header: "Address line 1",
          accessor: "address_line_1",
          Cell: ({ original }) => normalizeString(original?.address_line_1)
        },
        {
          Header: "Address line 2",
          accessor: "address_line_2",
          Cell: ({ original }) => normalizeString(original?.address_line_2)
        },
        {
          Header: "City",
          accessor: "address_city",
          Cell: ({ original }) => normalizeString(original?.address_city)
        },
        {
          Header: "State",
          accessor: "address_state",
          Cell: ({ original }) => normalizeString(original?.address_state)
        },
        {
          Header: "Zip",
          accessor: "address_postal_code",
          Cell: ({ original }) => normalizeString(original?.address_postal_code)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: ({ original }) => (original?.color ? "Yes" : "No")
        },
        {
          Header: "Two Sided",
          accessor: "duplex",
          Cell: ({ original }) => (original?.duplex ? "Yes" : "No")
        },
        {
          Header: "Number of Pages",
          accessor: "post_pages"
        },
        {
          Header: "Cost",
          accessor: "post_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.post_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Date",
          accessor: "date"
        }
      ]
    },
    {
      title: "Postcard",
      heading: "Postcard",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_POSTCARD_REPORT,
      queryKey: "listHydratePostcardReport",
      help: "Postcard | approx $1.11 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "post_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Postcards Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Name",
          accessor: "address_name",
          Cell: ({ original }) => normalizeString(original?.address_name)
        },
        {
          Header: "Address line 1",
          accessor: "address_line_1",
          Cell: ({ original }) => normalizeString(original?.address_line_1)
        },
        {
          Header: "Address line 2",
          accessor: "address_line_2",
          Cell: ({ original }) => normalizeString(original?.address_line_2)
        },
        {
          Header: "City",
          accessor: "address_city",
          Cell: ({ original }) => normalizeString(original?.address_city)
        },
        {
          Header: "State",
          accessor: "address_state",
          Cell: ({ original }) => normalizeString(original?.address_state)
        },
        {
          Header: "Zip",
          accessor: "address_postal_code",
          Cell: ({ original }) => normalizeString(original?.address_postal_code)
        },
        {
          Header: "Country",
          accessor: "address_country",
          Cell: ({ original }) => normalizeString(original?.address_country)
        },
        {
          Header: "Cost",
          accessor: "post_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.post_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Date",
          accessor: "date"
        },
      ]
    }
  ];
  const summaryData = data[0].cardFields.map(
    ({ name, icon, render, label }, index) => {
      const loading = data.some(({ title }) => info[title]?.loading);
      const loadingCompare = data.some(
        ({ title }) => info[title]?.loadingCompare
      );
      let sum = sumBy(data, ({ title, cardFields }) => {
        if(cardFields === undefined )
          return 0;
        const { name, margin } = cardFields[index];        
        let sum = sumBy(info[title]?.data || [], name);
        if(title === "Email" && label === "Total Spent")
        {
          sum = sum * .001;
          if(sum > 0 && sum < .01)
            sum = .01;
        }
        if (margin) {
          return addMargin(sum || 0, state);
        }
        return sum || 0;
      });
      const sumCompare = sumBy(data, ({ title, cardFields }) => {
        if(cardFields === undefined)
          return 0;
        const { name, margin } = cardFields[index];
        let sum = sumBy(info[title]?.compareData || [], name);
        if(title === "Email" && label === "Total Spent")
        {
          sum = sum * .001;
          if(sum > 0 && sum < .01)
            sum = .01;
        }
        if (margin) {
          return addMargin(sum || 0, state);
        }
        return sum || 0;
      });

      return (
        <StatsCard
          icon={icon}
          loading={loading}
          label={label}
          value={sum}
          render={render}
          previous
          footer={loadingCompare ? "..." : compareOption ? sumCompare : ""}
          footerRender
          key={name}
        />
      );
    }
  );

  return (
    <HydrateNoContentWrapper>
      <div>
        <h2 className={classes.pageHeader}>All Hydrate Campaigns</h2>
        {data.map(({ title, ...rest }) => (
          <HydrateSub
            dateRangeString={dateRangeString}
            compareOption={compareOption}
            compareDateRange={compareDateRange}
            customer_id={customer_id}
            state={state}
            {...rest}
            callback={newInfo =>
              setInfo({
                ...info,
                [title]: newInfo
              })
            }
            info={info[title]}
            key={title}
          />
        ))}
        <GridContainer>{summaryData}</GridContainer>
      </div>
      <Hydrate classes={classes} />
    </HydrateNoContentWrapper>
  );
};

export default Summary;
