import gql from "graphql-tag";

export const GET_NEURON_CAMPAIGN_SUMMARY = gql`
  query getNeuronCampaignSummary(
    $neuron_id: String
    $start_date: String
    $end_date: String
  ) {
    getNeuronCampaignSummary(
      neuron_id: $neuron_id
      start_date: $start_date
      end_date: $end_date
    ) {
      budget
      spent
      impressions
      eCPM
      playouts
    }
  }
`;

export const LIST_HOME_DETAILS = gql`
  query listHomeDetails(
    $neuron_id: String
    $start_date: String
    $end_date: String
  ) {
    listHomeDetails(
      neuron_id: $neuron_id
      start_date: $start_date
      end_date: $end_date
    ) {
      name
      date
      startDate
      endDate
      impressions
      playouts
    }
  }
`;

export const LIST_LINE_ITEM_SCREENS = gql`
  query listLineItemScreens(
    $neuron_id: String
    $start_date: String
    $end_date: String
  ) {
    listLineItemScreens(
      neuron_id: $neuron_id
      start_date: $start_date
      end_date: $end_date
    ) {
      name
      date
      publisherName
      street
      city
      region
      latitude
      longitude
      audiencePerMonth
      width
      height
    }
  }
`;

export const LIST_OUT_OF_HOME_ASSETS = gql`
  query listOutOfHomeAssets(
    $neuron_id: String
    $start_date: String
    $end_date: String
  ) {
    listOutOfHomeAssets(
      neuron_id: $neuron_id
      start_date: $start_date
      end_date: $end_date
    ) {
      type
      name
      width
      height
      url
    }
  }
`;
