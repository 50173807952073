import gql from "graphql-tag";

export const LOGIN = gql`
  mutation($email: String, $password: String) {
    login(email: $email, password: $password) {
      error
      token
      user {
        id
        user_level
        crm_user_level
        commission
        customer_id
        customer {
          margin
          agency_margin
        }
      }
    }
  }
`;

export const REQUEST_RESET_PASSWORD = gql`
  mutation($email: String, $signup: Int) {
    requestResetPassword(email: $email, signup: $signup)
  }
`;

export const SEND_CUSTOMER_ONBOARDING_EMAIL = gql`
  mutation($email: String, $customer: Int, $id: Int, $peopleid: Int, $contractid: Int, $onboarding_id: Int) {
    SendCustomerOnboardingEmail(email: $email, customer: $customer, id: $id, peopleid: $peopleid, contractid: $contractid, onboarding_id: $onboarding_id)
  }
`;

export const RESET_PASSWORD = gql`
  mutation($email: String, $password: String, $code: String) {
    resetPassword(email: $email, password: $password, code: $code)
  }
`;
