//ui
export const SELECT_COMPARE_OPTION = "ui/SELECT_COMPARE_OPTION";

//auth
export const SET_TOKEN = "auth/SET_TOKEN";

// customers
export const SET_CUSTOMERS = "customers/SET_CUSTOMERS";
export const SELECT_CUSTOMER = "customers/SELECT_CUSTOMER";
export const UPDATE_CUSTOMER_SELECT = "customers/UPDATE_CUSTOMER_SELECT";
export const ADD_CUSTOMER_SELECT = "customers/ADD_CUSTOMER_SELECT";
export const SET_DATE_RANGE = "customers/SET_DATE_RANGE";

// users
export const SET_USERS = "users/SET_USERS";
export const SELECT_USER = "users/SELECT_USER";
