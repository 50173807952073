import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";

import Button from '@mui/material/Button';
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Dropzone from "components/Dropzone";
import Avatar from "components/Avatar";
import CardAvatar from "components/Card/CardAvatar";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

import { READ_ME } from "queries/users";

import {
  UPDATE_CUSTOMER_REPRESENTATIVES
} from "queries/customers";
import { Context } from "../../../redux/store";

const styles = theme => ({
  stepIcon: {
    color: "#0071ce"
  },
  sidebyside: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  paper: { minWidth: "60%" },
  cursor: { cursor: 'pointer' }
});

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

const UpdateRepresentativeProfile = ({ handleClose, setCustomers, customers, current, updated, refetch }) => {
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const me = useQuery(READ_ME)?.data?.readMe;
  const classes = useStyles(styles);
  const [submissionData, setSubmissionData] = useState(null);
  const customer_id = state.customers?.selected?.id;

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  useEffect(() => {
    setSubmissionData(current);
  }, [current]);

  const handleSave = (fromgotoStep) => {
    if (submissionData?.first_name === undefined || submissionData?.first_name === "") {
      alert("First Name is required");
      return false;
    }
    if (submissionData?.last_name === undefined || submissionData?.last_name === "") {
      alert("Last Name is required");
      return false;
    }
    if ((submissionData?.cell_phone_number === undefined || submissionData?.cell_phone_number === "") &&
      (submissionData?.email === undefined || submissionData?.email === "")) {
      alert("Phone or Email is required");
      return false;
    }

    submitUpdateRequest({
      variables: {
        customer_id,
        CustomerRepresentative: {
          ...omit(submissionData, ["__typename"]),
          cell_phone_number: `${submissionData?.cell_phone_number !== undefined ? submissionData?.cell_phone_number : ""}`
            ?.replaceAll("(", "")
            ?.replaceAll(")", "")
            ?.replaceAll(" ", "")
            ?.split("-")
            .join(""),
          office_phone_number: `${submissionData?.office_phone_number !== undefined ? submissionData?.office_phone_number : ""}`
            ?.replaceAll("(", "")
            ?.replaceAll(")", "")
            ?.replaceAll(" ", "")
            ?.split("-")
            .join("")
        }
      }
    });

    handleClose(submissionData);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_REPRESENTATIVES);

  return (
    <div style={{ minWidth: "60%" }}>
      <Dialog open onClose={() => handleClose()} classes={{ paper: classes.paper }}>
        <DialogContent>
          <div style={{ float: "right" }}><Button onClick={() => handleClose()}><CloseIcon /></Button></div>
          <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{submissionData?.first_name || current?.first_name || ""}&nbsp;{submissionData?.last_name || current?.last_name || ""}</b></h2>
          <div>
              <>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "30px" }}>
                    <Card profile>
                      <CardAvatar profile>
                        <Dropzone
                          path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                          accept="image/png, image/jpeg"
                          onChange={value => updateFieldHandler("profile_image")({ target: { value } })}
                          setuploadPercentage={setuploadPercentage}
                        >
                          {({ loading }) => (
                            <Avatar
                              loading={loading}
                              url={submissionData?.profile_image || current?.profile_image || ""}
                              size={130}
                            />
                          )}
                        </Dropzone>
                      </CardAvatar>
                      <CardBody>
                        <h6 className={classes.cardCategory}>
                          Ideal image upload size is 400 x 400
                        </h6>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}
                    >First Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="first_name"
                      maxLength={45}
                      type="text"
                      defaultValue={submissionData?.first_name || current?.first_name || ""}
                      onChange={updateFieldHandler("first_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Last Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="last_name"
                      maxLength={45}
                      type="text"
                      defaultValue={submissionData?.last_name || current?.last_name || ""}
                      onChange={updateFieldHandler("last_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Email&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="email"
                      maxLength={155}
                      type="text"
                      defaultValue={submissionData?.email || current?.email || ""}
                      onChange={updateFieldHandler("email")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Cell Phone&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="cell_phone_number"
                      type={"string"}
                      maxLength={20}
                      defaultValue={submissionData?.cell_phone_number || current?.cell_phone_number || ""}
                      onChange={updateFieldHandler("cell_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Phone</label>
                    <input
                      id="office_phone_number"
                      maxLength={20}
                      type="text"
                      defaultValue={submissionData?.office_phone_number || current?.office_phone_number || ""}
                      onChange={updateFieldHandler("office_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Extension</label>
                    <input
                      id="office_extension"
                      type="text"
                      maxLength={12}
                      defaultValue={submissionData?.office_extension || current?.office_extension || ""}
                      onChange={updateFieldHandler("office_extension")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <label
                      style={{ marginTop: "30px" }}>Bio</label>
                    <textarea
                      id="notes"
                      type="text"
                      defaultValue={submissionData?.bio || current?.bio || ""}
                      onChange={updateFieldHandler("bio")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Job Title</label>
                    <input
                      id="job_title"
                      maxLength={255}
                      type="text"
                      defaultValue={submissionData?.job_title || current?.job_title || ""}
                      onChange={updateFieldHandler("job_title")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => handleSave(false)}>
                    Save
                  </Button>
                </div>
              </>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateRepresentativeProfile;