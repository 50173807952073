import React, { useContext } from "react";

import NoContentWrapper from "components/NoContentWrapper";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../redux/store";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

const HydrateNoContentWrapper = ({ title, children, noTemplate = false }) => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  return (
    <NoContentWrapper
      skip={!customer_id}
      queries={[
        {
          name: "budget",
          query: GET_BUDGET,
          options: { variables: { customer_id, product_line: "hydrate" } }
        }
      ]}
      product="Hydrate"
      title={title}
      isLoading={({ budget }) => budget?.loading}
      hasNoData={({ budget }) => {
        let hydratebudget = budget?.data?.getBudget;
        const spent = Number(hydratebudget?.monthly_budget || 0);
        if (spent > 0) {
          return true;
        }
        return false;
      }}
      noContent="Water Bear Marketing ensures that you can reach your prospective leads and existing customers using Hydrate. We provide efficient ways to communicate with your customer as they move through your buying funnel and customer lifecycle. Our proprietary technology helps us, help you maximize your return on investment and improve communication with your potential customers or existing customers. With our Hydrate product, our goal is to generate revenue for our clients that meets or exceeds their exceptions in the most seamless way possible."
      noTemplate={noTemplate}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NoContentWrapper>
  );
};

export default HydrateNoContentWrapper;
