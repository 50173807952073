import React, { useContext } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Icon from "@mui/material/Icon";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import { LIST_BING_AD_GROUPS } from "queries/bing";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const BingAdsGroup = () => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data } = useQuery(LIST_BING_AD_GROUPS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const classes = useStyles();
  data = data?.listBingAdGroups || [];

  const columns = [
    {
      Header: "Name",
      accessor: "ad_group_name"
    },
    {
      Header: "Cost",
      accessor: "cost",
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin(original?.cost || 0, state))
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US").format(original?.impressions || 0)
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US").format(original?.clicks || 0)
    },
    {
      Header: "CTR",
      accessor: "ctr",
      Cell: ({ original }) =>
        `${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(
          (original?.clicks || 0) / (original?.impressions || 1) / 0.01
        )}%`
    },
    {
      Header: "CPC",
      accessor: "avg_cpc",
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin((original?.cost || 0) / (original?.clicks || 0), state)
        )
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="google" icon>
            <CardIcon>
              <Icon>list</Icon>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Bing Ad Group Breakdown
              <HelpTooltip>
                {`An ad group contains one or more ads that share similar targets. Ad groups are used to organize your ads by a common theme.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Bing Ads Group (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(10, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default BingAdsGroup;
