import React from "react";
import { withStyles } from "@mui/styles";
import Checkbox from '@mui/material/Checkbox';

const CustomCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#0071CE"
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
