import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { useNavigate, useOutletContext } from 'react-router-dom';
import { omit } from "lodash";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from 'react-responsive';

import { useSnackbar } from "notistack";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SocialSelect from "components/Select/Select";
import TableContainer from '@mui/material/TableContainer';
import CustomInput from "components/CustomInput/CustomInput";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import PropTypes from 'prop-types';
import Dropzone from "components/Dropzone";
import UploadInput from "components/UploadInput";
import LinearProgress from '@mui/material/LinearProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoo, faFaceParty, faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import { REQUEST_RESET_PASSWORD, SEND_CUSTOMER_ONBOARDING_EMAIL } from "queries/auth";

import {
  CREATE_QUICKBOOK_CUSTOMER
} from "queries/quickbooks";

import {
  CREATE_CARD,
  REMOVE_CARD,
  LIST_CARDS,
  CREATE_BANK,
  REMOVE_BANK,
  LIST_BANKS
} from "queries/quickbooks";
import { SET_DEFAULT_PAYMENT_ID } from "queries/users";
import { Context } from "../../../redux/store";
import { READ_ME, READ_USER_BY_EMAIL } from "queries/users";
import FormatPhone from "../Format/FormatPhone";

import BankAccountForm from "../Payment/BankAccountForm";
import CreditCardForm from "../Payment/CreditCardForm";
import NavItem from "../Payment/NavItem";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/modifyMarketingGoalsStyle";

import {
  UPDATE_CRM_ACTIVITY,
  UPDATE_ONBOARDING_TABLE_DETAILS,
  LIST_ONBOARDING_TABLE_DETAILS,
  LIST_CUSTOMER_WEBSITE_DESIGN,
  CREATE_CUSTOMER_WEBSITE_DESIGN,
  LIST_WEBSITE_ACCESS,
  CREATE_CUSTOMER_WEBSITE_ACCESS,
  DELETE_COMPANY_PHOTO_OR_LOGO,
  LIST_BRANDS_COLORS_AND_FONTS,
  UPDATE_BRANDS_COLORS_AND_FONTS,
  LIST_BRAND_PHOTOS_AND_LOGOS,
  CREATE_CUSTOMER_PHOTOS_AND_LOGOS_GALLERY,
  GET_CUSTOMER_MARKETING_GOAL,
  UPDATE_CUSTOMER_MARKETING_GOAL,
  LIST_CUSTOMERS,
  CRM_CUSTOMERS,
  UPDATE_CRM_CUSTOMERS,
  CREATE_WATER_BEAR_MARKETING_CUSTOMERS,
  LIST_CUSTOMER_EMAILS,
  LIST_CONTRACTS,
  CREATE_WATER_BEAR_MARKETING_USER,
  UPDATE_CUSTOMER_HOURS,
  LIST_MONTHY_BUDGETS,
  GET_TOP_COMPETITORS,
  GET_TOP_PRODUCTS,
  GET_NO_FOCUS_PRODUCTS,
  GET_TARGET_AREAS,
  GET_SERVICE_AREAS,
  GET_CUSTOMER_BRANDS,
  GET_CERTIFICATES,
  GET_LICENSES,
  GET_AWARDS,
  GET_COUPONS,
  GET_FAQS,
  GET_TARGET_INDUSTRIES,
  GET_TARGET_JOB_TITLES,
  GET_TARGET_INTERESTS,
  GET_ONLINE_VIDEOS,
  GET_WEBSITE_INSPIRATION,
  GET_BLOG_TOPIC_IDEAS,
  GET_LOCAL_PAGES,
  GET_TRACKING_INFO,
  GET_ADDITIONAL_COLORS,
  GET_VIDEO_INSPIRATION,
  UPDATE_CUSTOMER_PRODUCTS,
  UPDATE_CUSTOMER_COMPETITORS,
  UPDATE_CUSTOMER_NO_FOCUS_PRODUCTS,
  UPDATE_CUSTOMER_BRANDS,
  UPDATE_CUSTOMER_CERTIFICATES,
  UPDATE_CUSTOMER_TARGET_AREAS,
  UPDATE_CUSTOMER_SERVICE_AREAS,
  UPDATE_CUSTOMER_LICENSES,
  UPDATE_CUSTOMER_AWARDS,
  UPDATE_CUSTOMER_COUPONS,
  UPDATE_CUSTOMER_FAQ,
  UPDATE_CUSTOMER_TARGET_INDUSTRIES,
  UPDATE_CUSTOMER_TARGET_JOB_TITLES,
  UPDATE_CUSTOMER_TARGET_INTERESTS,
  LIST_CURRENT_SOCIALS,
  UPDATE_CURRENT_SOCIALS,
  LIST_ALL_SOCIALS,
  REMOVE_CUSTOMER_SOCIAL,
  UPDATE_CUSTOMER_ONLINE_VIDEOS,
  UPDATE_CUSTOMER_WEBSITE_INSPIRATION,
  UPDATE_CUSTOMER_BLOG_TOPIC_IDEAS,
  UPDATE_CUSTOMER_LOCAL_PAGES,
  UPDATE_CUSTOMER_TRACKING_INFO,
  UPDATE_CUSTOMER_ADDITIONAL_COLORS,
  UPDATE_CUSTOMER_VIDEO_INSPIRATION
} from "queries/customers";

const moment = require("moment");

const useStyles = makeStyles(styles);

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}

const OnBoardingForm = (props) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state, dispatch] = useContext(Context);
  const [marketingGoal, setMarketingGoal] = useState({});
  const [CRMNewCustomerID, setCRMNewCustomerID] = useState(null);
  const [tryedToGetCustomerIdFromCustomersTable, settryedToGetCustomerIdFromCustomersTable] = useState(false);
  const [WaterBearMarketingUser, setWaterBearMarketingUser] = useState(false);
  const [AccountInfo, setAccountInfo] = useState([]);
  const [user_id, setuser_id] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [competitorData, setcompetitorData] = useState([]);
  const [websiteinspirationData, setwebsiteinspirationData] = useState([]);
  const [videoinspirationData, setvideoinspirationData] = useState([]);
  const [localPagesData, setlocalPagesData] = useState([]);
  const [TrackingInfoData, setTrackingInfoData] = useState([]);
  const [blogtopicideasData, setblogtopicideasData] = useState([]);
  const [licenseData, setlicenseData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [additionalcolorsData, setadditionalcolorsData] = useState([]);
  const [awardData, setawardData] = useState([]);
  const [couponData, setcouponData] = useState([]);
  const [faqData, setfaqData] = useState([]);
  const [certificateData, setcertificateData] = useState([]);
  const [brandData, setbrandData] = useState([]);
  const [targetAreaData, settargetAreaData] = useState([]);
  const [ServiceAreaData, setServiceAreaData] = useState([]);
  const [noFocusProductData, setnoFocusProductData] = useState([]);
  const [targetinginterestsData, settargetinginterestsData] = useState([]);
  const [targetjobtitleData, settargetjobtitleData] = useState([]);
  const [targetindustryData, settargetindustryData] = useState([]);
  const [onlinevideoData, setonlinevideoData] = useState([]);
  const [duplicateHighPriorityLocations, setduplicateHighPriorityLocations] = useState(false);
  const [duplicateServiceAreas, setduplicateServiceAreas] = useState(false);
  const [duplicateAges, setduplicateAges] = useState(false);
  const [duplicateTopProducts, setduplicateTopProducts] = useState(false);
  const [duplicateNoFocusProducts, setduplicateNoFocusProducts] = useState(false);
  const [duplicateLeadGoals, setduplicateLeadGoals] = useState(false);
  const [duplicateTargeting, setduplicateTargeting] = useState(false);
  const [LogoGallerysubmissionData, setLogoGallerysubmissionData] = useState([]);
  const [CustomerPhotoGallerysubmissionData, setCustomerPhotoGallerysubmissionData] = useState(null);
  const [BrandColorsAndFontssubmissionData, setBrandColorsAndFontssubmissionData] = useState(null);
  const [CurrentWebsiteAccessssubmissionData, setCurrentWebsiteAccessssubmissionData] = useState(null);

  const [highestSocialID, sethighestSocialID] = useState(0);
  const [highestCompetitorID, sethighestCompetitorID] = useState(0);
  const [highestWebsiteInspirationID, sethighestWebsiteInspirationID] = useState(0);
  const [highestVideoInspirationID, sethighestVideoInspirationID] = useState(0);
  const [highestLocalPageID, sethighestLocalPageID] = useState(0);
  const [highestTrackingInfoID, sethighestTrackingInfoID] = useState(0);
  const [highestBlogTopicIdeaID, sethighestBlogTopicIdeaID] = useState(0);
  const [highestProductID, sethighestProductID] = useState(0);
  const [highestAdditionalColorID, sethighestAdditionalColorID] = useState(0);
  const [highestOnlineVideoID, sethighestOnlineVideoID] = useState(0);
  const [highestLicenseID, sethighestLicenseID] = useState(0);
  const [highestAwardID, sethighestAwardID] = useState(0);
  const [highestCouponID, sethighestCouponID] = useState(0);
  const [highestFAQID, sethighestFAQID] = useState(0);
  const [highestNoFocusProductID, sethighestNoFocusProductID] = useState(0);
  const [highestmarketingGoalID, sethighestmarketingGoalID] = useState(0);
  const [highestTargetingInterestID, sethighestTargetingInterestID] = useState(0);
  const [highestTargetJobTitleID, sethighestTargetJobTitleID] = useState(0);
  const [highestTargetIndustryID, sethighestTargetIndustryID] = useState(0);
  const [highestBrandID, sethighestBrandID] = useState(0);
  const [highestCertID, sethighestCertID] = useState(0);
  const [highestTopTargetAreasID, sethighestTopTargetAreasID] = useState(0);
  const [highestServiceAreasID, sethighestServiceAreasID] = useState(0);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [GotAllBusinessInfoRequiredState, setGotAllBusinessInfoRequiredState] = useState(false);
  const [GotAllDashboardUserInfoRequiredState, setGotAllDashboardUserInfoRequiredState] = useState(false);
  const [GotOnePaymentMethodRequiredState, setGotOnePaymentMethodRequiredState] = useState(false);
  const [CRMMonthlyBudget, setCRMMonthlyBudget] = useState(null);

  const [PaidSearch, setPaidSearch] = useState(false);
  const [Hydrate, setHydrate] = useState(false);
  const [BannerAdsGeoDemo, setBannerAdsGeoDemo] = useState(false);
  const [BannerAdsGeoIntention, setBannerAdsGeoIntention] = useState(false);
  const [BannerAdsReEngage, setBannerAdsReEngage] = useState(false);
  const [LocalServicesAds, setLocalServicesAds] = useState(false);
  const [OrganicSearchTwo, setOrganicSearchTwo] = useState(false);
  const [OrganicSearchFour, setOrganicSearchFour] = useState(false);
  const [OrganicSearchRegional, setOrganicSearchRegional] = useState(false);
  const [OrganicSearchAdditionalBusinessListingManagement, setOrganicSearchAdditionalBusinessListingManagement] = useState(false);
  const [HostingMonthlyManagedServices, setHostingMonthlyManagedServices] = useState(false);
  const [WebsiteDesign, setWebsiteDesign] = useState(false);
  const [WebsiteDesignWooCommerce, setWebsiteDesignWooCommerce] = useState(false);
  const [OfflineTracking, setOfflineTracking] = useState(false);
  const [OnlineVideoGeoDemo, setOnlineVideoGeoDemo] = useState(false);
  const [OnlineVideoReEngage, setOnlineVideoReEngage] = useState(false);
  const [OnlineVideoGeoIntention, setOnlineVideoGeoIntention] = useState(false);
  const [OCAProduction, setOCAProduction] = useState(false);
  const [OCAProductionTwo, setOCAProductionTwo] = useState(false);
  const [OCAProductionThree, setOCAProductionThree] = useState(false);
  const [Chat, setChat] = useState(false);
  const [SocialAdsReEngage, setSocialAdsReEngage] = useState(false);
  const [OutOfHome, setOutOfHome] = useState(false);
  const [LinearCRM, setLinearCRM] = useState(false);
  const [OCAGeoDemo, setOCAGeoDemo] = useState(false);
  const [OCAReEngage, setOCAReEngage] = useState(false);
  const [OCAGeoIntention, setOCAGeoIntention] = useState(false);

  const [ages_18_24, setages_18_24] = useState([]);
  const [ages_25_34, setages_25_34] = useState([]);
  const [ages_35_44, setages_35_44] = useState([]);
  const [ages_45_54, setages_45_54] = useState([]);
  const [ages_55_64, setages_55_64] = useState([]);
  const [ages_65_plus, setages_65_plus] = useState([]);
  const [ages_no_restrictions, setages_no_restrictions] = useState([]);
  const [ages_unknown, setages_unknown] = useState([]);

  const [income_top_10_percent, setincome_top_10_percent] = useState([]);
  const [income_11_20_percent, setincome_11_20_percent] = useState([]);
  const [income_21_30_percent, setincome_21_30_percent] = useState([]);
  const [income_31_40_percent, setincome_31_40_percent] = useState([]);
  const [income_41_50_percent, setincome_41_50_percent] = useState([]);
  const [income_lower_50_percent, setincome_lower_50_percent] = useState([]);
  const [income_unknown, setincome_unknown] = useState([]);

  const [gender_no_restrictions, setgender_no_restrictions] = useState([]);
  const [gender_male, setgender_male] = useState([]);
  const [gender_female, setgender_female] = useState([]);

  const [not_a_parent, setnot_a_parent] = useState([]);
  const [parent_true, setparent_true] = useState([]);
  const [unknown_parent, setunknown_parent] = useState([]);

  const [PaidSearch_id, setPaidSearch_id] = useState(14);
  const [Hydrate_id, setHydrate_id] = useState(1);
  const [BannerAdsGeoDemo_id, setBannerAdsGeoDemo_id] = useState(2);
  const [BannerAdsGeoIntention_id, setBannerAdsGeoIntention_id] = useState(3);
  const [BannerAdsReEngage_id, setBannerAdsReEngage_id] = useState(12);
  const [LocalServicesAds_id, setLocalServicesAds_id] = useState(13);
  const [OrganicSearchTwo_id, setOrganicSearchTwo_id] = useState(15);
  const [OrganicSearchFour_id, setOrganicSearchFour_id] = useState(16);
  const [OrganicSearchRegional_id, setOrganicSearchRegional_id] = useState(17);
  const [OrganicSearchAdditionalBusinessListingManagement_id, setOrganicSearchAdditionalBusinessListingManagement_id] = useState(18);
  const [HostingMonthlyManagedServices_id, setHostingMonthlyManagedServices_id] = useState(19);
  const [WebsiteDesign_id, setWebsiteDesign_id] = useState(20);
  const [WebsiteDesignWooCommerce_id, setWebsiteDesignWooCommerce_id] = useState(21);
  const [OfflineTracking_id, setOfflineTracking_id] = useState(22);
  const [OnlineVideoGeoDemo_id, setOnlineVideoGeoDemo_id] = useState(24);
  const [OnlineVideoReEngage_id, setOnlineVideoReEngage_id] = useState(25);
  const [OnlineVideoGeoIntention_id, setOnlineVideoGeoIntention_id] = useState(26);
  const [OCAProduction_id, setOCAProduction_id] = useState(27);
  const [OCAProductionTwo_id, setOCAProductionTwo_id] = useState(28);
  const [OCAProductionThree_id, setOCAProductionThree_id] = useState(29);
  const [Chat_id, setChat_id] = useState(30);
  const [SocialAdsReEngage_id, setSocialAdsReEngage_id] = useState(31);
  const [OutOfHome_id, setOutOfHome_id] = useState(32);
  const [LinearCRM_id, setLinearCRM_id] = useState(33);
  const [OCAGeoDemo_id, setOCAGeoDemo_id] = useState(34);
  const [OCAReEngage_id, setOCAReEngage_id] = useState(35);
  const [OCAGeoIntention_id, setOCAGeoIntention_id] = useState(36);

  const [HasLincenses, setHasLincenses] = useState("0");
  const [HasCertifications, setHasCertifications] = useState("0");
  const [Hasawards, setHasawards] = useState("0");

  const [CRMSocials, setCRMSocials] = useState([]);
  const [AllSocials, setAllSocials] = useState([]);
  const [CRMPeople, setCRMPeople] = useState([]);
  const [WebsiteDesignsubmissiondata, setWebsiteDesignsubmissiondata] = useState(null);
  const [OnboardingDetailsdata, setOnboardingDetailsdata] = useState(null);

  const [CRMContract, setCRMContract] = useState(null);
  const [users_table_id, setusers_table_id] = useState(null);

  const [PhotoHover, setPhotoHover] = useState([]);

  const [revenue, setrevenue] = useState('');
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const config = isMobile ? { width: "100%", margin: "0 auto" } : { width: "90%", margin: "0 auto" };
  const float = isMobile ? { float: "left", display: "inline-block" } : { float: "left", display: "inline-block", marginRight: "50px" };
  const [expanded, setExpanded] = React.useState("panel1");
  const [paymentId, setPaymentId] = useState();
  const [method, setMethod] = useState("credit-card");
  const [cards, setCards] = useState([]);
  const [banks, setBanks] = useState([]);
  const [error, setError] = useState("");
  const [BrandPhotosandLogos, setBrandPhotosandLogos] = useState([]);

  const [HighPriorityLocationsHover, setHighPriorityLocationsHover] = useState([]);
  const [WebsiteInpirationHover, setWebsiteInpirationHover] = useState([]);
  const [VideoInpirationHover, setVideoInpirationHover] = useState([]);
  const [LocalPagesHover, setLocalPagesHover] = useState([]);
  const [TrackingInfoHover, setTrackingInfoHover] = useState([]);
  const [BlogTopicIdeasHover, setBlogTopicIdeasHover] = useState([]);
  const [ServiceAreaLocationsHover, setServiceAreaLocationsHover] = useState([]);
  const [ProductsHover, setProductsHover] = useState([]);
  const [NoFocusProductHover, setNoFocusProductHover] = useState([]);
  const [BrandHover, setBrandHover] = useState([]);
  const [CertificateHover, setCertificateHover] = useState([]);
  const [LicensesHover, setLicensesHover] = useState([]);
  const [AwardsHover, setAwardsHover] = useState([]);
  const [CouponsHover, setCouponsHover] = useState([]);
  const [FAQHover, setFAQHover] = useState([]);
  const [TopCompetitorsHover, setTopCompetitorsHover] = useState([]);
  const [TargetIndustriesHover, setTargetIndustriesHover] = useState([]);
  const [OnlineVideoHover, setOnlineVideoHover] = useState([]);
  const [AdditionalColorsHover, setAdditionalColorsHover] = useState([]);
  const [TargetJobTitleHover, setTargetJobTitleHover] = useState([]);
  const [TargetingInterestsHover, setTargetingInterestsHover] = useState([]);
  const [SocialMediaHover, setSocialMediaHover] = useState([]);
  const [uploadPercentagegeneral_liability, setuploadPercentagegeneral_liability] = useState("");

  const [uploadPercentageCompanyPhotos, setuploadPercentageCompanyPhotos] = useState("");
  const [uploadPercentageLogos, setuploadPercentageLogos] = useState("");
  const [crm_customer_id, setcrm_customer_id] = useState(null);
  const [crm_people_id, setcrm_people_id] = useState(null);
  const [crm_contract_id, setcrm_contract_id] = useState(null);
  const [crm_onboarding_id, setcrm_onboarding_id] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);
  const [quick_books_id, setquick_books_id] = useState(null);

  const navigate = useNavigate();
  const classes = useStyles();
  const { data: me } = useQuery(READ_ME);

  const [defaultId, setDefaultId] = useState(
    me?.readMe?.default_payment_id || ""
  );

  const { data: GetCustomersUser, loading: UsersTableloading } = useQuery(READ_USER_BY_EMAIL, {
    skip: !CRMPeople || !CRMPeople?.email,
    fetchPolicy: "no-cache",
    variables: {
      email: CRMPeople?.email
    },
  });

  const [updateMarketingGoal, { loading: updatingMarketingGoal }] = useMutation(UPDATE_CUSTOMER_MARKETING_GOAL, {
    fetchPolicy: "no-cache"
  });

  const [updateBrandColorsAndFonts] = useMutation(UPDATE_BRANDS_COLORS_AND_FONTS, {
    fetchPolicy: "no-cache"
  });

  const [createQuickbookCustomer, { loading: updating }] = useMutation(
    CREATE_QUICKBOOK_CUSTOMER,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        setquick_books_id(data?.createQuickbookCustomer);

        await submitCreateWaterBearMarketingCustomer({
          fetchPolicy: "no-cache",
          variables: {
            crm_onboarding_id: crm_onboarding_id,
            BusinessData: {
              ...omit(CRMCustomers, ["__typename", "update_time", "representative_id",
                "first_name", "last_name", "status", "revenue", "revenue",
                "income_targeted", "accepted_payments", "ages_targeted", "gender_targeted",
                "target_parent", "lead_status"]),
              quick_books_id: data?.createQuickbookCustomer
            }
          }
        });
      }
    }
  );

  const [CustomersTabledata] = useLazyQuery(LIST_CUSTOMERS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: { crm_customers_id: crm_customer_id },
    onCompleted: async (data) => {
      if (data?.listCustomers[0]?.id) {
        setCRMNewCustomerID(data?.listCustomers[0]?.id)

        await submitUpdateOnboardingDetails({
          skip: !crm_onboarding_id || !data?.listCustomers[0]?.id,
          fetchPolicy: "no-cache",
          variables: {
            customer_id: data?.listCustomers[0]?.id,
            crm_onboarding_id: crm_onboarding_id
          }
        });

        setOnboardingDetailsdata({
          ...OnboardingDetailsdata,
          ["customer_id"]: data?.listCustomers[0]?.id
        })
      }
    }
  });

  let { data: Brand_Colors_And_Fonts } = useQuery(LIST_BRANDS_COLORS_AND_FONTS, {
    skip: !crm_customer_id || !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: Website_Access } = useQuery(LIST_WEBSITE_ACCESS, {
    skip: !crm_customer_id || !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: Website_Design } = useQuery(LIST_CUSTOMER_WEBSITE_DESIGN, {
    skip: !crm_customer_id || !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: Onboarding_Details } = useQuery(LIST_ONBOARDING_TABLE_DETAILS, {
    skip: !crm_onboarding_id || !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: Brand_Photos_and_Logos_data } = useQuery(LIST_BRAND_PHOTOS_AND_LOGOS, {
    skip: !crm_customer_id || !CRMNewCustomerID || !crm_onboarding_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_customer_id: crm_customer_id,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: crm_customers_data } = useQuery(CRM_CUSTOMERS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: 38,
      status: -1,
      id: crm_customer_id
    }
  });

  let { data: people_data, loading: peopleloading, refetch: refetchPeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !crm_people_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: 38,
      count: 0,
      id: crm_people_id
    }
  });

  let { data: Contract_data, refetch: refetchContracts } = useQuery(LIST_CONTRACTS, {
    fetchPolicy: "no-cache",
    variables: {
      customer_id: 38,
    }
  });

  let [createCard, { loading: creatingCard }] = useMutation(CREATE_CARD, {
    fetchPolicy: "no-cache",
    skip: !quick_books_id,
    onCompleted: data => {
      if (data?.createCard?.errors?.length) {
        setError(data?.createCard?.errors[0]);
      } else {
        setCards([...cards, data?.createCard]);
        setGotOnePaymentMethodRequiredState(true);
      }
    }
  });
  let [createBank, { loading: creatingBank }] = useMutation(CREATE_BANK, {
    skip: !quick_books_id,
    fetchPolicy: "no-cache",
    onCompleted: data => {
      if (data?.createBank?.errors?.length) {
        setError(data?.createBank?.errors[0]);
      } else {
        setBanks([...banks, data?.createBank]);
        setGotOnePaymentMethodRequiredState(true);
      }
    }
  });
  const [
    setDefaultPaymentId,
    { loading: settingDefaultPaymentId }
  ] = useMutation(SET_DEFAULT_PAYMENT_ID, {
    onCompleted: ({ setDefaultPaymentId }) => {
      setDefaultId(setDefaultPaymentId);
    }
  });
  const { loading: listingCard } = useQuery(LIST_CARDS, {
    fetchPolicy: "no-cache",
    skip: !quick_books_id,
    variables: {
      quick_books_id
    },
    onCompleted: data => {
      setCards(data?.listCards || []);
    }
  });
  const { loading: listingBank } = useQuery(LIST_BANKS, {
    fetchPolicy: "no-cache",
    skip: !quick_books_id,
    variables: {
      quick_books_id
    },
    onCompleted: data => {
      setBanks(data?.listBanks || []);
    }
  });
  const [removeCard, { loading: removingCard }] = useMutation(REMOVE_CARD, {
    fetchPolicy: "no-cache",
    skip: !quick_books_id
  });
  const [removeBank, { loading: removingBank }] = useMutation(REMOVE_BANK, {
    fetchPolicy: "no-cache",
    skip: !quick_books_id
  });
  const addCard = () => {
    setError("");
    createCard({
      fetchPolicy: "no-cache",
      variables: {
        quick_books_id,
        card_info: paymentInfo
      }
    });
  };
  const addBank = () => {
    setError("");
    createBank({
      fetchPolicy: "no-cache",
      variables: {
        quick_books_id,
        bank_info: paymentInfo
      }
    });
  };
  const deleteCard = () => {
    removeCard({
      variables: {
        fetchPolicy: "no-cache",
        quick_books_id,
        payment_id: paymentId
      },
      onCompleted: () => {
        setCards(cards?.filter(({ id }) => id !== paymentId));
      }
    });
  };
  const deleteBank = () => {
    removeBank({
      fetchPolicy: "no-cache",
      variables: {
        quick_books_id,
        payment_id: paymentId
      },
      onCompleted: () => {
        setBanks(banks?.filter(({ id }) => id !== paymentId));
      }
    });
  };
  const loadingbilling =
    creatingCard ||
    removingCard ||
    listingCard ||
    creatingBank ||
    removingBank ||
    listingBank ||
    settingDefaultPaymentId;
  const removable = !loadingbilling && (cards.length || 0) + (banks.length || 0) > 1;
  const renderButton = (type, isDefault, id) => (
    <Button
      size="sm"
      color={isDefault ? "primary" : "white"}
      onClick={() => {
        if (!isDefault)
          setDefaultPaymentId({
            variables: { default_payment_id: `${type}-${id}` }
          });
      }}
    >
      {isDefault ? "Default" : "Set as Default"}
    </Button>
  );

  const handleChange = (panel) => (event, newExpanded) => {
    if (newExpanded === false)
      setExpanded(false);
    else
      setExpanded(panel);

    setTimeout(() => {
      document.getElementById(panel + "_link").click();
    }, 700);
  };

  let { data: social_data, refetch: refetchSocial } = useQuery(LIST_CURRENT_SOCIALS, {
    skip: !crm_customer_id || !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_customer_id: crm_customer_id,
      crm_onboarding_id: crm_onboarding_id
    }
  });

  let { data: all_social_data } = useQuery(LIST_ALL_SOCIALS, {
    skip: !state.customers?.selected?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  let { data: Monthly_Budget_data } = useQuery(LIST_MONTHY_BUDGETS, {
    skip: !crm_customer_id || !crm_contract_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: 38,
      contract_id: crm_contract_id
    }
  });

  let { data: all_data, refetch } = useQuery(GET_TOP_COMPETITORS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: product_data, refetch: product_refetch } = useQuery(GET_TOP_PRODUCTS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: no_focus_product_data, refetch: no_focus_product_refetch } = useQuery(GET_NO_FOCUS_PRODUCTS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: brands_data, refetch: brands_refetch } = useQuery(GET_CUSTOMER_BRANDS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: certificates_data, refetch: certificates_refetch } = useQuery(GET_CERTIFICATES, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: licenses_data, refetch: licenses_refetch } = useQuery(GET_LICENSES, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: awards_data, refetch: awards_refetch } = useQuery(GET_AWARDS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: coupons_data, refetch: coupons_refetch } = useQuery(GET_COUPONS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: faqs_data, refetch: faqs_refetch } = useQuery(GET_FAQS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: target_industries_data, refetch: target_industries_refetch } = useQuery(GET_TARGET_INDUSTRIES, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: target_job_title_data, refetch: target_job_titles_refetch } = useQuery(GET_TARGET_JOB_TITLES, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: target_interests_data, refetch: target_interests_refetch } = useQuery(GET_TARGET_INTERESTS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: online_videos_data, refetch: online_video_refetch } = useQuery(GET_ONLINE_VIDEOS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: website_inspiration_data, refetch: website_inspiration_refetch } = useQuery(GET_WEBSITE_INSPIRATION, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: blog_topic_ideas_data, refetch: blog_topic_ideas_refetch } = useQuery(GET_BLOG_TOPIC_IDEAS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: local_pages_data, refetch: local_pages_refetch } = useQuery(GET_LOCAL_PAGES, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: tracking_info_data, refetch: tracking_info_refetch } = useQuery(GET_TRACKING_INFO, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: additional_colors_data, refetch: additional_colors_refetch } = useQuery(GET_ADDITIONAL_COLORS, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: video_inspiration_data, refetch: video_inspiration_refetch } = useQuery(GET_VIDEO_INSPIRATION, {
    skip: !CRMNewCustomerID,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: target_areas_data, refetch: target_areas_data_refetch } = useQuery(GET_TARGET_AREAS, {
    skip: !CRMNewCustomerID || !crm_onboarding_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { data: service_areas_data, refetch: service_areas_data_refetch } = useQuery(GET_SERVICE_AREAS, {
    skip: !CRMNewCustomerID || !crm_onboarding_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
  });

  let { loading, data } = useQuery(GET_CUSTOMER_MARKETING_GOAL, {
    skip: !CRMNewCustomerID || !crm_onboarding_id,
    variables: {
      customer_id: CRMNewCustomerID,
      crm_onboarding_id: crm_onboarding_id
    },
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (CRMPeople?.email && UsersTableloading === false) {
      let UsersTabledata = GetCustomersUser?.readUserbyEmail || [];
      if (UsersTabledata?.email?.length) {
        setCRMPeople({
          ...CRMPeople,
          ["billing_contact"]: UsersTabledata?.billing_contact,
          ["auto_reporting_emails"]: UsersTabledata?.auto_reporting_emails,
          ["cell_phone_number"]: FormatPhone(UsersTabledata?.cell_phone),
          ["email"]: UsersTabledata?.email,
          ["first_name"]: UsersTabledata?.first_name,
          ["last_name"]: UsersTabledata?.last_name,
          ["office_phone_number"]: UsersTabledata?.office_phone,
          ["office_extension"]: UsersTabledata?.office_extension,
          ["birthday"]: UsersTabledata?.birthday
        });

        setusers_table_id(UsersTabledata?.id);
        setWaterBearMarketingUser(true);
      }
    }

    if (peopleloading === false && !CRMPeople?.email) {
      let UsersTabledata = people_data?.listCustomerEmails[0] || [];
      if (UsersTabledata?.cell_phone_number?.length) {
        setCRMPeople({ ...UsersTabledata, ["cell_phone_number"]: FormatPhone(UsersTabledata?.cell_phone_number) });
      }
    }
  }, [people_data, UsersTableloading, peopleloading])

  useEffect(() => {
    setquick_books_id(Number(me?.readMe?.quick_books_id || 0));

    setDefaultId(me?.readMe?.default_payment_id);
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);

    if (urlParams.get("id") && !crm_customer_id) {
      setcrm_customer_id(parseInt(urlParams.get("id")));
    } else if (!crm_customer_id) {
      navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
    }

    if (urlParams.get("peopleid") && !crm_people_id) {
      setcrm_people_id(parseInt(urlParams.get("peopleid")));
    } else if (!crm_people_id) {
      navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
    }

    if (urlParams.get("contractid") && !crm_contract_id) {
      setcrm_contract_id(parseInt(urlParams.get("contractid")));
    } else if (!crm_contract_id) {
      navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
    }

    if (urlParams.get("onboarding_id") && !crm_onboarding_id) {
      setcrm_onboarding_id(parseInt(urlParams.get("onboarding_id")));
    }

    if (state.customers?.selected?.id) {
      if (OnboardingDetailsdata === undefined && OnboardingDetailsdata?.onboarding_id === undefined || (crm_onboarding_id && OnboardingDetailsdata?.onboarding_id && crm_onboarding_id !== OnboardingDetailsdata?.onboarding_id)) {
        alert("You do not have access to that information");
        navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
      }

      if (crm_customer_id && OnboardingDetailsdata?.crm_customer_id && crm_customer_id !== OnboardingDetailsdata?.crm_customer_id) {
        alert("You do not have access to that information");
        navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
      }

      if (crm_contract_id && OnboardingDetailsdata?.contract_id && crm_contract_id !== OnboardingDetailsdata?.contract_id) {
        alert("You do not have access to that information");
        navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
      }

      if (crm_people_id && OnboardingDetailsdata?.people_id && crm_people_id !== OnboardingDetailsdata?.people_id) {
        alert("You do not have access to that information");
        navigate("/admin/account-overview?customer=" + state.customers?.selected?.id);
      }
    }

    let GotAllDashboardUserInfoRequired = true;

    if (UsersTableloading === false) {
      if (!CRMPeople?.first_name || CRMPeople?.first_name?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      } else if (!CRMPeople?.last_name || CRMPeople?.last_name?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      } else if (!CRMPeople?.email || CRMPeople?.email?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      } else if (!CRMPeople?.cell_phone_number || CRMPeople?.cell_phone_number?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      } else if (!CRMPeople?.billing_contact || CRMPeople?.billing_contact?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      } else if (!CRMPeople?.auto_reporting_emails || CRMPeople?.auto_reporting_emails?.length === 0) {
        GotAllDashboardUserInfoRequired = false;
      }
    }
    if (GotAllDashboardUserInfoRequiredState != GotAllDashboardUserInfoRequired)
      setGotAllDashboardUserInfoRequiredState(GotAllDashboardUserInfoRequired);

    let GotAllBusinessInfoRequired = true;

    if (!CRMCustomers?.business_name || CRMCustomers?.business_name?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_address_line_one || CRMCustomers?.office_address_line_one?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_city || CRMCustomers?.office_city?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_state || CRMCustomers?.office_state?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_zip || CRMCustomers?.office_zip?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_country || CRMCustomers?.office_country?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.office_phone_number || CRMCustomers?.office_phone_number?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (!CRMCustomers?.website || CRMCustomers?.website?.length === 0) {
      GotAllBusinessInfoRequired = false;
    }

    if (GotAllBusinessInfoRequiredState != GotAllBusinessInfoRequired)
      setGotAllBusinessInfoRequiredState(GotAllBusinessInfoRequired);

    if (banks?.length > 0 || cards?.length < 0)
      setGotOnePaymentMethodRequiredState(true);

    if (user_id === null && me?.readMe?.id)
      setuser_id(me?.readMe?.id);

    if (CRMNewCustomerID === null && tryedToGetCustomerIdFromCustomersTable === false) {
      CustomersTabledata();
      settryedToGetCustomerIdFromCustomersTable(true)
    }

    setBrandPhotosandLogos(Brand_Photos_and_Logos_data?.ListBrandPhotosAndLogos || []);

    setBrandColorsAndFontssubmissionData(Brand_Colors_And_Fonts?.ListBrandColorsAndFonts[0] || []);
    setCurrentWebsiteAccessssubmissionData(Website_Access?.ListWebsiteAccess[0] || []);
    setWebsiteDesignsubmissiondata(Website_Design?.ListCustomerWebsiteDesign[0] || []);
    if (Onboarding_Details?.ListOnboardingTableDetails)
      setOnboardingDetailsdata(Onboarding_Details?.ListOnboardingTableDetails[0]);


    if (CRMCustomers)
      setAccountInfo({
        "timezone": CRMCustomers?.timezone,
        "round_the_clock_support": CRMCustomers?.round_the_clock_support,
        "sunday_open_hours": CRMCustomers?.sunday_open?.split(":")[0],
        "sunday_open_mins": CRMCustomers?.sunday_open !== null ? CRMCustomers?.sunday_open?.split(":")[1] : "00",
        "sunday_open": CRMCustomers?.sunday_open,
        "sunday_close_hours": CRMCustomers?.sunday_close?.split(":")[0],
        "sunday_close_mins": CRMCustomers?.sunday_close !== null ? CRMCustomers?.sunday_close?.split(":")[1] : "00",
        "sunday_close": CRMCustomers?.sunday_close,
        "monday_open_hours": CRMCustomers?.monday_open?.split(":")[0],
        "monday_open_mins": CRMCustomers?.monday_open !== null ? CRMCustomers?.monday_open?.split(":")[1] : "00",
        "monday_open": CRMCustomers?.monday_open,
        "monday_close_hours": CRMCustomers?.monday_close?.split(":")[0],
        "monday_close_mins": CRMCustomers?.monday_close !== null ? CRMCustomers?.monday_close?.split(":")[1] : "00",
        "monday_close": CRMCustomers?.monday_close,
        "tuesday_open_hours": CRMCustomers?.tuesday_open?.split(":")[0],
        "tuesday_open_mins": CRMCustomers?.tuesday_open !== null ? CRMCustomers?.tuesday_open?.split(":")[1] : "00",
        "tuesday_open": CRMCustomers?.tuesday_open,
        "tuesday_close_hours": CRMCustomers?.tuesday_close?.split(":")[0],
        "tuesday_close_mins": CRMCustomers?.tuesday_close !== null ? CRMCustomers?.tuesday_close?.split(":")[1] : "00",
        "tuesday_close": CRMCustomers?.tuesday_close,
        "wednesday_open_hours": CRMCustomers?.wednesday_open?.split(":")[0],
        "wednesday_open_mins": CRMCustomers?.wednesday_open !== null ? CRMCustomers?.wednesday_open?.split(":")[1] : "00",
        "wednesday_open": CRMCustomers?.wednesday_open,
        "wednesday_close_hours": CRMCustomers?.wednesday_close?.split(":")[0],
        "wednesday_close_mins": CRMCustomers?.wednesday_close !== null ? CRMCustomers?.wednesday_close?.split(":")[1] : "00",
        "wednesday_close": CRMCustomers?.wednesday_close,
        "thursday_open_hours": CRMCustomers?.thursday_open?.split(":")[0],
        "thursday_open_mins": CRMCustomers?.thursday_open !== null ? CRMCustomers?.thursday_open?.split(":")[1] : "00",
        "thursday_open": CRMCustomers?.thursday_open,
        "thursday_close_hours": CRMCustomers?.thursday_close?.split(":")[0],
        "thursday_close_mins": CRMCustomers?.thursday_close !== null ? CRMCustomers?.thursday_close?.split(":")[1] : "00",
        "thursday_close": CRMCustomers?.thursday_close,
        "friday_open_hours": CRMCustomers?.friday_open?.split(":")[0],
        "friday_open_mins": CRMCustomers?.friday_open !== null ? CRMCustomers?.friday_open?.split(":")[1] : "00",
        "friday_open": CRMCustomers?.friday_open,
        "friday_close_hours": CRMCustomers?.friday_close?.split(":")[0],
        "friday_close_mins": CRMCustomers?.friday_close !== null ? CRMCustomers?.friday_close?.split(":")[1] : "00",
        "friday_close": CRMCustomers?.friday_close,
        "saturday_open_hours": CRMCustomers?.saturday_open?.split(":")[0],
        "saturday_open_mins": CRMCustomers?.saturday_open !== null ? CRMCustomers?.saturday_open?.split(":")[1] : "00",
        "saturday_open": CRMCustomers?.saturday_open,
        "saturday_close_hours": CRMCustomers?.saturday_close?.split(":")[0],
        "saturday_close_mins": CRMCustomers?.saturday_close ? CRMCustomers?.saturday_close?.split(":")[1] : "00",
        "saturday_close": CRMCustomers?.saturday_close
      });

    setCRMSocials(social_data?.listCurrentSocials || []);
    setAllSocials(all_social_data?.listAllSocials || []);

    if (CRMSocials?.length) {
      sethighestSocialID(CRMSocials[CRMSocials?.length - 1].id + 1);
    }

    if (competitorData?.length)
      sethighestCompetitorID(competitorData[competitorData.length - 1].id + 1)

    if (productData?.length)
      sethighestProductID(productData[productData.length - 1].id + 1)

    if (noFocusProductData?.length)
      sethighestNoFocusProductID(noFocusProductData[noFocusProductData.length - 1].id + 1)

    if (highestmarketingGoalID?.length)
      sethighestmarketingGoalID(marketingGoal[marketingGoal.length - 1].id + 1)

    if (targetAreaData?.length)
      sethighestTopTargetAreasID(targetAreaData[targetAreaData.length - 1].id + 1)

    if (ServiceAreaData?.length)
      sethighestServiceAreasID(ServiceAreaData[ServiceAreaData.length - 1].id + 1)

    if (all_data?.getTopCompetitors?.length)
      setcompetitorData(all_data?.getTopCompetitors || []);
    if (product_data?.getTopProducts?.length)
      setproductData(product_data?.getTopProducts || []);
    if (no_focus_product_data?.getNoFocusProducts?.length)
      setnoFocusProductData(no_focus_product_data?.getNoFocusProducts || []);
    if (brands_data?.getBrands?.length)
      setbrandData(brands_data?.getBrands || []);
    if (certificates_data?.getCertificates?.length)
      setcertificateData(certificates_data?.getCertificates || []);

    if (certificateData?.length && HasCertifications === "0") {
      setHasCertifications("1");
    }
    if (licenses_data?.getLicenses?.length)
      setlicenseData(licenses_data?.getLicenses || []);
    if (licenseData?.length && HasLincenses === "0") {
      setHasLincenses("1");
    }
    if (awards_data?.getAwards?.length)
      setawardData(awards_data?.getAwards || []);

    if (awardData?.length && Hasawards === "0") {
      setHasawards("1");
    }
    if (coupons_data?.getCoupons?.length)
      setcouponData(coupons_data?.getCoupons || []);
    if (faqs_data?.getFAQS?.length)
      setfaqData(faqs_data?.getFAQS || []);
    if (target_industries_data?.getTargetIndustries?.length)
      settargetindustryData(target_industries_data?.getTargetIndustries || []);
    if (target_job_title_data?.getTargetJobTitles?.length)
      settargetjobtitleData(target_job_title_data?.getTargetJobTitles || []);
    if (target_interests_data?.getTargetInterests?.length)
      settargetinginterestsData(target_interests_data?.getTargetInterests || []);
    if (online_videos_data?.getOnlineVideo?.length)
      setonlinevideoData(online_videos_data?.getOnlineVideo || []);
    if (website_inspiration_data?.getWebsiteInspiration?.length)
      setwebsiteinspirationData(website_inspiration_data?.getWebsiteInspiration || []);
    if (blog_topic_ideas_data?.getBlogTopicIdeas?.length)
      setblogtopicideasData(blog_topic_ideas_data?.getBlogTopicIdeas || []);
    if (local_pages_data?.getLocalPages?.length)
      setlocalPagesData(local_pages_data?.getLocalPages || []);
    if (tracking_info_data?.getTrackingInfo?.length)
      setTrackingInfoData(tracking_info_data?.getTrackingInfo || []);
    if (additional_colors_data?.getAdditionalColors?.length)
      setadditionalcolorsData(additional_colors_data?.getAdditionalColors || []);
    if (video_inspiration_data?.getVideoInspiration?.length)
      setvideoinspirationData(video_inspiration_data?.getVideoInspiration || []);
    if (target_areas_data?.getTargetAreas?.length)
      settargetAreaData(target_areas_data?.getTargetAreas || []);
    if (service_areas_data?.getServiceAreas?.length)
      setServiceAreaData(service_areas_data?.getServiceAreas || []);
    if (CRMMonthlyBudget?.length)
      setMarketingGoal(new Array(CRMMonthlyBudget?.length).fill(null));
    if (data?.getCustomerMarketingGoal?.length)
      setMarketingGoal(data?.getCustomerMarketingGoal);

    if (!CRMContract)
      setCRMContract(Contract_data?.ListContract || []);

    setCRMMonthlyBudget(Monthly_Budget_data?.listMonthlyBudgets || []);
    if (CRMCustomers === null && crm_customers_data?.CRMCustomers) {
      setCRMCustomers(crm_customers_data?.CRMCustomers[0] || []);
      FormatCurrency(crm_customers_data?.CRMCustomers[0]?.revenue);
    }


    if (CRMMonthlyBudget) {
      CRMMonthlyBudget?.map((CurrentBudget, index) => {
        // check if there are products that require lead goals
        if (CurrentBudget?.product_id === 1)
          setHydrate(true);
        if (CurrentBudget?.product_id === 2)
          setBannerAdsGeoDemo(true);
        if (CurrentBudget?.product_id === 3)
          setBannerAdsGeoIntention(true);
        if (CurrentBudget?.product_id === 12)
          setBannerAdsReEngage(true);
        if (CurrentBudget?.product_id === 13)
          setLocalServicesAds(true);
        if (CurrentBudget?.product_id === 14)
          setPaidSearch(true);
        if (CurrentBudget?.product_id === 15)
          setOrganicSearchTwo(true);
        if (CurrentBudget?.product_id === 16)
          setOrganicSearchFour(true);
        if (CurrentBudget?.product_id === 17)
          setOrganicSearchRegional(true);
        if (CurrentBudget?.product_id === 18)
          setOrganicSearchAdditionalBusinessListingManagement(true);
        if (CurrentBudget?.product_id === 19)
          setHostingMonthlyManagedServices(true);
        if (CurrentBudget?.product_id === 20)
          setWebsiteDesign(true);
        if (CurrentBudget?.product_id === 21)
          setWebsiteDesignWooCommerce(true);
        if (CurrentBudget?.product_id === 22)
          setOfflineTracking(true);
        if (CurrentBudget?.product_id === 24)
          setOnlineVideoGeoDemo(true);
        if (CurrentBudget?.product_id === 25)
          setOnlineVideoReEngage(true);
        if (CurrentBudget?.product_id === 26)
          setOnlineVideoGeoIntention(true);
        if (CurrentBudget?.product_id === 27)
          setOCAProduction(true);
        if (CurrentBudget?.product_id === 28)
          setOCAProductionTwo(true);
        if (CurrentBudget?.product_id === 29)
          setOCAProductionThree(true);
        if (CurrentBudget?.product_id === 30)
          setChat(true);
        if (CurrentBudget?.product_id === 31)
          setSocialAdsReEngage(true);
        if (CurrentBudget?.product_id === 32)
          setOutOfHome(true);
        if (CurrentBudget?.product_id === 33)
          setLinearCRM(true);
        if (CurrentBudget?.product_id === 34)
          setOCAGeoDemo(true);
        if (CurrentBudget?.product_id === 35)
          setOCAGeoIntention(true);
        if (CurrentBudget?.product_id === 36)
          setOCAReEngage(true);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("18_24") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_18_24[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_18_24(ages_18_24);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("25_34") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_25_34[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_25_34(ages_25_34);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("35_44") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_35_44[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_35_44(ages_35_44);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("45_54") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_45_54[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_45_54(ages_45_54);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("55_64") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_55_64[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_55_64(ages_55_64);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("65_plus") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_65_plus[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_65_plus(ages_65_plus);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("no_restrictions") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_no_restrictions[index] = CurrentBudget?.product_id;
            }
          }
        }

        setages_no_restrictions(ages_no_restrictions);

        if (CRMCustomers?.ages_targeted) {
          for (let i = 0; i <= CRMCustomers?.ages_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[0]?.indexOf("unknown") > -1 &&
              parseInt(CRMCustomers?.ages_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              ages_unknown[index] = CurrentBudget?.product_id
            }
          }
        }

        setages_unknown(ages_unknown);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("top_10_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_top_10_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_top_10_percent(income_top_10_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("11_20_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_11_20_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_11_20_percent(income_11_20_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("21_30_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_21_30_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_21_30_percent(income_21_30_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("31_40_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_31_40_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_31_40_percent(income_31_40_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("41_50_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_41_50_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_41_50_percent(income_41_50_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("lower_50_percent") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_lower_50_percent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_lower_50_percent(income_lower_50_percent);

        if (CRMCustomers?.income_targeted) {
          for (let i = 0; i <= CRMCustomers?.income_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[0]?.indexOf("unknown") > -1 &&
              parseInt(CRMCustomers?.income_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              income_unknown[index] = CurrentBudget?.product_id;
            }
          }
        }

        setincome_unknown(income_unknown);

        if (CRMCustomers?.gender_targeted) {
          for (let i = 0; i <= CRMCustomers?.gender_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[0]?.indexOf("no_restrictions") > -1 &&
              parseInt(CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              gender_no_restrictions[index] = CurrentBudget?.product_id;
            }
          }
        }

        setgender_no_restrictions(gender_no_restrictions);

        if (CRMCustomers?.gender_targeted) {
          for (let i = 0; i <= CRMCustomers?.gender_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[0]?.indexOf("male") > -1 &&
              parseInt(CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              gender_male[index] = CurrentBudget?.product_id;
            }
          }
        }

        setgender_male(gender_male);

        if (CRMCustomers?.gender_targeted) {
          for (let i = 0; i <= CRMCustomers?.gender_targeted?.split("|")?.length; i++) {
            if (CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[0]?.indexOf("female") > -1 &&
              parseInt(CRMCustomers?.gender_targeted?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              gender_female[index] = CurrentBudget?.product_id;
            }
          }
        }

        setgender_female(gender_female);

        if (CRMCustomers?.target_parent) {
          for (let i = 0; i <= CRMCustomers?.target_parent?.split("|")?.length; i++) {
            if (CRMCustomers?.target_parent?.split("|")[i]?.split("~")[0]?.indexOf("not_a_parent") > -1 &&
              parseInt(CRMCustomers?.target_parent?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              not_a_parent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setnot_a_parent(not_a_parent);

        if (CRMCustomers?.target_parent) {
          for (let i = 0; i <= CRMCustomers?.target_parent?.split("|")?.length; i++) {
            if (CRMCustomers?.target_parent?.split("|")[i]?.split("~")[0]?.indexOf("parent_true") > -1 &&
              parseInt(CRMCustomers?.target_parent?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              parent_true[index] = CurrentBudget?.product_id;
            }
          }
        }

        setparent_true(parent_true);

        if (CRMCustomers?.target_parent) {
          for (let i = 0; i <= CRMCustomers?.target_parent?.split("|")?.length; i++) {
            if (CRMCustomers?.target_parent?.split("|")[i]?.split("~")[0]?.indexOf("unknown_parent") > -1 &&
              parseInt(CRMCustomers?.target_parent?.split("|")[i]?.split("~")[1]) === CurrentBudget?.product_id) {
              unknown_parent[index] = CurrentBudget?.product_id;
            }
          }
        }

        setunknown_parent(unknown_parent);
      })
    }

    if (CRMCustomers?.accepted_payments) {
      if (CRMCustomers?.accepted_payments?.indexOf("cash") > -1)
        CRMCustomers.cash = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("after_pay") > -1)
        CRMCustomers.after_pay = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("affirm") > -1)
        CRMCustomers.affirm = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("venmo") > -1)
        CRMCustomers.venmo = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("google_pay") > -1)
        CRMCustomers.google_pay = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("apple_pay") > -1)
        CRMCustomers.apple_pay = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("discover") > -1)
        CRMCustomers.discover = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("paypal") > -1)
        CRMCustomers.paypal = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("financing") > -1)
        CRMCustomers.financing = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("travelers_checks") > -1)
        CRMCustomers.travelers_checks = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("debit") > -1)
        CRMCustomers.debit = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("insurance") > -1)
        CRMCustomers.insurance = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("invoice") > -1)
        CRMCustomers.invoice = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("personal_check") > -1)
        CRMCustomers.personal_check = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("american_express") > -1)
        CRMCustomers.american_express = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("mastercard") > -1)
        CRMCustomers.mastercard = 1;
      if (CRMCustomers?.accepted_payments?.indexOf("visa") > -1)
        CRMCustomers.visa = 1;
    }
  }, [CRMCustomers, competitorData, productData, noFocusProductData, targetAreaData, me,
    all_data, product_data, no_focus_product_data, target_areas_data,
    data, state.customers, crm_customers_data, Monthly_Budget_data])

  const [RemoveCustomerSocial] = useMutation(
    REMOVE_CUSTOMER_SOCIAL
  );

  const [updateSocials] = useMutation(
    UPDATE_CURRENT_SOCIALS,
    {
      skip: !CRMNewCustomerID,
      skip: !CRMSocials
    });

  const [submitDeleteCompanyPhotoAndLogoRequest] = useMutation(
    DELETE_COMPANY_PHOTO_OR_LOGO,
    {
      onCompleted: () => {
        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  function updateSocialssocial_channel_url(index, e) {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_url: e.target.value });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const updateSocialsocial_channel_id = (index) => e => {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_id: e });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const submitDeletePhotoRequest = (photo_url) => {
    BrandPhotosandLogos?.filter((CurrentPhoto) =>
      CurrentPhoto?.photo_url === photo_url)?.map((CurrentPhoto) => {
        submitDeleteCompanyPhotoAndLogoRequest({ skip: !CRMNewCustomerID, variables: { customer_id: CRMNewCustomerID, id: CurrentPhoto?.id, crm_onboarding_id: crm_onboarding_id } });
      });

    let NewArray = BrandPhotosandLogos?.filter((CurrentPhoto) =>
      CurrentPhoto?.photo_url !== photo_url)?.map((CurrentPhoto) => {
        return CurrentPhoto
      });
    setBrandPhotosandLogos(NewArray);
  }

  const AddSocial = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestSocialID, social_channel_id: '', social_channel_url: '', people_id: null, crm_customer_id: crm_customer_id })
    sethighestSocialID(highestSocialID + 1)
    setCRMSocials({ ...CRMSocials, [Object.keys(CRMSocials)?.length]: NewArray });
  }

  const RemoveSocial = (key) => e => {
    RemoveCustomerSocial({
      fetchPolicy: "no-cache",
      variables: {
        customer_id: CRMNewCustomerID,
        id: key
      },
      onCompleted: () => {
        setCRMSocials(Object.keys(CRMSocials)?.map((Social, index) => {
          if (CRMSocials[index].id !== key)
            return CRMSocials[index]
        })?.filter(Social => Social !== undefined))

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    });
  };

  const social_columns = [
    {
      Header: "Social",
      accessor: "social_channel"
    },
    {
      Header: "Remove"
    }
  ];

  const FormatCurrency = (revenue) => {
    let Customer_Revenue = "0";
    if (revenue !== null && revenue !== "")
      Customer_Revenue = revenue;
    let newValue = parseFloat(Customer_Revenue?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setrevenue(newValue);
  }

  const updateCustomerRevenueValueFieldHandler = (e) => {
    setrevenue(e.target.value);
  };

  const updateBusinessInfoFieldHandler = (key, product_id) => e => {
    if (key === "ages_18_24") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_18_24({
              ...ages_18_24,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_25_34") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_25_34({
              ...ages_25_34,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_35_44") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_35_44({
              ...ages_35_44,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_45_54") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_45_54({
              ...ages_45_54,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_55_64") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_55_64({
              ...ages_55_64,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_65_plus") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_65_plus({
              ...ages_65_plus,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_no_restrictions") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_no_restrictions({
              ...ages_no_restrictions,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "ages_unknown") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setages_unknown({
              ...ages_unknown,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_top_10_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_top_10_percent({
              ...income_top_10_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_11_20_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_11_20_percent({
              ...income_11_20_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_21_30_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_21_30_percent({
              ...income_21_30_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_31_40_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_31_40_percent({
              ...income_31_40_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_41_50_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_41_50_percent({
              ...income_41_50_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_lower_50_percent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_lower_50_percent({
              ...income_lower_50_percent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "income_unknown") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setincome_unknown({
              ...income_unknown,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "gender_no_restrictions") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setgender_no_restrictions({
              ...gender_no_restrictions,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "gender_male") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setgender_male({
              ...gender_male,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "gender_male") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setgender_male({
              ...gender_male,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "gender_female") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setgender_female({
              ...gender_female,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "not_a_parent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setnot_a_parent({
              ...not_a_parent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "parent_true") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setparent_true({
              ...parent_true,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    } else if (key === "unknown_parent") {
      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === product_id) {
            setunknown_parent({
              ...unknown_parent,
              [index]: e.target.checked === true ? product_id : 0
            });
          }
        })
    }

    if (key === "cash" || key === "after_pay" || key === "affirm" || key === "venmo" ||
      key === "google_pay" || key === "apple_pay" || key === "discover" || key === "paypal" ||
      key === "financing" || key === "travelers_checks" || key === "debit" || key === "insurance" ||
      key === "invoice" || key === "personal_check" || key === "american_express" || key === "mastercard" || key === "visa") {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (
      key === "nowalkins") {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.value
      });
    }

    let GotAllBusinessInfoRequired = true;

    if (key === "business_name" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_address_line_one" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_city" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_state" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_zip" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_country" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "office_phone_number" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    } else if (key === "website" && e.target.value?.length === 0) {
      GotAllBusinessInfoRequired = false;
    }

    setGotAllBusinessInfoRequiredState(GotAllBusinessInfoRequired);
  };

  const [updateCustomerHours, { loading: updatingCustomerHours }] = useMutation(
    UPDATE_CUSTOMER_HOURS,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: crm_customer_id,
        data: {
          ...omit(AccountInfo, ["sunday_open", "sunday_close", "monday_open", "monday_close",
            "tuesday_open", "tuesday_close", "wednesday_open", "wednesday_close", "thursday_open",
            "thursday_close", "friday_open", "friday_close", "saturday_open", "saturday_close"]),
          id: undefined,
          __typename: undefined
        }
      }
    }
  );

  const [updateCustomerCompetitors, { loading: updatingCustomerCompetitors }] = useMutation(
    UPDATE_CUSTOMER_COMPETITORS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !competitorData,
      onCompleted: () => {
        refetch();
      }
    });

  const [updateCustomerProducts] = useMutation(
    UPDATE_CUSTOMER_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !productData,
      onCompleted: () => {
        product_refetch();
      }
    });

  const [updateCustomerNoFocusProducts] = useMutation(
    UPDATE_CUSTOMER_NO_FOCUS_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !noFocusProductData,
      onCompleted: () => {
        no_focus_product_refetch();
      }
    });

  const [updateCustomerBrands] = useMutation(
    UPDATE_CUSTOMER_BRANDS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !brandData,
      onCompleted: () => {
        brands_refetch();
      }
    });

  const [updateCustomerCertificates] = useMutation(
    UPDATE_CUSTOMER_CERTIFICATES,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !brandData,
      onCompleted: () => {
        certificates_refetch();
      }
    });

  const [updateCustomerLicense] = useMutation(
    UPDATE_CUSTOMER_LICENSES,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !licenseData,
      onCompleted: () => {
        licenses_refetch();
      }
    });

  const [updateCustomerAwards] = useMutation(
    UPDATE_CUSTOMER_AWARDS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !awardData,
      onCompleted: () => {
        awards_refetch();
      }
    });

  const [updateCustomerCoupons] = useMutation(
    UPDATE_CUSTOMER_COUPONS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !couponData,
      onCompleted: () => {
        coupons_refetch();
      }
    });

  const [updateCustomerFAQ] = useMutation(
    UPDATE_CUSTOMER_FAQ,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !faqData,
      onCompleted: () => {
        faqs_refetch();
      }
    });

  const [updateCustomerTargetAreas] = useMutation(
    UPDATE_CUSTOMER_TARGET_AREAS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !targetAreaData,
      onCompleted: () => {
        target_areas_data_refetch();
      }
    });

  const [updateCustomerServiceAreas] = useMutation(
    UPDATE_CUSTOMER_SERVICE_AREAS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !ServiceAreaData,
      onCompleted: () => {
        service_areas_data_refetch();
      }
    });

  const [updateCustomersTargetIndustries] = useMutation(
    UPDATE_CUSTOMER_TARGET_INDUSTRIES,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !targetindustryData,
      onCompleted: () => {
        target_industries_refetch();
      }
    });

  const [updateCustomersTargetJobTitles] = useMutation(
    UPDATE_CUSTOMER_TARGET_JOB_TITLES,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !targetjobtitleData,
      onCompleted: () => {
        target_job_titles_refetch();
      }
    });

  const [updateCustomersOnlineVideos] = useMutation(
    UPDATE_CUSTOMER_ONLINE_VIDEOS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !onlinevideoData,
      onCompleted: () => {
        online_video_refetch();
      }
    });

  const [updateCustomersAdditionalColors] = useMutation(
    UPDATE_CUSTOMER_ADDITIONAL_COLORS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !additionalcolorsData,
      onCompleted: () => {
        additional_colors_refetch();
      }
    });

  const [updateCustomersWebsiteInspiration] = useMutation(
    UPDATE_CUSTOMER_WEBSITE_INSPIRATION,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !websiteinspirationData,
      onCompleted: () => {
        website_inspiration_refetch();
      }
    });

  const [updateCustomersVideoInspiration] = useMutation(
    UPDATE_CUSTOMER_VIDEO_INSPIRATION,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !videoinspirationData,
      onCompleted: () => {
        video_inspiration_refetch();
      }
    });

  const [updateCustomersTrackingInfo] = useMutation(
    UPDATE_CUSTOMER_TRACKING_INFO,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !TrackingInfoData,
      onCompleted: () => {
        tracking_info_refetch();
      }
    });

  const [updateCustomersBlogTopicIdeas] = useMutation(
    UPDATE_CUSTOMER_BLOG_TOPIC_IDEAS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !blogtopicideasData,
      onCompleted: () => {
        blog_topic_ideas_refetch();
      }
    });

  const [updateCustomersLocalPages] = useMutation(
    UPDATE_CUSTOMER_LOCAL_PAGES,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !localPagesData,
      onCompleted: () => {
        local_pages_refetch();
      }
    });

  const [updateCustomersTargetInterests] = useMutation(
    UPDATE_CUSTOMER_TARGET_INTERESTS,
    {
      fetchPolicy: "no-cache",
      skip: !CRMNewCustomerID,
      skip: !targetinginterestsData,
      onCompleted: () => {
        target_interests_refetch();
      }
    });

  const top_competitors_columns = [
    {
      Header: "Top Competitors",
      accessor: "customers_top_competitors"
    },
    {
      Header: "Top Competitor's Url",
      accessor: "top_competitors_url"
    },
    {
      Header: "Remove"
    }
  ];

  const inpirational_websites_columns = [
    {
      Header: "Website Url",
      accessor: "inpirational_website_url"
    },
    {
      Header: "Notes",
      accessor: "inpirational_website_notes"
    },
    {
      Header: "Remove"
    }
  ];

  const inpirational_video_columns = [
    {
      Header: "Video Url",
      accessor: "inpirational_video_url"
    },
    {
      Header: "Notes",
      accessor: "inpirational_video_notes"
    },
    {
      Header: "Remove"
    }
  ];

  const local_pages_columns = [
    {
      Header: "Products/Services",
      accessor: "products_or_services"
    },
    {
      Header: "Area",
      accessor: "products_or_services_area"
    },
    {
      Header: "Remove"
    }
  ];

  const tracking_numbers_columns = [
    {
      Header: "What is being Tracked?",
      accessor: "tracking_description"
    },
    {
      Header: "Rings To",
      accessor: "rings_to"
    },
    {
      Header: "Remove"
    }
  ];

  const blog_topic_ideas_columns = [
    {
      Header: "Blog Topic Ideas",
      accessor: "blog_topic_ideas"
    },
    {
      Header: "Remove"
    }
  ];

  const top_products_columns = [
    {
      Header: "Top Products",
      accessor: "product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const online_video_columns = [
    {
      Header: "Online Videos",
    },
    {
      Header: "Remove"
    }
  ];

  const additional_colors_columns = [
    {
      Header: "Additional Colors",
    },
    {
      Header: "Remove"
    }
  ];

  const frequently_columns = [
    {
      Header: "Question",
    },
    {
      Header: "Answer",
    },
    {
      Header: "Remove"
    }
  ];

  const certifications_columns = [
    {
      Header: "Certifications"
    },
    {
      Header: "Remove"
    }
  ];

  const successes_columns = [
    {
      Header: "Successes, Awards, and/or Highlights",
    },
    {
      Header: "Remove"
    }
  ];

  const license_columns = [
    {
      Header: "License",
    },
    {
      Header: "Remove"
    }
  ];

  const coupons_columns = [
    {
      Header: "Name",
      accessor: "coupons"
    },
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Start Date"
    },
    {
      Header: "Stop Date"
    },
    {
      Header: "On Your website"
    },
    {
      Header: "Remove"
    }
  ];

  const brand_columns = [
    {
      Header: "Brand",
      accessor: "brand"
    },
    {
      Header: "Remove"
    }
  ];

  const no_focus_products_columns = [
    {
      Header: "No Focus Products",
      accessor: "no_product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const industries_columns = [
    {
      Header: "Industry",
      accessor: "industry"
    },
    {
      Header: "Remove"
    }
  ];

  const job_title_columns = [
    {
      Header: "Job Title",
      accessor: "job_title"
    },
    {
      Header: "Remove"
    }
  ];

  const interests_columns = [
    {
      Header: "Interests",
      accessor: "interests"
    },
    {
      Header: "Remove"
    }
  ];

  const target_areas_columns = [
    {
      Header: "Target Area",
      accessor: "customer_top_target_area"
    },
    {
      Header: "Target Type",
      accessor: "customer_top_target_type"
    },
    {
      Header: "Remove"
    }
  ];

  const [submitUpdateCRMRequest] = useMutation(UPDATE_CRM_CUSTOMERS);
  const [submitUpdateOnboardingDetails] = useMutation(UPDATE_ONBOARDING_TABLE_DETAILS);

  const [submitCreateWaterBearMarketingCustomer] = useMutation(CREATE_WATER_BEAR_MARKETING_CUSTOMERS, {
    onCompleted: async (data) => {
      if (data?.createWaterBearMarketingCustomer > 0) {
        setCRMNewCustomerID(data?.createWaterBearMarketingCustomer);

        await submitCreateWaterBearMarketingUser({
          fetchPolicy: "no-cache",
          variables: {
            customer_id: data?.createWaterBearMarketingCustomer,
            customerEmail: {
              ...omit(CRMPeople, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text"]),
              cell_phone_number: CRMPeople?.cell_phone_number !== undefined ? CRMPeople?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
              office_phone_number: CRMPeople?.office_phone_number !== undefined ? CRMPeople?.office_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
              first_name: CRMPeople?.first_name ? CRMPeople?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
              id: crm_people_id,
              users_id: user_id,
              crm_representative_id: 1
            }
          }
        });

        createQuickbookCustomer({
          variables: {
            customer_id: data?.createWaterBearMarketingCustomer,
            isAgency: false,
            data: JSON.stringify({
              "firstName": CRMPeople?.first_name,
              "lastName": CRMPeople?.last_name,
              "email": CRMPeople?.email,
              "mobile": CRMPeople?.cell_phone_number,
              "name": CRMCustomers?.business_name,
              "address_1": CRMCustomers?.office_address_line_one,
              "address_2": CRMCustomers?.office_address_line_two,
              "city": CRMCustomers?.office_city,
              "state": CRMCustomers?.office_state,
              "zip": CRMCustomers?.office_zip,
              "country": CRMCustomers?.office_country,
              "phone": CRMCustomers?.office_phone_number,
              "fax": "",
              "website": CRMCustomers?.website?.indexOf("https://") > -1 ? CRMCustomers.website : "https://" + CRMCustomers?.website
            })
          }
        });

        // set customer_id to new id
        await submitUpdateOnboardingDetails({
          skip: !crm_onboarding_id || !data?.createWaterBearMarketingCustomer,
          fetchPolicy: "no-cache",
          variables: {
            customer_id: data?.createWaterBearMarketingCustomer,
            crm_onboarding_id: crm_onboarding_id
          }
        });
      }
    }
  });

  const [submitCreateWaterBearMarketingUser] = useMutation(CREATE_WATER_BEAR_MARKETING_USER, {
    onCompleted: (data) => {
      setusers_table_id(data?.createWaterBearMarketingUser);
      setWaterBearMarketingUser(true);
    }
  }
  );

  const updateFieldHandler = (key, product_id, index) => e => {
    let MarketingGoalssubmissionDataClone = Object.assign({}, marketingGoal);

    Object.keys(MarketingGoalssubmissionDataClone).map((item, i) => {
      if (index === i) {
        MarketingGoalssubmissionDataClone[index] = Object.assign({}, MarketingGoalssubmissionDataClone[index], { [key]: e.target.value, "product_id": product_id });
      }
      setMarketingGoal({
        ...MarketingGoalssubmissionDataClone
      });
    })
  };

  const [requestResetPassword] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      skip: !CRMPeople?.email,
      variables: { email: CRMPeople?.email, signup: 1 }
    }
  );

  const [submitUpdateCRMActivity] = useMutation(UPDATE_CRM_ACTIVITY,
    {
      onCompleted: async () => {
        LoadNewNotifications();
      }
    });

  const [SendCustomerOnboardingEmail] = useMutation(
    SEND_CUSTOMER_ONBOARDING_EMAIL,
    {
      skip: !CRMPeople?.email,
      variables: {
        email: CRMPeople?.email,
        customer: CRMNewCustomerID,
        id: crm_customer_id,
        peopleid: crm_people_id,
        contractid: crm_contract_id,
        onboarding_id: crm_onboarding_id
      }
    }
  );

  const updateDatabaseBusinessInfo = async (profile_image, customer_status) => {
    if (!CRMCustomers?.business_name || CRMCustomers?.business_name?.length === 0) {
      alert("Business Name is required.");
      return;
    } else if (!CRMCustomers?.office_address_line_one || CRMCustomers?.office_address_line_one?.length === 0) {
      alert("Address Line 1 is required.");
      return;
    } else if (!CRMCustomers?.office_city || CRMCustomers?.office_city?.length === 0) {
      alert("City is required.");
      return;
    } else if (!CRMCustomers?.office_state || CRMCustomers?.office_state?.length === 0) {
      alert("State is required.");
      return;
    } else if (!CRMCustomers?.office_zip || CRMCustomers?.office_zip?.length === 0) {
      alert("Zip is required.");
      return;
    } else if (!CRMCustomers?.office_country || CRMCustomers?.office_country?.length === 0) {
      alert("Country is required.");
      return;
    } else if (!CRMCustomers?.office_phone_number || CRMCustomers?.office_phone_number?.length === 0) {
      alert("Phone is required.");
      return;
    } else if (!CRMCustomers?.website || CRMCustomers?.website?.length === 0) {
      alert("Website is required.");
      return;
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers) {
      await submitUpdateCRMRequest({
        fetchPolicy: "no-cache",
        variables: {
          customer_id: 38,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "representative_id", "first_name", "last_name", "status",
              "revenue", "lead_status", "income_targeted", "accepted_payments", "ages_targeted", "gender_targeted",
              "target_parent"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            ages_18_24: Object.keys(ages_18_24)?.map((keyName) => { return { product_id: ages_18_24[keyName] } }),
            ages_25_34: Object.keys(ages_25_34)?.map((keyName) => { return { product_id: ages_25_34[keyName] } }),
            ages_35_44: Object.keys(ages_35_44)?.map((keyName) => { return { product_id: ages_35_44[keyName] } }),
            ages_45_54: Object.keys(ages_45_54)?.map((keyName) => { return { product_id: ages_45_54[keyName] } }),
            ages_55_64: Object.keys(ages_55_64)?.map((keyName) => { return { product_id: ages_55_64[keyName] } }),
            ages_65_plus: Object.keys(ages_65_plus)?.map((keyName) => { return { product_id: ages_65_plus[keyName] } }),
            ages_unknown: Object.keys(ages_unknown)?.map((keyName) => { return { product_id: ages_unknown[keyName] } }),
            ages_no_restrictions: Object.keys(ages_no_restrictions)?.map((keyName) => { return { product_id: ages_no_restrictions[keyName] } }),
            income_top_10_percent: Object.keys(income_top_10_percent)?.map((keyName) => { return { product_id: income_top_10_percent[keyName] } }),
            income_11_20_percent: Object.keys(income_11_20_percent)?.map((keyName) => { return { product_id: income_11_20_percent[keyName] } }),
            income_21_30_percent: Object.keys(income_21_30_percent)?.map((keyName) => { return { product_id: income_21_30_percent[keyName] } }),
            income_31_40_percent: Object.keys(income_31_40_percent)?.map((keyName) => { return { product_id: income_31_40_percent[keyName] } }),
            income_41_50_percent: Object.keys(income_41_50_percent)?.map((keyName) => { return { product_id: income_41_50_percent[keyName] } }),
            income_lower_50_percent: Object.keys(income_lower_50_percent)?.map((keyName) => { return { product_id: income_lower_50_percent[keyName] } }),
            income_unknown: Object.keys(income_unknown)?.map((keyName) => { return { product_id: income_unknown[keyName] } }),
            gender_no_restrictions: Object.keys(gender_no_restrictions)?.map((keyName) => { return { product_id: gender_no_restrictions[keyName] } }),
            gender_male: Object.keys(gender_male)?.map((keyName) => { return { product_id: gender_male[keyName] } }),
            gender_female: Object.keys(gender_female)?.map((keyName) => { return { product_id: gender_female[keyName] } }),
            not_a_parent: Object.keys(not_a_parent)?.map((keyName) => { return { product_id: not_a_parent[keyName] } }),
            parent_true: Object.keys(parent_true)?.map((keyName) => { return { product_id: parent_true[keyName] } }),
            unknown_parent: Object.keys(unknown_parent)?.map((keyName) => { return { product_id: unknown_parent[keyName] } }),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
            status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
            users_id: user_id,
            representative_id: CRMCustomers?.representative_id ? CRMCustomers?.representative_id : 1
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const CreateWaterBearMarketingCustomer = async () => {
    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers) {
      await submitCreateWaterBearMarketingCustomer({
        fetchPolicy: "no-cache",
        variables: {
          crm_onboarding_id: crm_onboarding_id,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "representative_id",
              "first_name", "last_name", "status", "revenue", "revenue",
              "income_targeted", "accepted_payments", "ages_targeted", "gender_targeted",
              "target_parent", "lead_status"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            user_id: users_table_id,
            ad_consultant_id: parseInt(CRMContract?.filter((CurrentContract) => CurrentContract?.id === crm_contract_id)?.map((CurrentContract) => CurrentContract?.assigned_to).join(""))
          }
        }
      });
    }
  };

  const updateDatabasDashboardUserInfo = async () => {
    if (!CRMPeople?.first_name || CRMPeople?.first_name?.length === 0) {
      alert("First Name is required.");
      return false;
    } else if (!CRMPeople?.last_name || CRMPeople?.last_name?.length === 0) {
      alert("Last Name is required.");
      return false;
    } else if (!CRMPeople?.email || CRMPeople?.email?.length === 0) {
      alert("Email is required.");
      return false;
    } else if (!CRMPeople?.cell_phone_number || CRMPeople?.cell_phone_number?.length === 0) {
      alert("Phone is required.");
      return false;
    } else if (!CRMPeople?.billing_contact || CRMPeople?.billing_contact?.length === 0) {
      alert("Billing Contact is required.");
      return false;
    } else if (!CRMPeople?.auto_reporting_emails || CRMPeople?.auto_reporting_emails?.length === 0) {
      alert("Reporting Email is required.");
      return false;
    }

    if (CRMPeople) {
      await submitCreateWaterBearMarketingUser({
        fetchPolicy: "no-cache",
        variables: {
          customer_id: CRMNewCustomerID,
          customerEmail: {
            ...omit(CRMPeople, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text"]),
            cell_phone_number: CRMPeople?.cell_phone_number !== undefined ? CRMPeople?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
            office_phone_number: CRMPeople?.office_phone_number !== undefined ? CRMPeople?.office_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
            first_name: CRMPeople?.first_name ? CRMPeople?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
            id: crm_people_id,
            users_id: user_id,
            crm_representative_id: 1
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }

    return true;
  };

  const AddCompetitor = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestCompetitorID, customers_top_competitors: '', top_competitors_url: '' })
    sethighestCompetitorID(highestCompetitorID + 1)
    setcompetitorData({ ...competitorData, [competitorData?.length || Object.keys(competitorData)?.length]: NewArray });
  }

  const AddWebsiteInspiration = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestWebsiteInspirationID, website: '', notes: '' })
    sethighestWebsiteInspirationID(highestWebsiteInspirationID + 1)
    setwebsiteinspirationData({ ...websiteinspirationData, [websiteinspirationData?.length || Object.keys(websiteinspirationData)?.length]: NewArray });
  }

  const AddVideoInspiration = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestVideoInspirationID, website: '', notes: '' })
    sethighestVideoInspirationID(highestVideoInspirationID + 1)
    setvideoinspirationData({ ...videoinspirationData, [videoinspirationData?.length || Object.keys(videoinspirationData)?.length]: NewArray });
  }

  const AddLocalPage = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestLocalPageID, products_or_services: '', products_or_services_area: '' })
    sethighestLocalPageID(highestLocalPageID + 1)
    setlocalPagesData({ ...localPagesData, [localPagesData?.length || Object.keys(localPagesData)?.length]: NewArray });
  }

  const AddTrackingInfo = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTrackingInfoID, tracking_description: '', rings_to: '' })
    sethighestTrackingInfoID(highestTrackingInfoID + 1)
    setTrackingInfoData({ ...TrackingInfoData, [TrackingInfoData?.length || Object.keys(TrackingInfoData)?.length]: NewArray });
  }

  const AddBlogTopicIdea = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestBlogTopicIdeaID, blog_topic_ideas: '' })
    sethighestBlogTopicIdeaID(highestBlogTopicIdeaID + 1)
    setblogtopicideasData({ ...blogtopicideasData, [blogtopicideasData?.length || Object.keys(blogtopicideasData)?.length]: NewArray });
  }

  const AddProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestProductID, product_focus: '', product_id: product_id })
    sethighestProductID(highestProductID + 1)
    setproductData({ ...productData, [productData?.length || Object.keys(productData)?.length]: NewArray });
  }

  const AddAdditionalColor = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestAdditionalColorID, additional_color: '' })
    sethighestAdditionalColorID(highestAdditionalColorID + 1)
    setadditionalcolorsData({ ...additionalcolorsData, [additionalcolorsData?.length || Object.keys(additionalcolorsData)?.length]: NewArray });
  }

  const AddOnlineVideo = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestOnlineVideoID, online_video: '' })
    sethighestOnlineVideoID(highestOnlineVideoID + 1)
    setonlinevideoData({ ...onlinevideoData, [onlinevideoData?.length || Object.keys(onlinevideoData)?.length]: NewArray });
  }

  const AddLicense = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestLicenseID, license: '' })
    sethighestLicenseID(highestLicenseID + 1)
    setlicenseData({ ...licenseData, [licenseData?.length || Object.keys(licenseData)?.length]: NewArray });
  }

  const AddAward = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestAwardID, award: '' })
    sethighestAwardID(highestAwardID + 1)
    setawardData({ ...awardData, [awardData?.length || Object.keys(awardData)?.length]: NewArray });
  }

  const AddCoupon = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestCouponID, coupon: '' })
    sethighestCouponID(highestCouponID + 1)
    setcouponData({ ...couponData, [couponData?.length || Object.keys(couponData)?.length]: NewArray });
  }

  const AddFAQ = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestFAQID, faq_question: '' })
    sethighestFAQID(highestFAQID + 1)
    setfaqData({ ...faqData, [faqData?.length || Object.keys(faqData)?.length]: NewArray });
  }

  const AddNoFocusProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestNoFocusProductID, no_product_focus: '', product_id: product_id })
    sethighestNoFocusProductID(highestNoFocusProductID + 1)
    setnoFocusProductData({ ...noFocusProductData, [noFocusProductData?.length || Object.keys(noFocusProductData)?.length]: NewArray });
  }

  const AddTargetingInterest = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTargetingInterestID, targeting_interests: '', product_id: product_id })
    sethighestTargetingInterestID(highestTargetingInterestID + 1)
    settargetinginterestsData({ ...targetinginterestsData, [targetinginterestsData?.length || Object.keys(targetinginterestsData)?.length]: NewArray });
  }

  const AddTargetJobTitle = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTargetJobTitleID, target_job_title: '', product_id: product_id })
    sethighestTargetJobTitleID(highestTargetJobTitleID + 1)
    settargetjobtitleData({ ...targetjobtitleData, [targetjobtitleData?.length || Object.keys(targetjobtitleData)?.length]: NewArray });
  }

  const AddTargetIndustry = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTargetIndustryID, target_industries: '', product_id: product_id })
    sethighestTargetIndustryID(highestTargetIndustryID + 1)
    settargetindustryData({ ...targetindustryData, [targetindustryData?.length || Object.keys(targetindustryData)?.length]: NewArray });
  }

  const AddBrand = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestBrandID, brand: '' })
    sethighestBrandID(highestBrandID + 1)
    setbrandData({ ...brandData, [brandData?.length || Object.keys(brandData)?.length]: NewArray });
  }

  const AddCertificate = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestCertID, certificate: '' })
    sethighestCertID(highestCertID + 1)
    setcertificateData({ ...certificateData, [certificateData?.length || Object.keys(certificateData)?.length]: NewArray });
  }

  const AddTargetArea = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTopTargetAreasID, customer_top_target_area: '', customer_top_target_type: '', product_id: product_id })
    sethighestTopTargetAreasID(highestTopTargetAreasID + 1)
    settargetAreaData({ ...targetAreaData, [targetAreaData?.length || Object.keys(targetAreaData)?.length]: NewArray });
  }

  const AddServiceArea = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestServiceAreasID, customer_top_service_area: '', customer_top_service_area_type: '', product_id: product_id })
    sethighestServiceAreasID(highestServiceAreasID + 1)
    setServiceAreaData({ ...ServiceAreaData, [ServiceAreaData?.length || Object.keys(ServiceAreaData)?.length]: NewArray });
  }

  const RemoveCompetitor = (key) => e => {
    setcompetitorData(Object.keys(competitorData)?.filter((Competitor) => competitorData[Competitor].id !== key)?.map((Competitor) => competitorData[Competitor]));
  };

  const RemoveProduct = (key) => e => {
    setproductData(Object.keys(productData)?.filter((CurrentProduct) => productData[CurrentProduct].id !== key)?.map((CurrentProduct) => productData[CurrentProduct]));
  };

  const RemoveNoFocusProduct = (key) => e => {
    setnoFocusProductData(Object.keys(noFocusProductData)?.filter((noFocusProduct) => noFocusProductData[noFocusProduct].id !== key)?.map((noFocusProduct) => noFocusProductData[noFocusProduct]));
  };

  const RemoveTargetArea = (key) => e => {
    settargetAreaData(Object.keys(targetAreaData)?.filter((newTargetArea) => targetAreaData[newTargetArea].id !== key)?.map((newTargetArea) => targetAreaData[newTargetArea]));
  };

  const RemoveTargetIndustries = (key) => e => {
    settargetindustryData(Object.keys(targetindustryData)?.filter((TargetIndustry) => targetindustryData[TargetIndustry].id !== key)?.map((TargetIndustry) => targetindustryData[TargetIndustry]));
  };

  const RemoveWebsiteInspiration = (key) => e => {
    setwebsiteinspirationData(Object.keys(websiteinspirationData)?.filter((WebsiteInspiration) => websiteinspirationData[WebsiteInspiration].id !== key)?.map((WebsiteInspiration) => websiteinspirationData[WebsiteInspiration]));
  };

  const RemoveVideoInspiration = (key) => e => {
    setvideoinspirationData(Object.keys(videoinspirationData)?.filter((VideoInspiration) => videoinspirationData[VideoInspiration].id !== key)?.map((VideoInspiration) => videoinspirationData[VideoInspiration]));
  };

  const RemoveLocalPage = (key) => e => {
    setlocalPagesData(Object.keys(localPagesData)?.filter((LocalPage) => localPagesData[LocalPage].id !== key)?.map((LocalPage) => localPagesData[LocalPage]));
  };

  const RemoveTrackingInfo = (key) => e => {
    setTrackingInfoData(Object.keys(TrackingInfoData)?.filter((TrackingInfo) => TrackingInfoData[TrackingInfo].id !== key)?.map((LocalPage) => TrackingInfoData[TrackingInfo]));
  };

  const RemoveBlogTopicIdea = (key) => e => {
    setblogtopicideasData(Object.keys(blogtopicideasData)?.filter((BlogTopicIdeas) => blogtopicideasData[BlogTopicIdeas].id !== key)?.map((BlogTopicIdeas) => blogtopicideasData[BlogTopicIdeas]));
  };

  const RemoveAdditionalColor = (key) => e => {
    setadditionalcolorsData(Object.keys(additionalcolorsData)?.filter((AdditionalColor) => additionalcolorsData[AdditionalColor].id !== key)?.map((AdditionalColor) => additionalcolorsData[AdditionalColor]));
  };

  const RemoveOnlineVideo = (key) => e => {
    setonlinevideoData(Object.keys(onlinevideoData)?.filter((OnlineVideo) => onlinevideoData[OnlineVideo].id !== key)?.map((OnlineVideo) => onlinevideoData[OnlineVideo]));
  };

  const RemoveTargetingInterests = (key) => e => {
    settargetinginterestsData(Object.keys(targetinginterestsData)?.filter((TargetingInterest) => targetinginterestsData[TargetingInterest].id !== key)?.map((TargetingInterest) => targetinginterestsData[TargetingInterest]));
  };

  const RemoveTargetJobTitle = (key) => e => {
    settargetjobtitleData(Object.keys(targetjobtitleData)?.filter((TargetJobTitle) => targetjobtitleData[TargetJobTitle].id !== key)?.map((TargetJobTitle) => targetjobtitleData[TargetJobTitle]));
  };

  const RemoveCertificate = (key) => e => {
    setcertificateData(Object.keys(certificateData)?.filter((Certificate) => certificateData[Certificate].id !== key)?.map((Certificate) => certificateData[Certificate]));
  };

  const RemoveLicense = (key) => e => {
    setlicenseData(Object.keys(licenseData)?.filter((License) => licenseData[License].id !== key)?.map((License) => licenseData[License]));
  };

  const RemoveAward = (key) => e => {
    setawardData(Object.keys(awardData)?.filter((Award) => awardData[Award].id !== key)?.map((Award) => awardData[Award]));
  };

  const RemoveCoupon = (key) => e => {
    setcouponData(Object.keys(couponData)?.filter((Coupon) => couponData[Coupon].id !== key)?.map((Coupon) => couponData[Coupon]));
  };

  const RemoveFAQ = (key) => e => {
    setfaqData(Object.keys(faqData)?.filter((FAQ) => faqData[FAQ].id !== key)?.map((FAQ) => faqData[FAQ]));
  };

  const RemoveBrand = (key) => e => {
    setbrandData(Object.keys(brandData)?.filter((Brand) => brandData[Brand].id !== key)?.map((Brand) => brandData[Brand]));
  };

  const RemoveServiceArea = (key) => e => {
    setServiceAreaData(Object.keys(ServiceAreaData)?.filter((newTargetArea) => ServiceAreaData[newTargetArea].id !== key)?.map((newTargetArea) => ServiceAreaData[newTargetArea]));
  };

  function updateTopCompetitors(index, key, e) {
    let EstimatesubmissionDataClone = Object.assign({}, competitorData);
    Object.keys(EstimatesubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "customers_top_competitors") {
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { customers_top_competitors: e.target.value });
        }
        else
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { top_competitors_url: e.target.value });
      }
      setcompetitorData({ ...EstimatesubmissionDataClone });
    })
  };

  function updateWebsiteInspiration(index, key, e) {
    let WebsiteInspirationsubmissionDataClone = Object.assign({}, websiteinspirationData);
    Object.keys(WebsiteInspirationsubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "website") {
          WebsiteInspirationsubmissionDataClone[i] = Object.assign({}, WebsiteInspirationsubmissionDataClone[i], { website: e.target.value });
        }
        else
          WebsiteInspirationsubmissionDataClone[i] = Object.assign({}, WebsiteInspirationsubmissionDataClone[i], { notes: e.target.value });
      }
      setwebsiteinspirationData({ ...WebsiteInspirationsubmissionDataClone });
    })
  };

  function updateVideoInspiration(index, key, e) {
    let VideoInspirationsubmissionDataClone = Object.assign({}, videoinspirationData);
    Object.keys(VideoInspirationsubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "website") {
          VideoInspirationsubmissionDataClone[i] = Object.assign({}, VideoInspirationsubmissionDataClone[i], { website: e.target.value });
        }
        else
          VideoInspirationsubmissionDataClone[i] = Object.assign({}, VideoInspirationsubmissionDataClone[i], { notes: e.target.value });
      }
      setvideoinspirationData({ ...VideoInspirationsubmissionDataClone });
    })
  };

  function updateLocalPages(index, key, e) {
    let LocalPagessubmissionDataClone = Object.assign({}, localPagesData);
    Object.keys(LocalPagessubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "products_services") {
          LocalPagessubmissionDataClone[i] = Object.assign({}, LocalPagessubmissionDataClone[i], { products_or_services: e.target.value });
        }
        else
          LocalPagessubmissionDataClone[i] = Object.assign({}, LocalPagessubmissionDataClone[i], { products_or_services_area: e.target.value });
      }
      setlocalPagesData({ ...LocalPagessubmissionDataClone });
    })
  };

  function updateTrackingInfo(index, key, e) {
    let TrackingInfosubmissionDataClone = Object.assign({}, TrackingInfoData);
    Object.keys(TrackingInfosubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "tracking_description") {
          TrackingInfosubmissionDataClone[i] = Object.assign({}, TrackingInfosubmissionDataClone[i], { tracking_description: e.target.value });
        }
        else
          TrackingInfosubmissionDataClone[i] = Object.assign({}, TrackingInfosubmissionDataClone[i], { rings_to: e.target.value });
      }
      setTrackingInfoData({ ...TrackingInfosubmissionDataClone });
    })
  };

  function updateBlogTopicIdeas(index, e) {
    let BlogTopicIdeassubmissionDataClone = Object.assign({}, blogtopicideasData);
    Object.keys(BlogTopicIdeassubmissionDataClone).map((item, i) => {
      if (index === i) {
        BlogTopicIdeassubmissionDataClone[i] = Object.assign({}, BlogTopicIdeassubmissionDataClone[i], { blog_topic_ideas: e.target.value });
      }
      setblogtopicideasData({ ...BlogTopicIdeassubmissionDataClone });
    })
  };

  function updateTopProducts(index, e, product_id) {
    let ProductsubmissionDataClone = Object.assign({}, productData);
    Object.keys(ProductsubmissionDataClone).map((item, i) => {
      if (index === i && product_id === ProductsubmissionDataClone[i]?.product_id) {
        ProductsubmissionDataClone[i] = Object.assign({}, ProductsubmissionDataClone[i], { product_focus: e.target.value, product_id: product_id });
      }
      setproductData({ ...ProductsubmissionDataClone });
    })
  };

  function updateAdditionalColors(index, e) {
    let ProductsubmissionDataClone = Object.assign({}, additionalcolorsData);
    Object.keys(ProductsubmissionDataClone).map((item, i) => {
      if (index === i) {
        ProductsubmissionDataClone[i] = Object.assign({}, ProductsubmissionDataClone[i], { additional_color: e.target.value });
      }
      setadditionalcolorsData({ ...ProductsubmissionDataClone });
    })
  };

  function updateOnlineVideo(index, e) {
    let OnlineVideosubmissionDataClone = Object.assign({}, onlinevideoData);
    Object.keys(OnlineVideosubmissionDataClone).map((item, i) => {
      if (index === i) {
        OnlineVideosubmissionDataClone[i] = Object.assign({}, OnlineVideosubmissionDataClone[i], { online_video: e.target.value });
      }
      setonlinevideoData({ ...OnlineVideosubmissionDataClone });
    })
  };

  function updateFAQQuestions(index, e) {
    let FAQsubmissionDataClone = Object.assign({}, faqData);
    Object.keys(FAQsubmissionDataClone).map((item, i) => {
      if (index === i) {
        FAQsubmissionDataClone[i] = Object.assign({}, FAQsubmissionDataClone[i], { faq_question: e.target.value });
      }
      setfaqData({ ...FAQsubmissionDataClone });
    })
  };

  function updateFAQAnswers(index, e) {
    let FAQsubmissionDataClone = Object.assign({}, faqData);
    Object.keys(FAQsubmissionDataClone).map((item, i) => {
      if (index === i) {
        FAQsubmissionDataClone[i] = Object.assign({}, FAQsubmissionDataClone[i], { faq_answer: e.target.value });
      }
      setfaqData({ ...FAQsubmissionDataClone });
    })
  };

  function updateCouponsStartDate(index, e) {
    let CouponsubmissionDataClone = Object.assign({}, couponData);
    Object.keys(CouponsubmissionDataClone).map((item, i) => {
      if (index === i) {
        CouponsubmissionDataClone[i] = Object.assign({}, CouponsubmissionDataClone[i], { coupon_start_date: e.target.value });
      }
      setcouponData({ ...CouponsubmissionDataClone });
    })
  };

  function updateCouponsStopDate(index, e) {
    let CouponsubmissionDataClone = Object.assign({}, couponData);
    Object.keys(CouponsubmissionDataClone).map((item, i) => {
      if (index === i) {
        CouponsubmissionDataClone[i] = Object.assign({}, CouponsubmissionDataClone[i], { coupon_stop_date: e.target.value });
      }
      setcouponData({ ...CouponsubmissionDataClone });
    })
  };

  function updateCouponsWalkins(index, e) {
    let CouponsubmissionDataClone = Object.assign({}, couponData);
    Object.keys(CouponsubmissionDataClone).map((item, i) => {
      if (index === i) {
        CouponsubmissionDataClone[i] = Object.assign({}, CouponsubmissionDataClone[i], { coupon_walkins: e.target.checked === true ? 1 : 0 });
      }
      setcouponData({ ...CouponsubmissionDataClone });
    })
  };

  function updateCoupons(index, e) {
    let CouponsubmissionDataClone = Object.assign({}, couponData);
    Object.keys(CouponsubmissionDataClone).map((item, i) => {
      if (index === i) {
        CouponsubmissionDataClone[i] = Object.assign({}, CouponsubmissionDataClone[i], { coupon: e.target.value });
      }
      setcouponData({ ...CouponsubmissionDataClone });
    })
  };

  function updateAwards(index, e) {
    let AwardsubmissionDataClone = Object.assign({}, awardData);
    Object.keys(AwardsubmissionDataClone).map((item, i) => {
      if (index === i) {
        AwardsubmissionDataClone[i] = Object.assign({}, AwardsubmissionDataClone[i], { award: e.target.value });
      }
      setawardData({ ...AwardsubmissionDataClone });
    })
  };

  function updateLicenses(index, e) {
    let LicensesubmissionDataClone = Object.assign({}, licenseData);
    Object.keys(LicensesubmissionDataClone).map((item, i) => {
      if (index === i) {
        LicensesubmissionDataClone[i] = Object.assign({}, LicensesubmissionDataClone[i], { license: e.target.value });
      }
      setlicenseData({ ...LicensesubmissionDataClone });
    })
  };

  function updateCertificate(index, e) {
    let CertificatesubmissionDataClone = Object.assign({}, certificateData);
    Object.keys(CertificatesubmissionDataClone).map((item, i) => {
      if (index === i) {
        CertificatesubmissionDataClone[i] = Object.assign({}, CertificatesubmissionDataClone[i], { certificate: e.target.value });
      }
      setcertificateData({ ...CertificatesubmissionDataClone });
    })
  };

  function updateBrands(index, e) {
    let BrandsubmissionDataClone = Object.assign({}, brandData);
    Object.keys(BrandsubmissionDataClone).map((item, i) => {
      if (index === i) {
        BrandsubmissionDataClone[i] = Object.assign({}, BrandsubmissionDataClone[i], { brand: e.target.value });
      }
      setbrandData({ ...BrandsubmissionDataClone });
    })
  };

  function updateNoFocusProducts(index, e, product_id) {
    let NoFocussubmissionDataClone = Object.assign({}, noFocusProductData);
    Object.keys(NoFocussubmissionDataClone).map((item, i) => {
      if (index === i && product_id === NoFocussubmissionDataClone[i]?.product_id) {
        NoFocussubmissionDataClone[i] = Object.assign({}, NoFocussubmissionDataClone[i], { no_product_focus: e.target.value, product_id: product_id });
      }
      setnoFocusProductData({ ...NoFocussubmissionDataClone });
    })
  };

  function updateTargetingInterests(index, e, product_id) {
    let TargetingInterestssubmissionDataClone = Object.assign({}, targetinginterestsData);
    Object.keys(TargetingInterestssubmissionDataClone).map((item, i) => {
      if (index === i) {
        TargetingInterestssubmissionDataClone[i] = Object.assign({}, TargetingInterestssubmissionDataClone[i], { targeting_interests: e.target.value, product_id: product_id });
      }
      settargetinginterestsData({ ...TargetingInterestssubmissionDataClone });
    })
  };

  function updateTargetJobTitle(index, e, product_id) {
    let TargetJobTitlesubmissionDataClone = Object.assign({}, targetjobtitleData);
    Object.keys(TargetJobTitlesubmissionDataClone).map((item, i) => {
      if (index === i) {
        TargetJobTitlesubmissionDataClone[i] = Object.assign({}, TargetJobTitlesubmissionDataClone[i], { target_job_title: e.target.value, product_id: product_id });
      }
      settargetjobtitleData({ ...TargetJobTitlesubmissionDataClone });
    })
  };

  function updateTargetIndustries(index, e, product_id) {
    let TargetIndustriessubmissionDataClone = Object.assign({}, targetindustryData);
    Object.keys(TargetIndustriessubmissionDataClone).map((item, i) => {
      if (index === i) {
        TargetIndustriessubmissionDataClone[i] = Object.assign({}, TargetIndustriessubmissionDataClone[i], { target_industries: e.target.value, product_id: product_id });
      }
      settargetindustryData({ ...TargetIndustriessubmissionDataClone });
    })
  };

  function updateTargetAreas(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_area: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  function updateServiceArea(index, e, product_id) {
    let ServiceAreasubmissionDataClone = Object.assign({}, ServiceAreaData);
    Object.keys(ServiceAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === ServiceAreasubmissionDataClone[i]?.product_id) {
        ServiceAreasubmissionDataClone[i] = Object.assign({}, ServiceAreasubmissionDataClone[i], { customer_top_service_area: e.target.value, product_id: product_id });
      }
      setServiceAreaData({ ...ServiceAreasubmissionDataClone });
    })
  };

  function updateTargetType(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_type: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  function updateCouponType(index, e) {
    let CouponTypesubmissionDataClone = Object.assign({}, couponData);
    Object.keys(CouponTypesubmissionDataClone).map((item, i) => {
      if (index === i) {
        CouponTypesubmissionDataClone[i] = Object.assign({}, CouponTypesubmissionDataClone[i], { coupon_type: e.target.value });
      }
      setcouponData({ ...CouponTypesubmissionDataClone });
    })
  };

  function updateServiceAreTargetType(index, e, product_id) {
    let ServiceAreasubmissionDataClone = Object.assign({}, ServiceAreaData);
    Object.keys(ServiceAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === ServiceAreasubmissionDataClone[i]?.product_id) {
        ServiceAreasubmissionDataClone[i] = Object.assign({}, ServiceAreasubmissionDataClone[i], { customer_top_service_area_type: e.target.value, product_id: product_id });
      }
      setServiceAreaData({ ...ServiceAreasubmissionDataClone });
    })
  };

  const updatePersonFieldHandler = (key) => e => {
    if (key === "cell_phone_number") {
      setCRMPeople({
        ...CRMPeople,
        [key]: e.target.value,
        ["cell_phone"]: e.target.value
      })
    } else {
      setCRMPeople({
        ...CRMPeople,
        [key]: e.target.value
      })
    }

    let GotAllDashboardUserInfoRequired = true;

    if (key === "first_name" && e.target.value?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    } else if (key === "last_name" && e.target.value?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    } else if (key === "email" && e.target.value?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    } else if (key === "cell_phone_number" && e.target.value?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    } else if (!CRMPeople?.billing_contact || CRMPeople?.billing_contact?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    } else if (!CRMPeople?.auto_reporting_emails || CRMPeople?.auto_reporting_emails?.length === 0) {
      GotAllDashboardUserInfoRequired = false;
    }

    setGotAllDashboardUserInfoRequiredState(GotAllDashboardUserInfoRequired);
  };

  const updateWebsiteDesignFieldHandler = (key) => e => {
    if (key === "website_easter_eggs") {
      setWebsiteDesignsubmissiondata({
        ...WebsiteDesignsubmissiondata,
        [key]: e.target.checked === true ? 1 : 0
      })
    } else {
      setWebsiteDesignsubmissiondata({
        ...WebsiteDesignsubmissiondata,
        [key]: e.target.value
      })
    }
  };

  function DuplicateHighPriorityLocations(product_id) {
    if (duplicateHighPriorityLocations === false) {
      let ArrayIndex = highestTopTargetAreasID;
      let TargetAreasubmissionDataClone = Object.keys(targetAreaData)?.filter((keyName) =>
        targetAreaData[keyName]?.product_id === product_id)
        ?.map((keyName) => targetAreaData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === PaidSearch_id ||
        CurrentBudget?.product_id === OrganicSearchTwo_id ||
        CurrentBudget?.product_id === OrganicSearchFour_id ||
        CurrentBudget?.product_id === OrganicSearchRegional_id ||
        CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === LocalServicesAds_id ||
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === LinearCRM_id
      ))?.map((CurrentBudget, index) => {
        if (CurrentBudget?.product_id === PaidSearch_id ||
          CurrentBudget?.product_id === OrganicSearchTwo_id ||
          CurrentBudget?.product_id === OrganicSearchFour_id ||
          CurrentBudget?.product_id === OrganicSearchRegional_id ||
          CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
          CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
          CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
          CurrentBudget?.product_id === BannerAdsReEngage_id ||
          CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
          CurrentBudget?.product_id === OnlineVideoReEngage_id ||
          CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
          CurrentBudget?.product_id === WebsiteDesign_id ||
          CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
          CurrentBudget?.product_id === OCAGeoDemo_id ||
          CurrentBudget?.product_id === OCAReEngage_id ||
          CurrentBudget?.product_id === OCAGeoIntention_id ||
          CurrentBudget?.product_id === LocalServicesAds_id ||
          CurrentBudget?.product_id === SocialAdsReEngage_id ||
          CurrentBudget?.product_id === LinearCRM_id)
          Object.keys(TargetAreasubmissionDataClone)?.filter((keyName) =>
            TargetAreasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
              TargetAreasubmissionDataClone.push(Object.assign({}, omit(TargetAreasubmissionDataClone[keyName], ["product_id", "id"]), {
                product_id: CurrentBudget?.product_id, id: ArrayIndex
              }));
              ArrayIndex += 1;
            })
      })

      sethighestTopTargetAreasID(ArrayIndex);
      settargetAreaData({ ...TargetAreasubmissionDataClone });
      setduplicateHighPriorityLocations(true);
    }
  };

  function DuplicateNoFocusProducts(product_id) {
    if (duplicateNoFocusProducts === false) {
      let ArrayIndex = highestNoFocusProductID;
      let NoFocusProductsubmissionDataClone = Object.keys(noFocusProductData)?.filter((keyName) =>
        noFocusProductData[keyName]?.product_id === product_id)
        ?.map((keyName) => noFocusProductData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === PaidSearch_id ||
        CurrentBudget?.product_id === OrganicSearchTwo_id ||
        CurrentBudget?.product_id === OrganicSearchFour_id ||
        CurrentBudget?.product_id === OrganicSearchRegional_id ||
        CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === Hydrate_id ||
        CurrentBudget?.product_id === OutOfHome_id ||
        CurrentBudget?.product_id === LocalServicesAds_id ||
        CurrentBudget?.product_id === SocialAdsReEngage_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === PaidSearch_id ||
            CurrentBudget?.product_id === OrganicSearchTwo_id ||
            CurrentBudget?.product_id === OrganicSearchFour_id ||
            CurrentBudget?.product_id === OrganicSearchRegional_id ||
            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === Hydrate_id ||
            CurrentBudget?.product_id === OutOfHome_id ||
            CurrentBudget?.product_id === LocalServicesAds_id ||
            CurrentBudget?.product_id === SocialAdsReEngage_id)
            Object.keys(NoFocusProductsubmissionDataClone)?.filter((keyName) =>
              NoFocusProductsubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                NoFocusProductsubmissionDataClone.push(Object.assign({}, omit(NoFocusProductsubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));

                ArrayIndex += 1;
              })
        })

      sethighestNoFocusProductID(ArrayIndex);
      setnoFocusProductData({ ...NoFocusProductsubmissionDataClone });
      setduplicateNoFocusProducts(true);
    }
  };

  function DuplicateLeadGoals(product_id) {
    if (duplicateLeadGoals === false) {
      let ArrayIndex = highestmarketingGoalID;
      let LeadGoalssubmissionDataClone = Object.keys(marketingGoal)?.filter((keyName) =>
        marketingGoal[keyName]?.product_id === product_id)
        ?.map((keyName) => marketingGoal[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === PaidSearch_id ||
        CurrentBudget?.product_id === LocalServicesAds_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OutOfHome_id ||
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OrganicSearchTwo_id ||
        CurrentBudget?.product_id === OrganicSearchFour_id ||
        CurrentBudget?.product_id === OrganicSearchRegional_id ||
        CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === PaidSearch_id ||
            CurrentBudget?.product_id === LocalServicesAds_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OutOfHome_id ||
            CurrentBudget?.product_id === SocialAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === OrganicSearchTwo_id ||
            CurrentBudget?.product_id === OrganicSearchFour_id ||
            CurrentBudget?.product_id === OrganicSearchRegional_id ||
            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id)
            Object.keys(marketingGoal)?.filter((keyName) =>
              marketingGoal[keyName]?.product_id === product_id)?.map((keyName, i) => {
                LeadGoalssubmissionDataClone.push(Object.assign({}, omit(marketingGoal[0], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));
                ArrayIndex += 1;
              })
        })

      sethighestmarketingGoalID(ArrayIndex);
      setMarketingGoal({ ...LeadGoalssubmissionDataClone });
      setduplicateLeadGoals(true);
    }
  };

  function DuplicateTopProducts(product_id) {
    if (duplicateTopProducts === false) {
      let ArrayIndex = highestProductID;
      let TopProductsubmissionDataClone = Object.keys(productData)?.filter((keyName) =>
        productData[keyName]?.product_id === product_id)
        ?.map((keyName) => productData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === PaidSearch_id ||
        CurrentBudget?.product_id === OrganicSearchTwo_id ||
        CurrentBudget?.product_id === OrganicSearchFour_id ||
        CurrentBudget?.product_id === OrganicSearchRegional_id ||
        CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === Hydrate_id ||
        CurrentBudget?.product_id === OutOfHome_id ||
        CurrentBudget?.product_id === LocalServicesAds_id ||
        CurrentBudget?.product_id === SocialAdsReEngage_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === PaidSearch_id ||
            CurrentBudget?.product_id === OrganicSearchTwo_id ||
            CurrentBudget?.product_id === OrganicSearchFour_id ||
            CurrentBudget?.product_id === OrganicSearchRegional_id ||
            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === Hydrate_id ||
            CurrentBudget?.product_id === OutOfHome_id ||
            CurrentBudget?.product_id === LocalServicesAds_id ||
            CurrentBudget?.product_id === SocialAdsReEngage_id)
            Object.keys(TopProductsubmissionDataClone)?.filter((keyName) =>
              TopProductsubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                TopProductsubmissionDataClone.push(Object.assign({}, omit(TopProductsubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));
                ArrayIndex += 1;
              })
        })

      sethighestProductID(ArrayIndex);
      setproductData({ ...TopProductsubmissionDataClone });
      setduplicateTopProducts(true);
    }
  };

  function DuplicateServiceAreas(product_id) {
    if (duplicateServiceAreas === false) {
      let ArrayIndex = highestServiceAreasID;
      let ServiceAreaDatasubmissionDataClone = Object.keys(ServiceAreaData)?.filter((keyName) =>
        ServiceAreaData[keyName]?.product_id === product_id)
        ?.map((keyName) => ServiceAreaData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === PaidSearch_id ||
        CurrentBudget?.product_id === OrganicSearchTwo_id ||
        CurrentBudget?.product_id === OrganicSearchFour_id ||
        CurrentBudget?.product_id === OrganicSearchRegional_id ||
        CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === LocalServicesAds_id ||
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === LinearCRM_id
      ))?.map((CurrentBudget, index) => {
        if (CurrentBudget?.product_id === PaidSearch_id ||
          CurrentBudget?.product_id === OrganicSearchTwo_id ||
          CurrentBudget?.product_id === OrganicSearchFour_id ||
          CurrentBudget?.product_id === OrganicSearchRegional_id ||
          CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
          CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
          CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
          CurrentBudget?.product_id === BannerAdsReEngage_id ||
          CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
          CurrentBudget?.product_id === OnlineVideoReEngage_id ||
          CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
          CurrentBudget?.product_id === WebsiteDesign_id ||
          CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
          CurrentBudget?.product_id === OCAGeoDemo_id ||
          CurrentBudget?.product_id === OCAReEngage_id ||
          CurrentBudget?.product_id === OCAGeoIntention_id ||
          CurrentBudget?.product_id === LocalServicesAds_id ||
          CurrentBudget?.product_id === SocialAdsReEngage_id ||
          CurrentBudget?.product_id === LinearCRM_id)
          Object.keys(ServiceAreaDatasubmissionDataClone)?.filter((keyName) =>
            ServiceAreaDatasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
              ServiceAreaDatasubmissionDataClone.push(Object.assign({}, omit(ServiceAreaDatasubmissionDataClone[keyName], ["product_id", "id"]), {
                product_id: CurrentBudget?.product_id, id: ArrayIndex
              }));
              ArrayIndex += 1;
            })
      })
      sethighestServiceAreasID(ArrayIndex);
      setServiceAreaData({ ...ServiceAreaDatasubmissionDataClone });
      setduplicateServiceAreas(true);
    }
  }

  function DuplicateTargeting(product_id) {
    /// targetindustryData
    if (duplicateTargeting === false) {
      let ArrayIndex = highestTargetIndustryID;
      let targetindustryDatasubmissionDataClone = Object.keys(targetindustryData)?.filter((keyName) =>
        targetindustryData[keyName]?.product_id === product_id)
        ?.map((keyName) => targetindustryData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === SocialAdsReEngage_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === OutOfHome_id) {
            Object.keys(targetindustryDatasubmissionDataClone)?.filter((keyName) =>
              targetindustryDatasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                targetindustryDatasubmissionDataClone.push(Object.assign({}, omit(targetindustryDatasubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));
                ArrayIndex += 1;
              })
          }
        })

      sethighestTargetIndustryID(ArrayIndex);
      settargetindustryData({ ...targetindustryDatasubmissionDataClone });

      //targetjobtitleData
      ArrayIndex = highestTargetJobTitleID;
      let targetjobtitleDataasubmissionDataClone = Object.keys(targetjobtitleData)?.filter((keyName) =>
        targetjobtitleData[keyName]?.product_id === product_id)
        ?.map((keyName) => targetjobtitleData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === SocialAdsReEngage_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === OutOfHome_id) {
            Object.keys(targetjobtitleDataasubmissionDataClone)?.filter((keyName) =>
              targetjobtitleDataasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                targetjobtitleDataasubmissionDataClone.push(Object.assign({}, omit(targetjobtitleDataasubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));
                ArrayIndex += 1;
              })
          }
        })
      sethighestTargetJobTitleID(ArrayIndex);
      settargetjobtitleData({ ...targetjobtitleDataasubmissionDataClone });

      //targetinginterestsData
      ArrayIndex = highestTargetingInterestID;
      let targetinginterestsDatasubmissionDataClone = Object.keys(targetinginterestsData)?.filter((keyName) =>
        targetinginterestsData[keyName]?.product_id === product_id)
        ?.map((keyName) => targetinginterestsData[keyName])

      CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id !== product_id && (
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id))?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === SocialAdsReEngage_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === OutOfHome_id) {
            Object.keys(targetinginterestsDatasubmissionDataClone)?.filter((keyName) =>
              targetinginterestsDatasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                targetinginterestsDatasubmissionDataClone.push(Object.assign({}, omit(targetinginterestsDatasubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: CurrentBudget?.product_id, id: ArrayIndex
                }));
                ArrayIndex += 1;
              })
          }
        })
      sethighestTargetingInterestID(ArrayIndex);
      settargetinginterestsData({ ...targetinginterestsDatasubmissionDataClone });

      setduplicateTargeting(true);
    }
  }

  function CopyFirstProductAges(product_id) {
    if (duplicateAges === false) {
      let ages_no_restrictions_found = 0;

      if (typeof ages_no_restrictions === 'string' && ages_no_restrictions?.indexOf(product_id) > -1)
        ages_no_restrictions_found = product_id;
      else {
        if (Object.keys(ages_no_restrictions)?.filter((keyName) =>
          ages_no_restrictions[keyName] === product_id)
          ?.map((keyName) => ages_no_restrictions[keyName])?.length)
          ages_no_restrictions_found = product_id;
      }
      let ages_no_restrictionsDataClone = [];

      let ages_18_24_found = 0;

      if (typeof ages_18_24 === 'string' && ages_18_24?.indexOf(product_id) > -1)
        ages_18_24_found = product_id;
      else {
        if (Object.keys(ages_18_24)?.filter((keyName) =>
          ages_18_24[keyName] === product_id)
          ?.map((keyName) => ages_18_24[keyName])?.length)
          ages_18_24_found = product_id;
      }
      let ages_18_24DataClone = [];

      let ages_25_34_found = 0;

      if (typeof ages_25_34 === 'string' && ages_25_34?.indexOf(product_id) > -1)
        ages_25_34_found = product_id;
      else {
        if (Object.keys(ages_25_34)?.filter((keyName) =>
          ages_25_34[keyName] === product_id)
          ?.map((keyName) => ages_25_34[keyName])?.length)
          ages_25_34_found = product_id;
      }
      let ages_25_34DataClone = [];


      let ages_35_44_found = 0;

      if (typeof ages_35_44 === 'string' && ages_35_44?.indexOf(product_id) > -1)
        ages_35_44_found = product_id;
      else {
        if (Object.keys(ages_35_44)?.filter((keyName) =>
          ages_35_44[keyName] === product_id)
          ?.map((keyName) => ages_35_44[keyName])?.length)
          ages_35_44_found = product_id;
      }
      let ages_35_44DataClone = [];

      let ages_45_54_found = 0;

      if (typeof ages_35_44 === 'string' && ages_45_54?.indexOf(product_id) > -1)
        ages_45_54_found = product_id;
      else {
        if (Object.keys(ages_35_44)?.filter((keyName) =>
          ages_35_44[keyName] === product_id)
          ?.map((keyName) => ages_35_44[keyName])?.length)
          ages_45_54_found = product_id;
      }
      let ages_45_54DataClone = [];

      let ages_55_64_found = 0;

      if (typeof ages_55_64 === 'string' && ages_55_64?.indexOf(product_id) > -1)
        ages_55_64_found = product_id;
      else {
        if (Object.keys(ages_55_64)?.filter((keyName) =>
          ages_55_64[keyName] === product_id)
          ?.map((keyName) => ages_55_64[keyName])?.length)
          ages_55_64_found = product_id;
      }
      let ages_55_64DataClone = [];

      let ages_65_plus_found = 0;

      if (typeof ages_65_plus === 'string' && ages_65_plus?.indexOf(product_id) > -1)
        ages_65_plus_found = product_id;
      else {
        if (Object.keys(ages_65_plus)?.filter((keyName) =>
          ages_65_plus[keyName] === product_id)
          ?.map((keyName) => ages_65_plus[keyName])?.length)
          ages_65_plus_found = product_id;
      }
      let ages_65_plusDataClone = [];

      let ages_unknown_found = 0;

      if (typeof ages_unknown === 'string' && ages_unknown?.indexOf(product_id) > -1)
        ages_unknown_found = product_id;
      else {
        if (Object.keys(ages_unknown)?.filter((keyName) =>
          ages_unknown[keyName] === product_id)
          ?.map((keyName) => ages_unknown[keyName])?.length)
          ages_unknown_found = product_id;
      }
      let ages_unknownDataClone = [];

      let income_top_10_percent_found = 0;

      if (typeof income_top_10_percent === 'string' && income_top_10_percent?.indexOf(product_id) > -1)
        income_top_10_percent_found = product_id;
      else {
        if (Object.keys(income_top_10_percent)?.filter((keyName) =>
          income_top_10_percent[keyName] === product_id)
          ?.map((keyName) => income_top_10_percent[keyName])?.length)
          income_top_10_percent_found = product_id;
      }
      let income_top_10_percentDataClone = [];

      let income_11_20_percent_found = 0;

      if (typeof income_11_20_percent === 'string' && income_11_20_percent?.indexOf(product_id) > -1)
        income_11_20_percent_found = product_id;
      else {
        if (Object.keys(income_11_20_percent)?.filter((keyName) =>
          income_11_20_percent[keyName] === product_id)
          ?.map((keyName) => income_11_20_percent[keyName])?.length)
          income_11_20_percent_found = product_id;
      }
      let income_11_20_percentDataClone = [];

      let income_21_30_percent_found = 0;

      if (typeof income_21_30_percent === 'string' && income_21_30_percent?.indexOf(product_id) > -1)
        income_21_30_percent_found = product_id;
      else {
        if (Object.keys(income_21_30_percent)?.filter((keyName) =>
          income_21_30_percent[keyName] === product_id)
          ?.map((keyName) => income_21_30_percent[keyName])?.length)
          income_21_30_percent_found = product_id;
      }
      let income_21_30_percentDataClone = [];

      let income_31_40_percent_found = 0;

      if (typeof income_31_40_percent === 'string' && income_31_40_percent?.indexOf(product_id) > -1)
        income_31_40_percent_found = product_id;
      else {
        if (Object.keys(income_31_40_percent)?.filter((keyName) =>
          income_31_40_percent[keyName] === product_id)
          ?.map((keyName) => income_31_40_percent[keyName])?.length)
          income_31_40_percent_found = product_id;
      }
      let income_31_40_percentDataClone = [];

      let income_41_50_percent_found = 0;

      if (typeof income_41_50_percent === 'string' && income_41_50_percent?.indexOf(product_id) > -1)
        income_41_50_percent_found = product_id;
      else {
        if (Object.keys(income_41_50_percent)?.filter((keyName) =>
          income_41_50_percent[keyName] === product_id)
          ?.map((keyName) => income_41_50_percent[keyName])?.length)
          income_41_50_percent_found = product_id;
      }
      let income_41_50_percentDataClone = [];

      let income_lower_50_percent_found = 0;

      if (typeof income_lower_50_percent === 'string' && income_lower_50_percent?.indexOf(product_id) > -1)
        income_lower_50_percent_found = product_id;
      else {
        if (Object.keys(income_lower_50_percent)?.filter((keyName) =>
          income_lower_50_percent[keyName] === product_id)
          ?.map((keyName) => income_lower_50_percent[keyName])?.length)
          income_lower_50_percent_found = product_id;
      }
      let income_lower_50_percentDataClone = [];

      let income_unknown_found = 0;

      if (typeof income_unknown === 'string' && income_unknown?.indexOf(product_id) > -1)
        income_unknown_found = product_id;
      else {
        if (Object.keys(income_unknown)?.filter((keyName) =>
          income_unknown[keyName] === product_id)
          ?.map((keyName) => income_unknown[keyName])?.length)
          income_unknown_found = product_id;
      }
      let income_unknownDataClone = [];

      let gender_male_found = 0;

      if (typeof gender_male === 'string' && gender_male?.indexOf(product_id) > -1)
        gender_male_found = product_id;
      else {
        if (Object.keys(gender_male)?.filter((keyName) =>
          gender_male[keyName] === product_id)
          ?.map((keyName) => gender_male[keyName])?.length)
          gender_male_found = product_id;
      }
      let gender_maleDataClone = [];

      let gender_female_found = 0;

      if (typeof gender_female === 'string' && gender_female?.indexOf(product_id) > -1)
        gender_female_found = product_id;
      else {
        if (Object.keys(gender_female)?.filter((keyName) =>
          gender_female[keyName] === product_id)
          ?.map((keyName) => gender_female[keyName])?.length)
          gender_female_found = product_id;
      }
      let gender_femaleDataClone = [];

      let gender_no_restrictions_found = 0;

      if (typeof gender_no_restrictions === 'string' && gender_no_restrictions?.indexOf(product_id) > -1)
        gender_no_restrictions_found = product_id;
      else {
        if (Object.keys(gender_no_restrictions)?.filter((keyName) =>
          gender_no_restrictions[keyName] === product_id)
          ?.map((keyName) => gender_no_restrictions[keyName])?.length)
          gender_no_restrictions_found = product_id;
      }
      let gender_no_restrictionsDataClone = [];

      let not_a_parent_found = 0;

      if (typeof not_a_parent === 'string' && not_a_parent?.indexOf(product_id) > -1)
        not_a_parent_found = product_id;
      else {
        if (Object.keys(not_a_parent)?.filter((keyName) =>
          not_a_parent[keyName] === product_id)
          ?.map((keyName) => not_a_parent[keyName])?.length)
          not_a_parent_found = product_id;
      }
      let not_a_parentDataClone = [];

      let parent_true_found = 0;

      if (typeof parent_true === 'string' && parent_true?.indexOf(product_id) > -1)
        parent_true_found = product_id;
      else {
        if (Object.keys(parent_true)?.filter((keyName) =>
          parent_true[keyName] === product_id)
          ?.map((keyName) => parent_true[keyName])?.length)
          parent_true_found = product_id;
      }
      let parent_trueDataClone = [];

      let unknown_parent_found = 0;

      if (typeof unknown_parent === 'string' && unknown_parent?.indexOf(product_id) > -1)
        unknown_parent_found = product_id;
      else {
        if (Object.keys(unknown_parent)?.filter((keyName) =>
          unknown_parent[keyName] === product_id)
          ?.map((keyName) => unknown_parent[keyName])?.length)
          unknown_parent_found = product_id;
      }
      let unknown_parentDataClone = [];

      CRMMonthlyBudget?.filter((CurrentBudget) =>
        CurrentBudget?.product_id === SocialAdsReEngage_id ||
        CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
        CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
        CurrentBudget?.product_id === BannerAdsReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
        CurrentBudget?.product_id === OnlineVideoReEngage_id ||
        CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
        CurrentBudget?.product_id === WebsiteDesign_id ||
        CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
        CurrentBudget?.product_id === OCAGeoDemo_id ||
        CurrentBudget?.product_id === OCAReEngage_id ||
        CurrentBudget?.product_id === OCAGeoIntention_id ||
        CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
          if (CurrentBudget?.product_id === SocialAdsReEngage_id ||
            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
            CurrentBudget?.product_id === BannerAdsReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
            CurrentBudget?.product_id === WebsiteDesign_id ||
            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
            CurrentBudget?.product_id === OCAGeoDemo_id ||
            CurrentBudget?.product_id === OCAReEngage_id ||
            CurrentBudget?.product_id === OCAGeoIntention_id ||
            CurrentBudget?.product_id === OutOfHome_id) {
            if (ages_no_restrictions_found)
              ages_no_restrictionsDataClone[index] = CurrentBudget?.product_id;
            if (ages_18_24_found)
              ages_18_24DataClone[index] = CurrentBudget?.product_id;
            if (ages_25_34_found)
              ages_25_34DataClone[index] = CurrentBudget?.product_id;
            if (ages_35_44_found)
              ages_35_44DataClone[index] = CurrentBudget?.product_id;
            if (ages_45_54_found)
              ages_45_54DataClone[index] = CurrentBudget?.product_id;
            if (ages_55_64_found)
              ages_55_64DataClone[index] = CurrentBudget?.product_id;
            if (ages_65_plus_found)
              ages_65_plusDataClone[index] = CurrentBudget?.product_id;
            if (ages_unknown_found)
              ages_unknownDataClone[index] = CurrentBudget?.product_id;
            if (income_top_10_percent_found)
              income_top_10_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_11_20_percent_found)
              income_11_20_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_21_30_percent_found)
              income_21_30_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_31_40_percent_found)
              income_31_40_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_41_50_percent_found)
              income_41_50_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_lower_50_percent_found)
              income_lower_50_percentDataClone[index] = CurrentBudget?.product_id;
            if (income_unknown_found)
              income_unknownDataClone[index] = CurrentBudget?.product_id;
            if (gender_male_found)
              gender_maleDataClone[index] = CurrentBudget?.product_id;
            if (gender_female_found)
              gender_femaleDataClone[index] = CurrentBudget?.product_id;
            if (gender_no_restrictions_found)
              gender_no_restrictionsDataClone[index] = CurrentBudget?.product_id;
            if (not_a_parent_found)
              not_a_parentDataClone[index] = CurrentBudget?.product_id;
            if (parent_true_found)
              parent_trueDataClone[index] = CurrentBudget?.product_id;
            if (unknown_parent_found)
              unknown_parentDataClone[index] = CurrentBudget?.product_id;
          }
        })
      setages_no_restrictions(ages_no_restrictionsDataClone);
      setages_18_24(ages_18_24DataClone);
      setages_25_34(ages_25_34DataClone);
      setages_35_44(ages_35_44DataClone);
      setages_45_54(ages_45_54DataClone);
      setages_55_64(ages_55_64DataClone);
      setages_65_plus(ages_65_plusDataClone);
      setages_unknown(ages_unknownDataClone);

      setincome_top_10_percent(income_top_10_percentDataClone);
      setincome_11_20_percent(income_11_20_percentDataClone);
      setincome_21_30_percent(income_21_30_percentDataClone);
      setincome_31_40_percent(income_31_40_percentDataClone);
      setincome_41_50_percent(income_41_50_percentDataClone);
      setincome_lower_50_percent(income_lower_50_percentDataClone);
      setincome_unknown(income_unknownDataClone);

      setgender_male(gender_maleDataClone);
      setgender_female(gender_femaleDataClone);
      setgender_no_restrictions(gender_no_restrictionsDataClone);

      setnot_a_parent(not_a_parentDataClone);
      setparent_true(parent_trueDataClone);
      setunknown_parent(unknown_parentDataClone);

      setduplicateAges(true);
    }
  }

  const updateCustomerPhotoGalleryFieldHandler = key => (e) => {
    if (key === "photo_url") {
      setCustomerPhotoGallerysubmissionData({
        ...omit(CustomerPhotoGallerysubmissionData, ["photo_url"]),
        [key]: e.target.value,
        ["photo_gallery_name"]: "Company Photos",
        ["photo_gallery_description"]: "Company Photos"
      });
    }
    else if (key === "logo_gallery_url") {
      setLogoGallerysubmissionData({
        ...omit(LogoGallerysubmissionData, ["photo_url"]),
        ["photo_url"]: e.target.value,
        ["photo_gallery_name"]: "Company Logos",
        ["photo_gallery_description"]: "Company Logos"
      });
    }
  };

  const updateBrandColorsAndFontsFieldHandler = key => (e) => {
    setBrandColorsAndFontssubmissionData({
      ...BrandColorsAndFontssubmissionData,
      [key]: e.target.value
    });
  };


  const updateCurrentWebsiteAccessFieldHandler = key => (e) => {
    setCurrentWebsiteAccessssubmissionData({
      ...CurrentWebsiteAccessssubmissionData,
      [key]: e.target.value
    });
  };

  const [submitCreateCRMGallery] = useMutation(
    CREATE_CUSTOMER_PHOTOS_AND_LOGOS_GALLERY);

  const [submitCreateWebsiteAccess] = useMutation(
    CREATE_CUSTOMER_WEBSITE_ACCESS);

  const [submitCreateWebsiteDesign] = useMutation(
    CREATE_CUSTOMER_WEBSITE_DESIGN);

  const updateGalleryDatabase = async () => {
    if (LogoGallerysubmissionData?.photo_gallery_description) {
      await submitCreateCRMGallery({
        skip: !CRMNewCustomerID,
        variables: {
          customer_id: CRMNewCustomerID,
          crm_onboarding_id: crm_onboarding_id,
          GalleryData: {
            ...omit(LogoGallerysubmissionData, ["__typename"]),
            crm_customers_id: crm_customer_id,
          }
        }
      });
    }

    if (CustomerPhotoGallerysubmissionData?.photo_gallery_description) {
      await submitCreateCRMGallery({
        skip: !CRMNewCustomerID,
        variables: {
          customer_id: CRMNewCustomerID,
          crm_onboarding_id: crm_onboarding_id,
          GalleryData: {
            ...omit(CustomerPhotoGallerysubmissionData, ["__typename"]),
            crm_customers_id: crm_customer_id,
          }
        }
      });
    }
  };

  const updateWebsiteAccess = async () => {
    if (CurrentWebsiteAccessssubmissionData) {
      await submitCreateWebsiteAccess({
        skip: !CRMNewCustomerID,
        variables: {
          customer_id: CRMNewCustomerID,
          crm_onboarding_id: crm_onboarding_id,
          WebsiteAccess: {
            ...omit(CurrentWebsiteAccessssubmissionData, ["__typename"]),
          }
        }
      });
    }
  };

  const updateWebsiteDesign = async () => {
    if (WebsiteDesignsubmissiondata) {
      await submitCreateWebsiteDesign({
        skip: !CRMNewCustomerID,
        variables: {
          customer_id: CRMNewCustomerID,
          crm_onboarding_id: crm_onboarding_id,
          WebsiteDesign: {
            ...omit(WebsiteDesignsubmissiondata, ["__typename"]),
          }
        }
      });
    }
  };

  const updateCustomerFieldHandler = (key, convert) => e => {
    if (key === "sunday_open" || key === "sunday_close" ||
      key === "monday_open" || key === "monday_close" ||
      key === "tuesday_open" || key === "tuesday_close" ||
      key === "wednesday_open" || key === "wednesday_close" ||
      key === "thursday_open" || key === "thursday_close" ||
      key === "friday_open" || key === "friday_close" ||
      key === "saturday_open" || key === "saturday_close" ||
      key === "round_the_clock_support")
      e.target.value = parseInt(e.target.value)
    setAccountInfo({
      ...AccountInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <div key={marketingGoal?.id} style={config}>
      <h2 className={classes.pageHeader}>Onboarding Form for {CRMCustomers?.business_name}</h2>
      <div>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div id="panel1" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
            <a href="#panel1" id="panel1_link" style={{ visibility: "hidden" }}>jump link</a>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}>
                  <h5><b>Dashboard User</b></h5>
                </Typography>
                <Typography sx={{ color: GotAllDashboardUserInfoRequiredState ? 'green' : "red", display: 'flex', verticalAlign: "center", alignItems: 'center' }}>{GotAllDashboardUserInfoRequiredState ? <><FontAwesomeIcon size="lg" icon={faFaceParty} />&nbsp;&nbsp;&nbsp;Great Success!</> : <><FontAwesomeIcon size="lg" icon={faPoo} />&nbsp;&nbsp;&nbsp;You've got work to do...</>}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>First Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <input
                      id="first_name"
                      maxLength={255}
                      type="text"
                      value={CRMPeople?.first_name || ""}
                      onChange={updatePersonFieldHandler("first_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Last Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <input
                      id="last_name"
                      maxLength={255}
                      type="text"
                      value={CRMPeople?.last_name || ""}
                      onChange={updatePersonFieldHandler("last_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Email&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <input
                      id="email"
                      maxLength={255}
                      type="text"
                      value={CRMPeople?.email || ""}
                      onChange={updatePersonFieldHandler("email")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Phone&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <input
                      id="cell_phone_number"
                      maxLength={255}
                      type="text"
                      value={CRMPeople?.cell_phone_number || ""}
                      onChange={updatePersonFieldHandler("cell_phone_number")}
                      onBlur={() => {
                        setCRMPeople({
                          ...CRMPeople,
                          ["cell_phone_number"]: FormatPhone(CRMPeople?.cell_phone_number)
                        });
                      }}
                      onFocus={() => {
                        setCRMPeople({
                          ...CRMPeople,
                          ["cell_phone_number"]: CRMPeople?.cell_phone_number?.replace(/[^0-9]/g, "")
                        });
                      }}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Office Phone</b></label>
                    <input
                      id="office_phone_number"
                      maxLength={255}
                      type="text"
                      value={FormatPhone(CRMPeople?.office_phone_number) || ""}
                      onChange={updatePersonFieldHandler("office_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Office Ext</b></label>
                    <input
                      id="office_extension"
                      maxLength={255}
                      type="text"
                      value={CRMPeople?.office_extension || ""}
                      onChange={updatePersonFieldHandler("office_extension")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "25px", float: "left", color: "#363636", fontSize: "16px" }}><b>Birthday</b></label>
                    <TextField
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={CRMPeople?.birthday || ""}
                      onChange={updatePersonFieldHandler("birthday")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "25px", float: "left", color: "#363636", fontSize: "16px" }}><b>Billing Contact&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <Select
                      onChange={updatePersonFieldHandler("billing_contact")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                      value={parseInt(CRMPeople?.billing_contact)}
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                    </Select>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "25px", float: "left", color: "#363636", fontSize: "16px" }}><b>Reporting Emails&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                    <Select
                      name="auto_reporting_emails"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                      value={parseInt(CRMPeople?.auto_reporting_emails)}
                      onChange={updatePersonFieldHandler("auto_reporting_emails")}
                    >
                      <MenuItem value="0">No</MenuItem>
                      <MenuItem value="2">Monthly</MenuItem>
                    </Select>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <Button
                    type="submit"
                    disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                      (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                    }
                    onClick={async () => {
                      if (await updateDatabasDashboardUserInfo() === true) {
                        requestResetPassword();
                        setExpanded("panel2");
                      }
                    }}
                  >Save</Button>
                </Typography>
              </AccordionDetails>
            </Accordion>
            {WaterBearMarketingUser ?
              <>
                <div id="panel2" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                <a href="#panel2" id="panel2_link" style={{ visibility: "hidden" }}>jump link</a>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}>
                      <h5><b>Business Information</b></h5>
                    </Typography>
                    <Typography sx={{ color: GotAllBusinessInfoRequiredState ? 'green' : "red", display: 'flex', verticalAlign: "center", alignItems: 'center' }}>{GotAllBusinessInfoRequiredState ? <><FontAwesomeIcon size="lg" icon={faFaceParty} />&nbsp;&nbsp;&nbsp;Great Success!</> : <><FontAwesomeIcon size="lg" icon={faPoo} />&nbsp;&nbsp;&nbsp;You've got work to do...</>}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Business Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="business_name"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.business_name || ""}
                          onChange={updateBusinessInfoFieldHandler("business_name")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Address Line 1&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_address_line_one"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_address_line_one || ""}
                          onChange={updateBusinessInfoFieldHandler("office_address_line_one")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Address Line 2</b></label>
                        <input
                          id="office_address_line_two"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_address_line_two || ""}
                          onChange={updateBusinessInfoFieldHandler("office_address_line_two")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>City&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_city"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_city || ""}
                          onChange={updateBusinessInfoFieldHandler("office_city")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>State&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_state"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_state || ""}
                          onChange={updateBusinessInfoFieldHandler("office_state")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Zip&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_zip"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_zip || ""}
                          onChange={updateBusinessInfoFieldHandler("office_zip")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Country&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_country"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_country || ""}
                          onChange={updateBusinessInfoFieldHandler("office_country")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Phone&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="office_phone_number"
                          maxLength={255}
                          type="text"
                          value={FormatPhone(CRMCustomers?.office_phone_number) || ""}
                          onChange={updateBusinessInfoFieldHandler("office_phone_number")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Website&nbsp;<div style={{ color: "red", float: "right" }}>*</div></b></label>
                        <input
                          id="website"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.website || ""}
                          onChange={updateBusinessInfoFieldHandler("website")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      {/*<div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Industry</b></label>
                    <input
                      id="industry"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.industry || ""}
                      onChange={updateBusinessInfoFieldHandler("industry")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>*/}
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Revenue</b></label>
                        <input
                          id="revenue"
                          maxLength={15}
                          type="text"
                          value={revenue}
                          onBlur={() => {
                            FormatCurrency(revenue);
                          }}
                          onFocus={() => {
                            setCRMCustomers({
                              ...CRMCustomers,
                              ["revenue"]: ''
                            });
                            setrevenue('');
                          }}
                          onChange={(e) => { updateBusinessInfoFieldHandler("revenue", e); updateCustomerRevenueValueFieldHandler(e) }}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      {/*
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>SIC Code</b></label>
                    <input
                      id="sic_code"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.sic_code || ""}
                      onChange={updateBusinessInfoFieldHandler("sic_code")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>NAICS Code</b></label>
                    <input
                      id="naics_code"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.naics_code || ""}
                      onChange={updateBusinessInfoFieldHandler("naics_code")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                    />
                    </div>*/}
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Number of Employees</b></label>
                        <input
                          id="employees"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.employees || ""}
                          onChange={updateBusinessInfoFieldHandler("employees")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Year Founded</b></label>
                        <input
                          id="established"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.established || ""}
                          onChange={updateBusinessInfoFieldHandler("established")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Company Tagline</b></label>
                        <input
                          id="tagline"
                          type="text"
                          value={CRMCustomers?.tagline}
                          onChange={updateBusinessInfoFieldHandler("tagline")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label
                          style={{ marginTop: "15px", color: "#363636", fontSize: "16px" }}><b>Business Description</b></label>
                        <textarea
                          id="business_description"
                          type="text"
                          defaultValue={CRMCustomers?.business_description || ""}
                          onChange={updateBusinessInfoFieldHandler("business_description")}
                          style={{ borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      {LocalServicesAds ?
                        <>
                          <div style={{ clear: "both" }}></div>
                          <div style={{ display: "block" }}>
                            <label
                              style={{ marginTop: "15px", color: "#363636", fontSize: "16px" }}><b>Business Owner First Name</b></label>
                            <input
                              id="owner_first_name"
                              maxLength={2000}
                              type="text"
                              value={CRMCustomers?.owner_first_name || ""}
                              onChange={updateBusinessInfoFieldHandler("owner_first_name")}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                          <div style={{ display: "block" }}>
                            <label
                              style={{ marginTop: "30px", color: "#363636", fontSize: "16px" }}><b>Business Owner Last Name</b></label>
                            <input
                              id="owner_last_name"
                              maxLength={2000}
                              type="text"
                              value={CRMCustomers?.owner_last_name || ""}
                              onChange={updateBusinessInfoFieldHandler("owner_last_name")}
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                            />
                          </div>
                        </>
                        : ""}
                      <div style={{ clear: "both", height: "30px" }}></div>
                      <div style={{ display: "inline-block" }}>
                        <label style={{ verticalAlign: "middle", color: "#363636", fontSize: "16px" }}>
                          <span><b>Customers do not come to our address.</b></span>
                          <Checkbox
                            checked={CRMCustomers?.nowalkins ? true : false}
                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                            onChange={updateBusinessInfoFieldHandler("nowalkins")}>
                          </Checkbox></label>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <Button
                        type="submit"
                        disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                          (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                        }
                        onClick={() => {
                          updateDatabaseBusinessInfo();
                          CreateWaterBearMarketingCustomer();
                        }}
                      >Save</Button>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </>
              : ""}
            {CRMNewCustomerID !== null && WaterBearMarketingUser ?
              <>
                {quick_books_id ?
                  <>
                    <div id="panel3" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel3" id="panel3_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Billing Information</b></h5></Typography>
                        <Typography sx={{ color: GotOnePaymentMethodRequiredState ? 'green' : "red", display: 'flex', verticalAlign: "center", alignItems: 'center' }}>{GotOnePaymentMethodRequiredState ? <><FontAwesomeIcon size="lg" icon={faFaceParty} />&nbsp;&nbsp;&nbsp;Great Success!</> : <><FontAwesomeIcon size="lg" icon={faPoo} />&nbsp;&nbsp;&nbsp;You've got work to do...</>}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={12} md={6}>
                                {banks.map(bankInfo => (
                                  <NavItem
                                    active={paymentId === bankInfo.id}
                                    onClick={() => {
                                      setMethod("bank-account");
                                      setPaymentId(bankInfo.id);
                                      setPaymentInfo(bankInfo);
                                      setError("");
                                    }}
                                    key={bankInfo.id}
                                    readOnly
                                    style={{ position: "relative" }}
                                  >
                                    <div className={classes.cardName}>
                                      <AccountBalanceIcon /> {bankInfo.name}
                                    </div>
                                    <div className={classes.cardNumber}>
                                      {bankInfo.accountNumber}
                                    </div>
                                    <div className={classes.defaultButtonWrapper}>
                                      {renderButton(
                                        "bank",
                                        defaultId === `bank-${bankInfo.id}`,
                                        bankInfo.id
                                      )}
                                    </div>
                                  </NavItem>
                                ))}
                                {cards.map(cardInfo => (
                                  <NavItem
                                    active={paymentId === cardInfo.id}
                                    onClick={() => {
                                      setMethod("credit-card");
                                      setPaymentId(cardInfo.id);
                                      setPaymentInfo(cardInfo);
                                      setError("");
                                    }}
                                    key={cardInfo.id}
                                    readOnly
                                    style={{ position: "relative" }}
                                  >
                                    <div className={classes.cardName}>
                                      <CreditCardIcon /> {cardInfo.name}
                                    </div>
                                    <div className={classes.cardNumber}>{cardInfo.number}</div>
                                    <div className={classes.defaultButtonWrapper}>
                                      {renderButton(
                                        "card",
                                        defaultId === `card-${cardInfo.id}`,
                                        cardInfo.id
                                      )}
                                    </div>
                                  </NavItem>
                                ))}
                                <NavItem
                                  active={paymentId === 0}
                                  onClick={() => {
                                    setMethod("credit-card");
                                    setPaymentId(0);
                                    setPaymentInfo({});
                                    setError("");
                                  }}
                                >
                                  Add +
                                </NavItem>
                              </GridItem>
                              <GridItem xs={12} md={6}>
                                {paymentId === 0 && (
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      row
                                      aria-label="method"
                                      name="method"
                                      value={method}
                                      onChange={({ target: { value } }) => {
                                        setMethod(value);
                                        setPaymentInfo({});
                                      }}
                                    >
                                      <FormControlLabel
                                        value="credit-card"
                                        control={<Radio color="primary" />}
                                        label="Credit Card"
                                      />
                                      <FormControlLabel
                                        value="bank-account"
                                        control={<Radio color="primary" />}
                                        label="Bank Account"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                )}
                                {paymentId !== undefined && (
                                  <>
                                    {method === "credit-card" && (
                                      <CreditCardForm
                                        info={paymentInfo}
                                        onUpdate={setPaymentInfo}
                                        readOnly={paymentId !== 0}
                                        error={error?.detail}
                                        key={paymentId}
                                      />
                                    )}
                                    {method === "bank-account" && (
                                      <BankAccountForm
                                        info={paymentInfo}
                                        onUpdate={setPaymentInfo}
                                        readOnly={paymentId !== 0}
                                        error={error?.detail}
                                        key={paymentId}
                                      />
                                    )}
                                    {error?.moreInfo}
                                    <div>
                                      By clicking submit I agree to the{" "}
                                      <a
                                        href="#"
                                        onClick={e => {
                                          e.preventDefault();
                                          setTermsPopupOpen(true);
                                        }}
                                      >
                                        Payment Terms and Conditions
                                      </a>
                                    </div>
                                    <Dialog
                                      open={Boolean(termsPopupOpen)}
                                      onClose={() => setTermsPopupOpen(false)}
                                      aria-labelledby="alert-dialog-title"
                                      aria-describedby="alert-dialog-description"
                                    >
                                      <DialogTitle id="alert-dialog-title">
                                        Payment Terms and Conditions
                                      </DialogTitle>
                                      <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                          {`By clicking submit, you represent that you have the authority to bind the payor, and hereby authorize Water Bear Marketing to charge the Default credit card (the Card) or Bank Account for the amounts owed on all Order Forms to which the above-referenced Client is a party, consistent with the Order Form and the Online Marketing Services Terms & Conditions. Should charges be declined, Water Bear Marketing will promptly re-charge the Card or Bank Account for the amount due.`}
                                          <br />
                                          <br />
                                          {`If the services you purchased require recurring payments, the Card or Bank Account will be used for all such on-going charges. In addition, in some cases Water Bear Marketing is provided updated card information from card issuers, including regarding canceled accounts or changes to card numbers or expiration dates. Water Bear marketing will apply any updated information to your account to avoid service disruptions.`}
                                          <br />
                                          <br />
                                          {`If you would like Water Bear Marketing not to update your account information this way or would like to use another payment method at any time, please contact your Water Bear Marketing representative.`}
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={() => setTermsPopupOpen(false)}
                                          color="primary"
                                          autoFocus
                                        >
                                          Agree
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    <br />
                                    {paymentId === 0 && (
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          if (method === "credit-card") {
                                            addCard();
                                          } else {
                                            addBank();
                                          }
                                        }}
                                      >
                                        Submit
                                      </Button>
                                    )}
                                    {paymentId !== 0 && removable && (
                                      <Button
                                        color="secondary"
                                        onClick={() => {
                                          if (method === "credit-card") {
                                            deleteCard();
                                          } else {
                                            deleteBank();
                                          }
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    )}
                                  </>
                                )}
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                <div id="panel4" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                <a href="#panel4" id="panel4_link" style={{ visibility: "hidden" }}>jump link</a>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}>
                      <h5><b>Hours of Operation</b></h5>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Time Zone:</b></label>
                      <Select
                        id="timezone"
                        required={true}
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        value={AccountInfo?.timezone || ""}
                        inputProps={{
                          type: "string",
                          onChange: updateCustomerFieldHandler("timezone"),
                        }}
                      >
                        <MenuItem value="Eastern Time">Eastern Time</MenuItem>
                        <MenuItem value="Central Time">Central Time</MenuItem>
                        <MenuItem value="Mountain Time">Mountain Time</MenuItem>
                        <MenuItem value="Pacific Time">Pacific Time</MenuItem>
                        <MenuItem value="Alaska Time">Alaska Time</MenuItem>
                        <MenuItem value="Hawaii Time">Hawaii Time</MenuItem>
                      </Select>
                      <div style={{ clear: "both", height: "40px" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Do you offer 24/7 Service or Support?</b></label>
                        <Select
                          onChange={updateCustomerFieldHandler("round_the_clock_support")}
                          style={{ verticalAlign: "middle", marginLeft: "20px" }}
                          value={`${AccountInfo?.round_the_clock_support}`}
                        >
                          <MenuItem value="0">No</MenuItem>
                          <MenuItem value="1">Yes</MenuItem>
                        </Select>
                      </div>
                      <div style={{ clear: "both", height: "40px" }}></div>
                      <div>
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Sunday Open:</b></label>
                          <Select
                            id="sunday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("sunday_open_hours"),
                              value: AccountInfo?.sunday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="sunday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("sunday_open_mins"),
                              value: AccountInfo?.sunday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Sunday Close:</b></label>
                          <Select
                            id="sunday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("sunday_close_hours"),
                              value: AccountInfo?.sunday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="sunday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("sunday_close_mins"),
                              value: AccountInfo?.sunday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Monday Open:</b></label>
                          <Select
                            id="monday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("monday_open_hours"),
                              value: AccountInfo?.monday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="monday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("monday_open_mins"),
                              value: AccountInfo?.monday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Monday Close:</b></label>
                          <Select
                            id="monday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("monday_close_hours"),
                              value: AccountInfo?.monday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="monday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("monday_close_mins"),
                              value: AccountInfo?.monday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Tuesday Open:</b></label>
                          <Select
                            id="tuesday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("tuesday_open_hours"),
                              value: AccountInfo?.tuesday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="tuesday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("tuesday_open_mins"),
                              value: AccountInfo?.tuesday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Tuesday Close:</b></label>
                          <Select
                            id="tuesday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("tuesday_close_hours"),
                              value: AccountInfo?.tuesday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="tuesday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("tuesday_close_mins"),
                              value: AccountInfo?.tuesday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Wednesday Open:</b></label>
                          <Select
                            id="wednesday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("wednesday_open_hours"),
                              value: AccountInfo?.wednesday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="wednesday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("wednesday_open_mins"),
                              value: AccountInfo?.wednesday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Wednesday Close:</b></label>
                          <Select
                            id="wednesday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("wednesday_close_hours"),
                              value: AccountInfo?.wednesday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="wednesday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("wednesday_close_mins"),
                              value: AccountInfo?.wednesday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Thursday Open:</b></label>
                          <Select
                            id="thursday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("thursday_open_hours"),
                              value: AccountInfo?.thursday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="thursday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("thursday_open_mins"),
                              value: AccountInfo?.thursday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Thursday Close:</b></label>
                          <Select
                            id="thursday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("thursday_close_hours"),
                              value: AccountInfo?.thursday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="thursday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("thursday_close_mins"),
                              value: AccountInfo?.thursday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Friday Open:</b></label>
                          <Select
                            id="friday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("friday_open_hours"),
                              value: AccountInfo?.friday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="friday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("friday_open_mins"),
                              value: AccountInfo?.friday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Friday Close:</b></label>
                          <Select
                            id="friday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("friday_close_hours"),
                              value: AccountInfo?.friday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="friday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("friday_close_mins"),
                              value: AccountInfo?.friday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div style={{ float: "left", display: "inline-block" }}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Saturday Open:</b></label>
                          <Select
                            id="saturday_open_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("saturday_open_hours"),
                              value: AccountInfo?.saturday_open_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="saturday_open_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("saturday_open_mins"),
                              value: AccountInfo?.saturday_open_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>

                        </div>
                        <div style={float}>
                          <label style={{ width: "140px", marginLeft: "50px", color: "#363636", fontSize: "16px" }}><b>Saturday Close:</b></label>
                          <Select
                            id="saturday_close_hours"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("saturday_close_hours"),
                              value: AccountInfo?.saturday_close_hours || "00"
                            }}
                          >
                            {[...Array(24)].map((e, i) => <MenuItem value={`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}>{`${i?.toString()?.length === 1 ? "0" + i?.toString() : i}`}</MenuItem>)}
                          </Select>
                          <Select
                            id="saturday_close_mins"
                            required={true}
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateCustomerFieldHandler("saturday_close_mins"),
                              value: AccountInfo?.saturday_close_mins || "00"
                            }}
                          >
                            {[...Array(4)].map((e, i) => <MenuItem value={`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}>{`${(i * 15)?.toString()?.length === 1 ? "0" + (i * 15)?.toString() : (i * 15)}`}</MenuItem>)}
                          </Select>
                        </div>
                      </div>
                      <div style={{ clear: "both", height: "30px" }}></div>
                      <Button
                        type="submit"
                        disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                          (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                        }
                        onClick={() => {
                          updateCustomerHours();

                          const notify = options => {
                            const key = enqueueSnackbar(options?.text, {
                              variant: options?.variant,
                              persist: true
                            });
                            setTimeout(() => {
                              closeSnackbar(key);
                            }, options?.delay || 2000);
                          }
                          notify({
                            text: "Information has been successfully submitted",
                            variant: "success"
                          });
                        }}
                      >Save</Button>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement || BannerAdsGeoDemo || BannerAdsGeoIntention ||
                  BannerAdsReEngage || OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention ||
                  WebsiteDesign || WebsiteDesignWooCommerce || OCAGeoDemo || OCAReEngage || OCAGeoIntention ||
                  LocalServicesAds || SocialAdsReEngage || OCAGeoDemo || OCAReEngage || OCAGeoIntention || LinearCRM ?
                  <>
                    <div id="panel5" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel5" id="panel5_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Service Areas</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id === PaidSearch_id ||
                            CurrentBudget?.product_id === OrganicSearchTwo_id ||
                            CurrentBudget?.product_id === OrganicSearchFour_id ||
                            CurrentBudget?.product_id === OrganicSearchRegional_id ||
                            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === WebsiteDesign_id ||
                            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === LocalServicesAds_id ||
                            CurrentBudget?.product_id === SocialAdsReEngage_id ||
                            CurrentBudget?.product_id === LinearCRM_id
                          )?.map((CurrentBudget, index) => {
                            if (CurrentBudget?.product_id === PaidSearch_id ||
                              CurrentBudget?.product_id === OrganicSearchTwo_id ||
                              CurrentBudget?.product_id === OrganicSearchFour_id ||
                              CurrentBudget?.product_id === OrganicSearchRegional_id ||
                              CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                              CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                              CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                              CurrentBudget?.product_id === BannerAdsReEngage_id ||
                              CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                              CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                              CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                              CurrentBudget?.product_id === WebsiteDesign_id ||
                              CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                              CurrentBudget?.product_id === OCAGeoDemo_id ||
                              CurrentBudget?.product_id === OCAReEngage_id ||
                              CurrentBudget?.product_id === OCAGeoIntention_id ||
                              CurrentBudget?.product_id === LocalServicesAds_id ||
                              CurrentBudget?.product_id === SocialAdsReEngage_id ||
                              CurrentBudget?.product_id === LinearCRM_id)
                              return (
                                <>
                                  <div style={{ clear: "both", height: "40px" }}></div>
                                  <label style={{ color: "#363636", fontSize: "16px" }}><b>High Priority Locations | (Zip Codes, Cities, States, Counties or Radius)</b></label>
                                  <div style={{ clear: "both", height: "40px" }}></div>
                                  <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                  {index !== 0 ?
                                    <div style={{ display: "inline-block" }}>
                                      <label style={{ verticalAlign: "middle" }}>
                                        <Checkbox
                                          checked={duplicateHighPriorityLocations}
                                          style={{ marginRight: "20px", verticalAlign: "middle" }}
                                          onChange={() => {
                                            DuplicateHighPriorityLocations(CRMMonthlyBudget[0]?.product_id)
                                          }}>
                                        </Checkbox><span>{duplicateHighPriorityLocations === false ? "duplicate first location targeting" : "Duplicated"}</span></label>
                                    </div>
                                    : ""}
                                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                    <Table stickyHeader>
                                      <TableRow>
                                        {target_areas_columns.map((datacolumns) => (
                                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                        ))}
                                      </TableRow>
                                      {targetAreaData ? Object.keys(targetAreaData).map((keyName, innerIndex) => {
                                        if (targetAreaData[keyName]?.product_id === CurrentBudget?.product_id)
                                          return (
                                            <TableRow key={targetAreaData[keyName]?.id + index}>
                                              <TableCell style={{ padding: 5 }}>
                                                <CustomInput
                                                  id={`customers_target_areas${targetAreaData[keyName].id}${innerIndex + index}`}
                                                  maxLength="455"
                                                  type="text"
                                                  key={`customers_target_areas${targetAreaData[keyName].id}${innerIndex + index}`}
                                                  formControlProps={{
                                                    fullWidth: true,
                                                  }}
                                                  inputProps={{
                                                    onChange: event => { updateTargetAreas(innerIndex, event, CurrentBudget?.product_id) },
                                                    defaultValue: targetAreaData[keyName]?.customer_top_target_area ? targetAreaData[keyName].customer_top_target_area : '',
                                                    style: { verticalAlign: "middle" }
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                <Select
                                                  id={`customers_target_type${targetAreaData[keyName].id}${innerIndex + index}`}
                                                  inputProps={{
                                                    onChange: event => { updateTargetType(innerIndex, event, CurrentBudget?.product_id) },
                                                    value: targetAreaData[keyName]?.customer_top_target_type ? targetAreaData[keyName].customer_top_target_type : "Select"
                                                  }}
                                                >
                                                  <MenuItem value="Select" label="Select">Select</MenuItem>
                                                  <MenuItem value="Zip Codes">Zip Codes</MenuItem>
                                                  <MenuItem value="Cities">Cities</MenuItem>
                                                  <MenuItem value="States">States</MenuItem>
                                                  <MenuItem value="Counties">Counties</MenuItem>
                                                </Select>
                                              </TableCell>
                                              <TableCell style={{ padding: 0, textAlign: "center" }}>
                                                <a style={{ cursor: "pointer", color: HighPriorityLocationsHover[innerIndex + index] === true ? "red" : "#000" }} onClick={RemoveTargetArea(targetAreaData[keyName]?.id)}><FontAwesomeIcon icon={HighPriorityLocationsHover[innerIndex + index] ? faDumpsterFire : faDumpster} onMouseOver={() => { setHighPriorityLocationsHover({ ...HighPriorityLocationsHover, [innerIndex + index]: true }) }} onMouseLeave={() => { setHighPriorityLocationsHover({ ...HighPriorityLocationsHover, [innerIndex + index]: false }) }} /></a>
                                              </TableCell>
                                            </TableRow>
                                          )
                                      }
                                      ) : ""}
                                    </Table>
                                  </TableContainer>
                                  <br />
                                  <a style={{ cursor: "pointer" }} onClick={() => AddTargetArea(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                </>
                              )
                          })}
                          <br />
                          <br />
                          <br />
                          <br />
                          {CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id === PaidSearch_id ||
                            CurrentBudget?.product_id === OrganicSearchTwo_id ||
                            CurrentBudget?.product_id === OrganicSearchFour_id ||
                            CurrentBudget?.product_id === OrganicSearchRegional_id ||
                            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === WebsiteDesign_id ||
                            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === LocalServicesAds_id ||
                            CurrentBudget?.product_id === SocialAdsReEngage_id ||
                            CurrentBudget?.product_id === LinearCRM_id
                          )?.map((CurrentBudget, index) => {
                            if (CurrentBudget?.product_id === PaidSearch_id ||
                              CurrentBudget?.product_id === OrganicSearchTwo_id ||
                              CurrentBudget?.product_id === OrganicSearchFour_id ||
                              CurrentBudget?.product_id === OrganicSearchRegional_id ||
                              CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                              CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                              CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                              CurrentBudget?.product_id === BannerAdsReEngage_id ||
                              CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                              CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                              CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                              CurrentBudget?.product_id === WebsiteDesign_id ||
                              CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                              CurrentBudget?.product_id === OCAGeoDemo_id ||
                              CurrentBudget?.product_id === OCAReEngage_id ||
                              CurrentBudget?.product_id === OCAGeoIntention_id ||
                              CurrentBudget?.product_id === LocalServicesAds_id ||
                              CurrentBudget?.product_id === SocialAdsReEngage_id ||
                              CurrentBudget?.product_id === LinearCRM_id)
                              return (
                                <>
                                  <div style={{ clear: "both", height: "40px" }}></div>
                                  <label style={{ color: "#363636", fontSize: "16px" }}><b>Service Areas? (Zip Codes, Cities, States, Counties or Radius)</b></label>
                                  <div style={{ clear: "both", height: "40px" }}></div>
                                  <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                  {index !== 0 ?
                                    <div style={{ display: "inline-block" }}>
                                      <label style={{ verticalAlign: "middle" }}>
                                        <Checkbox
                                          checked={duplicateServiceAreas}
                                          style={{ marginRight: "20px", verticalAlign: "middle" }}
                                          onChange={() => {
                                            DuplicateServiceAreas(CRMMonthlyBudget[0]?.product_id)
                                          }}>
                                        </Checkbox><span>{duplicateServiceAreas === false ? "duplicate first area targeting" : "Duplicated"}</span></label>
                                    </div>
                                    : ""}
                                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                    <Table stickyHeader>
                                      <TableRow>
                                        {target_areas_columns.map((datacolumns) => (
                                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                        ))}
                                      </TableRow>
                                      {ServiceAreaData ? Object.keys(ServiceAreaData)?.map((keyName, innerIndex) => {
                                        if (ServiceAreaData[keyName]?.product_id === CurrentBudget?.product_id)
                                          return (
                                            <TableRow key={ServiceAreaData[keyName]?.id + index}>
                                              <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                <CustomInput
                                                  id={`customers_service_areas${ServiceAreaData[keyName].id}${innerIndex + index}`}
                                                  maxLength="455"
                                                  type="text"
                                                  key={`customers_service_areas${ServiceAreaData[keyName].id}${innerIndex + index}`}
                                                  formControlProps={{
                                                    fullWidth: true,
                                                  }}
                                                  inputProps={{
                                                    onChange: event => { updateServiceArea(innerIndex, event, CurrentBudget?.product_id) },
                                                    defaultValue: ServiceAreaData[keyName]?.customer_top_service_area ? ServiceAreaData[keyName].customer_top_service_area : '',
                                                    style: { verticalAlign: "middle" }
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                <Select
                                                  id={`customers_service_target_type${ServiceAreaData[keyName].id}${innerIndex + index}`}
                                                  inputProps={{
                                                    onChange: event => { updateServiceAreTargetType(innerIndex, event, CurrentBudget?.product_id) },
                                                    value: ServiceAreaData[keyName]?.customer_top_service_area_type ? ServiceAreaData[keyName].customer_top_service_area_type : "Select"
                                                  }}
                                                >
                                                  <MenuItem value="Select" label="Select">Select</MenuItem>
                                                  <MenuItem value="Zip Codes">Zip Codes</MenuItem>
                                                  <MenuItem value="Cities">Cities</MenuItem>
                                                  <MenuItem value="States">States</MenuItem>
                                                  <MenuItem value="Counties">Counties</MenuItem>
                                                </Select>
                                              </TableCell>
                                              <TableCell style={{ padding: 0, textAlign: "center" }}>
                                                <a style={{ cursor: "pointer", color: ServiceAreaLocationsHover[innerIndex] === true ? "red" : "#000" }} onClick={RemoveServiceArea(ServiceAreaData[keyName]?.id)}><FontAwesomeIcon icon={ServiceAreaLocationsHover[innerIndex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setServiceAreaLocationsHover({ ...ServiceAreaLocationsHover, [innerIndex]: true }) }} onMouseLeave={() => { setServiceAreaLocationsHover({ ...ServiceAreaLocationsHover, [innerIndex]: false }) }} /></a>
                                              </TableCell>
                                            </TableRow>
                                          )
                                      }) : ""}
                                    </Table>
                                  </TableContainer>
                                  <br />
                                  <a style={{ cursor: "pointer" }} onClick={() => AddServiceArea(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                </>
                              )
                          })}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            onClick={() => {
                              Object.keys(targetAreaData).map((keyName) => {
                                updateCustomerTargetAreas({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(targetAreaData[keyName], ["__typename"])
                                  }
                                });
                              })
                              Object.keys(ServiceAreaData).map((keyName) => {
                                updateCustomerServiceAreas({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(ServiceAreaData[keyName], ["__typename"])
                                  }
                                });
                              })

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >
                            Submit
                          </Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement || BannerAdsGeoDemo ||
                  BannerAdsGeoIntention || BannerAdsReEngage || OnlineVideoGeoDemo ||
                  OnlineVideoReEngage || OnlineVideoGeoIntention || WebsiteDesign ||
                  WebsiteDesignWooCommerce || OCAGeoDemo || OCAReEngage || OCAGeoIntention ||
                  Hydrate || OutOfHome || LocalServicesAds || SocialAdsReEngage ?
                  <>
                    <div id="panel6" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel6" id="panel6_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Differentiators</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id === PaidSearch_id ||
                            CurrentBudget?.product_id === OrganicSearchTwo_id ||
                            CurrentBudget?.product_id === OrganicSearchFour_id ||
                            CurrentBudget?.product_id === OrganicSearchRegional_id ||
                            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === WebsiteDesign_id ||
                            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === Hydrate_id ||
                            CurrentBudget?.product_id === OutOfHome_id ||
                            CurrentBudget?.product_id === LocalServicesAds_id ||
                            CurrentBudget?.product_id === SocialAdsReEngage_id)?.map((CurrentBudget, index) => {
                              if (CurrentBudget?.product_id === PaidSearch_id ||
                                CurrentBudget?.product_id === OrganicSearchTwo_id ||
                                CurrentBudget?.product_id === OrganicSearchFour_id ||
                                CurrentBudget?.product_id === OrganicSearchRegional_id ||
                                CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                                CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                                CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                                CurrentBudget?.product_id === BannerAdsReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                                CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                                CurrentBudget?.product_id === WebsiteDesign_id ||
                                CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                                CurrentBudget?.product_id === OCAGeoDemo_id ||
                                CurrentBudget?.product_id === OCAReEngage_id ||
                                CurrentBudget?.product_id === OCAGeoIntention_id ||
                                CurrentBudget?.product_id === Hydrate_id ||
                                CurrentBudget?.product_id === OutOfHome_id ||
                                CurrentBudget?.product_id === LocalServicesAds_id ||
                                CurrentBudget?.product_id === SocialAdsReEngage_id)
                                return (
                                  <>
                                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                    {index !== 0 ?
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={duplicateTopProducts}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={() => {
                                              DuplicateTopProducts(CRMMonthlyBudget[0]?.product_id)
                                            }}>
                                          </Checkbox><span>{duplicateTopProducts === false ? "duplicate first product targeting" : "Duplicated"}</span></label>
                                      </div>
                                      : ""}
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    <label style={{ color: "#363636", fontSize: "16px" }}><b>What are the top products or services we should market?</b></label>
                                    <br />
                                    <br />
                                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                      <Table stickyHeader>
                                        <TableRow>
                                          {top_products_columns.map((datacolumns) => (
                                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                          ))}
                                        </TableRow>
                                        {productData ? Object.keys(productData).map((keyName, Innerindex) => {
                                          if (productData[keyName]?.product_id === CurrentBudget?.product_id)
                                            return (
                                              <TableRow key={productData[keyName]?.id}>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <CustomInput
                                                    id={`customers_top_products${productData[keyName].id}${index + Innerindex}`}
                                                    maxLength="455"
                                                    type="text"
                                                    key={`customers_top_products${productData[keyName].id}${index + Innerindex}`}
                                                    formControlProps={{
                                                      fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                      onChange: event => { updateTopProducts(Innerindex, event, CurrentBudget?.product_id) },
                                                      defaultValue: productData[keyName]?.product_focus ? productData[keyName].product_focus : ''
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <a style={{ cursor: "pointer", color: ProductsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveProduct(productData[keyName]?.id)}><FontAwesomeIcon icon={ProductsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setProductsHover({ ...ProductsHover, [Innerindex]: true }) }} onMouseLeave={() => { setProductsHover({ ...ProductsHover, [Innerindex]: false }) }} /></a>
                                                </TableCell>
                                              </TableRow>
                                            )
                                        }
                                        ) : ""}
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    <a style={{ cursor: "pointer" }} onClick={() => AddProduct(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                  </>
                                )
                            })}
                          {CRMMonthlyBudget?.filter((CurrentBudget) => CurrentBudget?.product_id === PaidSearch_id ||
                            CurrentBudget?.product_id === OrganicSearchTwo_id ||
                            CurrentBudget?.product_id === OrganicSearchFour_id ||
                            CurrentBudget?.product_id === OrganicSearchRegional_id ||
                            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === WebsiteDesign_id ||
                            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === Hydrate_id ||
                            CurrentBudget?.product_id === OutOfHome_id ||
                            CurrentBudget?.product_id === LocalServicesAds_id ||
                            CurrentBudget?.product_id === SocialAdsReEngage_id)?.map((CurrentBudget, index) => {
                              if (CurrentBudget?.product_id === PaidSearch_id ||
                                CurrentBudget?.product_id === OrganicSearchTwo_id ||
                                CurrentBudget?.product_id === OrganicSearchFour_id ||
                                CurrentBudget?.product_id === OrganicSearchRegional_id ||
                                CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                                CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                                CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                                CurrentBudget?.product_id === BannerAdsReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                                CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                                CurrentBudget?.product_id === WebsiteDesign_id ||
                                CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                                CurrentBudget?.product_id === OCAGeoDemo_id ||
                                CurrentBudget?.product_id === OCAReEngage_id ||
                                CurrentBudget?.product_id === OCAGeoIntention_id ||
                                CurrentBudget?.product_id === Hydrate_id ||
                                CurrentBudget?.product_id === OutOfHome_id ||
                                CurrentBudget?.product_id === LocalServicesAds_id ||
                                CurrentBudget?.product_id === SocialAdsReEngage_id)
                                return (
                                  <>
                                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                    {index !== 0 ?
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={duplicateNoFocusProducts}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={() => {
                                              DuplicateNoFocusProducts(CRMMonthlyBudget[0]?.product_id)
                                            }}>
                                          </Checkbox><span>{duplicateNoFocusProducts === false ? "duplicate first no focus products" : "Duplicated"}</span></label>
                                      </div>
                                      : ""}
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>What products or services we should not market?</b></label>
                                    <br />
                                    <br />
                                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                      <Table stickyHeader>
                                        <TableRow>
                                          {no_focus_products_columns.map((datacolumns) => (
                                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                          ))}
                                        </TableRow>
                                        {noFocusProductData ? Object.keys(noFocusProductData).map((keyName, Innerindex) => {
                                          if (noFocusProductData[keyName]?.product_id === CurrentBudget?.product_id)
                                            return (
                                              <TableRow key={noFocusProductData[keyName]?.id}>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <CustomInput
                                                    id={`customers_no_focus_products${noFocusProductData[keyName].id}${index + Innerindex}`}
                                                    maxLength="455"
                                                    type="text"
                                                    key={`customers_no_focus_products${noFocusProductData[keyName].id}${index + Innerindex}`}
                                                    formControlProps={{
                                                      fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                      onChange: event => { updateNoFocusProducts(Innerindex, event, CurrentBudget?.product_id) },
                                                      defaultValue: noFocusProductData[keyName]?.no_product_focus ? noFocusProductData[keyName].no_product_focus : ''
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <a style={{ cursor: "pointer", color: NoFocusProductHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveNoFocusProduct(noFocusProductData[keyName]?.id)}><FontAwesomeIcon icon={NoFocusProductHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setNoFocusProductHover({ ...NoFocusProductHover, [Innerindex]: true }) }} onMouseLeave={() => { setNoFocusProductHover({ ...NoFocusProductHover, [Innerindex]: false }) }} /></a>
                                                </TableCell>
                                              </TableRow>
                                            )
                                        }
                                        ) : ""}
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    <a style={{ cursor: "pointer" }} onClick={() => AddNoFocusProduct(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                  </>
                                )
                            })}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || WebsiteDesign ||
                            WebsiteDesignWooCommerce || Chat || LocalServicesAds ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Payment Methods Accepted</b></label>
                                <div style={{ clear: "both" }}></div>
                                <div style={{ float: "left" }}>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.cash ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("cash")}>
                                    </Checkbox><span>Cash</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.visa ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("visa")}>
                                    </Checkbox><span>Visa</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.mastercard ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("mastercard")}>
                                    </Checkbox><span>Mastercard</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.american_express ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("american_express")}>
                                    </Checkbox><span>American Express</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.personal_check ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("personal_check")}>
                                    </Checkbox><span>Personal Check</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.invoice ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("invoice")}>
                                    </Checkbox><span>Invoice</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.insurance ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("insurance")}>
                                    </Checkbox><span>Insurance</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.debit ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("debit")}>
                                    </Checkbox><span>Debit</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.travelers_checks ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("travelers_checks")}>
                                    </Checkbox><span>Traveler's Checks</span>
                                  </label>
                                </div>
                                <div style={{ float: "left", marginLeft: "50px", color: "#363636", fontSize: "16px" }}>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.financing ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("financing")}>
                                    </Checkbox><span>Financing</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.paypal ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("paypal")}>
                                    </Checkbox><span>PayPal</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.discover ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("discover")}>
                                    </Checkbox><span>Discover</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.apple_pay ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("apple_pay")}>
                                    </Checkbox><span>Apple Pay</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.google_pay ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("google_pay")}>
                                    </Checkbox><span>Google Pay</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.venmo ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("venmo")}>
                                    </Checkbox><span>Venmo</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.affirm ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("affirm")}>
                                    </Checkbox><span>Affirm</span>
                                  </label>
                                  <div style={{ clear: "both" }}></div>
                                  <label>
                                    <Checkbox
                                      checked={CRMCustomers?.after_pay ? true : false}
                                      style={{ marginRight: "20px", verticalAlign: "middle" }}
                                      onChange={updateBusinessInfoFieldHandler("after_pay")}>
                                    </Checkbox><span>After Pay</span>
                                  </label>
                                </div>
                              </div>
                            </>
                            : ""}
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || WebsiteDesign ||
                            WebsiteDesignWooCommerce || BannerAdsGeoDemo || BannerAdsGeoIntention ||
                            BannerAdsReEngage || SocialAdsReEngage || OnlineVideoGeoDemo ||
                            OnlineVideoReEngage || OnlineVideoGeoIntention || LocalServicesAds || Chat ||
                            OCAGeoDemo || OCAReEngage || OCAGeoIntention ?
                            <>
                              <div style={{ clear: "both", height: "60px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Which brands do you sell?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {brand_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {brandData ? Object.keys(brandData).map((keyName, Innerindex) => (
                                    <TableRow key={brandData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_brands${brandData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_brands${brandData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateBrands(Innerindex, event) },
                                            value: brandData[keyName]?.brand ? brandData[keyName].brand : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: BrandHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveBrand(brandData[keyName]?.id)}><FontAwesomeIcon icon={BrandHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setBrandHover({ ...BrandHover, [Innerindex]: true }) }} onMouseLeave={() => { setBrandHover({ ...BrandHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={() => AddBrand()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                            </>
                            : ""}
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || BannerAdsGeoDemo ||
                            BannerAdsGeoIntention || BannerAdsReEngage || SocialAdsReEngage || OutOfHome ||
                            OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention ||
                            WebsiteDesign || WebsiteDesignWooCommerce || OCAGeoDemo || OCAReEngage ||
                            OCAGeoIntention || Hydrate || LocalServicesAds || Chat ?
                            <>
                              <div style={{ clear: "both", height: "30px" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Do Have Certifications?</b></label>
                                <Select
                                  onChange={() => setHasCertifications(HasCertifications === "1" ? "0" : "1")}
                                  style={{ verticalAlign: "middle", marginLeft: "20px" }}
                                  inputProps={{
                                    value: HasCertifications
                                  }}
                                >
                                  <MenuItem value="0">No</MenuItem>
                                  <MenuItem value="1">Yes</MenuItem>
                                </Select>
                              </div>
                              {HasCertifications === "1" ?
                                <>
                                  <div style={{ clear: "both", height: "30px" }}></div>
                                  <label style={{ color: "#363636", fontSize: "16px" }}><b>If Yes, Describe which Certifications.</b></label>
                                  <br />
                                  <br />
                                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                    <Table stickyHeader>
                                      <TableRow>
                                        {certifications_columns.map((datacolumns) => (
                                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                        ))}
                                      </TableRow>
                                      {certificateData ? Object.keys(certificateData).map((keyName, Innerindex) => (
                                        <TableRow key={certificateData[keyName]?.id}>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <CustomInput
                                              id={`customers_certificates${certificateData[keyName].id}${Innerindex}`}
                                              maxLength="455"
                                              type="text"
                                              key={`customers_certificates${certificateData[keyName].id}${Innerindex}`}
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                onChange: event => { updateCertificate(Innerindex, event) },
                                                defaultValue: certificateData[keyName]?.certificate ? certificateData[keyName].certificate : ''
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <a style={{ cursor: "pointer", color: CertificateHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveCertificate(certificateData[keyName]?.id)}><FontAwesomeIcon icon={CertificateHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setCertificateHover({ ...CertificateHover, [Innerindex]: true }) }} onMouseLeave={() => { setCertificateHover({ ...CertificateHover, [Innerindex]: false }) }} /></a>
                                          </TableCell>
                                        </TableRow>
                                      )) : ""}
                                    </Table>
                                  </TableContainer>
                                  <br />
                                  <a style={{ cursor: "pointer" }} onClick={() => AddCertificate()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                </>
                                : ""}
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </>
                            : ""}
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || LocalServicesAds || WebsiteDesign ||
                            WebsiteDesignWooCommerce || Chat ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Do you have a License?</b></label>
                                <Select
                                  onChange={() => setHasLincenses(HasLincenses === "1" ? "0" : "1")}
                                  style={{ verticalAlign: "middle", marginLeft: "20px" }}
                                  inputProps={{
                                    value: HasLincenses
                                  }}
                                >
                                  <MenuItem value="0">No</MenuItem>
                                  <MenuItem value="1">Yes</MenuItem>
                                </Select>
                              </div>
                              {HasLincenses === "1" ?
                                <>
                                  <div style={{ clear: "both", height: "30px" }}></div>
                                  <label style={{ color: "#363636", fontSize: "16px" }}><b>If Yes, Describe which Licenses.</b></label>
                                  <br />
                                  <br />
                                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                    <Table stickyHeader>
                                      <TableRow>
                                        {license_columns.map((datacolumns) => (
                                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                        ))}
                                      </TableRow>
                                      {licenseData ? Object.keys(licenseData).map((keyName, Innerindex) => (
                                        <TableRow key={licenseData[keyName]?.id}>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <CustomInput
                                              id={`customers_licenses${licenseData[keyName].id}${Innerindex}`}
                                              maxLength="455"
                                              type="text"
                                              key={`customers_licenses${licenseData[keyName].id}${Innerindex}`}
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                onChange: event => { updateLicenses(Innerindex, event) },
                                                defaultValue: licenseData[keyName]?.license ? licenseData[keyName].license : ''
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <a style={{ cursor: "pointer", color: LicensesHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveLicense(licenseData[keyName]?.id)}><FontAwesomeIcon icon={LicensesHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setLicensesHover({ ...LicensesHover, [Innerindex]: true }) }} onMouseLeave={() => { setLicensesHover({ ...LicensesHover, [Innerindex]: false }) }} /></a>
                                          </TableCell>
                                        </TableRow>

                                      )) : ""}
                                    </Table>
                                  </TableContainer>
                                  <br />
                                  <a style={{ cursor: "pointer" }} onClick={() => AddLicense()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                </>
                                : ""}
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </>
                            : ""}
                          {LocalServicesAds ?
                            <>
                              {uploadPercentagegeneral_liability && (
                                <LinearDeterminate uploadPercentage={uploadPercentagegeneral_liability} />
                              )}
                              <label
                                style={{ marginTop: "30px", color: "#363636", fontSize: "16px", float: "left" }}><b>General Liability Insurance Form?</b></label>
                              <div style={{ marginTop: "20px", float: "left", marginLeft: "40px", width: "70%" }}>
                                <UploadInput
                                  path={`customer_creatives/assets/${CRMNewCustomerID}/${new Date().getTime()}`}
                                  value={CRMCustomers?.general_liability_insurance_form_url || ""}
                                  onChange={(value) => { updateBusinessInfoFieldHandler("general_liability_insurance_form_url")({ target: { value } }) }}
                                  label={CRMCustomers?.general_liability_insurance_form_url ? "View" : "Upload"}
                                  setuploadPercentage={setuploadPercentagegeneral_liability}
                                  accept={{ "application/pdf": [".pdf"] }}
                                />
                              </div>
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </>
                            : ""}
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || BannerAdsGeoDemo ||
                            BannerAdsGeoIntention || BannerAdsReEngage || SocialAdsReEngage || OutOfHome ||
                            OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention ||
                            WebsiteDesign || WebsiteDesignWooCommerce || OCAGeoDemo || OCAReEngage ||
                            OCAGeoIntention || Hydrate || LocalServicesAds || Chat ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Do you have Successes, Awards, and/or Highlights you would like to share?</b></label>
                                <Select
                                  onChange={() => setHasawards(Hasawards === "1" ? "0" : "1")}
                                  style={{ verticalAlign: "middle", marginLeft: "20px" }}
                                  inputProps={{
                                    value: Hasawards
                                  }}
                                >
                                  <MenuItem value="0">No</MenuItem>
                                  <MenuItem value="1">Yes</MenuItem>
                                </Select>
                              </div>
                              {Hasawards === "1" ?
                                <>
                                  <div style={{ clear: "both", height: "30px" }}></div>
                                  <label style={{ color: "#363636", fontSize: "16px" }}><b>If Yes, Describe which Successes, Awards, and/or Highlights.</b></label>
                                  <br />
                                  <br />
                                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                    <Table stickyHeader>
                                      <TableRow>
                                        {successes_columns.map((datacolumns) => (
                                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                        ))}
                                      </TableRow>
                                      {awardData ? Object.keys(awardData).map((keyName, Innerindex) => (
                                        <TableRow key={awardData[keyName]?.id}>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <CustomInput
                                              id={`customers_awards${awardData[keyName].id}${Innerindex}`}
                                              maxLength="455"
                                              type="text"
                                              key={`customers_awards${awardData[keyName].id}${Innerindex}`}
                                              formControlProps={{
                                                fullWidth: true,
                                              }}
                                              inputProps={{
                                                onChange: event => { updateAwards(Innerindex, event) },
                                                defaultValue: awardData[keyName]?.award ? awardData[keyName].award : ''
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell style={{ padding: 5, textAlign: "center" }}>
                                            <a style={{ cursor: "pointer", color: AwardsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveAward(awardData[keyName]?.id)}><FontAwesomeIcon icon={AwardsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setAwardsHover({ ...AwardsHover, [Innerindex]: true }) }} onMouseLeave={() => { setAwardsHover({ ...AwardsHover, [Innerindex]: false }) }} /></a>
                                          </TableCell>
                                        </TableRow>
                                      )) : ""}
                                    </Table>
                                  </TableContainer>
                                  <br />
                                  <a style={{ cursor: "pointer" }} onClick={() => AddAward()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                </>
                                : ""}
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </> : ""}
                          {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || WebsiteDesign ||
                            WebsiteDesignWooCommerce || BannerAdsGeoDemo || BannerAdsGeoIntention ||
                            BannerAdsReEngage || SocialAdsReEngage || Chat ?
                            <>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Coupons, Warranties, Guarantees, or Finanicng Terms?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {coupons_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {couponData ? Object.keys(couponData).map((keyName, Innerindex) => (
                                    <TableRow key={couponData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_coupons${couponData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_coupons${couponData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateCoupons(Innerindex, event) },
                                            defaultValue: couponData[keyName]?.coupon ? couponData[keyName].coupon : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <Select
                                          style={{ marginTop: "10px" }}
                                          inputProps={{
                                            onChange: event => { updateCouponType(Innerindex, event) },
                                            value: couponData[keyName]?.coupon_type ? couponData[keyName].coupon_type : "Select"
                                          }}
                                        >
                                          <MenuItem value="Coupons">Coupons</MenuItem>
                                          <MenuItem value="Warranty">Warranty</MenuItem>
                                          <MenuItem value="Guarantee">Guarantee</MenuItem>
                                          <MenuItem value="Financing">Financing</MenuItem>
                                        </Select>
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <TextField
                                          type="date"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          value={couponData[keyName]?.coupon_start_date?.indexOf("-") === -1 ? moment(parseInt(couponData[keyName]?.coupon_start_date)).format("YYYY-MM-DDTHH:mm") : couponData[keyName]?.coupon_start_date ? couponData[keyName]?.coupon_start_date : ""}
                                          onChange={event => { updateCouponsStartDate(Innerindex, event) }}
                                          style={{ width: "140px" }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <TextField
                                          type="date"
                                          InputLabelProps={{
                                            shrink: true
                                          }}
                                          value={couponData[keyName]?.coupon_stop_date?.indexOf("-") === -1 ? moment(parseInt(couponData[keyName]?.coupon_stop_date)).format("YYYY-MM-DDTHH:mm") : couponData[keyName]?.coupon_stop_date ? couponData[keyName]?.coupon_stop_date : ""}
                                          onChange={event => { updateCouponsStopDate(Innerindex, event) }}
                                          style={{ width: "140px" }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <Checkbox
                                          checked={couponData[keyName]?.coupon_walkins ? true : false}
                                          style={{ marginRight: "20px", verticalAlign: "middle" }}
                                          onChange={event => { updateCouponsWalkins(Innerindex, event) }}
                                        >
                                        </Checkbox>
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: CouponsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveCoupon(couponData[keyName]?.id)}><FontAwesomeIcon icon={CouponsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setCouponsHover({ ...CouponsHover, [Innerindex]: true }) }} onMouseLeave={() => { setCouponsHover({ ...CouponsHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={() => AddCoupon()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </>
                            : ""}
                          {WebsiteDesign || WebsiteDesignWooCommerce || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement ?
                            <>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Please give us at least 3 Frequently Asked Questions?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {frequently_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {faqData ? Object.keys(faqData).map((keyName, Innerindex) => (
                                    <TableRow key={faqData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_faq_questions${faqData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_faq_questions${faqData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateFAQQuestions(Innerindex, event) },
                                            defaultValue: faqData[keyName]?.faq_question ? faqData[keyName].faq_question : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_faq_questions_answers${faqData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_faq_questions_answers${faqData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateFAQAnswers(Innerindex, event) },
                                            defaultValue: faqData[keyName]?.faq_answer ? faqData[keyName].faq_answer : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: FAQHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveFAQ(faqData[keyName]?.id)}><FontAwesomeIcon icon={FAQHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setFAQHover({ ...FAQHover, [Innerindex]: true }) }} onMouseLeave={() => { setFAQHover({ ...FAQHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={() => AddFAQ()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </>
                            : ""}
                          {PaidSearch || LocalServicesAds || BannerAdsGeoDemo || BannerAdsGeoIntention || BannerAdsReEngage ||
                            SocialAdsReEngage || OutOfHome || OnlineVideoGeoDemo || OnlineVideoReEngage ||
                            OnlineVideoGeoIntention || OCAGeoDemo || OCAReEngage || OCAGeoIntention ||
                            WebsiteDesign || WebsiteDesignWooCommerce || OrganicSearchTwo || OrganicSearchFour ||
                            OrganicSearchRegional || OrganicSearchAdditionalBusinessListingManagement ||
                            Chat ?
                            <div style={{ display: "block" }}>
                              <label
                                style={{ marginTop: "30px", color: "#363636", fontSize: "16px" }}><b>Why should people buy from You?</b></label>
                              <textarea
                                id="why_buy_from_you"
                                type="text"
                                defaultValue={CRMCustomers?.why_buy_from_you || ""}
                                onChange={updateBusinessInfoFieldHandler("why_buy_from_you")}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            : ""}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                              (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                            }
                            onClick={() => {
                              Object.keys(productData).map((keyName) => {
                                updateCustomerProducts({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(productData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(noFocusProductData).map((keyName) => {
                                updateCustomerNoFocusProducts({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(noFocusProductData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(brandData).map((keyName) => {
                                updateCustomerBrands({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(brandData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(certificateData).map((keyName) => {
                                updateCustomerCertificates({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(certificateData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(licenseData).map((keyName) => {
                                updateCustomerLicense({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(licenseData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(awardData).map((keyName) => {
                                updateCustomerAwards({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(awardData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(couponData).map((keyName) => {
                                updateCustomerCoupons({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(couponData[keyName], ["__typename"])
                                  }
                                });
                              })

                              Object.keys(faqData).map((keyName) => {
                                updateCustomerFAQ({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(faqData[keyName], ["__typename"])
                                  }
                                });
                              })

                              updateDatabaseBusinessInfo();
                            }}
                          >Save</Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {PaidSearch || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement || BannerAdsGeoDemo ||
                  BannerAdsGeoIntention || BannerAdsReEngage || SocialAdsReEngage || OutOfHome ||
                  OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention || OCAGeoDemo ||
                  OCAReEngage || OCAGeoIntention ?
                  <>
                    <div id="panel7" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel7" id="panel7_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Competitors</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>Who are the top competitors?</b></label>
                          <br />
                          <br />
                          <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                            <Table stickyHeader>
                              <TableRow>
                                {top_competitors_columns.map((datacolumns) => (
                                  <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                ))}
                              </TableRow>
                              {competitorData ? Object.keys(competitorData).map((keyName, Innerindex) => (
                                <TableRow key={competitorData[keyName]?.id}>
                                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                                    <CustomInput
                                      id={`customers_top_competitors${competitorData[keyName].id}${Innerindex}`}
                                      maxLength="455"
                                      type="text"
                                      key={`customers_top_competitors${competitorData[keyName].id}${Innerindex}`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        onChange: event => { updateTopCompetitors(Innerindex, "customers_top_competitors", event) },
                                        defaultValue: competitorData[keyName]?.customers_top_competitors ? competitorData[keyName].customers_top_competitors : ''
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                                    <CustomInput
                                      id={`competitorData[keyName]${competitorData[keyName].id}${Innerindex}`}
                                      maxLength="455"
                                      type="text"
                                      key={`competitorData[keyName]${competitorData[keyName].id}${Innerindex}`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        onChange: event => { updateTopCompetitors(Innerindex, "top_competitors_url", event) },
                                        defaultValue: competitorData[keyName]?.top_competitors_url ? competitorData[keyName].top_competitors_url : ''
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 0, textAlign: "center" }}>
                                    <a style={{ cursor: "pointer", color: TopCompetitorsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveCompetitor(competitorData[keyName]?.id)}><FontAwesomeIcon icon={TopCompetitorsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTopCompetitorsHover({ ...TopCompetitorsHover, [Innerindex]: true }) }} onMouseLeave={() => { setTopCompetitorsHover({ ...TopCompetitorsHover, [Innerindex]: false }) }} /></a>
                                  </TableCell>
                                </TableRow>
                              )) : ""}
                            </Table>
                          </TableContainer>
                          <br />
                          <a style={{ cursor: "pointer" }} onClick={() => AddCompetitor()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            onClick={() => {
                              Object.keys(competitorData).map((keyName) => {
                                updateCustomerCompetitors({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(competitorData[keyName], ["__typename"])
                                  }
                                })
                              })

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >
                            Submit
                          </Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </> : ""}
                {PaidSearch || LocalServicesAds || BannerAdsGeoDemo || BannerAdsGeoIntention || BannerAdsReEngage | OutOfHome ||
                  SocialAdsReEngage || OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention || OCAGeoDemo ||
                  OCAReEngage || OCAGeoIntention || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement ?
                  <>
                    <div id="panel8" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel8" id="panel8_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Lead Goals</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {CRMMonthlyBudget?.filter((CurrentBudget) =>
                            CurrentBudget?.product_id === PaidSearch_id ||
                            CurrentBudget?.product_id === LocalServicesAds_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OutOfHome_id ||
                            CurrentBudget?.product_id === SocialAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === OrganicSearchTwo_id ||
                            CurrentBudget?.product_id === OrganicSearchFour_id ||
                            CurrentBudget?.product_id === OrganicSearchRegional_id ||
                            CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id)?.map((CurrentBudget, index) => {
                              if (CurrentBudget?.product_id === PaidSearch_id ||
                                CurrentBudget?.product_id === LocalServicesAds_id ||
                                CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                                CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                                CurrentBudget?.product_id === BannerAdsReEngage_id ||
                                CurrentBudget?.product_id === OutOfHome_id ||
                                CurrentBudget?.product_id === SocialAdsReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                                CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                                CurrentBudget?.product_id === OCAGeoDemo_id ||
                                CurrentBudget?.product_id === OCAReEngage_id ||
                                CurrentBudget?.product_id === OCAGeoIntention_id ||
                                CurrentBudget?.product_id === OrganicSearchTwo_id ||
                                CurrentBudget?.product_id === OrganicSearchFour_id ||
                                CurrentBudget?.product_id === OrganicSearchRegional_id ||
                                CurrentBudget?.product_id === OrganicSearchAdditionalBusinessListingManagement_id)
                                return (
                                  <>
                                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                    {index !== 0 ?
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={duplicateLeadGoals}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={() => {
                                              DuplicateLeadGoals(marketingGoal[0]?.product_id);
                                            }}>
                                          </Checkbox><span>{duplicateNoFocusProducts === false ? "duplicate first Lead Goals values" : "Duplicated"}</span></label>
                                      </div>
                                      : ""}
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Average Cost Per Lead</b></label>
                                      <input
                                        id="avg_cost_per_lead"
                                        type="text"
                                        value={marketingGoal[index]?.cost_per_lead_goal}
                                        onChange={updateFieldHandler(
                                          "cost_per_lead_goal",
                                          CurrentBudget?.product_id,
                                          index
                                        )}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Average Sale/Transaction</b></label>
                                      <input
                                        id="average_sale_goal"
                                        type="text"
                                        value={marketingGoal[index]?.average_sale_goal}
                                        onChange={updateFieldHandler(
                                          "average_sale_goal",
                                          CurrentBudget?.product_id,
                                          index
                                        )}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Average Liftime Value of a Customer</b></label>
                                      <input
                                        id="avg_lifetime_value"
                                        type="text"
                                        value={marketingGoal[index]?.lifetime_value}
                                        onChange={updateFieldHandler("lifetime_value",
                                          CurrentBudget?.product_id,
                                          index)}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Monthly leads needed to get an ROI</b></label>
                                      <input
                                        id="leads_needed_to_break_even"
                                        type="text"
                                        value={marketingGoal[index]?.monthly_leads_needed_breakeven}
                                        onChange={updateFieldHandler("monthly_leads_needed_breakeven",
                                          CurrentBudget?.product_id,
                                          index)}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Leads Needed to Increase Budget</b></label>
                                      <input
                                        id="leads_needed_to_increase_budget"
                                        type="text"
                                        value={marketingGoal[index]?.leads_needed_increase_budget}
                                        onChange={updateFieldHandler("leads_needed_increase_budget",
                                          CurrentBudget?.product_id,
                                          index)}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    <div style={{ display: "block" }}>
                                      <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Leads a Month Needed to be Happy</b></label>
                                      <input
                                        id="leads_needed_to_be_happy"
                                        type="text"
                                        value={marketingGoal[index]?.leads_needed_to_be_happy}
                                        onChange={updateFieldHandler("leads_needed_to_be_happy",
                                          CurrentBudget?.product_id,
                                          index)}
                                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                      />
                                    </div>
                                    <div style={{ clear: "both" }}></div>
                                    {me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                                      me?.readMe?.user_level === "campaign_consultant" ?
                                      <>
                                        <div style={{ display: "block" }}>
                                          <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>CPL Goal</b></label>
                                          <input
                                            id="cpl_goal"
                                            type="text"
                                            value={marketingGoal[index]?.cpl_goal}
                                            onChange={updateFieldHandler("cpl_goal",
                                              CurrentBudget?.product_id,
                                              index)}
                                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                          />
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                      </>
                                      : ""}
                                    {me?.readMe?.user_level === "web_designer" ||
                                      me?.readMe?.user_level === "ad_consultant" || me?.readMe?.user_level === "content_manager" ?
                                      <>
                                        <div style={{ display: "block" }}>
                                          <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>CPL Goal: {marketingGoal?.cpl_goal}</b></label>
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                      </>
                                      : ""}
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                  </>
                                )
                            })}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            onClick={() => {
                              let FoundMissingLeadGoal = false;
                              Object.keys(marketingGoal).map((keyName) => {
                                if (marketingGoal[keyName] === null)
                                  return;

                                if (!marketingGoal[keyName]?.cost_per_lead_goal
                                  || !marketingGoal[keyName]?.leads_needed_to_be_happy
                                  || !marketingGoal[keyName]?.leads_needed_increase_budget
                                  || !marketingGoal[keyName]?.monthly_leads_needed_breakeven
                                  || !marketingGoal[keyName]?.lifetime_value || !marketingGoal[keyName]?.average_sale_goal
                                  || marketingGoal[keyName]?.cost_per_lead_goal?.length === 0
                                  || marketingGoal[keyName]?.leads_needed_to_be_happy?.length === 0
                                  || marketingGoal[keyName]?.leads_needed_increase_budget?.length === 0
                                  || marketingGoal[keyName]?.monthly_leads_needed_breakeven?.length === 0
                                  || marketingGoal[keyName]?.lifetime_value?.length === 0 || marketingGoal[keyName]?.average_sale_goal?.length === 0
                                  || ((me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                                    me?.readMe?.user_level === "campaign_consultant") && (!marketingGoal[keyName]?.cpl_goal ||
                                      marketingGoal[keyName]?.cpl_goal?.length === 0)
                                  )
                                ) {
                                  FoundMissingLeadGoal = true;
                                }
                              });

                              if (FoundMissingLeadGoal) {
                                alert("Please enter all Lead Goal values");
                                return;
                              }

                              Object.keys(marketingGoal).map((keyName) => {
                                if (marketingGoal[keyName] !== null) {
                                  updateMarketingGoal({
                                    variables: {
                                      customer_id: CRMNewCustomerID,
                                      onboarding_id: crm_onboarding_id,
                                      ...omit(marketingGoal[keyName], ["__typename"])
                                    }
                                  })
                                }
                              })

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >
                            Submit
                          </Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {SocialAdsReEngage || BannerAdsGeoDemo || BannerAdsGeoIntention ||
                  BannerAdsReEngage || OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention ||
                  WebsiteDesign || WebsiteDesignWooCommerce || OCAGeoDemo || OCAReEngage || OCAGeoIntention ||
                  OutOfHome ?
                  <>
                    <div id="panel9" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel9" id="panel9_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Demographics and Interests</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {CRMMonthlyBudget?.filter((CurrentBudget) =>
                            CurrentBudget?.product_id === SocialAdsReEngage_id ||
                            CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                            CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                            CurrentBudget?.product_id === BannerAdsReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                            CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                            CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                            CurrentBudget?.product_id === WebsiteDesign_id ||
                            CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                            CurrentBudget?.product_id === OCAGeoDemo_id ||
                            CurrentBudget?.product_id === OCAReEngage_id ||
                            CurrentBudget?.product_id === OCAGeoIntention_id ||
                            CurrentBudget?.product_id === OutOfHome_id)?.map((CurrentBudget, index) => {
                              if (CurrentBudget?.product_id === SocialAdsReEngage_id ||
                                CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                                CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                                CurrentBudget?.product_id === BannerAdsReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                                CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                                CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                                CurrentBudget?.product_id === WebsiteDesign_id ||
                                CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                                CurrentBudget?.product_id === OCAGeoDemo_id ||
                                CurrentBudget?.product_id === OCAReEngage_id ||
                                CurrentBudget?.product_id === OCAGeoIntention_id ||
                                CurrentBudget?.product_id === OutOfHome_id)
                                return (
                                  <>
                                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                    {index !== 0 ?
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={duplicateAges}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={() => CopyFirstProductAges(CRMMonthlyBudget[0]?.product_id)}>
                                          </Checkbox><span>{duplicateAges === false ? "duplicate first targeting" : "Duplicated"}</span></label>
                                      </div>
                                      : ""}
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    <div style={{ float: "left" }}>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle", color: "#363636", fontSize: "16px" }}><b>Ages</b></label>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_no_restrictions[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_no_restrictions", CurrentBudget?.product_id)}>
                                          </Checkbox><span>No Restrictions</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_18_24[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_18_24", CurrentBudget?.product_id)}>
                                          </Checkbox><span>18-24</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_25_34[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_25_34", CurrentBudget?.product_id)}>
                                          </Checkbox><span>25-34</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_35_44[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_35_44", CurrentBudget?.product_id)}>
                                          </Checkbox><span>35-44</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_45_54[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_45_54", CurrentBudget?.product_id)}>
                                          </Checkbox><span>45-54</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_55_64[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_55_64", CurrentBudget?.product_id)}>
                                          </Checkbox><span>55-64</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_65_plus[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_65_plus", CurrentBudget?.product_id)}>
                                          </Checkbox><span>65+</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={ages_unknown[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("ages_unknown", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Unknown</span></label>
                                      </div>
                                      <div style={{ clear: "both", height: "30px" }}></div>
                                    </div>
                                    <div style={{ float: "left" }}>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle", color: "#363636", fontSize: "16px" }}><b>Income</b></label>
                                        <div style={{ clear: "both" }}></div>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_top_10_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_top_10_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Top 10%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_11_20_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_11_20_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>11-20%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_21_30_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_21_30_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>21-30%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_31_40_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_31_40_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>31-40%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_41_50_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_41_50_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>41-50%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_lower_50_percent[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_lower_50_percent", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Lower 50%</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={income_unknown[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("income_unknown", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Unknown</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                    </div>
                                    <div style={{ float: "left" }}>
                                      <label style={{ verticalAlign: "middle", color: "#363636", fontSize: "16px" }}><b>Gender</b></label>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={gender_no_restrictions[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("gender_no_restrictions", CurrentBudget?.product_id)}>
                                          </Checkbox><span>No Restrictions</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={gender_male[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("gender_male", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Male</span></label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={gender_female[index] ? true : false}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={updateBusinessInfoFieldHandler("gender_female", CurrentBudget?.product_id)}>
                                          </Checkbox><span>Female</span></label>
                                      </div>
                                    </div>
                                    <div style={{ float: "left" }}>
                                      <label style={{ verticalAlign: "middle", color: "#363636", fontSize: "16px" }}><b>Parent</b></label>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            onChange={updateBusinessInfoFieldHandler("not_a_parent", CurrentBudget?.product_id)}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            checked={not_a_parent[index] ? true : false}
                                          />Not a Parent</label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            onChange={updateBusinessInfoFieldHandler("parent_true", CurrentBudget?.product_id)}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            checked={parent_true[index] ? true : false}
                                          />Parent</label>
                                      </div>
                                      <div style={{ clear: "both" }}></div>
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            onChange={updateBusinessInfoFieldHandler("unknown_parent", CurrentBudget?.product_id)}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            checked={unknown_parent[index] ? true : false}
                                          />Unknown</label>
                                      </div>
                                      <div style={{ clear: "both", height: "40px" }}></div>
                                    </div>
                                    <div style={{ clear: "both", height: "40px" }}></div>
                                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{CurrentBudget?.product_name}</b></h4>
                                    {index !== 0 ?
                                      <div style={{ display: "inline-block" }}>
                                        <label style={{ width: "200px", verticalAlign: "middle" }}>
                                          <Checkbox
                                            checked={duplicateTargeting}
                                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                                            onChange={() => DuplicateTargeting(CRMMonthlyBudget[0]?.product_id)}>
                                          </Checkbox><span>{duplicateTargeting === false ? "duplicate first industry targeting" : "Duplicated"}</span></label>
                                      </div>
                                      : ""}
                                    <div style={{ clear: "both", height: "40px" }}></div>
                                    <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>Industries to Target</b></label>
                                    <br />
                                    <br />
                                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                      <Table stickyHeader>
                                        <TableRow>
                                          {industries_columns.map((datacolumns) => (
                                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                          ))}
                                        </TableRow>
                                        {targetindustryData ? Object.keys(targetindustryData).map((keyName, Innerindex) => {
                                          if (targetindustryData[keyName]?.product_id === CurrentBudget?.product_id)
                                            return (
                                              <TableRow key={targetindustryData[keyName]?.id}>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <CustomInput
                                                    id={`customers_target_industry${targetindustryData[keyName].id}${Innerindex}`}
                                                    maxLength="455"
                                                    type="text"
                                                    key={`customers_target_industry${targetindustryData[keyName].id}${Innerindex}`}
                                                    formControlProps={{
                                                      fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                      onChange: event => { updateTargetIndustries(Innerindex, event, CurrentBudget?.product_id) },
                                                      defaultValue: targetindustryData[keyName]?.target_industries ? targetindustryData[keyName].target_industries : ''
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <a style={{ cursor: "pointer", color: TargetIndustriesHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveTargetIndustries(targetindustryData[keyName]?.id)}><FontAwesomeIcon icon={TargetIndustriesHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTargetIndustriesHover({ ...TargetIndustriesHover, [Innerindex]: true }) }} onMouseLeave={() => { setTargetIndustriesHover({ ...TargetIndustriesHover, [Innerindex]: false }) }} /></a>
                                                </TableCell>
                                              </TableRow>
                                            )
                                        }) : ""}
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    <a style={{ cursor: "pointer" }} onClick={() => AddTargetIndustry(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>Job Title to Target</b></label>
                                    <br />
                                    <br />
                                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                      <Table stickyHeader>
                                        <TableRow>
                                          {job_title_columns.map((datacolumns) => (
                                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                          ))}
                                        </TableRow>
                                        {targetjobtitleData ? Object.keys(targetjobtitleData).map((keyName, Innerindex) => {
                                          if (targetjobtitleData[keyName]?.product_id === CurrentBudget?.product_id)
                                            return (
                                              <TableRow key={targetjobtitleData[keyName]?.id}>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <CustomInput
                                                    id={`customers_target_job_title${targetjobtitleData[keyName].id}${Innerindex}`}
                                                    maxLength="455"
                                                    type="text"
                                                    key={`customers_target_job_title${targetjobtitleData[keyName].id}${Innerindex}`}
                                                    formControlProps={{
                                                      fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                      onChange: event => { updateTargetJobTitle(Innerindex, event, CurrentBudget?.product_id) },
                                                      defaultValue: targetjobtitleData[keyName]?.target_job_title ? targetjobtitleData[keyName].target_job_title : ''
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <a style={{ cursor: "pointer", color: TargetJobTitleHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveTargetJobTitle(targetjobtitleData[keyName]?.id)}><FontAwesomeIcon icon={TargetJobTitleHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTargetJobTitleHover({ ...TargetJobTitleHover, [Innerindex]: true }) }} onMouseLeave={() => { setTargetJobTitleHover({ ...TargetJobTitleHover, [Innerindex]: false }) }} /></a>
                                                </TableCell>
                                              </TableRow>
                                            )
                                        }) : ""}
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    <a style={{ cursor: "pointer" }} onClick={() => AddTargetJobTitle(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>Interests for Targeting</b></label>
                                    <br />
                                    <br />
                                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                      <Table stickyHeader>
                                        <TableRow>
                                          {interests_columns.map((datacolumns) => (
                                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                          ))}
                                        </TableRow>
                                        {targetinginterestsData ? Object.keys(targetinginterestsData).map((keyName, Innerindex) => {
                                          if (targetinginterestsData[keyName]?.product_id === CurrentBudget?.product_id)
                                            return (
                                              <TableRow key={targetinginterestsData[keyName]?.id}>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <CustomInput
                                                    id={`customers_targeting_interests${targetinginterestsData[keyName].id}${Innerindex}`}
                                                    maxLength="455"
                                                    type="text"
                                                    key={`customers_targeting_interests${targetinginterestsData[keyName].id}${Innerindex}`}
                                                    formControlProps={{
                                                      fullWidth: true,
                                                    }}
                                                    inputProps={{
                                                      onChange: event => { updateTargetingInterests(Innerindex, event, CurrentBudget?.product_id) },
                                                      defaultValue: targetinginterestsData[keyName]?.targeting_interests ? targetinginterestsData[keyName].targeting_interests : ''
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                                  <a style={{ cursor: "pointer", color: TargetingInterestsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveTargetingInterests(targetinginterestsData[keyName]?.id)}><FontAwesomeIcon icon={TargetingInterestsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTargetingInterestsHover({ ...TargetingInterestsHover, [Innerindex]: true }) }} onMouseLeave={() => { setTargetingInterestsHover({ ...TargetingInterestsHover, [Innerindex]: false }) }} /></a>
                                                </TableCell>
                                              </TableRow>
                                            )
                                        }) : ""}
                                      </Table>
                                    </TableContainer>
                                    <br />
                                    <a style={{ cursor: "pointer" }} onClick={() => AddTargetingInterest(CurrentBudget?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                                    <div style={{ clear: "both", height: "30px" }}></div>
                                    {CRMMonthlyBudget?.filter((CurrentBudget) =>
                                      CurrentBudget?.product_id === SocialAdsReEngage_id ||
                                      CurrentBudget?.product_id === BannerAdsGeoDemo_id ||
                                      CurrentBudget?.product_id === BannerAdsGeoIntention_id ||
                                      CurrentBudget?.product_id === BannerAdsReEngage_id ||
                                      CurrentBudget?.product_id === OnlineVideoGeoDemo_id ||
                                      CurrentBudget?.product_id === OnlineVideoReEngage_id ||
                                      CurrentBudget?.product_id === OnlineVideoGeoIntention_id ||
                                      CurrentBudget?.product_id === WebsiteDesign_id ||
                                      CurrentBudget?.product_id === WebsiteDesignWooCommerce_id ||
                                      CurrentBudget?.product_id === OCAGeoDemo_id ||
                                      CurrentBudget?.product_id === OCAReEngage_id ||
                                      CurrentBudget?.product_id === OCAGeoIntention_id ||
                                      CurrentBudget?.product_id === OutOfHome_id)?.length - 1 === index ?
                                      <Button
                                        type="submit"
                                        disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                                          (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                                        }
                                        onClick={() => {

                                          Object.keys(targetindustryData).map((keyName) => {
                                            updateCustomersTargetIndustries({
                                              variables: {
                                                customer_id: CRMNewCustomerID,
                                                crm_onboarding_id: crm_onboarding_id,
                                                data: omit(targetindustryData[keyName], ["__typename"])
                                              }
                                            })
                                          })

                                          Object.keys(targetjobtitleData).map((keyName) => {
                                            updateCustomersTargetJobTitles({
                                              variables: {
                                                customer_id: CRMNewCustomerID,
                                                crm_onboarding_id: crm_onboarding_id,
                                                data: omit(targetjobtitleData[keyName], ["__typename"])
                                              }
                                            })
                                          })

                                          Object.keys(targetinginterestsData).map((keyName) => {
                                            updateCustomersTargetInterests({
                                              variables: {
                                                customer_id: CRMNewCustomerID,
                                                crm_onboarding_id: crm_onboarding_id,
                                                data: omit(targetinginterestsData[keyName], ["__typename"])
                                              }
                                            })
                                          })

                                          updateDatabaseBusinessInfo();
                                        }}
                                      >Save</Button>
                                      : ""}
                                  </>
                                )
                            })}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement || OnlineVideoGeoDemo ||
                  OnlineVideoReEngage || OnlineVideoGeoIntention || WebsiteDesign ||
                  WebsiteDesignWooCommerce || Hydrate || LocalServicesAds ||
                  OCAProduction || OCAProductionTwo || OCAProductionThree || Chat || OCAGeoDemo ||
                  OCAReEngage || OCAGeoIntention ?
                  <>
                    <div id="panel10" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel10" id="panel10_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Social Channels</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <label>Social Channels</label>
                          <br />
                          <br />
                          <TableContainer>
                            <Table stickyHeader>
                              <TableRow>
                                {social_columns?.map((datacolumns) => (
                                  <TableCell style={{ textAlign: "left", padding: "0px", margin: "0px" }}><label className={classes.selectFormControl}>{datacolumns?.Header}</label></TableCell>
                                ))}
                              </TableRow>
                              {CRMSocials ? Object.keys(CRMSocials)?.map((keyName, index) => (
                                <>
                                  <TableRow key={CRMSocials[keyName]?.id}>
                                    <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                      <SocialSelect
                                        options={AllSocials?.map(({ id, social_channel }) => ({
                                          key: id,
                                          label: social_channel
                                        }))}
                                        selected={CRMSocials[keyName].social_channel_id || 0}
                                        onSelect={updateSocialsocial_channel_id(index, "social_channel_id")}
                                        style={{ marginBottom: 2, height: "40px" }}
                                      />&nbsp;&nbsp;
                                      {CRMSocials[keyName]?.social_channel_url ? <a target="_blank" href={"//" + CRMSocials[keyName].social_channel_url}>Go To Link</a> : ""}
                                    </TableCell>
                                    <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                      <a style={{ cursor: "pointer", color: SocialMediaHover[index] === true ? "red" : "#000" }} onClick={RemoveSocial(CRMSocials[keyName]?.id)}><FontAwesomeIcon icon={SocialMediaHover[index] ? faDumpsterFire : faDumpster} onMouseOver={() => { setSocialMediaHover({ ...SocialMediaHover, [index]: true }) }} onMouseLeave={() => { setSocialMediaHover({ ...SocialMediaHover, [index]: false }) }} /></a>
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell style={{ padding: "5px", margin: "0px", width: "100%" }} colSpan="2">
                                      <CustomInput
                                        id={`customers_top_products${CRMSocials[keyName].id}`}
                                        maxLength="2048"
                                        type="text"
                                        key={`customers_top_products${CRMSocials[keyName].id}`}
                                        formControlProps={{
                                          fullWidth: true,
                                        }}
                                        inputProps={{
                                          onChange: event => { updateSocialssocial_channel_url(index, event) },
                                          defaultValue: CRMSocials[keyName]?.social_channel_url ? CRMSocials[keyName].social_channel_url : ''
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                              )) : ""}
                            </Table>
                          </TableContainer>
                          <br />
                          <a style={{ cursor: "pointer" }} onClick={AddSocial}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Social Channel</a>
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            color="orange"
                            type="submit"
                            onClick={() => {
                              let ErrorPresent = false;
                              Object.keys(CRMSocials)?.map((keyName) => {
                                if (CRMSocials[keyName].social_channel_id === null || CRMSocials[keyName].social_channel_id === '') {
                                  alert("Please Select a Social Channel");
                                  ErrorPresent = true;
                                  return;
                                }
                                else if (CRMSocials[keyName].social_channel_url === null || CRMSocials[keyName].social_channel_url === '') {
                                  alert("Please Enter a Social URL");
                                  ErrorPresent = true;
                                  return;
                                }

                                updateSocials({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    data: omit(CRMSocials[keyName], ["__typename"])
                                  }
                                })
                              })

                              if (ErrorPresent === false) {
                                refetchSocial();

                                const notify = options => {
                                  const key = enqueueSnackbar(options?.text, {
                                    variant: options?.variant,
                                    persist: true
                                  });
                                  setTimeout(() => {
                                    closeSnackbar(key);
                                  }, options?.delay || 2000);
                                }
                                notify({
                                  text: "Information has been successfully submitted",
                                  variant: "success"
                                });
                              }
                            }
                            }
                          >
                            Submit
                          </Button>
                          <div style={{ clear: "both" }}></div>

                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  :
                  ""}
                {PaidSearch || WebsiteDesign || WebsiteDesignWooCommerce || BannerAdsGeoDemo || BannerAdsGeoIntention ||
                  BannerAdsReEngage || SocialAdsReEngage || Hydrate || LocalServicesAds || OrganicSearchTwo ||
                  OrganicSearchFour || OrganicSearchRegional || OrganicSearchAdditionalBusinessListingManagement ||
                  OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention || OCAProduction ||
                  OCAProductionTwo || OCAProductionThree || OutOfHome || OCAGeoDemo || OCAReEngage ||
                  OCAGeoIntention ?
                  <>
                    <div id="panel11" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel11" id="panel11_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Your Company's Brand Information</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <ImageList>
                            {BrandPhotosandLogos?.map((CurrentPhoto, index) => {
                              if (CurrentPhoto?.photo_name === "Company Photos")
                                return (
                                  <ImageListItem key={CurrentPhoto?.id}>
                                    <img
                                      src={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format`}
                                      srcSet={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                      alt={CurrentPhoto?.photo_alt_text || ""}
                                      loading="lazy"
                                      style={{ maxHeight: "200px", objectFit: 'contain', backgroundColor: "#363636" }}
                                    />
                                    <ImageListItemBar title={CurrentPhoto?.photo_name}
                                      subtitle={CurrentPhoto?.photo_description}
                                      actionIcon={
                                        <>
                                          <FontAwesomeIcon
                                            style={{ color: '#fff', marginRight: "15px", marginLeft: "15px", cursor: "pointer" }}
                                            size="lg"
                                            icon={PhotoHover[index] ? faDumpsterFire : faDumpster}
                                            onMouseOver={() => { setPhotoHover({ ...PhotoHover, [index]: true }) }}
                                            onMouseLeave={() => { setPhotoHover({ ...PhotoHover, [index]: false }) }}
                                            onClick={() => {
                                              if (window.confirm("Are you sure?")) {
                                                submitDeletePhotoRequest(CurrentPhoto?.photo_url);
                                              }
                                            }} />
                                        </>
                                      }>
                                    </ImageListItemBar>
                                  </ImageListItem>
                                )
                            }
                            )}
                          </ImageList>
                          {uploadPercentageCompanyPhotos && (
                            <LinearDeterminate uploadPercentage={uploadPercentageCompanyPhotos} />
                          )}
                          <div style={{ clear: "both", marginTop: "20px" }}></div>
                          <label
                            style={{ marginTop: "30px", color: "#363636", fontSize: "16px" }}><b>Upload your Company Photos here.</b></label>
                          <div style={{ marginTop: "20px" }}>
                            <Dropzone
                              setuploadPercentage={setuploadPercentageCompanyPhotos}
                              path={`customer_creatives/assets/${CRMNewCustomerID}/${new Date().getTime()}`}
                              onChange={(value) => { updateCustomerPhotoGalleryFieldHandler("photo_url")({ target: { value } }) }}
                              value={CustomerPhotoGallerysubmissionData?.photo_url || ""}
                              accept={{
                                'image/jpeg': [".jpeg"],
                                'image/png': [".png"],
                                'image/jpg': [".jpg"],
                                'image/tiff': [".tiff"],
                                'image/ai': [".ai"],
                                'image/gif': [".gif"],
                                'image/eps': [".eps"],
                                'image/psd': [".psd"],
                                'image/raw': [".raw"],
                                'image/idd': [".idd"]
                              }}
                              multiple={true}
                              maxFiles={100}
                            >
                              {() => {
                                return (
                                  <div style={{ width: "100%", height: "300px", borderColor: "grey", borderWidth: "1px", borderStyle: "solid", textAlign: "center", verticalAlign: "middle", lineHeight: "300px", borderRadius: "5px" }}>
                                    <Button
                                      style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                                    >
                                      Upload file(s)
                                    </Button>
                                    <span> ...or drag image file(s) here.</span>
                                  </div>
                                )
                              }}</Dropzone>
                          </div>
                          {OnlineVideoGeoDemo || OnlineVideoReEngage || OnlineVideoGeoIntention || OCAGeoDemo ||
                            OCAReEngage || OCAGeoIntention ?
                            <>
                              <div style={{ clear: "both", height: "30px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Do you have links to Current Videos?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {online_video_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {onlinevideoData ? Object.keys(onlinevideoData).map((keyName, Innerindex) => (
                                    <TableRow key={onlinevideoData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_online_videos${onlinevideoData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_online_videos${onlinevideoData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateOnlineVideo(Innerindex, event) },
                                            defaultValue: onlinevideoData[keyName]?.online_video ? onlinevideoData[keyName].online_video : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: OnlineVideoHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveOnlineVideo(onlinevideoData[keyName]?.id)}><FontAwesomeIcon icon={OnlineVideoHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setOnlineVideoHover({ ...OnlineVideoHover, [Innerindex]: true }) }} onMouseLeave={() => { setOnlineVideoHover({ ...OnlineVideoHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddOnlineVideo}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                            </> : ""}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <ImageList>
                            {BrandPhotosandLogos?.map((CurrentPhoto, index) => {
                              if (CurrentPhoto?.photo_name === "Company Logos")
                                return (
                                  <ImageListItem key={CurrentPhoto?.id}>
                                    <img
                                      src={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format`}
                                      srcSet={`${CurrentPhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                      alt={CurrentPhoto?.photo_alt_text || ""}
                                      loading="lazy"
                                      style={{ maxHeight: "200px", objectFit: 'contain', backgroundColor: "#363636" }}
                                    />
                                    <ImageListItemBar title={CurrentPhoto?.photo_name}
                                      subtitle={CurrentPhoto?.photo_description}
                                      actionIcon={
                                        <>
                                          <FontAwesomeIcon
                                            style={{ color: '#fff', marginRight: "15px", marginLeft: "15px", cursor: "pointer" }}
                                            size="lg"
                                            icon={PhotoHover[index] ? faDumpsterFire : faDumpster}
                                            onMouseOver={() => { setPhotoHover({ ...PhotoHover, [index]: true }) }}
                                            onMouseLeave={() => { setPhotoHover({ ...PhotoHover, [index]: false }) }}
                                            onClick={() => {
                                              if (window.confirm("Are you sure?")) {
                                                submitDeletePhotoRequest(CurrentPhoto?.photo_url);
                                              }
                                            }} />
                                        </>
                                      }>
                                    </ImageListItemBar>
                                  </ImageListItem>
                                )
                            }
                            )}
                          </ImageList>
                          {uploadPercentageLogos && (
                            <LinearDeterminate uploadPercentage={uploadPercentageLogos} />
                          )}
                          <div style={{ clear: "both", marginTop: "20px" }}></div>
                          <label
                            style={{ marginTop: "30px", color: "#363636", fontSize: "16px" }}><b>Upload your Logos here. (EPS, Ai, SVG or PNG files preferred)</b></label>
                          <div style={{ marginTop: "20px" }}>
                            <Dropzone
                              setuploadPercentage={setuploadPercentageLogos}
                              path={`customer_creatives/assets/${CRMNewCustomerID}/${new Date().getTime()}`}
                              onChange={(value) => { updateCustomerPhotoGalleryFieldHandler("logo_gallery_url")({ target: { value } }) }}
                              value={LogoGallerysubmissionData?.photo_url || ""}
                              accept={{
                                'image/jpeg': [".jpeg"],
                                'image/png': [".png"],
                                'image/jpg': [".jpg"],
                                'image/tiff': [".tiff"],
                                'image/ai': [".ai"],
                                'image/gif': [".gif"],
                                'image/eps': [".eps"],
                                'image/psd': [".psd"],
                                'image/raw': [".raw"],
                                'image/idd': [".idd"]
                              }}
                              multiple={true}
                              maxFiles={100}
                            >
                              {() => {
                                return (
                                  <div style={{ width: "100%", height: "300px", borderColor: "grey", borderWidth: "1px", borderStyle: "solid", textAlign: "center", verticalAlign: "middle", lineHeight: "300px", borderRadius: "5px" }}>
                                    <Button
                                      style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                                    >
                                      Upload file(s)
                                    </Button>
                                    <span> ...or drag image file(s) here.</span>
                                  </div>
                                )
                              }}</Dropzone>
                          </div>
                          <div style={{ clear: "both", height: "30px" }}></div>
                          {BannerAdsGeoDemo || BannerAdsGeoIntention || BannerAdsReEngage || SocialAdsReEngage || OutOfHome ||
                            OCAProduction || OCAProductionTwo || OCAProductionThree ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Company Primary Font</b></label>
                                <input
                                  id="company_primary_font"
                                  type="text"
                                  value={BrandColorsAndFontssubmissionData?.company_primary_font}
                                  onChange={updateBrandColorsAndFontsFieldHandler("company_primary_font")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Company Secondary Font</b></label>
                                <input
                                  id="company_secondary_font"
                                  type="text"
                                  value={BrandColorsAndFontssubmissionData?.company_secondary_font}
                                  onChange={updateBrandColorsAndFontsFieldHandler("company_secondary_font")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Primary Company Color</b></label>
                                <input
                                  id="primary_company_color"
                                  type="text"
                                  value={BrandColorsAndFontssubmissionData?.primary_company_color}
                                  onChange={updateBrandColorsAndFontsFieldHandler("primary_company_color")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Secondary Company Color</b></label>
                                <input
                                  id="secondary_company_color"
                                  type="text"
                                  value={BrandColorsAndFontssubmissionData?.secondary_company_color}
                                  onChange={updateBrandColorsAndFontsFieldHandler("secondary_company_color")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both", height: "50px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Do you have any Additional Colors?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {additional_colors_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {additionalcolorsData ? Object.keys(additionalcolorsData).map((keyName, Innerindex) => (
                                    <TableRow key={additionalcolorsData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_additional_colors${additionalcolorsData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_additional_colors${additionalcolorsData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateAdditionalColors(Innerindex, event) },
                                            defaultValue: additionalcolorsData[keyName]?.additional_color ? additionalcolorsData[keyName].additional_color : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: AdditionalColorsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveAdditionalColor(additionalcolorsData[keyName]?.id)}><FontAwesomeIcon icon={AdditionalColorsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setAdditionalColorsHover({ ...AdditionalColorsHover, [Innerindex]: true }) }} onMouseLeave={() => { setAdditionalColorsHover({ ...AdditionalColorsHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddAdditionalColor}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                            </>
                            : ""}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                              (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                            }
                            onClick={() => {

                              Object.keys(onlinevideoData).map((keyName) => {
                                updateCustomersOnlineVideos({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(onlinevideoData[keyName], ["__typename"])
                                  }
                                })
                              })

                              Object.keys(additionalcolorsData).map((keyName) => {
                                updateCustomersAdditionalColors({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(additionalcolorsData[keyName], ["__typename"])
                                  }
                                })
                              })

                              updateBrandColorsAndFonts({
                                variables: {
                                  customer_id: CRMNewCustomerID,
                                  company_primary_font: BrandColorsAndFontssubmissionData?.company_primary_font ? BrandColorsAndFontssubmissionData?.company_primary_font : "",
                                  company_secondary_font: BrandColorsAndFontssubmissionData?.company_secondary_font ? BrandColorsAndFontssubmissionData?.company_secondary_font : "",
                                  primary_company_color: BrandColorsAndFontssubmissionData?.primary_company_color ? BrandColorsAndFontssubmissionData?.primary_company_color : "",
                                  secondary_company_color: BrandColorsAndFontssubmissionData?.secondary_company_color ? BrandColorsAndFontssubmissionData?.secondary_company_color : "",
                                  onboarding_id: crm_onboarding_id,
                                }
                              })

                              updateGalleryDatabase();

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >Save</Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {(WebsiteDesign || WebsiteDesignWooCommerce || Hydrate || OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement || HostingMonthlyManagedServices
                ) && (me?.readMe?.user_level === "web_designer" || me?.readMe?.user_level === "master") ?
                  <>
                    <div id="panel12" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel12" id="panel12_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Website | Domain | Email Access</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {WebsiteDesign || WebsiteDesignWooCommerce || Hydrate || HostingMonthlyManagedServices ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Domain Provider</b></label>
                                <input
                                  id="tumblr"
                                  type="text"
                                  value={CurrentWebsiteAccessssubmissionData?.domain_provider}
                                  onChange={updateCurrentWebsiteAccessFieldHandler("domain_provider")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Domain Username</b></label>
                                <input
                                  id="tumblr"
                                  type="text"
                                  value={CurrentWebsiteAccessssubmissionData?.domain_username}
                                  onChange={updateCurrentWebsiteAccessFieldHandler("domain_username")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                            </>
                            : ""}
                          {WebsiteDesign || WebsiteDesignWooCommerce || HostingMonthlyManagedServices ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Domain Password</b></label>
                                <input
                                  id="tumblr"
                                  type="text"
                                  value={CurrentWebsiteAccessssubmissionData?.domain_password}
                                  onChange={updateCurrentWebsiteAccessFieldHandler("domain_password")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Website Username</b></label>
                                <input
                                  id="website_username"
                                  type="text"
                                  value={CurrentWebsiteAccessssubmissionData?.website_username}
                                  onChange={updateCurrentWebsiteAccessFieldHandler("website_username")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Website Password</b></label>
                                <input
                                  id="website_password"
                                  type="text"
                                  value={CurrentWebsiteAccessssubmissionData?.website_password}
                                  onChange={updateCurrentWebsiteAccessFieldHandler("website_password")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {WebsiteDesign || WebsiteDesignWooCommerce ?
                                <>
                                  <div style={{ display: "block" }}>
                                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Social Email Account</b></label>
                                    <input
                                      id="social_email_account"
                                      type="text"
                                      value={CurrentWebsiteAccessssubmissionData?.social_email_account}
                                      onChange={updateCurrentWebsiteAccessFieldHandler("social_email_account")}
                                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                    />
                                  </div>
                                  <div style={{ clear: "both" }}></div>
                                  <div style={{ display: "block" }}>
                                    <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Social Email Password</b></label>
                                    <input
                                      id="social_email_password"
                                      type="text"
                                      value={CurrentWebsiteAccessssubmissionData?.social_email_password}
                                      onChange={updateCurrentWebsiteAccessFieldHandler("social_email_password")}
                                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                    />
                                  </div>
                                  <div style={{ clear: "both", height: "30px" }}></div>
                                </> : ""}
                            </>
                            : OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                              OrganicSearchAdditionalBusinessListingManagement ?
                              <>
                                <div style={{ display: "block" }}>
                                  <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Website Username</b></label>
                                  <input
                                    id="website_username"
                                    type="text"
                                    value={CurrentWebsiteAccessssubmissionData?.website_username}
                                    onChange={updateBusinessInfoFieldHandler("website_username")}
                                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                  />
                                </div>
                                <div style={{ clear: "both" }}></div>
                                <div style={{ display: "block" }}>
                                  <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Website Password</b></label>
                                  <input
                                    id="website_password"
                                    type="text"
                                    value={CurrentWebsiteAccessssubmissionData?.website_password}
                                    onChange={updateBusinessInfoFieldHandler("website_password")}
                                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                  />
                                </div>
                                <div style={{ clear: "both" }}></div>
                                <div style={{ display: "block" }}>
                                  <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Social Email Account</b></label>
                                  <input
                                    id="social_email_account"
                                    type="text"
                                    value={CurrentWebsiteAccessssubmissionData?.social_email_account}
                                    onChange={updateBusinessInfoFieldHandler("social_email_account")}
                                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                  />
                                </div>
                                <div style={{ clear: "both" }}></div>
                                <div style={{ display: "block" }}>
                                  <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Social Email Password</b></label>
                                  <input
                                    id="social_email_password"
                                    type="text"
                                    value={CurrentWebsiteAccessssubmissionData?.social_email_password}
                                    onChange={updateBusinessInfoFieldHandler("social_email_password")}
                                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                  />
                                </div>
                                <div style={{ clear: "both", height: "30px" }}></div>
                              </>
                              : ""}
                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                              (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                            }
                            onClick={() => {
                              updateWebsiteAccess();

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >Save</Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {OCAProduction || OCAProductionTwo || OCAProductionThree || WebsiteDesign || WebsiteDesignWooCommerce ||
                  OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                  OrganicSearchAdditionalBusinessListingManagement ?
                  <>
                    <div id="panel13" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel13" id="panel13_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}><h5><b>Website Design and Content</b></h5></Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {WebsiteDesign || WebsiteDesignWooCommerce ?
                            <>
                              <div style={{ display: "block" }}>
                                <label style={{ marginTop: "15px", float: "left", color: "#363636", fontSize: "16px" }}><b>Form Submission Email</b></label>
                                <input
                                  id="form_submission_email"
                                  maxLength={255}
                                  type="text"
                                  value={WebsiteDesignsubmissiondata?.form_submission_email || ""}
                                  onChange={updateWebsiteDesignFieldHandler("form_submission_email")}
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "75%", marginTop: "10px", float: "right" }}
                                />
                              </div>
                              <div style={{ clear: "both", height: "50px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px", marginRight: "30px" }}><b>Website Style</b></label>
                              <Select
                                id="website_style"
                                required={true}
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                inputProps={{
                                  type: "string",
                                  onChange: updateWebsiteDesignFieldHandler("website_style"),
                                  defaultValue: WebsiteDesignsubmissiondata?.website_style || ""
                                }}
                              >
                                <MenuItem value="Corporate">Corporate</MenuItem>
                                <MenuItem value="Minimalistic">Minimalistic</MenuItem>
                                <MenuItem value="Whimsical">Whimsical</MenuItem>
                                <MenuItem value="Informational">Informational</MenuItem>
                                <MenuItem value="Abstract">Abstract</MenuItem>
                                <MenuItem value="Surprise Me!">Surprise Me!</MenuItem>
                              </Select>
                              <div style={{ clear: "both", height: "50px" }}></div>

                              <label style={{ color: "#363636", fontSize: "16px", marginRight: "30px" }}><b>Writing Style</b></label>
                              <Select
                                id="writing_style"
                                required={true}
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                inputProps={{
                                  type: "string",
                                  onChange: updateWebsiteDesignFieldHandler("writing_style"),
                                  defaultValue: WebsiteDesignsubmissiondata?.writing_style || ""
                                }}
                              >
                                <MenuItem value="Normal">Normal</MenuItem>
                                <MenuItem value="Casual">Casual</MenuItem>
                                <MenuItem value="Serious">Serious</MenuItem>
                                <MenuItem value="Friendly">Friendly</MenuItem>
                                <MenuItem value="Optimistic">Optimistic</MenuItem>
                                <MenuItem value="Pessimistic">Pessimistic</MenuItem>
                                <MenuItem value="Scientific">Scientific</MenuItem>
                                <MenuItem value="Concise">Concise</MenuItem>
                                <MenuItem value="Descriptive">Descriptive</MenuItem>
                                <MenuItem value="Persuasive">Persuasive</MenuItem>
                                <MenuItem value="Informative">Informative</MenuItem>
                              </Select>
                              <div style={{ clear: "both", height: "50px" }}></div>
                            </> : ""}
                          {WebsiteDesign || WebsiteDesignWooCommerce ?
                            <>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Website Inspiration?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {inpirational_websites_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {websiteinspirationData ? Object.keys(websiteinspirationData).map((keyName, Innerindex) => (
                                    <TableRow key={websiteinspirationData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_website_inspiration${websiteinspirationData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_website_inspiration${websiteinspirationData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateWebsiteInspiration(Innerindex, "website", event) },
                                            defaultValue: websiteinspirationData[keyName]?.website ? websiteinspirationData[keyName].website : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_website_inspiration_note${websiteinspirationData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_website_inspiration_note${websiteinspirationData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateWebsiteInspiration(Innerindex, "notes", event) },
                                            defaultValue: websiteinspirationData[keyName]?.note ? websiteinspirationData[keyName].note : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: WebsiteInpirationHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveWebsiteInspiration(websiteinspirationData[keyName]?.id)}><FontAwesomeIcon icon={WebsiteInpirationHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setWebsiteInpirationHover({ ...WebsiteInpirationHover, [Innerindex]: true }) }} onMouseLeave={() => { setWebsiteInpirationHover({ ...WebsiteInpirationHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddWebsiteInspiration}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "50px" }}></div>
                            </>
                            : ""}
                          {OCAProduction || OCAProductionTwo || OCAProductionThree ?
                            <>
                              <label style={{ color: "#363636", fontSize: "16px", marginRight: "30px" }}><b>Video Style</b></label>
                              <Select
                                id="video_style"
                                required={true}
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                inputProps={{
                                  type: "string",
                                  onChange: updateWebsiteDesignFieldHandler("video_style"),
                                  defaultValue: WebsiteDesignsubmissiondata?.video_style || ""
                                }}
                              >
                                <MenuItem value="Corporate">Corporate</MenuItem>
                                <MenuItem value="Minimalistic">Minimalistic</MenuItem>
                                <MenuItem value="Whimsical">Whimsical</MenuItem>
                                <MenuItem value="Informational">Informational</MenuItem>
                                <MenuItem value="Abstract">Abstract</MenuItem>
                                <MenuItem value="Surprise Me!">Surprise Me!</MenuItem>
                              </Select>
                              <div style={{ clear: "both", height: "50px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Video Inspiration?</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {inpirational_video_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {videoinspirationData ? Object.keys(videoinspirationData).map((keyName, Innerindex) => (
                                    <TableRow key={videoinspirationData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_video_inspiration${videoinspirationData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_video_inspiration${videoinspirationData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateVideoInspiration(Innerindex, "website", event) },
                                            defaultValue: videoinspirationData[keyName]?.website ? videoinspirationData[keyName].website : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_video_inspiration_note${videoinspirationData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_video_inspiration_note${videoinspirationData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateVideoInspiration(Innerindex, "notes", event) },
                                            defaultValue: videoinspirationData[keyName]?.notes ? videoinspirationData[keyName].notes : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: VideoInpirationHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveVideoInspiration(videoinspirationData[keyName]?.id)}><FontAwesomeIcon icon={VideoInpirationHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setVideoInpirationHover({ ...VideoInpirationHover, [Innerindex]: true }) }} onMouseLeave={() => { setVideoInpirationHover({ ...VideoInpirationHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddVideoInspiration}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "50px" }}></div>
                            </>
                            : ""}
                          {OrganicSearchTwo || OrganicSearchFour || OrganicSearchRegional ||
                            OrganicSearchAdditionalBusinessListingManagement || WebsiteDesign || WebsiteDesignWooCommerce ?
                            <>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Blog Topic Ideas</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {blog_topic_ideas_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {blogtopicideasData ? Object.keys(blogtopicideasData).map((keyName, Innerindex) => (
                                    <TableRow key={blogtopicideasData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_blog_topic_ideas${blogtopicideasData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_blog_topic_ideas${blogtopicideasData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateBlogTopicIdeas(Innerindex, event) },
                                            defaultValue: blogtopicideasData[keyName]?.blog_topic_ideas ? blogtopicideasData[keyName].blog_topic_ideas : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: BlogTopicIdeasHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveBlogTopicIdea(blogtopicideasData[keyName]?.id)}><FontAwesomeIcon icon={BlogTopicIdeasHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setBlogTopicIdeasHover({ ...BlogTopicIdeasHover, [Innerindex]: true }) }} onMouseLeave={() => { setBlogTopicIdeasHover({ ...BlogTopicIdeasHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddBlogTopicIdea}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "50px" }}></div>
                              <label style={{ color: "#363636", fontSize: "16px" }}><b>Local Pages</b></label>
                              <br />
                              <br />
                              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                                <Table stickyHeader>
                                  <TableRow>
                                    {local_pages_columns.map((datacolumns) => (
                                      <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                    ))}
                                  </TableRow>
                                  {localPagesData ? Object.keys(localPagesData).map((keyName, Innerindex) => (
                                    <TableRow key={localPagesData[keyName]?.id}>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_local_pages_products_services${localPagesData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_local_pages_products_services${localPagesData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateLocalPages(Innerindex, "products_services", event) },
                                            defaultValue: localPagesData[keyName]?.products_or_services ? localPagesData[keyName].products_or_services : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 5, textAlign: "center" }}>
                                        <CustomInput
                                          id={`customers_local_pages_area${localPagesData[keyName].id}${Innerindex}`}
                                          maxLength="455"
                                          type="text"
                                          key={`customers_local_pages_area${localPagesData[keyName].id}${Innerindex}`}
                                          formControlProps={{
                                            fullWidth: true,
                                          }}
                                          inputProps={{
                                            onChange: event => { updateLocalPages(Innerindex, "area", event) },
                                            defaultValue: localPagesData[keyName]?.products_or_services_area ? localPagesData[keyName].products_or_services_area : ''
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell style={{ padding: 0, textAlign: "center" }}>
                                        <a style={{ cursor: "pointer", color: LocalPagesHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveLocalPage(localPagesData[keyName]?.id)}><FontAwesomeIcon icon={LocalPagesHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setLocalPagesHover({ ...LocalPagesHover, [Innerindex]: true }) }} onMouseLeave={() => { setLocalPagesHover({ ...LocalPagesHover, [Innerindex]: false }) }} /></a>
                                      </TableCell>
                                    </TableRow>
                                  )) : ""}
                                </Table>
                              </TableContainer>
                              <br />
                              <a style={{ cursor: "pointer" }} onClick={AddLocalPage}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                              <div style={{ clear: "both", height: "50px" }}></div>
                            </> : ""}
                          {WebsiteDesign || WebsiteDesignWooCommerce ?
                            <>
                              <div style={{ display: "inline-block" }}>
                                <label style={{ verticalAlign: "middle", color: "#363636", fontSize: "16px" }}>
                                  <span><b>Would you like Easter Eggs on your website?</b></span>
                                  <Checkbox
                                    checked={WebsiteDesignsubmissiondata?.website_easter_eggs ? true : false}
                                    style={{ marginRight: "20px", verticalAlign: "middle" }}
                                    onChange={updateWebsiteDesignFieldHandler("website_easter_eggs")}>
                                  </Checkbox></label>
                              </div>
                              <div style={{ clear: "both", height: "30px" }}></div>
                            </> : ""}

                          <div style={{ clear: "both", height: "30px" }}></div>
                          <Button
                            type="submit"
                            disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                              (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                            }
                            onClick={() => {

                              Object.keys(websiteinspirationData).map((keyName) => {
                                updateCustomersWebsiteInspiration({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(websiteinspirationData[keyName], ["__typename"])
                                  }
                                })
                              })

                              Object.keys(videoinspirationData).map((keyName) => {
                                updateCustomersVideoInspiration({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(videoinspirationData[keyName], ["__typename"])
                                  }
                                })
                              })

                              Object.keys(blogtopicideasData).map((keyName) => {
                                updateCustomersBlogTopicIdeas({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(blogtopicideasData[keyName], ["__typename"])
                                  }
                                })
                              })

                              Object.keys(localPagesData).map((keyName) => {
                                updateCustomersLocalPages({
                                  variables: {
                                    customer_id: CRMNewCustomerID,
                                    crm_onboarding_id: crm_onboarding_id,
                                    data: omit(localPagesData[keyName], ["__typename"])
                                  }
                                })
                              })

                              updateWebsiteDesign();

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }}
                          >Save</Button>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
                {OfflineTracking ?
                  <>
                    <div id="panel14" style={{ display: "block", position: "relative", top: "-75px", visibility: "hidden" }} />
                    <a href="#panel14" id="panel14_link" style={{ visibility: "hidden" }}>jump link</a>
                    <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')} disableGutters>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography sx={{ width: '33%', display: 'flex', verticalAlign: "center", alignItems: 'center' }}>
                          <h5><b>Offline Tracking</b></h5>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div style={{ clear: "both", height: "50px" }}></div>
                          <label style={{ color: "#363636", fontSize: "16px" }}><b>Tracking Numbers</b></label>
                          <br />
                          <br />
                          <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                            <Table stickyHeader>
                              <TableRow>
                                {tracking_numbers_columns.map((datacolumns) => (
                                  <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                                ))}
                              </TableRow>
                              {TrackingInfoData ? Object.keys(TrackingInfoData).map((keyName, Innerindex) => (
                                <TableRow key={TrackingInfoData[keyName]?.id}>
                                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                                    <CustomInput
                                      id={`customers_tracking_description${TrackingInfoData[keyName].id}${Innerindex}`}
                                      maxLength="455"
                                      type="text"
                                      key={`customers_tracking_description${TrackingInfoData[keyName].id}${Innerindex}`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        onChange: event => { updateTrackingInfo(Innerindex, "tracking_description", event) },
                                        defaultValue: TrackingInfoData[keyName]?.tracking_description ? TrackingInfoData[keyName].tracking_description : ''
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                                    <CustomInput
                                      id={`customers_rings_to${TrackingInfoData[keyName].id}${Innerindex}`}
                                      maxLength="455"
                                      type="text"
                                      key={`customers_rings_to${TrackingInfoData[keyName].id}${Innerindex}`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        onChange: event => { updateTrackingInfo(Innerindex, "rings_to", event) },
                                        defaultValue: TrackingInfoData[keyName]?.rings_to ? TrackingInfoData[keyName].rings_to : ''
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ padding: 0, textAlign: "center" }}>
                                    <a style={{ cursor: "pointer", color: TrackingInfoHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveTrackingInfo(TrackingInfoData[keyName]?.id)}><FontAwesomeIcon icon={TrackingInfoHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTrackingInfoHover({ ...TrackingInfoHover, [Innerindex]: true }) }} onMouseLeave={() => { setTrackingInfoHover({ ...TrackingInfoHover, [Innerindex]: false }) }} /></a>
                                  </TableCell>
                                </TableRow>
                              )) : ""}
                            </Table>
                          </TableContainer>
                          <br />
                          <a style={{ cursor: "pointer" }} onClick={AddTrackingInfo}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                          <div style={{ clear: "both", height: "50px" }}></div>
                        </Typography>
                        <div style={{ clear: "both", height: "30px" }}></div>
                        <Button
                          type="submit"
                          disabled={((user_id !== users_table_id && OnboardingDetailsdata?.onboarding_email_sent === 1) ||
                            (user_id === users_table_id && OnboardingDetailsdata?.customer_saved_onboarding))
                          }
                          onClick={() => {

                            Object.keys(TrackingInfoData).map((keyName) => {
                              updateCustomersTrackingInfo({
                                variables: {
                                  customer_id: CRMNewCustomerID,
                                  crm_onboarding_id: crm_onboarding_id,
                                  data: omit(TrackingInfoData[keyName], ["__typename"])
                                }
                              })
                            })

                            const notify = options => {
                              const key = enqueueSnackbar(options?.text, {
                                variant: options?.variant,
                                persist: true
                              });
                              setTimeout(() => {
                                closeSnackbar(key);
                              }, options?.delay || 2000);
                            }
                            notify({
                              text: "Information has been successfully submitted",
                              variant: "success"
                            });
                          }}
                        >Save</Button>
                      </AccordionDetails>
                    </Accordion>
                  </>
                  : ""}
              </> : ""}
            <div style={{ clear: "both", height: "50px" }}></div>
            {user_id !== users_table_id ?
              <>
                <Button
                  type="submit"
                  disabled={OnboardingDetailsdata?.onboarding_email_sent}
                  onClick={async () => {
                    if (OnboardingDetailsdata?.onboarding_email_sent === 0) {
                      await SendCustomerOnboardingEmail();

                      // set onboarding_email_sent to 1
                      await submitUpdateOnboardingDetails({
                        skip: !crm_onboarding_id || !CRMNewCustomerID,
                        fetchPolicy: "no-cache",
                        variables: {
                          customer_id: CRMNewCustomerID,
                          crm_onboarding_id: crm_onboarding_id,
                          onboarding_email_sent: 1
                        }
                      });

                      setOnboardingDetailsdata({
                        ...OnboardingDetailsdata,
                        ["onboarding_email_sent"]: 1
                      })

                      await submitUpdateCRMActivity({
                        variables: {
                          customer_id: 38,
                          ActivityData: {
                            users_id: user_id,
                            activity_type: "Onboarding Sent to Customer",
                            activity_description: "Sent Onboarding to Customer: " + CRMCustomers?.business_name,
                            contract_id: crm_contract_id,
                            people_id: crm_people_id,
                            onboarding_id: crm_onboarding_id,
                            representative_id: CRMCustomers?.representative_id,
                            crm_customers_id: crm_customer_id,
                            activity_completed: 1,
                            activity_completed_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                            activity_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                          }
                        }
                      });
                    }
                  }}
                >Send Onboarding to Customer</Button>
                <br />
                <br />
                {OnboardingDetailsdata?.onboarding_email_sent ?
                  <div style={{ color: "red" }}>This Onboarding Form has already been sent to the Customer</div>
                  : ""}
              </>
              :
              <>
                <Button
                  type="submit"
                  disabled={OnboardingDetailsdata?.customer_saved_onboarding}
                  onClick={async () => {
                    if (OnboardingDetailsdata?.customer_saved_onboarding === 0) {

                      if (banks?.length === 0 && cards?.length === 0) {
                        alert("Please enter 1 Payment Method in the Billing Information Section");
                        return;
                      }

                      await submitUpdateOnboardingDetails({
                        skip: !crm_onboarding_id || !CRMNewCustomerID,
                        fetchPolicy: "no-cache",
                        variables: {
                          customer_id: CRMNewCustomerID,
                          crm_onboarding_id: crm_onboarding_id,
                          customer_saved_onboarding: 1,
                          onboarding_email_sent: 1
                        }
                      });


                      setOnboardingDetailsdata({
                        ...OnboardingDetailsdata,
                        ["customer_saved_onboarding"]: 1
                      })

                      await submitUpdateCRMActivity({
                        variables: {
                          customer_id: 38,
                          ActivityData: {
                            users_id: user_id,
                            activity_type: "Customer Submitted Onboarding",
                            activity_description: "Submitted Customer: " + CRMCustomers?.business_name + " Onboarding",
                            contract_id: crm_contract_id,
                            onboarding_id: crm_onboarding_id,
                            people_id: crm_people_id,
                            representative_id: CRMCustomers?.representative_id,
                            crm_customers_id: crm_customer_id,
                            activity_completed: 1,
                            activity_completed_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                            activity_date: moment().format("YYYY-MM-DD hh:mm:ss"),
                          }
                        }
                      });
                    }
                  }}>Save Onboarding Form</Button>
                <br />
                <br />
                {OnboardingDetailsdata?.customer_saved_onboarding ?
                  <div style={{ color: "red" }}>This Onboarding Form has already been saved.  It cannot be changed.</div>
                  : ""}
              </>}
          </CardBody>
        </Card>
      </div>
    </div >
  );
};

OnBoardingForm.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default OnBoardingForm;