import gql from "graphql-tag";

export const LIST_USERS = gql`
  {
    listUsers {
      id
      first_name
      last_name
      email
      cell_phone
      commission
      office_phone
      office_extension
      user_level
      customer_id
      birthday
      quick_books_id
      default_payment_id
      sub
      customer_id
      crm_user_level
    }
  }
`;
export const LIST_CUSTOMER_USERS = gql`
  query($customer_id: Int) {
  listCustomerUsers(customer_id: $customer_id) {
      id
      first_name
      last_name
      email
      cell_phone
      commission
      office_phone
      office_extension
      user_level
      customer_id
      birthday
      quick_books_id
      default_payment_id
      sub
      customer_id
      crm_user_level
    }
  }
`;

export const READ_USER = gql`
  {
    readUser {
      id
      first_name
      last_name
      email
      cell_phone
      commission
      office_phone
      office_extension
      user_level
      customer_id
      birthday
      quick_books_id
      default_payment_id
      sub
      crm_user_level
    }
  }
`;

export const READ_USER_BY_EMAIL = gql`
    query($email: String) {
      readUserbyEmail(email: $email) {
        id
        first_name
        last_name
        email
        cell_phone
        commission
        office_phone
        office_extension
        user_level
        customer_id
        birthday
        quick_books_id
        default_payment_id
        sub
        crm_user_level
        billing_contact
        auto_reporting_emails
      }
    }
`;

export const READ_ME = gql`
  {
    readMe {
      id
      first_name
      last_name
      birthday
      email
      cell_phone
      commission
      office_phone
      office_extension
      user_level
      quick_books_id
      default_payment_id
      sub
      crm_user_level
      customer_id
      billing_contact
      auto_reporting_emails
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation(
    $new_password: String
  ) {
    updatePassword(
      new_password: $new_password
    )
  }
`;

export const UPDATE_ME = gql`
  mutation(
    $id: Int
    $first_name: String
    $last_name: String
    $email: String
    $birthday: String
    $cell_phone: String
    $office_phone: String
    $office_extension: String
    $crm_user_level: String
  ) {
    updateMe(
      id: $id
      first_name: $first_name
      last_name: $last_name
      email: $email
      birthday: $birthday
      cell_phone: $cell_phone
      office_phone: $office_phone
      office_extension: $office_extension
      crm_user_level: $crm_user_level
    ) {
      id
      first_name
      last_name
      email
      cell_phone
      birthday
      office_phone
      office_extension
      quick_books_id
      default_payment_id
      sub
      crm_user_level
    }
  }
`;

export const GET_AD_CONSULTANT = gql`
  {
    getAdConsultant {
      id
      first_name
      last_name
      email
      office_phone
      office_extension
      cell_phone
    }
  }
`;

export const GET_AGENCY_CONSULTANT = gql`
  {
    getAgencyConsultant {
      id
      first_name
      last_name
      email
      office_phone
      office_extension
      cell_phone
    }
  }
`;

export const GET_PAID_AD_MANAGER = gql`
  {
    getCampaignConsultant {
      id
      first_name
      last_name
      email
      office_phone
      office_extension
      cell_phone
    }
  }
`;

export const GET_CONTENT_MANAGER = gql`
  {
    getCampaignConsultant {
      id
      first_name
      last_name
      email
      office_phone
      office_extension
      cell_phone
    }
  }
`;

export const GET_CAMPAIGN_CONSULTANT = gql`
  {
    getCampaignConsultant {
      id
      first_name
      last_name
      email
      office_phone
      office_extension
      cell_phone
    }
  }
`;

export const ADD_USER = gql`
  mutation(
    $customer_id: Int
    $first_name: String
    $last_name: String
    $email: String
    $cell_phone: String
    $commission: Int
    $office_phone: String
    $office_extension: String
    $user_level: String
    $crm_user_level: String
  ) {
    createUser(
      customer_id: $customer_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      cell_phone: $cell_phone
      commission: $commission
      office_phone: $office_phone
      office_extension: $office_extension
      user_level: $user_level
      crm_user_level: $crm_user_level
    )
  }
`;

export const UPDATE_USER = gql`
  mutation($id: Int, $data: UserInput!) {
    updateUser(id: $id, data: $data) {
      id
      first_name
      last_name
      email
      cell_phone
      commission
      office_phone
      office_extension
      user_level
      customer_id
      birthday
      quick_books_id
      default_payment_id
      sub
      crm_user_level
    }
  }
`;

export const DELETE_USER = gql`
  mutation($FK_IndexID: Int) {
    deleteUser(FK_IndexID: $FK_IndexID)
  }
`;

export const SET_DEFAULT_PAYMENT_ID = gql`
  mutation($default_payment_id: String, $customer_id: Int) {
    setDefaultPaymentId(default_payment_id: $default_payment_id, customer_id: $customer_id)
  }
`;

export const GET_DEFAULT_PAYMENT_ID = gql`
  query($customer_id: Int) {
    getDefaultPaymentId(customer_id: $customer_id)
  }
`;