import React from "react";
import PropTypes from "prop-types";
import { createTheme } from '@mui/material';
import { ThemeProvider, makeStyles } from '@mui/styles';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import TrackedLeadsSummary from "../TrackedLeadsSummary";
// import GoogleAccountSummary from "./GoogleAccountSummary";
// import BingAccountSummary from "./BingAccountSummary";
import PaidSearch from "./PaidSearch";
import AllProducts from "./AllProducts";
import AllBudgets from "./AllBudgets";

const theme = createTheme()

const useStyles = makeStyles(styles);

export default function AccountOverview({ type = "default" }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div>
        {type === "default" && (
          <>
            <h2 className={classes.pageHeader}>Tracked Conversions</h2>
            <TrackedLeadsSummary />
          </>
        )}
        {/*
      <h2 className={classes.pageHeader}>Google</h2>
      <GoogleAccountSummary />
      <h2 className={classes.pageHeader}>Bing</h2>
      <BingAccountSummary />
      */}
        {type === "paid-search" && <PaidSearch key="paid-search" />}
        {type === "default" && (
          <>
            <h2 className={classes.pageHeader}>All Products</h2>
            <AllProducts />
          </>
        )}
        {type === "default" && (
          <>
            <h2 className={classes.pageHeader}>Budgets</h2>
            <AllBudgets />
          </>
        )}
      </div>
    </ThemeProvider>
  );
}

AccountOverview.propTypes = {
  type: PropTypes.string
};
