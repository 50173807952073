import React, { useContext } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_BING_TEXT_ADS } from "queries/bing";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const TextAds = () => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_BING_TEXT_ADS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listBingTextAds || [{}];
  const classes = useStyles();
  const columns = [
    {
      Header: "Status",
      accessor: "ad_status",
      width: 100
    },
    {
      Header: "Type",
      accessor: "ad_type",
      width: 180
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name",
      width: 180
    },
    {
      Header: "Headline",
      accessor: "title_part1",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{original?.title_part1}</div>
          <div>{original?.title_part2}</div>
          <div>{original?.title_part3}</div>
        </>
      ),
      renderCSV: ({ original }) =>
        `${original?.title_part1}\n${original?.title_part2}\n${original?.title_part3}`
    },
    {
      Header: "Description",
      accessor: "ad_description",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{original?.ad_description}</div>
          <div>{original?.ad_description2}</div>
        </>
      ),
      renderCSV: ({ original }) =>
        `${original?.ad_description}\n${original?.ad_description2}`
    },
    {
      Header: "Path",
      accessor: "path1",
      maxWidth: 300,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{original?.path1}</div>
          <div>{original?.path2}</div>
        </>
      ),
      renderCSV: ({ original }) => `${original?.path1}\n${original?.path2}`
    },
    {
      Header: "Landing Page",
      accessor: "final_url",
      width: 100,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original?.final_url ? (
          "Unknown"
        ) : (
          <a
            href={original?.final_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        ),
      renderCSV: ({ original }) => original?.final_url || "Unknown"
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Text Ads on Bing
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Bing Text Ads (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TextAds;
