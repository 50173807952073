import React from "react";


import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import HydrateNoContentWrapper from "components/HydrateNoContentWrapper";

import CustomerEmailList from "./CustomerEmailList";

const useStyles = makeStyles(styles);

export default function CustomerList() {
  const classes = useStyles();
  return (
    <HydrateNoContentWrapper>
      <h2 className={classes.pageHeader}>Customer List</h2>
      <CustomerEmailList />
    </HydrateNoContentWrapper>
  );
}
