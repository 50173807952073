import React, { useContext } from "react";

import NoContentWrapper from "components/NoContentWrapper";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../redux/store";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

const PaidSearchNoContentWrapper = ({ title, children }) => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  return (
    <NoContentWrapper
      skip={!customer_id}
      queries={[
        {
          name: "budget",
          query: GET_BUDGET,
          options: { variables: { customer_id, product: 1 } }
        }
      ]}
      product="PaidSearch"
      title={title}
      isLoading={({ budget }) => budget?.loading}
      hasNoData={({ budget }) => {
        const spent = Number(budget?.data?.getBudget?.monthly_budget || 0)
        if(spent !== 0)
        {
          return true;
        }
        return false;
      }}
      noContent="Water Bear Marketing ensures that you can reach 99% of the places that people search online. We provide tracking of results through phone calls, emails, online sales, and form submissions. Our proprietary technology helps us to proactively make adjustments to our client's campaigns to drive more leads and sales. We use our proprietary processes and technology to track and improve your results. With our PaidSearch product, our goal is to generate revenue for our clients that meets or exceeds their exceptions."
    >
     <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NoContentWrapper>
  );
};

export default PaidSearchNoContentWrapper;
