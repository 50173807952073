import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle";

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}),
styles);

const SimpleSelect = ({
  error,
  loading,
  options,
  selected,
  style,
  onSelect
}) => {
  const classes = useStyles();

  const handleChange = event => {
    onSelect(event.target.value);
  };

  if (error) {
    return (
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value="error"
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ "aria-label": "Without label" }}
        style={style}
      >
        <MenuItem value="error">{error}</MenuItem>
      </Select>
    );
  }

  if (loading) {
    return (
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        value="loading"
        displayEmpty
        className={classes.selectEmpty}
        inputProps={{ "aria-label": "Without label" }}
        style={style}
      >
        <MenuItem value="loading">Loading ...</MenuItem>
      </Select>
    );
  }

  return (
    <Select
      MenuProps={{
        className: classes.selectMenu
      }}
      classes={{
        select: classes.select
      }}
      value={selected}
      onChange={handleChange}
      displayEmpty
      className={classes.selectEmpty}
      inputProps={{ "aria-label": "Without label" }}
      style={{
        ...style,
        fontStyle: options.find(({ key }) => key === selected)?.fontStyle
      }}
    >
      {options.map(({ key, label, fontStyle, disabled }) => (
        <MenuItem
          value={key}
          key={key}
          style={{ fontStyle }}
          disabled={disabled}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

SimpleSelect.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.any
    })
  ),
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  onSelect: PropTypes.func
};

SimpleSelect.defaultProps = {
  error: "",
  loading: false,
  options: [],
  selected: null,
  style: {},
  hoverColor: "primary !important",
  onSelect: noop
};

export default SimpleSelect;
