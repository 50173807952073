import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import { ADD_ACCOUNT } from "queries/customers";
import { READ_ME } from "queries/users";
import { ADD_CUSTOMER_SELECT } from "../../../../redux/constants";
import { Context } from "../../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

// import styles

import styles from "../styles";
import { allAccountTypes, accountFields } from "../data";
import CustomSelect from "../CustomSelect";
import SelectStatic from "../SelectStatic";

const useStyles = makeStyles(styles);

const AddAccount = ({ formSubmitted }) => {
  const [, dispatch] = useContext(Context);
  const { data: meData, loading: meLoading } = useQuery(READ_ME);
  const me = meData?.readMe;
  const accountTypeStart = allAccountTypes.findIndex(
    ({ key }) => key === me?.user_level
  );
  const accountTypes = allAccountTypes.slice(accountTypeStart + 1);
  const [accountTypeKey, setAccountTypeKey] = useState(undefined);
  const [accountInfo, setAccountInfo] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [validation, setValidation] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const accountTypeStart = allAccountTypes.findIndex(
      ({ key }) => key === me?.user_level
    );
    const accountTypes = allAccountTypes.slice(accountTypeStart + 1);
    if (accountTypes.length) {
      setAccountTypeKey(accountTypes[0].key);
    }
  }, [me]);

  const updateFieldHandler = (key, convert) => e => {
    if(key === "google_id")
      e.target.value = e.target.value.replace(/\D/g,'')
    else if(key === "phone")
      e.target.value = e.target.value.replace(/[^0-9\-]/g,'');
      
    setAccountInfo({
      ...accountInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const [addAccount, { loading: addingAccount }] = useMutation(ADD_ACCOUNT, {
    variables: {
      type: accountTypeKey,
      data: JSON.stringify({
        ...accountInfo,
        margin: 40
      })
    },
    onCompleted: ({ addAccount }) => {
      if (accountTypeKey === "agency_customer") {
        dispatch({
          type: ADD_CUSTOMER_SELECT,
          payload: JSON.parse(addAccount)
        });
      }
      formSubmitted();
      setRefresh(refresh + 1);
    }
  });

  const availableFields = accountFields[accountTypeKey]?.filter(
    ({ permissions }) =>
      !permissions || permissions.indexOf(me?.user_level) !== -1
  );

  const onImport = importedData => {
    const data = {};
    Object.keys(importedData).forEach(key => {
      const field = availableFields.find(
        ({ key: fieldKey }) => fieldKey === key
      );
      console.log(key, field);
      if (field.type === "select-static") {
        data[key] = field.data.find(
          ({ label }) => label.toLowerCase() === label.toLowerCase()
        )?.value;
      } else if (field.type === "select") {
        data[key] = `label_${importedData[key]}`;
      } else {
        data[key] = importedData[key];
      }
    });
    setAccountInfo(data);
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>
        New Account/Contact Form Submission
      </h2>
      <GridContainer justify="center" loading={addingAccount || meLoading}>
        <GridItem xs={12}>
          <GridContainer>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (
                      accountFields[accountTypeKey]?.find(
                        ({ key, required, dataSource }) =>
                          required &&
                          !accountInfo[key] &&
                          accountTypes.find(
                            ({ key }) => dataSource?.type === key
                          )
                      )
                    ) {
                      setValidation(true);
                      return;
                    }
                    addAccount();
                  }}
                  key={`${refresh}-${accountTypeKey}`}
                >
                  <label>Type</label>
                  <CustomSelect
                    name="account_type"
                    value={accountTypeKey}
                    onChange={e => {
                      setAccountTypeKey(e.target.value);
                      setAccountInfo({});
                    }}
                    style={{ marginTop: "10px"}}
                    data={
                      meLoading
                        ? []
                        : accountTypes.map(({ label, key }) => ({
                            label,
                            value: key
                          }))
                    }
                  />
                  {availableFields?.map(
                    ({
                      key,
                      label,
                      required,
                      type,
                      dataSource,
                      data,
                      defaultValue = ""
                    }) => {
                      if (type === "input" || !type) {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              fullWidth: true,
                              required
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler(key),
                              value: accountInfo[key] || ""
                            }}
                          />
                        );
                      } else if (type === "integer") {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              fullWidth: true,
                              required
                            }}
                            inputProps={{
                              type: "number",
                              onChange: updateFieldHandler(key, parseInt),
                              value: accountInfo[key] || 0
                            }}
                          />
                        );
                      } else if (type === "date") {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              required
                            }}
                            style={{ width: 220 }}
                            inputProps={{
                              type: "date",
                              onChange: updateFieldHandler(key),
                              value: accountInfo[key] || ""
                            }}
                          />
                        );
                      } else if (type === "select-static") {
                        return (
                          <><label>{ label }</label>
                          <SelectStatic
                            name={key}
                            key={key}
                            value={accountInfo && accountInfo[key]}
                            onChange={updateFieldHandler(key)}
                            data={data}
                            required={required}
                            validation={validation}
                          />
                          </>
                        );
                      } else if (type === "select") {
                        if (
                          dataSource?.type &&
                          !accountTypes.find(
                            ({ key }) => key === dataSource?.type
                          )
                        ) {
                          return null;
                        }
                        return (
                          <><label>{ label }</label>
                          <CustomSelect
                            name={key}
                            key={key}
                            value={accountInfo && accountInfo[key]}
                            onChange={updateFieldHandler(key)}
                            dataSource={{
                              ...dataSource,
                              parent_id:
                                accountInfo &&
                                accountInfo[(dataSource?.parent_id)]
                            }}
                            onLoaded={data => {
                              if (required) {
                                updateFieldHandler(key)({
                                  target: { value: data[0]?.id }
                                });
                              }
                            }}
                            required={required}
                            validation={validation}
                          />
                          </>
                        );
                      }
                      return null;
                    }
                  )}
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(AddAccount);
