import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import NoContentWrapper from "components/NoContentWrapper";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";

import ChatTotals from "./ChatTotals";
import ReviewsTracking from "./ReviewsTracking";

const useStyles = makeStyles(styles);

export default function Chat() {
  const [state] = useContext(Context);
  const classes = useStyles();
  const [ChatCustomer, setChatCustomer] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const content =
    "Chat is part of our OrganicSearch product that gives your company an additional way for your website visitors to interact with your business. From gathering reviews to messaging customers about appointments, chat can save customers and your business time, all while increasing interactions with your company. With chat, you can communicate with people on your website, Google My Business, Twitter, or Facebook pages and collect contact information so you can follow up with them later and continue the conversation. Reach your customers wherever they are with text messaging.";

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "organic_search"
    }
  });

  useEffect(() => {
    setChatCustomer(budget_data?.getBudget?.monthly_budget);
  }, [budget_data]);

  return (
    <>
      {ChatCustomer !== null ?
        <>
          <h2 className={classes.pageHeader}>Chat Totals</h2>
          <ChatTotals />
          <h2 className={classes.pageHeader}>Reviews Totals</h2>
          <ReviewsTracking />
        </>
        :
        <NoContentWrapper
          skip={!customer_id}
          queries={[
            {
              name: "budgetData",
              query: GET_BUDGET,
              options: {
                variables: { customer_id, product_line: "organic_search" },
              },
            },
          ]}
          product="Chat"
          isLoading={({ budgetData }) => budgetData?.loading}
          hasNoData={({ budgetData }) => {
            const spent = Number(
              budgetData?.data?.getBudget.new_monthly_budget ||
              budgetData?.data?.getBudget.monthly_budget ||
              0
            );
            if (spent === 0) {
              return false;
            }
            return true;
          }}
          noContent={content}
        >
          <h2 className={classes.pageHeader}>Chat Totals</h2>
          <ChatTotals />
          <h2 className={classes.pageHeader}>Reviews Totals</h2>
          <ReviewsTracking />
        </NoContentWrapper>
    }
    </>
  );
}

Chat.propTypes = {
  type: PropTypes.string,
};
