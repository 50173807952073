import React from "react";
import moment from "moment";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import CustomInput from "components/CustomInput/CustomInput";

const CreditCardForm = ({ info, onUpdate, error, readOnly }) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomInput
          labelText="Card Number"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.number,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, number: value })
          }}
          error={error === "number"}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomInput
          labelText="First & Last Name"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.name,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, name: value })
          }}
          error={error === "name"}
        />
      </GridItem>
      <GridItem xs={4}>
        <CustomInput
          labelText="Exp Year"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.expYear,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, expYear: value }),
            placeholder: moment().format("YYYY")
          }}
          error={error === "expyear"}
        />
      </GridItem>
      <GridItem xs={4}>
        <CustomInput
          labelText="Exp Month"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.expMonth,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, expMonth: value }),
            placeholder: moment().format("MM")
          }}
          error={error === "expmonth"}
        />
      </GridItem>
      {!readOnly && (
        <GridItem xs={4}>
          <CustomInput
            labelText="CVC"
            disabled={readOnly}
            readOnly={readOnly}
            formControlProps={{
              fullWidth: true,
              disabled: readOnly,
              readOnly: readOnly
            }}
            inputProps={{
              value: info?.cvc,
              type: "string",
              onChange: ({ target: { value } }) =>
                onUpdate({ ...info, cvc: value })
            }}
            error={error === "cvc"}
          />
        </GridItem>
      )}
    </GridContainer>
  );
};

export default CreditCardForm;
