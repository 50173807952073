import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { omit, noop } from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";

import HelpTooltip from "components/HelpTooltip";
import { GET_HYDRATE_CAMPAIGN, UPDATE_HYDRATE_CAMPAIGN, GET_MESSAGE_NAMES } from "queries/hydrate";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";

const frequencies = [
  {
    label: "Standard",
    value: 3,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 5,
      email_day: 3,
      letter_day: 11,
      postcard_day: 9,
      voice_message_day: 7
    }
  },
  {
    label: "Alternative",
    value: 2,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 3,
      email_day: 2,
      letter_day: 6,
      postcard_day: 5,
      voice_message_day: 4
    }
  },
  {
    label: "ASAP",
    value: 1,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 1,
      email_day: 1,
      letter_day: 1,
      postcard_day: 1,
      voice_message_day: 1
    }
  },
  {
    label: "Weekly",
    value: 4,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 1,
      email_day: 1,
      letter_day: 1,
      postcard_day: 1,
      voice_message_day: 1
    }
  },
  {
    label: "Monthly",
    value: 5,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 1,
      email_day: 1,
      letter_day: 1,
      postcard_day: 1,
      voice_message_day: 1
    }
  },
  {
    label: "Quaterly",
    value: 6,
    campaign: {
      sms_text_day: 1,
      mms_text_day: 1,
      email_day: 1,
      letter_day: 1,
      postcard_day: 1,
      voice_message_day: 1
    }
  },
  { label: "Custom", value: 7 }
];

let assets = [
  { key: "sms_text", label: "First Message - Send on Day" },
  { key: "mms_text", label: "Sencond Message - Send on Day" },
  { key: "email", label: "Third Message - Send on Day" },
  { key: "voice_message", label: "Fourth Message - Send on Day" },
  { key: "letter", label:"Fifth Message - Send on Day" },
  { key: "postcard", label: "Sixth Message - Send on Day" }
];

const CampaignConfigure = ({ customerId, leadStatus, campaign, setCampaign = noop }) => {
  const [frequency, setFrequency] = useState();
  const { loading: loadingmessagenames, data: messagename_data } = useQuery(GET_MESSAGE_NAMES, {
    skip: !customerId,
    variables: { customer_id: customerId,
                 lead_status: leadStatus }
  });

  const Message_Name_data_temp = messagename_data?.getHydrateMessageNames || [];

  if (Message_Name_data_temp.length) {
    assets = new Array();
    Message_Name_data_temp.map(message => {
      if (message?.message_id === "Message1" && message?.message_status)
        assets.push({ key: "sms_text", label: Message_Name_data_temp[0].message_name })
      if (message?.message_id === "Message2" && message?.message_status)
        assets.push({ key: "mms_text", label: Message_Name_data_temp[1].message_name })
      if (message?.message_id === "Message3" && message?.message_status)
        assets.push({ key: "email", label: Message_Name_data_temp[2].message_name })
      if (message?.message_id === "Message4" && message?.message_status)
        assets.push({ key: "voice_message", label: Message_Name_data_temp[3].message_name })
      if (message?.message_id === "Message5" && message?.message_status)
        assets.push({ key: "letter", label: Message_Name_data_temp[4].message_name })
      if (message?.message_id === "Message6" && message?.message_status)
        assets.push({ key: "postcard", label: Message_Name_data_temp[5].message_name })
    })
  }

  const { loading } = useQuery(GET_HYDRATE_CAMPAIGN, {
    variables: {
      customer_id: customerId,
      list_name: leadStatus
    },
    fetchPolicy: "no-cache",
    skip: !customerId || !leadStatus,
    onCompleted: data => {
      if (!data?.getHydrateCampaign) {
        setFrequency(null);
        setCampaign({});
        return;
      }
      const { frequency_template } = data?.getHydrateCampaign;
      if (frequency_template) {
        setFrequency(frequency_template);
        setCampaign({
          ...frequencies.find(({ value }) => value === frequency_template)
            ?.campaign,
          ...data?.getHydrateCampaign
        });
      }
    }
  });

  const [updateCampaign, { loading: updating }] = useMutation(
    UPDATE_HYDRATE_CAMPAIGN
  );

  const updateCampaignHandler = (newCampaign, fr) => {
    const normalizedCampaign = omit(newCampaign, ["__typename", "id"]);
    setCampaign(newCampaign);
    updateCampaign({
      variables: {
        data: {
          customer_id: customerId,
          list_name: leadStatus,
          ...normalizedCampaign,
          frequency_template: fr || frequency
        }
      }
    });
  };
  // console.log(data, loading);
  return (
    <div
      style={loading || updating ? { opacity: 0.5, pointerEvents: "none" } : {}}
    >
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: 20 }}>
          Choose Hydrate Frequency
          <HelpTooltip style={{ whiteSpace: "nowrap", maxWidth: "100%" }}>
            You can change your frequency for each lead status.<br /><br />
            Standard Sends on Days 1, 3, 5, 7, 9, 11<br />
            Alternate Sends on Days 1, 2, 3, 4, 5, 6<br />
            Weekly Sends on Days 1, 8, 15, 22, 29, 36<br />
            Monthly Sends on Days 1, 31, 62, 93, 124, 155
          </HelpTooltip>
        </h4>
        <CustomSelect
          value={frequency ? frequency : 3}
          onChange={e => {
            setFrequency(e.target.value);
            const newCampaign = {
              sms_text: 1,
              mms_text: 1,
              email: 1,
              letter: 1,
              postcard: 1,
              voice_message: 1,
              ...campaign,
              ...frequencies.find(({ value }) => value === e.target.value)
                .campaign
            };
            updateCampaignHandler(newCampaign, e.target.value);
          }}
          data={frequencies}
        />
      </div>
      <div>
        {assets.map(({ key, label }) => (
          <div style={{ display: "flex", alignItems: "center" }} key={key}>
            { frequency === 7 ? 
            <FormControlLabel
              control={ <div />}
              label={label}
              style={{ width: 550 }}
            />: "" }
            {frequency === 7 && (
              <select
                key={campaign[key]}
                disabled={frequency !== 7}
                onChange={e => {
                  const newCampaign = {
                    ...campaign,
                    [`${key}_day`]: Number(e.target.value)
                  };
                  updateCampaignHandler(newCampaign);
                }}
              >
                <option disabled selected={!campaign[`${key}_day`]}>Choose Date</option>
                {new Array(30)
                  .fill(0)
                  .map((_, index) => index + 1)
                  .map(day => (
                    <option
                      value={day}
                      key={day}
                      selected={day === campaign[`${key}_day`]}
                    >
                      {day}
                    </option>
                  ))}
              </select>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CampaignConfigure;