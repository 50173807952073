import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import BudgetCard from "components/BudgetCard";
import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import PaidSearchNoContentWrapper from "components/PaidSearchNoContentWrapper";
import { GET_GOOGLE_CAMPAIGN_INFO_SUMMARY } from "queries/google";
import { GET_BING_CAMPAIGNS_SUMMARY } from "queries/bing";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import { getPrevious } from "utils/format";
import { READ_ME } from "queries/users";

const useStyles = makeStyles(styles);

const PaidSearch = () => {
  const classes = useStyles();
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const compareOption = state.ui?.compareOption;
  const skip = !customer_id || !dateRangeString;
  const { data: me } = useQuery(READ_ME);
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const { loading: googleLoading, data: googleData } = useQuery(
    GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
    {
      skip,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );

  const { loading: loadingGoogleCompare, data: dataGoogleCompare } = useQuery(
    GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
    {
      skip: !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  const { loading: loadingBingCompare, data: dataBingCompare } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip: !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  const { loading: bingLoading, data: bingData } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const loading = googleLoading || bingLoading;

  return (
    <>
    {me?.readMe?.user_level !== "master" && me?.readMe?.user_level !== "campaign_consultant" && me?.readMe?.user_level !== "ad_consultant" && me?.readMe?.user_level !== "paid_ad_manager" && me?.readMe?.user_level !== "content_manager" ?
    <PaidSearchNoContentWrapper>
      <div>
        <h2 className={classes.pageHeader}>PaidSearch</h2>
        <GridContainer>
          <StatsCard
            icon="attach_money"
            loading={loading}
            label="Total Spent"
            value={addMargin(
              (googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (bingData?.getBingCampaignsSummary?.cost || 0),
              state
            )}
            render="currency"
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? addMargin((dataGoogleCompare?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (dataBingCompare?.getBingCampaignsSummary?.cost || 0), 
                state)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="dvr"
            loading={loading}
            label="Impressions"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.impressions || 0) +
              (bingData?.getBingCampaignsSummary?.impressions || 0)
            }
            render="integer"
            help="Impressions are the number of times that your advertisement has been displayed."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.impressions || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.impressions || 0)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="donut_small"
            loading={loading}
            label="Clicks"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
              (bingData?.getBingCampaignsSummary?.clicks || 0)
            }
            render="integer"
            help="Clicks are the count of the number of times users have clicked on your advertisement."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.clicks || 0)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="scatter_plot"
            loading={loading}
            label="CTR"
            value={
              (((googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
                (bingData?.getBingCampaignsSummary?.clicks || 0)) /
                Math.max(
                  (googleData?.getGoogleCampaignInfoSummary?.impressions || 0) +
                    (bingData?.getBingCampaignsSummary?.impressions || 0),
                  1
                )) *
              100
            }
            render="percentage"
            milestones={[
              {
                value: 1,
                color: "info",
                icon: "fitness_center",
                message: `Not met "Working on it.."`
              },
              {
                value: 3,
                color: "orange",
                icon: "sentiment_satisfied",
                message: "Average"
              },
              {
                color: "wbmMoney",
                icon: "sentiment_very_satisfied",
                message: "Above Average"
              }
            ]}
            help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ?
                  (((dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                    (dataBingCompare?.getBingCampaignsSummary?.clicks || 0)) /
                    Math.max(
                      (dataGoogleCompare?.getGoogleCampaignInfoSummary?.impressions || 0) +
                        (dataBingCompare?.getBingCampaignsSummary?.impressions || 0),
                      1
                    )) *
                  100
                
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="image_aspect_ratio"
            loading={loading}
            label="CPC"
            value={addMargin(
              ((googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (bingData?.getBingCampaignsSummary?.cost || 0)) /
                Math.max(
                  (googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
                    (bingData?.getBingCampaignsSummary?.clicks || 0),
                  1
                ),
              state
            )}
            render="currency"
            help="CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? addMargin(
                  ((dataGoogleCompare?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                    (dataBingCompare?.getBingCampaignsSummary?.cost || 0)) /
                    Math.max(
                      (dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                        (dataBingCompare?.getBingCampaignsSummary?.clicks || 0),
                      1
                    ),
                  state
                )
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="compass_calibration"
            loading={loading}
            label="Web Events"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.conversions || 0) +
              (bingData?.getBingCampaignsSummary?.conversions || 0)
            }
            render="integer"
            help="This is the count of an action when someone interacts with your ad and then takes an action that you've defined as valuable to your business, such as a webpage visit."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.conversions || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.conversions || 0)
                : ""
            }
            footerRender
            previous
          />
          <BudgetCard productLine="paid_search" title="Paid Search" />
        </GridContainer>
      </div>
      
      </PaidSearchNoContentWrapper> 
      : 
      <div>
        <h2 className={classes.pageHeader}>PaidSearch</h2>
        <GridContainer>
          <StatsCard
            icon="attach_money"
            loading={loading}
            label="Total Spent"
            value={addMargin(
              (googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (bingData?.getBingCampaignsSummary?.cost || 0),
              state
            )}
            render="currency"
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? addMargin((dataGoogleCompare?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (dataBingCompare?.getBingCampaignsSummary?.cost || 0), 
                state)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="dvr"
            loading={loading}
            label="Impressions"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.impressions || 0) +
              (bingData?.getBingCampaignsSummary?.impressions || 0)
            }
            render="integer"
            help="Impressions are the number of times that your advertisement has been displayed."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.impressions || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.impressions || 0)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="donut_small"
            loading={loading}
            label="Clicks"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
              (bingData?.getBingCampaignsSummary?.clicks || 0)
            }
            render="integer"
            help="Clicks are the count of the number of times users have clicked on your advertisement."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.clicks || 0)
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="scatter_plot"
            loading={loading}
            label="CTR"
            value={
              (((googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
                (bingData?.getBingCampaignsSummary?.clicks || 0)) /
                Math.max(
                  (googleData?.getGoogleCampaignInfoSummary?.impressions || 0) +
                    (bingData?.getBingCampaignsSummary?.impressions || 0),
                  1
                )) *
              100
            }
            render="percentage"
            milestones={[
              {
                value: 1,
                color: "info",
                icon: "fitness_center",
                message: `Not met "Working on it.."`
              },
              {
                value: 3,
                color: "orange",
                icon: "sentiment_satisfied",
                message: "Average"
              },
              {
                color: "wbmMoney",
                icon: "sentiment_very_satisfied",
                message: "Above Average"
              }
            ]}
            help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ?
                  (((dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                    (dataBingCompare?.getBingCampaignsSummary?.clicks || 0)) /
                    Math.max(
                      (dataGoogleCompare?.getGoogleCampaignInfoSummary?.impressions || 0) +
                        (dataBingCompare?.getBingCampaignsSummary?.impressions || 0),
                      1
                    )) *
                  100
                
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="image_aspect_ratio"
            loading={loading}
            label="CPC"
            value={addMargin(
              ((googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                (bingData?.getBingCampaignsSummary?.cost || 0)) /
                Math.max(
                  (googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
                    (bingData?.getBingCampaignsSummary?.clicks || 0),
                  1
                ),
              state
            )}
            render="currency"
            help="CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? addMargin(
                  ((dataGoogleCompare?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
                    (dataBingCompare?.getBingCampaignsSummary?.cost || 0)) /
                    Math.max(
                      (dataGoogleCompare?.getGoogleCampaignInfoSummary?.clicks || 0) +
                        (dataBingCompare?.getBingCampaignsSummary?.clicks || 0),
                      1
                    ),
                  state
                )
                : ""
            }
            footerRender
            previous
          />
          <StatsCard
            icon="compass_calibration"
            loading={loading}
            label="Web Events"
            value={
              (googleData?.getGoogleCampaignInfoSummary?.conversions || 0) +
              (bingData?.getBingCampaignsSummary?.conversions || 0)
            }
            render="integer"
            help="This is the count of an action when someone interacts with your ad and then takes an action that you've defined as valuable to your business, such as a webpage visit."
            footer={
              loadingBingCompare || loadingGoogleCompare
                ? "..."
                : compareOption
                ? (dataGoogleCompare?.getGoogleCampaignInfoSummary?.conversions || 0) +
                (dataBingCompare?.getBingCampaignsSummary?.conversions || 0)
                : ""
            }
            footerRender
            previous
          />
          <BudgetCard productLine="paid_search" title="Paid Search" />
        </GridContainer>
      </div>}
      </>   
  );
};

export default PaidSearch;
