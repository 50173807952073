import Bg404 from "assets/img/bg-404.jpg";

const styles = () => ({
  container: {
    position: "fixed",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    background: `url(${Bg404}) no-repeat center`,
    backgroundSize: "cover",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    padding: "0 30px"
  },
  heading: {
    maxWidth: "100%",
    width: "400px",
    fontSize: "75px",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    margin: "10px 0 30px 0"
  },
  button: {
    backgroundColor: "#0065c2",
    fontSize: "18px",
    fontFamily: "Comfortaa",
    fontWeight: "bold",
    width: "400px",
    maxWidth: "100%",
    padding: "12px 24px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#eb811f"
    }
  },
  buttonIcon: {
    width: "25px !important",
    height: "25px !important",
    marginRight: "10px !important"
  }
});

export default styles;
