import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_IMAGE_ASSETS } from "queries/google";
import { SUBMIT_UPDATE_REQUEST } from "queries/formSubmission";
import { Context } from "../../../redux/store";
import { renderInputGroup, renderUpdateTable } from "utils/renders";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paperScrollPaper: {
    maxWidth: "auto"
  }
};

const useStyles = makeStyles(styles);

const ViewAds = ({ filter, title, formSubmitted }) => {
  const [state, dispatch] = useContext(Context);
  const [updatedAds, setUpdatedAds] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const dateRangeString = state.customers?.dateRangeString;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_IMAGE_ASSETS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      filter,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listImageAssets || [{}];
  const classes = useStyles();

  const updateFieldHandler = (key, convert) => e => {
    setUpdatedAds({
      ...updatedAds,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const inputs = [
    {
      field: "ad_group_name",
      label: "Ad Type",
      type: "label"
    },
    {
      field: "image_url",
      label: "Ad Url",
      type: "label"
    },
    {
      field: "call_to_action",
      label: "Call to Action",
      inputProps: { maxLength: 75 }
    },
    {
      field: "phone_number",
      label: "Phone Number",
      inputProps: {
        maxLength: 30,
        type: "tel",
        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
        mask: "999-999-9999"
      }
    },
    {
      field: "landing_page",
      label: "Landing Page",
      inputProps: { maxLength: 30, type: "url" }
    }
  ];

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    SUBMIT_UPDATE_REQUEST,
    {
      variables: {
        subject: `Update Banner Ads | ${state.customers?.selected?.name}`,
        updateTable: renderUpdateTable(null, updatedAds, inputs),
        customerId: customer_id
      },
      onCompleted: () => {
        formSubmitted();
      }
    }
  );

  const handleOpen = row => {
    setUpdatedAds(row);
  };

  const handleClose = (accept = false) => () => {
    if (accept) {
      submitUpdateRequest();
    }
    setUpdatedAds(null);
  };

  const hasUpdate =
    user_level !== "customer" && user_level !== "agency_customer";

  const columns = compact([
    {
      Header: "Ad Group",
      accessor: "ad_group_name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Ad Type",
      accessor: "mime_type"
    },
    {
      Header: "Width",
      accessor: "pixel_width"
    },
    {
      Header: "Height",
      accessor: "pixel_height"
    },
    {
      Header: "Impressions",
      accessor: "impressions"
    },
    {
      Header: "Clicks",
      accessor: "clicks"
    },
    {
      Header: "CTR",
      accessor: "ctr",
      Cell:  ({ original }) => original?.impressions > 0 ? (Math.round(original?.clicks / original?.impressions * 10000) / 100).toString() + "%" : "0%"
    },
    {
      Header: "View Ad",
      accessor: "image_url",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original?.image_url ? (
          "Unknown"
        ) : (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              setImageUrl(original?.image_url);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            View
          </a>
        )
    },
    {
      Header: "Landing Page",
      accessor: "final_urls",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original?.final_urls ? (
          "Unknown"
        ) : (
          <a
            href={ original?.final_urls }
            rel="noopener noreferrer"
            target="_blank"
          >
            View
          </a>
        )
    },
    hasUpdate && {
      Header: "",
      accessor: "update",
      width: 100,
      sortable: false,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <a
          href="#"
          rel="noopener noreferrer"
          onClick={e => {
            handleOpen(original);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          Update
        </a>
      ),
      noCSV: false
    }
  ]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading || updating}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <Dialog
              open={Boolean(imageUrl)}
              onClose={() => setImageUrl(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paperScrollPaper: classes.paperScrollPaper
              }}
            >
              <img src={imageUrl} alt="" />
            </Dialog>
            {updatedAds && (
              <Dialog
                open={Boolean(updatedAds)}
                onClose={handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">Update Ads</DialogTitle>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleClose(true)();
                  }}
                >
                  <DialogContent>
                    {inputs.map(
                      renderInputGroup(null, updatedAds, updateFieldHandler)
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      autoFocus
                      type="submit"
                      disabled={inputs.some(({ field }) => !updatedAds[field])}
                    >
                      Submit
                    </Button>
                    <Button onClick={handleClose()}>Cancel</Button>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

ViewAds.propTypes = {
  filter: PropTypes.string,
  title: PropTypes.string
};

export default withSubmissions(ViewAds);
