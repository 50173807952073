import React, { useState, useContext } from "react";

import { Context } from "../../../../../redux/store";
import { getPrevious } from "utils/format";
import Assignment from "@mui/icons-material/Assignment";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import { useSnackbar } from "notistack";


import { useQuery, useMutation } from "@apollo/client";
import { LIST_CUSTOMER_EMAILS, UPDATE_CUSTOMER_EMAIL } from "queries/customers";

import {
  LIST_HYDRATE_TEXT_REPORT,
  LIST_HYDRATE_MMS_REPORT,
  LIST_HYDRATE_VOICE_REPORT,
  LIST_HYDRATE_EMAIL_REPORT,
  LIST_HYDRATE_LETTER_REPORT,
  LIST_HYDRATE_POSTCARD_REPORT,
  LIST_HYDRATE_EMAIL_DETAIL_REPORT
} from "queries/hydrate";
import { formatPhone } from "utils/format";
import { addMargin } from "utils/margin";

const normalizeString = (str, postfix = "") => (str ? `${str}${postfix} ` : "");

const moment = require("moment");

import HydrateSubPage from "./HydrateSubPage";
//import data from "./data";

const Hydrate = ({ classes }) => {
  const [state] = useContext(Context);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [customers, setCustomers] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customer_id = state.customers?.selected?.id;
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const compareDateRange = getPrevious(dateRangeString, compareOption);

  const data = (state) => [
    {
      title: "Text",
      heading: "Text",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_TEXT_REPORT,
      queryKey: "listHydrateTextReport",
      help: "Text | approx $0.044 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "Message Parts",
          accessor: "message_parts",
          Cell: ({ original }) => (original?.message_parts ? "Yes" : "-")
        },
        {
          Header: "From",
          accessor: "from",
          Cell: ({ original }) => formatPhone(original?.from) || ""
        },
        {
          Header: "Phone Number",
          accessor: "to",
          Cell: ({ original }) =>
            original?.to ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {formatPhone(original?.to)}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Body",
          accessor: "body",
          width: 300
        },
        {
          Header: "Spend",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Status Text",
          accessor: "status_text"
        },
        {
          Header: "Error",
          accessor: "error_text",
          Cell: ({ original }) => original?.status === "Sent" ? "" : original?.error_text
        }
      ]
    },
    {
      title: "MMS",
      heading: "MMS",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_MMS_REPORT,
      queryKey: "listHydrateMMSReport",
      help: "MMS | approx $0.09 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total MMS Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "Message Parts",
          accessor: "message_parts",
          Cell: ({ original }) => (original?.message_parts ? "Yes" : "-")
        },
        {
          Header: "From",
          accessor: "from",
          Cell: ({ original }) => formatPhone(original?.from) || ""
        },
        {
          Header: "Phone Number",
          accessor: "to",
          Cell: ({ original }) =>
            original?.to ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {formatPhone(original?.to)}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Message",
          accessor: "body",
          width: 300
        },
        {
          Header: "Spend",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        }
      ]
    },
    {
      title: "Voice",
      heading: "Voice",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_VOICE_REPORT,
      queryKey: "listHydrateVoiceReport",
      help: "Voice Message | approx $0.02 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "message_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Voice Messages",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Date",
          accessor: "date_created",
          width: 200,
          Cell: ({ original }) => moment.utc(original?.date_created).format("MM-DD-YYYY hh:mm:ss A") || ""
        },
        {
          Header: "From",
          accessor: "from_formatted",
          Cell: ({ original }) => original?.from_formatted || ""
        },
        {
          Header: "Phone Number",
          accessor: "to_formatted",
          Cell: ({ original }) =>
            original?.to_formatted ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {original?.to_formatted}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Type",
          accessor: "direction"
        },
        {
          Header: "Duration in Seconds",
          accessor: "duration"
        },
        {
          Header: "Cost",
          accessor: "message_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: original?.price_unit,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.message_price || 0, state))
        }
      ]
    },
    {
      title: "Email",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_EMAIL_REPORT,
      queryKey: "listHydrateEmailReport",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "delivered",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Emails",
          name: "delivered",
          render: "integer"
        },
        {
          icon: "attach_money",
          label: "Bounces",
          name: "bounces",
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Opens",
          name: "opens",
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Clicks",
          name: "clicks",
          render: "integer"
        },
        {
          icon: "donut_small",
          label: "Unsubscribes",
          name: "unsubscribes",
          render: "integer"
        },
      ]
    },
    {
      heading: "Email",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_EMAIL_DETAIL_REPORT,
      queryKey: "listHydrateEmailDetailsReport",
      help: "Email | approx $0.004 ea",
      columns: [
        {
          Header: "Date",
          accessor: "date"
        },
        {
          Header: "From",
          accessor: "from_email"
        },
        {
          Header: "To",
          accessor: "to_email",
          Cell: ({ original }) =>
            original?.to_email ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {original?.to_email}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Subject",
          accessor: "subject"
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Opens",
          accessor: "opens_count"
        },
        {
          Header: "Clicks",
          accessor: "clicks_count"
        },
        {
          Header: "Last Event",
          accessor: "last_event_time",
          width: 100,
          Cell: ({ original }) =>
            new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(original.last_event_time)
        }
      ]
    },
    {
      title: "Letter",
      heading: "Letter",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_LETTER_REPORT,
      queryKey: "listHydrateLetterReport",
      help: "Letter | approx. $1.24 ea. + color approx. $1.53 ea. + priority $.504",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "post_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Letters Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Name",
          accessor: "address_name",
          Cell: ({ original }) =>
            original?.address_name ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {normalizeString(original?.address_name)}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Address line 1",
          accessor: "address_line_1",
          Cell: ({ original }) => normalizeString(original?.address_line_1)
        },
        {
          Header: "Address line 2",
          accessor: "address_line_2",
          Cell: ({ original }) => normalizeString(original?.address_line_2)
        },
        {
          Header: "City",
          accessor: "address_city",
          Cell: ({ original }) => normalizeString(original?.address_city)
        },
        {
          Header: "State",
          accessor: "address_state",
          Cell: ({ original }) => normalizeString(original?.address_state)
        },
        {
          Header: "Zip",
          accessor: "address_postal_code",
          Cell: ({ original }) => normalizeString(original?.address_postal_code)
        },
        {
          Header: "Color",
          accessor: "color",
          Cell: ({ original }) => (original?.color ? "Yes" : "No")
        },
        {
          Header: "Two Sided",
          accessor: "duplex",
          Cell: ({ original }) => (original?.duplex ? "Yes" : "No")
        },
        {
          Header: "Number of Pages",
          accessor: "post_pages"
        },
        {
          Header: "Cost",
          accessor: "post_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.post_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Date",
          accessor: "date"
        }
      ]
    },
    {
      title: "Postcard",
      heading: "Postcard",
      TableIcon: Assignment,
      gqlKey: LIST_HYDRATE_POSTCARD_REPORT,
      queryKey: "listHydratePostcardReport",
      help: "Postcard | approx $1.11 ea",
      cardFields: [
        {
          icon: "attach_money",
          label: "Total Spent",
          name: "post_price",
          margin: true,
          render: "currency"
        },
        {
          icon: "donut_small",
          label: "Total Postcards Sent",
          name: () => 1,
          render: "integer"
        },
        {
          icon: "dvr",
          label: "Total Bounced",
          name: ({ original }) => (original?.status === "Bounced" ? 1 : 0),
          render: "integer"
        }
      ],
      columns: [
        {
          Header: "Name",
          accessor: "address_name",
          Cell: ({ original }) =>
            original?.address_name ?
              <a
                href="#"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpen(original);
                }}
              >
                {normalizeString(original?.address_name)}
              </a>
              : <>&nbsp;</>
        },
        {
          Header: "Address line 1",
          accessor: "address_line_1",
          Cell: ({ original }) => normalizeString(original?.address_line_1)
        },
        {
          Header: "Address line 2",
          accessor: "address_line_2",
          Cell: ({ original }) => normalizeString(original?.address_line_2)
        },
        {
          Header: "City",
          accessor: "address_city",
          Cell: ({ original }) => normalizeString(original?.address_city)
        },
        {
          Header: "State",
          accessor: "address_state",
          Cell: ({ original }) => normalizeString(original?.address_state)
        },
        {
          Header: "Zip",
          accessor: "address_postal_code",
          Cell: ({ original }) => normalizeString(original?.address_postal_code)
        },
        {
          Header: "Country",
          accessor: "address_country",
          Cell: ({ original }) => normalizeString(original?.address_country)
        },
        {
          Header: "Cost",
          accessor: "post_price",
          Cell: ({ original }) =>
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(addMargin(original?.post_price || 0, state))
        },
        {
          Header: "Status",
          accessor: "status"
        },
        {
          Header: "Date",
          accessor: "date"
        },
      ]
    }
  ];

  let { refetch: refetchCustomerEmail } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables:
    {
      customer_id,
      count: 0
    }
  });

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL);

  const handleOpen = async (original) => {
    let CustomerEmail = [];
    if (original?.to_email || original?.to_formatted || original?.to_email || original?.address_name) {
      CustomerEmail = await refetchCustomerEmail({
        customer_id,
        cell_phone_number: original?.to ? formatPhone(original?.to).replaceAll("-", "")?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "") : original?.to_formatted ? original?.to_formatted.replaceAll("-", "")?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "") : "",
        email: original?.to_email ? original?.to_email : "",
        first_name: original?.address_name?.split(" ")[0],
        last_name: original?.address_name?.split(" ")[1]
      })
    }
    if (CustomerEmail?.data?.listCustomerEmails?.length > 0) {
      setUpdatedCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0] || {});
      setCurrentCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0]?.id ? CustomerEmail?.data?.listCustomerEmails[0] : null);
    } else {
      setUpdatedCustomerEmail({
        first_name: original?.address_name?.split(" ")[0],
        last_name: original?.address_name?.split(" ")[1],
        cell_phone_number: formatPhone(original?.to),
        email: original?.to_email
      });
      setCurrentCustomerEmail({
        first_name: original?.address_name?.split(" ")[0],
        last_name: original?.address_name?.split(" ")[1],
        cell_phone_number: formatPhone(original?.to),
        email: original?.to_email
      });
    }
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  return (
    <div>
      {data(state).map(({ title, ...rest }) => (
        <div key={title}>
          <h2 className={classes.pageHeader}>{title}</h2>
          <HydrateSubPage
            dateRangeString={dateRangeString}
            compareOption={compareOption}
            compareDateRange={compareDateRange}
            customer_id={customer_id}
            classes={classes}
            state={state}
            {...rest}
          />
        </div>
      ))}
      {updatedCustomerEmail && (
        <Dialog
          open={Boolean(updatedCustomerEmail)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          fullWidth
        >
          <DialogContent>
            <UpdateCustomerProfile
              current={currentCustomerEmail}
              updated={updatedCustomerEmail}
              handleClose={handleClose}
              setCustomers={setCustomers}
              customers={customers}
              TrackedLead={1}
              source={"Hydrate"}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Hydrate;
