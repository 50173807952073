export const allAccountTypes = [
  {
    label: "Campaign Consultant",
    key: "campaign_consultant"
  },
  {
    label: "Advertising Consultant",
    key: "ad_consultant"
  },
  {
    label: "Customer",
    key: "customer"
  },
  {
    label: "PARA",
    key: "agency"
  },
  {
    label: "PARA Consultant",
    key: "agency_consultant"
  },
  {
    label: "PARA Customer",
    key: "agency_customer"
  }
];

export const accountFields = {
  campaign_consultant: [
    { key: "name", label: "Name", required: true, type: "input" }
  ],
  ad_consultant: [
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    { key: "name", label: "Name", required: true, type: "input" }
  ],
  customer: [
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    { key: "name", label: "Business Name", required: true },
    {
      key: "status",
      label: "Status",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Active" },
        { value: 2, label: "Paused" },
        { value: 3, label: "Cancelled" }
      ]
    },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true, defaultValue: "USA" },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (must include https://)", required: true },
    {
      key: "year_founded",
      label: "Year Founded",
      required: true,
      type: "integer"
    },
    {
      key: "invoicing_type",
      label: "Invoicing Type",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Immediately" },
        { value: 2, label: "Monthly" },
        { value: 3, label: "PARA Immediately" },
        { value: 4, label: "PARA Monthly" },
        { value: 5, label: "Ignore" }
      ]
    },
    {
      key: "invoicing_desired_date",
      label: "Invoicing Desired Date",
      type: "select-static",
      data: new Array(31)
        .fill(0)
        .map((n, index) => ({ value: index + 1, label: index + 1 }))
    },
    {
      key: "organic_search_dashboard_link",
      label: "Organic Search Dashboard Link"
    },
    {
      key: "paid_search_budget",
      label: "Paid Search Budget",
      type: "integer"
    },
    {
      key: "organic_search_budget",
      label: "Organic Search Budget",
      type: "integer"
    },
    {
      key: "local_services_ads_budget",
      label: "Local Services Ads Budget",
      type: "integer"
    },
    {
      key: "banner_ads_geo_demo_budget",
      label: "BannerAds | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "banner_ads_geo_intention_budget",
      label: "BannerAds | GeoIntention® Budget",
      type: "integer"
    },
    {
      key: "banner_ads_reengage_budget",
      label: "BannerAds | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_boosted_posts_budget",
      label: "SocialAds | BoostedPosts Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_geo_demo_budget",
      label: "SocialAds | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_reengage_budget",
      label: "SocialAds | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "video_ads_geo_demo_budget",
      label: "OnlineVideo | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "video_ads_geo_intention_budget",
      label: "OnlineVideo | GeoIntention® Budget",
      type: "integer"
    },
    {
      key: "video_ads_reengage_budget",
      label: "OnlineVideo | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "out_of_home_budget",
      label: "Out of Home Budget",
      type: "integer"
    },
    {
      key: "ott_budget",
      label: "OTT Budget",
      type: "integer"
    },
    {
      key: "offline_tracking_budget",
      label: "Offline Tracking Budget",
      type: "integer"
    },
    {
      key: "hosting_budget",
      label: "Hosting Budget",
      type: "integer"
    },
    { key: "tag_manger_head_code", label: "Tag Manager Head Code" },
    { key: "tag_manger_body_code", label: "Tag Manager Body Code" },
    { key: "tracking_code_url", label: "Tracking Code Url" },
    { key: "ecommerce", label: "Ecommerce Account" },
    {
      key: "monthly_leads_needed_breakeven",
      label: "Leads Needed to get a Return on Investment",
      type: "integer"
    },
    {
      key: "leads_needed_increase_budget",
      label: "Leads Needed to Increase Your Budget",
      type: "integer"
    },
    {
      key: "average_sale_goal",
      label: "Average Sale Goal",
      type: "integer"
    },
    {
      key: "lifetime_value",
      label: "Average Lifetime Value",
      type: "integer"
    },
    {
      key: "click_send_id",
      label: "Hydrate Username"
    },
    {
      key: "click_send_auth",
      label: "Hydrate Key"
    },
    {
      key: "quick_books_id",
      label: "Quick Books ID",
      permissions: ["master"]
    },
    {
      key: "birdeye_id",
      label: "Birdeye ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    },
    {
      key: "neuron_id",
      label: "Out of Home ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    }
  ],
  agency: [
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    { key: "name", label: "PARA Name", required: true },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (must include https://)", required: true },
    {
      key: "year_founded",
      label: "Year Founded",
      required: true,
      type: "integer"
    },
    {
      key: "original_start_date",
      label: "Original Start Date",
      type: "date"
    },
    { key: "quick_books_id", label: "Quick Books Id" }
  ],
  agency_consultant: [
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    {
      key: "agency_id",
      label: "PARA",
      required: true,
      type: "select",
      dataSource: {
        type: "agency",
        parent_id: "ad_consultant_id"
      }
    },
    { key: "name", label: "Name", required: true, type: "input" }
  ],
  agency_customer: [
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    {
      key: "agency_id",
      label: "PARA",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "agency",
        parent_id: "ad_consultant_id"
      }
    },
    {
      key: "agency_consultant_id",
      label: "PARA Consultant",
      type: "select",
      dataSource: {
        type: "agency_consultant",
        parent_id: "agency_id"
      }
    },
    { key: "name", label: "Business Name", required: true },
    {
      key: "status",
      label: "Status",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Active" },
        { value: 2, label: "Paused" },
        { value: 3, label: "Cancelled" }
      ]
    },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true, defaultValue: "USA" },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (must include https://)", required: true },
    {
      key: "year_founded",
      label: "Year Founded",
      required: true,
      type: "integer"
    },
    {
      key: "invoicing_type",
      label: "Invoicing Type",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Immediately" },
        { value: 2, label: "Monthly" },
        { value: 3, label: "PARA Immediately" },
        { value: 4, label: "PARA Monthly" },
        { value: 5, label: "Ignore" }
      ]
    },
    {
      key: "invoicing_desired_date",
      label: "Invoicing Desired Date",
      type: "select-static",
      data: new Array(31)
        .fill(0)
        .map((n, index) => ({ value: index + 1, label: index + 1 }))
    },
    {
      key: "organic_search_dashboard_link",
      label: "Organic Search Dashboard Link"
    },
    {
      key: "paid_search_budget",
      label: "Paid Search Budget",
      type: "integer"
    },
    {
      key: "organic_search_budget",
      label: "Organic Search Budget",
      type: "integer"
    },
    {
      key: "local_services_ads_budget",
      label: "Local Services Ads Budget",
      type: "integer"
    },
    {
      key: "banner_ads_geo_demo_budget",
      label: "BannerAds | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "banner_ads_geo_intention_budget",
      label: "BannerAds | GeoIntention® Budget",
      type: "integer"
    },
    {
      key: "banner_ads_reengage_budget",
      label: "BannerAds | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_boosted_posts_budget",
      label: "SocialAds | BoostedPosts Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_geo_demo_budget",
      label: "SocialAds | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "social_media_ads_reengage_budget",
      label: "SocialAds | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "video_ads_geo_demo_budget",
      label: "OnlineVideo | GeoDemo® Budget",
      type: "integer"
    },
    {
      key: "video_ads_geo_intention_budget",
      label: "OnlineVideo | GeoIntention® Budget",
      type: "integer"
    },
    {
      key: "video_ads_reengage_budget",
      label: "OnlineVideo | ReEngage® Budget",
      type: "integer"
    },
    {
      key: "out_of_home_budget",
      label: "Out of Home Budget",
      type: "integer"
    },
    {
      key: "ott_budget",
      label: "OTT Budget",
      type: "integer"
    },
    {
      key: "offline_tracking_budget",
      label: "Offline Tracking Budget",
      type: "integer"
    },
    {
      key: "hosting_budget",
      label: "Hosting Budget",
      type: "integer"
    },
    { key: "tag_manger_head_code", label: "Tag Manager Head Code" },
    { key: "tag_manger_body_code", label: "Tag Manager Body Code" },
    { key: "tracking_code_url", label: "Tracking Code Url" },
    { key: "ecommerce", label: "Ecommerce Account" },
    {
      key: "monthly_leads_needed_breakeven",
      label: "Leads Needed to get a Return on Investment",
      type: "integer"
    },
    {
      key: "leads_needed_increase_budget",
      label: "Leads Needed to Increase Your Budget",
      type: "integer"
    },
    {
      key: "average_sale_goal",
      label: "Average Sale Goal",
      type: "integer"
    },
    {
      key: "lifetime_value",
      label: "Average Lifetime Value",
      type: "integer"
    },
    {
      key: "click_send_id",
      label: "Hydrate Username"
    },
    {
      key: "click_send_auth",
      label: "Hydrate Key"
    },
    {
      key: "quick_books_id",
      label: "Quick Books ID",
      permissions: ["master"]
    },
    {
      key: "birdeye_id",
      label: "Birdeye ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    },
    {
      key: "neuron_id",
      label: "Out of Home ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    }
  ]
};

export const updateAccountFields = {
  campaign_consultant: [
    {
      key: "id",
      label: "Campaign Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant",
        all: true
      },
      sort: true
    },
    { key: "name", label: "Name", required: true, type: "input" }
  ],
  ad_consultant: [
    {
      key: "id",
      label: "Advertising Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        all: true
      }
    },
    { key: "name", label: "Name", required: true, type: "input" },
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    }
  ],
  customer: [
    {
      key: "id",
      label: "Customer",
      required: true,
      type: "select",
      dataSource: {
        type: "customer",
        all: true
      }
    },
    { key: "name", label: "Business Name", required: true },
    {
      key: "status",
      label: "Status",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Active" },
        { value: 2, label: "Paused" },
        { value: 3, label: "Cancelled" }
      ]
    },
    { key: "google_id", label: "Google ID (No Dashes)" },
    { key: "bing_id", label: "Bing ID" },
    { key: "mouseflow_id", label: "Mouse Flow ID" },
    { key: "twilio_account_sid", label: "Twilio Account Sid" },
    { key: "twilio_auth_id", label: "Twilio Auth ID" },
    // { key: "synup_id", label: "Synup ID" },
    { key: "call_rail_id", label: "Call_Rail ID" },
    { key: "google_analytics_id", label: "Google Analytics ID" },
    { key: "facebook_id", label: "Facebook ID" },
    // { key: "click_send_id", label: "Click_Send ID" },
    // { key: "quick_books_id", label: "Quick_Books ID" },
    { key: "margin", label: "Margin", type: "number", permissions: ["master"] },
    {
      key: "agency_margin",
      label: "PARA Margin",
      type: "number",
      permissions: ["master"]
    },
    {
      key: "lsa_margin",
      label: "LSA Margin",
      type: "number",
      permissions: ["master"]
    },
    { key: "tag_manager_id", label: "Tag Manager ID" },
    { key: "tag_manger_head_code", label: "Tag Manager Head Code" },
    { key: "tag_manger_body_code", label: "Tag Manager Body Code" },
    { key: "tracking_code_url", label: "Tracking Code Url" },
    { key: "ecommerce", label: "Ecommerce Account" },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (Must Include https://)", required: true },
    { key: "google_lsa_id_1", label: "Google LSA Account Number" },
    { key: "google_lsa_id_2", label: "Google LSA Account Number 2" },
    {
      key: "year_founded",
      label: "Year Founded",
      type: "integer"
    },
    {
      key: "invoicing_type",
      label: "Invoicing Type",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Immediately" },
        { value: 2, label: "Monthly" },
        { value: 3, label: "PARA Immediately" },
        { value: 4, label: "PARA Monthly" },
        { value: 5, label: "Ignore" }
      ]
    },
    {
      key: "invoicing_desired_date",
      label: "Invoicing Desired Date",
      type: "select-static",
      data: new Array(31)
        .fill(0)
        .map((n, index) => ({ value: index + 1, label: index + 1 }))
    },
    {
      key: "organic_search_dashboard_link",
      label: "Organic Search Dashboard Link"
    },
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    {
      key: "click_send_id",
      label: "Hydrate Username"
    },
    {
      key: "click_send_auth",
      label: "Hydrate Key"
    },
    {
      key: "quick_books_id",
      label: "Quick Books ID",
      permissions: ["master"]
    },
    {
      key: "birdeye_id",
      label: "Birdeye ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    },
    {
      key: "neuron_id",
      label: "Out of Home ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    }
  ],
  agency: [
    {
      key: "id",
      label: "PARA",
      required: true,
      type: "select",
      dataSource: {
        type: "agency",
        all: true
      }
    },
    { key: "name", label: "PARA Name", required: true },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true, defaultValue: "USA" },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (must include https://)", required: true },
    {
      key: "year_founded",
      label: "Year Founded",
      required: true,
      type: "integer"
    },
    {
      key: "original_start_date",
      label: "Original Start Date",
      type: "date"
    },
    { key: "quick_books_id", label: "Quick Books Id" },
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    }
  ],
  agency_consultant: [
    {
      key: "id",
      label: "PARA Consultant",
      required: true,
      type: "select",
      dataSource: {
        type: "agency_consultant",
        all: true
      }
    },
    { key: "name", label: "Name", required: true, type: "input" },
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    {
      key: "agency_id",
      label: "PARA",
      required: true,
      type: "select",
      dataSource: {
        type: "agency",
        parent_id: "ad_consultant_id"
      }
    }
  ],
  agency_customer: [
    {
      key: "id",
      label: "PARA Customer",
      required: true,
      type: "select",
      dataSource: {
        type: "agency_customer",
        all: true
      }
    },
    { key: "name", label: "Business Name", required: true },
    {
      key: "status",
      label: "Status",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Active" },
        { value: 2, label: "Paused" },
        { value: 3, label: "Cancelled" }
      ]
    },
    { key: "google_id", label: "Google ID (No Dashes)" },
    { key: "bing_id", label: "Bing ID" },
    { key: "mouseflow_id", label: "Mouse Flow ID" },
    { key: "twilio_account_sid", label: "Twilio Account Sid" },
    { key: "twilio_auth_id", label: "Twilio Auth ID" },
    // { key: "synup_id", label: "Synup ID" },
    { key: "call_rail_id", label: "Call_Rail ID" },
    { key: "google_analytics_id", label: "Google Analytics ID" },
    { key: "facebook_id", label: "Facebook ID" },
    // { key: "click_send_id", label: "Click_Send ID" },
    // { key: "quick_books_id", label: "Quick_Books ID" },
    { key: "margin", label: "Margin", type: "number" },
    { key: "agency_margin", label: "PARA Margin", type: "number" },
    { key: "lsa_margin", label: "LSA Margin", type: "number" },
    { key: "tag_manager_id", label: "Tag Manager ID" },
    { key: "tag_manger_head_code", label: "Tag Manager Head Code" },
    { key: "tag_manger_body_code", label: "Tag Manager Body Code" },
    { key: "tracking_code_url", label: "Tracking Code Url" },
    { key: "ecommerce", label: "Ecommerce Account" },
    { key: "address_1", label: "Address Line 1", required: true },
    { key: "address_2", label: "Address Line 2" },
    { key: "city", label: "City", required: true },
    { key: "state", label: "State", required: true },
    { key: "zipcode", label: "Zipcode", required: true },
    { key: "country", label: "Country", required: true },
    { key: "phone", label: "Main Phone Number (10 digits and dashes)", required: true },
    { key: "website", label: "Website (Must Include https://)", required: true },
    { key: "google_lsa_id_1", label: "Google LSA Account Number" },
    { key: "google_lsa_id_2", label: "Google LSA Account Number 2" },
    {
      key: "year_founded",
      label: "Year Founded",
      type: "integer"
    },
    {
      key: "invoicing_type",
      label: "Invoicing Type",
      required: true,
      type: "select-static",
      data: [
        { value: 1, label: "Immediately" },
        { value: 2, label: "Monthly" },
        { value: 3, label: "PARA Immediately" },
        { value: 4, label: "PARA Monthly" },
        { value: 5, label: "Ignore" }
      ]
    },
    {
      key: "invoicing_desired_date",
      label: "Invoicing Desired Date",
      type: "select-static",
      data: new Array(31)
        .fill(0)
        .map((n, index) => ({ value: index + 1, label: index + 1 }))
    },
    {
      key: "organic_search_dashboard_link",
      label: "Organic Search Dashboard Link"
    },
    {
      key: "campaign_consultant_id",
      label: "Campaign Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "campaign_consultant"
      }
    },
    {
      key: "ad_consultant_id",
      label: "Advertising Consultant",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "ad_consultant",
        parent_id: "campaign_consultant_id"
      }
    },
    {
      key: "agency_id",
      label: "PARA",
      required: true,
      ignored: true,
      type: "select",
      dataSource: {
        type: "agency",
        parent_id: "ad_consultant_id"
      }
    },
    {
      key: "agency_consultant_id",
      label: "PARA Consultant",
      type: "select",
      dataSource: {
        type: "agency_consultant",
        parent_id: "agency_id"
      }
    },
    {
      key: "click_send_id",
      label: "Hydrate Username"
    },
    {
      key: "click_send_auth",
      label: "Hydrate Key"
    },
    {
      key: "quick_books_id",
      label: "Quick Books ID",
      permissions: ["master"]
    },
    {
      key: "birdeye_id",
      label: "Birdeye ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    },
    {
      key: "neuron_id",
      label: "Out of Home ID",
      permissions: ["master", "campaign_consultant", "ad_consultant"]
    }
  ]
};
