import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_BIRDEYE_CUSTOMERS_AND_LEADS } from "queries/birdeye";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";

const ChatTotals = () => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const customer_id = state.customers?.selected?.id;
  const { loading, data } = useQuery(GET_BIRDEYE_CUSTOMERS_AND_LEADS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  const { loading: loadingCompare, data: dataCompare } = useQuery(
    GET_BIRDEYE_CUSTOMERS_AND_LEADS,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const summary = data?.getBirdeyeCustomersAndLeads;
  const summaryCompare = dataCompare?.getBirdeyeCustomersAndLeads;
  return (
    <GridContainer>
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Total Conversions"
        value={summary?.total || 0}
        render="integer"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.total || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="web"
        loading={loading}
        label="Leads From Website Chat"
        value={summary?.webchat || 0}
        render="integer"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.webchat || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="location_on"
        loading={loading}
        label="Leads From GMB"
        value={summary?.google || 0}
        render="integer"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.google || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="facebook"
        loading={loading}
        label="Leads From Facebook or Instagram"
        value={summary?.facebook || 0}
        render="integer"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.facebook || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="apps"
        loading={loading}
        label="Leads From Other"
        value={summary?.other || 0}
        render="integer"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.other || 0
            : ""
        }
        previous
      />
    </GridContainer>
  );
};

export default ChatTotals;
