import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import NoContentWrapper from "components/NoContentWrapper";
import { Context } from "../../../redux/store";

import AllOutofHomeAdsCampaigns from "./AllOutofHomeAdsCampaigns";
import CampaignBreakdown from "./CampaignBreakdown";
import CampaignTargetLocations from "./CampaignTargetLocations";
import OutOfHomeAssets from "./OutOfHomeAssets";

const useStyles = makeStyles(styles);

export default function OutOfHome() {
  const [state] = useContext(Context);
  const classes = useStyles();
  const neuron_id = state.customers?.selected?.neuron_id;

  return (
    <NoContentWrapper
      product="Out of Home"
      isAn
      isLoading={false}
      hasNoData={!neuron_id}
      noContent="Leverage Water Bear Marketing's targeting capabilities in places like roadside billboards, airports, transit locations, retail, entertainment arenas, points of care facilities, and/or commercial real estate locations. Eliminate long-term creative commitment, as we can swap out your ads on a daily basis. We have targeting capabilities to reach your audiences in every market in the USA."
    >
      <h2 className={classes.pageHeader}>All Out of Home Ads Campaigns</h2>
      <AllOutofHomeAdsCampaigns />
      <h2 className={classes.pageHeader}>Campaign Breakdown</h2>
      <CampaignBreakdown />
      <h2 className={classes.pageHeader}>Campaign Target Locations</h2>
      <CampaignTargetLocations />
      <h2 className={classes.pageHeader}>Out of Home Assets</h2>
      <OutOfHomeAssets />
    </NoContentWrapper>
  );
}
