import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import queryString from "query-string";


import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { RESET_PASSWORD } from "queries/auth";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import infoStyles from "assets/jss/material-dashboard-pro-react/components/infoStyle";

const useStyles = makeStyles(styles);
const useInfoStyles = makeStyles(infoStyles);

const ResetPasswordPage = ({ history }) => {
  const navigate = useNavigate();
  const { code, email } = queryString.parse(window.location.search);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [updated, setUpdated] = useState(false);
  const [Copied, setCopied] = useState(false);
  const [newSuggestedPassword, setnewSuggestedPassword] = useState("");

  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [resetPassword, { data, loading }] = useMutation(RESET_PASSWORD, {
    variables: { email, code, password },
    onCompleted: data => {
      if (!data?.resetPassword) {
        setUpdated(true);
      }
    },
    onError: errors => {
      if (errors?.message === "jwt expired") {
        alert("Your password code has expired. Please request a new one.  Redirecting you to forgot password page.");
        navigate("/auth/forgot-password");
      }
    }
  });
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const infoClasses = useInfoStyles();
  const goLogin = () => {
    navigate("/auth/login");
  };

  let charset = "";
  let newPassword = "";

  useEffect(() => {
    charset += "`~!@#$%^&*()_+-={}|[]:\"'?,/";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < 20; i++) {
      if(i === 0 || i === 5)
        newPassword += charset.charAt(Math.floor(Math.random() * 27));
      else
        newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    if (newSuggestedPassword?.length === 0)
      setnewSuggestedPassword(newPassword);
  });

  const ValidatePassword = () =>
  {
    var re = {
      capital: /(?=.*[A-Z])/,
      special: /(?=.*['`~\$\*\+\[\|\]\^\(\)\{\}\?!@#%&_=:\",/])/,
      digit: /(?=.*[0-9])/,
  };

  return re.capital .test(password) && 
         re.digit   .test(password) && 
         re.special    .test(password);
  }

  return (
    <div className={classes.container}>
      <div className={classes.container} style={{ width: "500px", margin: "0 auto" }}>
        <br />
        <br />
        <br />
        <br />
        <form>
          <Card login className={classes[cardAnimaton]}>
            <CardBody>
              {data?.resetPassword && (
                <div className={infoClasses.danger}>
                  {data?.resetPassword}
                </div>
              )}
              {updated ? (
                <h5>Password is updated successfully.</h5>
              ) : (
                <>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: email,
                      autoComplete: "off",
                      readOnly: true
                    }}
                  />
                  <CustomInput
                    labelText="New Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off",
                      value: password,
                      onChange: e => setPassword(e.target.value)
                    }}
                  />
                  <CustomInput
                    labelText="Confirm Password"
                    id="confirm_password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      autoComplete: "off",
                      value: confirmPassword,
                      onChange: e => setConfirmPassword(e.target.value)
                    }}
                  />
                  <br />
                  <br />
                  {Copied ?
                    <Tooltip
                      title="Copied to Clipboard.  Please Save in a Password Manager!"
                      placement="top"
                    >
                      <div style={{ color: "#0051AE", textAlign: "center", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(newSuggestedPassword); setCopied(true); setConfirmPassword(newSuggestedPassword); setPassword(newSuggestedPassword); }}><div style={{ fontSize: "20px" }}>Click to use Suggested Password: <br /><br /><b>{newSuggestedPassword}</b></div></div>
                    </Tooltip>
                    : <div style={{ color: "#0051AE", textAlign: "center", cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(newSuggestedPassword); setCopied(true); setConfirmPassword(newSuggestedPassword); setPassword(newSuggestedPassword); }}><div style={{ fontSize: "20px" }}>Click to use Suggested Password: <br /><br /><b>{newSuggestedPassword}</b></div></div>}
                  {confirmPassword !== password &&
                    <><br /><div style={{ color: "red", textAlign: "center" }}>Password and confirm password does not match.</div><br /></>}                  
                  {password?.length < 12 &&
                    <><br /><div style={{ color: "red", textAlign: "center" }}>Password must be at least 12 characters.</div><br /></>}
                  {ValidatePassword() === false &&
                    <><div style={{ color: "red", textAlign: "center" }}>Password must contain Upper and Lower case characters plus one of the following symbols:  {"` ~ ! @ # $ % ^ & * ( ) _ + - = { } | [ ] : \" ' ? , /"}</div>.<br /></>}
                </>
              )}
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="orange"
                size="lg"
                block
                disabled={!password || confirmPassword !== password || password?.length < 12 || ValidatePassword() === false}
                onClick={updated ? goLogin : resetPassword}
              >
                {updated ? "Go Back to Login" : "Update Password"}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
