import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";
import { addMargin } from "utils/margin";
import HelpTooltip from "components/HelpTooltip";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_AD_EXTENSIONS_CALLS } from "queries/google";
import { Context } from "../../../redux/store";
import { formatMobileNumber } from "utils/format";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Calls = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_GOOGLE_AD_EXTENSIONS_CALLS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGoogleAdExtensionCalls || [];

  data = data.map((row) => ({    
    ...row,
    ctr: Number(row.impressions) !== 0 ? Number((row.clicks / row.impressions).toFixed(2)): 0
  }));

  const classes = useStyles();

  const inputs = [
    {
      field: "country_code",
      label: "Country Code"
    },
    {
      field: "phone_number",
      label: "Phone Number"
    },
    { field: "notes", label: "Notes", optional: true }
  ];

  data = data.filter(row => inputs.some(({ field }) => row[field]));

  const columns = compact([
    {
      Header: "Phone",
      accessor: "phone_number",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{formatMobileNumber(original?.phone_number)}</div>
        </>
      ),
      renderCSV: ({ original }) =>
        [
          formatMobileNumber(original?.phone_number)
        ].join("\n")
    },
    {
      Header: "Campaign Name",
      accessor: "campaign_name",
      width: 200
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name"
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
      (original?.ctr * 100).toFixed(2) + "%"
    },
    {
      Header: "Phone Impressions",
      accessor: "phone_impressions",
      width: 100,
      Cell: ({ original }) =>
      original?.phone_impressions !== null ? original?.phone_impressions : 0
    },
    {
      Header: "Phone Calls",
      accessor: "phone_calls",
      width: 100,
      Cell: ({ original }) =>
      original?.phone_calls !== null ? original?.phone_calls : 0
    },
    {
      Header: "Phone CTR",
      accessor: "phone_through_rate",
      width: 100,
      Cell: ({ original }) =>
      original?.phone_through_rate !== null ? (original?.phone_through_rate * 100).toFixed(2) + "%" : 0
    }
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Calls
              <HelpTooltip>
                {`Call assets let you add phone numbers to your ads, which can significantly increase clickthrough rates. When your call assets show, people can tap or click a button to call your business directly or go to your website.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Calls(${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(Calls);
