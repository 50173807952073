import gql from "graphql-tag";

export const SYNCHRONIZE_WITH_CLICKSEND = gql`
  mutation($customer_id: Int!) {
    synchronizeWithClicksend(customer_id: $customer_id)
  }
`;

export const LIST_RETURN_ADDRESSES = gql`
  query($customer_id: Int) {
    listReturnAddresses(customer_id: $customer_id) {
      id
      customer_id
      address_name
      address_line_1
      address_line_2
      address_city
      address_postal_code
      address_country
      address_state
      email_address
      business_name
      cc_email
      bcc_email
      first_name
      last_name
      pinterest_url
      linkedin_url
      twitter_url
      instagram_url
      facebook_url
      website_url
      review_url
      youtube_url
      landing_page_url
      hydrate_phone
      youtube_url
    }
  }
`;

export const UPDATE_RETURN_ADDRESS = gql`
  mutation($customer_id: Int, $returnAddress: ReturnAddressInput) {
    updateReturnAddress(
      customer_id: $customer_id
      returnAddress: $returnAddress
    ) {
      id
      customer_id
      address_name
      address_line_1
      address_line_2
      address_city
      address_postal_code
      address_country
      address_state
      email_address
      business_name
      cc_email
      bcc_email
      first_name
      last_name
      error
      pinterest_url
      linkedin_url
      twitter_url
      instagram_url
      facebook_url
      website_url
      review_url
      landing_page_url
      hydrate_phone
      youtube_url
    }
  }
`;

export const REMOVE_RETURN_ADDRESS = gql`
  mutation($customer_id: Int, $id: Int) {
    removeReturnAddress(customer_id: $customer_id, id: $id) {
      id
      customer_id
      address_name
      address_line_1
      address_line_2
      address_city
      address_postal_code
      address_country
      address_state
      email_address
      business_name
      cc_email
      bcc_email
      first_name
      last_name
      error
      pinterest_url
      linkedin_url
      twitter_url
      instagram_url
      facebook_url
      website_url
      review_url
      landing_page_url
      hydrate_phone
      youtube_url
    }
  }
`;

export const LIST_EMAIL_ADDRESSES = gql`
  query($customer_id: Int) {
    listEmailAddresses(customer_id: $customer_id) {
      id
      customer_id
      email_address
      name
      verified
      address
      address2
      nickname
      state
      city
      country
      zip
      sendgrid_template
      sendgrid_subuser
      sendgrid_api_key
    }
  }
`;

export const UPDATE_EMAIL_ADDRESS = gql`
  mutation($customer_id: Int, $emailAddress: EmailAddressInput) {
    updateEmailAddress(
      customer_id: $customer_id
      emailAddress: $emailAddress
    ) {
      id
      customer_id
      email_address
      name
      verified
      address
      address2
      nickname
      state
      city
      country
      zip
      sendgrid_template
      sendgrid_subuser
      sendgrid_api_key
    }
  }
`;

export const VERIFY_EMAIL_ADDRESS = gql`
  mutation($id: Int) {
    verifyEmailAddress(id: $id) {
      id
      customer_id
      email_address
      name
      verified
      address
      address2
      nickname
      state
      city
      country
      zip
      sendgrid_template
      sendgrid_subuser
      sendgrid_api_key
    }
  }
`;

export const REMOVE_EMAIL_ADDRESS = gql`
  mutation($id: Int) {
    removeEmailAddress(id: $id) {
      id
    }
  }
`;
