import React from 'react';
import { useSnackbar } from "notistack";

const withSubmissions = (Component) => {
  const ComponentUpdated = (props) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    };

    const formSubmitted = options => {
      notify({
        text: "Information has been successfully submitted",
        variant: "success",
        ...options
      });
    };

    const formSubmissionFailed = options => {
      notify({
        text: "Something went wrong!",
        ...options,
        variant: "error"
      });
    };

    return (
      <Component
        formSubmitted={formSubmitted}
        formSubmissionFailed={formSubmissionFailed}
        notify={notify}
        {...props}
      />
    );
  };
  return ComponentUpdated;
};

export default withSubmissions;