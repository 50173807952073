import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_SOCIAL_ADS_RESULTS } from "queries/social";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const AdResultsBreakdown = () => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const facebook_id = state.customers?.selected?.facebook_id;
  let { loading, data } = useQuery(LIST_SOCIAL_ADS_RESULTS, {
    skip: !facebook_id || !state.customers?.dateRangeString,
    variables: {
      facebook_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = facebook_id ? data?.listSocialAdsResults || [] : [];
  const classes = useStyles();
  const columns = [
    {
      Header: "AdSet Name",
      accessor: "ad_set_name"
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: ({ original }) => moment(original.date_start).format("YYYY-MM-DD")
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: ({ original }) => moment(original.date_stop).format("YYYY-MM-DD")
    },
    {
      Header: "Objective",
      accessor: "objective"
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100,
      Cell: ({ original }) => original.impressions || 0
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100,
      Cell: ({ original }) => original.clicks || 0
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(
          (original?.clicks || 0) / (original?.impressions || 1) / 0.01
        )}%`
    },
    {
      Header: "CPC",
      accessor: "avg_cpc",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin(
            ((original?.social_spend || 0) + (original?.spend || 0)) /
              Math.max(original?.clicks || 0, 1),
            state
          )
        )
    },
    {
      Header: "CPM",
      accessor: "avg_cpm",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin(
            (((original?.social_spend || 0) + (original?.spend || 0)) /
              Math.max(original?.impressions || 0, 1)) *
              1000,
            state
          )
        )
    },
    {
      Header: "Reach",
      accessor: "reach",
      width: 100,
      Cell: ({ original }) => original.reach || 0
    },
    {
      Header: "Social Spend",
      accessor: "social_spend",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin(original?.social_spend || 0, state))
    },
    {
      Header: "Spend",
      accessor: "spend",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin(original?.spend || 0, state))
    }
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>SocialAds Results</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AdResultsBreakdown;
