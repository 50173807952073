import { SET_USERS, SELECT_USER } from "../../redux/constants";

const Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        data: action.payload || [],
        selected: (action.payload || [])[0]
      };
    case SELECT_USER:
      return {
        ...state,
        selected: state.data?.find(({ id }) => id === action.payload) || {}
      };
    default:
      return state;
  }
};

export default Reducer;
