import React, { useContext, useState, useEffect } from "react";

import PropTypes from 'prop-types';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useLocation } from "react-router-dom";
import LinearNoContentWrapper from "components/LinearNoContentWrapper";

import People from "./People";
import CRMCustomersPage from "./CRMCustomers";
import CRMLeads from "./CRMLeads";
import Jobs from "./Jobs";
import Documents from "./Documents";
import Contracts from "./Contracts";
import Proposals from "./Proposals";
import Products from "./Products";
import Photos from "./Photos";
import Activity from "./Activity";
import Services from "./Services";
import Tickets from "./Tickets";
import Estimates from "./Estimates";
import { READ_ME } from "queries/users";

import { useQuery, useLazyQuery } from "@apollo/client";
import { Context } from "../../../redux/store";

import {
  GET_CRM_SETTINGS,
  LIST_CUSTOMERS
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";
import { LabelImportant } from "@mui/icons-material";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const CustomerManagement = (props) => {
  const history = useLocation();
  const [Pagevalue, setPagevalue] = useState(0);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const [CRMSettings, setCRMSettings] = useState([]);

  const [B2B, setB2B] = useState(null);
  const [state] = useContext(Context);
  const { data: me } = useQuery(READ_ME);
  const customer_id = state.customers?.selected?.id;

  let { data: customer_data, loading: customer_data_loading } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  useEffect(() => {
    GetCRMSettings();
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);
    setB2B(customer_data?.listCustomers[0]?.B2B === 1 ? "B2B" : "B2C");

    if (history.pathname.indexOf("leads") > -1)
      setPagevalue(1);
    else if (history.pathname.indexOf("crmcustomers") > -1)
      setPagevalue(2);
    else if (history.pathname.indexOf("people") > -1)
      setPagevalue(3);
    else if (history.pathname.indexOf("jobs") > -1)
      setPagevalue(4);
    else if (history.pathname.indexOf("products") > -1)
      setPagevalue(5);
    else if (history.pathname.indexOf("services") > -1)
      setPagevalue(6);
    else if (history.pathname.indexOf("photo-gallery") > -1)
      setPagevalue(7);
    else if (history.pathname.indexOf("documents") > -1)
      setPagevalue(8);
    else if (history.pathname.indexOf("estimates") > -1)
      setPagevalue(9);
    else if (history.pathname.indexOf("proposals") > -1)
      setPagevalue(10);
    else if (history.pathname.indexOf("contracts") > -1)
      setPagevalue(11);
    else if (history.pathname.indexOf("tickets") > -1)
      setPagevalue(12);
  }, [budget_data, customer_data, history]);

  useEffect(() => {
    if (document.getElementById("Heading"))
      document.getElementById("Heading").scrollIntoView(false);
  })

  const handleChangeAppBarPage = (event, newValue) => {
    setPagevalue(newValue);
  };

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <Card>
            <CardBody>
              <div id="Heading" style={{ display: "block" }}>
                <div style={{ width: "40%", float: "left" }}>
                  {Pagevalue === 0 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Activity List</label>)}
                  {Pagevalue === 1 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Leads List</label>)}
                  {Pagevalue === 2 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Customers List</label>)}
                  {Pagevalue === 3 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>People List</label>)}
                  {Pagevalue === 4 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Jobs List</label>)}
                  {Pagevalue === 5 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Products List</label>)}
                  {Pagevalue === 6 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Services List</label>)}
                  {Pagevalue === 7 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Photos List</label>)}
                  {Pagevalue === 8 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Documents List</label>)}
                  {Pagevalue === 9 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Estimate List</label>)}
                  {Pagevalue === 10 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Proposals List</label>)}
                  {Pagevalue === 11 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Contracts List</label>)}
                  {Pagevalue === 12 && (<label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Tickets List</label>)}
                </div>
              </div>
            </CardBody>
          </Card>
          <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
            <Toolbar>
              <Tabs value={Pagevalue} onChange={handleChangeAppBarPage} variant="scrollable" scrollButtons={true}>
                <Tab label="Activity" {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Leads" style={{ display: B2B === "B2C" ? "none" : "" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Customers" style={{ display: B2B === "B2C" ? "none" : "" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="People" {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Jobs" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_jobs ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Products" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_products ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Services" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_services ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Photos" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_gallery ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Documents" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_documents ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Estimates" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_estimates ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Proposals" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_proposals ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Contracts" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_contracts ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
                <Tab label="Tickets" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_tickets ? "" : "none" }} {...a11yProps(0)} borderleft={1} borderright={1} />
              </Tabs>
            </Toolbar>
          </AppBar>
          <TabPanel value={Pagevalue} index={0}>
            <Activity />
          </TabPanel>
          <TabPanel value={Pagevalue} index={1}>
            <CRMLeads />
          </TabPanel>
          <TabPanel value={Pagevalue} index={2}>
            <CRMCustomersPage />
          </TabPanel>
          <TabPanel value={Pagevalue} index={3}>
            <People />
          </TabPanel>
          <TabPanel value={Pagevalue} index={4}>
            <Jobs />
          </TabPanel>
          <TabPanel value={Pagevalue} index={5}>
            <Products />
          </TabPanel>
          <TabPanel value={Pagevalue} index={6}>
            <Services />
          </TabPanel>
          <TabPanel value={Pagevalue} index={7}>
            <Photos />
          </TabPanel>
          <TabPanel value={Pagevalue} index={8}>
            <Documents />
          </TabPanel>
          <TabPanel value={Pagevalue} index={9}>
            <Estimates />
          </TabPanel>
          <TabPanel value={Pagevalue} index={10}>
            <Proposals />
          </TabPanel>
          <TabPanel value={Pagevalue} index={11}>
            <Contracts />
          </TabPanel>
          <TabPanel value={Pagevalue} index={12}>
            <Tickets />
          </TabPanel>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
}


CustomerManagement.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CustomerManagement;