import React from "react";
import { compact } from "lodash";

import ReactTable from "components/CustomReactTable";

const Preview = ({ mapping, inputs, content, customers }) => {
  const columns = compact(
    content[0]?.map((value, index) => {
      if (mapping[index]) {
        const input = inputs.find(({ field }) => field === mapping[index]);
        return {
          Header: input?.label,
          accessor: mapping[index]
        };
      }
      return null;
    })
  );
  return (
    <div>
      <p style={{ fontWeight: "bold" }}>Preview import data</p>
      <ReactTable
        data={customers}
        columns={columns}
        showPaginationTop={false}
        showPaginationBottom={false}
        defaultPageSize={5}
        className="-striped -highlight height-limited"
      />
    </div>
  );
};

export default Preview;
