import gql from "graphql-tag";

export const LIST_CUSTOMERS = gql`
    query($customer_id: Int, $crm_customers_id: Int) {
      listCustomers(customer_id: $customer_id, crm_customers_id: $crm_customers_id) {
        id
        name
        google_id
        bing_id
        mouseflow_id
        twilio_account_sid
        twilio_auth_id
        synup_id
        call_rail_id
        google_analytics_id
        facebook_id
        click_send_id
        click_send_auth
        quick_books_id
        margin
        agency_margin
        tag_manager_id
        tag_manger_head_code
        tag_manger_body_code
        tracking_code_url
        address_1
        address_2
        city
        state
        zipcode
        country
        phone
        website
        year_founded
        original_start_date
        ad_consultant_id
        campaign_consultant_id
        agency_consultant_id
        status
        organic_search_dashboard_link
        birdeye_id
        neuron_id
        google_lsa_id_1
        google_lsa_id_2
        lsa_margin
        sunday_open
        sunday_close
        monday_open
        monday_close
        tuesday_open
        tuesday_close
        wednesday_open
        wednesday_close
        thursday_open
        thursday_close
        friday_open
        friday_close
        saturday_open
        saturday_close
        timezone
        B2B
        crm_customers_id
    }
  }
`;

export const GET_CRM_SETTINGS = gql`
    query getCRMSettings($customer_id: Int) {
      getCRMSettings(customer_id: $customer_id) {
        id
        hide_jobs
        hide_products
        hide_services
        hide_gallery
        hide_documents
        hide_estimates
        hide_proposals
        hide_contracts
        hide_tickets
    }
  }
`;

export const LIST_MUTED_NOTIFICATIONS = gql`
    query listMutedNotifications($customer_id: Int, $user_id: Int, $object: String) {
      listMutedNotifications(customer_id: $customer_id, user_id: $user_id, object: $object) {
        id
        user_id
        object
    }
  }
`;

export const GET_CUSTOMER_MARKETING_GOAL = gql`
  query getCustomerMarketingGoal($customer_id: Int!, $crm_onboarding_id: Int) {
    getCustomerMarketingGoal(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      monthly_leads_goal
      cost_per_lead_goal
      average_sale_goal
      lifetime_value
      monthly_leads_needed_breakeven
      leads_needed_increase_budget
      leads_needed_write_review
      leads_needed_referral
      leads_needed_to_be_happy
      cpl_goal
      product_id
      onboarding_id
    }
  }
`;

export const UPDATE_BRANDS_COLORS_AND_FONTS = gql`
  mutation(
    $company_primary_font: String
    $company_secondary_font: String
    $primary_company_color: String
    $secondary_company_color: String
    $customer_id: Int
    $onboarding_id: Int
  ) {
    updateBrandColorsAndFonts(
      company_primary_font: $company_primary_font
      company_secondary_font: $company_secondary_font
      primary_company_color: $primary_company_color
      secondary_company_color: $secondary_company_color
      customer_id: $customer_id
      onboarding_id: $onboarding_id
    )
  }
`;

export const UPDATE_CUSTOMER_MARKETING_GOAL = gql`
  mutation(
    $id: Int
    $monthly_leads_goal: String
    $cost_per_lead_goal: String
    $average_sale_goal: String
    $lifetime_value: String
    $monthly_leads_needed_breakeven: String
    $leads_needed_increase_budget: String
    $leads_needed_write_review: String
    $leads_needed_referral: String
    $leads_needed_to_be_happy: String
    $cpl_goal: String
    $customer_id: Int
    $product_id: Int
    $onboarding_id: Int
  ) {
    updateCustomerMarketingGoal(
      id: $id
      monthly_leads_goal: $monthly_leads_goal
      cost_per_lead_goal: $cost_per_lead_goal
      average_sale_goal: $average_sale_goal
      lifetime_value: $lifetime_value
      monthly_leads_needed_breakeven: $monthly_leads_needed_breakeven
      leads_needed_increase_budget: $leads_needed_increase_budget
      leads_needed_write_review: $leads_needed_write_review
      leads_needed_referral: $leads_needed_referral
      leads_needed_to_be_happy: $leads_needed_to_be_happy
      cpl_goal: $cpl_goal
      customer_id: $customer_id
      product_id: $product_id
      onboarding_id: $onboarding_id
    ) {
      id
      monthly_leads_goal
      cost_per_lead_goal
      average_sale_goal
      lifetime_value
      monthly_leads_needed_breakeven
      leads_needed_increase_budget
      leads_needed_write_review
      leads_needed_referral
      leads_needed_to_be_happy
      cpl_goal
      product_id
      onboarding_id
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation($id: Int, $data: CustomerInput!) {
    updateCustomer(id: $id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_HOURS = gql`
  mutation($id: Int, $data: CustomerInput!) {
    updateCustomerHours(id: $id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_DATE = gql`
  mutation($id: Int, $original_start_date: String) {
    updateCustomerDate(id: $id, original_start_date: $original_start_date)
  }
`;

export const ADD_ACCOUNT = gql`
  mutation($type: String, $data: String) {
    addAccount(type: $type, data: $data)
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation($id: Int, $type: String, $data: String) {
    updateAccount(id: $id, type: $type, data: $data)
  }
`;

export const ADD_CUSTOMER = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
      name
      google_id
      bing_id
      mouseflow_id
      twilio_account_sid
      twilio_auth_id
      synup_id
      call_rail_id
      google_analytics_id
      facebook_id
      click_send_id
      click_send_auth
      quick_books_id
      margin
      agency_margin
      tag_manager_id
      tag_manger_head_code
      tag_manger_body_code
      tracking_code_url
      address_1
      address_2
      city
      state
      zipcode
      country
      phone
      website
      year_founded
      original_start_date
      ad_consultant_id
      campaign_consultant_id
      agency_consultant_id
      status
      organic_search_dashboard_link
      birdeye_id
      neuron_id
      google_lsa_id_1
      google_lsa_id_2
      lsa_margin
      B2B
    }
  }
`;

export const ADD_CAMPAIGN_CONSULTANT = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const ADD_AD_CONSULTANT = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const ADD_AGENCY = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const ADD_AGENCY_CONSULTANT = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const ADD_AGENCY_CUSTOMER = gql`
  mutation($data: CustomerInput!) {
    addCustomer(data: $data) {
      id
    }
  }
`;

export const LIST_ACCOUNTS = gql`
  query($type: String, $parent_id: Int) {
    listAccounts(type: $type, parent_id: $parent_id) {
      id
      name
    }
  }
`;

export const LIST_ALL_ACCOUNTS = gql`
  query($type: String) {
    listAllAccounts(type: $type)
  }
`;

export const GET_ACCOUNT_USER = gql`
  query($account_id: String, $is_sub: Boolean) {
    getAccountUser(account_id: $account_id, is_sub: $is_sub) {
      id
      first_name
      last_name
      email
      cell_phone
      birthday
      office_phone
      office_extension
      commission
      auto_reporting_emails
      billing_contact
      sub
      customer_id
    }
  }
`;

export const UPDATE_ACCOUNT_USER = gql`
  mutation($account_id: String, $user: UserInput, $is_sub: Boolean) {
    updateAccountUser(account_id: $account_id, user: $user, is_sub: $is_sub) {
      id
      first_name
      last_name
      email
      commission
      cell_phone
      birthday
      office_phone
      office_extension
      auto_reporting_emails
      sub
      customer_id
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query($customer_id: Int) {
    listProductCategories(customer_id: $customer_id) {
      id
      category_text
    }
  }
`;

export const GET_SERVICE_CATEGORIES = gql`
  query($customer_id: Int) {
    listServiceCategories(customer_id: $customer_id) {
      id
      category_text
    }
  }
`;

export const LIST_ALL_SOCIALS = gql`
  query($customer_id: Int) {
    listAllSocials(customer_id: $customer_id) {
      id
      social_channel
    }
  }
`;

export const REMOVE_CUSTOMER_SOCIAL = gql`
  mutation($customer_id: Int, $id: Int) {
    removeSocial(customer_id: $customer_id, id: $id)
  }
`;

export const UPDATE_CURRENT_SOCIALS = gql`
  mutation updateSocials($customer_id: Int, $data: [SocialInput!]!) {
    updateSocials(customer_id: $customer_id, data: $data)
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  mutation markNotificiationRead($customer_id: Int, $notification_id: Int) {
    markNotificiationRead(customer_id: $customer_id, notification_id: $notification_id)
  }
`;

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation markAllNotificiationRead($customer_id: Int, $user_id: Int) {
    markAllNotificiationRead(customer_id: $customer_id, user_id: $user_id)
  }
`;

export const LIST_CURRENT_SOCIALS = gql`
  query($customer_id: Int, $people_id: Int, $crm_customer_id: Int) {
    listCurrentSocials(customer_id: $customer_id, people_id: $people_id, crm_customer_id: $crm_customer_id) {
      id
      social_channel_id
      social_channel_url
      people_id
      crm_customer_id
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query($customer_id: Int, $user_id: Int) {
    getNotifications(customer_id: $customer_id, user_id: $user_id) {
      id
      image_object
      detailPage
      message
      notificationread
      created_time
    }
  }
`;

export const LIST_CUSTOMER_EMAILS = gql`
  query($customer_id: Int, $crm_customer_id: Int, $email: String, $cell_phone_number: String, $first_name: String, $last_name: String, $name: String, $count: Int, $order_by: String, $rows_per_page: Int, $page: Int, $office_phone_number: String, $address: String, $assigned_to: String, $id: Int, $search: Int, $LastSaleValue: String, $LifetimeValue: String, $Notes: String, $JobTitle: String, $BusinessEmail: String, $BusinessPhone: String, $BusinessExtension: String, $BusinessAddress: String, $BusinessName: String, $ListName: String, $lead_status_text: String, $customer_ids: [Int], $job_ids: [Int], $gallery_ids: [Int], $document_ids: [Int], $estimate_ids: [Int], $proposal_ids: [Int], $contract_ids: [Int], $ticket_ids: [Int]) {
    listCustomerEmails(customer_id: $customer_id, crm_customer_id: $crm_customer_id, email: $email, cell_phone_number: $cell_phone_number, first_name: $first_name, last_name: $last_name, name: $name, count: $count, order_by: $order_by, rows_per_page: $rows_per_page, page: $page, office_phone_number: $office_phone_number, address: $address, assigned_to: $assigned_to, id: $id, search: $search, LastSaleValue: $LastSaleValue, LifetimeValue: $LifetimeValue, Notes: $Notes, JobTitle: $JobTitle, BusinessEmail: $BusinessEmail, BusinessPhone: $BusinessPhone, BusinessExtension: $BusinessExtension, BusinessAddress: $BusinessAddress, BusinessName: $BusinessName, ListName: $ListName, lead_status_text: $lead_status_text, customer_ids: $customer_ids, job_ids: $job_ids, gallery_ids: $gallery_ids, document_ids: $document_ids, estimate_ids: $estimate_ids, proposal_ids: $proposal_ids, contract_ids: $contract_ids, ticket_ids: $ticket_ids) {
      id
      first_name
      last_name
      email
      list_name
      cell_phone_number
      opt_int_email
      opt_in_text
      opt_in_voice
      profile_image
      contact_business_only
      lead_quality
      lead_status
      lead_type
      sale_value
      lifetime_value
      notes
      first_contacted
      last_contacted
      job_title
      office_email
      office_phone_number
      office_extension
      crm_customers_id
      crm_representative_id
      address_line_one
      address_line_two
      city
      zip_code
      state
      country
      unsubscribe
      products
      categories
      business_name
      office_address_line_one
      office_address_line_two
      office_city
      office_state
      office_country
      office_zip
      rep_first_name
      rep_last_name
      count 
      lead_status_text
      referrer_id
      assigned_to
      count
      activity_date
      assigned_to_first_name
      assigned_to_last_name
    }
  }
`;

export const GET_CALL_DATA = gql`
  query($customer_id: Int, $callIDS: String) {
    GetCallData(customer_id: $customer_id, callIDS: $callIDS) {
      id
      formatted_customer_name
      formatted_customer_phone_number
      formatted_duration
      start_time
      formatted_call_type
      formatted_tracking_source
      note
      recording
      recording_player
      value
      call_summary 
    }
  }
`;

export const GET_FORM_DATA = gql`
  query($customer_id: Int, $formIDS: String) {
    GetFormData(customer_id: $customer_id, formIDS: $formIDS) {
      id
      submitted_at
      formatted_customer_name
      formatted_customer_phone_number
      source
      form_url
      landing_page_url
      form_data
      note
      value
    }
  }
`;

export const CRM_CUSTOMERS = gql`
  query($customer_id: Int, $count: Int, $address: String, $assigned_to: String, $name: String, $phone: String, $status: Int, $industry: String, $order_by: String, $rows_per_page: Int, $page: Int, $id: Int, $search: Int, $lead_status: String, $customer_ids: [Int], $job_ids: [Int], $gallery_ids: [Int], $document_ids: [Int], $estimate_ids: [Int], $proposal_ids: [Int], $contract_ids: [Int], $ticket_ids: [Int]) {
    CRMCustomers(customer_id: $customer_id, count: $count, address: $address, assigned_to: $assigned_to, name: $name, phone: $phone, status: $status, industry: $industry, order_by: $order_by, rows_per_page: $rows_per_page, page: $page, id: $id, search: $search, lead_status: $lead_status, customer_ids: $customer_ids, job_ids: $job_ids, gallery_ids: $gallery_ids, document_ids: $document_ids, estimate_ids: $estimate_ids, proposal_ids: $proposal_ids, contract_ids: $contract_ids, ticket_ids: $ticket_ids) {
      id
      office_phone_number
      office_address_line_one
      office_address_line_two
      office_city
      office_state
      office_zip
      office_country
      business_name
      update_time
      status
      website
      profile_image
      first_name
      last_name
      customer_status
      industry
      revenue
      sic_code
      naics_code
      employees
      established
      lead_status
      tagline
      business_description
      owner_first_name
      owner_last_name
      nowalkins
      income_targeted      
      ages_targeted
      target_parent
      why_buy_from_you
      accepted_payments
      gender_targeted
      sunday_open
      sunday_close
      monday_open
      monday_close
      tuesday_open
      tuesday_close
      wednesday_open
      wednesday_close
      thursday_open
      thursday_close
      friday_open
      friday_close
      saturday_open
      saturday_close
      timezone
      round_the_clock_support
      general_liability_insurance_form_url
      referrer_id
      assigned_to
      count
      assigned_to_first_name
      assigned_to_last_name
      representative_id
    }
  }
`;


export const CREATE_WATER_BEAR_MARKETING_CUSTOMERS = gql`
  mutation($customer_id: Int, $BusinessData: CRMCustomerinput, $crm_onboarding_id: Int) {
    createWaterBearMarketingCustomer(
      customer_id: $customer_id
      BusinessData: $BusinessData
      crm_onboarding_id: $crm_onboarding_id
    ) 
  }
`;

export const CREATE_WATER_BEAR_MARKETING_USER = gql`
  mutation($customer_id: Int, $customerEmail: CustomerEmailInput) {
    createWaterBearMarketingUser(
      customer_id: $customer_id
      customerEmail: $customerEmail
    ) 
  }
`;


export const UPDATE_CRM_CUSTOMERS = gql`
  mutation($customer_id: Int, $BusinessData: CRMCustomerinput) {
    updateCRMCustomers(
      customer_id: $customer_id
      BusinessData: $BusinessData
    ) {
      id
      office_phone_number
      office_address_line_one
      office_address_line_two
      office_city
      office_state
      office_zip
      office_country
      business_name
      profile_image
      customer_status
      website
      industry
      revenue
      sic_code
      naics_code
      employees
      established
      representative_id
      status
      tagline
      business_description
      owner_first_name
      owner_last_name
      nowalkins
      income_targeted      
      ages_targeted
      target_parent
      why_buy_from_you
      gender_targeted
      accepted_payments
      general_liability_insurance_form_url
      referrer_id
      assigned_to
      created
      update_status
    }
  }
`;

export const UPDATE_CRM_ACTIVITY = gql`
  mutation($customer_id: Int, $ActivityData: CRMActivityinput) {
    updateCRMActivity(
      customer_id: $customer_id
      ActivityData: $ActivityData
    ) {
      id
      representative_id
      activity_type
      activity_description
      activity_notes
      activity_date
      activity_recurring
      activity_frequency
      activity_completed
      activity_completed_date
      activity_completed_notes
      people_id
      crm_customers_id
      proposal_id
      job_id
      contract_id
      assigned_to
      users_id
      document_id
      photo_gallery_id
      estimate_id
      onboarding_id
    }
  }
`;

export const UPDATE_ONBOARDING_TABLE_DETAILS = gql`
  mutation($customer_id: Int, $crm_onboarding_id: Int, $onboarding_email_sent: Int, $customer_saved_onboarding: Int) {
    UpdateOnboardingTableDetails(
      customer_id: $customer_id
      crm_onboarding_id: $crm_onboarding_id
      onboarding_email_sent: $onboarding_email_sent
      customer_saved_onboarding: $customer_saved_onboarding
    )
  }
`;

export const CREATE_CUSTOMER_PHOTOS_AND_LOGOS_GALLERY = gql`
  mutation($customer_id: Int, $crm_onboarding_id: Int, $GalleryData: CRMPhotoinput) {
    createCustomerPhotosAndLogosGallery(
      customer_id: $customer_id
      crm_onboarding_id: $crm_onboarding_id
      GalleryData: $GalleryData
    ) {
      id
      photo_gallery_name
      photo_gallery_description
      photo_gallery_alt_text
      photo_gallery_url
      crm_customers_id
    }
  }
`;

export const CREATE_CUSTOMER_WEBSITE_ACCESS = gql`
  mutation($customer_id: Int, $crm_onboarding_id: Int, $WebsiteAccess: WebsiteAccessinput) {
    createCustomerWebsiteAccess(
      customer_id: $customer_id
      crm_onboarding_id: $crm_onboarding_id
      WebsiteAccess: $WebsiteAccess
    )
  }
`;

export const CREATE_CUSTOMER_WEBSITE_DESIGN = gql`
  mutation($customer_id: Int, $crm_onboarding_id: Int, $WebsiteDesign: WebsiteDesigninput) {
    createCustomerWebsiteDesign(
      customer_id: $customer_id
      crm_onboarding_id: $crm_onboarding_id
      WebsiteDesign: $WebsiteDesign
    )
  }
`;

export const CREATE_CRM_GALLERY = gql`
  mutation($customer_id: Int, $GalleryData: CRMPhotoinput) {
    createCRMGallery(
      customer_id: $customer_id
      GalleryData: $GalleryData
    ) {
      id
      photo_gallery_name
      photo_gallery_description
      photo_gallery_alt_text
      photo_gallery_url
      people_id
      crm_customers_id
      representative_id
      proposal_id
      contract_id
      job_id 
      photo_gallery_upload_date
      photo_gallery_last_updated
      document_id
      assigned_to
    }
  }
`;

export const CREATE_CRM_JOB = gql`
  mutation($customer_id: Int, $JobData: CRMJobinput) {
    createCRMJob(
      customer_id: $customer_id
      JobData: $JobData
    ) {
      id
      job_scheduled_date
      job_start_date
      job_end_date
      job_rescheduled
      job_rescheduled_date
      job_completed
      people_id
      representative_id
      contract_id
      crm_customers_id
      proposal_id
      photo_gallery_id
      document_id
      job_recurring
      job_frequency
      job_completed_date
      job_completed_notes
      job_status
      job_value
      assigned_to
      update_status
    }
  }
`;

export const CREATE_CRM_PROPOSAL = gql`
  mutation($customer_id: Int, $ProposalData: CRMProposalinput) {
    createCRMProposal(
      customer_id: $customer_id
      ProposalData: $ProposalData
    ) {
      id
      proposal_status
      proposal_date
      proposal_update_date
      people_id
      representative_id
      contract_id
      crm_customers_id
      proposal_reason
      proposal_value
      proposal_estimated_close_date
      proposal_start_date
      proposal_end_date
      proposal_new_customer
      proposal_name
      proposal_url
      document_id
      job_id
      photo_gallery_id
      update_status
    }
  }
`;

export const CREATE_CRM_PROPOSAL_FROM_ESTIMATE = gql`
  mutation($customer_id: Int, $estimate_id: Int) {
    createCRMProposalFromEstimate(
      customer_id: $customer_id
      estimate_id: $estimate_id
    )
  }
`;

export const CREATE_CRM_CONTRACT_FROM_PROPOSAL = gql`
  mutation($customer_id: Int, $proposal_id: Int) {
    createCRMContractFromProposal(
      customer_id: $customer_id
      proposal_id: $proposal_id
    )
  }
`;

export const CREATE_TICKET = gql`
  mutation($customer_id: Int, $TicketData: CRMTicketinput) {
    createCRMTicket(
      customer_id: $customer_id
      TicketData: $TicketData
    ) {
      id
      name
      type
      cc_users
      file_url
      status
      owner_id
      priority
      people_id
      crm_customers_id
      proposal_id
      job_id      
      contract_id
      photo_gallery_id
      services
      products      
      assigned_to
      requested_completion_date
      completed_date
      estimate_id
      document_id
      created_time
      updated_time
    }
  }
`;

export const CREATE_CRM_ESTIMATE = gql`
  mutation($customer_id: Int, $EstimateData: CRMEstimateinput) {
    createCRMEstimate(
      customer_id: $customer_id
      EstimateData: $EstimateData
    ) {
      id
      representative_id
      people_id
      crm_customers_id
      proposal_id
      job_id
      estimate_name
      estimate_email_text
      contract_id
      photo_gallery_id
      estimate_status
      services
      products
      assigned_to
      first_name
      last_name
      estimate_needed_by_date
      keyword_ideas
      ideal_customer
      estimate_ad_budget
      website
      industry
      additional_comments
      update_status
    }
  }
`;

export const CREATE_ONBOARDING_FORM_RECORD = gql`
  mutation($customer_id: Int, $contract_id: Int, $crm_customers_id: Int, $people_id: Int, $estimate_id: Int) {
    createOnboardingFormRecord(
      customer_id: $customer_id
      contract_id: $contract_id
      crm_customers_id: $crm_customers_id
      people_id: $people_id
      estimate_id: $estimate_id
    )
  }
`;

export const CREATE_CRM_CONTRACT = gql`
  mutation($customer_id: Int, $ContractData: CRMContractinput) {
    createCRMContract(
      customer_id: $customer_id
      ContractData: $ContractData
    ) {
      id
      representative_id
      people_id
      crm_customers_id
      proposal_id
      job_id
      estimate_id
      contract_name
      contract_url
      contract_start_date
      contract_end_date
      contract_completed
      document_id
      contract_value
      photo_gallery_id 
      contract_status
      update_status
    }
  }
`;

export const CREATE_CRM_DOCUMENT = gql`
  mutation($customer_id: Int, $DocumentData: CRMDocumentinput) {
    createCRMDocument(
      customer_id: $customer_id
      DocumentData: $DocumentData
    ) {
      id
      documents_status
      documents_name
      documents_date
      documents_update_date
      representative_id
      documents_url
      contract_id
      job_id
      photo_gallery_id
      people_id
      crm_customers_id
      proposal_id
      assigned_to
    }
  }
`;

export const LIST_ACTIVITY = gql`
  query($customer_id: Int, $crm_customer_id: Int, $people_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $type: String, $description: String, $representative: String, $job_id: Int, $filterCompletable: Int, $completed: Int, $people_ids: [Int]) {
    ListActivity(customer_id: $customer_id, crm_customer_id: $crm_customer_id, people_id: $people_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, type: $type, description: $description, representative: $representative, job_id: $job_id, filterCompletable: $filterCompletable, completed: $completed, people_ids: $people_ids) {
      id
      representative_id
      activity_type
      activity_description
      activity_notes
      activity_date
      activity_recurring
      activity_frequency
      activity_completed
      activity_completed_date
      activity_completed_notes
      people_id
      crm_customers_id
      proposal_id
      job_id
      contract_id
      document_id
      photo_gallery_id
      users_id
      assigned_to
      estimate_id
      ticket_id
      onboarding_id
      count
    }
  }
`;

export const LIST_PROPOSAL = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $value: String, $status: String, $start: String, $end: String, $proposal_estimated_close_date: String, $assignedto: String, $job_id: Int, $search: Int, $customer_ids: [Int], $people_ids: [Int], $job_ids: [Int], $proposal_job_ids: [Int], $proposal_gallery_ids: [Int], $gallery_ids: [Int], $proposal_document_ids: [Int], $document_ids: [Int], $proposal_estimate_ids: [Int], $estimate_ids: [Int], $proposal_contract_ids: [Int], $contact_ids: [Int], $ticket_ids: [Int]) {
    ListProposal(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, value: $value, status: $status, start: $start, end: $end, proposal_estimated_close_date: $proposal_estimated_close_date, assignedto: $assignedto, job_id: $job_id, search: $search, customer_ids: $customer_ids, people_ids: $people_ids, job_ids: $job_ids, proposal_job_ids: $proposal_job_ids, proposal_gallery_ids: $proposal_gallery_ids, gallery_ids: $gallery_ids, proposal_document_ids: $proposal_document_ids, document_ids: $document_ids, proposal_estimate_ids: $proposal_estimate_ids, estimate_ids: $estimate_ids, proposal_contract_ids: $proposal_contract_ids, contact_ids: $contact_ids, ticket_ids: $ticket_ids) {
      id
      proposal_status
      proposal_date
      proposal_update_date
      people_id
      representative_id
      contract_id
      crm_customers_id
      proposal_reason
      proposal_value
      proposal_estimated_close_date
      proposal_start_date
      proposal_end_date
      proposal_new_customer
      proposal_name
      proposal_url
      document_id
      job_id
      photo_gallery_id
      products
      services
      assigned_to
      first_name
      last_name
      estimate_id
      count
    }
  }
`;

export const GET_ONBOARDING_ID = gql`
  query($customer_id: Int, $crm_customer_id: Int, $contract_id: Int) {
    GetOnboardingID(customer_id: $customer_id, crm_customer_id: $crm_customer_id, contract_id: $contract_id) 
  }
`;

export const LIST_PRODUCT = gql`
  query($customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $category: String, $SKU: String, $price: String, $search: Int) {
    ListProduct(customer_id: $customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, category: $category, SKU: $SKU, price: $price, search: $search) {
      id
      product_name
      product_description
      product_cost
      product_margin
      product_price
      product_discount
      product_sale_price
      product_tax
      product_taxable
      product_billing_type
      product_billing_frequency
      product_unit
      product_image
      product_SKU
      product_category
      product_stock_status
      product_date_added
      product_date_last_updated
      category_text
      budget_cycles_product_id
    }
  }
`;

export const LIST_TICKET_PRIORITIES = gql`
  query($customer_id: Int) {
    ListTicketPriorities(customer_id: $customer_id) {
      id
      priority
    }
  }
`;

export const LIST_TICKETS = gql`
  query($customer_id: Int, $ticket_id: Int, $order_by: String, $page: Int, $rows_per_page: Int, $name: String, $BusinessName: String, $priority: String, $type: String, $assignedto: String, $assignedby: String, $status: String, $count: Int, $search: Int, $completed: Int, $customer_ids: [Int], $people_ids: [Int], $ticket_job_ids: [Int], $ticket_gallery_ids: [Int], $ticket_document_ids: [Int], $ticket_proposal_ids: [Int], $ticket_contract_ids: [Int]) {
    ListTickets(customer_id: $customer_id, ticket_id: $ticket_id, order_by: $order_by, page: $page, rows_per_page: $rows_per_page, name: $name, BusinessName: $BusinessName, priority: $priority, type: $type, assignedto: $assignedto, assignedby: $assignedby, status: $status, count: $count, search: $search, completed: $completed, customer_ids: $customer_ids, people_ids: $people_ids, ticket_job_ids: $ticket_job_ids, ticket_gallery_ids: $ticket_gallery_ids, ticket_document_ids: $ticket_document_ids, ticket_proposal_ids: $ticket_proposal_ids, ticket_contract_ids: $ticket_contract_ids) {
      id
      name
      type
      cc_users
      file_url
      status
      owner_id
      priority
      people_id
      crm_customers_id
      proposal_id
      job_id      
      contract_id
      photo_gallery_id
      services
      products      
      assigned_to
      requested_completion_date
      completed_date
      estimate_id
      document_id
      created_time
      updated_time
      count
      business_name
      priority_name
    }
  }
`;



export const LIST_TICKET_STATUSES = gql`
  query($customer_id: Int) {
    ListTicketStatuses(customer_id: $customer_id) {
      id
      status
    }
  }
`;

export const LIST_TICKET_NOTES = gql`
  query($customer_id: Int, $ticket_id: Int) {
    ListTicketNotes(customer_id: $customer_id, ticket_id: $ticket_id) {
      id
      notes
      user_id
      ticket_id
      created_time
    }
  }
`;

export const LIST_TICKET_TYPES = gql`
  query($customer_id: Int) {
    ListTicketTypes(customer_id: $customer_id) {
      id
      type
    }
  }
`;

export const LIST_MONTHY_BUDGETS = gql`
  query($customer_id: Int, $contract_id: Int, $estimate_id: Int, $proposal_id: Int) {
    listMonthlyBudgets(customer_id: $customer_id, contract_id: $contract_id, estimate_id: $estimate_id, proposal_id: $proposal_id) {
      product_name
      monthly_budget
      budget_cycles_product_id
      contract_id
      product_id
      proposal_id
      estimate_id
    }
  }
`;

export const LIST_SERVICES = gql`
  query($customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $category: String, $SKU: String, $price: String, $search: Int) {
    ListServices(customer_id: $customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, category: $category, SKU: $SKU, price: $price, search: $search) {
      id
      service_name
      service_description
      service_cost
      service_margin
      service_price
      service_discount
      service_sale_price
      service_tax
      service_taxable
      service_billing_type
      service_billing_frequency
      service_unit
      service_image
      service_sku
      service_category
      category_text
      service_date_added
      service_date_last_updated
      users_id
      parent_category
      service_status
    }
  }
`;

export const LIST_DOCUMENT = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $status: String, $assignedto: String, $job_id: Int, $search: Int, $customer_ids: [Int], $people_ids: [Int], $document_job_ids: [Int], $job_ids: [Int], $document_gallery_ids: [Int], $gallery_ids: [Int], $document_proposal_ids: [Int], $proposal_ids: [Int], $document_contract_ids: [Int], $contact_ids: [Int], $ticket_ids: [Int]) {
    ListDocuments(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, status: $status, assignedto: $assignedto, job_id: $job_id, search: $search, customer_ids: $customer_ids, people_ids: $people_ids, document_job_ids: $document_job_ids, job_ids: $job_ids, document_gallery_ids: $document_gallery_ids, gallery_ids: $gallery_ids, document_proposal_ids: $document_proposal_ids, proposal_ids: $proposal_ids, document_contract_ids: $document_contract_ids, contact_ids: $contact_ids, ticket_ids: $ticket_ids) {
      id
      documents_status
      documents_name
      documents_date
      documents_update_date
      representative_id
      documents_url
      contract_id
      job_id
      photo_gallery_id
      people_id
      crm_customers_id
      proposal_id
      assigned_to
      first_name
      last_name
      count
    }
  }
`;

export const LIST_CONTRACTS = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $value: String, $status: String, $start: String, $end: String, $assignedto: String, $job_id: Int, $search: Int, $customer_ids: [Int], $people_ids: [Int], $job_ids: [Int], $contract_job_ids: [Int], $contract_gallery_ids: [Int], $gallery_ids: [Int], $contract_document_ids: [Int], $document_ids: [Int], $contract_estimate_ids: [Int], $estimate_ids: [Int], $contract_proposal_ids: [Int], $proposal_ids: [Int], $ticket_ids: [Int]) {
    ListContract(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, value: $value, status: $status, start: $start, end: $end, assignedto: $assignedto, job_id: $job_id, search: $search, customer_ids: $customer_ids, people_ids: $people_ids, job_ids: $job_ids, contract_job_ids: $contract_job_ids, contract_gallery_ids: $contract_gallery_ids, gallery_ids: $gallery_ids, contract_document_ids: $contract_document_ids, document_ids: $document_ids, contract_estimate_ids: $contract_estimate_ids, estimate_ids: $estimate_ids, contract_proposal_ids: $contract_proposal_ids, proposal_ids: $proposal_ids, ticket_ids: $ticket_ids) {
      id
      representative_id
      people_id
      crm_customers_id
      proposal_id
      job_id
      estimate_id
      contract_name
      contract_url
      contract_start_date
      contract_end_date
      contract_completed
      document_id
      contract_value
      photo_gallery_id 
      contract_status
      products
      services
      assigned_to
      first_name
      last_name
      count
    }
  }
`;

export const LIST_ESTIMATES = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $adbudget: String, $status: String, $assignedto: String, $search: Int, $customer_ids: [Int], $people_ids: [Int], $estimate_job_ids: [Int], $job_ids: [Int], $estimate_gallery_ids: [Int], $gallery_ids: [Int], $estimate_proposal_ids: [Int], $proposal_ids: [Int], $estimate_contract_ids: [Int], $contact_ids: [Int], $ticket_ids: [Int]) {
    ListEstimates(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, adbudget: $adbudget, status: $status, assignedto: $assignedto, search: $search, customer_ids: $customer_ids, people_ids: $people_ids, estimate_job_ids: $estimate_job_ids, job_ids: $job_ids, estimate_gallery_ids: $estimate_gallery_ids, gallery_ids: $gallery_ids, estimate_proposal_ids: $estimate_proposal_ids, proposal_ids: $proposal_ids, estimate_contract_ids: $estimate_contract_ids, contact_ids: $contact_ids, ticket_ids: $ticket_ids) {
      id
      representative_id
      people_id
      crm_customers_id
      proposal_id
      job_id
      estimate_name
      estimate_email_text
      contract_id
      photo_gallery_id
      estimate_status
      services
      products
      assigned_to
      first_name
      last_name
      estimate_needed_by_date
      keyword_ideas
      ideal_customer
      estimate_ad_budget
      website
      industry
      additional_comments
      count
    }
  }
`;

export const LIST_JOBS = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $assignedto: String, $name: String, $search: Int, $value: String, $customer_ids: [Int], $people_ids: [Int], $job_gallery_ids: [Int], $gallery_ids: [Int], $job_document_ids: [Int], $document_ids: [Int], $estimate_ids: [Int], $job_contract_ids: [Int], $contact_ids: [Int], $ticket_ids: [Int]) {
    ListJobs(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, assignedto: $assignedto, name: $name, search: $search, value: $value, customer_ids: $customer_ids, people_ids: $people_ids, job_gallery_ids: $job_gallery_ids, gallery_ids: $gallery_ids, job_document_ids: $job_document_ids, document_ids: $document_ids, estimate_ids: $estimate_ids, job_contract_ids: $job_contract_ids, contact_ids: $contact_ids, ticket_ids: $ticket_ids) {
      id
      job_name
      job_scheduled_date
      job_start_date
      job_end_date
      job_rescheduled
      job_rescheduled_date
      job_completed
      people_id
      representative_id
      contract_id
      crm_customers_id
      proposal_id
      photo_gallery_id
      document_id
      job_recurring
      job_frequency
      job_completed_date
      job_completed_notes
      job_status
      job_value
      services
      products
      assigned_to
      first_name
      last_name
      count
    }
  }
`;

export const LIST_PHOTOS = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $description: String, $representative: String, $photo_gallery_id: Int, $search: Int, $customer_ids: [Int]) {
    ListPhotos(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, description: $description, representative: $representative, photo_gallery_id: $photo_gallery_id, search: $search, customer_ids: $customer_ids) {
      id
      photo_name
      photo_description
      photo_alt_text
      photo_url
      photo_upload_date
      photo_gallery_id
      crm_customers_id
    }
  }
`;

export const LIST_BRAND_PHOTOS_AND_LOGOS = gql`
  query($customer_id: Int, $crm_customer_id: Int, $photo_gallery_id: Int, $crm_onboarding_id: Int) {
    ListBrandPhotosAndLogos(customer_id: $customer_id, crm_customer_id: $crm_customer_id, photo_gallery_id: $photo_gallery_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      photo_name
      photo_description
      photo_alt_text
      photo_url
      photo_upload_date
      photo_gallery_id
      crm_customers_id
    }
  }
`;

export const LIST_WEBSITE_ACCESS = gql`
  query($customer_id: Int, $crm_onboarding_id: Int) {
    ListWebsiteAccess(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      domain_provider
      domain_username
      domain_password
      website_username
      website_password
      social_email_account
      social_email_password
    }
  }
`;

export const LIST_CUSTOMER_WEBSITE_DESIGN = gql`
  query($customer_id: Int, $crm_onboarding_id: Int) {
    ListCustomerWebsiteDesign(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      customer_id
      form_submission_email
      video_style
      website_style
      writing_style
      website_easter_eggs
    }
  }
`;

export const LIST_ONBOARDING_TABLE_DETAILS = gql`
  query($customer_id: Int, $crm_onboarding_id: Int) {
    ListOnboardingTableDetails(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      onboarding_id
      customer_id
      crm_customer_id
      contract_id
      onboarding_email_sent
      customer_saved_onboarding
    }
  }
`;

export const LIST_BRANDS_COLORS_AND_FONTS = gql`
  query($customer_id: Int, $crm_onboarding_id: Int) {
    ListBrandColorsAndFonts(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      customer_id
      company_primary_font
      company_secondary_font
      primary_company_color
      secondary_company_color
      onboarding_id
    }
  }
`;

export const LIST_PHOTO_GALLERY = gql`
  query($customer_id: Int, $crm_customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $description: String, $assignedto: String, $job_id: Int, $search: Int, $customer_ids: [Int], $people_ids: [Int], $gallery_job_ids: [Int], $job_ids: [Int], $gallery_document_ids: [Int], $document_ids: [Int], $estimate_ids: [Int], $gallery_proposal_ids: [Int], $proposal_ids: [Int], $gallery_contract_ids: [Int], $contact_ids: [Int], $ticket_ids: [Int]) {
    ListPhotoGallery(customer_id: $customer_id, crm_customer_id: $crm_customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, description: $description, assignedto: $assignedto, job_id: $job_id, search: $search, customer_ids: $customer_ids, people_ids: $people_ids, gallery_job_ids: $gallery_job_ids, job_ids: $job_ids, gallery_document_ids: $gallery_document_ids, document_ids: $document_ids, estimate_ids: $estimate_ids, gallery_proposal_ids: $gallery_proposal_ids, proposal_ids: $proposal_ids, gallery_contract_ids: $gallery_contract_ids, contact_ids: $contact_ids, ticket_ids: $ticket_ids) {
      id
      photo_gallery_name
      photo_gallery_description
      photo_gallery_alt_text
      photo_gallery_url
      people_id
      crm_customers_id
      representative_id
      proposal_id
      contract_id
      job_id 
      photo_gallery_upload_date
      photo_gallery_last_updated
      document_id
      assigned_to
      first_name
      last_name
      count
    }
  }
`;

export const LIST_CUSTOMER_REPRESENTATIVES = gql`
  query($customer_id: Int, $count: Int, $page: Int, $rows_per_page: Int, $order_by: String, $name: String, $title: String, $email: String, $cell: String, $bio: String, $rating: String, $officephone: String, $officeext: String, $search: Int) {
    listCustomerRepresentatives(customer_id: $customer_id, count: $count, page: $page, rows_per_page: $rows_per_page, order_by: $order_by, name: $name, title: $title, email: $email, cell: $cell, bio: $bio, rating: $rating, officephone: $officephone, officeext: $officeext, search: $search) {
      id, 
      first_name, 
      last_name, 
      email, 
      cell_phone_number, 
      bio, 
      office_phone_number, 
      office_extension, 
      job_title, 
      profile_image, 
      rating
    }
  }
`;

export const UPDATE_CUSTOMER_REPRESENTATIVES = gql`
  mutation($customer_id: Int, $CustomerRepresentative: CustomerRepresentativeInput) {
    UpdateCustomerRepresentatives(customer_id: $customer_id, CustomerRepresentative: $CustomerRepresentative) {
      id, 
      first_name, 
      last_name, 
      email, 
      cell_phone_number, 
      bio, 
      office_phone_number, 
      office_extension, 
      job_title, 
      profile_image, 
      rating
    }
  }
`;

export const DELETE_CUSTOMER_REPRESENTATIVES = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteCustomerRepresentatives(customer_id: $customer_id, id: $id)
  }
`;

export const UPDATE_CUSTOMER_EMAIL = gql`
  mutation($customer_id: Int, $customerEmail: CustomerEmailInput, $TrackedLead: Int) {
    updateCustomerEmail(
      customer_id: $customer_id
      customerEmail: $customerEmail
      TrackedLead: $TrackedLead
    ) {
      id
      first_name
      last_name
      email
      list_name
      cell_phone_number
      opt_int_email
      opt_in_text
      opt_in_voice
      profile_image
      contact_business_only
      lead_quality
      lead_type
      lead_status
      sale_value
      lifetime_value
      notes
      first_contacted
      last_contacted
      job_title
      office_email
      office_phone_number
      office_extension
      crm_customers_id
      crm_representative_id
      address_line_one
      address_line_two
      city
      zip_code
      state
      country
      unsubscribe
      products
      categories
      referrer_id
      assigned_to
      created
      update_status
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation($customer_id: Int, $Product: CRMProductinput) {
    updateProduct(
      customer_id: $customer_id
      Product: $Product
    ) {
      id
      product_name
      product_description
      product_cost
      product_margin
      product_price
      product_discount
      product_sale_price
      product_tax
      product_taxable
      product_billing_type
      product_billing_frequency
      product_unit
      product_image
      product_SKU
      product_category
      product_stock_status
      product_date_added
      product_date_last_updated
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation($customer_id: Int, $Service: CRMServiceinput) {
    updateService(
      customer_id: $customer_id
      Service: $Service
    ) {
      id
      service_name
      service_description
      service_cost
      service_margin
      service_price
      service_discount
      service_sale_price
      service_tax
      service_taxable
      service_billing_type
      service_billing_frequency
      service_unit
      service_image
      service_sku
      category_text
      service_date_added
      service_date_last_updated
      users_id
      parent_category
      service_status
    }
  }
`;

export const UPDATE_FOLLOWING = gql`
  mutation($customer_id: Int!, $object_id: Int, $object_type: String, $add: Int, $id: Int) {
    updateCRMFollowing(customer_id: $customer_id, object_id: $object_id, object_type: $object_type, add: $add, id: $id)
  }
`;

export const UPDATE_CRM_PHOTO = gql`
  mutation($customer_id: Int, $id: Int, $photo_name: String, $photo_description: String, $photo_alt_text: String) {
    updateCRMPhoto(customer_id: $customer_id, id: $id, photo_name: $photo_name, photo_description: $photo_description, photo_alt_text: $photo_alt_text)
  }
`;

export const UPLOAD_CRM_CUSTOMERS = gql`
  mutation($customer_id: Int, $leadsUpload: Int, $CRMcustomers: [CRMCustomerinput]) {
    uploadCRMCustomers(
      customer_id: $customer_id
      leadsUpload: $leadsUpload
      CRMcustomers: $CRMcustomers
    ) {
      count
      inserted
    }
  }
`;

export const UPLOAD_CUSTOMER_EMAILS = gql`
  mutation($customer_id: Int, $customerEmails: [CustomerEmailInput]) {
    uploadCustomerEmails(
      customer_id: $customer_id
      customerEmails: $customerEmails
    ) {
      count
      inserted
    }
  }
`;

export const UPLOAD_OVERRIDE_CUSTOMER_EMAILS = gql`
  mutation($customer_id: Int) {
    uploadOverrideCustomerEmails(
      customer_id: $customer_id
    )
  }
`;

export const UPLOAD_OVERRIDE_CRM_CUSTOMERS = gql`
  mutation($customer_id: Int, $leadsUpload: Int) {
    uploadOverrideCRMCustomers(
      customer_id: $customer_id
      leadsUpload: $leadsUpload
    ) {
      count
      inserted
    }
  }
`;

export const COMMIT_CUSTOMER_EMAILS = gql`
  mutation($customer_id: Int) {
    commitCustomerEmailsWithoutDuplicates(
      customer_id: $customer_id
    )
  }
`;

export const DELETE_STAGING_CUSTOMER_EMAILS = gql`
  mutation($customer_id: Int) {
    deleteStagingCustomerEmails(
      customer_id: $customer_id
    )
  }
`;

export const DELETE_STAGING_CRM_CUSTOMERS = gql`
  mutation($customer_id: Int) {
    deleteStagingCRMCustomers(
      customer_id: $customer_id
    )
  }
`;

export const COMMIT_CRM_CUSTOMERS = gql`
  mutation($customer_id: Int, $leadsUpload: Int) {
    commitCRMCustomersWithoutDuplicates(
      customer_id: $customer_id
      leadsUpload: $leadsUpload
    )
  }
`;

export const GET_STAGING_DUPLICATES_CRM_CUSTOMERS = gql`
  query($customer_id: Int) {
    getStagingDuplicatesCRMCustomers(
      customer_id: $customer_id
    )
    {
      id
      office_phone_number
      office_address_line_one
      office_address_line_two
      office_city
      office_state
      office_zip
      office_country
      business_name
      update_time
      representative_id
      status
      website
      profile_image
      first_name
      last_name
      customer_status
      industry
      revenue
      sic_code
      naics_code
      employees
      established
    }
  }
`;

export const GET_STAGING_DUPLICATES = gql`
  query($customer_id: Int) {
    getStagingDuplicates(
      customer_id: $customer_id
    )
    {
      id
      first_name
      last_name
      email
      list_name
      cell_phone_number
      opt_int_email
      opt_in_text
      opt_in_voice
      profile_image
      contact_business_only
      lead_quality
      lead_type
      lead_status
      sale_value
      lifetime_value
      notes
      first_contacted
      last_contacted
      job_title
      office_email
      office_phone_number
      office_extension
      crm_customers_id
      crm_representative_id
      address_line_one
      address_line_two
      city
      zip_code
      state
      country
      unsubscribe
      products
      categories
      office_phone_number
      office_address_line_one
      office_address_line_two
      office_city
      office_state
      office_zip
      office_country
      business_name
      status
      website
      profile_image
      first_name
      last_name
      customer_status
      industry
      revenue
      sic_code
      naics_code
      employees
      established
    }
  }
`;

export const DELETE_PERSON = gql`
  mutation($customer_id: Int, $id: Int) {
    deletePerson(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_PRODUCT = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteProduct(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_PRODUCT_CATEGORY = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteProductCategory(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_ESTIMATE_STATUS = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteEstimateStatus(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_JOB_STATUS = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteJobStatus(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_CONTRACT_STATUS = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteContractStatus(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_PROPOSAL_STATUS = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteProposalStatus(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_LEAD_STATUS = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteLeadStatus(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_SERVICE_CATEGORY = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteServiceCategory(customer_id: $customer_id, id: $id)
  }
`;

export const GET_CUSTOMER_STATUS = gql`
  query getCustomerStatus($customer_id: Int!, $status: Int, $customonly: Int) {
    getCustomerStatus(customer_id: $customer_id, status: $status, customonly: $customonly) {
      id
      customer_status
      customer_status_text
    }
  }
`;

export const GET_FOLLOWING = gql`
  query getFollowing($customer_id: Int!, $object_id: Int, $object_type: String) {
    getFollowing(customer_id: $customer_id, object_id: $object_id, object_type: $object_type) 
  }
`;

export const GET_CONTRACT_STATUS = gql`
  query getContractStatus($customer_id: Int!, $customonly: Int) {
    getContractStatus(customer_id: $customer_id, customonly: $customonly) {
      id
      contract_status_text
    }
  }
`;

export const GET_JOB_STATUS = gql`
  query getJobStatus($customer_id: Int!, $customonly: Int) {
    getJobStatus(customer_id: $customer_id, customonly: $customonly) {
      id
      job_status_text
    }
  }
`;

export const GET_ESTIMATE_STATUS = gql`
  query getEstimateStatus($customer_id: Int!, $customonly: Int) {
    getEstimateStatus(customer_id: $customer_id, customonly: $customonly) {
      id
      estimate_status_text
    }
  }
`;

export const GET_SIC_CODES = gql`
  query {
    getSICCodes {
      sic_code
      description
    }
  }
`;

export const GET_NAICS_CODES = gql`
  query {
    getNAICSCodes {
      naics_code
      title
    }
  }
`;

export const GET_STATES = gql`
  query {
    getStates {
      name
    }
  }
`;

export const GET_COUNTRIES = gql`
  query {
    getCountries {
      name
    }
  }
`;

export const GET_POSTAL_CODE = gql`
  query getPostalCode($name: String) {
    getPostalCode(name: $name) {
      name
    }
  }
`;

export const GET_CITIES = gql`
  query getCity($name: String) {
    getCity(name: $name) {
      name
    }
  }
`;


export const GET_PROPOSAL_STATUS = gql`
  query getProposalStatus($customer_id: Int!) {
    getProposalStatus(customer_id: $customer_id) {
      id
      proposal_status_text
    }
  }
`;

export const ADD_SERVICE_CATEGORY = gql`
  mutation addServiceCategory(
    $customer_id: Int!
    $add_category: String!
  ) {
    addServiceCategory(
      customer_id: $customer_id
      add_category: $add_category
    )
  }
`;

export const ADD_PROPOSAL_STATUS = gql`
  mutation addProposalStatus(
    $customer_id: Int!
    $proposal_status_text: String!
  ) {
    addProposalStatus(
      customer_id: $customer_id
      proposal_status_text: $proposal_status_text
    )
  }
`;

export const ADD_TICKET_PRIORITY = gql`
  mutation addTicketPriority(
    $customer_id: Int!
    $priority: String!
  ) {
    addTicketPriority(
      customer_id: $customer_id
      priority: $priority
    )
  }
`;

export const ADD_TICKET_TYPE = gql`
  mutation addTicketType(
    $customer_id: Int!
    $type: String!
  ) {
    addTicketType(
      customer_id: $customer_id
      type: $type
    )
  }
`;

export const ADD_TICKET_STATUS = gql`
  mutation addTicketStatus(
    $customer_id: Int!
    $status: String!
  ) {
    addTicketStatus(
      customer_id: $customer_id
      status: $status
    )
  }
`;

export const ADD_CONTRACT_STATUS = gql`
  mutation addContractStatus(
    $customer_id: Int!
    $contract_status_text: String!
  ) {
    addContractStatus(
      customer_id: $customer_id
      contract_status_text: $contract_status_text
    )
  }
`;

export const ADD_ESTIMATE_STATUS = gql`
  mutation addEstimateStatus(
    $customer_id: Int!
    $estimate_status_text: String!
  ) {
    addEstimateStatus(
      customer_id: $customer_id
      estimate_status_text: $estimate_status_text
    )
  }
`;

export const ADD_JOB_STATUS = gql`
  mutation addJobStatus(
    $customer_id: Int!
    $job_status_text: String!
  ) {
    addJobStatus(
      customer_id: $customer_id
      job_status_text: $job_status_text
    )
  }
`;

export const ADD_LEAD_STATUS = gql`
  mutation addLeadStatus(
    $customer_id: Int!
    $customer_status_text: String!
    $status: Int!
  ) {
    addLeadStatus(
      customer_id: $customer_id
      customer_status_text: $customer_status_text,
      status: $status
    )
  }
`;

export const UPDATE_SERVICE_CATEGORY = gql`
  mutation UpdateServiceCategory(
    $customer_id: Int!
    $category_text: String!
    $update_category: String!
  ) {
    UpdateServiceCategory(
      customer_id: $customer_id
      category_text: $category_text
      update_category: $update_category
    )
  }
`;

export const UPDATE_CONTRACT_STATUS = gql`
  mutation UpdateContractStatus(
    $customer_id: Int!
    $contract_status_text: String!
    $update_contract_status_text: String!
  ) {
    UpdateContractStatus(
      customer_id: $customer_id
      contract_status_text: $contract_status_text
      update_contract_status_text: $update_contract_status_text
    )
  }
`;

export const UPDATE_CRM_SETTINGS = gql`
  mutation UpdateCRMSettings(
    $customer_id: Int
    $hide_jobs: Int
    $hide_products: Int
    $hide_services: Int
    $hide_gallery: Int
    $hide_documents: Int
    $hide_estimates: Int
    $hide_proposals: Int
    $hide_contracts: Int
    $hide_tickets: Int
  ) {
    UpdateCRMSettings(
      customer_id: $customer_id
      hide_jobs: $hide_jobs
      hide_products: $hide_products
      hide_services: $hide_services
      hide_gallery: $hide_gallery
      hide_documents: $hide_documents
      hide_estimates: $hide_estimates
      hide_proposals: $hide_proposals
      hide_contracts: $hide_contracts
      hide_tickets: $hide_tickets
    )
  }
`;

export const UPDATE_MUTED_NOTIFICATIONS = gql`
  mutation UpdateMutedNotifications(
    $customer_id: Int
    $user_id: Int
    $object: String
  ) {
    UpdateMutedNotifications(
      customer_id: $customer_id
      user_id: $user_id
      object: $object
    )
  }
`;

export const UPDATE_ESTIMATE_STATUS = gql`
  mutation UpdateEstimateStatus(
    $customer_id: Int!
    $estimate_status_text: String!
    $update_estimate_status_text: String!
  ) {
    UpdateEstimateStatus(
      customer_id: $customer_id
      estimate_status_text: $estimate_status_text
      update_estimate_status_text: $update_estimate_status_text
    )
  }
`;

export const UPDATE_JOB_STATUS = gql`
  mutation UpdateJobStatus(
    $customer_id: Int!
    $job_status_text: String!
    $update_job_status_text: String!
  ) {
    UpdateJobStatus(
      customer_id: $customer_id
      job_status_text: $job_status_text
      update_job_status_text: $update_job_status_text
    )
  }
`;

export const UPDATE_PROPOSAL_STATUS = gql`
  mutation UpdateProposalStatus(
    $customer_id: Int!
    $proposal_status_text: String!
    $update_proposal_status_text: String!
  ) {
    UpdateProposalStatus(
      customer_id: $customer_id
      proposal_status_text: $proposal_status_text
      update_proposal_status_text: $update_proposal_status_text
    )
  }
`;

export const UPDATE_TICKET_PRIORITY = gql`
  mutation UpdateTicketPriority(
    $customer_id: Int!
    $priority: String!
    $update_priority: String!
  ) {
    UpdateTicketPriority(
      customer_id: $customer_id
      priority: $priority
      update_priority: $update_priority
    )
  }
`;

export const UPDATE_TICKET_STATUS = gql`
  mutation UpdateTicketStatus(
    $customer_id: Int!
    $status: String!
    $update_status: String!
  ) {
    UpdateTicketStatus(
      customer_id: $customer_id
      status: $status
      update_status: $update_status
    )
  }
`;

export const UPDATE_TICKET_TYPE = gql`
  mutation UpdateTicketType(
    $customer_id: Int!
    $type: String!
    $update_type: String!
  ) {
    UpdateTicketType(
      customer_id: $customer_id
      type: $type
      update_type: $update_type
    )
  }
`;

export const UPDATE_LEAD_STATUS = gql`
  mutation UpdateLeadStatus(
    $customer_id: Int!
    $customer_status_text: String!
    $update_customer_status_text: String!
  ) {
    UpdateLeadStatus(
      customer_id: $customer_id
      customer_status_text: $customer_status_text
      update_customer_status_text: $update_customer_status_text
    )
  }
`;

export const UPDATE_PRODUCT_CATEGORY = gql`
  mutation UpdateProductCategory(
    $customer_id: Int!
    $category_text: String!
    $update_category: String!
  ) {
    UpdateProductCategory(
      customer_id: $customer_id
      category_text: $category_text
      update_category: $update_category
    )
  }
`;

export const ADD_PRODUCT_CATEGORY = gql`
  mutation addProductCategory(
    $customer_id: Int!
    $add_category: String!
  ) {
    addProductCategory(
      customer_id: $customer_id
      add_category: $add_category
    )
  }
`;

export const DELETE_PROPOSAL = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteProposal(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_ACTIVITY = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteActivity(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_CONTRACT = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteContract(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteDocument(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_GALLERY = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteGallery(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_SERVICE = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteService(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_JOB = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteJob(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_CRM_CUSTOMER = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteCRMCustomer(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_CRM_PHOTO = gql`
  mutation($customer_id: Int, $id: Int) {
    deleteCRMPhoto(customer_id: $customer_id, id: $id)
  }
`;

export const DELETE_COMPANY_PHOTO_OR_LOGO = gql`
  mutation($customer_id: Int, $id: Int, $crm_onboarding_id: Int) {
    deleteCompanyPhotoOrLogo(customer_id: $customer_id, id: $id, crm_onboarding_id: $crm_onboarding_id)
  }
`;

export const GET_TOP_COMPETITORS = gql`
  query getTopCompetitors($customer_id: Int, $crm_onboarding_id: Int, $estimate_id: Int) {
    getTopCompetitors(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, estimate_id: $estimate_id) {
      id
      customers_top_competitors
      top_competitors_url
    }
  }
`;

export const GET_TOP_PRODUCTS = gql`
  query getTopProducts($customer_id: Int, $crm_onboarding_id: Int, $estimate_id: Int) {
    getTopProducts(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, estimate_id: $estimate_id) {
      id
      product_focus
      product_id
    }
  }
`;

export const GET_NO_FOCUS_PRODUCTS = gql`
  query getNoFocusProducts($customer_id: Int, $crm_onboarding_id: Int, $estimate_id: Int) {
    getNoFocusProducts(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, estimate_id: $estimate_id) {
      id
      no_product_focus
      product_id
    }
  }
`;

export const GET_CUSTOMER_BRANDS = gql`
  query getBrands($customer_id: Int, $crm_onboarding_id: Int) {
    getBrands(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      brand
    }
  }
`;

export const GET_CERTIFICATES = gql`
  query getCertificates($customer_id: Int, $crm_onboarding_id: Int) {
    getCertificates(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      certificate
    }
  }
`;

export const GET_LICENSES = gql`
  query getLicenses($customer_id: Int, $crm_onboarding_id: Int) {
    getLicenses(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      license
    }
  }
`;

export const GET_AWARDS = gql`
  query getAwards($customer_id: Int, $crm_onboarding_id: Int) {
    getAwards(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      award
    }
  }
`;

export const GET_COUPONS = gql`
  query getCoupons($customer_id: Int, $crm_onboarding_id: Int) {
    getCoupons(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      coupon
      coupon_type
      coupon_start_date
      coupon_stop_date
      coupon_walkins
    }
  }
`;

export const GET_FAQS = gql`
  query getFAQS($customer_id: Int, $crm_onboarding_id: Int) {
    getFAQS(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      faq_question
      faq_answer
    }
  }
`;

export const GET_TARGET_INDUSTRIES = gql`
  query getTargetIndustries($customer_id: Int, $crm_onboarding_id: Int) {
    getTargetIndustries(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      target_industries
      product_id
    }
  }
`;

export const GET_TARGET_JOB_TITLES = gql`
  query getTargetJobTitles($customer_id: Int, $crm_onboarding_id: Int) {
    getTargetJobTitles(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      target_job_title
      product_id
    }
  }
`;

export const GET_TARGET_INTERESTS = gql`
  query getTargetInterests($customer_id: Int, $crm_onboarding_id: Int) {
    getTargetInterests(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      targeting_interests
      product_id
    }
  }
`;

export const GET_ONLINE_VIDEOS = gql`
  query getOnlineVideo($customer_id: Int, $crm_onboarding_id: Int) {
    getOnlineVideo(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      online_video
    }
  }
`;

export const GET_WEBSITE_INSPIRATION = gql`
  query getWebsiteInspiration($customer_id: Int, $crm_onboarding_id: Int) {
    getWebsiteInspiration(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      website
      notes
    }
  }
`;

export const GET_BLOG_TOPIC_IDEAS = gql`
  query getBlogTopicIdeas($customer_id: Int, $crm_onboarding_id: Int) {
    getBlogTopicIdeas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      blog_topic_ideas
    }
  }
`;

export const GET_LOCAL_PAGES = gql`
  query getLocalPages($customer_id: Int, $crm_onboarding_id: Int) {
    getLocalPages(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      products_or_services
      products_or_services_area
    }
  }
`;

export const GET_TRACKING_INFO = gql`
  query getTrackingInfo($customer_id: Int, $crm_onboarding_id: Int) {
    getTrackingInfo(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      tracking_description
      rings_to
    }
  }
`;

export const GET_ADDITIONAL_COLORS = gql`
  query getAdditionalColors($customer_id: Int, $crm_onboarding_id: Int) {
    getAdditionalColors(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      additional_color
    }
  }
`;

export const GET_VIDEO_INSPIRATION = gql`
  query getVideoInspiration($customer_id: Int, $crm_onboarding_id: Int) {
    getVideoInspiration(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      website
      notes
    }
  }
`;

export const GET_TARGET_AREAS = gql`
  query getTargetAreas($customer_id: Int, $crm_onboarding_id: Int, $estimate_id: Int) {
    getTargetAreas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, estimate_id: $estimate_id) {
      id
      customer_top_target_area
      customer_top_target_type
      product_id
    }
  }
`;

export const GET_SERVICE_AREAS = gql`
  query getServiceAreas($customer_id: Int, $crm_onboarding_id: Int) {
    getServiceAreas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id) {
      id
      customer_top_service_area
      customer_top_service_area_type
      product_id
    }
  }
`;



export const UPDATE_CUSTOMER_COMPETITORS = gql`
  mutation updateTopCompetitors($customer_id: Int, $crm_onboarding_id: Int, $data: [TopCompetitorsInput!]!) {
    updateTopCompetitors(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_PRODUCTS = gql`
  mutation updateTopProducts($customer_id: Int, $crm_onboarding_id: Int, $data: [TopProductsInput!]!) {
    updateTopProducts(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_NO_FOCUS_PRODUCTS = gql`
  mutation updateNoFocusProducts($customer_id: Int, $crm_onboarding_id: Int, $data: [NoFocusProductsInput!]!) {
    updateNoFocusProducts(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_BRANDS = gql`
  mutation updatebrands($customer_id: Int, $crm_onboarding_id: Int, $data: [BrandsInput!]!) {
    updatebrands(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_CERTIFICATES = gql`
  mutation updateCertificates($customer_id: Int, $crm_onboarding_id: Int, $data: [CertificateInput!]!) {
    updateCertificates(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_LICENSES = gql`
  mutation updateLicense($customer_id: Int, $crm_onboarding_id: Int, $data: [LicenseInput!]!) {
    updateLicense(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_AWARDS = gql`
  mutation updateAwards($customer_id: Int, $crm_onboarding_id: Int, $data: [AwardInput!]!) {
    updateAwards(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_COUPONS = gql`
  mutation updateCoupons($customer_id: Int, $crm_onboarding_id: Int, $data: [CouponInput!]!) {
    updateCoupons(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_FAQ = gql`
  mutation updateFAQ($customer_id: Int, $crm_onboarding_id: Int, $data: [faqInput!]!) {
    updateFAQ(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_TARGET_AREAS = gql`
  mutation updateTargetAreas($customer_id: Int, $crm_onboarding_id: Int, $data: [TargetAreasInput!]!) {
    updateTargetAreas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_SERVICE_AREAS = gql`
  mutation updateServiceAreas($customer_id: Int, $crm_onboarding_id: Int, $data: [ServiceAreasInput!]!) {
    updateServiceAreas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_TARGET_INDUSTRIES = gql`
  mutation updateTargetIndustries($customer_id: Int, $crm_onboarding_id: Int, $data: [TargetIndustryInput!]!) {
    updateTargetIndustries(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_TARGET_JOB_TITLES = gql`
  mutation updateTargetJobTitles($customer_id: Int, $crm_onboarding_id: Int, $data: [TargetJobTitleInput!]!) {
    updateTargetJobTitles(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_ONLINE_VIDEOS = gql`
  mutation updateOnlineVideos($customer_id: Int, $crm_onboarding_id: Int, $data: [OnlineVideoInput!]!) {
    updateOnlineVideos(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_WEBSITE_INSPIRATION = gql`
  mutation updateWebsiteInspiration($customer_id: Int, $crm_onboarding_id: Int, $data: [WebsiteInspirationInput!]!) {
    updateWebsiteInspiration(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_VIDEO_INSPIRATION = gql`
  mutation updateVideoInspiration($customer_id: Int, $crm_onboarding_id: Int, $data: [VideoInspirationInput!]!) {
    updateVideoInspiration(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_TRACKING_INFO = gql`
  mutation updateTrackingInfo($customer_id: Int, $crm_onboarding_id: Int, $data: [TrackingInfoInput!]!) {
    updateTrackingInfo(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_ADDITIONAL_COLORS = gql`
  mutation updateAdditionalColors($customer_id: Int, $crm_onboarding_id: Int, $data: [AdditionalColorsInput!]!) {
    updateAdditionalColors(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_BLOG_TOPIC_IDEAS = gql`
  mutation updateBlogTopicIdeas($customer_id: Int, $crm_onboarding_id: Int, $data: [BlogTopicIdeasInput!]!) {
    updateBlogTopicIdeas(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_LOCAL_PAGES = gql`
  mutation updateLocalPages($customer_id: Int, $crm_onboarding_id: Int, $data: [LocalPagesInput!]!) {
    updateLocalPages(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const UPDATE_CUSTOMER_TARGET_INTERESTS = gql`
  mutation updateTargetInterests($customer_id: Int, $crm_onboarding_id: Int, $data: [TargetingInterestsInput!]!) {
    updateTargetInterests(customer_id: $customer_id, crm_onboarding_id: $crm_onboarding_id, data: $data)
  }
`;

export const REMOVE_CUSTOMER_COMPETITOR = gql`
  mutation removeTopCompetitor($id: Int) {
    removeTopCompetitor(id: $id)
  }
`;

export const REMOVE_CUSTOMER_PRODUCT = gql`
  mutation removeTopProduct($id: Int) {
    removeTopProduct(id: $id)
  }
`;

export const REMOVE_CUSTOMER_NO_FOCUS_PRODUCT = gql`
  mutation removeNoFocusProduct($id: Int) {
    removeNoFocusProduct(id: $id)
  }
`;

export const REMOVE_TARGET_AREA = gql`
  mutation removeTargetArea($id: Int) {
    removeTargetArea(id: $id)
  }
`;