import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import OutsideClickHandler from "react-outside-click-handler";
import { noop } from "lodash";

import Input from "@mui/material/Input";
import { DateRangePicker } from "material-react-daterange-picker";
import { makeStyles } from "@mui/styles";
import { addDays, startOfWeek, endOfWeek, addWeeks, addMonths } from "date-fns";

import { SET_DATE_RANGE } from "../../redux/constants";
import { Context } from "../../redux/store";

import styles from "./styles";

const useStyles = makeStyles(styles);

const getDefaultRanges = date => [
  {
    label: "Today",
    startDate: date,
    endDate: date
  },
  {
    label: "Yesterday",
    startDate: addDays(date, -1),
    endDate: addDays(date, -1)
  },
  {
    label: "This Week",
    startDate: startOfWeek(date),
    endDate: endOfWeek(date)
  },
  {
    label: "Last 7 Days",
    startDate: addWeeks(date, -1),
    endDate: addDays(date, -1)
  },
  {
    label: "Last 30 Days",
    startDate: addMonths(date, -1),
    endDate: addDays(date, -1)
  }
];

const dateRanges = getDefaultRanges(new Date());
const initialRange = dateRanges[dateRanges.length - 1];

const DateRangeSelect = ({ onSelect, isMobile }) => {
  const [state, dispatch] = useContext(Context);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch({
      type: SET_DATE_RANGE,
      payload: initialRange
    });
  }, [dispatch]);

  const toggle = () => setOpen(!open);

  const classes = useStyles();
  const dateRange = state.customers?.dateRange;
  const dateRangeString = state.customers?.dateRangeString;

  return (
    <span className={classes.container} key={dateRange}>
      <Input
        value={`${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}`}
        onClick={toggle}
        readOnly
        className={classes.input}
      />
      <div className={isMobile ? classes.pickerPhone : classes.picker}>
        <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
          {open && (
            <DateRangePicker
              open
              onChange={range => {
                dispatch({ type: SET_DATE_RANGE, payload: range });
                toggle();
                onSelect();
              }}
              definedRanges={dateRanges}
              initialDateRange={dateRange}
              maxDate={new Date()}
            />
          )}
        </OutsideClickHandler>
      </div>
    </span>
  );
};

DateRangeSelect.propTypes = {
  onSelect: PropTypes.func,
  isMobile: PropTypes.bool
};

DateRangeSelect.defaultProps = {
  onSelect: noop
};

export default DateRangeSelect;
