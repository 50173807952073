import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";


import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";

// @mui/icons-material
// import Face from "@mui/icons-material/Face";
import Email from "@mui/icons-material/Email";
// import LockOutline from "@mui/icons-material/LockOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { LOGIN } from "queries/auth";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import infoStyles from "assets/jss/material-dashboard-pro-react/components/infoStyle";

const useStyles = makeStyles(styles);

const useInfoStyles = makeStyles(infoStyles);

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [login, { data, loading }] = useMutation(LOGIN, {
    variables: { email, password },
    onCompleted: data => {
      const tokenInfo = localStorage.getItem("tokenInfo");
      if (!tokenInfo && data?.login?.token && !data?.login?.error && data?.login?.user?.user_level === "crm_user") {
        localStorage.setItem("tokenInfo", JSON.stringify(data?.login));
        window.location.replace("/admin/activity");
      } else if (!tokenInfo && data?.login?.token && !data?.login?.error) {
        localStorage.setItem("tokenInfo", JSON.stringify(data?.login));
        window.location.replace("/admin/account-overview");
      }
    }
  });
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const infoClasses = useInfoStyles();

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://www.google.com/recaptcha/api.js?render=6LdSaN8bAAAAAAFDWmzCV9tlwNY8Xp8gAm96MEkN";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className={classes.container} style={{ width: "500px", margin: "0 auto" }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          grecaptcha.ready(function() {
            grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'submit'}).then(function(token) {
              login();
            });
          });
        }}
      >
        <Card login className={classes[cardAnimaton]}>
          <CardBody>
            {data?.login?.error && (
              <div className={infoClasses.danger} style={{ fontSize: "14pt", marginBottom: "15px", marginTop: "15px"}}><b>{data?.login?.error}</b></div>
            )}
            <CustomInput
              labelText="Email..."
              id="email"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                onChange: e => setEmail(e.target.value)
              }}
            />
            <CustomInput
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputAdornmentIcon}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                type: "password",
                autoComplete: "off",
                onChange: e => setPassword(e.target.value)
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button
              size="lg"
              block              
              data-callback='onSubmit'
              type="submit"
              fullWidth
              onClick={login}
            >
              Log In
            </Button>
          </CardFooter>
          <h6 className={classes.forgotPassword}>
            <Link to="/auth/forgot-password">Forgot your password?</Link>
          </h6>
        </Card>
      </form>
    </div>
  );
}
