import { useEffect } from "react";
import { isEqual } from "lodash";
import { useQuery } from "@apollo/client";

const HydrateSub = ({
  customer_id,
  dateRangeString,
  compareDateRange,
  gqlKey,
  queryKey,
  info,
  callback
}) => {
  let { loading, data } = useQuery(gqlKey, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = customer_id && data ? data[queryKey] || [] : [];
  let { loading: loadingCompare, data: compareData } = useQuery(gqlKey, {
    skip: !customer_id || !compareDateRange,
    variables: {
      customer_id,
      start_date: compareDateRange?.startDate,
      end_date: compareDateRange?.endDate
    }
  });
  compareData = customer_id && compareData ? compareData[queryKey] || [] : [];

  useEffect(() => {
    const newInfo = {
      loading,
      data,
      loadingCompare,
      compareData
    };
    if (!isEqual(info, newInfo)) {
      //if (newInfo.data?.length) {
      //  console.log(newInfo.data);
      //}
      callback(newInfo);
    }
  }, [loading, loadingCompare, data, compareData]);
  return null;
};

export default HydrateSub;
