import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { compact, omit } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// @mui/icons-material
import PermIdentity from "@mui/icons-material/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer";
import CloseIcon from '@mui/icons-material/Close';
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import CSVDownloader from "components/CSVDownloader";
import CSVReader from "components/CSVReader";
import CircularProgress from '@mui/material/CircularProgress';
import { READ_ME, UPDATE_ME, ADD_USER } from "queries/users";
import { LIST_CUSTOMER_USERS } from "queries/users";
import {
  UPDATE_CUSTOMER,
  LIST_CUSTOMERS,
  LIST_CUSTOMER_EMAILS,
  LIST_MUTED_NOTIFICATIONS,
  GET_CRM_SETTINGS,
  UPDATE_CRM_SETTINGS,
  ADD_PRODUCT_CATEGORY,
  GET_PRODUCT_CATEGORIES,
  DELETE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY,
  ADD_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORIES,
  DELETE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORY,
  GET_CUSTOMER_STATUS,
  ADD_LEAD_STATUS,
  UPDATE_LEAD_STATUS,
  DELETE_LEAD_STATUS,
  GET_CONTRACT_STATUS,
  ADD_CONTRACT_STATUS,
  GET_JOB_STATUS,
  GET_ESTIMATE_STATUS,
  ADD_JOB_STATUS,
  ADD_ESTIMATE_STATUS,
  UPDATE_CONTRACT_STATUS,
  UPDATE_JOB_STATUS,
  UPDATE_ESTIMATE_STATUS,
  DELETE_CONTRACT_STATUS,
  DELETE_JOB_STATUS,
  DELETE_ESTIMATE_STATUS,
  GET_PROPOSAL_STATUS,
  LIST_TICKET_PRIORITIES,
  ADD_PROPOSAL_STATUS,
  ADD_TICKET_PRIORITY,
  ADD_TICKET_TYPE,
  ADD_TICKET_STATUS,
  LIST_TICKET_TYPES,
  LIST_TICKET_STATUSES,
  UPDATE_PROPOSAL_STATUS,
  UPDATE_TICKET_PRIORITY,
  UPDATE_TICKET_TYPE,
  UPDATE_TICKET_STATUS,
  DELETE_PROPOSAL_STATUS,
  CRM_CUSTOMERS,
  UPLOAD_CRM_CUSTOMERS,
  GET_STAGING_DUPLICATES_CRM_CUSTOMERS,
  COMMIT_CRM_CUSTOMERS,
  UPLOAD_OVERRIDE_CRM_CUSTOMERS,
  DELETE_STAGING_CRM_CUSTOMERS,
  DELETE_STAGING_CUSTOMER_EMAILS,
  UPLOAD_CUSTOMER_EMAILS,
  UPLOAD_OVERRIDE_CUSTOMER_EMAILS,
  GET_STAGING_DUPLICATES,
  COMMIT_CUSTOMER_EMAILS
} from "queries/customers";

import {
  GET_LEAD_STATUS,
  ADD_LEAD_STATUS as ADD_PEOPLE_LEAD_STATUS,
  GET_LEAD_TYPE,
  DELETE_LEAD_STATUS as DELETE_PEOPLE_LEAD_STATUS,
  UPDATE_HYDRATE_CAMPAIGN
} from "queries/hydrate";

import HelpTooltip from "components/HelpTooltip";
import { Context } from "../../redux/store";

import FormatPhone from "../Dashboard/Format/FormatPhone";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const CRMSettings = () => {
  const me = useQuery(READ_ME)?.data;
  const [state] = useContext(Context);
  const [newuserSubmission, setnewuserSubmission] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [CRMSettings, setCRMSettings] = useState([]);
  const [B2B, setB2B] = useState(null);
  const [AddProductCategory, setAddProductCategory] = useState(false);
  const [UpdateProductCategory, setUpdateProductCategory] = useState(false);
  const [DeleteProductCategory, setDeleteProductCategory] = useState(false);
  const [submissionData, setSubmissionData] = useState([]);
  const [submissionPeopleLeadStatusData, setSubmissionPeopleLeadStatusData] = useState([]);
  const [LeadStatusSubmissionData, setLeadStatusSubmissionData] = useState([]);
  const [CustomerStatusSubmissionData, setCustomerStatusSubmissionData] = useState([]);
  const [ContractStatusSubmissionData, setContractStatusSubmissionData] = useState([]);
  const [JobStatusSubmissionData, setJobStatusSubmissionData] = useState([]);
  const [EstimateStatusSubmissionData, setEstimateStatusSubmissionData] = useState([]);  
  const [ProposalStatusSubmissionData, setProposalStatusSubmissionData] = useState([]);
  const [TicketPrioritySubmissionData, setTicketPrioritySubmissionData] = useState([]);
  const [TicketTypeSubmissionData, setTicketTypeSubmissionData] = useState([]);
  const [TicketStatusSubmissionData, setTicketStatusSubmissionData] = useState([]);
  const [UpdateCategory, setUpdateCategory] = useState([]);
  const [UpdateLeadStatus, setUpdateLeadStatus] = useState(false);
  const [DeleteCategory, setDeleteCategory] = useState(null);
  const [AddServiceCategory, setAddServiceCategory] = useState(false);
  const [UpdateServiceCategory, setUpdateServiceCategory] = useState(false);
  const [DeleteServiceCategory, setDeleteServiceCategory] = useState(false);
  const [AddTicketPriority, setAddTicketPriority] = useState(false);
  const [AddTicketType, setAddTicketType] = useState(false);
  const [AddTicketStatus, setAddTicketStatus] = useState(false);
  const [UpdateTicketStatus, setUpdateTicketStatus] = useState(false);
  const [UpdateTicketType, setUpdateTicketType] = useState(false);
  const [UpdateTicketPriority, setUpdateTicketPriority] = useState(false);
  const [AddLeadStatus, setAddLeadStatus] = useState(false);
  const [AddCustomerStatus, setAddCustomerStatus] = useState(false);
  const [UpdateCustomerStatus, setUpdateCustomerStatus] = useState(false);
  const [AddContractStatus, setAddContractStatus] = useState(false);
  const [AddJobStatus, setAddJobStatus] = useState(false);
  const [AddEstimateStatus, setAddEstimateStatus] = useState(false);  
  const [UpdateContractStatus, setUpdateContractStatus] = useState(false);
  const [UpdateJobStatus, setUpdateJobStatus] = useState(false);
  const [UpdateEstimateStatus, setUpdateEstimateStatus] = useState(false);  
  const [DeleteContractStatusDialog, setDeleteContractStatusDialog] = useState(false);
  const [DeleteJobStatusDialog, setDeleteJobStatusDialog] = useState(false);
  const [DeleteEstimateStatusDialog, setDeleteEstimateStatusDialog] = useState(false);  
  const [AddProposalStatus, setAddProposalStatus] = useState(false);
  const [UpdateProposalStatus, setUpdateProposalStatus] = useState(false);
  const [DeleteProposalStatusDialog, setDeleteProposalStatusDialog] = useState(false);
  const [UpdateCustomerStatusSubmissionData, setUpdateCustomerStatusSubmissionData] = useState([]);
  const [UpdateContractStatusSubmissionData, setUpdateContractStatusSubmissionData] = useState([]);
  const [UpdateJobStatusSubmissionData, setUpdateJobStatusSubmissionData] = useState([]);
  const [UpdateEstimateStatusSubmissionData, setUpdateEstimateStatusSubmissionData] = useState([]);  
  const [UpdateProposalStatusSubmissionData, setUpdateProposalStatusSubmissionData] = useState([]);
  const [UpdateTicketPrioritySubmissionData, setUpdateTicketPrioritySubmissionData] = useState([]);
  const [UpdateTicketTypeSubmissionData, setUpdateTicketTypeSubmissionData] = useState([]);
  const [UpdateTicketStatusSubmissionData, setUpdateTicketStatusSubmissionData] = useState([]);
  const [AddPersonStatus, setAddPersonStatus] = useState(false);
  const [DeletePersonStatus, setDeletePersonStatus] = useState(false);
  const [DeleteCustomerStatus, setDeleteCustomerStatus] = useState('');
  const [DeleteContractStatus, setDeleteContractStatus] = useState('');
  const [DeleteJobStatus, setDeleteJobStatus] = useState('');
  const [DeleteEstimateStatus, setDeleteEstimateStatus] = useState('');
  const [DeleteProposalStatus, setDeleteProposalStatus] = useState('');
  const [DeleteCustomerStatusDialog, setDeleteCustomerStatusDialog] = useState(false);
  const [DeleteLeadStatus, setDeleteLeadStatus] = useState(false);
  const [CategoryData, setCategoryData] = useState(null);
  const [ServiceCategoryData, setServiceCategoryData] = useState(null);
  const [LeadStatusData, setLeadStatusData] = useState(null);
  const [ContractStatusData, setContractStatusData] = useState(null);
  const [JobStatusData, setJobStatusData] = useState(null);
  const [EstimateStatusData, setEstimateStatusData] = useState(null);
  
  const [ProposalStatusData, setProposalStatusData] = useState(null);
  const [TicketPriorityData, setTicketPriorityData] = useState(null);
  const [TicketTypeData, setTicketTypeData] = useState(null);
  const [TicketStatusData, setTicketStatusData] = useState(null);
  const [CustomerStatusData, setCustomerStatusData] = useState(null);
  const [ServicesubmissionData, setServiceSubmissionData] = useState([]);
  const [ServiceUpdateCategory, setServiceUpdateCategory] = useState([]);
  const [ServiceDeleteCategory, setServiceDeleteCategory] = useState(null);
  const [deleteType, setSelectedDeleteType] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [Pagevalue, setPagevalue] = useState(0);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMLeads, setCRMLeads] = useState(null);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [addedCount, setAddedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [ShowDuplicateFile, setShowDuplicateFile] = useState(null);
  const [DuplicatePeopledata, setDuplicatePeopledata] = useState(null);
  const [DuplicatCRMCustomers, setDuplicatCRMCustomers] = useState(null);
  const [ShowDuplicateFilePeople, setShowDuplicateFilePeople] = useState(null);
  const [ShowOverrideLoading, setShowOverrideLoading] = useState(false);
  const [CheckingForDuplicates, setCheckingForDuplicates] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.readMe?.crm_user_level;
  const customer_id = state.customers?.selected?.id;

  const { data: users, refetch } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !me?.readMe?.customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id: me?.readMe?.customer_id,
      }
    });

  if (crm_user_level && crm_user_level !== "crm_admin")
    navigate("/admin/crmcustomers?customer=" + customer_id);

  const [updateMe] = useMutation(UPDATE_ME);

  const [addUser] = useMutation(ADD_USER,
    {
      onCompleted: (reponse) => {
        if (reponse?.createUser === false)
          alert("Email Address Already In Use.")
        else {
          const notify = options => {
            const key = enqueueSnackbar(options?.text, {
              variant: options?.variant,
              persist: true
            });
            setTimeout(() => {
              closeSnackbar(key);
            }, options?.delay || 2000);
          }
          notify({
            text: "Information has been successfully submitted",
            variant: "success"
          });

          setnewuserSubmission(null);
          refetch();
        }
      }
    });

  const classes = useStyles();

  let { data: customer_data, refetch: refetch_customers } = useQuery(LIST_CUSTOMERS, {
    skip: !me?.readMe?.customer_id,
    variables: { customer_id: me?.readMe?.customer_id }
  });

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  const [updateCustomer, { loading: updatingCustomer }] = useMutation(
    UPDATE_CUSTOMER,
    {
      onCompleted: () => {
        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        refetch_customers();
      }
    }
  );

  const { data: Category_Data, refetch: refetchCategories } = useQuery(GET_PRODUCT_CATEGORIES, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    }
  });

  const { data: ServiceCategory_Data, refetch: refetchServiceCategories } = useQuery(GET_SERVICE_CATEGORIES, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    }
  });

  const [deleteServiceCategoryForm] = useMutation(DELETE_SERVICE_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchServiceCategories();
    }
  });

  let { data: CustomerStatus_data, refetch: refetchCustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: LeadStatus_data, refetch: refetchLeadStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 0,
      customonly: 1
    }
  });

  let { data: ContractStatus_data, refetch: refetchContractStatus_data } = useQuery(GET_CONTRACT_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: JobStatus_data, refetch: refetchJobStatus_data } = useQuery(GET_JOB_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: EstimateStatus_data, refetch: refetchEstimateStatus_data } = useQuery(GET_ESTIMATE_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: ProposalStatus_data, refetch: refetchProposalStatus_data } = useQuery(GET_PROPOSAL_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: TicketPriority_data, refetch: refetchTicketPriority_data } = useQuery(LIST_TICKET_PRIORITIES, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: TicketType_data, refetch: refetchTicketType_data } = useQuery(LIST_TICKET_TYPES, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  let { data: TicketStatus_data, refetch: refetchTicketStatus_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1,
      customonly: 1
    }
  });

  const { data: LeadStatusPeople_data, refetch: refetchPersonLeadStatus } = useQuery(GET_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
      skip_message_to_all: false
    }
  });

  let LeadStatus_data_temp = LeadStatusPeople_data?.getHydrateLeadStatus || [];

  let defaultLeadStatuses = [];
  let defaultLeadCustomerStatuses = [];

  if (LeadStatus_data_temp) {
    LeadStatus_data_temp.forEach(obj => {
      defaultLeadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
    })

    LeadStatus_data_temp.forEach(obj => {
      if (obj.lead_status > 11)
        defaultLeadCustomerStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
    })
  }

  const { data: LeadType_data, refetch: refetchleadtypes } = useQuery(GET_LEAD_TYPE, {
    skip: !me?.readMe?.customer_id,
    variables: { customer_id: me?.readMe?.customer_id }
  });

  let LeadType_data_temp = LeadType_data?.getHydrateLeadType || [];

  let defaultLeadTypes = [];
  let defaultLeadCustomerTypes = [];

  if (LeadType_data_temp) {
    defaultLeadTypes.push({ label: "Add Custom Lead Type", value: -1 })

    LeadType_data_temp.forEach(obj => {
      defaultLeadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })

    LeadType_data_temp.forEach(obj => {
      if (obj.lead_type > 4)
        defaultLeadCustomerTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const { loading, data, refetch: crm_customers_refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !me?.readMe?.customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 1
    }
  });

  const { loading: lead_loading, data: leadsdata, refetch: crm_leads_refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !me?.readMe?.customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      status: 0
    }
  });

  const { loading: people_loading, data: people_data, refetch: people_refetch } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    }
  });

  useEffect(() => {
    setCRMLeads(leadsdata?.CRMCustomers || null);
    setCRMCustomers(data?.CRMCustomers || null);
    setCRMPeople(people_data?.listCustomerEmails || null);
    GetCRMSettings();
    setCRMAssignedToUsers(users?.listCustomerUsers || []);
    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
    setCategoryData(Category_Data?.listProductCategories || []);
    setServiceCategoryData(ServiceCategory_Data?.listServiceCategories || []);
    setCustomerStatusData(CustomerStatus_data?.getCustomerStatus || []);
    setLeadStatusData(LeadStatus_data?.getCustomerStatus || []);
    setJobStatusData(JobStatus_data?.getJobStatus || []);
    setEstimateStatusData(EstimateStatus_data?.getEstimateStatus || []);
    setContractStatusData(ContractStatus_data?.getContractStatus || []);
    setProposalStatusData(ProposalStatus_data?.getProposalStatus || []);
    setTicketPriorityData(TicketPriority_data?.ListTicketPriorities || []);
    setTicketTypeData(TicketType_data?.ListTicketTypes || []);
    setTicketStatusData(TicketStatus_data?.ListTicketStatuses || []);
  }, [users, customer_data, Category_Data, ServiceCategory_Data,
    CustomerStatus_data, LeadStatus_data, ContractStatus_data,
    ProposalStatus_data, JobStatus_data, TicketPriority_data,
    TicketType_data, TicketStatus_data, data, leadsdata,
    people_data])

  const listNames = [
    "Water Bear Marketing",
    "Employment",
    "From Website",
    "From CMS/ERP",
    "Purchased",
    "Tradeshows",
    "Database",
    "Lead Generation",
    "Walk-In",
    "Customize"
  ].map(v => ({
    label: v,
    value: v
  }));

  const leadQualities = [
    { label: "Good", value: 1 },
    { label: "Bad", value: 2 },
    { label: "Other", value: 3 }
  ];

  const [uploadCustomerEmails] = useMutation(
    UPLOAD_CUSTOMER_EMAILS);

  const [uploadOverrideCustomerEmails] = useMutation(
    UPLOAD_OVERRIDE_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      setShowDuplicateFilePeople(false);
      setShowOverrideLoading(false);
      setDuplicatePeopledata([]);
      people_refetch();
    }
  });

  const [uploadCRMCustomers] = useMutation(
    UPLOAD_CRM_CUSTOMERS);

  const [uploadOverrideCRMCustomers] = useMutation(
    UPLOAD_OVERRIDE_CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: async () => {
      setShowDuplicateFile(false);
      setShowOverrideLoading(false);
      setDuplicatCRMCustomers([]);
    }
  });

  const [deleteStagingCRMCustomers] = useMutation(
    DELETE_STAGING_CRM_CUSTOMERS
  );

  const [getStagingDuplicates] = useLazyQuery(GET_STAGING_DUPLICATES_CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStagingDuplicatesCRMCustomers }) => {
      setDuplicatCRMCustomers(getStagingDuplicatesCRMCustomers);
      if (getStagingDuplicatesCRMCustomers?.length > 0) {
        setShowDuplicateFile(true);
      }

      setCheckingForDuplicates(false);
    }
  });

  const [getStagingDuplicatesPeople] = useLazyQuery(GET_STAGING_DUPLICATES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStagingDuplicates }) => {
      setDuplicatePeopledata(getStagingDuplicates);
      if (getStagingDuplicates?.length > 0) {
        setShowDuplicateFilePeople(true);
      }

      setCheckingForDuplicates(false);
    }
  });

  const [commitCustomerEmails] = useMutation(
    COMMIT_CRM_CUSTOMERS,
    {
      onCompleted: () => {
        setUploadedCount(0);
        setAddedCount(0);
        refetch();
        people_refetch();
      }
    }
  );

  const [commitCustomerEmailsPeople] = useMutation(
    COMMIT_CUSTOMER_EMAILS,
    {
      onCompleted: () => {
        setUploadedCount(0);
        setAddedCount(0);
        people_refetch();
      }
    }
  );


  const csv_columns = compact([
    {
      Header: "Business Name",
      accessor: "business_name",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        FormatPhone(original?.office_phone_number)
    },
    {
      Header: "Business Address Line 1",
      accessor: "office_address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business Address Line 2",
      accessor: "office_address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business City",
      accessor: "office_city",
      optional: true
    },
    {
      Header: "Business Zip Code",
      accessor: "office_zip",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business State",
      accessor: "office_state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Country",
      accessor: "office_country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Industry",
      accessor: "industry",
      optional: true
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
      optional: true
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
      optional: true
    },
    {
      Header: "Established",
      accessor: "established",
      optional: true
    },
    {
      Header: "CRM Customer ID",
      accessor: "id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.id)
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.assigned_to)
    }
  ]);

  const Peoplecsv_columns = compact([
    {
      Header: "First Name*",
      accessor: "first_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.first_name}`
      )
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.last_name}`
      )
    },
    {
      Header: "Email*",
      accessor: "email"
    },
    {
      Header: "Cell*",
      accessor: "cell_phone_number",
      optional: true,
      minWidth: 150
    },
    {
      Header: "Personal Address Line 1",
      accessor: "address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal Address Line 2",
      accessor: "address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal City",
      accessor: "city",
      optional: true
    },
    {
      Header: "Personal State",
      accessor: "state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Zip Code",
      accessor: "zip_code",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Country",
      accessor: "country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Email",
      accessor: "opt_int_email",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_int_email}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Text",
      accessor: "opt_in_text",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_text}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Voice",
      accessor: "opt_in_voice",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_voice}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Contact Business Only",
      accessor: "contact_business_only",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.contact_business_only}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "List Name",
      accessor: "list_name",
      optional: true,
    },
    {
      Header: "Quality",
      accessor: "lead_quality",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadQualities.find(
          ({ value }) => value === (original?.lead_quality || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Type",
      accessor: "lead_type",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        defaultLeadTypes.find(
          ({ value }) => value === (original?.lead_type || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Status",
      accessor: "lead_status",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        defaultLeadStatuses.find(
          ({ value }) => value === (original?.lead_status || "other")
        )?.label || "Other",
      optional: true
    },
    {
      Header: "Last Sale Value",
      accessor: "sale_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Lifetime Value",
      accessor: "lifetime_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Notes",
      accessor: "notes",
      minWidth: 150,
      optional: true
    },
    {
      Header: "First Contact",
      accessor: "first_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Last Contact",
      accessor: "last_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Email",
      accessor: "office_email",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        FormatPhone(original?.office_phone_number)
    },
    {
      Header: "Business Extension",
      accessor: "office_extension",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Name",
      accessor: "business_name",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Address Line 1",
      accessor: "office_address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business Address Line 2",
      accessor: "office_address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business City",
      accessor: "office_city",
      optional: true
    },
    {
      Header: "Business Zip Code",
      accessor: "office_zip",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business State",
      accessor: "office_state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Country",
      accessor: "office_country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Unsubscribe",
      accessor: "unsubscribe",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        original?.unsubscribe && Number(original?.unsubscribe) ? "✓" : "",
      width: 100
    },
    {
      Header: "Website",
      accessor: "website",
      optional: true
    },
    {
      Header: "Industry",
      accessor: "industry",
      optional: true
    },
    {
      Header: "Revenue",
      accessor: "revenue",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
      optional: true
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
      optional: true
    },
    {
      Header: "Established",
      accessor: "established",
      optional: true
    },
    {
      Header: "CRM Customer ID",
      accessor: "crm_customers_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.crm_customers_id)
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.assigned_to)
    },
    {
      Header: "Assigned User ID",
      accessor: "users_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.users_id)
    }
  ]);

  const inputs = [
    {
      field: "business_name",
      label: "Business Name",
      optional: true
    },
    {
      field: "office_phone_number",
      label: "Business Phone",
      optional: true
    },
    {
      field: "office_address_line_one",
      label: "Business Address Line 1",
      optional: true
    },
    {
      field: "office_address_line_two",
      label: "Business Address Line 2",
      optional: true
    },
    {
      field: "office_city",
      label: "Business City",
      optional: true
    },
    {
      field: "office_state",
      label: "Business State",
      optional: true
    },
    {
      field: "office_zip",
      label: "Business Zip Code",
      optional: true
    },
    {
      field: "office_country",
      label: "Business Country",
      optional: true
    },
    {
      field: "website",
      label: "Website",
      optional: true
    },
    {
      field: "status",
      label: "Status",
      optional: true
    },
    {
      label: "Industry",
      field: "industry",
      optional: true
    },
    {
      label: "Revenue",
      field: "revenue",
      optional: true
    },
    {
      label: "SIC Code",
      field: "sic_code",
      optional: true
    },
    {
      label: "NAICS Code",
      field: "naics_code",
      optional: true
    },
    {
      label: "Employees",
      field: "employees",
      optional: true
    },
    {
      label: "Established",
      field: "established",
      optional: true
    },
    {
      label: "Business Facebook",
      field: "Business_Facebook",
      optional: true
    },
    {
      label: "Business LinkedIn",
      field: "Business_LinkedIn",
      optional: true
    },
    {
      label: "Business Twitter",
      field: "Business_Twitter",
      optional: true
    },
    {
      label: "Business Instagram",
      field: "Business_Instagram",
      optional: true
    },
    {
      label: "Business Pinterest",
      field: "Business_Pinterest",
      optional: true
    },
    {
      label: "Business Mastodon",
      field: "Business_Mastodon",
      optional: true
    },
    {
      label: "Business Telegram",
      field: "Business_Telegram",
      optional: true
    },
    {
      label: "Business Tumblr",
      field: "Business_Tumblr",
      optional: true
    },
    {
      label: "Business Subreddit",
      field: "Business_Subreddit",
      optional: true
    },
    {
      label: "Google Business Profile",
      field: "Google_Business_Profile",
      optional: true
    },
    {
      label: "Business YouTube",
      field: "Business_YouTube",
      optional: true
    },
    {
      label: "Business Tiktok",
      field: "Business_Tiktok",
      optional: true
    }
  ];

  const onCSVLoaded = async (CRMCustomersdata) => {
    CRMCustomersdata = CRMCustomersdata?.filter(CurrentCustomer =>
      CurrentCustomer?.business_name !== '')

    setTotalCount(CRMCustomersdata.length);
    setCsvUploadOpen(true);
    const size = 100;
    const upload = async (start) => {
      if (start >= CRMCustomersdata.length) {
        setCsvUploadOpen(false);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        return;
      }
      await uploadCRMCustomers({
        fetchPolicy: "no-cache",
        variables: {
          customer_id,
          CRMcustomers: CRMCustomersdata.slice(start, start + size),
          leadsUpload: Pagevalue === 1 ? 0 : 1
        }
      }).then(async ({ data: { uploadCRMCustomers: { count, inserted } } }) => {
        setUploadedCount(u => u + count);
        setAddedCount(a => a + inserted);
        await upload(start + size);
      });
    };

    // delete rows from the hydrate_customer_list_ table
    await deleteStagingCRMCustomers({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    await upload(0);

    await getStagingDuplicates({
      variables: {
        customer_id
      }
    })

    // now select staging data and compare to hydrate customer list
    // if record exists leave it in stage for duplicate csv
    // if the key is not found insert into hydrate customer list
    // and remove it from staging
    await commitCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
        leadsUpload: Pagevalue === 1 ? 0 : 1
      }
    });
  };


  const onCSVLoadedPeople = async (CustomerEmailsdata) => {
    CustomerEmailsdata = CustomerEmailsdata?.filter(CurrentCustomer =>
      CurrentCustomer?.first_name !== '')

    setTotalCount(CustomerEmailsdata.length);
    setCsvUploadOpen(true);
    const size = 100;
    const upload = async (start) => {
      if (start >= CustomerEmailsdata.length) {
        setCsvUploadOpen(false);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        return;
      }
      await uploadCustomerEmails({
        fetchPolicy: "no-cache",
        variables: {
          customer_id,
          customerEmails: CustomerEmailsdata.slice(start, start + size)
        }
      }).then(async ({ data: { uploadCustomerEmails: { count, inserted } } }) => {
        setUploadedCount(u => u + count);
        setAddedCount(a => a + inserted);
        await upload(start + size);
      });
    };

    // delete rows from the hydrate_staging_customer_list_1 table
    await deleteStagingCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    // delete rows from the hydrate_customer_list_ table
    await deleteStagingCRMCustomers({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    await upload(0);

    // now select staging data and compare to hydrate customer list
    // if record exists leave it in stage for duplicate csv
    // if the key is not found insert into hydrate customer list
    // and remove it from staging


    setShowDuplicateFilePeople(true);

    await getStagingDuplicatesPeople({
      variables: {
        customer_id
      }
    })

    await commitCustomerEmailsPeople({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });
  };

  const [updateCampaign, { loading: updatingCampaign }] = useMutation(
    UPDATE_HYDRATE_CAMPAIGN
  );

  const handleChangeAppBarPage = (event, newValue) => {
    setPagevalue(newValue);
  };

  const deleteFieldHandler = (key, convert) => e => {
    setDeleteData({
      ...deleteData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateB2BFieldHandler = (key) => e => {
    setB2B(
      e.target.checked
    );

    updateCustomer({
      variables: {
        id: me?.readMe?.customer_id,
        data: {
          B2B: e.target.checked ? 1 : 0
        }
      }
    });
  }

  const [addPeopleLeadStatusForm] = useMutation(ADD_PEOPLE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchPersonLeadStatus();
      refetchleadtypes();
    }
  });

  const [DeleteLeadStatusForm] = useMutation(DELETE_PEOPLE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchPersonLeadStatus();
      refetchleadtypes();
    }
  });

  const ServicedeleteCategory = (id) => {
    deleteServiceCategoryForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteCategory = (id) => {
    deleteCategoryForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteLeadStatus = (id) => {
    deleteLeadStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteCustomerStatus = (id) => {
    deleteCustomerStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteContactStatus = (id) => {
    deleteContractStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteJobStatus = (id) => {
    deleteJobStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteEstimateStatus = (id) => {
    deleteEstimateStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const deleteProposalStatus = (id) => {
    deleteProposalStatusForm({
      variables: {
        customer_id: me?.readMe?.customer_id,
        id: id
      }
    })
  }

  const updateCategoryFieldHandler = (key, convert) => e => {
    if (key === "category_text") {
      setUpdateCategory({
        ...UpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_category"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateCategory({
        ...UpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateCustomerStatusFieldHandler = (key, convert) => e => {
    if (key === "customer_status_text") {
      setUpdateCustomerStatusSubmissionData({
        ...UpdateCustomerStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_customer_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateCustomerStatusSubmissionData({
        ...UpdateCustomerStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };


  const updateContractStatusFieldHandler = (key, convert) => e => {
    if (key === "contract_status_text") {
      setUpdateContractStatusSubmissionData({
        ...UpdateContractStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_contract_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateContractStatusSubmissionData({
        ...UpdateContractStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateJobStatusFieldHandler = (key, convert) => e => {
    if (key === "job_status_text") {
      setUpdateJobStatusSubmissionData({
        ...UpdateJobStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_job_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateJobStatusSubmissionData({
        ...UpdateJobStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const UpdateEstimateStatusFieldHandler = (key, convert) => e => {
    if (key === "estimate_status_text") {
      setUpdateEstimateStatusSubmissionData({
        ...UpdateEstimateStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_estimate_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateEstimateStatusSubmissionData({
        ...UpdateEstimateStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateProposalStatusFieldHandler = (key, convert) => e => {
    if (key === "proposal_status_text") {
      setUpdateProposalStatusSubmissionData({
        ...UpdateProposalStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_proposal_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateProposalStatusSubmissionData({
        ...UpdateProposalStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateTicketPriorityFieldHandler = (key, convert) => e => {
    if (key === "priority") {
      setUpdateTicketPrioritySubmissionData({
        ...UpdateTicketPrioritySubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_priority"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateTicketPrioritySubmissionData({
        ...UpdateTicketPrioritySubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateTicketTypeFieldHandler = (key, convert) => e => {
    if (key === "type") {
      setUpdateTicketTypeSubmissionData({
        ...UpdateTicketTypeSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_type"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateTicketTypeSubmissionData({
        ...UpdateTicketTypeSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateTicketStatusFieldHandler = (key, convert) => e => {
    if (key === "status") {
      setUpdateTicketStatusSubmissionData({
        ...UpdateTicketStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_status"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setUpdateTicketStatusSubmissionData({
        ...UpdateTicketStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateServiceCategoryFieldHandler = (key, convert) => e => {
    if (key === "category_text") {
      setServiceUpdateCategory({
        ...ServiceUpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_category"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setServiceUpdateCategory({
        ...ServiceUpdateCategory,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const [addCategoryForm] = useMutation(ADD_PRODUCT_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setSubmissionData({});
      refetch();
      refetchCategories();
    }
  });

  const [addLeadStatusForm] = useMutation(ADD_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setLeadStatusSubmissionData({});
      refetch();
      refetchLeadStatus_data();
    }
  });

  const [addCustomerStatusForm] = useMutation(ADD_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setCustomerStatusSubmissionData({});
      refetch();
      refetchCustomerStatus_data();
    }
  });

  const [addContractStatusForm] = useMutation(ADD_CONTRACT_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setContractStatusSubmissionData({});
      refetch();
      refetchContractStatus_data();
    }
  });
  
  const [addJobStatusForm] = useMutation(ADD_JOB_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setJobStatusSubmissionData({});
      refetch();
      refetchJobStatus_data();
    }
  });

  const [addEstimateStatusForm] = useMutation(ADD_ESTIMATE_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setEstimateStatusSubmissionData({});
      refetch();
      refetchEstimateStatus_data();
    }
  }); 

  const [addProposalStatusForm] = useMutation(ADD_PROPOSAL_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setProposalStatusSubmissionData({});
      refetch();
      refetchProposalStatus_data();
    }
  });

  const [addTicketPriorityForm] = useMutation(ADD_TICKET_PRIORITY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setTicketPrioritySubmissionData({});
      refetch();
      refetchTicketPriority_data();
    }
  });

  const [addTicketTypeForm] = useMutation(ADD_TICKET_TYPE, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setTicketTypeSubmissionData({});
      refetch();
      refetchTicketType_data();
    }
  });

  const [addTicketStatusForm] = useMutation(ADD_TICKET_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setTicketStatusSubmissionData({});
      refetch();
      refetchTicketStatus_data();
    }
  });

  const [addServiceCategoryForm] = useMutation(ADD_SERVICE_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setServiceSubmissionData({});
      refetch();
      refetchServiceCategories();
    }
  });

  const [UpdateCategoryForm] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchCategories();
    }
  });

  const [UpdateLeadStatusForm] = useMutation(UPDATE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchLeadStatus_data();
    }
  });

  const [UpdateCustomerStatusForm] = useMutation(UPDATE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchCustomerStatus_data();
    }
  });

  const [UpdateContractStatusForm] = useMutation(UPDATE_CONTRACT_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchContractStatus_data();
    }
  });

  const [deleteStagingCustomerEmails] = useMutation(
    DELETE_STAGING_CUSTOMER_EMAILS
  );

  const [UpdateCRMSettings] = useMutation(UPDATE_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      GetCRMSettings();
    }
  });

  const [UpdateJobStatusForm] = useMutation(UPDATE_JOB_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchJobStatus_data();
    }
  });

  const [UpdateEstimateStatusForm] = useMutation(UPDATE_ESTIMATE_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchEstimateStatus_data();
    }
  });

  const [UpdateProposalStatusForm] = useMutation(UPDATE_PROPOSAL_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchProposalStatus_data();
    }
  });

  const [UpdateTicketPriorityForm] = useMutation(UPDATE_TICKET_PRIORITY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchTicketPriority_data();
    }
  });

  const [UpdateTicketTypeForm] = useMutation(UPDATE_TICKET_TYPE, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchTicketType_data();
    }
  });

  const [UpdateTicketStatusForm] = useMutation(UPDATE_TICKET_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetch();
      refetchTicketStatus_data();
    }
  });

  const [UpdateServiceCategoryForm] = useMutation(UPDATE_SERVICE_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      setUpdateCategory({});
      refetch();
      refetchServiceCategories();
    }
  });

  const [deleteCategoryForm] = useMutation(DELETE_PRODUCT_CATEGORY, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchCategories();
    }
  });

  const [deleteLeadStatusForm] = useMutation(DELETE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchLeadStatus_data();
    }
  });

  const [deleteCustomerStatusForm] = useMutation(DELETE_LEAD_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchCustomerStatus_data();
    }
  });

  const [deleteContractStatusForm] = useMutation(DELETE_CONTRACT_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchContractStatus_data();
    }
  });

  const [deleteJobStatusForm] = useMutation(DELETE_JOB_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchJobStatus_data();
    }
  });

  const [deleteEstimateStatusForm] = useMutation(DELETE_ESTIMATE_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchEstimateStatus_data();
    }
  });  

  const [deleteProposalStatusForm] = useMutation(DELETE_PROPOSAL_STATUS, {
    skip: !me?.readMe?.customer_id,
    onCompleted: () => {
      refetchProposalStatus_data();
    }
  });

  const handleProductCategoryOpen = row => {
    setAddProductCategory(true);
  };

  const handleUpdateProductCategoryOpen = row => {
    setUpdateProductCategory(true);
  };

  const handleDeleteProductCategoryOpen = row => {
    setDeleteProductCategory(true);
  };

  const handleLeadStatusOpen = row => {
    setAddLeadStatus(true);
  };

  const handleUpdateLeadStatusOpen = row => {
    setUpdateLeadStatus(true);
  };

  const handleDeleteLeadStatusOpen = row => {
    setDeleteLeadStatus(true);
  };

  const handleCustomerStatusOpen = row => {
    setAddCustomerStatus(true);
  };

  const handleUpdateCustomerStatusOpen = row => {
    setUpdateCustomerStatus(true);
  };

  const handleDeleteCustomerStatusOpen = row => {
    setDeleteCustomerStatusDialog(true);
  };

  const handleContractStatusOpen = row => {
    setAddContractStatus(true);
  };

  const handleJobStatusOpen = row => {
    setAddJobStatus(true);
  };

  const handleEstimateStatusOpen = row => {
    setAddEstimateStatus(true);
  };

  const handleUpdateContractStatusOpen = row => {
    setUpdateContractStatus(true);
  };

  const handleUpdateJobStatusOpen = row => {
    setUpdateJobStatus(true);
  };

  const handleUpdateEstimateStatusOpen = row => {
    setUpdateEstimateStatus(true);
  };  

  const handleDeleteContractStatusOpen = row => {
    setDeleteContractStatusDialog(true);
  };

  const handleDeleteJobStatusOpen = row => {
    setDeleteJobStatusDialog(true);
  };

  const handleDeleteEstimateStatusOpen = row => {
    setDeleteEstimateStatusDialog(true);
  };

  const handleProposalStatusOpen = row => {
    setAddProposalStatus(true);
  };

  const handleUpdateProposalStatusOpen = row => {
    setUpdateProposalStatus(true);
  };

  const handleDeleteProposalStatusOpen = row => {
    setDeleteProposalStatusDialog(true);
  };

  const handlePersonStatusOpen = row => {
    setAddPersonStatus(true);
  };

  const handleDeletePersonStatusOpen = row => {
    setDeletePersonStatus(true);
  };

  const handleDeletePersonTypeOpen = row => {
    setSelectedDeleteType(true);
  };

  const handleServiceCategoryOpen = row => {
    setAddServiceCategory(true);
  };

  const handleUpdateServiceCategoryOpen = row => {
    setUpdateServiceCategory(true);
  };

  const handleDeleteServiceCategoryOpen = row => {
    setDeleteServiceCategory(true);
  };

  const handleTicketPriorityOpen = row => {
    setAddTicketPriority(true);
  };

  const handleTicketTypeOpen = row => {
    setAddTicketType(true);
  };

  const handleTicketStatusOpen = row => {
    setAddTicketStatus(true);
  };

  const handleUpdateTicketStatusOpen = row => {
    setUpdateTicketStatus(true);
  };

  const handleUpdateTicketTypeOpen = row => {
    setUpdateTicketType(true);
  };

  const handleUpdateTicketPriorityOpen = row => {
    setUpdateTicketPriority(true);
  };

  const updateFieldHandler = (key, id) => e => {
    updateMe({
      variables: {
        id: id,
        crm_user_level: e.target.value === "inactive" ? null : e.target.value,
      }
    });

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    refetch();
  };

  const updateFieldPersonStatusHandler = (key, convert) => e => {
    if (key === "add_new_lead") {
      setSubmissionPeopleLeadStatusData({
        ...omit(submissionPeopleLeadStatusData, ["lead_status"]),
        [key]: String(e.target.value)
      });
    } else if (key === "lead_status") {
      setSubmissionPeopleLeadStatusData({
        ...omit(submissionPeopleLeadStatusData, ["add_new_lead"]),
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
    else
      setSubmissionPeopleLeadStatusData({
        ...submissionPeopleLeadStatusData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
  }

  const updateProductCategoryFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateLeadStatusFieldHandler = (key, convert) => e => {
    if (key === "customer_status_text") {
      setLeadStatusSubmissionData({
        ...LeadStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value,
        ["update_customer_status_text"]: convert ? convert(e.target.value) : e.target.value
      });
    } else {
      setLeadStatusSubmissionData({
        ...LeadStatusSubmissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  }

  const addCustomerStatusFieldHandler = (key, convert) => e => {
    setCustomerStatusSubmissionData({
      ...CustomerStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addContractStatusFieldHandler = (key, convert) => e => {
    setContractStatusSubmissionData({
      ...ContractStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addJobStatusFieldHandler = (key, convert) => e => {
    setJobStatusSubmissionData({
      ...JobStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addEstimateStatusFieldHandler = (key, convert) => e => {
    setEstimateStatusSubmissionData({
      ...EstimateStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addProposalStatusFieldHandler = (key, convert) => e => {
    setProposalStatusSubmissionData({
      ...ProposalStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addTicketPriorityFieldHandler = (key, convert) => e => {
    setTicketPrioritySubmissionData({
      ...TicketPrioritySubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addTicketTypeFieldHandler = (key, convert) => e => {
    setTicketTypeSubmissionData({
      ...TicketTypeSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const addTicketStatusFieldHandler = (key, convert) => e => {
    setTicketStatusSubmissionData({
      ...TicketStatusSubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const updateNewUserFieldHandler = (key) => e => {
    setnewuserSubmission({
      ...newuserSubmission,
      [key]: e.target.value
    });
  };

  const addServiceCategoryFieldHandler = (key, convert) => e => {
    setServiceSubmissionData({
      ...ServicesubmissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const CSVdownloadcolumns = [
    {
      accessor: "id",
      Header: "Business ID",
    },
    {
      accessor: "business_name",
      Header: "Business Name",
    },
    {
      accessor: "office_phone_number",
      Header: "Business Phone",
    },
    {
      accessor: "office_address_line_one",
      Header: "Business Address Line 1",
    },
    {
      accessor: "office_address_line_two",
      Header: "Business Address Line 2",
    },
    {
      accessor: "office_city",
      Header: "Business City",
    },
    {
      accessor: "office_state",
      Header: "Business State",
    },
    {
      accessor: "office_zip",
      Header: "Business Zip Code",
    },
    {
      accessor: "office_country",
      Header: "Business Country",
    },
    {
      accessor: "website",
      Header: "Website",
    },
    {
      accessor: "status",
      Header: "status",
    },
    {
      Header: "Industry",
      accessor: "industry",
    },
    {
      Header: "Revenue",
      accessor: "revenue",
    },
    {
      Header: "SIC Code",
      accessor: "sic_code",
    },
    {
      Header: "NAICS Code",
      accessor: "naics_code",
      optional: true
    },
    {
      Header: "Employees",
      accessor: "employees",
    },
    {
      Header: "Established",
      accessor: "established",
    },
    {
      Header: "CRM Customer ID",
      accessor: "id",
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to",
    }
  ];

  const Peopleinputs = [
    {
      field: "first_name",
      label: "First Name*"
    },
    {
      field: "last_name",
      label: "Last Name",
      optional: true
    },
    {
      field: "email",
      label: "Email*",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "cell_phone_number",
      label: "Cell*",
      optional: true
    },
    {
      field: "address_line_one",
      label: "Personal Address Line 1",
      optional: true
    },
    {
      field: "address_line_two",
      label: "Personal Address Line 2",
      optional: true
    },
    {
      field: "city",
      label: "Personal City",
      optional: true
    },
    {
      field: "state",
      label: "Personal State",
      optional: true
    },
    {
      field: "zip_code",
      label: "Personal Zip Code",
      optional: true
    },
    {
      field: "country",
      label: "Personal Country",
      optional: true
    },
    {
      field: "opt_int_email",
      label: "Opted in Email",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_text",
      label: "Opted in Text",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_voice",
      label: "Opted in Voice",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "contact_business_only",
      label: "Contact Business Only",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "list_name",
      label: "List Name",
      type: "select",
      options: listNames,
      defaultValue: "Water Bear Marketing",
      customOption: "Customize",
      optional: true
    },
    {
      field: "lead_quality",
      label: "Quality",
      type: "select",
      options: leadQualities,
      defaultValue: "unknown",
      optional: true
    },
    {
      field: "lead_type",
      label: "Type",
      type: "select",
      options: defaultLeadTypes,
      defaultValue: "lead",
      optional: true
    },
    {
      field: "lead_status",
      label: "Status",
      type: "select",
      options: defaultLeadStatuses,
      optionsFilter: (options, { lead_type }) =>
        options.filter(({ types }) => types.indexOf(lead_type) !== -1),
      defaultValue: "other",
      optional: true
    },
    {
      field: "sale_value",
      label: "Last Sale Value",
      optional: true
    },
    {
      field: "lifetime_value",
      label: "Lifetime Value",
      optional: true
    },
    {
      field: "notes",
      label: "Notes",
      optional: true
    },
    {
      field: "first_contacted",
      label: "First Contact",
      type: "date",
      optional: true
    },
    {
      field: "last_contacted",
      label: "Last Contact",
      type: "date",
      optional: true
    },
    {
      field: "job_title",
      label: "Job Title",
      optional: true
    },
    {
      field: "office_email",
      label: "Business Email",
      defaultValue: "",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "office_phone_number",
      label: "Business Phone",
      optional: true
    },
    {
      field: "office_extension",
      label: "Business Extension",
      optional: true
    },
    {
      field: "business_name",
      label: "Business Name",
      optional: true
    },
    {
      field: "office_address_line_one",
      label: "Business Address Line 1",
      optional: true
    },
    {
      field: "office_address_line_two",
      label: "Business Address Line 2",
      optional: true
    },
    {
      field: "office_city",
      label: "Business City",
      optional: true
    },
    {
      field: "office_zip",
      label: "Business Zip Code",
      optional: true
    },
    {
      field: "office_state",
      label: "Business State",
      optional: true
    },
    {
      field: "office_country",
      label: "Business Country",
      optional: true
    },
    {
      label: "Website",
      field: "website",
      optional: true
    },
    {
      field: "unsubscribe",
      label: "Unsubscribe",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      label: "Industry",
      field: "industry",
      optional: true
    },
    {
      label: "Revenue",
      field: "revenue",
      optional: true
    },
    {
      label: "SIC Code",
      field: "sic_code",
      optional: true
    },
    {
      label: "NAICS Code",
      field: "naics_code",
      optional: true
    },
    {
      label: "Employees",
      field: "employees",
      optional: true
    },
    {
      label: "Established",
      field: "established",
      optional: true
    },
    {
      label: "CRM Customer ID",
      field: "crm_customers_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Assigned To",
      field: "assigned_to",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Assigned User ID",
      field: "users_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Business Facebook",
      field: "Business_Facebook",
      optional: true
    },
    {
      label: "Business LinkedIn",
      field: "Business_LinkedIn",
      optional: true
    },
    {
      label: "Business Twitter",
      field: "Business_Twitter",
      optional: true
    },
    {
      label: "Business Instagram",
      field: "Business_Instagram",
      optional: true
    },
    {
      label: "Business Pinterest",
      field: "Business_Pinterest",
      optional: true
    },
    {
      label: "Business Mastodon",
      field: "Business_Mastodon",
      optional: true
    },
    {
      label: "Business Telegram",
      field: "Business_Telegram",
      optional: true
    },
    {
      label: "Business Tumblr",
      field: "Business_Tumblr",
      optional: true
    },
    {
      label: "Business Subreddit",
      field: "Business_Subreddit",
      optional: true
    },
    {
      label: "Google Business Profile",
      field: "Google_Business_Profile",
      optional: true
    },
    {
      label: "Business YouTube",
      field: "Business_YouTube",
      optional: true
    },
    {
      label: "Business Tiktok",
      field: "Business_Tiktok",
      optional: true
    },
    {
      label: "Personal Facebook",
      field: "Personal_Facebook",
      optional: true
    },
    {
      label: "Personal LinkedIn",
      field: "Personal_LinkedIn",
      optional: true
    },
    {
      label: "Personal Twitter",
      field: "Personal_Twitter",
      optional: true
    },
    {
      label: "Personal Instagram",
      field: "Personal_Instagram",
      optional: true
    },
    {
      label: "Personal Pinterest",
      field: "Personal_Pinterest",
      optional: true
    },
    {
      label: "Personal Mastodon",
      field: "Personal_Mastodon",
      optional: true
    },
    {
      label: "Personal Telegram",
      field: "Personal_Telegram",
      optional: true
    },
    {
      label: "Personal Tumblr",
      field: "Personal_Tumblr",
      optional: true
    },
    {
      label: "Personal Subreddit",
      field: "Personal_Subreddit",
      optional: true
    },
    {
      label: "Personal YouTube",
      field: "Personal_YouTube",
      optional: true
    },
    {
      label: "Personal_Tiktok",
      field: "Personal_Tiktok",
      optional: true
    }
  ];

  const isLoading = loading || lead_loading;

  return (
    <div>
      <AppBar position="static" style={{ background: '#ffffff', color: "#000000", width: "100%" }}>
        <Toolbar>
          <Tabs value={Pagevalue} onChange={handleChangeAppBarPage} variant="scrollable" scrollButtons={true}>
            <Tab label="Users" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Leads" style={{ display: B2B === "B2C" ? "none" : "" }} {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Customers" style={{ display: B2B === "B2C" ? "none" : "" }} {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="People" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Jobs" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Products" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Services" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Photos" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Documents" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Estimates" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Proposals" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Contracts" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Tickets" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Company Settings" {...a11yProps(0)} borderleft={1} borderright={1} />
          </Tabs>
        </Toolbar>
      </AppBar>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <TabPanel value={Pagevalue} index={0}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon>
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  LinearCRM User Permissions
                  <HelpTooltip>
                    A Standard User has the ability to create and view calendar, activities, leads, people, jobs, customers, photos, documents, contracts, and proposals. They only have the ability to view, products, services, representatives, and reporting.<br /><br />
                    A CRM Editor has the same ablity as Standard User. In addition, they can create products, services, and representatives.<br /><br />
                    A CRM Admin - has the same ablity as CRM Editor. In addition, they can delete people, jobs, customers, products, services, photos, documents, contracts, proposals, and representatives. They also have access to billing and can add or modify user levels.<br />
                  </HelpTooltip>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    {CRMAssignedToUsers?.map(({ id, first_name, last_name, email, crm_user_level }) => {
                      return (
                        <div style={{ marginTop: "15px" }}>{first_name} {last_name}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{email}
                          <select
                            id={"crm_user_level" + id}
                            onChange={updateFieldHandler("crm_user_level", id)}
                            value={crm_user_level ? crm_user_level : "select_role"}
                            style={{ float: "right" }}
                          >
                            <option value="inactive">Inactive</option>
                            <option value="crm_admin">CRM Admin</option>
                            <option value="crm_editor">CRM Editor</option>
                            <option value="crm_standard">CRM Standard</option>
                          </select>
                        </div>
                      )
                    })}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel value={Pagevalue} index={1} style={{ marginTop: "20px" }}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && B2B === "B2B" && (
              <>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>Leads Status Settings</h4>
                  </CardHeader>
                  <CardBody>
                    <Button
                      onClick={() => handleLeadStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Add Lead Status
                    </Button>
                    <Button
                      onClick={() => handleUpdateLeadStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Update Lead Status
                    </Button>
                    <Button
                      onClick={() => handleDeleteLeadStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Delete Lead Status
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>Import/Export</h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                      <div style={{ display: "inline-block" }}>
                        Download<br />
                        {CRMLeads === null ?
                          <CircularProgress style={{ display: "block" }} />
                          :
                          <CSVDownloader
                            data={CRMLeads || []}
                            columns={CSVdownloadcolumns}
                            filename={`LinearCRM Leads (${state.customers?.selected?.name})`}
                          />
                        }
                      </div>
                      <div style={{ display: "inline-block", marginLeft: "100px" }}>
                        {crm_user_level === "crm_admin" && (
                          <>
                            Upload<br />
                            <CSVReader
                              onLoaded={onCSVLoaded}
                              disabled={isLoading}
                              inputs={inputs}
                              Customers={0}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={2}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && B2B === "B2B" && (
              <>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>Customers Settings</h4>
                  </CardHeader>
                  <CardBody>
                    <Button
                      onClick={() => handleCustomerStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Add Customer Status
                    </Button>
                    <Button
                      onClick={() => handleUpdateCustomerStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Update Customer Status
                    </Button>
                    <Button
                      onClick={() => handleDeleteCustomerStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Delete Customer Status
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>Import/Export</h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "inline-block", textAlign: "center", width: "100%" }}>
                      <div style={{ display: "inline-block" }}>
                        Download<br />
                        {CRMCustomers === null ?
                          <CircularProgress style={{ display: "block" }} />
                          :
                          <CSVDownloader
                            data={CRMCustomers || []}
                            columns={CSVdownloadcolumns}
                            filename={`LinearCRM Customers (${state.customers?.selected?.name})`}
                          />
                        }
                      </div>
                      <div style={{ display: "inline-block", marginLeft: "100px" }}>
                        {crm_user_level === "crm_admin" && (
                          <>
                            Upload<br />
                            <CSVReader
                              onLoaded={onCSVLoaded}
                              disabled={isLoading}
                              inputs={inputs}
                              Customers={1}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={3}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>People Status Settings</h4>
                  </CardHeader>
                  <CardBody>
                    <Button
                      onClick={() => handlePersonStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Add/Update Person Status/Type
                    </Button>
                    <Button
                      onClick={() => handleDeletePersonStatusOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Delete Person Status
                    </Button>
                    <Button
                      onClick={() => handleDeletePersonTypeOpen()}
                      color="primary"
                      style={{ marginLeft: 20 }}
                    >
                      Delete Person Type
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader color="rose" icon>
                    <h4 className={classes.cardIconTitle}>Import/Export</h4>
                  </CardHeader>
                  <CardBody>
                    <div style={{ display: "flow", textAlign: "center", width: "100%" }}>
                      <div style={{ display: "inline-block" }}>
                        Download<br />
                        {CRMPeople === null ?
                          <CircularProgress style={{ display: "block" }} />
                          :
                          <CSVDownloader
                            data={CRMPeople || []}
                            columns={Peoplecsv_columns}
                            filename={`LinearCRM People (${state.customers?.selected?.name})`}
                          />
                        }
                      </div>
                      <div style={{ display: "inline-block", marginLeft: "100px" }}>
                        {crm_user_level === "crm_admin" && (
                          <>
                            Upload<br />
                            <CSVReader
                              onLoaded={onCSVLoadedPeople}
                              disabled={isLoading}
                              inputs={Peopleinputs}
                              People={1}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={4}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Job Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleJobStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Job Status
                  </Button>
                  <Button
                    onClick={() => handleUpdateJobStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Job Status
                  </Button>
                  <Button
                    onClick={() => handleDeleteJobStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Delete Job Status
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_jobs"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] === 0 ? 1 : 0,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                        }
                      });
                    }}
                    color="primary"
                    name="hide_jobs"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Jobs&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={5}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Product Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleProductCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Product Category
                  </Button>
                  <Button
                    onClick={() => handleUpdateProductCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Product Category
                  </Button>
                  <Button
                    onClick={() => handleDeleteProductCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Delete Product Category
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_products"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] === 0 ? 1 : 0,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                        }
                      });
                    }}
                    color="primary"
                    name="hide_products"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Products&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={6}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Service Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleServiceCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Service Category
                  </Button>
                  <Button
                    onClick={() => handleUpdateServiceCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Service Category
                  </Button>
                  <Button
                    onClick={() => handleDeleteServiceCategoryOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Delete Service Category
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_services"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] === 0 ? 1 : 0,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                        }
                      });
                    }}
                    color="primary"
                    name="hide_services"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Services&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={7}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Gallery Settings</h4>
              </CardHeader>
              <CardBody>
                <Switch
                  checked={CRMSettings?.length ? CRMSettings[0]["hide_gallery"] : 0}
                  onChange={async () => {
                    await UpdateCRMSettings({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] === 0 ? 1 : 0,
                        hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                        hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                        hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                        hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                        hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                        hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                        hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                        hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                      }
                    });
                  }}
                  color="primary"
                  name="hide_gallery"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />&nbsp;&nbsp;Hide Photo Galleries&nbsp;&nbsp;
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel value={Pagevalue} index={8}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Document Settings</h4>
              </CardHeader>
              <CardBody>
                <Switch
                  checked={CRMSettings?.length ? CRMSettings[0]["hide_documents"] : 0}
                  onChange={async () => {
                    await UpdateCRMSettings({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] === 0 ? 1 : 0,
                        hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                        hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                        hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                        hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                        hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                        hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                        hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                        hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                      }
                    });
                  }}
                  color="primary"
                  name="hide_documents"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />&nbsp;&nbsp;Hide Documents&nbsp;&nbsp;
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel value={Pagevalue} index={9}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>Estimate Settings</h4>
              </CardHeader>
              <CardBody>
                <Button
                  onClick={() => handleEstimateStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Add Estimate Status
                </Button>
                <Button
                  onClick={() => handleUpdateEstimateStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Update Estimate Status
                </Button>
                <Button
                  onClick={() => handleDeleteEstimateStatusOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                >
                  Delete Estimate Status
                </Button>
                <div style={{ clear: "both" }} />
              </CardBody>
              <CardBody>
                <Switch
                  checked={CRMSettings?.length ? CRMSettings[0]["hide_estimates"] : 0}
                  onChange={async () => {
                    await UpdateCRMSettings({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] === 0 ? 1 : 0,
                        hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                        hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                        hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                        hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                        hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                        hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                        hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                        hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                      }
                    });
                  }}
                  color="primary"
                  name="hide_estimates"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />&nbsp;&nbsp;Hide Estimates&nbsp;&nbsp;
              </CardBody>
            </Card>
          </TabPanel>
          <TabPanel value={Pagevalue} index={10}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Proposal Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleProposalStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Proposal Status
                  </Button>
                  <Button
                    onClick={() => handleUpdateProposalStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Proposal Status
                  </Button>
                  <Button
                    onClick={() => handleDeleteProposalStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Delete Proposal Status
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_proposals"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] === 0 ? 1 : 0,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                        }
                      });
                    }}
                    color="primary"
                    name="hide_proposals"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Proposals&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={11}>
            {(crm_user_level === "crm_admin" || crm_user_level === "crm_editor") && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Contract Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleContractStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Contract Status
                  </Button>
                  <Button
                    onClick={() => handleUpdateContractStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Contract Status
                  </Button>
                  <Button
                    onClick={() => handleDeleteContractStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Delete Contract Status
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_contracts"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] === 0 ? 1 : 0,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] ? 1 : 0
                        }
                      });
                    }}
                    color="primary"
                    name="hide_contracts"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Contracts&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}

          </TabPanel>
          <TabPanel value={Pagevalue} index={12}>
            {crm_user_level === "crm_admin" && (
              <Card>
                <CardHeader color="rose" icon>
                  <h4 className={classes.cardIconTitle}>Ticket Settings</h4>
                </CardHeader>
                <CardBody>
                  <Button
                    onClick={() => handleTicketPriorityOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Ticket Priority
                  </Button>
                  <Button
                    onClick={() => handleUpdateTicketPriorityOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Ticket Priority
                  </Button>
                  <div style={{ clear: "both", marginTop: "30px" }}></div>
                  <Button
                    onClick={() => handleTicketTypeOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Ticket Type
                  </Button>
                  <Button
                    onClick={() => handleUpdateTicketTypeOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Ticket Type
                  </Button>
                  <div style={{ clear: "both", marginTop: "30px" }}></div>
                  <Button
                    onClick={() => handleTicketStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Add Ticket Status
                  </Button>
                  <Button
                    onClick={() => handleUpdateTicketStatusOpen()}
                    color="primary"
                    style={{ marginLeft: 20 }}
                  >
                    Update Ticket Status
                  </Button>
                  <div style={{ clear: "both" }} />
                  <Switch
                    checked={CRMSettings?.length ? CRMSettings[0]["hide_tickets"] : 0}
                    onChange={async () => {
                      await UpdateCRMSettings({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          hide_tickets: CRMSettings?.length && CRMSettings[0]["hide_tickets"] === 0 ? 1 : 0,
                          hide_jobs: CRMSettings?.length && CRMSettings[0]["hide_jobs"] ? 1 : 0,
                          hide_products: CRMSettings?.length && CRMSettings[0]["hide_products"] ? 1 : 0,
                          hide_services: CRMSettings?.length && CRMSettings[0]["hide_services"] ? 1 : 0,
                          hide_gallery: CRMSettings?.length && CRMSettings[0]["hide_gallery"] ? 1 : 0,
                          hide_documents: CRMSettings?.length && CRMSettings[0]["hide_documents"] ? 1 : 0,
                          hide_estimates: CRMSettings?.length && CRMSettings[0]["hide_estimates"] ? 1 : 0,
                          hide_proposals: CRMSettings?.length && CRMSettings[0]["hide_proposals"] ? 1 : 0,
                          hide_contracts: CRMSettings?.length && CRMSettings[0]["hide_contracts"] ? 1 : 0,
                        }
                      });
                    }}
                    color="primary"
                    name="hide_tickets"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />&nbsp;&nbsp;Hide Tickets&nbsp;&nbsp;
                </CardBody>
              </Card>
            )}
          </TabPanel>
          <TabPanel value={Pagevalue} index={13} style={{ marginTop: "20px" }}>
            <Card>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle}>LinearCRM B2B/B2C Setting
                  <HelpTooltip>
                    Are you Business to Business or Business to Customer?
                  </HelpTooltip>
                </h4>
              </CardHeader>
              <CardBody>
                &nbsp;&nbsp;B2C&nbsp;&nbsp;<Switch
                  checked={B2B === "B2B" ? 1 : 0}
                  onChange={updateB2BFieldHandler("B2B")}
                  color="primary"
                  name="B2B"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />&nbsp;&nbsp;B2B&nbsp;&nbsp;
              </CardBody>
            </Card>
          </TabPanel>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {Pagevalue === 0 ?
            <Card profile>
              <CardHeader color="rose" icon>
                <CardIcon>
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle} style={{ float: "left" }}>Add CRM User</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText="First Name"
                      id="first_name"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "string",
                        value: newuserSubmission?.first_name || "",
                        onChange: updateNewUserFieldHandler("first_name")
                      }}
                    />
                    <CustomInput
                      labelText="Last Name"
                      id="last_name"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "string",
                        value: newuserSubmission?.last_name || "",
                        onChange: updateNewUserFieldHandler("last_name")
                      }}
                    />
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "email",
                        value: newuserSubmission?.email || "",
                        onChange: updateNewUserFieldHandler("email")
                      }}
                    />
                    <CustomInput
                      labelText="Cell Phone Number"
                      id="cell_phone"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "phone_number",
                        value: newuserSubmission?.cell_phone || "",
                        onChange: updateNewUserFieldHandler("cell_phone", e => `${e}`)
                      }}
                    />
                    <CustomInput
                      labelText="Birthday"
                      id="birthday"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "date",
                        value:
                          moment(newuserSubmission?.birthday).format("YYYY-MM-DD") || "",
                        onChange: updateNewUserFieldHandler("birthday")
                      }}
                    />
                    <CustomInput
                      labelText="Office Phone Number"
                      id="office_phone"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "phone_number",
                        value: newuserSubmission?.office_phone || "",
                        onChange: updateNewUserFieldHandler("office_phone", e => `${e}`)
                      }}
                      value={me?.office_phone}
                    />
                    <CustomInput
                      labelText="Office Extension"
                      id="office_extension"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "integer",
                        value: newuserSubmission?.office_extension || "",
                        onChange: updateNewUserFieldHandler(
                          "office_extension",
                          e => `${e}`
                        )
                      }}
                    />
                    <div style={{ clear: "both", marginTop: "30px" }}></div>
                    <label>LinearCRM User Permissions</label>
                    <select
                      id={"crm_new_user_level"}
                      onChange={updateNewUserFieldHandler("crm_user_level")}
                      value={newuserSubmission?.crm_user_level ? newuserSubmission?.crm_user_level : "select_role"}
                      style={{ float: "right" }}
                    >
                      <option value="inactive">Inactive</option>
                      <option value="crm_admin">CRM Admin</option>
                      <option value="crm_editor">CRM Editor</option>
                      <option value="crm_standard">CRM Standard</option>
                    </select>
                    <div style={{ clear: "both", marginTop: "30px" }}></div>
                  </GridItem>
                </GridContainer>
                <Button
                  color="orange"
                  className={classes.updateProfileButton}
                  onClick={() => {
                    if (newuserSubmission?.length === 0)
                      alert("No Changes Detected.");
                    else if (newuserSubmission?.email?.length === 0)
                      alert("New User Email is Required.");
                    else {
                      addUser({
                        skip: !me?.customer_id,
                        variables: {
                          customer_id: me?.customer_id,
                          first_name: newuserSubmission?.first_name,
                          last_name: newuserSubmission?.last_name,
                          email: newuserSubmission?.email,
                          cell_phone: newuserSubmission?.cell_phone?.replace(/[^0-9\-]+/g, ""),
                          commission: null,
                          office_phone: newuserSubmission?.office_phone?.replace(/[^0-9\-]+/g, ""),
                          office_extension: newuserSubmission?.office_extension?.replace(/[^0-9]+/g, ""),
                          user_level: "crm_user",
                          crm_user_level: newuserSubmission?.crm_user_level
                        }
                      });
                    }
                  }
                  }
                >
                  Add CRM User
                </Button>
              </CardBody>
            </Card>
            :
            <Card profile>
              <CardHeader color="rose" icon>
                <h4 className={classes.cardIconTitle} style={{ float: "left" }}>Tutorials (Coming Soon)</h4>
              </CardHeader>
              <CardBody>
              </CardBody></Card>}
          {AddLeadStatus && (
            <Dialog
              open={Boolean(AddLeadStatus)}
              onClose={() => setAddLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Lead Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={LeadStatusSubmissionData?.customer_status_text}
                  onChange={updateLeadStatusFieldHandler("customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!LeadStatusSubmissionData?.customer_status_text) {
                      alert("Please enter a Lead Status.");
                      return;
                    }

                    if (LeadStatusSubmissionData && LeadStatusSubmissionData?.customer_status_text) {
                      await addLeadStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          customer_status_text: LeadStatusSubmissionData?.customer_status_text,
                          status: 0
                        }
                      });

                      setAddLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateLeadStatus && (
            <Dialog
              open={Boolean(UpdateLeadStatus)}
              onClose={() => setUpdateLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Lead Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Lead Status to Update</label>
                <div>
                  <CustomSelect
                    value={LeadStatusSubmissionData?.customer_status_text ? LeadStatusSubmissionData?.customer_status_text : ""}
                    onChange={updateLeadStatusFieldHandler("customer_status_text")}
                    data={LeadStatusData?.map(({ customer_status_text }) => ({
                      value: customer_status_text,
                      label: customer_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Lead Status</label>
                <TextField
                  fullWidth
                  value={LeadStatusSubmissionData?.update_customer_status_text || ""}
                  onChange={updateLeadStatusFieldHandler("update_customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (LeadStatusSubmissionData?.customer_status_text ===
                      LeadStatusSubmissionData?.update_customer_status_text || !LeadStatusSubmissionData?.update_customer_status_text) {
                      alert("Please enter an updated Lead Status.");
                      return;
                    }

                    if (LeadStatusSubmissionData && LeadStatusSubmissionData?.customer_status_text && LeadStatusSubmissionData?.update_customer_status_text) {
                      await UpdateLeadStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          customer_status_text: LeadStatusSubmissionData?.customer_status_text,
                          update_customer_status_text: LeadStatusSubmissionData?.update_customer_status_text
                        }
                      });

                      setLeadStatusSubmissionData({});
                      setUpdateLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteLeadStatus && (
            <Dialog
              open={Boolean(DeleteLeadStatus)}
              onClose={() => setDeleteLeadStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Lead Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Lead Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteLeadStatus || ""}
                    onChange={(e) => { setDeleteLeadStatus(e.target.value); }}
                    data={LeadStatusData?.map(({ id, customer_status_text }) => ({
                      value: id,
                      label: customer_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteLeadStatus) {
                      alert("Please choose a Lead Status to delete.");
                      return;
                    }

                    if (DeleteLeadStatus) {
                      deleteLeadStatus(DeleteLeadStatus);

                      setDeleteLeadStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddCustomerStatus && (
            <Dialog
              open={Boolean(AddCustomerStatus)}
              onClose={() => setAddCustomerStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Customer Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={CustomerStatusSubmissionData?.customer_status_text}
                  onChange={addCustomerStatusFieldHandler("customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!CustomerStatusSubmissionData?.customer_status_text) {
                      alert("Please enter a Customer Status.");
                      return;
                    }

                    if (CustomerStatusSubmissionData && CustomerStatusSubmissionData?.customer_status_text) {
                      await addCustomerStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          customer_status_text: CustomerStatusSubmissionData?.customer_status_text,
                          status: 1
                        }
                      });

                      setAddCustomerStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateCustomerStatus && (
            <Dialog
              open={Boolean(UpdateCustomerStatus)}
              onClose={() => setUpdateCustomerStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Customer Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Customer Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateCustomerStatusSubmissionData?.customer_status_text ? UpdateCustomerStatusSubmissionData?.customer_status_text : ""}
                    onChange={updateCustomerStatusFieldHandler("customer_status_text")}
                    data={CustomerStatusData?.map(({ customer_status_text }) => ({
                      value: customer_status_text,
                      label: customer_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Customer Status</label>
                <TextField
                  fullWidth
                  value={UpdateCustomerStatusSubmissionData?.update_customer_status_text || ""}
                  onChange={updateCustomerStatusFieldHandler("update_customer_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateCustomerStatusSubmissionData?.customer_status_text ===
                      UpdateCustomerStatusSubmissionData?.update_customer_status_text || !UpdateCustomerStatusSubmissionData?.update_customer_status_text) {
                      alert("Please enter an updated Customer Status.");
                      return;
                    }

                    if (UpdateCustomerStatusSubmissionData && UpdateCustomerStatusSubmissionData?.customer_status_text && UpdateCustomerStatusSubmissionData?.update_customer_status_text) {
                      await UpdateCustomerStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          customer_status_text: UpdateCustomerStatusSubmissionData?.customer_status_text,
                          update_customer_status_text: UpdateCustomerStatusSubmissionData?.update_customer_status_text
                        }
                      });

                      setUpdateCustomerStatusSubmissionData([]);
                      setUpdateCustomerStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteCustomerStatusDialog && (
            <Dialog
              open={Boolean(DeleteCustomerStatusDialog)}
              onClose={() => setDeleteCustomerStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Customer Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Customer Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteCustomerStatus || ""}
                    onChange={(e) => { setDeleteCustomerStatus(e.target.value); }}
                    data={CustomerStatusData?.map(({ id, customer_status_text }) => ({
                      value: id,
                      label: customer_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteCustomerStatus) {
                      alert("Please choose a Customer Status to delete.");
                      return;
                    }

                    if (DeleteCustomerStatus) {
                      deleteCustomerStatus(DeleteCustomerStatus);

                      setDeleteCustomerStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddProductCategory && (
            <Dialog
              open={Boolean(AddProductCategory)}
              onClose={() => setAddProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Product Category
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={submissionData?.add_category}
                  onChange={updateProductCategoryFieldHandler("add_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!submissionData?.add_category) {
                      alert("Please enter a Category Name.");
                      return;
                    }

                    if (submissionData && submissionData?.add_category) {
                      await addCategoryForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          add_category: submissionData?.add_category
                        }
                      });

                      setAddProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateProductCategory && (
            <Dialog
              open={Boolean(UpdateProductCategory)}
              onClose={() => setUpdateProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Product Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateCategory?.category_text ? UpdateCategory?.category_text : ""}
                    onChange={updateCategoryFieldHandler("category_text")}
                    data={CategoryData?.map(({ category_text }) => ({
                      value: category_text,
                      label: category_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Category</label>
                <TextField
                  fullWidth
                  value={UpdateCategory?.update_category || ""}
                  onChange={updateCategoryFieldHandler("update_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateCategory?.update_category === UpdateCategory?.category_text || !UpdateCategory?.update_category) {
                      alert("Please enter an updated Category Name.");
                      return;
                    }

                    if (UpdateCategory && UpdateCategory?.update_category && UpdateCategory?.category_text) {
                      await UpdateCategoryForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          category_text: UpdateCategory?.category_text,
                          update_category: UpdateCategory?.update_category
                        }
                      });

                      setUpdateCategory("");
                      setUpdateProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteProductCategory && (
            <Dialog
              open={Boolean(DeleteProductCategory)}
              onClose={() => setDeleteProductCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Product Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteCategory || ""}
                    onChange={(e) => { setDeleteCategory(e.target.value); }}
                    data={CategoryData?.map(({ id, category_text }) => ({
                      value: id,
                      label: category_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteCategory) {
                      alert("Please choose a Category to delete.");
                      return;
                    }

                    if (DeleteCategory) {
                      deleteCategory(DeleteCategory);

                      setDeleteProductCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddServiceCategory && (
            <Dialog
              open={Boolean(AddServiceCategory)}
              onClose={() => setAddServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Service Category
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ServicesubmissionData?.add_category}
                  onChange={addServiceCategoryFieldHandler("add_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ServicesubmissionData?.add_category) {
                      alert("Please enter a Category Name.");
                      return;
                    }

                    if (ServicesubmissionData && ServicesubmissionData?.add_category) {
                      await addServiceCategoryForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          add_category: ServicesubmissionData?.add_category
                        }
                      });

                      setAddServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateServiceCategory && (
            <Dialog
              open={Boolean(UpdateServiceCategory)}
              onClose={() => setUpdateServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Service Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Update</label>
                <div>
                  <CustomSelect
                    value={ServiceUpdateCategory?.category_text ? ServiceUpdateCategory?.category_text : ""}
                    onChange={updateServiceCategoryFieldHandler("category_text")}
                    data={ServiceCategoryData?.map(({ category_text }) => ({
                      value: category_text,
                      label: category_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Category</label>
                <TextField
                  fullWidth
                  value={ServiceUpdateCategory?.update_category || ""}
                  onChange={updateServiceCategoryFieldHandler("update_category")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (ServiceUpdateCategory?.update_category === ServiceUpdateCategory?.category_text || !ServiceUpdateCategory?.update_category) {
                      alert("Please enter an updated Category Name.");
                      return;
                    }

                    if (ServiceUpdateCategory && ServiceUpdateCategory?.update_category && ServiceUpdateCategory?.category_text) {
                      await UpdateServiceCategoryForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          category_text: ServiceUpdateCategory?.category_text,
                          update_category: ServiceUpdateCategory?.update_category
                        }
                      });
                      setServiceUpdateCategory("");
                      setUpdateServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteServiceCategory && (
            <Dialog
              open={Boolean(DeleteServiceCategory)}
              onClose={() => setDeleteServiceCategory(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Service Category
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Category to Delete</label>
                <div>
                  <CustomSelect
                    value={ServiceDeleteCategory || ""}
                    onChange={(e) => { setServiceDeleteCategory(e.target.value); }}
                    data={ServiceCategoryData?.map(({ id, category_text }) => ({
                      value: id,
                      label: category_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ServiceDeleteCategory) {
                      alert("Please choose a Category to delete.");
                      return;
                    }

                    if (ServiceDeleteCategory) {
                      ServicedeleteCategory(ServiceDeleteCategory);

                      setDeleteServiceCategory(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddEstimateStatus && (
            <Dialog
              open={Boolean(AddEstimateStatus)}
              onClose={() => setAddEstimateStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Estimate Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={EstimateStatusSubmissionData?.estimate_status_text}
                  onChange={addEstimateStatusFieldHandler("estimate_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!EstimateStatusSubmissionData?.estimate_status_text) {
                      alert("Please enter a Estimate Status.");
                      return;
                    }

                    if (EstimateStatusSubmissionData && EstimateStatusSubmissionData?.estimate_status_text) {
                      await addEstimateStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          estimate_status_text: EstimateStatusSubmissionData?.estimate_status_text
                        }
                      });

                      setAddEstimateStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateEstimateStatus && (
            <Dialog
              open={Boolean(UpdateEstimateStatus)}
              onClose={() => setUpdateEstimateStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Estimate Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Estimate Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateEstimateStatusSubmissionData?.estimate_status_text ? UpdateEstimateStatusSubmissionData?.estimate_status_text : ""}
                    onChange={UpdateEstimateStatusFieldHandler("estimate_status_text")}
                    data={EstimateStatusData?.map(({ estimate_status_text }) => ({
                      value: estimate_status_text,
                      label: estimate_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Job Status</label>
                <TextField
                  fullWidth
                  value={UpdateEstimateStatusSubmissionData?.update_estimate_status_text || ""}
                  onChange={UpdateEstimateStatusFieldHandler("update_estimate_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateEstimateStatusSubmissionData?.estimate_status_text ===
                      UpdateEstimateStatusSubmissionData?.update_estimate_status_text || !UpdateEstimateStatusSubmissionData?.update_estimate_status_text) {
                      alert("Please enter an updated Estimate Status.");
                      return;
                    }

                    if (UpdateEstimateStatusSubmissionData && UpdateEstimateStatusSubmissionData?.estimate_status_text && UpdateEstimateStatusSubmissionData?.update_estimate_status_text) {
                      await UpdateEstimateStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          estimate_status_text: UpdateEstimateStatusSubmissionData?.estimate_status_text,
                          update_estimate_status_text: UpdateEstimateStatusSubmissionData?.update_estimate_status_text
                        }
                      });

                      setUpdateEstimateStatusSubmissionData([]);
                      setUpdateEstimateStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteEstimateStatusDialog && (
            <Dialog
              open={Boolean(DeleteEstimateStatusDialog)}
              onClose={() => setDeleteEstimateStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Estimate Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Estimate Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteEstimateStatus || ""}
                    onChange={(e) => { setDeleteEstimateStatus(e.target.value); }}
                    data={EstimateStatusData?.map(({ id, estimate_status_text }) => ({
                      value: id,
                      label: estimate_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteEstimateStatus) {
                      alert("Please choose a Job Status to delete.");
                      return;
                    }

                    if (DeleteEstimateStatus) {
                      deleteEstimateStatus(DeleteEstimateStatus);

                      setDeleteEstimateStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddJobStatus && (
            <Dialog
              open={Boolean(AddJobStatus)}
              onClose={() => setAddJobStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Job Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={JobStatusSubmissionData?.job_status_text}
                  onChange={addJobStatusFieldHandler("job_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!JobStatusSubmissionData?.job_status_text) {
                      alert("Please enter a Job Status.");
                      return;
                    }

                    if (JobStatusSubmissionData && JobStatusSubmissionData?.job_status_text) {
                      await addJobStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          job_status_text: JobStatusSubmissionData?.job_status_text
                        }
                      });

                      setAddJobStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateJobStatus && (
            <Dialog
              open={Boolean(UpdateJobStatus)}
              onClose={() => setUpdateJobStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Job Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Job Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateJobStatusSubmissionData?.job_status_text ? UpdateJobStatusSubmissionData?.job_status_text : ""}
                    onChange={updateJobStatusFieldHandler("job_status_text")}
                    data={JobStatusData?.map(({ job_status_text }) => ({
                      value: job_status_text,
                      label: job_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Job Status</label>
                <TextField
                  fullWidth
                  value={UpdateJobStatusSubmissionData?.update_job_status_text || ""}
                  onChange={updateJobStatusFieldHandler("update_job_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateJobStatusSubmissionData?.job_status_text ===
                      UpdateJobStatusSubmissionData?.update_job_status_text || !UpdateJobStatusSubmissionData?.update_job_status_text) {
                      alert("Please enter an updated Job Status.");
                      return;
                    }

                    if (UpdateJobStatusSubmissionData && UpdateJobStatusSubmissionData?.job_status_text && UpdateJobStatusSubmissionData?.update_job_status_text) {
                      await UpdateJobStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          job_status_text: UpdateJobStatusSubmissionData?.job_status_text,
                          update_job_status_text: UpdateJobStatusSubmissionData?.update_job_status_text
                        }
                      });

                      setUpdateJobStatusSubmissionData([]);
                      setUpdateJobStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteJobStatusDialog && (
            <Dialog
              open={Boolean(DeleteJobStatusDialog)}
              onClose={() => setDeleteJobStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Job Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Job Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteJobStatus || ""}
                    onChange={(e) => { setDeleteJobStatus(e.target.value); }}
                    data={JobStatusData?.map(({ id, job_status_text }) => ({
                      value: id,
                      label: job_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteJobStatus) {
                      alert("Please choose a Job Status to delete.");
                      return;
                    }

                    if (DeleteJobStatus) {
                      deleteJobStatus(DeleteJobStatus);

                      setDeleteJobStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddContractStatus && (
            <Dialog
              open={Boolean(AddContractStatus)}
              onClose={() => setAddContractStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Contract Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ContractStatusSubmissionData?.contract_status_text}
                  onChange={addContractStatusFieldHandler("contract_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ContractStatusSubmissionData?.contract_status_text) {
                      alert("Please enter a Contract Status.");
                      return;
                    }

                    if (ContractStatusSubmissionData && ContractStatusSubmissionData?.contract_status_text) {
                      await addContractStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          contract_status_text: ContractStatusSubmissionData?.contract_status_text
                        }
                      });

                      setAddContractStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateContractStatus && (
            <Dialog
              open={Boolean(UpdateContractStatus)}
              onClose={() => setUpdateContractStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Contract Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Contract Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateContractStatusSubmissionData?.contract_status_text ? UpdateContractStatusSubmissionData?.contract_status_text : ""}
                    onChange={updateContractStatusFieldHandler("contract_status_text")}
                    data={ContractStatusData?.map(({ contract_status_text }) => ({
                      value: contract_status_text,
                      label: contract_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Contract Status</label>
                <TextField
                  fullWidth
                  value={UpdateContractStatusSubmissionData?.update_contract_status_text || ""}
                  onChange={updateContractStatusFieldHandler("update_contract_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateContractStatusSubmissionData?.contract_status_text ===
                      UpdateContractStatusSubmissionData?.update_contract_status_text || !UpdateContractStatusSubmissionData?.update_contract_status_text) {
                      alert("Please enter an updated Contract Status.");
                      return;
                    }

                    if (UpdateContractStatusSubmissionData && UpdateContractStatusSubmissionData?.contract_status_text && UpdateContractStatusSubmissionData?.update_contract_status_text) {
                      await UpdateContractStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          contract_status_text: UpdateContractStatusSubmissionData?.contract_status_text,
                          update_contract_status_text: UpdateContractStatusSubmissionData?.update_contract_status_text
                        }
                      });

                      setUpdateContractStatusSubmissionData([]);
                      setUpdateContractStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteContractStatusDialog && (
            <Dialog
              open={Boolean(DeleteContractStatusDialog)}
              onClose={() => setDeleteContractStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Contract Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Contract Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteContractStatus || ""}
                    onChange={(e) => { setDeleteContractStatus(e.target.value); }}
                    data={ContractStatusData?.map(({ id, contract_status_text }) => ({
                      value: id,
                      label: contract_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteContractStatus) {
                      alert("Please choose a Contract Status to delete.");
                      return;
                    }

                    if (DeleteContractStatus) {
                      deleteContactStatus(DeleteContractStatus);

                      setDeleteContractStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddProposalStatus && (
            <Dialog
              open={Boolean(AddProposalStatus)}
              onClose={() => setAddProposalStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Proposal Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={ProposalStatusSubmissionData?.proposal_status_text}
                  onChange={addProposalStatusFieldHandler("proposal_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!ProposalStatusSubmissionData?.proposal_status_text) {
                      alert("Please enter a Proposal Status.");
                      return;
                    }

                    if (ProposalStatusSubmissionData && ProposalStatusSubmissionData?.proposal_status_text) {
                      await addProposalStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          proposal_status_text: ProposalStatusSubmissionData?.proposal_status_text
                        }
                      });

                      setAddProposalStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddTicketPriority && (
            <Dialog
              open={Boolean(AddTicketPriority)}
              onClose={() => setAddTicketPriority(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Ticket Priority
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={TicketPrioritySubmissionData?.priority}
                  onChange={addTicketPriorityFieldHandler("priority")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!TicketPrioritySubmissionData?.priority) {
                      alert("Please enter a Ticket Priority");
                      return;
                    }

                    if (TicketPrioritySubmissionData && TicketPrioritySubmissionData?.priority) {
                      await addTicketPriorityForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          priority: TicketPrioritySubmissionData?.priority
                        }
                      });

                      setAddTicketPriority(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateTicketPriority && (
            <Dialog
              open={Boolean(UpdateTicketPriority)}
              onClose={() => setUpdateTicketPriority(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Ticket Priority
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Ticket Priority to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateTicketPrioritySubmissionData?.priority ? UpdateTicketPrioritySubmissionData?.priority : ""}
                    onChange={updateTicketPriorityFieldHandler("priority")}
                    data={TicketPriorityData?.map(({ priority }) => ({
                      value: priority,
                      label: priority
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Ticket Priority</label>
                <TextField
                  fullWidth
                  value={UpdateTicketPrioritySubmissionData?.update_priority || ""}
                  onChange={updateTicketPriorityFieldHandler("update_priority")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateTicketPrioritySubmissionData?.priority ===
                      UpdateTicketPrioritySubmissionData?.update_priority || !UpdateTicketPrioritySubmissionData?.update_priority) {
                      alert("Please enter an updated Proposal Status.");
                      return;
                    }

                    if (UpdateTicketPrioritySubmissionData && UpdateTicketPrioritySubmissionData?.priority && UpdateTicketPrioritySubmissionData?.update_priority) {
                      await UpdateTicketPriorityForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          priority: UpdateTicketPrioritySubmissionData?.priority,
                          update_priority: UpdateTicketPrioritySubmissionData?.update_priority
                        }
                      });

                      setUpdateTicketPrioritySubmissionData([]);
                      setUpdateTicketPriority(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddTicketType && (
            <Dialog
              open={Boolean(AddTicketType)}
              onClose={() => setAddTicketType(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Ticket Type
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={TicketTypeSubmissionData?.type}
                  onChange={addTicketTypeFieldHandler("type")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!TicketTypeSubmissionData?.type) {
                      alert("Please enter a Ticket Type");
                      return;
                    }

                    if (TicketTypeSubmissionData && TicketTypeSubmissionData?.type) {
                      await addTicketTypeForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          type: TicketTypeSubmissionData?.type
                        }
                      });

                      setAddTicketType(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateTicketType && (
            <Dialog
              open={Boolean(UpdateTicketType)}
              onClose={() => setUpdateTicketType(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Ticket Type
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Ticket Type to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateTicketTypeSubmissionData?.type ? UpdateTicketTypeSubmissionData?.type : ""}
                    onChange={updateTicketTypeFieldHandler("type")}
                    data={TicketTypeData?.map(({ type }) => ({
                      value: type,
                      label: type
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Ticket Type</label>
                <TextField
                  fullWidth
                  value={UpdateTicketTypeSubmissionData?.update_type || ""}
                  onChange={updateTicketTypeFieldHandler("update_type")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateTicketTypeSubmissionData?.type ===
                      UpdateTicketTypeSubmissionData?.update_type || !UpdateTicketTypeSubmissionData?.update_type) {
                      alert("Please enter an updated Ticket Type.");
                      return;
                    }

                    if (UpdateTicketTypeSubmissionData && UpdateTicketTypeSubmissionData?.type && UpdateTicketTypeSubmissionData?.update_type) {
                      await UpdateTicketTypeForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          type: UpdateTicketTypeSubmissionData?.type,
                          update_type: UpdateTicketTypeSubmissionData?.update_type
                        }
                      });

                      setUpdateTicketTypeSubmissionData([]);
                      setUpdateTicketType(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateTicketType && (
            <Dialog
              open={Boolean(UpdateTicketType)}
              onClose={() => setUpdateTicketType(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Ticket Type
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Ticket Type to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateTicketTypeSubmissionData?.type ? UpdateTicketTypeSubmissionData?.type : ""}
                    onChange={updateTicketTypeFieldHandler("type")}
                    data={TicketTypeData?.map(({ type }) => ({
                      value: type,
                      label: type
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Ticket Type</label>
                <TextField
                  fullWidth
                  value={UpdateTicketTypeSubmissionData?.update_type || ""}
                  onChange={updateTicketTypeFieldHandler("update_type")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateTicketTypeSubmissionData?.type ===
                      UpdateTicketTypeSubmissionData?.update_type || !UpdateTicketTypeSubmissionData?.update_type) {
                      alert("Please enter an updated Ticket Type.");
                      return;
                    }

                    if (UpdateTicketTypeSubmissionData && UpdateTicketTypeSubmissionData?.type && UpdateTicketTypeSubmissionData?.update_type) {
                      await UpdateTicketTypeForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          type: UpdateTicketTypeSubmissionData?.type,
                          update_type: UpdateTicketTypeSubmissionData?.update_type
                        }
                      });

                      setUpdateTicketTypeSubmissionData([]);
                      setUpdateTicketType(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateProposalStatus && (
            <Dialog
              open={Boolean(UpdateProposalStatus)}
              onClose={() => setUpdateProposalStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Proposal Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Proposal Status to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateProposalStatusSubmissionData?.proposal_status_text ? UpdateProposalStatusSubmissionData?.proposal_status_text : ""}
                    onChange={updateProposalStatusFieldHandler("proposal_status_text")}
                    data={ProposalStatusData?.map(({ proposal_status_text }) => ({
                      value: proposal_status_text,
                      label: proposal_status_text
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Proposal Status</label>
                <TextField
                  fullWidth
                  value={UpdateProposalStatusSubmissionData?.update_proposal_status_text || ""}
                  onChange={updateProposalStatusFieldHandler("update_proposal_status_text")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateProposalStatusSubmissionData?.proposal_status_text ===
                      UpdateProposalStatusSubmissionData?.update_proposal_status_text || !UpdateProposalStatusSubmissionData?.update_proposal_status_text) {
                      alert("Please enter an updated Proposal Status.");
                      return;
                    }

                    if (UpdateProposalStatusSubmissionData && UpdateProposalStatusSubmissionData?.proposal_status_text && UpdateProposalStatusSubmissionData?.update_proposal_status_text) {
                      await UpdateProposalStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          proposal_status_text: UpdateProposalStatusSubmissionData?.proposal_status_text,
                          update_proposal_status_text: UpdateProposalStatusSubmissionData?.update_proposal_status_text
                        }
                      });

                      setUpdateProposalStatusSubmissionData([]);
                      setUpdateProposalStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {AddTicketStatus && (
            <Dialog
              open={Boolean(AddTicketStatus)}
              onClose={() => setAddTicketStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
              width="300px"
            >
              <DialogTitle id="alert-dialog-title">
                Add Ticket Status
              </DialogTitle>
              <DialogContent>
                <TextField
                  fullWidth
                  value={TicketStatusSubmissionData?.status}
                  onChange={addTicketStatusFieldHandler("status")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  status="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!TicketStatusSubmissionData?.status) {
                      alert("Please enter a Ticket Status");
                      return;
                    }

                    if (TicketStatusSubmissionData && TicketStatusSubmissionData?.status) {
                      await addTicketStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          status: TicketStatusSubmissionData?.status
                        }
                      });

                      setAddTicketStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {UpdateTicketStatus && (
            <Dialog
              open={Boolean(UpdateTicketStatus)}
              onClose={() => setUpdateTicketStatus(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Update Ticket Type
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Ticket Type to Update</label>
                <div>
                  <CustomSelect
                    value={UpdateTicketStatusSubmissionData?.status ? UpdateTicketStatusSubmissionData?.status : ""}
                    onChange={updateTicketStatusFieldHandler("status")}
                    data={TicketStatusData?.map(({ status }) => ({
                      value: status,
                      label: status
                    })) || []}
                    deleteable={false}
                  />
                </div>
                <div style={{ clear: "both", marginTop: "20px" }}></div>
                <label>Update Ticket Type</label>
                <TextField
                  fullWidth
                  value={UpdateTicketStatusSubmissionData?.update_status || ""}
                  onChange={updateTicketStatusFieldHandler("update_status")}
                  inputProps={{
                    className: classes.textField
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (UpdateTicketStatusSubmissionData?.status ===
                      UpdateTicketStatusSubmissionData?.update_status || !UpdateTicketStatusSubmissionData?.update_status) {
                      alert("Please enter an updated Ticket Status.");
                      return;
                    }

                    if (UpdateTicketStatusSubmissionData && UpdateTicketStatusSubmissionData?.status && UpdateTicketStatusSubmissionData?.update_status) {
                      await UpdateTicketStatusForm({
                        variables: {
                          customer_id: me?.readMe?.customer_id,
                          status: UpdateTicketStatusSubmissionData?.status,
                          update_status: UpdateTicketStatusSubmissionData?.update_status
                        }
                      });

                      setUpdateTicketStatusSubmissionData([]);
                      setUpdateTicketStatus(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {DeleteProposalStatusDialog && (
            <Dialog
              open={Boolean(DeleteProposalStatusDialog)}
              onClose={() => setDeleteProposalStatusDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">
                Delete Proposal Status
              </DialogTitle>
              <DialogContent>
                <div style={{ clear: "both", marginTop: "20px", width: "300px" }}></div>
                <label>Choose Proposal Status to Delete</label>
                <div>
                  <CustomSelect
                    value={DeleteProposalStatus || ""}
                    onChange={(e) => { setDeleteProposalStatus(e.target.value); }}
                    data={ProposalStatusData?.map(({ id, proposal_status_text }) => ({
                      value: id,
                      label: proposal_status_text
                    })) || []}
                    deleteable={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  type="submit"
                  style={{ float: "right" }}
                  onClick={async (e) => {
                    e.preventDefault();

                    if (!DeleteProposalStatus) {
                      alert("Please choose a Proposal Status to delete.");
                      return;
                    }

                    if (DeleteProposalStatus) {
                      deleteProposalStatus(DeleteProposalStatus);

                      setDeleteProposalStatusDialog(false);
                    }
                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog
            open={Boolean(AddPersonStatus)}
            onClose={() => setAddPersonStatus(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Add Custom Lead Type and Status for People
            </DialogTitle>
            <DialogContent>
              <div>
                <label>Select Lead Type</label><CustomSelect
                  value={submissionPeopleLeadStatusData?.lead_type ? submissionPeopleLeadStatusData?.lead_type : ""}
                  onChange={updateFieldPersonStatusHandler("lead_type")}
                  data={defaultLeadTypes}
                />
              </div>
              {!submissionPeopleLeadStatusData?.lead_type || submissionPeopleLeadStatusData?.lead_type === -1 ?
                <div>
                  <label>Or Add Custom Lead Type</label>
                  <TextField
                    fullWidth
                    onChange={updateFieldPersonStatusHandler("lead_type_text")}
                    inputProps={{
                      className: classes.textField
                    }}
                  />
                </div> : ""}
              <div>
                <label>Select Lead Status</label><CustomSelect
                  value={submissionPeopleLeadStatusData?.lead_status ? submissionPeopleLeadStatusData?.lead_status : ""}
                  onChange={updateFieldPersonStatusHandler("lead_status")}
                  data={defaultLeadStatuses}
                />
              </div>
              <label>Add Custom Lead Status</label><TextField
                fullWidth
                value={submissionPeopleLeadStatusData?.add_new_lead ? submissionPeopleLeadStatusData?.add_new_lead : ""}
                onChange={updateFieldPersonStatusHandler("add_new_lead")}
                inputProps={{
                  className: classes.textField
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={async (e) => {
                  e.preventDefault();

                  let ReturnedPromise = '';

                  if (!submissionPeopleLeadStatusData?.add_new_lead && !submissionPeopleLeadStatusData?.lead_status) {
                    alert("Lead Status Required.  Please choose or add a Lead Status.");
                    return;
                  } else if (!submissionPeopleLeadStatusData?.lead_type_text && (!submissionPeopleLeadStatusData?.lead_type || submissionPeopleLeadStatusData?.lead_type === -1)) {
                    alert("Lead Type Required.  Please choose or add a Lead Type.");
                    return;
                  }
                  if (submissionPeopleLeadStatusData) {
                    ReturnedPromise = await addPeopleLeadStatusForm({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        data: submissionPeopleLeadStatusData
                      }
                    });

                    if (ReturnedPromise.data.addLeadStatusHydrate !== 0) {
                      updateCampaign({
                        variables: {
                          data: {
                            customer_id: me?.readMe?.customer_id,
                            list_name: ReturnedPromise.data.addLeadStatusHydrate,
                            sms_text: 1,
                            mms_text: 1,
                            email: 1,
                            postcard: 1,
                            letter: 1,
                            voice_message: 1,
                            sms_text_day: 1,
                            mms_text_day: 5,
                            email_day: 3,
                            letter_day: 11,
                            postcard_day: 9,
                            voice_message_day: 7,
                            frequency_template: 3
                          }
                        }
                      });
                    }

                    setAddPersonStatus(null);
                    setSubmissionPeopleLeadStatusData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(DeletePersonStatus)}
            onClose={() => setDeletePersonStatus(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Delete Custom Lead Statuses
            </DialogTitle>
            <DialogContent>
              Select Lead Status to Delete<CustomSelect
                value={deleteData?.lead_status ? deleteData?.lead_status : ""}
                onChange={deleteFieldHandler("lead_status")}
                data={defaultLeadCustomerStatuses}
                deleteable={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!deleteData?.lead_status) {
                    alert("Lead Status Required.  Please choose a Lead Status to Delete");
                    return;
                  }
                  if (deleteData) {
                    DeleteLeadStatusForm({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        data: deleteData
                      }
                    });

                    setDeletePersonStatus(false);
                    setDeleteData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(deleteType)}
            onClose={() => setSelectedDeleteType(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Delete Custom Lead Types
            </DialogTitle>
            <DialogContent>
              Select Lead Type to Delete<CustomSelect
                value={deleteData?.lead_type ? deleteData?.lead_type : ""}
                onChange={deleteFieldHandler("lead_type")}
                data={defaultLeadCustomerTypes}
                deleteable={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                style={{ float: "right" }}
                onClick={(e) => {
                  e.preventDefault();
                  if (!deleteData?.lead_type_text && (!deleteData?.lead_type || deleteData?.lead_type === -1)) {
                    alert("Lead Type Required.  Please choose a Lead Type to Delete");
                    return;
                  }
                  if (deleteData) {
                    DeleteLeadStatusForm({
                      variables: {
                        customer_id: me?.readMe?.customer_id,
                        data: deleteData
                      }
                    });

                    setSelectedDeleteType(null);
                    setDeleteData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          {csvUploadOpen && (
            <Dialog
              open={Boolean(csvUploadOpen)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Upload {Pagevalue === 1 ? "Customers" : "Leads"}
              </DialogTitle>
              <DialogContent>
                {uploadedCount} / {totalCount} uploaded <br />
                {addedCount} added <br />
              </DialogContent>
            </Dialog>
          )}
          {ShowDuplicateFile && (
            <Dialog
              open={Boolean(ShowDuplicateFile)}
            >
              <DialogTitle>
                See Duplicate {Pagevalue === 1 ? "Leads" : "Customers"}
                <div style={{ float: "right" }}><Button onClick={() => { setShowDuplicateFile(false); setDuplicatCRMCustomers([]); }}><CloseIcon /></Button></div>
              </DialogTitle>
              <DialogContent align={'left'}>
                Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
                <br />
                <br />
                Click here to overrides the duplicates.  This will overwrite<br />your current data for {Pagevalue === 1 ? "Leads" : "Customers"}
                <br />
                <br />
                <div style={{ textAlign: "center" }}>

                  <CSVDownloader
                    incomingstyle={{ float: "left" }}
                    data={DuplicatCRMCustomers || []}
                    columns={csv_columns}
                    filename={`LinearCRM ${Pagevalue === 1 ? "Leads" : "Customers"} Duplicates (${state.customers?.selected?.name})`}
                    text={"Download"}
                  />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                    setShowOverrideLoading(true);

                    await uploadOverrideCRMCustomers({
                      fetchPolicy: "no-cache",
                      variables: {
                        customer_id,
                        leadsUpload: Pagevalue === 1 ? 0 : 1
                      }
                    })

                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}>Override</Button>
                  {ShowOverrideLoading && (
                    <>
                      <br />
                      <br />
                      Overriding...
                      <br />
                      < CircularProgress />
                    </>
                  )}
                </div>
              </DialogContent>
            </Dialog>
          )}
          {csvUploadOpen && (
            <Dialog
              open={Boolean(csvUploadOpen)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Uploading {Pagevalue === 1 ? "Leads" : Pagevalue === 2 ? "Customers" : "People"}
              </DialogTitle>
              <DialogContent>
                {uploadedCount} / {totalCount} uploaded <br />
                {addedCount} added <br />
              </DialogContent>
            </Dialog>
          )}
          {CheckingForDuplicates && (
            <Dialog
              open={Boolean(CheckingForDuplicates)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent align={'left'}>
                <h4>We're Importing your data and checking for Duplicates.  Please stay on this page.</h4><br /><br />
                <div style={{ textAlign: "center" }}>
                  <CircularProgress style={{ textAlign: 'center' }} />
                </div>
              </DialogContent>
            </Dialog>
          )}
          {ShowDuplicateFilePeople && (
            <Dialog
              open={Boolean(ShowDuplicateFilePeople)}
            >
              <DialogTitle>
                See Duplicate People
                <div style={{ float: "right" }}><Button onClick={() => { setShowDuplicateFilePeople(false); setDuplicatePeopledata([]); }}><CloseIcon /></Button></div>
              </DialogTitle>
              <DialogContent align={'left'}>
                Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
                <br />
                <br />
                Click here to overrides the duplicates.  This will overwrite<br />your current data for People and Customers
                <br />
                <br />
                <div style={{ textAlign: "center" }}>
                  <CSVDownloader
                    incomingstyle={{ float: "left" }}
                    data={DuplicatePeopledata || []}
                    columns={Peoplecsv_columns}
                    filename={`LinearCRM People Duplicates (${state.customers?.selected?.name})`}
                    text={"Download"}
                  />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                    setShowOverrideLoading(true);

                    await uploadOverrideCustomerEmails({
                      fetchPolicy: "no-cache",
                      variables: {
                        customer_id
                      }
                    })

                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });
                  }}>Override</Button>
                  {ShowOverrideLoading && (
                    <>
                      <br />
                      <br />
                      Overriding...
                      <br />
                      <CircularProgress />
                    </>
                  )}
                </div>
              </DialogContent>
            </Dialog>
          )}
        </GridItem>
      </GridContainer >
    </div >
  );
}

export default CRMSettings;