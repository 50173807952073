import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import CustomInput from "components/CustomInput/CustomInput";
import SaveButton from "components/CustomButtons/Button";

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CardAvatar from "components/Card/CardAvatar";
import Avatar from "components/Avatar";
import Dropzone from "components/Dropzone";
import SwapScreenshotsReplaceWithS3URL from "components/SwapScreenshotsReplaceWithS3URL";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Select from "components/Select/Select";
import Tooltip from '@mui/material/Tooltip';
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import AddUpdateActivity from "./AddUpdateActivity";
import AddUpdateJob from "./AddUpdateJob";
import AddUpdateNote from "./AddUpdateNote";
import AddUpdatePhotoGallery from "./AddUpdatePhotoGallery";
import AddUpdateDocument from "./AddUpdateDocument";
import AddUpdateContract from "./AddUpdateContract";
import AddUpdateProposal from "./AddUpdateProposal";
import AddUpdateEstimate from "./AddUpdateEstimate";
import AddUpdateTicket from "./AddUpdateTicket";
import LatestActivity from "./LatestActivity";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';

import LinearNoContentWrapper from "components/LinearNoContentWrapper";

import FormatPhone from "../Format/FormatPhone";
import { startCase, snakeCase } from "lodash";
import LeadDefaultImage from "assets/img/Lead.png";

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import {
  LIST_CUSTOMER_EMAILS,
  LIST_CURRENT_SOCIALS,
  UPDATE_CURRENT_SOCIALS,
  LIST_ALL_SOCIALS,
  REMOVE_CUSTOMER_SOCIAL,
  UPDATE_CRM_CUSTOMERS,
  DELETE_CRM_CUSTOMER,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_ESTIMATES,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  UPDATE_CRM_ACTIVITY,
  CREATE_CRM_DOCUMENT,
  CREATE_CRM_CONTRACT,
  CREATE_CRM_PROPOSAL,
  CREATE_CRM_JOB,
  CREATE_CRM_GALLERY,
  CREATE_CRM_ESTIMATE,
  LIST_PHOTOS,
  LIST_SERVICES,
  LIST_PRODUCT,
  LIST_TICKETS,
  GET_CUSTOMER_STATUS,
  GET_CONTRACT_STATUS,
  GET_PROPOSAL_STATUS,
  GET_ESTIMATE_STATUS,
  GET_JOB_STATUS,
  GET_SIC_CODES,
  GET_NAICS_CODES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES,
  LIST_CUSTOMERS,
  GET_FORM_DATA,
  GET_CALL_DATA,
  GET_FOLLOWING,
  UPDATE_FOLLOWING,
  CREATE_TICKET,
  LIST_TICKET_PRIORITIES,
  LIST_TICKET_TYPES,
  LIST_TICKET_STATUSES,
  GET_CRM_SETTINGS
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { LIST_CUSTOMER_USERS } from "queries/users";

import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const CRMLeadDetail = (props) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const [Pagevalue, setPagevalue] = React.useState(0);
  const [CRMCustomersReferrer, setCRMCustomersReferrer] = useState(null);
  const [ReferrerFound, setReferrerFound] = useState(null);
  const [referrer_name, setreferrer_name] = useState(null);
  const [CRMTicketPriorites, setCRMTicketPriorites] = useState(null);
  const [CRMTicketTypes, setCRMTicketTypes] = useState(null);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState(null);
  const [UpdatePhoto, setUpdatePhoto] = useState([]);
  const [PhotoHover, setPhotoPhotoHover] = useState([]);
  const [TicketsubmissionData, setTicketsubmissionData] = useState({});
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [SICCodes, setSICCodes] = useState(null);
  const [NAICSCodes, setNAICSCodes] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [OriginalCRMCustomers, setOriginalCRMCustomers] = useState(null);
  const [key, setKey] = useState(0);
  const [CRMActivitySubmission, setCRMActivitySubmission] = useState({ "activity_completed": 1 });
  const [DocumentsubmissionData, setDocumentsubmissionData] = useState({});
  const [ContractsubmissionData, setContractsubmissionData] = useState({});
  const [ProposalsubmissionData, setProposalsubmissionData] = useState({});
  const [GallerysubmissionData, setGallerysubmissionData] = useState({});
  const [JobsubmissionData, setJobsubmissionData] = useState({});
  const [EstimatesubmissionData, setEstimatesubmissionData] = useState({});
  const [CRMEstimate, setCRMEstimate] = useState(null);
  const [PersonFound, setPersonFound] = useState(true);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CallImageSelected, setCallImageSelected] = useState(false);
  const [CallFilterImageSelected, setCallFilterImageSelected] = useState(false);
  const [EmailImageSelected, setEmailImageSelected] = useState(false);
  const [EmailFilterImageSelected, setEmailFilterImageSelected] = useState(false);
  const [TextImageSelected, setTextImageSelected] = useState(false);
  const [TextFilterImageSelected, setTextFilterImageSelected] = useState(false);
  const [NoteFilterImageSelected, setNoteFilterImageSelected] = useState(false);
  const [OnboardingFilterImageSelected, setOnboardingFilterImageSelected] = useState(false);
  const [MeetingImageSelected, setMeetingImageSelected] = useState(false);
  const [MeetingFilterImageSelected, setMeetingFilterImageSelected] = useState(false);
  const [TaskImageSelected, setTaskImageSelected] = useState(false);
  const [TaskFilterImageSelected, setTaskFilterImageSelected] = useState(false);
  const [DeadlineImageSelected, setDeadlineImageSelected] = useState(false);
  const [DeadlineFilterImageSelected, setDeadlineFilterImageSelected] = useState(false);
  const [EstimateFilterImageSelected, setEstimateFilterImageSelected] = useState(false);
  const [CRMTickets, setCRMTickets] = useState(null);
  const [TicketFilterImageSelected, setTicketFilterImageSelected] = useState(false);
  const [UploadFilterImageSelected, setUploadFilterImageSelected] = useState(false);
  const [JobFilterImageSelected, setJobFilterImageSelected] = useState(false);
  const [PhotoFilterImageSelected, setPhotoFilterImageSelected] = useState(false);
  const [ContractFilterImageSelected, setContractFilterImageSelected] = useState(false);
  const [ProposalFilterImageSelected, setProposalFilterImageSelected] = useState(false);
  const [InboundCallsFilterImageSelected, setInboundCallsFilterImageSelected] = useState(false);
  const [InboundFormsFilterImageSelected, setInboundFormsFilterImageSelected] = useState(false);
  const [activity_type, setactivity_type] = useState(null);
  const [crm_customer_id, setcrm_customer_id] = useState(0);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMProducts, setCRMProducts] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [showMorePeople, setshowMorePeople] = useState(false);
  const [showMoreActivity, setshowMoreActivity] = useState(false);
  const [showMoreDocuments, setshowMoreDocuments] = useState(false);
  const [showMorePhotos, setshowMorePhotos] = useState(false);
  const [showMoreContracts, setshowMoreContracts] = useState(false);
  const [showMoreProposals, setshowMoreProposals] = useState(false);
  const [Hover, setHover] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [showMoreJobs, setshowMoreJobs] = useState(false);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [user_id, setuser_id] = useState(null);
  const [CRMSettings, setCRMSettings] = useState([]);
  const [Following, setFollowing] = useState(null);
  const [jobvalue, setjobvalue] = useState('');
  const [contractvalue, setcontractvalue] = useState('');
  const [proposalvalue, setproposalvalue] = useState('');
  const [B2B, setB2B] = useState('');
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const navigate = useNavigate();
  const crm_user_level = me?.readMe?.crm_user_level;
  const [LinearCustomer, setLinearCustomer] = useState(false);
  const [revenue, setrevenue] = useState('');
  const [FormData, setFormData] = useState([]);
  const [CallData, setCallData] = useState([]);
  const [CRMSocials, setCRMSocials] = useState(null);
  const [highestSocialID, sethighestSocialID] = useState(1);
  const [AllSocials, setAllSocials] = useState(null);
  const [competitorData, setcompetitorData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [duplicateTopProducts, setduplicateTopProducts] = useState(false);
  const [duplicateNoFocusProducts, setduplicateNoFocusProducts] = useState(false);
  const [duplicateHighPriorityLocations, setduplicateHighPriorityLocations] = useState(false);
  const [targetAreaData, settargetAreaData] = useState([]);
  const [noFocusProductData, setnoFocusProductData] = useState([]);
  const [highestCompetitorID, sethighestCompetitorID] = useState(1);
  const [highestProductID, sethighestProductID] = useState(1);
  const [highestNoFocusProductID, sethighestNoFocusProductID] = useState(1);
  const [highestTopTargetAreasID, sethighestTopTargetAreasID] = useState(1);
  const [HighTopProductHover, setHighTopProductHover] = useState([]);
  const [HighTopCompetitorsHover, setHighTopCompetitorsHover] = useState([]);
  const [HighTargetAreasHover, setHighTargetAreasHover] = useState([]);
  const [HighNoFocusHover, setHighNoFocusHover] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const FormatCurrency = () => {
    let Customer_Revenue = "0";
    if (revenue !== null && revenue !== "")
      Customer_Revenue = revenue;
    let newValue = parseFloat(Customer_Revenue?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setCRMCustomers({
      ...CRMCustomers,
      ["revenue"]: newValue
    });

    setrevenue(newValue);
  }

  const [GetFormDataQuery, { called }] = useLazyQuery(GET_FORM_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormData((data?.GetFormData || [])?.map(
        ({ form_data: form_data_str, ...rest }) => {
          const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
          const form_data_obj_normalized = {};
          Object.keys(form_data_obj).forEach(key => {
            const title = startCase(key.split("<")[0]);
            if (title === "G Recaptcha Response") {
              form_data_obj_normalized[title] = form_data_obj[key];
              return;
            }
            form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
          });
          return {
            form_data: form_data_obj_normalized,
            ...rest
          };
        }
      ))
    }
  });

  const [GetCallDataQuery, { called: callsqueried }] = useLazyQuery(GET_CALL_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCallData(data?.GetCallData || []);
    }
  });

  //  looks for inbound calls and forms so we can get there info
  let CallIDS = [];
  let FormIDS = [];
  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Form") {
      FormIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Call") {
      CallIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  useEffect(() => {
    if (called === false && FormIDS?.length) {
      GetFormDataQuery({
        variables: {
          customer_id,
          formIDS: FormIDS?.join(", ")
        }
      })
    }
    if (callsqueried === false && CallIDS?.length) {
      GetCallDataQuery({
        variables: {
          customer_id,
          callIDS: CallIDS?.join(", ")
        }
      })
    }
  }, [FormIDS, CallIDS]);

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null || value === "")
      value = "0";
    return parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateCustomerRevenueValueFieldHandler = (e) => {
    setrevenue(e.target.value);
  };

  let { data: Following_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_customer_id,
    variables: {
      customer_id: customer_id,
      object_type: "Customer",
      object_id: crm_customer_id,
    }
  });

  const [submitUpdateCRMFollowingRequest] = useMutation(
    UPDATE_FOLLOWING,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        setFollowing(data?.updateCRMFollowing);
      }
    }
  );

  let { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  let { data: CustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      status: 0
    }
  });

  let { data: Activity_data, refetch: refetchActivity } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Proposal_data, refetch: refetchProposal } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Contract_data, refetch: refetchContracts } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Job_data, refetch: refetchJobs } = useQuery(LIST_JOBS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_Gallery_data, refetch: refetchGallery } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      id: crm_customer_id ? parseInt(crm_customer_id) : 0,
      // status 0 means lead not customer
      status: 0
    }
  });

  useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: referrer_name,
      status: -1,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMCustomersReferrer(data?.CRMCustomers || []);
    }
  });

  let { data: Representative_data, refetch: refetchRepresentatives } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data, refetch: refetchDocument } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0
    }
  });

  let { data: people_data, refetch: refetchPeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0,
      page: 0,
      rows_per_page: 100,
    }
  });

  let { data: social_data, refetch: refetchSocial } = useQuery(LIST_CURRENT_SOCIALS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id: crm_customer_id
    }
  });

  let { data: ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_priorities_data } = useQuery(LIST_TICKET_PRIORITIES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_types_data } = useQuery(LIST_TICKET_TYPES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  let { data: all_social_data } = useQuery(LIST_ALL_SOCIALS, {
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    }
  });

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: product_data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: { customer_id: customer_id }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  let { data: EstimateStatus_data } = useQuery(GET_ESTIMATE_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      customonly: 0
    }
  });

  let { data: SICCodes_data } = useQuery(GET_SIC_CODES);
  let { data: NAICSCodes_data } = useQuery(GET_NAICS_CODES);
  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  useQuery(GET_POSTAL_CODE,
    {
      skip: !CRMCustomers?.office_zip,
      variables: {
        name: CRMCustomers?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !CRMCustomers?.office_city,
      variables: {
        name: CRMCustomers?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  EstimateStatus_data = EstimateStatus_data?.getEstimateStatus || [];
  CustomerStatus_data = CustomerStatus_data?.getCustomerStatus || [];

  let CustomerStatuses = [], EstimateStatuses = [];

  if (CustomerStatus_data) {
    CustomerStatuses.push({ label: "select", value: 0 });
    CustomerStatus_data.forEach(obj => {
      CustomerStatuses.push({ label: obj.customer_status_text, value: obj.id })
    })
  }

  if (EstimateStatus_data) {
    EstimateStatuses.push({ label: "select", value: 0 });
    EstimateStatus_data.forEach(obj => {
      EstimateStatuses.push({ label: obj.estimate_status_text, value: obj.id })
    })
  }

  let { data: ContactStatus_data } = useQuery(GET_CONTRACT_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id
    }
  });

  ContactStatus_data = ContactStatus_data?.getContractStatus || [];

  let ContractStatuses = [];

  if (ContactStatus_data) {
    ContractStatuses.push({ label: "select", value: 0 });
    ContactStatus_data.forEach(obj => {
      ContractStatuses.push({ label: obj.contract_status_text, value: obj.id })
    })
  }

  let { data: ProposalStatus_data } = useQuery(GET_PROPOSAL_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id
    }
  });

  let { data: JobStatus_data } = useQuery(GET_JOB_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: -1
    }
  });

  ProposalStatus_data = ProposalStatus_data?.getProposalStatus || [];
  JobStatus_data = JobStatus_data?.getJobStatus || [];

  let ProposalStatuses = [], JobStatuses = [];

  if (ProposalStatus_data) {
    ProposalStatuses.push({ label: "select", value: 0 });
    ProposalStatus_data.forEach(obj => {
      ProposalStatuses.push({ label: obj.proposal_status_text, value: obj.id })
    })
  }

  if (JobStatus_data) {
    JobStatuses.push({ label: "select", value: 0 });
    JobStatus_data.forEach(obj => {
      JobStatuses.push({ label: obj.job_status_text, value: obj.id })
    })
  }

  useEffect(() => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);

    if (urlParams.get("id")) {
      setcrm_customer_id(parseInt(urlParams.get("id")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    GetCRMSettings();
    setuser_id(me?.readMe?.id);

    setFollowing(Following_data?.getFollowing);

    setCRMAssignedToUsers(users?.listCustomerUsers || []);

    setSICCodes(SICCodes_data?.getSICCodes || []);
    setNAICSCodes(NAICSCodes_data?.getNAICSCodes || []);
    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);

    setCRMCustomers({
      ...data?.CRMCustomers[0], ["office_phone_number"]: FormatPhone(data?.CRMCustomers[0]?.office_phone_number),
      ["referrer_name"]: data?.CRMCustomers[0]?.referrer_id ? CRMCustomersReferrer?.filter(CurrentCustomer =>
        CurrentCustomer?.id === data?.CRMCustomers[0]?.referrer_id)?.map((CurrentCustomer) => { setReferrerFound(true); return CurrentCustomer?.business_name })?.join("") : ""
    });

    setCRMActivity(Activity_data?.ListActivity || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMProducts(product_data?.ListProduct || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);
    setCRMSocials(social_data?.listCurrentSocials || []);
    setAllSocials(all_social_data?.listAllSocials || []);
    setCRMTickets(ticket_data?.ListTickets || []);
    setCRMTicketPriorites(ticket_priorities_data?.ListTicketPriorities || []);
    setCRMTicketTypes(ticket_types_data?.ListTicketTypes || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [data, Activity_data, Proposal_data, Contract_data, Job_data, Photo_Gallery_data, Representative_data,
    document_data, people_data, users, service_data, product_data, budget_data, customer_data,
    Following_data, ticket_statuses_data, ticket_types_data, ticket_priorities_data, ticket_data]);

  useEffect(() => {
    setOriginalCRMCustomers(CRMCustomers);
    setRepresentative(CRMCustomers?.representative_id);

    if (CRMSocials?.length) {
      sethighestSocialID(CRMSocials[CRMSocials?.length - 1].id + 1);
    }
  });

  const classes = useStyles();

  const handleOpen = () => {
    setUpdatedCustomerEmail({});
  };

  const handleClose = () => () => {
    setUpdatedCustomerEmail(null);
    refetchPeople();
  };
  const updateFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.length) {
        CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.map((CurrentCustomer) => {
          setCRMCustomers({
            ...CRMCustomers,
            [key]: e.target.value,
            ["referrer_name"]: CurrentCustomer?.business_name
          });

          setreferrer_name(e.target.value);
          setReferrerFound(true);
        })
      }
      else {
        setCRMCustomers({
          ...CRMCustomers,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        setreferrer_name(e.target.value);
        setReferrerFound(false);
      }
    } else if (key === "assigned_to" || key === "customer_status") {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e
      });
    } else {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.value
      });
    }
  };

  const updateSICFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateNAICSFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateCountryFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateReferrerHandler = (key, id, name) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: id,
      ["referrer_name"]: name
    });

    setReferrerFound(true);
  };

  const updateCityHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const [submitCreateCRMTicket] = useMutation(
    CREATE_TICKET,
    {
      onCompleted: async (TicketData) => {
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        // If user added document activity notes
        if (CRMActivitySubmission) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                assigned_to: TicketsubmissionData?.assigned_to,
                activity_type: "Ticket",
                activity_description: "Created Ticket " + TicketsubmissionData?.name +
                  " for " + CRMCustomers?.business_name,
                ticket_id: TicketData?.createCRMTicket?.id,
                representative_id: TicketData?.createCRMTicket?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setDocumentsubmissionData({});
        setTicketsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
        refetchTickets();
      }
    }
  );

  const [submitCreateCRMEstimate] = useMutation(
    CREATE_CRM_ESTIMATE,
    {
      onCompleted: async (EstimateData) => {
        // If user added estimate activity notes
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        if (CRMActivitySubmission) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Estimate",
                activity_description: "Created Estimate " + EstimatesubmissionData?.estimate_name + " for " + CRMCustomers?.business_name,
                estimate_id: EstimateData?.createCRMEstimate?.id,
                representative_id: EstimateData?.createCRMEstimate?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();


      }
    }
  );

  const AddCompetitor = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestCompetitorID, customers_top_competitors: '', top_competitors_url: '' })
    sethighestCompetitorID(highestCompetitorID + 1)
    setcompetitorData({ ...competitorData, [competitorData?.length || Object.keys(competitorData)?.length]: NewArray });
  }

  const AddTargetArea = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTopTargetAreasID, customer_top_target_area: '', customer_top_target_type: '', product_id: product_id })
    sethighestTopTargetAreasID(highestTopTargetAreasID + 1)
    settargetAreaData({ ...targetAreaData, [targetAreaData?.length || Object.keys(targetAreaData)?.length]: NewArray });
  }

  const AddProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestProductID, product_focus: '', product_id: product_id })
    sethighestProductID(highestProductID + 1)
    setproductData({ ...productData, [productData?.length || Object.keys(productData)?.length]: NewArray });
  }

  const AddNoFocusProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestNoFocusProductID, no_product_focus: '', product_id: product_id })
    sethighestNoFocusProductID(highestNoFocusProductID + 1)
    setnoFocusProductData({ ...noFocusProductData, [noFocusProductData?.length || Object.keys(noFocusProductData)?.length]: NewArray });
  }

  const RemoveCompetitor = (key) => e => {
    setcompetitorData(Object.keys(competitorData)?.filter((Competitor) => competitorData[Competitor].id !== key)?.map((Competitor) => competitorData[Competitor]));
  };

  const RemoveProduct = (key) => e => {
    setproductData(Object.keys(productData)?.filter((CurrentProduct) => productData[CurrentProduct].id !== key)?.map((CurrentProduct) => productData[CurrentProduct]));
  };

  const RemoveNoFocusProduct = (key) => e => {
    setnoFocusProductData(Object.keys(noFocusProductData)?.filter((noFocusProduct) => noFocusProductData[noFocusProduct].id !== key)?.map((noFocusProduct) => noFocusProductData[noFocusProduct]));
  };

  const RemoveTargetArea = (key) => e => {
    settargetAreaData(Object.keys(targetAreaData)?.filter((newTargetArea) => targetAreaData[newTargetArea].id !== key)?.map((newTargetArea) => targetAreaData[newTargetArea]));
  };

  function updateTopCompetitors(index, key, e) {
    let EstimatesubmissionDataClone = Object.assign({}, competitorData);
    Object.keys(EstimatesubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "customers_top_competitors") {
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { customers_top_competitors: e.target.value });
        }
        else
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { top_competitors_url: e.target.value });
      }
      setcompetitorData({ ...EstimatesubmissionDataClone });
    })
  };

  function updateTopProducts(index, e, product_id) {
    let ProductsubmissionDataClone = Object.assign({}, productData);
    Object.keys(ProductsubmissionDataClone).map((item, i) => {
      if (index === i && product_id === ProductsubmissionDataClone[i]?.product_id) {
        ProductsubmissionDataClone[i] = Object.assign({}, ProductsubmissionDataClone[i], { product_focus: e.target.value, product_id: product_id });
      }
      setproductData({ ...ProductsubmissionDataClone });
    })
  };

  function updateNoFocusProducts(index, e, product_id) {
    let NoFocussubmissionDataClone = Object.assign({}, noFocusProductData);
    Object.keys(NoFocussubmissionDataClone).map((item, i) => {
      if (index === i && product_id === NoFocussubmissionDataClone[i]?.product_id) {
        NoFocussubmissionDataClone[i] = Object.assign({}, NoFocussubmissionDataClone[i], { no_product_focus: e.target.value, product_id: product_id });
      }
      setnoFocusProductData({ ...NoFocussubmissionDataClone });
    })
  };

  function updateTargetAreas(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_area: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  function updateTargetType(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_type: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  const [RemoveCustomerSocial] = useMutation(
    REMOVE_CUSTOMER_SOCIAL
  );

  function updateSocialssocial_channel_url(index, e) {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_url: e.target.value });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const updateSocialsocial_channel_id = (index) => e => {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_id: e });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const AddSocial = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestSocialID, social_channel_id: '', social_channel_url: '', people_id: null, crm_customer_id: crm_customer_id })
    sethighestSocialID(highestSocialID + 1)
    setCRMSocials({ ...CRMSocials, [Object.keys(CRMSocials)?.length]: NewArray });
  }

  const RemoveSocial = (key) => e => {
    RemoveCustomerSocial({
      fetchPolicy: "no-cache",
      variables: {
        customer_id: state.customers?.selected?.id,
        id: key
      },
      onCompleted: () => {
        setCRMSocials(Object.keys(CRMSocials)?.map((Social, index) => {
          if (CRMSocials[index].id !== key)
            return CRMSocials[index]
        })?.filter(Social => Social !== undefined))

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    });
  };

  const [updateSocials] = useMutation(
    UPDATE_CURRENT_SOCIALS,
    {
      skip: !state.customers?.selected?.id,
      skip: !CRMSocials
    });

  const social_columns = [
    {
      Header: "Social",
      accessor: "social_channel"
    },
    {
      Header: "Remove"
    }
  ];

  const updateGalleryFieldHandler = key => (e) => {
    if (key === "representative_id" || key === "assigned_to" || key === "proposal_id"
      || key === "contract_id" || key === "job_id") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        [key]: e
      });
    } else if (key === "photo_gallery_url") {
      setGallerysubmissionData({
        ...omit(GallerysubmissionData, ["photo_gallery_url"]),
        [key]: e.target.value
      });
    }
    else {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updateDocumentFieldHandler = (key) => e => {
    if (key === "representative_id" || key === "assigned_to") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        [key]: e
      });
    } else {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updateTicketFieldHandler = (key) => e => {
    if (key === "representative_id" || key === "assigned_to" || key === "type" ||
      key === "status" || key === "contract_id" || key === "job_id"
      || key === "proposal_id" || key === "photo_gallery_id" ||
      key === "estimate_id" || key === "document_id" ||
      key === "priority" || key === "type") {
      setTicketsubmissionData({
        ...TicketsubmissionData,
        [key]: e
      });
    } else {
      setTicketsubmissionData({
        ...TicketsubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updateJobFieldHandler = (key) => e => {
    if (key === "job_status" && typeof e === 'string') {
      JobStatuses?.map((CurrentJob) => {
        if (CurrentJob?.label === e) {
          setJobsubmissionData({
            ...JobsubmissionData,
            [key]: CurrentJob?.value
          });
        }
      })
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "contract_id"
      || key === "proposal_id" || key === "photo_gallery_id" || key === "job_status") {
      setJobsubmissionData({
        ...JobsubmissionData,
        [key]: e
      });
    } else {
      setJobsubmissionData({
        ...JobsubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updateProposalFieldHandler = (key) => e => {
    let BudgetFound = false;

    if (ProposalsubmissionData?.Budgets !== undefined) {
      Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
        if (key?.split("_")[0] === ProposalsubmissionData?.Budgets[index]?.LocalID)
          BudgetFound = true;
      })
    }

    if (key === "proposal_status" && typeof e === 'string') {
      ProposalStatuses?.map((CurrentProposal) => {
        if (CurrentProposal?.label === e) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            [key]: CurrentProposal?.value
          });
        }
      })
    }
    else if (key?.indexOf("monthlybudget") > -1) {
      if (ProposalsubmissionData?.Budgets === undefined || BudgetFound === false) {
        let tempid = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (tempid == '')
          tempid = null;
        else
          tempid = parseInt(tempid);

        let product_id = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (product_id == '')
          product_id = null;
        else
          product_id = parseInt(product_id);

        let product_name = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        setProposalsubmissionData({
          ...ProposalsubmissionData,
          ["Budgets"]: { [ProposalsubmissionData?.Budgets ? Object.keys(ProposalsubmissionData?.Budgets)?.length : 0]: { "product_name": product_name, "LocalID": key?.split("_")[0], "monthly_budget": e.target.value, "budget_cycles_product_id": tempid, product_id: product_id }, ...ProposalsubmissionData?.Budgets }
        });
      } else if (BudgetFound) {
        Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
          if (ProposalsubmissionData?.Budgets[index]?.LocalID === key?.split("_")[0]) {
            let NewArray = Object.assign({}, { "product_name": ProposalsubmissionData?.Budgets[index]?.product_name, "LocalID": ProposalsubmissionData?.Budgets[index]?.LocalID, "monthly_budget": e.target.value, "budget_cycles_product_id": ProposalsubmissionData?.Budgets[index]?.budget_cycles_product_id, product_id: ProposalsubmissionData?.Budgets[index]?.product_id })
            setProposalsubmissionData({
              ...ProposalsubmissionData,
              ["Budgets"]: { [index]: NewArray, ...Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, i) => { if (index !== i) return ProposalsubmissionData?.Budgets[i] })?.filter(CurrentBudget => CurrentBudget !== undefined) }
            });
          }
        })
      }
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "job_id"
      || key === "photo_gallery_id" || key === "proposal_status") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        [key]: e
      });
    } else {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        [key]: e.target.value
      });
    }
  };


  const updateContractFieldHandler = (key) => e => {
    let BudgetFound = false;

    if (ContractsubmissionData?.Budgets !== undefined) {
      Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
        if (key?.split("_")[0] === ContractsubmissionData?.Budgets[index]?.LocalID)
          BudgetFound = true;
      })
    }

    if (key === "contract_status" && typeof e === 'string') {
      ContractStatuses?.map((CurrentContract) => {
        if (CurrentContract?.label === e) {
          setContractsubmissionData({
            ...ContractsubmissionData,
            [key]: CurrentContract?.value
          });
        }
      })
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "job_id"
      || key === "photo_gallery_id" || key === "contract_status") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        [key]: e
      });
    } else if (key?.indexOf("monthlybudget") > -1) {
      if (ContractsubmissionData?.Budgets === undefined || BudgetFound === false) {
        let tempid = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (tempid == '')
          tempid = null;
        else
          tempid = parseInt(tempid);

        let product_id = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (product_id == '')
          product_id = null;
        else
          product_id = parseInt(product_id);

        let product_name = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        setContractsubmissionData({
          ...ContractsubmissionData,
          ["Budgets"]: { [ContractsubmissionData?.Budgets ? Object.keys(ContractsubmissionData?.Budgets)?.length : 0]: { "product_name": product_name, "LocalID": key?.split("_")[0], "monthly_budget": e.target.value, "budget_cycles_product_id": tempid, product_id: product_id }, ...ContractsubmissionData?.Budgets }
        });
      } else if (BudgetFound) {
        Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
          if (ContractsubmissionData?.Budgets[index]?.LocalID === key?.split("_")[0]) {
            let NewArray = Object.assign({}, { "product_name": ContractsubmissionData?.Budgets[index]?.product_name, "LocalID": ContractsubmissionData?.Budgets[index]?.LocalID, "monthly_budget": e.target.value, "budget_cycles_product_id": ContractsubmissionData?.Budgets[index]?.budget_cycles_product_id, product_id: ContractsubmissionData?.Budgets[index]?.product_id })
            setContractsubmissionData({
              ...ContractsubmissionData,
              ["Budgets"]: { [index]: NewArray, ...Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, i) => { if (index !== i) return ContractsubmissionData?.Budgets[i] })?.filter(CurrentBudget => CurrentBudget !== undefined) }
            });
          }
        })
      }
    }
    else {
      setContractsubmissionData({
        ...ContractsubmissionData,
        [key]: e.target.value
      });
    }
  }

  const updatePeopleNameFieldHandler = (value, object) => {
    if (object === "Contract") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setContractsubmissionData({
            ...ContractsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setDocumentsubmissionData({
            ...DocumentsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setGallerysubmissionData({
            ...GallerysubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setJobsubmissionData({
            ...JobsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setCRMActivitySubmission({
            ...CRMActivitySubmission,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Estimate") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setEstimatesubmissionData({
            ...EstimatesubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    }
  };

  const updatePeopleFieldHandler = (key, object) => e => {
    if (object === "Estimate") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    }
    else if (object === "Contract") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    }
  };

  const updateActivityFieldHandler = (key) => e => {
    if (key === "activity_completed") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (key === "activity_date") {
      setCRMActivitySubmission({
        ...omit(CRMActivitySubmission, ["activity_completed"]),
        [key]: e.target.value,
        ["activity_completed"]: 0
      });
    } else if (key === "assigned_to" || key === "representative_id" || key === "activity_notes") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e
      });
    } else {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.value
      });
    }
  };

  const updateEstimateFieldHandler = (key) => e => {
    let BudgetFound = false;

    if (EstimatesubmissionData?.Budgets !== undefined) {
      Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => {
        if (key?.split("_")[0] === EstimatesubmissionData?.Budgets[index]?.LocalID)
          BudgetFound = true;
      })
    }

    if (key === "estimate_status" && typeof e === 'string') {
      EstimateStatuses?.map((CurrentEstimate) => {
        if (CurrentEstimate?.label === e) {
          setEstimatesubmissionData({
            ...EstimatesubmissionData,
            [key]: CurrentEstimate?.value
          });
        }
      })
    } else if (key === "estimate_status") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e
      });
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "job_id"
      || key === "photo_gallery_id" || key === "contract_status") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e
      });
    } else if (key?.indexOf("monthlybudget") > -1) {
      if (EstimatesubmissionData?.Budgets === undefined || BudgetFound === false) {
        let tempid = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (tempid == '')
          tempid = null;
        else
          tempid = parseInt(tempid);

        let product_id = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (product_id == '')
          product_id = null;
        else
          product_id = parseInt(product_id);

        let product_name = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        setEstimatesubmissionData({
          ...EstimatesubmissionData,
          ["Budgets"]: { [EstimatesubmissionData?.Budgets ? Object.keys(EstimatesubmissionData?.Budgets)?.length : 0]: { "product_name": product_name, "LocalID": key?.split("_")[0], "monthly_budget": e.target.value, "budget_cycles_product_id": tempid, product_id: product_id }, ...EstimatesubmissionData?.Budgets }
        });
      } else if (BudgetFound) {
        Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => {
          if (EstimatesubmissionData?.Budgets[index]?.LocalID === key?.split("_")[0]) {
            let NewArray = Object.assign({}, { "product_name": EstimatesubmissionData?.Budgets[index]?.product_name, "LocalID": EstimatesubmissionData?.Budgets[index]?.LocalID, "monthly_budget": e.target.value, "budget_cycles_product_id": EstimatesubmissionData?.Budgets[index]?.budget_cycles_product_id, product_id: EstimatesubmissionData?.Budgets[index]?.product_id })
            setEstimatesubmissionData({
              ...EstimatesubmissionData,
              ["Budgets"]: { [index]: NewArray, ...Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, i) => { if (index !== i) return EstimatesubmissionData?.Budgets[i] })?.filter(CurrentBudget => CurrentBudget !== undefined) }
            });
          }
        })
      }
    } else {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updateEstimateDatabase = async () => {
    if (!EstimatesubmissionData?.estimate_name) {
      alert("Estimate Name is Required");
      return false;
    }

    if (!EstimatesubmissionData?.Budgets) {
      alert("Product Budgets are Required");
      return false;
    }

    if (EstimatesubmissionData?.products?.filter((CurrentProduct) => CurrentProduct)?.length !== Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => CurrentBudget)?.length) {
      alert("All Product Budgets are Required");
      return false;
    }

    if (EstimatesubmissionData) {
      await submitCreateCRMEstimate({
        variables: {
          customer_id,
          EstimateData: {
            ...omit(EstimatesubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name", "contract_status"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            services: EstimatesubmissionData?.services ? EstimatesubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: EstimatesubmissionData?.products ? EstimatesubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            representative_id: EstimatesubmissionData?.representative_id ? EstimatesubmissionData?.representative_id : 1,
            estimate_status: typeof EstimatesubmissionData?.estimate_status === 'string' ? parseInt(EstimateStatuses?.map((CurrentEstimate) => { if (CurrentEstimate?.label === EstimatesubmissionData?.estimate_status) return CurrentEstimate?.value })?.filter(CurrentEstimate => CurrentEstimate !== undefined)?.join("")) : EstimatesubmissionData?.estimate_status ? EstimatesubmissionData?.estimate_status : null,
            TopCompetitors: Object.keys(competitorData).map((keyName, index) => { return omit(competitorData[keyName], ["__typename"]) }),
            TopProducts: Object.keys(productData).map((keyName, index) => { return omit(productData[keyName], ["__typename"]) }),
            NoFocusProducts: Object.keys(noFocusProductData).map((keyName, index) => { return omit(noFocusProductData[keyName], ["__typename"]) }),
            TargetAreas: Object.keys(targetAreaData).map((keyName, index) => { return omit(targetAreaData[keyName], ["__typename"]) }),
            Budgets: EstimatesubmissionData?.Budgets ? Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => { return omit(EstimatesubmissionData?.Budgets[index], "LocalID") }) : null,
            business_name: CRMCustomers?.business_name,
            business_address: CRMCustomers?.office_address_line_one +
              CRMCustomers?.office_address_line_two + " " +
              CRMCustomers?.office_city + " " +
              CRMCustomers?.office_state + " " +
              CRMCustomers?.office_zip + " " +
              (CRMCustomers?.office_country !== null ? CRMCustomers?.office_country : ""),
            business_type: CRMCustomers?.industry,
            website_url: CRMCustomers?.website
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateActivityDatabase = async (CurrentTab = "") => {
    if (CurrentTab === "Activity" && !activity_type) {
      alert("Activity Type is Required");
      return false;
    }
    if (CurrentTab === "Activity" && CRMActivitySubmission?.activity_description === "") {
      alert("Activity Description is Required");
      return false;
    }

    if (CurrentTab === "Note" && !CRMActivitySubmission?.activity_notes) {
      alert("Activity Note is Required");
      return false;
    }

    // If on the notes tab
    if (CRMActivitySubmission && CurrentTab === "Note") {
      let newActivityNotes = '';

      if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
        newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id);
      }

      await submitUpdateCRMActivity({
        variables: {
          customer_id,
          ActivityData: {
            ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "activity_date", "activity_notes"]),
            users_id: user_id,
            activity_type: "Note",
            crm_customers_id: crm_customer_id,
            representative_id: CRMActivitySubmission?.representative_id ? CRMActivitySubmission?.representative_id : 1,
            activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
            activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    } else if (CRMActivitySubmission && CurrentTab === "Activity") {
      let newActivityNotes = '';

      if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
        newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
      }

      await submitUpdateCRMActivity({
        variables: {
          customer_id,
          ActivityData: {
            ...omit(CRMActivitySubmission, ["__typename", "representative_id", "activity_date", "activity_notes"]),
            users_id: user_id,
            activity_type: activity_type ? activity_type : CRMActivitySubmission?.activity_type,
            crm_customers_id: crm_customer_id,
            representative_id: CRMActivitySubmission?.representative_id ? CRMActivitySubmission?.representative_id : 1,
            activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
            activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });

      window.location.reload();
    }
  };

  const updateGalleryDatabase = async () => {
    if (!GallerysubmissionData?.photo_gallery_name) {
      alert("Photo Gallery Name is Required");
      return false;
    }

    if (!GallerysubmissionData?.photo_gallery_description) {
      alert("Photo Gallery Description is Required");
      return false;
    }

    if (!GallerysubmissionData?.photo_gallery_url) {
      alert("At Least 1 Photo is Required");
      return false;
    }

    if (GallerysubmissionData) {
      await submitCreateCRMGallery({
        variables: {
          customer_id,
          GalleryData: {
            ...omit(GallerysubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            representative_id: GallerysubmissionData?.representative_id ? GallerysubmissionData?.representative_id : 1
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateDocumentDatabase = async () => {
    if (!DocumentsubmissionData?.documents_name) {
      alert("Document Name is Required");
      return false;
    }

    if (!DocumentsubmissionData?.documents_url) {
      alert("Document File is Required");
      return false;
    }

    if (DocumentsubmissionData) {
      await submitCreateCRMDocument({
        variables: {
          customer_id,
          DocumentData: {
            ...omit(DocumentsubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            representative_id: DocumentsubmissionData?.representative_id ? DocumentsubmissionData?.representative_id : 1
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateTicketDatabase = async () => {
    if (!TicketsubmissionData?.name) {
      alert("Ticket Name is Required");
      return false;
    }

    if (!TicketsubmissionData?.type) {
      alert("Ticket Type is Required");
      return false;
    }

    if (!TicketsubmissionData?.priority) {
      alert("Ticket Priority is Required");
      return false;
    }

    if (!TicketsubmissionData?.requested_completion_date) {
      alert("Ticket Completion Date is Required");
      return false;
    }

    if (!TicketsubmissionData?.assigned_to) {
      alert("Ticket Assigned To is Required");
      return false;
    }

    if (!CRMActivitySubmission?.activity_notes && CRMTicketNotes?.length === 0) {
      alert("Ticket Comments are Required");
      return false;
    }

    if (TicketsubmissionData) {
      let newActivityNotes = '';

      if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
        newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
      }

      await submitCreateCRMTicket({
        variables: {
          customer_id,
          TicketData: {
            ...omit(TicketsubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name"]),
            owner_id: user_id,
            crm_customers_id: crm_customer_id,
            cc_users: TicketsubmissionData?.cc_users ? TicketsubmissionData?.cc_users?.map((SelectedUser) => { const ReturnedValue = CRMAssignedToUsers?.map((CurrentUser) => { if (SelectedUser === (CurrentUser?.first_name + " " + CurrentUser?.last_name)) { return CurrentUser?.id } })?.filter(CurrentUser => CurrentUser !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            services: TicketsubmissionData?.services ? TicketsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: TicketsubmissionData?.products ? TicketsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes,
            status: 1
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateContractDatabase = async () => {
    if (!ContractsubmissionData?.contract_name) {
      alert("Contract Name is Required");
      return false;
    }

    if (!ContractsubmissionData?.contract_url) {
      alert("Contract File is Required");
      return false;
    }

    if ((customer_id === 38 || customer_id === 38) && !ContractsubmissionData?.people_id) {
      alert("Contract Person Required");
      return false;
    }

    if (!ContractsubmissionData?.Budgets) {
      alert("Product Budgets are Required");
      return false;
    }

    if (ContractsubmissionData?.products?.filter((CurrentProduct) => CurrentProduct)?.length !== Object.keys(ContractsubmissionData?.Budgets)?.filter((CurrentBudget) => CurrentBudget)?.length) {
      alert("All Product Budgets are Required");
      return false;
    }

    if (ContractsubmissionData) {
      await submitCreateCRMContract({
        variables: {
          customer_id,
          ContractData: {
            ...omit(ContractsubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name", "contract_status"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            contract_value: ContractsubmissionData?.contract_value ? ContractsubmissionData?.contract_value?.replace(/[^0-9.]+/g, "") : "",
            services: ContractsubmissionData?.services ? ContractsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: ContractsubmissionData?.products ? ContractsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            representative_id: ContractsubmissionData?.representative_id ? ContractsubmissionData?.representative_id : 1,
            contract_status: typeof ContractsubmissionData?.contract_status === 'string' ? parseInt(ContractStatuses?.map((CurrentContract) => { if (CurrentContract?.label === ContractsubmissionData?.contract_status) return CurrentContract?.value })?.filter(CurrentContract => CurrentContract !== undefined)?.join("")) : ContractsubmissionData?.contract_status ? ContractsubmissionData?.contract_status : null,
            Budgets: Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, index) => { return omit(ContractsubmissionData?.Budgets[index], "LocalID") })
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateJobDatabase = async () => {
    if (JobsubmissionData) {
      await submitCreateCRMJob({
        variables: {
          customer_id,
          JobData: {
            ...omit(JobsubmissionData, ["__typename", "activity_completed", "services", "products", "representative_id", "first_name", "last_name", "people_name"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            job_value: JobsubmissionData?.job_value ? JobsubmissionData?.job_value?.replace(/[^0-9.]+/g, "") : "",
            services: JobsubmissionData?.services ? JobsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: JobsubmissionData?.products ? JobsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            representative_id: JobsubmissionData?.representative_id ? JobsubmissionData?.representative_id : 1,
            job_status: typeof JobsubmissionData?.job_status === 'string' ? parseInt(JobStatuses?.map((CurrentJob) => { if (CurrentJob?.label === JobsubmissionData?.job_status) return CurrentJob?.value })?.filter(CurrentJob => CurrentJob !== undefined)?.join("")) : JobsubmissionData?.job_status ? JobsubmissionData?.job_status : null,
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateProposalDatabase = async () => {
    if (!ProposalsubmissionData?.proposal_name) {
      alert("Proposal Name is Required");
      return false;
    }

    if (!ProposalsubmissionData?.proposal_url) {
      alert("Proposal File is Required");
      return false;
    }

    if (!ProposalsubmissionData?.Budgets) {
      alert("Product Budgets are Required");
      return false;
    }

    if (ProposalsubmissionData?.products?.filter((CurrentProduct) => CurrentProduct)?.length !== Object.keys(ProposalsubmissionData?.Budgets)?.filter((CurrentBudget) => CurrentBudget)?.length) {
      alert("All Product Budgets are Required");
      return false;
    }

    if (ProposalsubmissionData) {
      await submitCreateCRMProposal({
        variables: {
          customer_id,
          ProposalData: {
            ...omit(ProposalsubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name", "proposal_status"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            proposal_value: ProposalsubmissionData?.proposal_value ? ProposalsubmissionData?.proposal_value?.replace(/[^0-9.]+/g, "") : "",
            services: ProposalsubmissionData?.services ? ProposalsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: ProposalsubmissionData?.products ? ProposalsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            representative_id: ProposalsubmissionData?.representative_id ? ProposalsubmissionData?.representative_id : 1,
            proposal_status: typeof ProposalsubmissionData?.proposal_status === 'string' ? parseInt(ProposalStatuses?.map((CurrentProposal) => { if (CurrentProposal?.label === ProposalsubmissionData?.proposal_status) return CurrentProposal?.value })?.filter(CurrentProposal => CurrentProposal !== undefined)?.join("")) : ProposalsubmissionData?.proposal_status ? ProposalsubmissionData?.proposal_status : null,
            Budgets: Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => { return omit(ProposalsubmissionData?.Budgets[index], "LocalID") })
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateDatabase = async (profile_image, customer_status) => {
    if (CRMCustomers?.business_name === OriginalCRMCustomers?.business_name &&
      CRMCustomers?.office_phone_number === OriginalCRMCustomers?.office_phone_number &&
      CRMCustomers?.office_address_line_one === OriginalCRMCustomers?.office_address_line_one &&
      CRMCustomers?.office_address_line_two === OriginalCRMCustomers?.office_address_line_two &&
      CRMCustomers?.office_city === OriginalCRMCustomers?.office_city &&
      CRMCustomers?.office_country === OriginalCRMCustomers?.office_country &&
      CRMCustomers?.office_state === OriginalCRMCustomers?.office_state &&
      CRMCustomers?.office_zip === OriginalCRMCustomers?.office_zip &&
      CRMCustomers?.website === OriginalCRMCustomers?.website &&
      CRMCustomers?.industry === OriginalCRMCustomers?.industry &&
      CRMCustomers?.revenue === OriginalCRMCustomers?.revenue &&
      CRMCustomers?.sic_code === OriginalCRMCustomers?.sic_code &&
      CRMCustomers?.naics_code === OriginalCRMCustomers?.naics_code &&
      CRMCustomers?.employees === OriginalCRMCustomers?.employees &&
      CRMCustomers?.established === OriginalCRMCustomers?.established &&
      (!profile_image && !customer_status)) {
      return
    }

    if (customer_status !== OriginalCRMCustomers?.customer_status && OriginalCRMCustomers?.customer_status) {
      if (!window.confirm("Are you sure you want to switch the Customer Status?"))
        return
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "representative_id", "first_name", "last_name", "status", "revenue", "lead_status", "referrer_name"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
            status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
            profile_image: profile_image ? profile_image : null,
            customer_status: customer_status ? customer_status : null,
            users_id: user_id,
            representative_id: CRMCustomers?.representative_id ? CRMCustomers?.representative_id : 1,
            lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (CRMCustomers?.length) {
    csvData.push(
      ...CRMCustomers.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const handleChangeAppBarPage = (event, newValue) => {
    setKey(key + 1);
    setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
    setContractsubmissionData({});
    setProposalsubmissionData({});
    setDocumentsubmissionData({});
    setGallerysubmissionData({});
    setJobsubmissionData({});
    setuploadPercentage("");
    setPagevalue(newValue);

    //notes
    if (newValue === 0) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
      setNoteFilterImageSelected(true);
    }
    // skip activities because it's not 1 to 1
    //job
    else if (newValue === 2) {
      setJobFilterImageSelected(true);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
    }
    //photo
    else if (newValue === 3) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(true);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
    }
    //document
    else if (newValue === 4) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(true);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
    }//esimtate
    else if (newValue === 5) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(true);
      setTicketFilterImageSelected(false);
    }
    //proposals
    else if (newValue === 6) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(true);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
    }
    //contract
    else if (newValue === 7) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(true);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(false);
    }
    //ticket
    else if (newValue === 8) {
      setJobFilterImageSelected(false);
      setUploadFilterImageSelected(false);
      setDeadlineFilterImageSelected(false);
      setTaskFilterImageSelected(false);
      setMeetingFilterImageSelected(false);
      setEmailFilterImageSelected(false);
      setTextFilterImageSelected(false);
      setPhotoFilterImageSelected(false);
      setContractFilterImageSelected(false);
      setProposalFilterImageSelected(false);
      setInboundCallsFilterImageSelected(false);
      setInboundFormsFilterImageSelected(false);
      setCallFilterImageSelected(false);
      setNoteFilterImageSelected(false);
      setEstimateFilterImageSelected(false);
      setTicketFilterImageSelected(true);
    }
  };

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: async (data) => {
        if (data?.updateCRMCustomers?.update_status !== 1) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "key"]),
                users_id: user_id,
                activity_type: "Lead",
                activity_description: "Updated Lead " + CRMCustomers?.business_name,
                representative_id: CRMCustomers?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
              }
            }
          });
        }
        else {
          LoadNewNotifications();
          setKey(key + 1);
          setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
          setactivity_type("");
          setDocumentsubmissionData({});
          setGallerysubmissionData({});
          setContractsubmissionData({});
          setProposalsubmissionData({});
          setJobsubmissionData({});
          setuploadPercentage("");
          // go to the note tab to reload
          setPagevalue(0);


          refetchActivity();
          refetchProposal();
          refetchContracts();
          refetchJobs();
          refetchGallery();
          refetchPhotos();
          refetchRepresentatives();
          refetchDocument();
          refetchPeople();
          refetchEsimates();
        }
      }
    }
  );

  const [submitUpdateCRMActivity] = useMutation(
    UPDATE_CRM_ACTIVITY,
    {
      onCompleted: () => {
        LoadNewNotifications();
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setactivity_type("");
        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);


        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
      }
    }
  );

  const [submitCreateCRMGallery] = useMutation(
    CREATE_CRM_GALLERY,
    {
      onCompleted: async (GalleryData) => {
        // If user added gallery activity notes
        if (CRMActivitySubmission) {
          let newActivityNotes = '';

          if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
            newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
          }

          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Photo",
                activity_description: "Created Photo Gallery " + GallerysubmissionData?.photo_gallery_name + " for " + CRMCustomers?.business_name,
                photo_gallery_id: GalleryData?.createCRMGallery?.id,
                representative_id: GalleryData?.createCRMGallery?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);


        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();


      }
    }
  );


  const [submitCreateCRMDocument] = useMutation(
    CREATE_CRM_DOCUMENT,
    {
      onCompleted: async (DocumentData) => {
        // If user added document activity notes
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        if (CRMActivitySubmission) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Document",
                activity_description: "Created Document " + DocumentsubmissionData?.documents_name + " for " + CRMCustomers?.business_name,
                document_id: DocumentData?.createCRMDocument?.id,
                representative_id: DocumentData?.createCRMDocument?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setProposalsubmissionData({});
        setJobsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();


      }
    }
  );

  const [submitCreateCRMContract] = useMutation(
    CREATE_CRM_CONTRACT,
    {
      onCompleted: async (ContractData) => {
        // If user added document activity notes
        if (CRMActivitySubmission) {
          let newActivityNotes = '';

          if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
            newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
          }

          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Contract",
                activity_description: "Created Contract " + ContractsubmissionData?.contract_name + " for " + CRMCustomers?.business_name,
                contract_id: ContractData?.createCRMContract?.id,
                representative_id: ContractData?.createCRMContract?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }
      }
    }
  );

  const [submitCreateCRMJob] = useMutation(
    CREATE_CRM_JOB,
    {
      onCompleted: async (JobData) => {
        // If user added job activity notes
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        if (CRMActivitySubmission) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Job",
                activity_description: "Created Job " + JobsubmissionData?.job_name + " for " + CRMCustomers?.business_name,
                job_id: JobData?.createCRMJob?.id,
                representative_id: JobData?.createCRMJob?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setProposalsubmissionData({});
        setJobsubmissionData({});
        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();


      }
    }
  );


  const [submitCreateCRMProposal] = useMutation(
    CREATE_CRM_PROPOSAL,
    {
      onCompleted: async (ProposalData) => {
        // If user added proposal activity notes
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        if (CRMActivitySubmission) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Proposal",
                activity_description: "Created Proposal " + ProposalsubmissionData?.proposal_name + " for " + CRMCustomers?.business_name,
                proposal_id: ProposalData?.createCRMProposal?.id,
                representative_id: ProposalData?.createCRMProposal?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }

        setProposalsubmissionData({});
        setJobsubmissionData({});
        setDocumentsubmissionData({});
        setGallerysubmissionData({});
        setContractsubmissionData({});
        setKey(key + 1);
        setCRMActivitySubmission({ "activity_completed": 1, "key": key + 1 });
        setuploadPercentage("");
        // go to the note tab to reload
        setPagevalue(0);

        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();


      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CRM_CUSTOMER,
    {
      onCompleted: () => {
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  let ShownActivityCount = 0;

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <label
                    style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}
                  ><FlagOutlinedIcon /> {CRMCustomers?.business_name || ""}</label>
                </div>
              </div>
              {crm_user_level === "crm_admin" && (
                <div style={{ display: "inline-block", float: "right" }}>
                  <Button style={{ backgroundColor: "#0071ce", color: "#ffffff", float: "left", marginTop: "10px" }} onClick={async () => {
                    await submitUpdateCRMRequest({
                      variables: {
                        customer_id,
                        BusinessData: {
                          ...omit(CRMCustomers, ["__typename", "update_time", "last_name", "first_name", "status", "revenue", "lead_status", "referrer_name"]),
                          revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
                          status: "1",
                          lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
                        }
                      }
                    });
                    navigate("/admin/crmcustomerdetail?customer=" + customer_id + "&id=" + CRMCustomers?.id);
                  }}>
                    Convert To Customer
                  </Button>
                  <Tooltip title="Delete Lead" placement="top">
                    <Button style={{ color: Hover === true ? "red" : "#000", float: "right", backgroundColor: "#FFF", marginTop: "13px" }} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        submitDeleteRequest({ skip: !customer_id || !crm_customer_id, variables: { customer_id: customer_id, id: crm_customer_id } });
                        navigate("/admin/crmcustomers?customer=" + customer_id);
                      }
                    }}>
                      <FontAwesomeIcon size="lg" icon={Hover ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} />
                    </Button>
                  </Tooltip>
                </div>
              )}
            </CardBody>
          </Card>          
          <GridContainer marginTop="20px">
            <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                  <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmleaddetail") === -1 ? <a href={`/admin/crmleaddetail?customer=${customer_id}&id=${crm_customer_id}`} ><FlagOutlinedIcon /> View Profile</a> : <div style={{ visibility: "hidden" }}><FlagOutlinedIcon /> View Profile</div>}</div>
                  <CardAvatar profile style={{ display: "inline-flex", alignItems: "right", float: "right !important" }}>
                    <Dropzone
                      path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                      accept={{
                        'image/jpeg': [".jpeg"],
                        'image/png': [".png"]
                      }}
                      onChange={(value) => {
                        updateFieldHandler("profile_image")({ target: { value } });
                        updateDatabase(value);
                      }}
                    >
                      {() => (
                        <Avatar
                          url={CRMCustomers?.profile_image || LeadDefaultImage}
                          size={130}
                        />
                      )}
                    </Dropzone>
                  </CardAvatar>

                  <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                    onClick={
                      async () => {
                        if (Following > 0) {
                          await submitUpdateCRMFollowingRequest({
                            variables: {
                              customer_id: customer_id,
                              object_type: "Customer",
                              object_id: crm_customer_id,
                              add: 0,
                              id: Following
                            }
                          });
                        } else {
                          await submitUpdateCRMFollowingRequest({
                            variables: {
                              customer_id: customer_id,
                              object_type: "Customer",
                              object_id: crm_customer_id,
                              add: 1,
                            }
                          });
                        }
                      }} >{Following > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                <CardBody>
                  <h6 className={classes.cardCategory}>
                    Ideal image upload size is 400 x 400
                  </h6>
                  <Tooltip title="Call" placement="top">
                    <a href={"tel:" + CRMCustomers?.office_phone_number}><PhoneOutlinedIcon style={{ marginRight: "8px" }} /></a>
                  </Tooltip>
                  {/*<a href={"mailto:" + CRMCustomers?.office_phone_number}><EmailOutlinedIcon style={{ marginRight: "8px" }} /></a>*/}
                  <Tooltip title="Text" placement="top">
                    <a href={"sms:" + CRMCustomers?.office_phone_number}><SmartphoneOutlinedIcon style={{ marginRight: "8px" }} /></a>
                  </Tooltip>
                  <Tooltip title="Meeting" placement="top">
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 1);
                      setDeadlineImageSelected(false);
                      setTaskImageSelected(false);
                      setTextImageSelected(false);
                      setEmailImageSelected(false);
                      setCallImageSelected(false);
                      setMeetingImageSelected(true);
                      setactivity_type("Meeting")
                      setCRMActivitySubmission({
                        ...CRMActivitySubmission,
                        ["activity_description"]: "Meeting"
                      });
                    }}>
                      <GroupAddOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Task" placement="top">
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 1);
                      setMeetingImageSelected(false);
                      setDeadlineImageSelected(false);
                      setTextImageSelected(false);
                      setEmailImageSelected(false);
                      setCallImageSelected(false);
                      setTaskImageSelected(true);
                      setactivity_type("Task")
                      setCRMActivitySubmission({
                        ...CRMActivitySubmission,
                        ["activity_description"]: "Task"
                      });
                    }}>
                      <TaskAltOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Deadline" placement="top">
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 1);
                      setMeetingImageSelected(false);
                      setTaskImageSelected(false);
                      setTextImageSelected(false);
                      setEmailImageSelected(false);
                      setCallImageSelected(false);
                      setDeadlineImageSelected(true);
                      setactivity_type("Deadline")
                      setCRMActivitySubmission({
                        ...CRMActivitySubmission,
                        ["activity_description"]: "Deadline"
                      });
                    }}>
                      <MenuOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Jobs" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_jobs ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 2);
                    }}>
                      <WorkOutlineOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Photos" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_gallery ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 3);
                    }}>
                      <PhotoCameraOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Documents" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_documents ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 4);
                    }}>
                      <UploadFileOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Estimates" placement="top" style={{ display: (customer_id === 38 || customer_id === 165) && CRMSettings?.length && !CRMSettings[0]?.hide_estimates ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 5);
                    }}>
                      <FactCheckOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Proposals" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_proposals ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 6);
                    }}>
                      <DescriptionOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Contracts" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_contracts ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 7);
                    }}>
                      <RequestPageOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <Tooltip title="Tickets" placement="top" style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_tickets ? "" : "none" }}>
                    <a href="#" onClick={() => {
                      handleChangeAppBarPage("", 8);
                    }}>
                      <ConfirmationNumberOutlinedIcon style={{ marginRight: "8px" }} />
                    </a>
                  </Tooltip>
                  <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                  <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Business Name</b></label>
                  <input
                    id="business_name"
                    maxLength={255}
                    type="text"
                    value={CRMCustomers?.business_name || ""}
                    onChange={updateFieldHandler("business_name")}
                    onBlur={() => updateDatabase()}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", textAlign: "center" }}
                  />
                  <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Address</b></label>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Line 1</label>
                    <input
                      id="office_address_line_one"
                      maxLength={255}
                      type="text"
                      value={CRMCustomers?.office_address_line_one || ""}
                      onChange={updateFieldHandler("office_address_line_one")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Line 2</label>
                    <input
                      id="office_address_line_two"
                      maxLength={255}
                      type="text"
                      value={CRMCustomers?.office_address_line_two || ""}
                      onChange={updateFieldHandler("office_address_line_two")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>City</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_city"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateCityHandler("office_city", newValue.key);
                        }}
                        value={CRMCustomers?.office_city ? { key: CRMCustomers?.office_city, label: CRMCustomers?.office_city } :
                          { key: "", label: "" }}
                        options={City?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("office_city")(e); }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>State</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_state"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateStateHandler("office_state", newValue.key);
                        }}
                        value={CRMCustomers?.office_state ? { key: CRMCustomers?.office_state, label: CRMCustomers?.office_state } :
                          { key: "", label: "" }}
                        options={States?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("office_state")(e); } }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>Zip</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_zip"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePostalCodeHandler("office_zip", newValue.key);
                        }}
                        value={CRMCustomers?.office_zip ? { key: CRMCustomers?.office_zip, label: CRMCustomers?.office_zip } :
                          { key: "", label: "" }}
                        options={PostalCode?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("office_zip")(e); }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>Country</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_country"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateCountryFieldHandler("office_country", newValue.key);
                        }}
                        value={CRMCustomers?.office_country ? { key: CRMCustomers?.office_country, label: CRMCustomers?.office_country } :
                          { key: "", label: "" }}
                        options={Countries?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("office_country")(e); } }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Phone</label>
                    <input
                      id="office_phone_number"
                      maxLength={255}
                      type="text"
                      value={CRMCustomers?.office_phone_number || ""}
                      onChange={updateFieldHandler("office_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      onBlur={() => {
                        setCRMCustomers({
                          ...CRMCustomers,
                          ["office_phone_number"]: FormatPhone(CRMCustomers?.office_phone_number)
                        });

                        updateDatabase();
                      }}
                      onFocus={() => {
                        setCRMCustomers({
                          ...CRMCustomers,
                          ["office_phone_number"]: CRMCustomers?.office_phone_number?.replace(/[^0-9]/g, "")
                        });
                      }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Website</label>
                    <input
                      id="website"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.website || ""}
                      onChange={updateFieldHandler("website")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Industry</label>
                    <input
                      id="industry"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.industry || ""}
                      onChange={updateFieldHandler("industry")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Revenue</label>
                    <input
                      id="revenue"
                      maxLength={15}
                      type="text"
                      value={revenue === '' && CRMCustomers?.revenue !== undefined && CRMCustomers?.revenue !== '' ? FormatCurrencyWithoutUpdatingState(CRMCustomers?.revenue) : revenue}
                      onBlur={() => {
                        FormatCurrency();
                        if (CRMCustomers?.revenue)
                          updateDatabase();
                      }}
                      onFocus={() => {
                        setCRMCustomers({
                          ...CRMCustomers,
                          ["revenue"]: ''
                        });
                        setrevenue('');
                      }}
                      onChange={(e) => { updateFieldHandler("revenue", e); updateCustomerRevenueValueFieldHandler(e) }}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>SIC Code</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="sic_code"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateSICFieldHandler("sic_code", newValue.key);
                        }}
                        value={SICCodes?.filter((CurrentSICCode) => CurrentSICCode?.sic_code === CRMCustomers?.sic_code)?.map((CurrentSICCode) => {
                          return { key: CurrentSICCode?.sic_code, label: CurrentSICCode?.sic_code + " - " + CurrentSICCode?.description }
                        })[0] || ""}
                        options={SICCodes?.map(({ sic_code, description }) => ({
                          key: sic_code,
                          label: sic_code + " - " + description
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("sic_code")(e); } }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "20%" }}>NAICS Code</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="naics_code"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateNAICSFieldHandler("naics_code", newValue.key);
                        }}
                        value={NAICSCodes?.filter((CurrentNAICSCode) => CurrentNAICSCode?.naics_code === CRMCustomers?.naics_code)?.map((CurrentNAICSCode) => {
                          return { key: CurrentNAICSCode?.naics_code, label: CurrentNAICSCode?.naics_code + " - " + CurrentNAICSCode?.title }
                        })[0] || ""}
                        options={NAICSCodes?.map(({ naics_code, title }) => ({
                          key: naics_code,
                          label: naics_code + " - " + title
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("naics_code")(e); } }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Employees</label>
                    <input
                      id="employees"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.employees || ""}
                      onChange={updateFieldHandler("employees")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div style={{ display: "block" }}>
                    <label style={{ marginTop: "15px", float: "left" }}>Established</label>
                    <input
                      id="established"
                      maxLength={2000}
                      type="text"
                      value={CRMCustomers?.established || ""}
                      onChange={updateFieldHandler("established")}
                      onBlur={() => updateDatabase()}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                    />
                  </div>
                  <div style={{ clear: "both", marginBottom: "20px" }}></div>
                  <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Lead Status:</label>
                  <Select
                    selected={CRMCustomers?.customer_status || 0}
                    onSelect={(value) => {
                      updateFieldHandler("customer_status")(value);
                      if (value !== "select") {
                        updateDatabase("", value)
                      }
                    }}
                    options={CustomerStatuses?.map(({ label, value }) => ({
                      key: value,
                      label: label
                    }))}
                    style={{ marginBottom: 20, float: "left", height: "40px" }}
                  />
                  <div style={{ clear: "both", marginBottom: "20px" }}></div>

                  <div>
                    <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                    <Select
                      options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                        key: id,
                        label: first_name + " " + last_name
                      }))}
                      selected={CRMCustomers?.assigned_to || ""}
                      onSelect={async (value) => {
                        if (value !== OriginalCRMCustomers?.assigned_to && OriginalCRMCustomers?.assigned_to) {
                          if (!window.confirm("Are you sure you want to switch the Assigned To?"))
                            return
                        }

                        updateFieldHandler("assigned_to")(value);

                        await submitUpdateCRMRequest({
                          variables: {
                            customer_id,
                            BusinessData: {
                              ...omit(CRMCustomers, ["__typename", "update_time", "last_name", "first_name", "status", "revenue", "lead_status", "referrer_name"]),
                              id: crm_customer_id,
                              assigned_to: value,
                              revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
                              status: CRMCustomers?.status === undefined ? "0" : `${CRMCustomers?.status}`,
                              lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
                            }
                          }
                        });

                        const notify = options => {
                          const key = enqueueSnackbar(options?.text, {
                            variant: options?.variant,
                            persist: true
                          });
                          setTimeout(() => {
                            closeSnackbar(key);
                          }, options?.delay || 2000);
                        }
                        notify({
                          text: "Information has been successfully submitted",
                          variant: "success"
                        });
                      }}
                      style={{ marginBottom: 20, height: "40px", float: "left" }}
                    />
                  </div>
                  <div style={{ clear: "both", marginBottom: "20px" }}></div>
                  <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="referrer_id"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                      onChange={(e, newValue) => {
                        updateReferrerHandler("referrer_id", newValue.key, newValue.label);
                      }}
                      value={CRMCustomers?.referrer_name ? { key: CRMCustomers?.referrer_id, label: CRMCustomers?.referrer_name } :
                        { key: "", label: "" }}
                      options={CRMCustomersReferrer?.map(({ business_name, id }) => ({
                        key: id,
                        label: business_name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        if (option === "")
                          return "";
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center", width: "100%" }
                          }}
                          onChange={e => { updateFieldHandler("referrer_name")(e); }}
                          onBlur={() => updateDatabase()}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                  {CRMCustomers?.referrer_name && !ReferrerFound ?
                    <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                  <div style={{ clear: "both", marginBottom: "20px" }}></div>
                </CardBody>
              </Card>
              {AllSocials?.length ?
                <>
                  <Card>
                    <CardBody>
                      <label>Social Channels</label>
                      <br />
                      <br />
                      <TableContainer>
                        <Table stickyHeader>
                          <TableRow>
                            {social_columns?.map((datacolumns) => (
                              <TableCell style={{ textAlign: "left", padding: "0px", margin: "0px" }}><label className={classes.selectFormControl}>{datacolumns?.Header}</label></TableCell>
                            ))}
                          </TableRow>
                          {CRMSocials ? Object.keys(CRMSocials)?.map((keyName, index) => (
                            <>
                              <TableRow key={CRMSocials[keyName]?.id}>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <Select
                                    options={AllSocials?.map(({ id, social_channel }) => ({
                                      key: id,
                                      label: social_channel
                                    }))}
                                    selected={CRMSocials[keyName].social_channel_id || 0}
                                    onSelect={updateSocialsocial_channel_id(index, "social_channel_id")}
                                    style={{ marginBottom: 2, height: "40px" }}
                                  />&nbsp;&nbsp;
                                  {CRMSocials[keyName]?.social_channel_url ? <a target="_blank" href={CRMSocials[keyName].social_channel_url}>Go To Link</a> : ""}
                                </TableCell>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <a style={{ cursor: "pointer" }} onClick={RemoveSocial(CRMSocials[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: "5px", margin: "0px", width: "100%" }} colSpan="2">
                                  <CustomInput
                                    id={`customers_top_products${CRMSocials[keyName].id}`}
                                    maxLength="2048"
                                    type="text"
                                    key={`customers_top_products${CRMSocials[keyName].id}`}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: event => { updateSocialssocial_channel_url(index, event) },
                                      defaultValue: CRMSocials[keyName]?.social_channel_url ? CRMSocials[keyName].social_channel_url : ''
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          )) : ""}
                        </Table>
                      </TableContainer>
                      <br />
                      <a style={{ cursor: "pointer" }} onClick={AddSocial}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Social Channel</a>
                      <div style={{ clear: "both", height: "30px" }}></div>
                      <SaveButton
                        color="orange"
                        type="submit"
                        onClick={() => {
                          let ErrorPresent = false;
                          Object.keys(CRMSocials)?.map((keyName, index) => {
                            if (CRMSocials[keyName].social_channel_id === null || CRMSocials[keyName].social_channel_id === '') {
                              alert("Please Select a Social Channel");
                              ErrorPresent = true;
                              return;
                            }
                            else if (CRMSocials[keyName].social_channel_url === null || CRMSocials[keyName].social_channel_url === '') {
                              alert("Please Enter a Social URL");
                              ErrorPresent = true;
                              return;
                            }

                            updateSocials({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                data: omit(CRMSocials[keyName], ["__typename"])
                              }
                            })
                          })

                          if (ErrorPresent === false) {
                            refetchSocial();

                            const notify = options => {
                              const key = enqueueSnackbar(options?.text, {
                                variant: options?.variant,
                                persist: true
                              });
                              setTimeout(() => {
                                closeSnackbar(key);
                              }, options?.delay || 2000);
                            }
                            notify({
                              text: "Information has been successfully submitted",
                              variant: "success"
                            });
                          }
                        }
                        }
                      >
                        Submit
                      </SaveButton>
                    </CardBody>
                  </Card>
                </>
                : ""}
              {/* PEOPLE */}
              <Card profile>
                <CardBody>
                  <label style={{ marginTop: "30px" }}>People</label>
                  <br />
                  <br />
                  {CRMPeople?.filter((CurrentPerson) => CurrentPerson?.crm_customers_id === crm_customer_id).length > 0 && (
                    crm_customer_id ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.crm_customers_id === crm_customer_id).map((CurrentPerson, index) => {
                      if (index > 4 && showMorePeople === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "20%" }}>Name</label>
                              <a href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + CurrentPerson?.crm_customers_id + "&peopleid=" + CurrentPerson?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                {CurrentPerson?.first_name} {CurrentPerson?.last_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentPerson?.cell_phone_number ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "20%" }}>Cell</label>
                              <label style={{ width: "80%", textAlign: "left" }}>
                                {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.cell_phone_number) : ""}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentPerson?.office_phone_number ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "20%" }}>Office</label>
                              <label style={{ width: "80%", textAlign: "left" }}>
                                {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.office_phone_number) : ""}
                                &nbsp;&nbsp;&nbsp;{CurrentPerson?.office_extension ? "X   " + CurrentPerson?.office_extension : ""}{CurrentPerson?.office_phone_number ? <br /> : ""}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentPerson?.email ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "20%" }}>Email</label>
                              <label style={{ width: "80%", textAlign: "left" }}>
                                {CurrentPerson?.cell_phone_number ? CurrentPerson?.email : ""}</label>
                            </div> : ""}
                            <br />
                          </>
                        )
                      }
                    }) : "")}
                  {CRMPeople?.filter((CurrentPerson) => CurrentPerson?.crm_customers_id === crm_customer_id).length > 5 ?
                    <a href="#" onClick={e => {
                      e.preventDefault();
                      if (showMorePeople) {
                        setshowMorePeople(false);
                      } else {
                        setshowMorePeople(true)
                      }
                    }}>
                      {showMorePeople ? "See Less..." : "See More..."}
                    </a>
                    : ""}
                  <br />
                  <div>
                    <a href="#"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen()
                      }}>+ People</a>
                  </div>
                </CardBody>
              </Card>
              {/* Jobs */}
              {CRMJob?.filter((CurrentJob) => CurrentJob?.crm_customers_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Jobs</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMJob?.filter((CurrentJob) => CurrentJob?.crm_customers_id === crm_customer_id).map((CurrentJob, index) => {
                      if (index > 4 && showMoreJobs === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ clear: "both" }}></div>
                            {CurrentJob?.job_name ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                <a
                                  href={"/admin/crmjobdetail?customer=" + customer_id + "&id=" + CurrentJob?.crm_customers_id + "&peopleid=" + CurrentJob?.people_id + "&jobid=" + CurrentJob?.id}
                                >
                                  {CurrentJob?.job_name}</a></label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentJob?.job_scheduled_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Scheduled Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentJob?.job_scheduled_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentJob?.job_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentJob?.job_status}</label>
                            </div> : ""}
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMJob?.filter((CurrentJob) => CurrentJob?.crm_customers_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreJobs) {
                          setshowMoreJobs(false);
                        } else {
                          setshowMoreJobs(true)
                        }
                      }}>
                        {showMoreJobs ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* PHOTO GALLERY */}
              {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customers_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Photo Gallery</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customers_id === crm_customer_id).map((CurrentPhoto, index) => {
                      if (index > 4 && showMorePhotos === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + CurrentPhoto?.crm_customers_id + "&peopleid=" + CurrentPhoto?.people_id + "&photoid=" + CurrentPhoto?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                {CurrentPhoto?.photo_gallery_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_description ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Description</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentPhoto?.photo_gallery_description}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_last_updated ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentPhoto?.photo_gallery_last_updated))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customers_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMorePhotos) {
                          setshowMorePhotos(false);
                        } else {
                          setshowMorePhotos(true)
                        }
                      }}>
                        {showMorePhotos ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* DOCUMENTS */}
              {CRMDocument?.filter((CurrentDocument) => CurrentDocument?.crm_customers_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Documents</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMDocument?.filter((CurrentDocument) => CurrentDocument?.crm_customers_id === crm_customer_id).map((CurrentDocument, index) => {
                      if (index > 4 && showMoreDocuments === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + CurrentDocument?.crm_customers_id + "&peopleid=" + CurrentDocument?.people_id + "&documentid=" + CurrentDocument?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                {CurrentDocument?.documents_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentDocument?.documents_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentDocument?.documents_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentDocument?.documents_update_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentDocument?.documents_update_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMDocument?.filter((CurrentDocument) => CurrentDocument?.crm_customers_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreDocuments) {
                          setshowMoreDocuments(false);
                        } else {
                          setshowMoreDocuments(true)
                        }
                      }}>
                        {showMoreDocuments ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* CONTRACT */}
              {CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customers_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Contracts</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customers_id === crm_customer_id).map((CurrentContract, index) => {
                      if (index > 4 && showMoreContracts === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href={"/admin/crmcontractdetail?customer=" + customer_id + "&id=" + CurrentContract?.crm_customers_id + "&peopleid=" + CurrentContract?.people_id + "&contractid=" + CurrentContract?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                {CurrentContract?.contract_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentContract?.contract_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_value}</label>
                            </div> : ""}
                            {CurrentContract?.contract_start_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Start Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_start_date}</label>
                            </div> : ""}
                            {CurrentContract?.contract_end_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>End Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_end_date}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customers_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreContracts) {
                          setshowMoreContracts(false);
                        } else {
                          setshowMoreContracts(true)
                        }
                      }}>
                        {showMoreContracts ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* PROPOSAL */}
              {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customers_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Proposals</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customers_id === crm_customer_id).map((CurrentProposal, index) => {
                      if (index > 4 && showMoreProposals === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href={"/admin/crmproposaldetail?customer=" + customer_id + "&id=" + CurrentProposal?.crm_customers_id + "&peopleid=" + CurrentProposal?.people_id + "&proposalid=" + CurrentProposal?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                {CurrentProposal?.proposal_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentProposal?.proposal_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_value}</label>
                            </div> : ""}
                            {CurrentProposal?.proposal_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentProposal?.proposal_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customers_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreProposals) {
                          setshowMoreProposals(false);
                        } else {
                          setshowMoreProposals(true)
                        }
                      }}>
                        {showMoreProposals ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardBody>
                  <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
                    <Tabs value={Pagevalue} onChange={handleChangeAppBarPage} variant="scrollable" scrollButtons={true}>
                      <Tab label="Notes" {...a11yProps(0)} borderleft={1} borderright={1} />
                      <Tab label="Activity" {...a11yProps(1)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_jobs ? "" : "none" }} label="Jobs" {...a11yProps(2)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_gallery ? "" : "none" }} label="Photos" {...a11yProps(3)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_documents ? "" : "none" }} label="Documents" {...a11yProps(4)} borderleft={1} borderright={1} />
                      <Tab style={{ display: (customer_id === 38 || customer_id === 165) && CRMSettings?.length && !CRMSettings[0]?.hide_estimates ? "" : "none" }} label="Estimates" {...a11yProps(5)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_proposals ? "" : "none" }} label="Proposals" {...a11yProps(6)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_contracts ? "" : "none" }} label="Contracts" {...a11yProps(7)} borderleft={1} borderright={1} />
                      <Tab style={{ display: CRMSettings?.length && !CRMSettings[0]?.hide_tickets ? "" : "none" }} label="Tickets" {...a11yProps(8)} borderleft={1} borderright={1} />
                    </Tabs>
                  </AppBar>
                  {/*  Notes  */}
                  <TabPanel value={Pagevalue} index={0} style={{ marginTop: "20px" }}>
                    {AddUpdateNote(CRMActivitySubmission, updateActivityFieldHandler, CRMAssignedToUsers, CRMRepresentative, updateActivityDatabase)}
                  </TabPanel>
                  {/*  Activity  */}
                  <TabPanel value={Pagevalue} index={1} style={{ textAlign: "center", marginTop: "20px" }}>
                    {AddUpdateActivity(setCallImageSelected,
                      setDeadlineImageSelected, setTaskImageSelected, setMeetingImageSelected,
                      setEmailImageSelected, setTextImageSelected, CallImageSelected, EmailImageSelected,
                      TextImageSelected, MeetingImageSelected, TaskImageSelected, DeadlineImageSelected,
                      CRMActivitySubmission, updateActivityFieldHandler, me, updateActivityDatabase,
                      setactivity_type, activity_type, setCRMActivitySubmission, CRMAssignedToUsers,
                      CRMRepresentative, CRMCustomers?.business_name, false, CRMPeople, PersonFound,
                      updatePeopleNameFieldHandler, updatePeopleFieldHandler
                    )}
                  </TabPanel>
                  {/*  Jobs  */}
                  <TabPanel value={Pagevalue} index={2} style={{ marginTop: "20px" }}>
                    {AddUpdateJob(CRMActivitySubmission, updateActivityFieldHandler, updateJobDatabase,
                      CRMPeople, JobsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, CRMProducts, CRMServices, updateJobFieldHandler, PersonFound,
                      false, [], CRMContract, CRMProposal, CRMPhotoGallery, setJobsubmissionData, jobvalue, setjobvalue,
                      JobStatuses)}
                  </TabPanel>
                  {/*  Photos  */}
                  <TabPanel value={Pagevalue} index={3} style={{ marginTop: "20px" }}>
                    {AddUpdatePhotoGallery(updateActivityFieldHandler, updateGalleryDatabase,
                      CRMPeople, updateGalleryFieldHandler, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, GallerysubmissionData, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id, null, null, null, false, [], CRMJob, CRMProposal, CRMContract, PhotoHover,
                      setPhotoPhotoHover, UpdatePhoto, setUpdatePhoto)}
                  </TabPanel>
                  {/*  Documents  */}
                  <TabPanel value={Pagevalue} index={4} style={{ marginTop: "20px" }}>
                    {AddUpdateDocument(CRMActivitySubmission, updateActivityFieldHandler, updateDocumentDatabase,
                      CRMPeople, DocumentsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, updateDocumentFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id)}
                  </TabPanel>
                  {/*  Estimates  */}
                  {customer_id === 38 || customer_id === 165 ?
                    <TabPanel value={Pagevalue} index={5} style={{ marginTop: "20px" }}>
                      {AddUpdateEstimate(CRMActivitySubmission, updateActivityFieldHandler, updateEstimateDatabase,
                        CRMPeople, EstimatesubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                        updatePeopleNameFieldHandler, updateEstimateFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                        customer_id, CRMProducts, CRMServices, false, CRMActivity, CRMPhotoGallery, CRMJob,
                        setEstimatesubmissionData, EstimateStatuses, me?.readMe?.user_level,
                        competitorData, productData, targetAreaData, noFocusProductData, AddCompetitor, AddProduct,
                        AddNoFocusProduct, AddTargetArea, HighTopProductHover, setHighTopProductHover,
                        RemoveCompetitor, RemoveProduct, RemoveNoFocusProduct, RemoveTargetArea, updateTopCompetitors,
                        updateTopProducts, updateNoFocusProducts, updateTargetAreas, updateTargetType,
                        HighTopCompetitorsHover, setHighTopCompetitorsHover, HighTargetAreasHover, setHighTargetAreasHover,
                        HighNoFocusHover, setHighNoFocusHover, null, CRMProposal, setproductData,
                        setnoFocusProductData, settargetAreaData, duplicateTopProducts, duplicateNoFocusProducts, duplicateHighPriorityLocations,
                        setduplicateTopProducts, setduplicateNoFocusProducts, setduplicateHighPriorityLocations)}
                    </TabPanel>
                    : ""}
                  {/*  Proposals  */}
                  <TabPanel value={Pagevalue} index={customer_id === 38 || customer_id === 165 ? 6 : 5} style={{ marginTop: "20px" }}>
                    {AddUpdateProposal(CRMActivitySubmission, updateActivityFieldHandler, updateProposalDatabase,
                      CRMPeople, ProposalsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, updateProposalFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id, CRMProducts, CRMServices, false, null, CRMPhotoGallery, CRMJob,
                      setProposalsubmissionData, proposalvalue, setproposalvalue, ProposalStatuses)}
                  </TabPanel>
                  {/*  Contracts  */}
                  <TabPanel value={Pagevalue} index={customer_id === 38 || customer_id === 165 ? 7 : 6} style={{ marginTop: "20px" }}>
                    {AddUpdateContract(CRMActivitySubmission, updateActivityFieldHandler, updateContractDatabase,
                      CRMPeople, ContractsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, updateContractFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id, CRMProducts, CRMServices, false, null, CRMPhotoGallery, CRMJob,
                      setContractsubmissionData, contractvalue, setcontractvalue, ContractStatuses, me?.readMe?.user_level)}
                  </TabPanel>
                  {/*  Tickets  */}
                  <TabPanel value={Pagevalue} index={customer_id === 38 || customer_id === 165 ? 8 : 7} style={{ marginTop: "20px" }}>
                    {AddUpdateTicket(updateActivityFieldHandler, updateTicketDatabase, CRMTicketPriorites, CRMTicketTypes,
                      CRMPeople, TicketsubmissionData, CRMAssignedToUsers, updatePeopleFieldHandler, B2B, CRMTicketStatuses,
                      updatePeopleNameFieldHandler, updateTicketFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id, false, [], CRMPhotoGallery, CRMContract, CRMProposal, CRMJob, CRMDocument, CRMEstimate,
                      CRMProducts, CRMServices, "", expanded, setExpanded)}
                  </TabPanel>
                </CardBody>
              </Card>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  {LatestActivity(NoteFilterImageSelected, CallFilterImageSelected, EmailFilterImageSelected, TextFilterImageSelected,
                    MeetingFilterImageSelected, TaskFilterImageSelected, DeadlineFilterImageSelected, JobFilterImageSelected, TicketFilterImageSelected,
                    PhotoFilterImageSelected, UploadFilterImageSelected, InboundFormsFilterImageSelected, OnboardingFilterImageSelected, setOnboardingFilterImageSelected,
                    InboundCallsFilterImageSelected, ProposalFilterImageSelected, ContractFilterImageSelected, EstimateFilterImageSelected, setEstimateFilterImageSelected,
                    setNoteFilterImageSelected, setJobFilterImageSelected, setUploadFilterImageSelected, setDeadlineFilterImageSelected,
                    setTaskFilterImageSelected, setMeetingFilterImageSelected, setEmailFilterImageSelected, setTicketFilterImageSelected,
                    setTextFilterImageSelected, setPhotoFilterImageSelected, setContractFilterImageSelected, setProposalFilterImageSelected,
                    setInboundCallsFilterImageSelected, setInboundFormsFilterImageSelected, setCallFilterImageSelected,
                    ShownActivityCount, showMoreActivity, setshowMoreActivity, CRMActivity, users, CRMRepresentative, CRMContract, CRMProposal, CRMDocument,
                    CRMPhotoGallery, CRMJob, customer_id, FormData, CallData, CRMPeople, [], [], CRMEstimate, CRMTickets, CRMSettings)}
                  {updatedCustomerEmail && (
                    <Dialog
                      open={Boolean(updatedCustomerEmail)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateCustomerProfile
                          updated={updatedCustomerEmail}
                          handleClose={handleClose}
                          setCustomers={setCRMCustomers}
                          customers={CRMCustomers}
                          crm_customer_id={crm_customer_id}
                          source={"Linear"}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

CRMLeadDetail.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMLeadDetail;
