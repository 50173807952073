import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

const styles = {
  loading: {
    opacity: 0.7,
    pointerEvents: "none"
  },
  grid: {
    margin: "0 -15px",
    width: "calc(100% + 30px)"
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  }
};

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const classes = useStyles();
  const { children, className, loading, ...rest } = props;
  return (
    <Grid
      container
      {...rest}
      className={`${classes.grid} ${className} ${
        loading ? classes.loading : ""
      }`}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Grid>
  );
}

GridContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool
};
