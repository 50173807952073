import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";


import Radio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import ReactTable from "components/CustomReactTable";
import {
  GET_ORGANIC_SEARCH,
  SET_ORGANIC_SEARCH_PRIMARY
} from "queries/organicSearch";
import { Context } from "../../../redux/store";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import styles1 from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles({
  ...styles,
  ...styles1
});

const UpdateOrganicSearch = () => {
  const [state] = useContext(Context);
  const [organicSearchLinks, setOrganicSearchLinks] = useState();
  const classes = useStyles();
  let { loading: listing, data } = useQuery(GET_ORGANIC_SEARCH, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id
    }
  });
  const [setPrimary, { loading: settingPrimary }] = useMutation(
    SET_ORGANIC_SEARCH_PRIMARY,
    {
      onCompleted: row => {
        setOrganicSearchLinks(
          organicSearchLinks.map(link =>
            Number(link.id) === Number(row?.setOrganicSearchPrimary?.id)
              ? { ...link, primary: 1 }
              : { ...link, primary: 0 }
          )
        );
      }
    }
  );
  useEffect(() => {
    setOrganicSearchLinks(data?.getOrganicSearch || [{}]);
  }, [data]);

  const loading = listing || settingPrimary;

  const columns = [
    {
      Header: "Location Name",
      accessor: "location_name",
      id: "location_name"
    },
    {
      Header: "Dashboard Link",
      accessor: "organic_search_dashboard_link",
      id: "dashboard_link"
    },
    {
      Header: "Primary",
      accessor: "primary",
      id: "primary",
      // eslint-disable-next-line react/display-name, react/prop-types
      Cell: ({ original: { id, primary } }) => (
        <Radio
          checked={Boolean(primary)}
          onChange={() => {
            setPrimary({
              variables: {
                customer_id: state.customers?.selected?.id,
                id: Number(id)
              }
            });
          }}
          name="radio button enabled"
          classes={{
            checked: classes.radio,
            root: classes.radioRoot
          }}
        />
      )
    }
  ];

  return (
    <div>
      <h2 className={classes.pageHeader}>Update Organic Search</h2>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ReactTable
                key={organicSearchLinks}
                data={organicSearchLinks}
                filterable
                loading={loading || !state.customers?.selected?.id}
                columns={columns}
                defaultPageSize={Math.min(20, organicSearchLinks?.length || 2)}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default UpdateOrganicSearch;
