import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { compact } from "lodash";

import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TaskOutlined from '@mui/icons-material/TaskOutlined';

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForTickets from "./CheckForTickets";
import TicketList from "./TicketList";

import {
  LIST_CUSTOMER_EMAILS,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { LIST_CUSTOMER_USERS } from "queries/users";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setStatus, setRepresentative, crm_user_level, setAdBudget, setAssignedTo } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          if (column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'left'}
                style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Due Date" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Name" ? setName(event.target.value) : column.Header === "Assigned To" ? setAssignedTo(event.target.value) :
                        column.Header === "Ad Budget" ? setAdBudget(event.target.value) :
                          column.Header === "Status" ? setStatus(event.target.value) : setRepresentative(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
          else if (crm_user_level === "crm_admin" && column.Header === "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'left'}
                style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
              >{column.Header}</TableCell>)
        })}
      </TableRow>
    </TableHead>
  );
}

const Estimates = () => {
  const [state] = useContext(Context);
  const [Estimates, setEstimates] = useState();
  const [CRMEstimatesCount, setCRMEstimatesCount] = useState();
  const [Documents, setDocuments] = useState();
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [name, setName] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [adbudget, setAdBudget] = React.useState('');
  const [assignedto, setAssignedTo] = React.useState('');
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const crm_user_level = me?.readMe?.crm_user_level;

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { loading, data: people_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.people_id)?.map((CurrentEstimate) => CurrentEstimate?.people_id)
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      proposal_estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.id)?.map((CurrentEstimate) => CurrentEstimate?.id),
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.proposal_id)?.map((CurrentEstimate) => CurrentEstimate?.proposal_id)
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      status,
      adbudget,
      assignedto,
      representative,
      count: 0
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      contract_estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.id)?.map((CurrentEstimate) => CurrentEstimate?.id),
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.contract_id)?.map((CurrentEstimate) => CurrentEstimate?.contract_id)
    }
  });

  let { data: job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.job_id)?.map((CurrentEstimate) => CurrentEstimate?.job_id)
    }
  });

  let { data: all_data } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1,
      name,
      status,
      adbudget,
      assignedto,
      representative,
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.photo_gallery_id)?.map((CurrentEstimate) => CurrentEstimate?.photo_gallery_id)
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1,
      estimate_ids: Estimates?.filter((CurrentEstimate) => CurrentEstimate?.crm_customers_id)?.map((CurrentEstimate) => CurrentEstimate?.crm_customers_id)
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  useEffect(() => {
    setCRMEstimatesCount(all_data?.ListEstimates || [])
    setEstimates(data?.ListEstimates || [])
    setDocuments(document_data?.ListDocuments || [])
    setCRMJob(job_data?.ListJobs || []);
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);

    setCRMAssignedToUsers(users?.listCustomerUsers || []);
  }, [people_data, crm_data, Representative_data, document_data,
    data, job_data, Proposal_data, Contract_data, all_ticket_data,
    Photo_Gallery_data, Photo_data, service_data, users]);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const columns = compact([
    {
      Header: "Name",
      accessor: "estimate_name"
    },
    {
      Header: "Status",
      accessor: "estimate_status"
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to"
    },
    {
      Header: "Due Date",
      accessor: "estimate_needed_by_date"
    },
    {
      Header: "Ad Budget",
      accessor: "estimate_ad_budget"
    },

    {
      Header: "Assigned To",
      accessor: "assigned_to"
    },
  ]);

  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data, handleOpen } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                {(CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id)?.length ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForDocuments(row, index, "estimate_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand) ||
                  CheckForContracts(row, index, "estimate_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "estimate_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand) ||
                  CheckForTickets(row, index, "estimate_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand) ||
                  CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                <>
                  <a
                    href={"/admin/crmestimatedetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.people_id + "&estimateid=" + row?.id}>
                    {row?.estimate_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.estimate_status}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{CRMAssignedToUsers?.filter((CurrentCRMAssignedToUser) => CurrentCRMAssignedToUser?.id === row?.assigned_to).map(CurrentCRMAssignedToUser => { return <div>{CurrentCRMAssignedToUser?.first_name} {CurrentCRMAssignedToUser?.last_name}</div> })}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.estimate_needed_by_date !== null ? moment(new Date(row?.estimate_needed_by_date)).utc().format("MM-DD-YYYY") : ""}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.estimate_ad_budget}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.first_name} {row?.last_name}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length} style={{ padding: 0, margin: 0 }}>
              <TableCell style={{ padding: 0 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                      {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                              <TableCell style={{ padding: 0, margin: 0 }}>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ width: "100px" }}>
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                        {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell><div style={{ float: "left" }}>Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                  </TableRow>
                                </Table>
                                <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                  <Table size="medium">
                                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10, width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell>
                                        <div style={{ width: "300px", float: "left" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>)
                      })}
                      {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                      {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                      {CheckForJobs(row, index, "estimate_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "estimate_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "estimate_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "estimate_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForDocuments(row, index, "estimate_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "estimate_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "estimate_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "estimate_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "estimate_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "estimate_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                      {CheckForTickets(row, index, "estimate_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                      {TicketList(row, index, CRMTickets, "estimate_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <TaskOutlined />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={Estimates || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={CRMEstimatesCount?.length ? CRMEstimatesCount[0]?.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              style={{ display: CRMEstimatesCount?.length > 0 ? "" : "none" }}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setStatus={setStatus}
                  setAdBudget={setAdBudget}
                  setAssignedTo={setAssignedTo}
                  setRepresentative={setRepresentative}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={Estimates} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Estimates;
