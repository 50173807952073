import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { compact } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import Input from "@mui/material/Input";
import Rating from "@mui/material/Rating";
import DeleteButton from "@mui/material/Button";

import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";

import LinearNoContentWrapper from "components/LinearNoContentWrapper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumpster, faDumpsterFire } from "@fortawesome/pro-regular-svg-icons";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import {
  LIST_CUSTOMER_REPRESENTATIVES,
  DELETE_CUSTOMER_REPRESENTATIVES,
  LIST_CUSTOMERS,
  GET_CRM_SETTINGS
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { READ_ME } from "queries/users";
import { Context } from ".././../../redux/store";

import UpdateRepresentativeProfile from "views/Dashboard/UpdateRepresentativeProfile";
import FormatPhone from "../Format/FormatPhone";
import { useLocation } from "react-router-dom";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};
const useStyles = makeStyles(styles);

const columns = compact([
  {
    Header: "Name",
    accessor: "first_name",
    minWidth: 150,
  },
  {
    Header: "Job Title",
    accessor: "job_title",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Cell",
    accessor: "cell_phone_number",
    optional: true,
  },
  {
    Header: "Rating",
    accessor: "rating",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Office Phone",
    accessor: "office_phone_number",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Office Ext",
    accessor: "office_extension",
    minWidth: 150,
    optional: true,
  },
  {
    Header: "Delete",
    accessor: "delete",
    width: 100,
    sortable: false,
  },
]);

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    setPageName,
    setTitle,
    setPageEmail,
    setCell,
    setBio,
    setRating,
    setPagefficePhone,
    setOfficeExt,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }}>
        {columns.map(
          (column) =>
            column.Header !== "" && (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={"center"}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : "asc"}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                <Input
                  style={{
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    borderBottom: "6px",
                    width: "100px",
                  }}
                  type="text"
                  onChange={(event) => {
                    column.Header === "Name"
                      ? setPageName(event.target.value)
                      : column.Header === "Job Title"
                        ? setTitle(event.target.value)
                        : column.Header === "Email"
                          ? setPageEmail(event.target.value)
                          : column.Header === "Cell"
                            ? setCell(event.target.value)
                            : column.Header === "Bio"
                              ? setBio(event.target.value)
                              : column.Header === "Rating"
                                ? setRating(event.target.value)
                                : column.Header === "Office Phone"
                                  ? setPagefficePhone(event.target.value)
                                  : setOfficeExt(event.target.value);
                  }}
                ></Input>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

const Representatives = (props) => {
  const history = useLocation();
  const [state] = useContext(Context);
  const [customers, setCustomers] = useState([]);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [updateRep, setupdateRep] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [Pagename, setPageName] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Pageemail, setPageEmail] = React.useState("");
  const [cell, setCell] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [rating, setRating] = React.useState("");
  const [Pageofficephone, setPagefficePhone] = React.useState("");
  const [officeext, setOfficeExt] = React.useState("");
  const [Hover, setHover] = useState(null);
  const [B2B, setB2B] = useState('');
  const [CRMSettings, setCRMSettings] = useState([]);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { loading, data, refetch } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: Pagename ? Pagename : "",
      title: title ? title : "",
      email: Pageemail ? Pageemail : "",
      cell: cell ? cell : "",
      bio: bio ? bio : "",
      rating: rating ? rating : "",
      officephone: Pageofficephone ? Pageofficephone : "",
      officeext: officeext ? officeext : "",
    },
  });

  let { data: all_data, refetch: refetchtotal } = useQuery(
    LIST_CUSTOMER_REPRESENTATIVES,
    {
      skip: !customer_id,
      variables: {
        customer_id: customer_id,
        count: 1,
      },
    }
  );

  all_data = all_data?.listCustomerRepresentatives || [];

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id },
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear;hydrate"
    }
  });

  useEffect(() => {
    setCustomers(
      data?.listCustomerRepresentatives?.filter(
        (CurrentRep) =>
          CurrentRep?.first_name !== "Not" &&
          CurrentRep?.first_name !== "Assigned"
      )
    );
    GetCRMSettings();
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [data]);

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CUSTOMER_REPRESENTATIVES,
    {
      onCompleted: () => {
        const notify = (options) => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true,
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        };
        notify({
          text: "Information has been successfully submitted",
          variant: "success",
        });

        refetch();
        refetchtotal();
      },
    }
  );

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleOpen = (row) => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = (updated) => {
    if (updated) {
      const notify = (options) => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true,
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      };
      notify({
        text: "Information has been successfully submitted",
        variant: "success",
      });
    }

    refetch();
    refetchtotal();
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  const handleRepClose = (updated) => {
    if (updated) {
      const notify = (options) => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true,
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      };
      notify({
        text: "Information has been successfully submitted",
        variant: "success",
      });
    }
    setupdateRep(null);
    refetch();
    refetchtotal();
  };

  const isLoading = loading || !customer_id;

  return (
    <>
    {LinearCustomer || LinearCustomer === undefined ?
      <>
      {history.pathname.indexOf("representativeshydrate") === -1 && (
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <label style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Representatives List</label>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="trackedKeywords" icon>
              <CardIcon>
                <AssignmentIndOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {history.pathname.indexOf("representativeshydrate") > -1 ? "Representatives" : ""}
                <Button
                  onClick={() => handleOpen()}
                  color="primary"
                  style={{ marginLeft: 20 }}
                  disabled={isLoading}
                >
                  Add Representative
                </Button>
              </h4>
            </CardHeader>
            <CardBody>
              <TablePagination
                component="div"
                count={all_data.length - 1}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              />
              <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    setPageName={setPageName}
                    setTitle={setTitle}
                    setPageEmail={setPageEmail}
                    setCell={setCell}
                    setBio={setBio}
                    setRating={setRating}
                    setPagefficePhone={setPagefficePhone}
                    setOfficeExt={setOfficeExt}
                  />
                  <TableBody>
                    {customers?.map((datarow) => (
                      <TableRow key={datarow?.length}>
                        <TableCell><a
                          href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen(datarow);
                          }}
                        >
                          {datarow?.first_name}&nbsp;{datarow?.last_name}
                        </a></TableCell>
                        <TableCell>{datarow?.job_title}</TableCell>
                        <TableCell>{datarow?.email}</TableCell>
                        <TableCell>{FormatPhone(datarow?.cell_phone_number)}</TableCell>
                        <TableCell><Rating name="half-rating" defaultValue={datarow?.rating} precision={0.5} readOnly />
                          {/*<Rating
                            name="simple-controlled"
                            value={datarow?.rating}
                            precision={0.5}
                            onChange={(event, newValue) => {
                              submitUpdateRequest({ variables: { customer_id: customer_id, CustomerRepresentative: { id: datarow?.id, rating: newValue } } });
                            }}
                          />*/}
                            </TableCell>
                            <TableCell>
                              {FormatPhone(datarow?.office_phone_number)}
                            </TableCell>
                            <TableCell>{datarow?.office_extension}</TableCell>
                            <TableCell>
                              <DeleteButton
                                style={{
                                  color: Hover === datarow?.id ? "red" : "#000",
                                  float: "right",
                                  backgroundColor: "#FFF",
                                }}
                                onMouseOver={() => {
                                  setHover(datarow?.id);
                                }}
                                onMouseLeave={() => {
                                  setHover(null);
                                }}
                                onClick={() => {
                                  if (window.confirm("Are you sure?")) {
                                    submitDeleteRequest({
                                      variables: {
                                        customer_id: customer_id,
                                        id: datarow?.id,
                                      },
                                    });
                                  }
                                }}
                              >
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={
                                    Hover === datarow?.id
                                      ? faDumpsterFire
                                      : faDumpster
                                  }
                                  onMouseOver={() => {
                                    setHover(datarow?.id);
                                  }}
                                  onMouseLeave={() => {
                                    setHover(null);
                                  }}
                                />
                              </DeleteButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {updatedCustomerEmail && (
                    <Dialog
                      open={Boolean(updatedCustomerEmail)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateRepresentativeProfile
                          current={currentCustomerEmail}
                          updated={updatedCustomerEmail}
                          handleClose={handleClose}
                          setCustomers={setCustomers}
                          customers={customers}
                        />
                      </DialogContent>
                    </Dialog>
                  )}
                </CardBody>
              </Card>
              {updateRep && (
                <Card>
                  <CardBody>
                    <Dialog
                      open={Boolean(updateRep)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                      fullWidth
                    >
                      <DialogContent>
                        <UpdateRepresentativeProfile
                          current={updateRep}
                          handleClose={handleRepClose}
                          setCustomers={setCRMCustomers}
                          customers={CRMCustomers}
                        />
                      </DialogContent>
                    </Dialog>
                  </CardBody>
                </Card>
              )}
            </GridItem>
          </GridContainer>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

export default Representatives;
