import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_VIDEO_ASSETS } from "queries/google";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  paperScrollPaper: {
    maxWidth: "800px"
  }
};

const useStyles = makeStyles(styles);

const ViewVideoAds = ({ filter, title }) => {
  const [state] = useContext(Context);
  const [video, setVideo] = useState();
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_VIDEO_ASSETS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      filter,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listVideoAssets || [];
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{title}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={[
                {
                  Header: "Title",
                  accessor: "title"
                },
                {
                  Header: "Video Length",
                  accessor: "duration_millis",
                  Cell: ({ original }) =>
                    parseInt(original.duration_millis / 1000, 10)
                },
                {
                  Header: "View Ad",
                  accessor: "video_id",
                  // eslint-disable-next-line react/display-name,react/prop-types
                  Cell: ({ original }) =>
                    !original?.video_id ? (
                      "Unknown"
                    ) : (
                      <a
                        href="#"
                        rel="noopener noreferrer"
                        onClick={e => {
                          e.preventDefault();
                          setVideo(
                            `https://www.youtube.com/embed/${original?.video_id}`
                          );
                        }}
                      >
                        View
                      </a>
                    )
                },
                {
                  Header: "Landing Page",
                  accessor: "final_urls",
                  // eslint-disable-next-line react/display-name,react/prop-types
                  Cell: ({ original }) =>
                    !original?.final_urls ? (
                      "Unknown"
                    ) : (
                      <a
                        href={ original?.final_urls }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        View
                      </a>
                    )
                },
              ]}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <Dialog
              open={Boolean(video)}
              onClose={() => setVideo(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              classes={{
                paperScrollPaper: classes.paperScrollPaper
              }}
            >
              <iframe
                width="800"
                height="400"
                src={video}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Dialog>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

ViewVideoAds.propTypes = {
  filter: PropTypes.string,
  title: PropTypes.string
};

export default ViewVideoAds;
