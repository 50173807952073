import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Select from "components/Select/Select";
import NoContent from "components/NoContent";
import { GET_ORGANIC_SEARCH } from "queries/organicSearch";
import { Context } from "../../../redux/store";

const useStyles = makeStyles(styles);

const OrganicSearch = () => {
  const [state] = useContext(Context);
  const [link, setLink] = useState();
  const classes = useStyles();
  let { loading, data } = useQuery(GET_ORGANIC_SEARCH, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id
    }
  });

  data = data?.getOrganicSearch;

  useEffect(() => {
    setLink(data?.find(({ primary }) => primary));
  }, [data]);

  if (loading || !state.customers?.selected?.id || (!data || !data.length)) {
    return (
      <NoContent
        product="Organic Search"
        isAn
        loading={loading || !state.customers?.selected?.id}
      >
        OrganicSearch is a multi-pronged approach to increase your brand's
        overall ranking on search engines. We start with traditional SEO for
        your website, we add in authority building, citation building, and
        reputation management. At Water Bear Marketing™, we are focused on
        increasing our client’s online ranking, consumer perception, and
        improving their chances of being found across the Internet with clear
        and accurate information. Our OrganicSearch program allows you to stand
        out, literally. Our goal is to get you seen in as many places as
        possible.
      </NoContent>
    );
  }

  return (
    <div>
      <h2 className={classes.pageHeader}>Organic Search</h2>
      <div style={{ display: "flex", alignItems: "center" }}>
        Choose Location:
        <Select
          options={data?.map(({ id, location_name }) => ({
            key: id,
            label: location_name
          }))}
          selected={link?.id}
          onSelect={value => {
            setLink(data?.find(({ id }) => id === value));
          }}
          loading={loading}
          style={{ marginLeft: 30, marginBottom: 20 }}
        />
      </div>
      <iframe
        src={link?.organic_search_dashboard_link}
        style={{ border: "none" }}
        key={link?.organic_search_dashboard_link}
        name="myiFrame"
        scrolling="yes"
        frameBorder={1}
        marginHeight={0}
        marginWidth={0}
        height="4000px"
        width="100%"
        allowFullScreen
      />
    </div>
  );
};

export default OrganicSearch;
