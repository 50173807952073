import gql from "graphql-tag";

export const GET_BING_ACCOUNTS_SUMMARY = gql`
  query getBingAccountsSummary(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getBingAccountsSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      ctr
      avg_cpc
    }
  }
`;

export const GET_BING_CAMPAIGNS_SUMMARY = gql`
  query getBingCampaignsSummary(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getBingCampaignsSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      ctr
      avg_cpc
      conversions
    }
  }
`;

export const LIST_BING_AD_GROUPS = gql`
  query listBingAdGroups(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    listBingAdGroups(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_group_name
      ad_group_id
      cost
      impressions
      clicks
      ctr
      avg_cpc
    }
  }
`;

export const LIST_BING_SEARCH_QUERIES = gql`
  query listBingSearchQueries(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
    $detailed: Boolean
  ) {
    listBingSearchQueries(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      detailed: $detailed
    ) {
      search_query
      impressions
      clicks
      campaign_name
      ad_group_name
    }
  }
`;

export const LIST_BING_TEXT_ADS = gql`
  query listBingTextAds(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listBingTextAds(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_id
      ad_type
      ad_group_name
      title_part1
      title_part2
      title_part3
      ad_description
      ad_description2
      path1
      path2
      ad_status
      final_url
    }
  }
`;
