import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { compact, omit } from "lodash";
import { CSVLink } from "react-csv";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "components/CustomButtons/Button";
import DeleteButton from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import CSVReader from "components/CSVReader";
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  DELETE_PERSON,
  UPLOAD_CUSTOMER_EMAILS,
  COMMIT_CUSTOMER_EMAILS,
  UPLOAD_OVERRIDE_CUSTOMER_EMAILS,
  GET_STAGING_DUPLICATES,
  DELETE_STAGING_CUSTOMER_EMAILS,
  DELETE_STAGING_CRM_CUSTOMERS,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { SYNCHRONIZE_WITH_CLICKSEND } from "queries/clicksend";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";
import FormatPhone from "../Format/FormatPhone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'

import CheckForJobs from "../CustomerManagement/CheckForJobs";
import JobList from "../CustomerManagement/JobList";
import CheckForPhotos from "../CustomerManagement/CheckForPhotos";
import PhotoList from "../CustomerManagement/PhotoList";
import CheckForDocuments from "../CustomerManagement/CheckForDocuments";
import DocumentList from "../CustomerManagement/DocumentList";
import CheckForContracts from "../CustomerManagement/CheckForContracts";
import ContractList from "../CustomerManagement/ContractList";
import CheckForProposals from "../CustomerManagement/CheckForProposals";
import ProposalList from "../CustomerManagement/ProposalList";
import CheckForEstimates from "../CustomerManagement/CheckForEstimates";
import EstimateList from "../CustomerManagement/EstimateList";
import CheckForTickets from "../CustomerManagement/CheckForTickets";
import TicketList from "../CustomerManagement/TicketList";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const listNames = [
  "Water Bear Marketing",
  "Employment",
  "From Website",
  "From CMS/ERP",
  "Purchased",
  "Tradeshows",
  "Database",
  "Lead Generation",
  "Walk-In",
  "Customize"
].map(v => ({
  label: v,
  value: v
}));

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { setcell_phone_number, setLastSaleValue, order, orderBy, setLifetimeValue, setNotes, onRequestSort, columns, setName, setBusinessEmail, setEmail, setOfficePhone, setAddress, setRepresentative, setJobTitle, setBusinessPhone, setBusinessExtension, setBusinessAddress, setBusinessName, setListName, setLeadStatus, crm_user_level } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell style={{ width: "50px"}}>&nbsp;</TableCell>
        {columns.map((column) => {
          if ((crm_user_level === "crm_admin" && column.Header === "Delete") || column.Header !== "Delete")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'left'}
                style={{ verticalAlign: "top", width: column.Header === "Name" || column.Header === "Last Sale Value" || 
                column.Header === "Lifetime Value" || column.Header === "Business Phone" ? "150px" : 
                column.Header === "Email" || column.Header === "Cell" || column.Header === "List Name" || 
                column.Header === "First Contact" || column.Header === "Last Contact" || column.Header === "Job Title"
                || column.Header === "Business Email" || column.Header === "Business Name" ? "200" : 
                column.Header === "Personal Address" || column.Header === "Business Address" ? 
                "550px" : column.Header === "Quality" || column.Header === "Type" || column.Header === "Lead Status" ||
                column.Header === "Business Extension" ? "100px" : 
                column.Header === "Notes" ? "400px" : "50px" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Last Activity" && column.Header !== "Delete" && column.Header !== "Opted in Email" && 
                column.Header !== "Opted in Text" && column.Header !== "Opted in Voice" && column.Header !== "Contact Business Only" && 
                 column.Header !== "Quality" && column.Header !== "Type" && column.Header !== "Status"  && 
                 column.Header !== "First Contact" && column.Header !== "Last Contact" && column.Header !== "Unsubscribe" ?
                  <Input style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                    column.Header === "Name" ? setName(event.target.value) :
                      column.Header === "Cell" ? setcell_phone_number(event.target.value) : column.Header === "Email" ? setEmail(event.target.value) :
                        column.Header === "Office Phone Number" ? setOfficePhone(event.target.value) : column.Header === "Personal Address" ? setAddress(event.target.value) :
                        column.Header === "Last Sale Value" ? setLastSaleValue(event.target.value) : column.Header === "Lifetime Value" ? setLifetimeValue(event.target.value) :
                         column.Header === "Job Title" ? setJobTitle(event.target.value) : column.Header === "Notes" ? setNotes(event.target.value)
                         : column.Header === "Business Email" ? setBusinessEmail(event.target.value) : column.Header === "Business Phone" ? setBusinessPhone(event.target.value) 
                         : column.Header === "Business Extension" ? setBusinessExtension(event.target.value) : column.Header === "Business Address" ? setBusinessAddress(event.target.value)
                         : column.Header === "Business Name" ? setBusinessName(event.target.value) : column.Header === "List Name" ? setListName(event.target.value) : 
                         column.Header === "Lead Status" ? setLeadStatus(event.target.value) : 
                         setRepresentative(event.target.value)
                  }}></Input> : ""}
              </TableCell>
            )
        })}
      </TableRow>
    </TableHead>
  );
}

const CustomerEmailList = () => {
  const [state] = useContext(Context);
  const [customers, setCustomers] = useState();
  const [budgetData, setbudgetData] = useState();
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [addedCount, setAddedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const customer_id = state.customers?.selected?.id;
  const [ShowDuplicateFile, setShowDuplicateFile] = useState(null);
  const [DuplicatePeopledata, setDuplicatePeopledata] = useState(null);
  const [CheckingForDuplicates, setCheckingForDuplicates] = useState(false);
  const [ShowOverrideLoading, setShowOverrideLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('update_time');
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMEstimate, setCRMEstimate] = useState(null);
  const [CRMTickets, setCRMTickets] = useState(null);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [Hover, setHover] = useState(null);
  const [Count, setCount] = useState(null);
  const [LinearCustomer, setLinearCustomer] = useState(false);
  const [name, setName] = React.useState('');
  const [cell_phone_number, setcell_phone_number] = React.useState('');  
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [LifetimeValue, setLifetimeValue] = React.useState('');  
  const [address, setAddress] = React.useState('');
  const [LastSaleValue, setLastSaleValue] = React.useState('');
  const [Notes, setNotes] = React.useState('');
  const [JobTitle, setJobTitle] = React.useState('');
  const [BusinessEmail, setBusinessEmail] = React.useState('');
  const [BusinessPhone, setBusinessPhone] = React.useState('');
  const [BusinessExtension, setBusinessExtension] = React.useState('');
  const [BusinessAddress, setBusinessAddress] = React.useState('');
  const [BusinessName, setBusinessName] = React.useState('');
  const [LeadStatus, setLeadStatus] = React.useState('');
  
  const [ListName, setListName] = React.useState('');
  
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.readMe?.crm_user_level;

  let { data: linearbudget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  let { loading, data, refetch } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative,
      name,
      cell_phone_number,
      phone,
      email,
      LastSaleValue,
      LifetimeValue,
      Notes,
      JobTitle,
      BusinessEmail,
      BusinessPhone,
      BusinessExtension,
      BusinessAddress,
      BusinessName,
      ListName,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      count: 0,
      lead_status_text: LeadStatus
    }
  });

  let { data: all_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative,
      name,
      cell_phone_number,
      phone,
      email,
      LastSaleValue,
      LifetimeValue,
      Notes,
      JobTitle,
      BusinessEmail,
      BusinessPhone,
      BusinessExtension,
      BusinessAddress,
      BusinessName,
      ListName,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      count: 1
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: crm_data, refetch: CRM_Customers_refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  const { data: BudgetData } = useQuery(GET_BUDGET, {
    fetchPolicy: "no-cache",
    skip: !customer_id,
    variables: {
      customer_id: state.customers?.selected?.id,
      product: 24
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const [uploadCustomerEmails, { loading: uploading }] = useMutation(
    UPLOAD_CUSTOMER_EMAILS
  );

  const [uploadOverrideCustomerEmails] = useMutation(
    UPLOAD_OVERRIDE_CUSTOMER_EMAILS);

  const [getStagingDuplicates] = useLazyQuery(GET_STAGING_DUPLICATES, {
    fetchPolicy: "no-cache",
    onCompleted: ({ getStagingDuplicates }) => {
      setDuplicatePeopledata(getStagingDuplicates);
      if (getStagingDuplicates?.length > 0) {
        setShowDuplicateFile(true);
      }

      setCheckingForDuplicates(false);
    }
  });

  const [commitCustomerEmails] = useMutation(
    COMMIT_CUSTOMER_EMAILS,
    {
      onCompleted: () => {
        setUploadedCount(0);
        setAddedCount(0);
        refetch();
      }
    }
  );

  const [deleteStagingCustomerEmails] = useMutation(
    DELETE_STAGING_CUSTOMER_EMAILS
  );

  const [deleteStagingCRMCustomers] = useMutation(
    DELETE_STAGING_CRM_CUSTOMERS
  );

  const [synchronizeWithClicksend, { loading: syncing }] = useMutation(
    SYNCHRONIZE_WITH_CLICKSEND,
    {
      onCompleted: () => {
        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  useEffect(() => {
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);
    setCustomers(data?.listCustomerEmails || []);
    setbudgetData(BudgetData?.getBudget || []);
    setCount(all_data?.listCustomerEmails[0]?.count || 0)
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);

    setLinearCustomer(linearbudget_data?.getBudget?.monthly_budget);

  }, [data, crm_data, Representative_data, all_ticket_data,
    Proposal_data, Contract_data, Job_data, Estimate_data, Estimate_data,
    Photo_Gallery_data, document_data, Photo_data, data, BudgetData]);

  const classes = useStyles();

  const onCSVLoaded = async (CustomerEmailsdata) => {
    CustomerEmailsdata = CustomerEmailsdata?.filter(CurrentCustomer =>
      CurrentCustomer?.first_name !== '')

    setTotalCount(CustomerEmailsdata.length);
    setCsvUploadOpen(true);
    const size = 100;
    const upload = async (start) => {
      if (start >= CustomerEmailsdata.length) {
        setCsvUploadOpen(false);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        refetch();
        return;
      }
      await uploadCustomerEmails({
        fetchPolicy: "no-cache",
        variables: {
          customer_id,
          customerEmails: CustomerEmailsdata.slice(start, start + size)
        }
      }).then(async ({ data: { uploadCustomerEmails: { count, inserted } } }) => {
        setUploadedCount(u => u + count);
        setAddedCount(a => a + inserted);
        await upload(start + size);
      });
    };

    // delete rows from the hydrate_staging_customer_list_1 table
    await deleteStagingCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    // delete rows from the hydrate_customer_list_ table
    await deleteStagingCRMCustomers({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });

    await upload(0);

    // now select staging data and compare to hydrate customer list
    // if record exists leave it in stage for duplicate csv
    // if the key is not found insert into hydrate customer list
    // and remove it from staging


    setCheckingForDuplicates(true);

    await getStagingDuplicates({
      variables: {
        customer_id
      }
    })

    await commitCustomerEmails({
      fetchPolicy: "no-cache",
      variables: {
        customer_id
      }
    });
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const csv_columnsForTargeting = [
    {
      label: "Email",
      key: "email",
    },
    {
      label: "First Name",
      key: "first_name",
    },
    {
      label: "Last Name",
      key: "last_name",
    },
    {
      label: "Country",
      key: "country",
    },
    {
      label: "Email",
      key: "office_email",
    },
    {
      label: "Phone",
      key: "cell_phone_number",
    },
    {
      label: "Phone",
      key: "office_phone_number",
    }
  ];

  const inputs = [
    {
      field: "first_name",
      label: "First Name*"
    },
    {
      field: "last_name",
      label: "Last Name",
      optional: true
    },
    {
      field: "email",
      label: "Email*",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "cell_phone_number",
      label: "Cell*",
      defaultValue: "",
      inputProps: {
        maxLength: 30,
        type: "tel",
        pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
        mask: "999-999-9999"
      },
      optional: true
    },
    {
      field: "address_line_one",
      label: "Personal Address Line 1",
      optional: true
    },
    {
      field: "address_line_two",
      label: "Personal Address Line 2",
      optional: true
    },
    {
      field: "city",
      label: "Personal City",
      optional: true
    },
    {
      field: "state",
      label: "Personal State",
      optional: true
    },
    {
      field: "zip_code",
      label: "Personal Zip Code",
      optional: true
    },
    {
      field: "country",
      label: "Personal Country",
      optional: true
    },
    {
      field: "opt_int_email",
      label: "Opted in Email",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_text",
      label: "Opted in Text",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "opt_in_voice",
      label: "Opted in Voice",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "contact_business_only",
      label: "Contact Business Only",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      field: "list_name",
      label: "List Name",
      type: "select",
      options: listNames,
      defaultValue: "Water Bear Marketing",
      customOption: "Customize",
      optional: true
    },
    {
      field: "lead_quality",
      label: "Quality",
      type: "select",
      options: leadQualities,
      defaultValue: "unknown",
      optional: true
    },
    {
      field: "lead_type",
      label: "Type",
      type: "select",
      options: leadTypes,
      defaultValue: "lead",
      optional: true
    },
    {
      field: "lead_status",
      label: "Status",
      type: "select",
      options: leadStatuses,
      optionsFilter: (options, { lead_type }) =>
        options.filter(({ types }) => types.indexOf(lead_type) !== -1),
      defaultValue: "other",
      optional: true
    },
    {
      field: "sale_value",
      label: "Last Sale Value",
      optional: true
    },
    {
      field: "lifetime_value",
      label: "Lifetime Value",
      optional: true
    },
    {
      field: "notes",
      label: "Notes",
      optional: true
    },
    {
      field: "first_contacted",
      label: "First Contact",
      type: "date",
      optional: true
    },
    {
      field: "last_contacted",
      label: "Last Contact",
      type: "date",
      optional: true
    },
    {
      field: "job_title",
      label: "Job Title",
      optional: true
    },
    {
      field: "office_email",
      label: "Business Email",
      defaultValue: "",
      inputProps: { type: "email" },
      optional: true
    },
    {
      field: "office_phone_number",
      label: "Business Phone",
      optional: true
    },
    {
      field: "office_extension",
      label: "Business Extension",
      optional: true
    },
    {
      field: "business_name",
      label: "Business Name",
      optional: true
    },
    {
      field: "office_address_line_one",
      label: "Business Address Line 1",
      optional: true
    },
    {
      field: "office_address_line_two",
      label: "Business Address Line 2",
      optional: true
    },
    {
      field: "office_city",
      label: "Business City",
      optional: true
    },
    {
      field: "office_zip",
      label: "Business Zip Code",
      optional: true
    },
    {
      field: "office_state",
      label: "Business State",
      optional: true
    },
    {
      field: "office_country",
      label: "Business Country",
      optional: true
    },
    {
      label: "Website",
      field: "website",
      optional: true
    },
    {
      field: "unsubscribe",
      label: "Unsubscribe",
      type: "select",
      options: [{ label: "Yes", value: "1" }, { label: "No", value: "0" }],
      defaultValue: "0",
      optional: true
    },
    {
      label: "Industry",
      field: "industry",
      optional: true
    },
    {
      label: "Revenue",
      field: "revenue",
      optional: true
    },
    {
      label: "SIC Code",
      field: "sic_code",
      optional: true
    },
    {
      label: "NAICS Code",
      field: "naics_code",
      optional: true
    },
    {
      label: "Employees",
      field: "employees",
      optional: true
    },
    {
      label: "Established",
      field: "established",
      optional: true
    },
    {
      label: "CRM Customer ID",
      field: "crm_customers_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Representative ID",
      field: "crm_representative_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    },
    {
      label: "Assigned User ID",
      field: "users_id",
      optional: true,
      inputProps: {
        type: "number"
      }
    }
  ];

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = customers.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setCustomers(newCustomers);
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_PERSON,
    {
      onCompleted: ({ deletePersonid }) => {
        const newCustomers = customers.filter(({ id }) => id !== deletePersonid);
        setCustomers(newCustomers);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });

        refetch();
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
    refetch();
  };

  let columns = [];
  if (me?.readMe?.user_level !== "master" && budgetData?.getBudget && budgetData?.getBudget?.monthly_budget !== null && budgetData?.getBudget?.monthly_budget > 0) {
    columns = compact([
      {
        Header: "Name",
        accessor: "first_name",
        minWidth: 150,
        Cell: ({ original }) => (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOpen(original);
            }}
          >
            {original?.first_name}&nbsp;{original?.last_name}
          </a>
        )
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Cell",
        accessor: "cell_phone_number",
        optional: true,
        minWidth: 150,
        Cell: ({ original }) =>
          FormatPhone(original?.cell_phone_number)
      },
      {
        Header: "Personal Address",
        accessor: "address_line_one",
        minWidth: 250,
        optional: true
      },
      {
        Header: "Opted in Email",
        accessor: "opt_int_email",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_int_email}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Opted in Text",
        accessor: "opt_in_text",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_in_text}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Opted in Voice",
        accessor: "opt_in_voice",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_in_voice}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Contact Business Only",
        accessor: "contact_business_only",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.contact_business_only}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "List Name",
        accessor: "list_name",
        optional: true,
      },
      {
        Header: "Quality",
        accessor: "lead_quality",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadQualities.find(
            ({ value }) => value === (original?.lead_quality || "unknown")
          )?.label || "Unknown",
        optional: true
      },
      {
        Header: "Type",
        accessor: "lead_type",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadTypes.find(
            ({ value }) => value === (original?.lead_type || "unknown")
          )?.label || "Unknown",
        optional: true
      },
      {
        Header: "Lead Status",
        accessor: "lead_status",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadStatuses.find(
            ({ value }) => value === (original?.lead_status || "other")
          )?.label || "Other",
        optional: true
      },
      {
        Header: "Last Sale Value",
        accessor: "sale_value",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Lifetime Value",
        accessor: "lifetime_value",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Notes",
        accessor: "notes",
        minWidth: 150,
        optional: true
      },
      {
        Header: "First Contact",
        accessor: "first_contacted",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Last Contact",
        accessor: "last_contacted",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Job Title",
        accessor: "job_title",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Email",
        accessor: "office_email",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Phone",
        accessor: "office_phone_number",
        minWidth: 150,
        optional: true,
        Cell: ({ original }) =>
          FormatPhone(original?.office_phone_number)
      },
      {
        Header: "Business Extension",
        accessor: "office_extension",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Name",
        accessor: "business_name",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Addres",
        accessor: "office_address_line_one",
        minWidth: 250,
        optional: true
      },
      {
        Header: "Unsubscribe",
        accessor: "unsubscribe",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          original?.unsubscribe && Number(original?.unsubscribe) ? "✓" : "",
        width: 100
      }
    ]);
  } else {
    columns = compact([
      {
        Header: "Name",
        accessor: "first_name",
        minWidth: 150,
        Cell: ({ original }) => (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOpen(original);
            }}
          >
            {original?.first_name}&nbsp;{original?.last_name}
          </a>
        )
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Cell",
        accessor: "cell_phone_number",
        optional: true,
        minWidth: 150,
        Cell: ({ original }) =>
          FormatPhone(original?.cell_phone_number)
      },
      {
        Header: "Personal Address",
        accessor: "address_line_one",
        minWidth: 250,
        optional: true
      },
      {
        Header: "Opted in Email",
        accessor: "opt_int_email",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_int_email}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Opted in Text",
        accessor: "opt_in_text",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_in_text}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Opted in Voice",
        accessor: "opt_in_voice",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.opt_in_voice}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Contact Business Only",
        accessor: "contact_business_only",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          `${original?.contact_business_only}` === "1" ? "Yes" : "No",
        minWidth: 150,
        optional: true
      },
      {
        Header: "List Name",
        accessor: "list_name",
        optional: true,
      },
      {
        Header: "Quality",
        accessor: "lead_quality",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadQualities.find(
            ({ value }) => value === (original?.lead_quality || "unknown")
          )?.label || "Unknown",
        optional: true
      },
      {
        Header: "Type",
        accessor: "lead_type",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadTypes.find(
            ({ value }) => value === (original?.lead_type || "unknown")
          )?.label || "Unknown",
        optional: true
      },
      {
        Header: "Lead Status",
        accessor: "lead_status",
        minWidth: 150,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          leadStatuses.find(
            ({ value }) => value === (original?.lead_status || "other")
          )?.label || "Other",
        optional: true
      },
      {
        Header: "Last Sale Value",
        accessor: "sale_value",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Lifetime Value",
        accessor: "lifetime_value",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Notes",
        accessor: "notes",
        minWidth: 150,
        optional: true
      },
      {
        Header: "First Contact",
        accessor: "first_contacted",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Last Contact",
        accessor: "last_contacted",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Job Title",
        accessor: "job_title",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Email",
        accessor: "office_email",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Phone",
        accessor: "office_phone_number",
        minWidth: 150,
        optional: true,
        Cell: ({ original }) =>
          FormatPhone(original?.office_phone_number)
      },
      {
        Header: "Business Extension",
        accessor: "office_extension",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Name",
        accessor: "business_name",
        minWidth: 150,
        optional: true
      },
      {
        Header: "Business Address",
        accessor: "office_address_line_one",
        minWidth: 250,
        optional: true
      },
      {
        Header: "Unsubscribe",
        accessor: "unsubscribe",
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) =>
          original?.unsubscribe && Number(original?.unsubscribe) ? "✓" : "",
        width: 100
      },
      {
        Header: "",
        accessor: "delete",
        width: 100,
        sortable: false,
        // eslint-disable-next-line react/display-name,react/prop-types
        Cell: ({ original }) => (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (window.confirm("Are you sure?")) {
                submitDeleteRequest({ variables: { customer_id: customer_id, id: original.id } });
              }
            }}
          >
            Delete
          </a>
        ),
        noCSV: true
      }
    ]);
  }

  const csv_columns = compact([
    {
      Header: "First Name",
      accessor: "first_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.first_name}`
      )
    },
    {
      Header: "Last Name",
      accessor: "last_name",
      minWidth: 150,
      Cell: ({ original }) => (
        `${original?.last_name}`
      )
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Cell",
      accessor: "cell_phone_number",
      optional: true,
      minWidth: 150,
      Cell: ({ original }) =>
        FormatPhone(original?.cell_phone_number)
    },
    {
      Header: "Personal Address Line 1",
      accessor: "address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal Address Line 2",
      accessor: "address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Personal City",
      accessor: "city",
      optional: true
    },
    {
      Header: "Personal State",
      accessor: "state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Zip Code",
      accessor: "zip_code",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Personal Country",
      accessor: "country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Email",
      accessor: "opt_int_email",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_int_email}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Text",
      accessor: "opt_in_text",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_text}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Opted in Voice",
      accessor: "opt_in_voice",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.opt_in_voice}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Contact Business Only",
      accessor: "contact_business_only",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        `${original?.contact_business_only}` === "1" ? "Yes" : "No",
      minWidth: 150,
      optional: true
    },
    {
      Header: "List Name",
      accessor: "list_name",
      optional: true,
    },
    {
      Header: "Quality",
      accessor: "lead_quality",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadQualities.find(
          ({ value }) => value === (original?.lead_quality || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Type",
      accessor: "lead_type",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadTypes.find(
          ({ value }) => value === (original?.lead_type || "unknown")
        )?.label || "Unknown",
      optional: true
    },
    {
      Header: "Lead Status",
      accessor: "lead_status",
      minWidth: 150,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        leadStatuses.find(
          ({ value }) => value === (original?.lead_status || "other")
        )?.label || "Other",
      optional: true
    },
    {
      Header: "Last Sale Value",
      accessor: "sale_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Lifetime Value",
      accessor: "lifetime_value",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Notes",
      accessor: "notes",
      minWidth: 150,
      optional: true
    },
    {
      Header: "First Contact",
      accessor: "first_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Last Contact",
      accessor: "last_contacted",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Email",
      accessor: "office_email",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        FormatPhone(original?.office_phone_number)
    },
    {
      Header: "Business Extension",
      accessor: "office_extension",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Name",
      accessor: "business_name",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Address Line 1",
      accessor: "office_address_line_one",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business Address Line 2",
      accessor: "office_address_line_two",
      minWidth: 250,
      optional: true
    },
    {
      Header: "Business City",
      accessor: "office_city",
      optional: true
    },
    {
      Header: "Business Zip Code",
      accessor: "office_zip",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business State",
      accessor: "office_state",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Business Country",
      accessor: "office_country",
      minWidth: 150,
      optional: true
    },
    {
      Header: "Unsubscribe",
      accessor: "unsubscribe",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        original?.unsubscribe && Number(original?.unsubscribe) ? "✓" : "",
      width: 100
    },
    {
      Header: "CRM Customer ID",
      accessor: "crm_customers_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.crm_customers_id)
    },
    {
      Header: "Representative ID",
      accessor: "crm_representative_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.crm_representative_id)
    },
    {
      Header: "Assigned User ID",
      accessor: "users_id",
      minWidth: 150,
      optional: true,
      Cell: ({ original }) =>
        parseInt(original?.users_id)
    }
  ]);

  function Row(props) {
    const { data } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length} style={{ width: "100%" }}>
              <TableCell style={{ width: "50px"}}>
                {(CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id)?.length ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand) ||
                  CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand) ||
                  CheckForEstimates(row, index, "crm_customers_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand) ||
                  CheckForTickets(row, index, "crm_customers_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell style={{ width: "150px"}}>
                <>
                  {LinearCustomer || LinearCustomer === undefined ?
                    <a
                      href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.id}
                      rel="noopener noreferrer"
                    >
                      {row?.first_name}&nbsp;{row?.last_name}
                    </a>
                    :
                    <a
                      href="#"
                      rel="noopener noreferrer"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpen(row);
                      }}
                    >
                      {row?.first_name}&nbsp;{row?.last_name}
                    </a>
                  }
                </>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.email}</div>
              </TableCell>
              <TableCell align="left"><div style={{ width: "200px" }}>{FormatPhone(row?.cell_phone_number)}</div>
              </TableCell>
              <TableCell align="left" style={{ whiteSpace: "nowrap", width: "550px" }}>{row?.address_line_one} {row?.address_line_two} {row?.city} {row?.state} {row?.zip_code} {row?.country}</TableCell>
              <TableCell align="left" style={{ width: "50px"}}><div>{row?.opt_int_email === 0 ? "No" : "Yes"}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "50px"}}><div>{row?.opt_in_text === 0 ? "No" : "Yes"}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "50px"}}><div>{row?.opt_in_voice === 0 ? "No" : "Yes"}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "50px"}}><div>{row?.contact_business_only === 0 ? "No" : "Yes"}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.list_name}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "100px"}}><div>{leadQualities?.filter(CurrentLeadQuality => CurrentLeadQuality?.value === row?.lead_quality)?.map(CurrentLeadQuality => CurrentLeadQuality?.label)}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "100px"}}><div>{leadTypes?.filter(CurrentLeadType => CurrentLeadType?.value === row?.lead_type)?.map(CurrentLeadType => CurrentLeadType?.label)}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "100px"}}><div>{leadStatuses?.filter(CurrentLeadStatus => CurrentLeadStatus?.value === row?.lead_status)?.map(CurrentLeadStatus => CurrentLeadStatus?.label)}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "150px"}}><div>{row?.sale_value}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "150px"}}><div>{row?.lifetime_value}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "400px"}}><div>{row?.notes}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.first_contacted}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.last_contacted}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.job_title}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.office_email}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "150px"}}><div>{FormatPhone(row?.office_phone_number)}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "100px"}}><div>{row?.office_extension}</div>
              </TableCell>
              <TableCell align="left" style={{ width: "200px"}}><div>{row?.business_name}</div>
              </TableCell>
              <TableCell align="left" style={{ whiteSpace: "nowrap", width: "550px" }}>{row?.office_address_line_one} {row?.office_address_line_two} {row?.office_city} {row?.office_state} {row?.office_zip_code} {row?.office_country}</TableCell>
              <TableCell align="left" style={{ width: "50px"}}><div>{row?.unsubscribe === 0 ? "No" : "Yes"}</div>
              </TableCell>
              <TableCell>{crm_user_level === "crm_admin" && (
                <DeleteButton style={{ color: Hover === row?.id ? "red" : "#000", float: "right", backgroundColor: "#FFF" }} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} onClick={() => {
                  if (window.confirm("Are you sure?")) {
                    submitDeleteRequest({ variables: { customer_id: customer_id, id: row?.id } });
                    refetch();
                    CRM_Customers_refetch();
                  }
                }}>
                  <FontAwesomeIcon size="lg" icon={Hover === row?.id ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(row?.id) }} onMouseLeave={() => { setHover(null) }} />
                </DeleteButton>
              )}</TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ padding: 0 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <tbody>
                      {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                              <TableCell>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ width: "100px" }}>
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                        {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell><div style={{ float: "left" }}>Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                  </TableRow>
                                </Table>
                                <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                  <Table size="medium">
                                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                      <TableCell style={{ width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell style={{ width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell>
                                        <div style={{ width: "300px", float: "left" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRep) => CurrentRep?.id === CurrentCustomer?.representative_id).map(CurrentRep => { return <>{CurrentRep?.first_name} {CurrentRep?.last_name}</> })}</div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>)
                      })}
                      {CheckForJobs(row, index, "people_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "people_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "people_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "people_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForDocuments(row, index, "people_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "people_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForEstimates(row, index, "people_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                      {EstimateList(row, index, CRMEstimate, "people_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "people_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "people_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "people_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "people_id", CRMRepresentative, isContractOpenCollapse, customer_id)}                      
                      {CheckForTickets(row, index, "people_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                      {TicketList(row, index, CRMTickets, "people_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                    </tbody>
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = loading || updating || uploading || deleting || syncing || !customer_id;
  const masterRole = me?.readMe?.user_level === "master" || me?.readMe?.user_level === "campaign_consultant";

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Customer List
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
                disabled={isLoading}
              >
                Add Customer
              </Button>
              {masterRole && (
                <Button
                  onClick={() => {
                    synchronizeWithClicksend({ variables: { customer_id } });
                  }
                  }
                  color="primary"
                  style={{ marginLeft: 20 }}
                  disabled={isLoading}
                >
                  Synchronize with Hydrate
                </Button>
              )}
              <CSVDownloader
                data={customers || []}
                columns={csv_columns}
                filename={`Customer Email List (${state.customers?.selected?.name})`}
              />
              <CSVReader
                onLoaded={onCSVLoaded}
                disabled={isLoading}
                inputs={inputs}
              />
              {masterRole && (
                <CSVLink
                  data={customers?.map(item => {
                    return {
                      ...item,
                      ...{ office_phone_number: `=""${item.office_phone_number}""` },
                      ...{ cell_phone_number: `=""${item.office_phone_number}""` }
                    }
                  }) || []}
                  headers={csv_columnsForTargeting}
                  filename={`customer-list.csv`}
                  style={{ float: "right", margin: 0 }}
                >
                  <Button
                    color="primary"
                    style={{ float: "right", marginRight: 10 }}
                    disabled={isLoading}
                  >
                    Download for Targeting
                  </Button>
                </CSVLink>
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={Count ? Count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              style={{ display: Count > 0 ? "" : "none"}}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data} key="CustomerTable1" >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setPhone={setPhone}
                  setAddress={setAddress}
                  setEmail={setEmail}
                  setRepresentative={setRepresentative}
                  setcell_phone_number={setcell_phone_number}
                  setLastSaleValue={setLastSaleValue}
                  setLifetimeValue={setLifetimeValue}
                  setNotes={setNotes}
                  setJobTitle={setJobTitle}
                  setBusinessEmail={setBusinessEmail}
                  setBusinessPhone={setBusinessPhone}
                  setBusinessExtension={setBusinessExtension}
                  setBusinessAddress={setBusinessAddress}
                  setBusinessName={setBusinessName}
                  setListName={setListName}
                  setLeadStatus={setLeadStatus}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={customers} />
                </TableBody>
              </Table>
            </TableContainer>
            {csvUploadOpen && (
              <Dialog
                open={Boolean(csvUploadOpen)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Upload Customers
                </DialogTitle>
                <DialogContent>
                  {uploadedCount} / {totalCount} uploaded <br />
                  {addedCount} added <br />
                </DialogContent>
              </Dialog>
            )}
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setCustomers={setCustomers}
                    customers={customers}
                    source={"Hydrate"}
                  />
                </DialogContent>
              </Dialog>
            )}
            {CheckingForDuplicates && (
              <Dialog
                open={Boolean(CheckingForDuplicates)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent align={'left'}>
                  <h4>We're Importing your data and checking for Duplicates.  Please stay on this page.</h4><br /><br />
                  <CircularProgress />
                </DialogContent>
              </Dialog>
            )}
            {ShowDuplicateFile && (
              <Dialog
                open={Boolean(ShowDuplicateFile)}
              >
                <DialogTitle>
                  See Duplicate People
                  <div style={{ float: "right" }}><Button onClick={() => setShowDuplicateFile(false)}><CloseIcon /></Button></div>
                </DialogTitle>
                <DialogContent align={'left'}>
                  Your import file had duplicates in it.  They were not uploaded.<br />This file contains the duplicates.
                  <br />
                  <br />
                  Click here to overrides the duplicates.  This will overwrite<br />your current data for People and Customers
                  <br />
                  <br />
                  <CSVDownloader
                    incomingstyle={{ float: "left" }}
                    data={DuplicatePeopledata || []}
                    columns={csv_columns}
                    filename={`LinearCRM People Duplicates (${state.customers?.selected?.name})`}
                    text={"Download"}
                  />&nbsp;<Button BackgroundColor="red" onClick={async () => {
                    setShowOverrideLoading(true);

                    await uploadOverrideCustomerEmails({
                      fetchPolicy: "no-cache",
                      variables: {
                        customer_id
                      }
                    })

                    setShowDuplicateFile(false);
                    setShowOverrideLoading(true);

                    const notify = options => {
                      const key = enqueueSnackbar(options?.text, {
                        variant: options?.variant,
                        persist: true
                      });
                      setTimeout(() => {
                        closeSnackbar(key);
                      }, options?.delay || 2000);
                    }
                    notify({
                      text: "Information has been successfully submitted",
                      variant: "success"
                    });

                    refetch();
                  }}>Override</Button>
                  {ShowOverrideLoading && (
                    <>
                      <br />
                      <br />
                      Overriding...
                      <br />
                      < CircularProgress />
                    </>
                  )}
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(CustomerEmailList);
