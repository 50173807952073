import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";

import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

import { API_URL } from "config";

import AudioReactRecorder, { RecordState } from 'audio-react-recorder'
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInput";
import HelpDialog from "components/HelpDialog";
import UploadInput from "components/UploadInput";
import ReactTable from "components/CustomReactTable";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import pageStyle from "assets/jss/material-dashboard-pro-react/views/marketingGoalsStyle";
import withSubmissions from "hocs/withSubmissions";
import Switch from '@mui/material/Switch';
import { GET_HYDRATE_CAMPAIGN, UPDATE_HYDRATE_CAMPAIGN } from "queries/hydrate";

const styles = theme => ({
  ...pageStyle(theme),
  loading: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  block: {
    padding: 10,
    backgroundColor: "white"
  },
  heading: {
    fontSize: 18
  },
  maxLength: {
    fontSize: 13,
    marginLeft: 10
  },
  textField: {
    fontSize: 14
  },
  paperScrollPaper: {
    maxWidth: "auto"
  }
});

const useStyles = makeStyles(styles);

const HydrateAssetSubPage = ({
  customer_id,
  leadStatus,
  title,
  fields,
  getQuery,
  getQueryKey,
  updateQuery,
  messageNumber,
  help,
  actions,
  hasPlaceholders = false,
  formSubmitted
}) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [HydrateCampaignData, setHydrateCampaignData] = useState({});
  const [recordState, setrecordState] = useState(null);
  const [audioData, setaudioData] = useState(null);
  const [url, setUrl] = useState("");
  const [loadingAWS, setLoading] = useState("");
  const [oldawsurl, setoldawsurl] = useState("");
  const [uploadPercentage, setuploadPercentage] = useState("");
  
  const [updateCampaign, { loading: updatingCampaign }] = useMutation(
    UPDATE_HYDRATE_CAMPAIGN, {
        onCompleted: () => {
          refetch();
        }
    }
  );

  let { loading, data } = useQuery(getQuery, {
    skip: !customer_id || !leadStatus,
    variables: {
      customer_id,
      list_name: leadStatus,
      message_number: messageNumber,
      get_template: 1
    },
    fetchPolicy: "no-cache"
  });

  let { hydrate_campaign_loading, data: hydrateCampaignData, refetch } = useQuery(GET_HYDRATE_CAMPAIGN, {
    variables: {
      customer_id,
      list_name: leadStatus
    },
    fetchPolicy: "no-cache",
    skip: !customer_id || !leadStatus
  });

  const [updateData, { loading: updating }] = useMutation(updateQuery, {
    skip: !customer_id || !leadStatus,
    onCompleted: () => {
      formSubmitted();
    }
  });
  data = data && !loading ? data[getQueryKey] || {} : {};

  useEffect(() => {
    setFormData(omit(data, ["__typename"]));

    setHydrateCampaignData(hydrateCampaignData?.getHydrateCampaign || {})
  }, [loading, hydrateCampaignData]);

  const handleChangeValue = name => value => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCampaignChange = (event, MessageNumber) => {
    if(MessageNumber === "sms_text")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            sms_text: event.target.checked === false ? 0 : 1
          }
        }
      });
    }else if(MessageNumber === "mms_text")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            mms_text: event.target.checked === false ? 0 : 1
          }
        }
      });
    }else if(MessageNumber === "voice_message")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            voice_message: event.target.checked === false ? 0 : 1
          }
        }
      });
    }else if(MessageNumber === "letter")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            letter: event.target.checked === false ? 0 : 1
          }
        }
      });
    }else if(MessageNumber === "email")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            email: event.target.checked === false ? 0 : 1
          }
        }
      });
    }else if(MessageNumber === "postcard")
    {
      updateCampaign({
        variables: {
          data: {
            customer_id,
            list_name: leadStatus,
            postcard: event.target.checked === false ? 0 : 1
          }
        }
      });
    }
  };

  const handleChange = name => e => {
    handleChangeValue(name)(e.target.value);
  };

  const start = () => {
    setrecordState(RecordState.START)
  }
 
  const stop = () => {
    setrecordState(RecordState.STOP)
  }
 
  const onStop = (audioData) => {
    if(audioData){
      setaudioData(audioData);
      let path = `customer_creatives/assets/${customer_id}/${new Date().getTime()}`;
      onDrop(audioData.blob, path);
    }
  }

  const RemoveOldAWSUrl= (path) => {
    axios.delete(path).then(res => {  
      console.log(res);
    })
  }

  const onDrop = (file, path) => {
    const contentType = file.type; // eg. image/jpeg or image/svg+xml
                                           
    const generatePutUrl = `${API_URL}/generate-put-url`;
    const generateGetUrl = `${API_URL}/generate-get-url`;
    const options = {
      onUploadProgress: (progressEvent) => {
        let percent = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        setuploadPercentage({percent})                                                             
      },
      params: {
        Key: path,
        ContentType: contentType
      },
      headers: {
        "Content-Type": contentType
      }
    };

    setLoading(true);

    axios.get(generatePutUrl, options).then(res => {
      const {
        data: { putURL }
      } = res;
      axios
        .put(putURL, file, options)
        .then(() => {
          axios.get(generateGetUrl, options).then(res => {
            const { data: getURL } = res;
            setLoading(false);
            setoldawsurl(formData["voice_message_url"])
            formData["voice_message_url"] = getURL

          });
        })
        .catch(() => {
          setLoading(false);
        });
    });
  };

  const LinearDeterminate = ({ uploadPercentage }) => {
    const classes = useStyles();    
    return (
      <div className={classes.root}>
        <LinearProgress variant="determinate" value={uploadPercentage.percent} />
        <Typography variant="caption" component="div" color="textSecondary">{ uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
      </div>
    );
  }

  const input = (
    <CustomInput
      formControlProps={{
        fullWidth: true,
        required: true
      }}
      inputProps={{
        type: "string",
        readOnly: true,
        value: audioData || ""
      }}
    />
  );

  if (!customer_id || !leadStatus) return null;

  return (
    <div>
    <div 
      className={loading || updating || !leadStatus ? classes.loading : null}
      style={{ marginTop: 30, float: "left", width: "49%", padding: "10px" }}
    >
      <h2 className={classes.pageHeader}>{title}</h2>
      {help && (
        <HelpDialog
          trigger={
            <h4 style={{ display: "inline-block" }}>
              <Link
                to="#"
                onClick={e => { e.preventDefault(); }}
              >
                View Requirements
              </Link>
            </h4>
          }
        >
          {help}
        </HelpDialog>
      )}
      <br />Enable&nbsp;{title}
      <Switch
          checked={title === "Text" && HydrateCampaignData?.sms_text === 1 ? 1 : 0 ||
                   title === "MMS" && HydrateCampaignData?.mms_text === 1 ? 1 : 0 ||
                   title === "Voice Message" && HydrateCampaignData?.voice_message === 1 ? 1 : 0 ||
                   title === "Letter" && HydrateCampaignData?.letter === 1 ? 1 : 0 || 
                   title === "Email" && HydrateCampaignData?.email === 1 ? 1 : 0 ||
                   title === "Postcard" && HydrateCampaignData?.postcard === 1 ? 1 : 0}
          onChange={title === "Text" ? event => { handleCampaignChange(event, "sms_text")} : "" ||
                    title === "MMS" ? event => { handleCampaignChange(event, "mms_text")} : "" ||
                    title === "Voice Message" ? event => { handleCampaignChange(event, "voice_message")} : "" ||
                    title === "Letter" ? event => { handleCampaignChange(event, "letter")} : "" ||
                    title === "Email" ? event => { handleCampaignChange(event, "email")} : "" ||
                    title === "Postcard" ? event => { handleCampaignChange(event, "postcard")} : ""}
          color="primary"
          name="keyword_optimization"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      {hasPlaceholders && (
          <HelpDialog
            trigger={
              <h4 style={{ display: "inline-block" }}>
                <Link
                  to="#"
                  onClick={e => { e.preventDefault(); }}
                >
                  Placeholders
                </Link>
              </h4>
            }
          >
            <ReactTable
              data={[
                { label: "Company Name", value: "{{companyname}}" },
                { label: "Company Address", value: "{{address}}" },
                { label: "Company Website", value: "{{website}}" },
                { label: "Company Email", value: "{{email}}" },
                { label: "Read/Write Reviews Landing Page", value: "{{review link}}" },
                { label: "Hydrate Landing Page", value: "{{landing page}}" },
                { label: "Hyrdrate Phone Number", value: "{{phone}}" },
                { label: "Facebook Link", value: "{{facebook}}" },
                { label: "Instagram Link", value: "{{instagram}}" },
                { label: "Twitter Link", value: "{{twitter}}" },
                { label: "YouTube Link", value: "{{youtube}}" },
                { label: "LinkedIn Link", value: "{{linkedin}}" },
                { label: "Pinterest Link", value: "{{pinterest}}" }
              ]}
              columns={[
                {
                  Header: "Placeholders",
                  accessor: "label",
                  sortable: false
                },
                {
                  Header: "",
                  accessor: "value",
                  sortable: false
                }
              ]}
              defaultPageSize={13}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="-striped -highlight height-limited"
            />
            <h5 style={{ textAlign: "center" }}>
              Note: *Placeholders need to be exactly as shown. Otherwise, they will not work.
            </h5>
          </HelpDialog>
      )}
      {actions}
      <form
        onSubmit= { e => {
          e.preventDefault();
          e.stopPropagation();

          if(oldawsurl)
          {
            RemoveOldAWSUrl(oldawsurl)
          }
          updateData({
            variables: {
              customer_id,
              list_name: leadStatus,
              message_number: messageNumber,
              data: formData,
              get_template: 1
            }
          });
        }}
      >
        {fields.map(
          ({
            name,
            label,
            type = "text-input",
            required = false,
            maxLength,
            options,
            accept,
            parts,
            append,
            maxSize,
            sizeHelper
          }) => {
            const textLength = formData[name]?.length || 0;
            const headingArea = (
              <div className={classes.heading}>
                {label}
                {required && "*"}
                {maxLength && (
                  <span className={classes.maxLength} style={{color: "red"}}>
                    ({formData[name]?.length || 0} / {maxLength} Characters)
                  </span>
                )}
                {parts && (
                  <span className={classes.maxLength}>
                    Parts ({parts.findIndex(part => part >= textLength + (append || 0)) + 1} / {parts.length})
                  </span>
                )}

                {name === "voice_message_url" ?
                  <div>
                    
                      { formData[name] && (
                      <a href={formData[name] || ""} target="_blank">Play Current Recording</a>
                      )}
                      <AudioReactRecorder state={recordState} onStop={onStop} />         
                      <label>Record New Message</label>
                      <br /><br />
                      <button onClick={start} type="button">Start</button>&nbsp;&nbsp;
                      <button onClick={stop} type="button">Stop</button>&nbsp;&nbsp;          
                      {audioData?.url && (<a href={audioData?.url} target="_blank">Play Message</a>)}
                      <br /><br />
                      <LinearDeterminate uploadPercentage={uploadPercentage} />
                  </div> : ""}
              </div>
            );
            return (
              <div key={name} className={classes.block}>
                {type === "link-input" && (
                  <div>
                    
                    <UploadInput
                      path={`hydrate/assets/${customer_id}/${leadStatus}/${messageNumber}/${title}`}
                      onChange={handleChangeValue(name)}
                      value={formData[name]}
                      label={label}
                      required={required}
                      maxLength={maxLength}
                      maxSize={maxSize}
                      sizeHelper={sizeHelper}
                      isImage={false}
                      accept={accept}
                    />
                    <label>Click above to upload your pdf</label>
                  </div>
                )}
                {type === "image" && (
                  <UploadInput
                    path={`hydrate/assets/${customer_id}/${leadStatus}/${messageNumber}/${title}`}
                    onChange={handleChangeValue(name)}
                    value={formData[name]}
                    label={label}
                    required={required}
                    maxLength={maxLength}
                    maxSize={maxSize}
                    sizeHelper={sizeHelper}
                    isImage
                  />
                )}
                {type !== "image" && type !== "link-input" && headingArea}
                <div>
                  {type === "text-input" && name !== "voice_message_url" && (
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "string",
                        maxLength: maxLength || undefined,
                        onChange: handleChange(name),
                        value: formData[name] || ""
                      }}
                    />
                  )}
                  {type === "text-input" && name === "voice_message_url" && (
                    <div>
                    <input
                      style={{display: 'none' }}
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "string",
                        maxLength: maxLength || undefined,
                        readOnly: true,
                        value: formData[name] || ""
                      }}
                    />
                    </div>
                  )}
                  {type === "long-text-input" && (
                    <TextField
                      multiline
                      rows={10}
                      maxRows={10}
                      fullWidth
                      value={formData[name] || ""}
                      onChange={handleChange(name)}
                      required={required}
                      inputProps={{
                        className: classes.textField,
                        maxLength: maxLength || undefined
                      }}
                    />
                  )}
                  {type === "select" && (
                    <CustomSelect
                      value={formData[name]}
                      onChange={handleChange(name)}
                      data={options}
                    />
                  )}
                </div>
              </div>
            );
          }
        )}
        <div className={classes.block}>
          <Button color="primary" type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
    {title === "Letter" ? <div style={{ clear: "both" }}></div>:""}
    </div>
  );
};

export default withSubmissions(HydrateAssetSubPage);
