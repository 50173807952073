import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_AD_EXTENSIONS_PRICE } from "queries/google";

import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Price = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_GOOGLE_AD_EXTENSIONS_PRICE, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGoogleAdExtensionPrice || [];

  data = data.map((row) => ({    
    ...row,
    ctr: Number(row.impressions) !== 0 ? Number(((row.clicks / row.impressions) * 100).toFixed(2)): 0
  }));

  const classes = useStyles();

  const inputs = [
    {
      field: "price_offerings",
      label: "Price",
      inputProps: {
        maxLength: 25,
        validation: v => `${v.length}/25 Characters`
      }
    },
    { field: "notes", label: "Notes", optional: true }
  ];

  data = data.filter(row => inputs.some(({ field }) => row[field]));

  const columns = compact([
    {
      Header: "Price",
      accessor: "price_offerings",
      width: 250,
      Cell: ({ original }) =>
      original?.price_offerings !== '""' ? JSON.parse(original?.price_offerings)?.map(CurrentElement => {
        return <><><a
        href={CurrentElement?.finalUrl}
        target="_blank"
        rel="noopener noreferrer"
      >{CurrentElement?.header}</a><br/>{new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format((CurrentElement?.price?.amountMicros || 0) / 1000000)}{
          CurrentElement?.unit === "UNSPECIFIED" ? "/Not specified." : 
          CurrentElement?.unit === "UNKNOWN" ? "/unknown" : 
          CurrentElement?.unit === "PER_HOUR" ? "/hourly" : 
          CurrentElement?.unit === "PER_DAY" ? "/daily" : 
          CurrentElement?.unit === "PER_WEEK" ? "/weekly" : 
          CurrentElement?.unit === "PER_MONTH" ? "/monthly" : 
          CurrentElement?.unit === "PER_YEAR" ? "/yearly" : 
          CurrentElement?.unit === "PER_NIGHT" ? "/nightly" : ""}<br/>
                {CurrentElement?.description}</><br/><br/></>
      }): ""
    },
    {
      Header: "Campaign Name",
      accessor: "campaign_name",
      width: 200
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name",
      width: 200
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
        original?.ctr + "%"
    },
    {
      Header: "CPC",
      accessor: "average_cpc",
      width: 100,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin(
            (original?.cost || 0) / (original?.clicks || 1) / 1000000,
            state
          )
        )
    }
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Price
              <HelpTooltip>
                {`Price assets appear below your text ad on desktop and mobile, and give you more space to tell people details about what your business offers.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Price (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(Price);
