import React, { createContext } from "react";
import { StateInspector, useReducer } from "reinspect";
import PropTypes from "prop-types";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()


import rootReducer from "./rootReducer";

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, {});
  return (
    <StateInspector>
      <Context.Provider value={[state, dispatch]}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </Context.Provider>
    </StateInspector>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired
};

export const Context = createContext([{}, () => {}]);
export default Store;
