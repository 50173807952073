/*!

WaterBearReports.com is Water Bear Marketing's Customer Facing Reporting Dashboard.

© 2022 Water Bear Marketing Advertising With Intelligence®, GeoDemo®, GeoIntetion®, ReEngage®, AdPantry®, and Water Bear Marketing® are Registered Trademarks of Water Bear Marketing Cleveland, OH 44116
800-341-7138 WaterBearMarketing.com

Thanks for checking our source code!  Here's a little something for you...

What do you do with an elephant with 3 balls?


...walk him and pitch to the rhino...

*/

import { rgbToHex } from "@mui/material";

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const drawerMiniWidth = 50;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const containerFluid = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px"
  },
  "@media (min-width: 992px)": {
    width: "970px"
  },
  "@media (min-width: 1200px)": {
    width: "1100px"
  },
  "&:before,&:after": {
    display: "table",
    content: '" "'
  },
  "&:after": {
    clear: "both"
  }
};

const defaultFont = {
  fontFamily: '"Bebas Neue", "Arcon", "Arial", sans-serif',
  fontWeight: 600,
  lineHeight: "1.5em"
};

const comfortaaFont = {
  fontFamily: '"Comfortaa", cursive',
  fontWeight: 600,
  lineHeight: "1.5em"
};

// const defaultFont = {
//   fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
//   fontWeight: "300",
//   lineHeight: "1.5em"
// };

const primaryColor = ["#0071CE", "#2081DE", "#0061BE", "#2081DE", "#0058A0"];
const warningColor = [
  "#ff9800",
  "#ffa726",
  "#fb8c00",
  "#ffa21a",
  "#f57c00",
  "#faf2cc",
  "#fcf8e3"
];
const dangerColor = [
  "#f44336",
  "#ef5350",
  "#e53935",
  "#f55a4e",
  "#d32f2f",
  "#ebcccc",
  "#f2dede"
];
const successColor = [
  "#4caf50",
  "#66bb6a",
  "#43a047",
  "#5cb860",
  "#388e3c",
  "#d0e9c6",
  "#dff0d8"
];
const infoColor = [
  "#00acc1",
  "#26c6da",
  "#00acc1",
  "#00d3ee",
  "#0097a7",
  "#c4e3f3",
  "#d9edf7"
];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573", "#c2185b"];
const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#555555",
  "#333",
  "#eee",
  "#ccc",
  "#e4e4e4",
  "#E5E5E5",
  "#f9f9f9",
  "#f5f5f5",
  "#495057",
  "#e7e7e7",
  "#212121",
  "#c8c8c8",
  "#505050"
];
const blackColor = "#000";
const blueColor = ["#0071CE"];
const wbmGrey = "#7B868C";
const wbmGoogleColor = ["#FFAC00"];
const wbmBingColor = ["#008080"];
const wbmLeadsColor = ["#355e3b"];
const wbmBannerAdsColor = ["#CD212A"];
const wbmEmailColor = ["#f88379"];
const wbmBusinessListingColor = ["#6b5876"];
const wbmPostOfficeColor = ["#C0C0C0"];
const wbmMyTeamColor = ["#4cbb17"];
const wbmMarketingGoalsColor = ["#81d8d0"];
const wbmTrackedKeywordsColor = ["#de778d"];
const wbmMoneyColor = ["#85bb65"];
const orangeColor = ["#f78d2b"];
const whiteColor = "#FFFFFF";
const offWhiteColor = "#EEEEEE";
const offDarkColor = "#2C3C4C";
const twitterColor = "#55acee";
const facebookColor = "#3b5998";
const googleColor = "#dd4b39";
const linkedinColor = "#0976b4";
const pinterestColor = "#cc2127";
const youtubeColor = "#e52d27";
const tumblrColor = "#35465c";
const behanceColor = "#1769ff";
const dribbbleColor = "#ea4c89";
const redditColor = "#ff4500";

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(primaryColor[0]) +
    ",.4)"
};
const infoBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(infoColor[0]) +
    ",.4)"
};
const successBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(successColor[0]) +
    ",.4)"
};
const warningBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(warningColor[0]) +
    ",.4)"
};
const dangerBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(dangerColor[0]) +
    ",.4)"
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(roseColor[0]) +
    ",.4)"
};
const orangeBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(orangeColor[0]) +
    ",.4)"
};
const googleBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmGoogleColor[0]) +
    ",.4)"
};
const bingBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmBingColor[0]) +
    ",.4)"
};
const leadsBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmLeadsColor[0]) +
    ",.4)"
};
const bannerBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmBannerAdsColor[0]) +
    ",.4)"
};
const emailBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmEmailColor[0]) +
    ",.4)"
};
const businessListingBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmBusinessListingColor[0]) +
    ",.4)"
};
const postOfficeBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmPostOfficeColor[0]) +
    ",.4)"
};
const myTeamBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmMyTeamColor[0]) +
    ",.4)"
};
const marketingGoalsBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmMarketingGoalsColor[0]) +
    ",.4)"
};
const trackedKeywordsBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmTrackedKeywordsColor[0]) +
    ",.4)"
};
const wbmBlueBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(blueColor[0]) +
    ",.4)"
};

const wbmMoneyBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(wbmMoneyColor[0]) +
    ",.4)"
};

const grayBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    rgbToHex(grayColor[0]) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(blueColor[0]) +
    ",.4)"
};

const warningCardHeader = {
  background:
    "linear-gradient(60deg, " + warningColor[1] + ", " + warningColor[2] + ")",
  ...warningBoxShadow
};
const successCardHeader = {
  background:
    "linear-gradient(60deg, " + successColor[1] + ", " + successColor[2] + ")",
  ...successBoxShadow
};
const dangerCardHeader = {
  background:
    "linear-gradient(60deg, " + dangerColor[1] + ", " + dangerColor[2] + ")",
  ...dangerBoxShadow
};
const infoCardHeader = {
  background:
    "linear-gradient(60deg, " + infoColor[1] + ", " + infoColor[2] + ")",
  ...infoBoxShadow
};
const primaryCardHeader = {
  background:
    "linear-gradient(60deg, " + primaryColor[1] + ", " + primaryColor[2] + ")",
  ...primaryBoxShadow
};
const roseCardHeader = {
  background:
    "linear-gradient(60deg, " + roseColor[1] + ", " + roseColor[2] + ")",
  ...roseBoxShadow
};
const orangeCardHeader = {
  background:
    "linear-gradient(60deg, " + orangeColor[0] + ", " + orangeColor[0] + ")",
  ...orangeBoxShadow
};
const googleCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmGoogleColor[0] +
    ", " +
    wbmGoogleColor[0] +
    ")",
  ...googleBoxShadow
};
const bingCardHeader = {
  background:
    "linear-gradient(60deg, " + wbmBingColor[0] + ", " + wbmBingColor[0] + ")",
  ...bingBoxShadow
};
const leadsCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmLeadsColor[0] +
    ", " +
    wbmLeadsColor[0] +
    ")",
  ...leadsBoxShadow
};
const bannerCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmBannerAdsColor[0] +
    ", " +
    wbmBannerAdsColor[0] +
    ")",
  ...bannerBoxShadow
};
const emailCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmEmailColor[0] +
    ", " +
    wbmEmailColor[0] +
    ")",
  ...emailBoxShadow
};
const businessListingCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmBusinessListingColor[0] +
    ", " +
    wbmBusinessListingColor[0] +
    ")",
  ...businessListingBoxShadow
};
const postOfficeCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmPostOfficeColor[0] +
    ", " +
    wbmPostOfficeColor[0] +
    ")",
  ...postOfficeBoxShadow
};
const myTeamCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmMyTeamColor[0] +
    ", " +
    wbmMyTeamColor[0] +
    ")",
  ...myTeamBoxShadow
};
const marketingGoalsCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmMarketingGoalsColor[0] +
    ", " +
    wbmMarketingGoalsColor[0] +
    ")",
  ...marketingGoalsBoxShadow
};
const trackedKeywordsCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmTrackedKeywordsColor[0] +
    ", " +
    wbmTrackedKeywordsColor[0] +
    ")",
  ...trackedKeywordsBoxShadow
};
const wbmBlueCardHeader = {
  background:
    "linear-gradient(60deg, " + blueColor[0] + ", " + blueColor[0] + ")",
  ...wbmBlueBoxShadow
};
const wbmMoneyCardHeader = {
  background:
    "linear-gradient(60deg, " +
    wbmMoneyColor[0] +
    ", " +
    wbmMoneyColor[0] +
    ")",
  ...wbmMoneyBoxShadow
};
const grayCardHeader = {
  background:
    "linear-gradient(60deg, " + grayColor[0] + ", " + grayColor[1] + ")",
  ...grayBoxShadow
};
const greyCardHeader = {
  background:
    "linear-gradient(60deg, " + grayColor[0] + ", " + grayColor[1] + ")",
  ...grayBoxShadow
};

const card = {
  display: "inline-block",
  position: "relative",
  width: "100%",
  margin: "25px 0",
  boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
  borderRadius: "6px",
  color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
  background: whiteColor
};

const cardActions = {
  margin: "0 20px 10px",
  paddingTop: "10px",
  borderTop: "1px solid " + grayColor[8],
  height: "auto",
  ...defaultFont
};

const cardHeader = {
  margin: "-20px 15px 0",
  borderRadius: "3px",
  padding: "15px"
};

const defaultBoxShadow = {
  border: "0",
  borderRadius: "3px",
  boxShadow:
    "0 10px 20px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 3px 20px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  padding: "10px 0",
  transition: "all 150ms ease 0s"
};

const tooltip = {
  padding: "10px 15px",
  minWidth: "130px",
  color: whiteColor,
  lineHeight: "1.7em",
  background: "rgba(" + hexToRgb(grayColor[6]) + ",0.9)",
  border: "none",
  borderRadius: "3px",
  opacity: "1!important",
  boxShadow:
    "0 8px 10px 1px rgba(" +
    hexToRgb(blackColor) +
    ", 0.14), 0 3px 14px 2px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 5px 5px -3px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
  maxWidth: "200px",
  textAlign: "center",
  fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  textShadow: "none",
  textTransform: "none",
  letterSpacing: "normal",
  wordBreak: "normal",
  wordSpacing: "normal",
  wordWrap: "normal",
  whiteSpace: "normal",
  lineBreak: "auto"
};

const title = {
  color: grayColor[2],
  textDecoration: "none",
  fontWeight: "300",
  marginTop: "30px",
  marginBottom: "25px",
  minHeight: "32px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  "& small": {
    color: grayColor[1],
    fontSize: "65%",
    fontWeight: "400",
    lineHeight: "1"
  }
};

const cardTitle = {
  ...title,
  marginTop: "0",
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto"
  }
};

const cardSubtitle = {
  marginTop: "-.375rem"
};

const cardLink = {
  "& + $cardLink": {
    marginLeft: "1.25rem"
  }
};

export {
  hexToRgb,
  //variables
  drawerWidth,
  drawerMiniWidth,
  transition,
  container,
  containerFluid,
  boxShadow,
  card,
  defaultFont,
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  blueColor,
  orangeColor,
  roseColor,
  grayColor,
  blackColor,
  offWhiteColor,
  offDarkColor,
  whiteColor,
  wbmGrey,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  orangeBoxShadow,
  roseBoxShadow,
  googleBoxShadow,
  bingBoxShadow,
  leadsBoxShadow,
  bannerBoxShadow,
  emailBoxShadow,
  businessListingBoxShadow,
  postOfficeBoxShadow,
  myTeamBoxShadow,
  marketingGoalsBoxShadow,
  trackedKeywordsBoxShadow,
  wbmBlueBoxShadow,
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  orangeCardHeader,
  roseCardHeader,
  googleCardHeader,
  bingCardHeader,
  leadsCardHeader,
  bannerCardHeader,
  grayCardHeader,
  greyCardHeader,
  emailCardHeader,
  businessListingCardHeader,
  postOfficeCardHeader,
  myTeamCardHeader,
  marketingGoalsCardHeader,
  trackedKeywordsCardHeader,
  wbmBlueCardHeader,
  cardActions,
  cardHeader,
  defaultBoxShadow,
  tooltip,
  title,
  cardTitle,
  cardSubtitle,
  cardLink,
  wbmGoogleColor,
  wbmBingColor,
  wbmBannerAdsColor,
  wbmBusinessListingColor,
  wbmMoneyCardHeader,
  wbmLeadsColor,
  wbmEmailColor,
  wbmMarketingGoalsColor,
  wbmPostOfficeColor,
  wbmMyTeamColor,
  wbmTrackedKeywordsColor,
  comfortaaFont
};
