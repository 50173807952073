import React from "react";


import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PaidSearchNoContentWrapper from "components/PaidSearchNoContentWrapper";

import Callouts from "./Callouts";
import Price from "./Price";
import GoogleCampaignSummary from "./GoogleCampaignSummary";
import GoogleAdsGroups from "./GoogleAdsGroups";
import Locations from "./Locations";
import Calls from "./Calls";
import Sitelinks from "./Sitelinks";
import Snippets from "./Snippets";
import Promotions from "./Promotions";
import ResponsiveSearchAds from "./ResponsiveSearchAds";
import TopKeywordsGoogle from "./TopKeywordsGoogle";
import NegativeKeywordsGoogle from "./NegativeKeywordsGoogle";
import KeywordsGoogle from "./KeywordsGoogle";
import { useQuery } from "@apollo/client";
import { READ_ME } from "queries/users";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function GoogleCampaigns() {
  const { data: me } = useQuery(READ_ME);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [AdExtensionsvalue, setAdExtensionsValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeAdExtensions = (event, newValue) => {
    setAdExtensionsValue(newValue);
  };

  const masterRole = me?.readMe?.user_level === "master" || 
      me?.readMe?.user_level === "campaign_consultant" ||
      me?.readMe?.user_level === "content_manager" ||
      me?.readMe?.user_level === "paid_ad_manager" ||
      me?.readMe?.user_level === "ad_consultant";

  return (
    <>
    {me?.readMe?.user_level !== "master" && me?.readMe?.user_level !== "campaign_consultant" && me?.readMe?.user_level !== "ad_consultant" && me?.readMe?.user_level !== "paid_ad_manager" && me?.readMe?.user_level !== "content_manager" ?
    <PaidSearchNoContentWrapper title="Google">
      <div>
        <h2 className={classes.pageHeader}>Google</h2>
        <GoogleCampaignSummary />
        
        <GoogleAdsGroups />
             
        {/*<h2 className={classes.pageHeader}>Expanded Text Ads</h2><TextAds />*/}
        <h2 className={classes.pageHeader}>Responsive Search Ads</h2>
        <ResponsiveSearchAds />
        <h2 className={classes.pageHeader}>Ad Assets</h2>
        <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
          <Tabs value={AdExtensionsvalue} onChange={handleChangeAdExtensions} variant="scrollable" scrollButtons="auto">
            <Tab label="Locations" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Structured Snippets" {...a11yProps(1)} />          
            <Tab label="Site Links" {...a11yProps(2)} />
            <Tab label="Callouts" {...a11yProps(3)} />
            <Tab label="Calls" {...a11yProps(4)} />
            <Tab label="Price" {...a11yProps(5)} />
            <Tab label="Promotions" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={AdExtensionsvalue} index={0}>
          <Locations />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={1}>
          <Snippets />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={2}>
          <Sitelinks />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={3}>
          <Callouts />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={4}>
          <Calls />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={5}>
          <Price />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={6}>
          <Promotions />
        </TabPanel>
        <h2 className={classes.pageHeader}>Keywords</h2>
        <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
          <Tabs value={value} onChange={handleChange}>
          {masterRole ?<Tab label="Search Keywords" {...a11yProps(0)} borderleft={1} borderright={1} />: "" }
          <Tab label="Search Terms" {...a11yProps(1)} />          
          {masterRole ? (<Tab label="Negative Keywords" {...a11yProps(2)} />): "" }
        </Tabs>
      </AppBar>
      { masterRole ? (
      <TabPanel value={value} index={0}>
        <KeywordsGoogle />
      </TabPanel>) : "" }
      <TabPanel value={value} index={1}>
        <TopKeywordsGoogle />
      </TabPanel>
      { masterRole ? (
      <TabPanel value={value} index={2}>
        <NegativeKeywordsGoogle />
      </TabPanel>) : "" }
       </div>
    </PaidSearchNoContentWrapper>
    :
    <div>
        <h2 className={classes.pageHeader}>Google</h2>
        <GoogleCampaignSummary />
        
        <GoogleAdsGroups />        
        {/*<h2 className={classes.pageHeader}>Expanded Text Ads</h2><TextAds />*/}
        <h2 className={classes.pageHeader}>Responsive Search Ads</h2>
        <ResponsiveSearchAds />
        <h2 className={classes.pageHeader}>Ad Assets</h2>  
        <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
          <Tabs value={AdExtensionsvalue} onChange={handleChangeAdExtensions} variant="scrollable" scrollButtons="auto">
            <Tab label="Locations" {...a11yProps(0)} borderleft={1} borderright={1} />
            <Tab label="Structured Snippets" {...a11yProps(1)} />          
            <Tab label="Site Links" {...a11yProps(2)} />
            <Tab label="Callouts" {...a11yProps(3)} />
            <Tab label="Calls" {...a11yProps(4)} />
            <Tab label="Price" {...a11yProps(5)} />
            <Tab label="Promotions" {...a11yProps(6)} />
          </Tabs>
        </AppBar>
        <TabPanel value={AdExtensionsvalue} index={0}>
          <Locations />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={1}>
          <Snippets />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={2}>
          <Sitelinks />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={3}>
          <Callouts />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={4}>
          <Calls />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={5}>
          <Price />
        </TabPanel>
        <TabPanel value={AdExtensionsvalue} index={6}>
          <Promotions />
        </TabPanel>
        <h2 className={classes.pageHeader}>Keywords</h2>
        <AppBar position="static" style={{ background: '#ffffff', color: "#000000" }}>
          <Tabs value={value} onChange={handleChange}>
          {masterRole ?<Tab label="Search Keywords" {...a11yProps(0)} borderleft={1} borderright={1} />: "" }
          <Tab label="Search Terms" {...a11yProps(1)} />          
          {masterRole ? (<Tab label="Negative Keywords" {...a11yProps(2)} />): "" }
        </Tabs>
      </AppBar>
      { masterRole ? (
      <TabPanel value={value} index={0}>
        <KeywordsGoogle />
      </TabPanel>) : "" }
      <TabPanel value={value} index={1}>
        <TopKeywordsGoogle />
      </TabPanel>
      { masterRole ? (
      <TabPanel value={value} index={2}>
        <NegativeKeywordsGoogle />
      </TabPanel>) : "" }
       </div>}
      </>   
  );
}
