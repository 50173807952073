import React, { useState } from "react";
import { useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";

// import Icon from "@mui/material/Icon";

// @mui/icons-material
import InputLabel from "@mui/material/InputLabel";
import MailOutline from "@mui/icons-material/MailOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
import { SUBMIT_REVIEW } from "queries/formSubmission";
import withSubmissions from "hocs/withSubmissions";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const WriteReview = ({ formSubmitted }) => {
  const classes = useStyles();
  const [submissionData, setSubmissionData] = useState({});

  const [submitReview, { loading: submitting }] = useMutation(SUBMIT_REVIEW, {
    variables: {
      data: {
        ...submissionData,
        favor: submissionData.favor?.split("\n").join("<br>"),
        comments: submissionData.comments?.split("\n").join("<br>")
      }
    },
    onCompleted: () => {
      setSubmissionData({});
      formSubmitted();
    }
  });

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>We’d Love Your Feedback...</h2>
      <h4>
        {`We value your opinion and we’d love to know what you think, don’t be shy, join the conversation below.`}
      </h4>
      <GridContainer loading={submitting}>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <form
              onSubmit={e => {
                e.preventDefault();
                submitReview();
              }}
            >
              <FormControl fullWidth className={classes.selectFormControl}>
                <label>
                  Are you happy with your results?{" "}
                </label>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.chrisHelp
                  }}
                  value={submissionData?.happinessLevel}
                  onChange={updateFieldHandler("happinessLevel")}
                  inputProps={{
                    name: "happinessLevel",
                    id: "happinessLevel"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Are you happy with your results?{" "}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="Very happy"
                  >
                    Very happy
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="Moderately happy"
                  >
                    Moderately happy
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="Very unhappy"
                  >
                    Very unhappy
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <FormControl fullWidth className={classes.selectFormControl}>
                <label>
                  Have you increased your ad budget?{" "}
                </label>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.chrisHelp
                  }}
                  value={submissionData?.hasIncreasedAdBudget}
                  onChange={updateFieldHandler("hasIncreasedAdBudget")}
                  inputProps={{
                    name: "hasIncreasedAdBudget",
                    id: "hasIncreasedAdBudget"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Have you increased your ad budget?{" "}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="Yes"
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="No"
                  >
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth className={classes.selectFormControl}>
                <label>
                  {`Are you interested in learning about some of our other solutions? `}
                </label>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.chrisHelp
                  }}
                  value={submissionData?.otherSolution}
                  onChange={updateFieldHandler("otherSolution")}
                  inputProps={{
                    name: "otherSolution",
                    id: "otherSolution"
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    {`Are you interested in learning about some of our other solutions? `}
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="Yes"
                  >
                    Yes
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="No"
                  >
                    No
                  </MenuItem>
                </Select>
              </FormControl>
              <br />
              <CustomInput
                labelText="What can we do to continue to help your organization achieve your desired outcomes?"
                id="favor"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value: submissionData?.favor || "",
                  onChange: updateFieldHandler("favor")
                }}
              />
              <br />
              <CustomInput
                labelText="Coments"
                id="comments"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value: submissionData?.comments || "",
                  onChange: updateFieldHandler("comments")
                }}
              />
              <Button color="primary" type="submit">
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(WriteReview);
