import React from "react";
import { makeStyles } from "@mui/styles";
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const videos = [
  { title: "Accounts", url: "https://youtube.com/embed/0ZqZN0djjds" },
  { title: "Goals", url: "https://youtube.com/embed/_LwE7WouZ-w" },
  { title: "Tracked Conversions", url: "https://youtube.com/embed/IToOxI_wT4U" },
  { title: "PaidSearch", url: "https://youtube.com/embed/A0U6BXtVynI" },
  { title: "OrganicSearch", url: "https://youtube.com/embed/htaXhB4fqb8" },
  { title: "BannerAds", url: "https://youtube.com/embed/YCAnr_pr_NI" },
  {
    title: "BannerAds, OnlineVideo, SocialAds Targeting Types",
    url: "https://youtube.com/embed/sUaZAGk44PE"
  },
  { title: "Chat", url: "https://youtube.com/embed/h1OWiJgR7og" },
  { title: "OfflineTracking", url: "https://youtube.com/embed/5g3WEZCGp-8" }
];

const useStyles = makeStyles(styles);
const Tutorials = () => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardBody>
          <h2 className={classes.pageHeader}>Tutorials</h2>
          {videos.map(({ title, url, index }) => (
            <>
              <div style={{ width: "50%", float: "left"}}>
                <label style={{ margin: "10px", textAlign: "center"}}>{title}</label>
                <br />
                <iframe src={url} title={title} height="300px" width="500px" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                
              </div>
              {index / 2 === 0 && (
                <div style={{ clear: "both" }}></div>
              )}
            </>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

export default Tutorials;
