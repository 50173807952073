import React, { useContext } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";

import WbmOrangeLogo from "assets/img/WBM2_Whitenav bar.png";

import { Context } from "../../redux/store";

const Brand = ({ classes, bgColor, rtlActive, miniActive }) => {
  const [state] = useContext(Context);
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const logoClasses =
    classes.logo +
    " " +
    cx({
      [classes.whiteAfter]: bgColor === "white"
    });
  return (
    <div className={logoClasses}>
      <Link to="/admin/account-overview">
        <img src={WbmOrangeLogo} alt="logo" />
      </Link>
    </div>
  );
};

Brand.propTypes = {
  classes: PropTypes.shape(),
  bgColor: PropTypes.string,
  rtlActive: PropTypes.bool,
  miniActive: PropTypes.bool
};

export default Brand;
