import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_VIDEO_ASSETS } from "queries/google";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const AdBreakdown = ({ filter, title }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_VIDEO_ASSETS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      filter,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listVideoAssets || [];
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Tracked {title} Details</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={[
                {
                  Header: "Title",
                  accessor: "title"
                },
                {
                  Header: "Impressions",
                  accessor: "impressions"
                },
                {
                  Header: "Views",
                  accessor: "video_views"
                },
                {
                  Header: (
                    <span
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      View Rate
                      <HelpTooltip>
                        View Rate is the number of views of the video ad to the
                        number of video impressions
                      </HelpTooltip>
                    </span>
                  ),
                  accessor: "video_view_rate",
                  Cell: ({ original }) =>
                    `${parseInt(original.video_view_rate * 100, 10)}%`
                },
                {
                  Header: "25%",
                  accessor: "video_quartile_p25_rate",
                  Cell: ({ original }) =>
                    `${parseInt(original.video_quartile_p25_rate * 100, 10)}%`
                },
                {
                  Header: "50%",
                  accessor: "video_quartile_p50_rate",
                  Cell: ({ original }) =>
                    `${parseInt(original.video_quartile_p50_rate * 100, 10)}%`
                },
                {
                  Header: "75%",
                  accessor: "video_quartile_p75_rate",
                  Cell: ({ original }) =>
                    `${parseInt(original.video_quartile_p75_rate * 100, 10)}%`
                },
                {
                  Header: "100%",
                  accessor: "video_quartile_p100_rate",
                  Cell: ({ original }) =>
                    `${parseInt(original.video_quartile_p100_rate * 100, 10)}%`
                }
              ]}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

AdBreakdown.propTypes = {
  filter: PropTypes.string,
  title: PropTypes.string
};

export default AdBreakdown;
