import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";
import { sumBy } from "lodash";

export const formatPhone = number => {
  return parsePhoneNumber(String(number))?.formatNational();
};

export const formatMobileNumber=(text=> {
  var cleaned = ("" + text).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "",
      number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
        ""
      );
    return number;
  }
  return text;
});

export const getPrevious = (rangeString, option) => {
  if (!option) return null;
  if (option === "period") {
    const compareEnd = moment(rangeString?.startDate)
      .add(-1, "days")
      .format("YYYY-MM-DD");
    const comparePeriod = moment(rangeString?.endDate).diff(
      moment(rangeString?.startDate),
      "days"
    );
    const compareStart = moment(compareEnd)

      .add(-comparePeriod, "days")
      .format("YYYY-MM-DD");
    return {
      startDate: compareStart,
      endDate: compareEnd
    };
  }
  const compareStart = moment(rangeString?.startDate)
    .add(-1, "years")
    .format("YYYY-MM-DD");
  const compareEnd = moment(rangeString?.endDate)
    .add(-1, "years")
    .format("YYYY-MM-DD");
  return {
    startDate: compareStart,
    endDate: compareEnd
  };
};

export const getSeconds = duration => {
  if (!duration) return 0;
  return sumBy(
    duration.split(" "),
    o =>
      parseFloat(o) *
      {
        h: 3600,
        m: 60,
        s: 1
      }[o[o.length - 1].toLowerCase()]
  );
};

export const getDigits = str => {
  if (!str) {
    return "";
  }
  const digits = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  return str
    .split("")
    .filter(s => digits.indexOf(s) !== -1)
    .join("");
};

export const getProductNameByProduct = product => {
  if (product === 1) {
    return "PaidSearch";
  } else if (product === 4) {
    return "OrganicSearch";
  } else if (product === 5) {
    return "Hosting";
  } else if (product === 6) {
    return "Local Services Ads";
  } else if (product === 8) {
    return "WebsiteDesign";
  } else if (product === 9) {
    return "OfflineTracking";
  } else if (product === 10) {
    return "Out of Home";
  } else if (product === 11) {
    return "Chat";
  } else if (product === 12) {
    return "OTT";
  } else if (product === 14) {
    return "BannerAds | GeoDemo®";
  } else if (product === 15) {
    return "BannerAds | GeoIntention®";
  } else if (product === 16) {
    return "BannerAds | ReEngage®";
  } else if (product === 17) {
    return "OnlineVideo | GeoDemo®";
  } else if (product === 18) {
    return "OnlineVideo | GeoIntention®";
  } else if (product === 19) {
    return "OnlineVideo | ReEngage®";
  } else if (product === 20) {
    return "SocialAds | GeoDemo®";
  } else if (product === 21) {
    return "Social Ads | ReEngage®";
  } else if (product === 22) {
    return "SocialAds | Boosted Posts";
  } else if (product === 23) {
    return "Hydrate";
  }
  return null;
};
