import React from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CheckForPeople = (row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand) => {
  for (var i = 0; i < CRMPeople?.length; i++) {
    if (CRMPeople[i]?.id === row?.people_id || CRMPeople[i]?.id === row?.people_id || CRMPeople[i]?.crm_customers_id === row?.id || (row?.__typename === "CRMTicket" && CRMPeople[i]?.id === row?.people_id)) {
      return (<TableRow style={{ backgroundColor: "#F5F5F5" }}>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={50}>
          <Table size="medium">
            <tbody>
              <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                <TableCell style={{ width: "100px" }}>
                  <IconButton aria-label="expand row" size="small" onClick={() => handlePeopleExpand(index)}>
                    {isPeopleOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
                <TableCell>People</TableCell>
              </TableRow>
            </tbody>
          </Table>
        </TableCell>
      </TableRow>)
    }
  }
  return null;
};

export default CheckForPeople;
