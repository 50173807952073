import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

import moment from "moment";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Select from "components/Select/Select";
import NoContent from "components/NoContent";
import { GET_HEAT_MAP_DATA } from "queries/getHeatMapData";
import { Context } from "../../../redux/store";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Assignment from "@mui/icons-material/Assignment";
import ReactTable from "components/CustomReactTable";
import Dialog from '@mui/material/Dialog';

const useStyles = makeStyles(styles);

const HeatMap = () => {
  const [state] = useContext(Context);
  const [link, setLink] = useState();
  const [selectedCall, setSelectedCall] = useState(null);
  const dateRangeString = state.customers?.dateRangeString;
  const classes = useStyles();
  let { loading, data } = useQuery(GET_HEAT_MAP_DATA, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  data = data?.getHeatMapData;

  if (loading || !state.customers?.selected?.id || (!data || !data.length)) {
    return (
      <NoContent
        product="Heat Map"
        isAn
        loading={loading || !state.customers?.selected?.id}
      >
        OrganicSearch is a multi-pronged approach to increase your brand's
        overall ranking on search engines. We start with traditional SEO for
        your website, we add in authority building, citation building, and
        reputation management. At Water Bear Marketing™, we are focused on
        increasing our client’s online ranking, consumer perception, and
        improving their chances of being found across the Internet with clear
        and accurate information. Our OrganicSearch program allows you to stand
        out, literally. Our goal is to get you seen in as many places as
        possible.
      </NoContent>
    );
  }

  const api_key = process.env.REACT_APP_MOUSEFLOW_API_TOKEN;
  
  const columns = [
    {
      Header: "Page Name",
      accessor: "page_list_title",
    },
    {
      Header: "Page Url",
      accessor: "page_list_display_url",
    },
    {
      Header: "Page Views",
      accessor: "page_list_views",
      Cell: ({ original }) =>
      original?.page_list_views ?
      Number(original?.page_list_views).toLocaleString('en')
      : ""
    },
    {
      Header: "Visit Time (sec)",
      accessor: "page_list_visit_time",
      Cell: ({ original }) =>
      original?.page_list_visit_time ?
      Number((original?.page_list_visit_time / 1000).toFixed(2)).toLocaleString('en')
      : ""
    },
    {
      Header: "Clicks",
      accessor: "page_list_clicks",
      Cell: ({ original }) =>
      original?.page_list_clicks ?
      Number(original?.page_list_clicks).toLocaleString('en')
      : ""
    },
    {
      Header: "Scroll Depth",
      accessor: "page_list_scroll",
      Cell: ({ original }) =>
          original?.page_list_scroll+"%"
    },
    {
      Header: "Load Time (sec)",
      accessor: "page_list_render_time",
      Cell: ({ original }) =>
          original?.page_list_render_time ?
          Number((original?.page_list_render_time / 1000).toFixed(2)).toLocaleString('en')
          : ""
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: ({ original }) =>
          original?.date ?
            moment(original?.date).format("MM/DD/YYYY")
            : ""
    },
    {
      Header: "View Heat Map",
      sortable: false,
      Cell: ({ original }) =>
          original?.token ?
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    "https://us.mouseflow.com/websites/" + original?.website_id + "/click-heatmaps?token=" + original?.token + "&url=" + original?.page_list_display_url,
                    "_blank"
                  ).focus();;
                }}
              >
                View 
              </a>: ""
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="leads" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Heat Map Information
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            <Dialog
              open={Boolean(selectedCall)}
              onClose={() => setSelectedCall(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
            </Dialog>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default HeatMap;
