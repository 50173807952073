import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { useNavigate } from 'react-router-dom';

import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";

// @mui/icons-material
// import Face from "@mui/icons-material/Face";
import Email from "@mui/icons-material/Email";
// import LockOutline from "@mui/icons-material/LockOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import { REQUEST_RESET_PASSWORD } from "queries/auth";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import infoStyles from "assets/jss/material-dashboard-pro-react/components/infoStyle";

const useStyles = makeStyles(styles);
const useInfoStyles = makeStyles(infoStyles);

const ForgotPasswordPage = ({ history }) => {
  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const navigate = useNavigate();
  const [requestResetPassword, { data, loading }] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      variables: { email, signup: 0 },
      onCompleted: data => {
        if (!data?.requestResetPassword) {
          setSent(true);
        }
      }
    }
  );
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const infoClasses = useInfoStyles();
  const goLogin = () => {
    navigate("/auth/login");
  };

  return (
    <div className={classes.container} style={{ width: "500px", margin: "0 auto" }}>
      <br />
      <br />
      <br />
      <br />
      <form
        onSubmit={e => {
          e.preventDefault();
          (sent ? goLogin : requestResetPassword)();
        }}
      >
        <Card login className={classes[cardAnimaton]}>
          <CardBody>
            {data?.requestResetPassword && (
              <div className={infoClasses.danger}>
                {data?.requestResetPassword}
              </div>
            )}
            {sent ? (
              <h5>Reset Password Link sent successfully to {email}</h5>
            ) : (
              <CustomInput
                labelText="Email..."
                id="email"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: e => setEmail(e.target.value)
                }}
              />
            )}
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button
              color="orange"
              size="lg"
              block
              onClick={sent ? goLogin : requestResetPassword}
            >
              {sent ? "Go Back to Login" : "Send Reset Password Link"}
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  );
};

ForgotPasswordPage.propTypes = {
  history: PropTypes.shape()
};

export default ForgotPasswordPage;
