import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { compact, flatten, startCase, uniq } from "lodash";
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";

import Button from "components/CustomButtons/Button";
import withSubmissions from "hocs/withSubmissions";

const groups = [
  "Base Keyword",
  "Description",
  "Longtail Description",
  "Geography"
];

const types = [
  { key: "exact", title: "Exact", render: s => `[${s}]` },
  { key: "phrase", title: "Phrase", render: s => `"${s}"` },
  { key: "broad_match", title: "Broad Match", render: s => s },
  { key: "capitalise", title: "Capitalise first letters", nostack: true },
  { key: "negative_exact", title: "Negative Exact", render: s => `-[${s}]` },
  { key: "negative_phrase", title: "Negative Phrase", render: s => `-"${s}"` },
  { key: "negative_broad", title: "Negative Broad", render: s => `-${s}` },
];

const styles = {
  card: {
    backgroundColor: "white",
    fontSize: 14,
    padding: 10,
    margin: 10
  },
};

const maxLine = 250;

const nestArrays = arrays =>
  flatten(arrays.map(arr => {
    if (arr.length === 4) {
      return [
        [arr[0], arr[1]],
        [arr[0], arr[1], arr[2]],
        arr,
      ];
    } else if (arr.length === 3) {
      return [
        [arr[0], arr[1]],
        arr,
      ];
    }
    return [arr];
  }));

const multiplyArrays = (...arrays) => {
  if (arrays.length < 2) {
    return (arrays[0] || []).map(s => [s]);
  }
  if (arrays.length > 2) {
    return multiplyArrays(arrays[0], multiplyArrays(...arrays.slice(1)));
  }
  const result = [];
  arrays[0].forEach(item1 => {
    arrays[1].forEach(item2 => {
      result.push(flatten([item1, item2]));
    });
  });
  return result;
};

const KeywordTool = ({ formSubmitted }) => {
  const [groupChecked, setGroupChecked] = useState([true, true, true, true]);
  const [keywords, setKeywords] = useState(['', '', '', '']);
  const [result, setResult] = useState('');
  const [filterChecked, setFilterChecked] = useState({
    broad_match: true
  });

  const onCheck = (index) => {
    const newGroupChecked = groupChecked.slice();
    newGroupChecked[index] = !newGroupChecked[index];
    setGroupChecked(newGroupChecked);
  };

  const onFilterChecked = (type) => {
    setFilterChecked({
      ...filterChecked,
      [type]: !filterChecked[type]
    });
  };

  const onUpdateKeywords = (index) => (e) => {
    const newKeywords = keywords.slice();
    if (e.target) {
      newKeywords[index] = e.target?.value;
      if (e.target?.value?.split("\n").length > maxLine) {
        return;
      }
    }
    setKeywords(newKeywords);
  };

  const generateResult = () => {
    const resultArray = nestArrays(
      multiplyArrays(
        ...compact(groups.map((group, index) => groupChecked[index] && keywords[index]?.length > 0 && compact((keywords[index].trim() + " ").split("\n"))))
      )
    );
    const capitalise = filterChecked.capitalise ? startCase : e => e;
    const stackArray = uniq(
      flatten(resultArray.map(keyword => {
        return types
          .filter(({ key, nostack }) => !nostack && filterChecked[key])
          .map(({ each, render }) =>
            each
              ? keyword.map(k => render(capitalise(k))?.trim().replace(/\s\s+/g, ' ')).join(" ")
              : render(capitalise(keyword.join(" "))?.trim().replace(/\s\s+/g, ' ')));
      }))
    );
    setResult(stackArray.join("\n"));
  };

  const onUpdateResult = (e) => {
    setResult(e.target?.value);
  };

  const onCopy = async () => {
    await navigator.clipboard.writeText(result);
    formSubmitted({
      text: 'Keywords Copied to Clipboard!'
    });
  };

  const resultLength = result?.split("\n").length;
  return (
    <Container>
      <Row>
        {groups.map((group, index) => (
          <Col xs={12} sm={6} xl={3} key={group}>
            <div className="text-center" style={styles.card}>
              <h4>{group}</h4>
              <Checkbox checked={groupChecked[index]} onClick={() => onCheck(index)} />
              <TextField
                multiline
                rows={10}
                maxRows={10}
                fullWidth
                value={keywords[index]}
                onChange={onUpdateKeywords(index)}
                disabled={!groupChecked[index]}
              />
              <p>
                <br />
                1 keyword per line<br />
                {keywords[index]?.split("\n").length} / {maxLine}
              </p>
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col xs={12} xl={3}>
          <div style={styles.card}>
            {types.map(type => (
              <div
                key={type.key}
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <Checkbox
                  checked={filterChecked[type.key]}
                  id={`check-${type.key}`}
                  onClick={() => onFilterChecked(type.key)}
                />
                <label
                  style={{ flex: 1, cursor: "pointer" }}
                  for={`check-${type.key}`}
                >
                  {type.title}
                </label>
              </div>
            ))}
            <Button
              color="primary"
              fullWidth
              onClick={generateResult}
            >
              Get 'Em
            </Button>
          </div>
        </Col>
        <Col xs={12} xl={9}>
          <div style={styles.card}>
            <div style={{ fontSize: 20, textAlign: "center", marginBottom: 15 }}>
              {result ? `${resultLength} ${resultLength === 1 ? 'Keyword' : 'Keywords'}` : 'Result will be displayed below'}
            </div>
            <TextField
              multiline
              rows={15}
              maxRows={15}
              fullWidth
              value={result}
              onChange={onUpdateResult}
            />
            <Button
              color="primary"
              fullWidth
              onClick={onCopy}
            >
              Copy
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withSubmissions(KeywordTool);
