import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { useSnackbar } from "notistack";

const moment = require("moment");

import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import Hidden from "@mui/material/Hidden";
import Popper from "@mui/material/Popper";
import Divider from "@mui/material/Divider";
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import TaskOutlined from '@mui/icons-material/TaskOutlined';
import PhotoLibraryOutlined from '@mui/icons-material/PhotoLibraryOutlined';
import MailOutline from "@mui/icons-material/MailOutline";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// @mui/icons-material
import Person from "@mui/icons-material/Person";

// core components
import Button from "components/CustomButtons/Button";
import DateRangeSelect from "components/DateRangeSelect";
import Select from "components/Select/Select";
import { SET_TOKEN, SELECT_COMPARE_OPTION } from "../../redux/constants";
import { Context } from "../../redux/store";
import { getPrevious } from "utils/format";
import { READ_ME } from "queries/users";
import { Badge as BaseBadge, badgeClasses } from '@mui/base/Badge';
import { styled } from '@mui/system';
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle";

import UserSelect from "./UserSelect";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import SearchResults from "components/SearchResults/SearchResults";
import UpdateCRMProduct from "views/Dashboard/UpdateCRMProduct";
import UpdateCRMService from "views/Dashboard/UpdateCRMService";

import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  MARK_ALL_NOTIFICATIONS_READ,
  GET_CRM_SETTINGS,
  LIST_CUSTOMER_EMAILS,
  CRM_CUSTOMERS,
  LIST_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_TICKETS,
  LIST_ESTIMATES,
  LIST_DOCUMENT,
  LIST_PHOTOS,
  UPDATE_CRM_PHOTO,
  LIST_SERVICES,
  LIST_PRODUCT
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

const useStyles = makeStyles(styles);

const HeaderLinks = (props) => {
  const { rtlActive, Notifications, setNotifications } = props;
  const [name, setSearchName] = React.useState('');
  const [value, setValue] = React.useState('');
  const [start, setStart] = React.useState('');
  const [end, setEnd] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [SKU, setSKU] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [phone, setSearchPhone] = React.useState('');
  const [address, setSearchAddress] = React.useState('');
  const [officephone, setOfficePhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [type, setType] = React.useState('');
  const [service, setService] = React.useState('');
  const [searchrepresentative, setSearchRepresentative] = React.useState('');
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentService, setCurrentService] = useState(null);
  const [compareOption, setCompareOption] = useState("no-compare");
  const [state, dispatch] = useContext(Context);
  const { data: me } = useQuery(READ_ME);
  const dateRangeString = state.customers?.dateRangeString;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const crm_user_level = me?.readMe?.crm_user_level;
  const [openProfile, setOpenProfile] = useState(null);
  const [showNotifications, setshowNotifications] = useState(false);
  const [placeholderText, setplaceholderText] = useState("   \uf002 Search");
  const [Hover, setHover] = useState(null);
  const [searchstring, setsearchstring] = useState(null);
  const [CRMCustomersSearch, setCRMCustomersSearch] = useState(null);
  const [CRMLeadsSearch, setCRMLeadsSearch] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [ShowAll, setShowAll] = useState(true);
  const [ShowCustomers, setShowCustomers] = useState(false);
  const [ShowLeads, setShowLeads] = useState(false);
  const [ShowPeople, setShowPeople] = useState(false);
  const [ShowJobs, setShowJobs] = useState(false);
  const [ShowGalleries, setShowGalleries] = useState(false);
  const [ShowPhotos, setShowPhotos] = useState(false);
  const [ShowProducts, setShowProducts] = useState(false);
  const [ShowServices, setShowServices] = useState(false);
  const [ShowContracts, setShowContracts] = useState(false);
  const [ShowDocuments, setShowDocuments] = useState(false);
  const [ShowProposals, setShowProposals] = useState(false);
  const [ShowEstimates, setShowEstimates] = useState(false);
  const [ShowTickets, setShowTickets] = useState(false);

  const [CRMPeopleSearch, setCRMPeopleSearch] = useState(null);
  const [CRMJobSearch, setCRMJobSearch] = useState(null);
  const [CRMGallerySearch, setCRMGallerySearch] = useState(null);
  const [CRMEstimatesSearch, setCRMEstimatesSearch] = useState(null);
  const [CRMTicketsSearch, setCRMTicketsSearch] = useState(null);
  const [CRMPhotosSearch, setCRMPhotosSearch] = useState(null);
  const [CRMDocumentSearch, setCRMDocumentSearch] = useState(null);
  const [CRMContractSearch, setCRMContractSearch] = useState(null);
  const [CRMProposalSearch, setCRMProposalSearch] = useState(null);
  const [CRMProductSearch, setCRMProductSearch] = useState(null);
  const [CRMServiceSearch, setCRMServiceSearch] = useState(null);
  const [CRMRepresentativeSearch, setCRMRepresentativeSearch] = useState(null);
  const [B2B, setB2B] = useState(null);
  const [UpdatePhoto, setUpdatePhoto] = useState([]);
  const [CRMSettings, setCRMSettings] = useState([]);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customer_id = state.customers?.selected?.id;

  let { data: notifications_data, refetch } = useQuery(GET_NOTIFICATIONS, {
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      user_id: me?.readMe?.id
    }
  });

  let { data: customer_data, loading: customer_data_loading } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  const [updateNotifications] = useMutation(MARK_NOTIFICATION_READ, {
    onCompleted: () => {
      refetch();
    }
  });

  const [updateAllNotifications] = useMutation(MARK_ALL_NOTIFICATIONS_READ, {
    onCompleted: () => {
      refetch();
    }
  });
  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });


  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  useEffect(() => {
    setCRMPhotos(Photo_data?.ListPhotos || []);
    GetCRMSettings();
    setB2B(customer_data?.listCustomers[0]?.B2B === 1 ? "B2B" : "B2C");
    setNotifications(notifications_data?.getNotifications || []);
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);
  }, [notifications_data, customer_data, Photo_data, budget_data]);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleOpenProfile = () => {
    window.location.href = "/admin/profile?customer=" + state.customers?.selected?.id;
  };
  const handleOpenNotification = () => {
    window.location.href = "/admin/notifications?customer=" + state.customers?.selected?.id;
  }
  const handleOpenPayment = () => {
    window.location.href = "/admin/payment?customer=" + state.customers?.selected?.id;
  };
  const handleOpenTutorials = () => {
    window.location.href = "/admin/tutorials?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMSettings = () => {
    window.location.href = "/admin/crm_settings?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMDataStructures = () => {
    window.location.href = "/admin/crm_data_structures?customer=" + state.customers?.selected?.id;
  };
  const handleOpenCRMTableofContents = () => {
    window.location.href = "/admin/crm_table_of_contents?customer=" + state.customers?.selected?.id;
  };

  const handleOpenLatestUpdates = () => {
    window.location.href = "/admin/latestupdates?customer=" + state.customers?.selected?.id;
  };

  const handleLogout = () => {
    props.onSelect();
    handleCloseProfile();
    localStorage.setItem("tokenInfo", "");
    dispatch({ type: SET_TOKEN, payload: {} });
    window.location.href = "/auth/login";
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  const comparePeriod = getPrevious(dateRangeString, "period");
  const compareYear = getPrevious(dateRangeString, "year");
  const compareOptions = [
    { key: "compare-to", label: "Compare to", disabled: true },
    { key: "no-compare", label: "Don't Compare" },
    {
      key: "period",
      label: `Previous Period (${comparePeriod?.startDate} ~ ${comparePeriod?.endDate})`
    },
    {
      key: "year",
      label: `Previous Year (${compareYear?.startDate} ~ ${compareYear?.endDate})`
    }
  ];

  const blue = {
    500: '#007FFF',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

  const Badge = styled(BaseBadge)(
    ({ theme }) => `
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    font-family: 'IBM Plex Sans', sans-serif;
    position: relative;
    display: inline-block;
    line-height: 1;
  
    & .${badgeClasses.badge} {
      z-index: auto;

      top: 0;
      right: 0;
      min-width: 22px;
      height: 22px;
      padding: 0 6px;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      white-space: nowrap;
      text-align: center;
      border-radius: 12px;
      background: ${blue[500]};
      box-shadow: 0px 4px 6x ${theme.palette.mode === 'dark' ? grey[900] : grey[300]};
      transform: translate(50%, -50%);
      transform-origin: 100% 0;
    }
    `,
  );

  let { loading: Customerloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 1,
      search: 1
    },
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });

  const [CustomerSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMCustomersSearch(data?.CRMCustomers || []);
    }
  });


  let { loading: Leadsloading } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !name || B2B !== "B2B",
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      representative: searchrepresentative,
      name,
      phone,
      page: 0,
      rows_per_page: 3,
      status: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  const [LeadSearchQuery] = useLazyQuery(CRM_CUSTOMERS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMLeadsSearch(data?.CRMCustomers || []);
    }
  });

  let { loading: Peopleloading } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      cell_phone_number: phone,
      name,
      email,
      address,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  const [PeopleSearchQuery] = useLazyQuery(LIST_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPeopleSearch(data?.listCustomerEmails || []);
    }
  });

  let { loading: Jobsloading } = useQuery(LIST_JOBS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      value,
      name,
      page: 0,
      rows_per_page: 3,
      representative: searchrepresentative,
      search: 1
    },
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  const [JobSearchQuery] = useLazyQuery(LIST_JOBS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMJobSearch(data?.ListJobs || []);
    }
  });

  let { loading: Galleryloading } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  const [GallerySearchQuery] = useLazyQuery(LIST_PHOTO_GALLERY, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMGallerySearch(data?.ListPhotoGallery || []);
    }
  });

  let { loading: Photosloading } = useQuery(LIST_PHOTOS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      description,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  const [PhotosSearchQuery] = useLazyQuery(LIST_PHOTOS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMPhotosSearch(data?.ListPhotos || []);
    }
  });

  let { loading: Documentsloading } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  const [DocumentSearchQuery] = useLazyQuery(LIST_DOCUMENT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMDocumentSearch(data?.ListDocuments || []);
    }
  });

  let { loading: Contractsloading } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  const [ContractSearchQuery] = useLazyQuery(LIST_CONTRACTS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMContractSearch(data?.ListContract || []);
    }
  });

  let { loading: Proposalsloading } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      value,
      representative: searchrepresentative,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const [ProposalSearchQuery] = useLazyQuery(LIST_PROPOSAL, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProposalSearch(data?.ListProposal || []);
    }
  });

  const { refetch: productrefetch, loading: Productsloading } = useQuery(LIST_PRODUCT, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      price: !isNaN(+price) ? price : "",
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const [ProductSearchQuery] = useLazyQuery(LIST_PRODUCT, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMProductSearch(data?.ListProduct || []);
    }
  });

  const { refetch: servicesrefetch, loading: Servicesloading } = useQuery(LIST_SERVICES, {
    skip: !customer_id || !name,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      page: 0,
      rows_per_page: 3,
      name,
      category,
      SKU,
      count: 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  const [ServiceSearchQuery] = useLazyQuery(LIST_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMServiceSearch(data?.ListServices || []);
    }
  });

  let { loading: Ticketsloading } = useQuery(LIST_TICKETS, {
    skip: !customer_id || !name,
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      name: name ? name : "",
      ticket_id: parseInt(name) ? parseInt(name) : 0,
      search: 1
    },
    onCompleted: (data) => {
      setCRMTicketsSearch(data?.ListTickets || [])
    }
  });

  const [TicketSearchQuery] = useLazyQuery(LIST_TICKETS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMTicketsSearch(data?.ListTickets || [])
    }
  });

  let { loading: Estimatesloading } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id || !name,
    variables: {
      customer_id,
      count: 0,
      page: 0,
      rows_per_page: 3,
      name: name ? name : "",
      search: 1
    },
    onCompleted: (data) => {
      setCRMEstimatesSearch(data?.ListEstimates || [])
    }
  });

  const [EstimateSearchQuery] = useLazyQuery(LIST_ESTIMATES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCRMEstimatesSearch(data?.ListEstimates || [])
    }
  });

  const handleProductOpen = row => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentProduct(row?.id ? row : {});
  };

  const handleServiceOpen = row => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setCurrentService(row?.id ? row : {});
  };

  const handleServiceClose = updated => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentService(null);
  };

  const handleProductClose = () => () => {
    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    setCurrentProduct(null);
  };

  const OpenPhotoEdit = (id) => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
    setUpdatePhoto(CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })?.length ? CRMPhotos?.filter((CurrentPhoto) => CurrentPhoto?.id === id)?.map((CurrentPhoto) => { return CurrentPhoto })[0] : []);
  };

  const updatePhotoFieldHandler = key => (e) => {
    setUpdatePhoto({
      ...UpdatePhoto,
      [key]: e.target.value
    });
  };

  const handleSave = async () => {
    if (UpdatePhoto?.photo_name === undefined || UpdatePhoto?.photo_name === "") {
      alert("Photo Name is required");
      return false;
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (UpdatePhoto) {
      await submitUpdateCRMPhotoRequest({
        variables: {
          customer_id,
          photo_name: UpdatePhoto?.photo_name,
          photo_description: UpdatePhoto?.photo_description,
          photo_alt_text: UpdatePhoto?.photo_alt_text,
          id: UpdatePhoto?.id
        }
      });
    }

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    return true;
  };

  const [submitUpdateCRMPhotoRequest] = useMutation(
    UPDATE_CRM_PHOTO,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        setUpdatePhoto([]);
      }
    }
  );

  const updateSearchHandler = () => e => {
    setsearchstring(e.target.value);

    if (e.target.value.length >= 2) {
      setSearchName(e.target.value);
      setSearchAddress(e.target.value);
      setSearchPhone(e.target.value);
      setEmail(e.target.value);
      setOfficePhone(e.target.value);
      setService(e.target.value);
      setType(e.target.value);
      setDescription(e.target.value);
      setStatus(e.target.value);
      setSearchRepresentative(e.target.value);
      setValue(e.target.value);
      setStart(e.target.value);
      setEnd(e.target.value);
      setCategory(e.target.value);
      setSKU(e.target.value);
      setPrice(e.target.value);
    }
    else {
      setSearchName(null);
      setSearchAddress(null);
      setSearchPhone(null);
      setSearchRepresentative(null);
      setEmail(null);
      setOfficePhone(null);
      setService(null);
      setType(null);
      setDescription(null);
      setStatus(null);
      setValue(null);
      setStart(null);
      setEnd(null);
      setCategory(null);
      setSKU(null);
      setPrice(null);

      setCRMCustomersSearch([]);
      setCRMLeadsSearch([]);
      setCRMPeopleSearch([]);
      setCRMJobSearch([]);
      setCRMGallerySearch([]);
      setCRMPhotosSearch([]);
      setCRMDocumentSearch([]);
      setCRMContractSearch([]);
      setCRMProposalSearch([]);
      setCRMProductSearch([]);
      setCRMServiceSearch([]);
      setCRMRepresentativeSearch([]);
    }
  };

  const updateSearchALL = () => {
    if (B2B === "B2B") {
      CustomerSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 1,
          search: 1
        }
      })

      LeadSearchQuery({
        variables: {
          customer_id,
          address,
          representative: searchrepresentative,
          name,
          phone,
          page: 0,
          rows_per_page: 3,
          status: 0,
          search: 1
        }
      })
    }

    PeopleSearchQuery({
      variables: {
        customer_id,
        count: 0,
        page: 0,
        rows_per_page: 3,
        cell_phone_number: phone,
        name,
        email,
        address,
        representative: searchrepresentative,
        search: 1
      }
    })

    JobSearchQuery({
      variables: {
        customer_id,
        count: 0,
        type,
        name,
        service,
        page: 0,
        rows_per_page: 3,
        representative: searchrepresentative,
        search: 1
      }
    })

    GallerySearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    PhotosSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        description,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    DocumentSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ContractSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProposalSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        value,
        representative: searchrepresentative,
        count: 0,
        search: 1
      }
    })

    ProductSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        price,
        count: 0,
        search: 1
      }
    })

    ServiceSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        category,
        SKU,
        count: 0,
        search: 1
      }
    })

    TicketSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        ticket_id: parseInt(name) ? parseInt(name) : 0,
        count: 0,
        search: 1
      }
    })

    EstimateSearchQuery({
      variables: {
        customer_id,
        page: 0,
        rows_per_page: 3,
        name,
        count: 0,
        search: 1
      }
    })
  };

  const handleSearchClose = () => () => {
    setSearchName(null);
    setSearchAddress(null);
    setSearchPhone(null);
    setSearchRepresentative(null);
    setsearchstring(null);
    setEmail(null);
    setOfficePhone(null);
    setService(null);
    setType(null);
    setDescription(null);
    setStatus(null);
    setValue(null);
    setStart(null);
    setEnd(null);
    setCategory(null);
    setSKU(null);
    setPrice(null);

    setCRMCustomersSearch([]);
    setCRMLeadsSearch([]);
    setCRMPeopleSearch([]);
    setCRMJobSearch([]);
    setCRMGallerySearch([]);
    setCRMPhotosSearch([]);
    setCRMDocumentSearch([]);
    setCRMContractSearch([]);
    setCRMProposalSearch([]);
    setCRMProductSearch([]);
    setCRMServiceSearch([]);
    setCRMRepresentativeSearch([]);
  };

  return (
    <div className={wrapper}>
      <div style={{ textAlign: "left", display: "block" }}>
      {LinearCustomer ?
        <input
          id="search"
          placeholder={placeholderText}
          type="text"
          value={searchstring || ""}
          autoFocus
          onChange={updateSearchHandler()}
          onClick={() => setplaceholderText("")}
          onBlur={() => setplaceholderText("   \uf002 Search")}
          style={{ borderColor: "#b7b7b7", border: "3px #0071CE solid", borderRadius: "100px", height: "50px", width: "300px", fontFamily: 'FontAwesome, Inter', fontSize: "18px", color: "#AAAAAA", marginRight: "200px" }}
        />
        : ""}
        <div className={managerClasses}>
          <UserSelect onSelect={props.onSelect} />
        </div>
        <div className={managerClasses}>
          <DateRangeSelect onSelect={props.onSelect} isMobile={props.isMobile} />
          <Select
            options={compareOptions}
            selected={compareOption}
            onSelect={value => {
              dispatch({
                type: SELECT_COMPARE_OPTION,
                payload: value === "no-compare" ? null : value
              });
              setCompareOption(value);
            }}
            style={{ marginLeft: 30, width: 200 }}
          />
        </div>
        <div className={managerClasses}>
          <Button
            color="transparent"
            aria-label="Person"
            justIcon
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : ""
            }}
          >
            <Person
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
            />
            <Hidden mdUp implementation="css">
              <span onClick={handleClickProfile} className={classes.linkText}>
                {rtlActive ? "الملف الشخصي" : "My Profile"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleOpenProfile}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "My Profile"}
                      </MenuItem>
                      <MenuItem
                        onClick={handleOpenNotification}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "Notifications"}
                      </MenuItem>
                      {(user_level === "master" ||
                        user_level === "customer" ||
                        user_level === "agency_customer" ||
                        user_level === "ad_consultant") && (
                          <MenuItem
                            onClick={handleOpenPayment}
                            className={dropdownItem}
                          >
                            {rtlActive ? "الملف الشخصي" : "Payment"}
                          </MenuItem>
                        )}
                      <MenuItem
                        onClick={handleOpenTutorials}
                        className={dropdownItem}
                      >
                        {rtlActive ? "الملف الشخصي" : "Tutorials"}
                      </MenuItem>
                      <MenuItem
                        onClick={handleOpenCRMDataStructures}
                        className={dropdownItem}
                      >LinearCRM Data Structures</MenuItem>
                      {crm_user_level === "crm_admin" && (
                        <MenuItem
                          onClick={handleOpenCRMSettings}
                          className={dropdownItem}
                        >LinearCRM Settings</MenuItem>
                      )}
                      <MenuItem
                        onClick={handleOpenCRMTableofContents}
                        className={dropdownItem}
                      >LinearCRM Table of Contents</MenuItem>
                      <MenuItem
                        onClick={handleOpenLatestUpdates}
                        className={dropdownItem}
                      >Latest Updates</MenuItem>
                      <Divider light />
                      <MenuItem onClick={handleLogout} className={dropdownItem}>
                        {rtlActive ? "الخروج" : "Log out"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <div className={managerClasses}>
            {Notifications?.filter((CurrentNotification) => CurrentNotification?.notificationread === 0)?.length ?
              <Badge badgeContent={Notifications?.filter((CurrentNotification) => CurrentNotification?.notificationread === 0)?.length} style={{ color: "#007FFF" }} onClick={() => setshowNotifications(showNotifications ? false : true)}>
                <NotificationsIcon style={{ color: "gray" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />
              </Badge>
              : <NotificationsIcon style={{ color: "gray" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />}
            {showNotifications ?
              <Card style={{ width: "600px", height: Notifications?.length === 0 ? "20%" : "80%", position: "fixed", overflowY: "hidden", right: "2%", borderRadius: "5%", padding: "20px", zIndex: 9999999 }}>
                <div style={{ overflowY: "auto", padding: "0", margin: "0" }}>
                  {Notifications?.length === 0 ? <><CloseIcon style={{ float: "right", marginRight: "20px", cursor: "pointer" }} onClick={() => setshowNotifications(showNotifications ? false : true)} /><div style={{ clear: "both" }} /><div>No Notificiations at this time.</div></> : ""}
                  {Notifications?.map((CurrentNotification, index) => {
                    return <>
                      {index === 0 ?
                        <><CloseIcon style={{ float: "right", marginRight: "40px", cursor: "pointer" }} onClick={() => setshowNotifications(showNotifications ? false : true)} />
                          <div style={{ clear: "both" }} />
                          <div style={{ padding: "20px" }}><a href={"/admin/notifications?customer=" + state.customers?.selected?.id}>view all</a><a href="#" style={{ float: "right" }} onClick={(e) => {
                            e.preventDefault();
                            updateAllNotifications({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                user_id: me?.readMe?.id
                              }
                            });
                          }}>mark all read</a></div><div style={{ clear: "both" }} /></>
                        : ""}
                      <div style={{ cursor: "pointer", padding: "20px", backgroundColor: Hover === CurrentNotification?.id ? "#f4f4f5" : CurrentNotification?.notificationread === 0 ? "#EEF3F9" : "#fff", width: "100%" }} onMouseOver={() => { setHover(CurrentNotification?.id) }} onMouseOut={() => { setHover(null) }}
                        onClick={(e) => {
                          if (e?.target?.innerHTML != "mark read") {
                            updateNotifications({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                notification_id: CurrentNotification?.id
                              }
                            })
                            window.location = CurrentNotification?.detailPage;
                          }
                        }}>
                        <div style={{ float: "left", display: "inline-block" }}>
                          <div style={{ borderRadius: "50%", backgroundColor: "#007FFF", height: "40px", width: "40px", textAlign: "center", float: "left", display: "inline-block" }}>
                            {CurrentNotification?.image_object === "Customer" ? <HomeWorkOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "2px", marginTop: "3px" }} />
                              : CurrentNotification?.image_object === "Lead" ? <FlagOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                : CurrentNotification?.image_object === "Person" ? <PermIdentityOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                  : CurrentNotification?.image_object === "Task" ? <TaskAltOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                    : CurrentNotification?.image_object === "Deadline" ? <MenuOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                      : CurrentNotification?.image_object === "Meeting" ? <GroupAddOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                        : CurrentNotification?.image_object === "Email" ? <EmailOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                          : CurrentNotification?.image_object === "Call" ? <PhoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                            : CurrentNotification?.image_object === "Text" ? <SmartphoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                              : CurrentNotification?.image_object === "Job" ? <WorkOutlineOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                : CurrentNotification?.image_object === "Contract" ? <RequestPageOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                  : CurrentNotification?.image_object === "Proposal" ? <DescriptionOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                    : CurrentNotification?.image_object === "Document" ? <UploadFileOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                      : CurrentNotification?.image_object === "Estimate" ? <TaskOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                        : CurrentNotification?.image_object === "Photo" ? <PhotoLibraryOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                          : CurrentNotification?.image_object === "Ticket" ? <ConfirmationNumberOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                            : CurrentNotification?.image_object === "Customer Submitted Onboarding" || CurrentNotification?.image_object === "Onboarding Sent to Customer" ? <MailOutline style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "4.5px", marginTop: "3px" }} />
                                                              : ""}
                          </div></div><div style={{ marginLeft: "30px", width: "70%", float: "left", display: "inline-block" }} >{CurrentNotification.message}</div>
                        <div style={{ clear: "both" }} />
                        <div style={{ marginTop: "10px", borderRadius: Notifications?.length - 1 === index ? "5%" : "0%" }}>{moment(new Date(parseInt(CurrentNotification.created_time))).fromNow()}{CurrentNotification?.notificationread === 0 ?
                          <a style={{ float: "right" }} href="#" onClick={() => {
                            updateNotifications({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                notification_id: CurrentNotification?.id
                              }
                            })
                          }}>mark read</a> : ""}</div></div>
                      {Notifications?.length - 1 !== index ? <hr style={{ color: "black", borderTop: "1px solid #000", padding: "0px", margin: "0px" }} /> : ""}
                    </>
                  })}
                </div>
              </Card>
              : ""}
            {currentProduct && (
              <Card>
                <CardBody>
                  <Dialog
                    open={Boolean(currentProduct)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogContent>
                      <UpdateCRMProduct
                        current={currentProduct}
                        setCurrentProduct={setCurrentProduct}
                        handleClose={handleProductClose}
                        refetch={productrefetch}
                        setproduct_cost={setproduct_cost}
                        product_cost={product_cost}
                        product_margin={product_margin}
                        setproduct_margin={setproduct_margin}
                        product_price={product_price}
                        setproduct_price={setproduct_price}
                        product_discount={product_discount}
                        setproduct_discount={setproduct_discount}
                        product_sale_price={product_sale_price}
                        setproduct_sale_price={setproduct_sale_price}
                        product_tax={product_tax}
                        setproduct_tax={setproduct_tax}
                      />
                    </DialogContent>
                  </Dialog>
                </CardBody>
              </Card>
            )}
            {currentService && (
              <Card>
                <CardBody>
                  <Dialog
                    open={Boolean(currentService)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth
                  >
                    <DialogContent>
                      <UpdateCRMService
                        current={currentService}
                        setCurrentService={setCurrentService}
                        handleClose={handleServiceClose}
                        refetch={servicesrefetch}
                        setservice_cost={setservice_cost}
                        service_cost={service_cost}
                        service_margin={service_margin}
                        setservice_margin={setservice_margin}
                        service_price={service_price}
                        setservice_price={setservice_price}
                        service_discount={service_discount}
                        setservice_discount={setservice_discount}
                        service_sale_price={service_sale_price}
                        setservice_sale_price={setservice_sale_price}
                        service_tax={service_tax}
                        setservice_tax={setservice_tax}
                      />
                    </DialogContent>
                  </Dialog>
                </CardBody>
              </Card>
            )}
            {UpdatePhoto?.id && (
              <Card>
                <CardBody>
                  <Dialog open onClose={() => handleSave()} maxWidth="sm" fullWidth>
                    <DialogContent>
                      <div>
                        <img
                          src={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format`}
                          srcSet={`${UpdatePhoto?.photo_url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                          alt={UpdatePhoto?.photo_alt_text || ""}
                          loading="lazy"
                          style={{ maxHeight: "200px", margin: "0 auto", display: "block" }}
                        /></div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }} >
                        <label
                          style={{ marginTop: "30px" }}
                        >Photo Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                        <input
                          id="photo_name"
                          maxLength={45}
                          type="text"
                          defaultValue={UpdatePhoto?.photo_name || ""}
                          onChange={updatePhotoFieldHandler("photo_name")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label
                          style={{ marginTop: "30px" }}>Description</label>
                        <textarea
                          id="description"
                          type="text"
                          defaultValue={UpdatePhoto?.photo_description || ""}
                          onChange={updatePhotoFieldHandler("photo_description")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label
                          style={{ marginTop: "30px" }}>Alt Text</label>
                        <textarea
                          id="photo_alt_text"
                          type="text"
                          defaultValue={UpdatePhoto?.photo_alt_text || ""}
                          onChange={updatePhotoFieldHandler("photo_alt_text")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                        />
                      </div>
                      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave() }}>
                        Save
                      </Button>
                    </DialogContent>
                  </Dialog>
                </CardBody>
              </Card>
            )}
          </div>
        </div>
      </div>
      {SearchResults(handleSearchClose, updateSearchALL, setShowAll, ShowAll, setShowCustomers, setShowLeads, setShowPeople, setShowJobs,
          setShowGalleries, setShowPhotos, setShowProducts, setShowServices, setShowContracts, setShowDocuments,
          setShowProposals, setShowEstimates, setShowTickets, CustomerSearchQuery, LeadSearchQuery, PeopleSearchQuery, JobSearchQuery,
          ProductSearchQuery, ServiceSearchQuery, GallerySearchQuery, DocumentSearchQuery, PhotosSearchQuery,
          TicketSearchQuery, EstimateSearchQuery, ProposalSearchQuery, ContractSearchQuery,
          ShowCustomers, ShowLeads, ShowPeople, ShowJobs, ShowGalleries, ShowPhotos, ShowProducts, ShowServices, ShowContracts,
          ShowDocuments, ShowProposals, ShowEstimates, ShowTickets,
          searchstring, CRMCustomersSearch, CRMLeadsSearch, CRMPeopleSearch, CRMJobSearch, CRMGallerySearch,
          CRMDocumentSearch, CRMContractSearch, CRMProposalSearch, CRMProductSearch, CRMServiceSearch, CRMTicketsSearch, CRMEstimatesSearch,
          CRMPhotosSearch, name, value, start, end, status, category, SKU, price, description, phone, address, officephone,
          email, type, service,
          setCRMCustomersSearch, setCRMLeadsSearch, setCRMPeopleSearch, setCRMJobSearch, setCRMGallerySearch,
          setCRMPhotosSearch, setCRMDocumentSearch, setCRMContractSearch, setCRMProposalSearch, setCRMProductSearch,
          setCRMServiceSearch, setCRMTicketsSearch, setCRMEstimatesSearch, Customerloading, Leadsloading, Peopleloading,
          Jobsloading, Galleryloading, Photosloading, Documentsloading, Contractsloading, Proposalsloading, Productsloading,
          Servicesloading, Ticketsloading, Estimatesloading, customer_id,
          handleProductOpen, handleServiceOpen, OpenPhotoEdit, B2B, CRMSettings)}
    </div>
  );
};

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  history: PropTypes.shape(),
  onSelect: PropTypes.func,
  setNotifications: PropTypes.func,
  isMobile: PropTypes.bool
};

HeaderLinks.defaultProps = {
  onSelect: noop
};

export default HeaderLinks;