import React, { useState } from "react";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CustomInput from "components/CustomInput/CustomInput";

const CPMCalculator = () => {
  const [data, setData] = useState({});

  const updateFieldHandler = field => e => {
    setData({
      ...data,
      [field]: e.target.value
    });
  };

  const calculateHandler = () => {
    if (!data?.impressions) {
      setData({
        ...data,
        impressions: Math.round((data?.budget * 1000) / data?.cpm)
      });
    } else if (!data?.cpm) {
      setData({
        ...data,
        cpm: (data?.budget * 1000) / data?.impressions
      });
    } else {
      setData({
        ...data,
        budget: (data?.cpm * data?.impressions) / 1000
      });
    }
  };

  const filledCount =
    (data?.impressions ? 1 : 0) + (data?.budget ? 1 : 0) + (data?.cpm ? 1 : 0);

  return (
    <div style={{ width: "70%", margin: "0 auto"}}>
      <Card>
        <CardBody>
          <h3>Impressions</h3>
          <CustomInput
            labelText="Impressions"
            inputProps={{
              type: "number",
              onChange: updateFieldHandler("impressions"),
              value: data?.impressions || ""
            }}
          />
          <h3>budget</h3>
          <CustomInput
            labelText="budget"
            inputProps={{
              type: "number",
              onChange: updateFieldHandler("budget"),
              value: data?.budget || "",
              style: { fontSize: 20 }
            }}
          />
          <h3>Cost Per 1,000 Impressions</h3>
          <CustomInput
            labelText="CPM"
            inputProps={{
              type: "number",
              onChange: updateFieldHandler("cpm"),
              value: data?.cpm || "",
              style: { fontSize: 20 }
            }}
          /><br />
          <Button
            disabled={filledCount !== 2}
            onClick={calculateHandler}
          >
            Calculate ({filledCount}/2)
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default CPMCalculator;
