import React from "react";
import Select from "components/Select/Select";
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import "assets/css/richtext_styles.css";
const moment = require("moment");

const filter = createFilterOptions();

const AddUpdateActivity = (setCallImageSelected,
  setDeadlineImageSelected, setTaskImageSelected, setMeetingImageSelected,
  setEmailImageSelected, setTextImageSelected, CallImageSelected, EmailImageSelected,
  TextImageSelected, MeetingImageSelected, TaskImageSelected, DeadlineImageSelected,
  CRMActivitySubmission, updateActivityFieldHandler, me, updateActivityDatabase,
  setactivity_type, activity_type, setCRMActivitySubmission, CRMAssignedToUsers,
  CRMRepresentative, BusinessOrPersonName, Update = false, CRMPeople, PersonFound,
  updatePeopleNameFieldHandler, updatePeopleFieldHandler) => {
  return (
    <div>
      <label style={{ textAlign: "center", width: "100%", paddingBottom: "10px" }}>{Update ? "Update" : "Log"} Activitiy</label>
      <div style={{ clear: "both" }}></div>
      <label style={{ float: "left", paddingRight: "20px" }}>Activity Type:</label>

      {Update === true && (
        <>
          {CRMActivitySubmission?.activity_type === "Call" && (
            <Tooltip title="Call" placement="top">
              <PhoneOutlinedIcon style={CallImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
          {CRMActivitySubmission?.activity_type === "Email" && (
            <Tooltip title="Email" placement="top">
              <EmailOutlinedIcon style={EmailImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
          {CRMActivitySubmission?.activity_type === "Text" && (
            <Tooltip title="Text" placement="top">
              <SmartphoneOutlinedIcon style={TextImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
          {CRMActivitySubmission?.activity_type === "Meeting" && (
            <Tooltip title="Meeting" placement="top">
              <GroupAddOutlinedIcon style={MeetingImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
          {CRMActivitySubmission?.activity_type === "Task" && (
            <Tooltip title="Task" placement="top">
              <TaskAltOutlinedIcon style={TaskImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
          {CRMActivitySubmission?.activity_type === "Deadline" && (
            <Tooltip title="Deadline" placement="top">
              <MenuOutlinedIcon style={DeadlineImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </Tooltip>
          )}
        </>
      )}
      {Update === false && (
        <>
          <Tooltip title="Call" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (CallImageSelected) {
                setCallImageSelected(false);
              }
              else {
                setDeadlineImageSelected(false);
                setTaskImageSelected(false);
                setMeetingImageSelected(false);
                setEmailImageSelected(false);
                setTextImageSelected(false);
                setCallImageSelected(true);
                setactivity_type("Call")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Call " + BusinessOrPersonName
                });
              }
            }}>
              <PhoneOutlinedIcon style={CallImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
          <Tooltip title="Email" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (EmailImageSelected) {
                setEmailImageSelected(false);
              }
              else {
                setDeadlineImageSelected(false);
                setTaskImageSelected(false);
                setMeetingImageSelected(false);
                setCallImageSelected(false);
                setTextImageSelected(false);
                setEmailImageSelected(true);
                setactivity_type("Email")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Email " + BusinessOrPersonName
                });
              }
            }}>
              <EmailOutlinedIcon style={EmailImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
          <Tooltip title="Text" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (TextImageSelected) {
                setTextImageSelected(false);
              }
              else {
                setDeadlineImageSelected(false);
                setTaskImageSelected(false);
                setMeetingImageSelected(false);
                setEmailImageSelected(false);
                setCallImageSelected(false);
                setTextImageSelected(true);
                setactivity_type("Text")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Text " + BusinessOrPersonName
                });
              }
            }}>
              <SmartphoneOutlinedIcon style={TextImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
          <Tooltip title="Meeting" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (MeetingImageSelected) {
                setMeetingImageSelected(false);
              }
              else {
                setDeadlineImageSelected(false);
                setTaskImageSelected(false);
                setTextImageSelected(false);
                setEmailImageSelected(false);
                setCallImageSelected(false);
                setMeetingImageSelected(true);
                setactivity_type("Meeting")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Meeting " + BusinessOrPersonName
                });
              }
            }}>
              <GroupAddOutlinedIcon style={MeetingImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
          <Tooltip title="Task" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (TaskImageSelected) {
                setTaskImageSelected(false);
              }
              else {
                setMeetingImageSelected(false);
                setDeadlineImageSelected(false);
                setTextImageSelected(false);
                setEmailImageSelected(false);
                setCallImageSelected(false);
                setTaskImageSelected(true);
                setactivity_type("Task")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Task " + BusinessOrPersonName
                });
              }
            }}>
              <TaskAltOutlinedIcon style={TaskImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
          <Tooltip title="Deadline" placement="top">
            <a onClick={(e) => {
              e.preventDefault();
              if (DeadlineImageSelected) {
                setDeadlineImageSelected(false);
              }
              else {
                setMeetingImageSelected(false);
                setTaskImageSelected(false);
                setTextImageSelected(false);
                setEmailImageSelected(false);
                setCallImageSelected(false);
                setDeadlineImageSelected(true);
                setactivity_type("Deadline")
                setCRMActivitySubmission({
                  ...CRMActivitySubmission,
                  ["activity_description"]: "Deadline " + BusinessOrPersonName
                });
              }
            }}>
              <MenuOutlinedIcon style={DeadlineImageSelected ? { marginRight: "10px", backgroundColor: "#D3D3D3", width: "40px", borderRadius: "8px", border: "1px solid #D3D3D3" } : { marginRight: "10px", width: "40px" }} />
            </a>
          </Tooltip>
        </>
      )}
      <div style={{ clear: "both" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Description:</label>
        <input
          id="activity_description"
          maxLength={255}
          type="text"
          value={CRMActivitySubmission?.activity_description || activity_type || ""}
          onChange={updateActivityFieldHandler("activity_description")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both" }}></div>
      <div>
        <RichTextEditor key={CRMActivitySubmission?.activity_type?.length} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} current={CRMActivitySubmission} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px" }} />
      </div>
      {me?.readMe?.user_level === "master" && (
        <>
          <label style={{ textAlign: "center", paddingRight: "20px", paddingBottom: "10px", marginTop: "15px" }}>Schedule As Future Activitiy</label>
          <div style={{ clear: "both" }}></div>
        </>
      )}
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ float: "left", width: "136px", marginRight: "2px" }}>Schedule Activity:</label>
        <TextField
          id="datetime-local"
          type="datetime-local"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(parseInt(CRMActivitySubmission?.activity_date)).format("YYYY-MM-DDTHH:mm") : CRMActivitySubmission?.activity_date ? CRMActivitySubmission?.activity_date : ""}
          onChange={
            updateActivityFieldHandler("activity_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both"}}></div>
      <div style={{ marginTop: "20px" }}>
        <label
          style={{ marginTop: "8px", width: "115px", float: "left", textAlign: "left" }}>People:</label>
        {Update === true ?
          <Stack spacing={2}>
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              disableClearable
              id="people_name"
              size={"small"}
              style={{ float: "left", borderRadius: "5px", width: "100%" }}
              value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === CRMActivitySubmission?.people_id)?.map((CurrentPerson) => {
                return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
              })[0] || { label: CRMActivitySubmission?.people_name ? CRMActivitySubmission?.people_name : "" }}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updatePeopleNameFieldHandler(newValue.label, "Activity")
                } else if (newValue) {
                  updatePeopleNameFieldHandler(newValue.label, "Activity")
                }
              }}
              options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                key: id,
                label: first_name + " " + last_name
              })) || []}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Regular option
                return option?.label;
              }}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { padding: 0, width: "100%" }
                  }}
                  onChange={updatePeopleFieldHandler("people_name", "Activity")}
                  maxLength={255}
                />
              )}
            />
          </Stack>
          :
          <Stack spacing={2}>
            <Autocomplete
              freeSolo
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              disableClearable
              id="people_name"
              size={"small"}
              style={{ float: "left", borderRadius: "5px", width: "100%" }}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  updatePeopleNameFieldHandler(newValue.label, "Activity")
                } else if (newValue) {
                  updatePeopleNameFieldHandler(newValue.label, "Activity")
                }
              }}
              options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                key: id,
                label: first_name + " " + last_name
              })) || []}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Regular option
                return option?.label;
              }}
              renderOption={(props, option) => <li {...props}>{option.label}</li>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { padding: 0, width: "100%" }
                  }}
                  onChange={updatePeopleFieldHandler("people_name", "Activity")}
                  maxLength={255}
                />
              )}
            />
          </Stack>
        }
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {PersonFound ? "" :
        <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div>
        <label style={{ marginTop: "8px", width: "115px", float: "left", textAlign: "left" }}>Assign To:</label>
        <Select
          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
            key: id,
            label: first_name + " " + last_name
          }))}
          selected={CRMActivitySubmission?.assigned_to || ""}
          onSelect={updateActivityFieldHandler("assigned_to")}
          style={{ marginBottom: 20, height: "40px", float: "left" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div>
        <label style={{ textAlign: "left", width: "150px", float: "left" }}>Mark Completed:</label>
        <Checkbox
          checked={CRMActivitySubmission?.activity_completed ? true : false}
          style={{ "width": "20px", float: "left" }}
          onChange={updateActivityFieldHandler("activity_completed")}>
        </Checkbox>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff", float: "left" }} onClick={() => updateActivityDatabase("Activity")}>
        Save
      </Button>
    </div>
  )
};

export default AddUpdateActivity;
