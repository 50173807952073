import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import ReactDOM from 'react-dom'


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Icon from "@mui/material/Icon";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import { LIST_PAIDSEARCH_CAMPAIGN_AD_SCHEDULES } from "queries/google";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import { READ_ME } from "queries/users";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

const PaidSearchCampaignAdSchedules = ({ filter, type_filter, title, isVideo, heading }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const { data: me } = useQuery(READ_ME);
  let { loading, data } = useQuery(LIST_PAIDSEARCH_CAMPAIGN_AD_SCHEDULES, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      filter,
      type_filter,
      campaign_name: "Awareness - RE"
    }
  });

  const classes = useStyles();
  data = data?.listPaidSearchCampaignAdSchedules || [];

  let columns = ""

  columns = [
    {
      Header: "Campaign Name",
      accessor: "campaign_name"
    },
    {
      Header: "Status",
      accessor: "campaign_status"
    },
    {
      Header: "Day",
      accessor: "day_of_week"
    },
    {
      Header: "Start Time",
      accessor: "start_hour",
      Cell: ({ original }) => (
        `${original?.start_hour}:${original?.start_minute === "ZERO" ? "00" :
        original?.start_minute === "FIFTEEN" ? "15" :
        original?.start_minute === "THIRTY" ? "30" :
        original?.start_minute === "FORTY_FIVE" ? "45" : original?.start_minute
        }`
      )
    },
    {
      Header: "End Time",
      accessor: "end_hour",
      Cell: ({ original }) => (
        `${original?.end_hour}:${original?.end_minute === "ZERO" ? "00" :
        original?.end_minute === "FIFTEEN" ? "15" :
        original?.end_minute === "THIRTY" ? "30" :
        original?.end_minute === "FORTY_FIVE" ? "45" : original?.end_minute
        }`
      )
    },
    {
      Header: "Time Zone",
      accessor: "google_account_info.time_zone",
      Cell: ({ original }) => (
        `${original?.google_account_info.time_zone.replace("/", " ").replace("_", " ")}`
        )
    }
  ];

  return (
    data?.length !== 0 ? 
    <div><h2 className={classes.pageHeader}>{heading}</h2>
      <GridContainer>
        <GridItem xs={12}>
        <Card>
            <CardHeader color="google" icon>
              <CardIcon>
                <Icon>list</Icon>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                { title } Campaign Ad Schedules
                <HelpTooltip>
                  {`Ad scheduling allows us to display your ads during certain times to maximize the leads you are getting.`}
                </HelpTooltip>
                <CSVDownloader
                  data={data}
                  columns={columns}
                  filename={`PaidSearch Campaign Ad Schedules (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                key={data?.length}
                data={data}
                filterable
                loading={loading}
                columns={columns}
                defaultPageSize={Math.min(10, data?.length || 2)}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
                CustomStyle={true}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
    :""
  );
};

export default PaidSearchCampaignAdSchedules;
