import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Icon from "@mui/material/Icon";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem";
import HelpTooltip from "components/HelpTooltip";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

const StatsCard = ({
  milestones,
  label,
  action,
  subheading,
  loading,
  value,
  icon,
  render,
  footerRender,
  descending,
  help,
  footer,
  previous,
  onClick
}) => {
  const classes = useStyles();

  let i = 0;
  for (i = 0; i < milestones.length - 1; i++) {
    if (
      (value <= milestones[i].value && !descending) ||
      (value >= milestones[i].value && descending)
    ) {
      break;
    }
  }
  const milestone = (loading ? milestones[0] : milestones[i]) || {
    icon: "",
    message: ""
  };

  let renderFunc = value => value;

  if (render === "integer") {
    renderFunc = value => new Intl.NumberFormat("en-US").format(value);
  } else if (render === "float" || render === "number") {
    renderFunc = value =>
      new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);
  } else if (render === "percentage") {
    renderFunc = value =>
      `${new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value)}%`;
  } else if (render === "currency") {
    renderFunc = value =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value);
  } else if (render === "date") {
    renderFunc = value => moment(value).format("MM/DD/YYYY");
  } else if (typeof render === "function") {
    renderFunc = render;
  }
  const footerLabel =
    footerRender && footer && footer !== "..."
      ? `${renderFunc(footer)}`
      : `${footer}`;
  return (
    <GridItem xs={12} md={6} lg={4}>
      <Card>
        <CardHeader stats icon>
          <CardIcon color={milestone.color}>
            <Icon>{icon}</Icon>
          </CardIcon>
          <p className={classes.cardCategory}>
            {label}
            {help && <HelpTooltip>{help}</HelpTooltip>}
          </p>
          <h2 className={classes.cardTitle}>
            {loading ? (
              "Fetching..."
            ) : onClick ? (
              <a href="#" onClick={onClick}>
                {renderFunc(value)}
              </a>
            ) : (
              renderFunc(value)
            )}
          </h2>
          <div style={{ color: "black" }}>{loading ? "" : subheading}</div>
        </CardHeader>
        <CardFooter stats>
          {previous && footerLabel ? `Previous: ${footerLabel}` : footerLabel}
          {loading ? (
            <div className={classes.stats}>Fetching ...</div>
          ) : (
            <div className={classes.stats}>
              {milestone.link ? (
                <Link to={milestone.link}>
                  {milestone.icon && <Icon>{milestone.icon}</Icon>}
                  {milestone.message}
                </Link>
              ) : (
                <>
                  {milestone.icon && <Icon>{milestone.icon}</Icon>}
                  {milestone.message}
                </>
              )}
            </div>
          )}
          <div>{action}</div>
        </CardFooter>
      </Card>
    </GridItem>
  );
};

StatsCard.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  loading: PropTypes.bool,
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      icon: PropTypes.string,
      message: PropTypes.string,
      color: PropTypes.string
    })
  ),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  descending: PropTypes.bool,
  help: PropTypes.string,
  action: PropTypes.node,
  subheading: PropTypes.string,
  footer: PropTypes.string
};

StatsCard.defaultProps = {
  milestones: [],
  loading: false,
  descending: false,
  action: null,
  subheading: "",
  footer: ""
};

export default StatsCard;
