import { SET_TOKEN } from "../../redux/constants";

const Reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        tokenInfo: action.payload || ""
      };
    default:
      return state;
  }
};

export default Reducer;
