import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import NoContentWrapper from "components/NoContentWrapper";
import { Context } from "../../../redux/store";

import SocialAdsCampaigns from "./SocialAdsCampaigns";
import CampaignBreakdown from "./CampaignBreakdown";
import AdResultsBreakdown from "./AdResultsBreakdown";
import SocialAssets from "./SocialAssets";

const useStyles = makeStyles(styles);

export default function SocialAds() {
  const [state] = useContext(Context);
  const classes = useStyles();
  const facebook_id = state.customers?.selected?.facebook_id;

  return (
    <NoContentWrapper
      product="Social Ads"
      isLoading={() => false}
      hasNoData={() => !facebook_id}
      noContent={`
        With Water Bear Marketing's SocialAds we can help get your ads in front of your potential customers where they are spending most of their time on the Internet. We eliminate wasted ad impressions by leveraging our technology and proprietary targeting products.
        What is SocialAds with GeoDemo®? SocialAds with GeoDemo® is a Water Bear Marketing proprietary targeting that helps our customers target based on a defined geographic area and demographics, so that we can hone in on your ideal customers. We are able to eliminate ad impressions waste while still getting your brand in front of the majority of people.
        What is SocialAds with ReEngage®? ReEngage® is a Water Bear Marketing is a Water Bear Marketing proprietary advertising targeting capability that enables our customers to communicate with people who have previously engaged with them.  Using Social Ads, we can reach many adults on Facebook or Instagram on mobile, desktop, or tablet devices. This allows us to help our clients advertise in front of people who have previously visited their website. Keep the conversation moving forward with ReEngage®.
        With Social Ads with BoostedPost, we take your posts that we created through our OrganicSearch product and promote them on Facebook or Instagram to reach and engage with your ideal audience or existing friends on Facebook or even people who have already been to your website.
      `}
    >
      <h2 className={classes.pageHeader}>SocialAds Campaigns</h2>
      <SocialAdsCampaigns />
      <h2 className={classes.pageHeader}>SocialAds Campagin Breakdown</h2>
      <CampaignBreakdown />
      <h2 className={classes.pageHeader}>SocialAds Ad Results Breakdown</h2>
      <AdResultsBreakdown />
      <h2 className={classes.pageHeader}>Links to SocialAds Assets</h2>
      <SocialAssets />
    </NoContentWrapper>
  );
}
