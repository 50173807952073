import React from "react";

import { makeStyles } from "@mui/styles";

const styles = {
  container: {
    height: 50,
    padding: "0 20px",
    cursor: "pointer",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "lightGray"
    }
  }
};

styles.activeContainer = {
  ...styles.container,
  backgroundColor: "lightGray"
};

const useStyles = makeStyles(styles);

const NavItem = ({ children, active, ...rest }) => {
  const classes = useStyles();
  return (
    <div
      className={active ? classes.activeContainer : classes.container}
      {...rest}
    >
      {children}
    </div>
  );
};

export default NavItem;
