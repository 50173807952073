import React from "react";
import { useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import { startCase } from "lodash";

const Meta = () => {
  const history = useLocation();
  const lastPath = history.pathname.split("/").slice(-1)[0];
  return (
    <Helmet>
      <title>
        Water Bear Marketing Reporting Dashboard | {startCase(lastPath)}
      </title>
    </Helmet>
  );
};

export default Meta;
