import React, { useContext, useState, useEffect } from "react";
import cx from "classnames";
import { Route, Outlet, useParams } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import { makeStyles } from "@mui/styles";

import { useQuery, useLazyQuery } from "@apollo/client";
import { READ_ME } from "queries/users";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import { offWhiteColor } from "assets/jss/material-dashboard-pro-react";
import { Context } from "../redux/store";

import routes from "routes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle";

import {
  GET_NOTIFICATIONS
} from "queries/customers";

var ps;

const useStyles = makeStyles(styles);

const Admin = (props) => {
  const { ...rest } = props;
  const { filter } = useParams();
  const [state] = useContext(Context);
  const { data: me } = useQuery(READ_ME);
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  // states and functions
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [image] = useState(require("assets/img/sidebar-2.jpg"));
  const [color] = useState("blue");
  const bgColor = offWhiteColor;
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.userAgent.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  const [GetCallDataQuery] = useLazyQuery(GET_NOTIFICATIONS, {
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      user_id: me?.readMe?.id
    },
    onCompleted: (data) => {
      setNotifications(data?.getNotifications || []);
    }
  });

  let { data: notifications_data } = useQuery(GET_NOTIFICATIONS, {
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      user_id: me?.readMe?.id
    }
  });

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    try {
      setNotifications(notifications_data?.getNotifications || []);

      if (navigator.userAgent.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(mainPanel.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        });
        document.body.style.overflow = "hidden";
      }
      window.addEventListener("resize", resizeFunction);
      // Specify how to clean up after this effect:
      return function cleanup() {
        if (navigator.userAgent.indexOf("Win") > -1) {
          ps.destroy();
        }
        window.removeEventListener("resize", resizeFunction);
      };
    } catch (err) {
      console.log("mainPanel.current not found");
    }
  }, [notifications_data]);

  if (localStorage.getItem("has_run") !== "yes") {
    localStorage.setItem("has_run", "yes");
    return (
      <Route
        path="/admin"
        element={<Navigate to="/admin/account-overview" />}
      />
    );
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const LoadNewNotifications = () => {
    GetCallDataQuery();
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logo={"assets/img/logo-white.svg"}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        closeDrawer={closeDrawer}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        Notifications={Notifications}
        setNotifications={setNotifications}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          Notifications={Notifications}
          setNotifications={setNotifications}
          {...rest}
        />
        <div className={classes.content}>
          <Outlet context={{LoadNewNotifications}} />
        </div>
        <Footer />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      </div>
    </div>
  );
};

export default Admin;
