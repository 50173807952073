import React from "react";
import PropTypes from "prop-types";

import { withStyles, makeStyles } from "@mui/styles";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

import styles from "./styles";

const useStyles = makeStyles(styles);

const HelpTooltip = ({ children, style = {} }) => {
  const classes = useStyles();

  const HelpTooltipWrapper = withStyles(() => ({
    tooltip: {
      fontSize: 15,
      ...style
    }
  }))(Tooltip);
  return (
    <ThemeProvider theme={theme}><HelpTooltipWrapper
      title={children}
      className={classes.helpIcon}
      disableTouchListener
    >
      <InfoIcon size={15} />
    </HelpTooltipWrapper>
    </ThemeProvider>
  );
};

HelpTooltip.propTypes = {
  children: PropTypes.string
};

HelpTooltip.defaultProps = {
  children: ""
};

export default HelpTooltip;
