import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_AD_EXTENSIONS_SITE_LINKS } from "queries/google";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Sitelinks = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_GOOGLE_AD_EXTENSIONS_SITE_LINKS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGoogleAdExtensionsSiteLinks || [];

  data = data.map((row) => ({    
    ...row,
    ctr: Number(row.impressions) !== 0 ? Number(((row.clicks / row.impressions) * 100).toFixed(2)): 0
  }));

  const classes = useStyles();

  const inputs = [
    {
      field: "final_urls",
      label: "Site Link Feed Item Final Urls",
      inputProps: { type: "url" }
    },
    {
      field: "link_text",
      label: "Site Link Feed Item Link Text",
      inputProps: {
        maxLength: 25,
        validation: v => `${v.length}/25 Characters`
      }
    }, 
    {
      field: "line1",
      label: "Site Link Feed Item Line 1",
      inputProps: {
        maxLength: 35,
        validation: v => `${v.length}/35 Characters`
      }
    },
    {
      field: "line2",
      label: "Site Link Feed Item Line 2",
      inputProps: {
        maxLength: 35,
        validation: v => `${v.length}/35 Characters`
      }
    },
    { field: "notes", label: "Notes", optional: true }
  ];

  data = data.filter(row => inputs.some(({ field }) => row[field]));

  const hasUpdate =
    user_level !== "customer" && user_level !== "agency_customer";
  const columns = compact([
    {
      Header: "Site Links",
      accessor: "site_links",
      width: 250,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <a
            href={original?.final_urls}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original?.link_text}
          </a>
          <br />
          <div>{original?.line1}</div>
          <div>{original?.line2}</div>
        </>
      ),
      renderCSV: ({ original }) =>
        [
          original?.link_text,
          "",
          original?.line1,
          original?.line2
        ].join("\n")
    },
    {
      Header: "Campaign Name",
      accessor: "campaign_name"
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name",
      width: 200
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
        original?.ctr + "%"
    },
    {
      Header: "CPC",
      accessor: "average_cpc",
      width: 100,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin(
            (original?.cost || 0) / (original?.clicks || 1) / 1000000,
            state
          )
        )
    }
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Site Links
              <HelpTooltip>
                {`Sitelinks help users go deep into your website, directly from an ad. Sitelinks appear beneath the text of your ads, helping customers find what they're looking for on your site with just one click.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Site Links (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(Sitelinks);
