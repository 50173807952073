import React from 'react'
import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const OPTIONS = {
  //minZoom: 6,
  //maxZoom: 6,
  scrollwheel: false
}

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBxhBrA71Kx4JR2A68eS0uOpGDiOu-8rYc"
  })

  const [map, setMap] = React.useState(null)
  

  if (props.Proximitydata.length === 0)
    return null

  let LocalProximitydata = "";

  if (props.campaignMap !== "All Campaigns") {
    LocalProximitydata = props.Proximitydata?.filter(CurrentCampaign => CurrentCampaign.campaign_name === props.campaignMap)
  } else {
    LocalProximitydata = props.Proximitydata
  }

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: parseFloat(Math.round(LocalProximitydata[0]?.latitude_in_micro_degrees / 1000000 * 100) / 100), lng: parseFloat(Math.round(LocalProximitydata[0]?.longitude_in_micro_degrees / 1000000 * 100) / 100) });
    //map.fitBounds(bounds);
    map.setZoom(6);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      options = {OPTIONS}
      mapContainerStyle={containerStyle}
      onLoad={onLoad}
      center={{ lat: parseFloat(Math.round(LocalProximitydata[0]?.latitude_in_micro_degrees / 1000000 * 100) / 100), lng: parseFloat(Math.round(LocalProximitydata[0]?.longitude_in_micro_degrees / 1000000 * 100) / 100) }}
      onUnmount={onUnmount}
    >
      {LocalProximitydata.map((CurrentCampaign) => <Circle options={{ strokeColor: "#7B868C", strokeOpacity: 0.8, strokeWeight: 2, fillColor: "#7B868C", fillOpacity: 0.15 }} center={{ lat: parseFloat(Math.round(CurrentCampaign?.latitude_in_micro_degrees / 1000000 * 100) / 100), lng: parseFloat(Math.round(CurrentCampaign?.longitude_in_micro_degrees / 1000000 * 100) / 100) }} radius={(parseFloat(CurrentCampaign?.radius) * 1609.344)} />)}
      <></>
    </GoogleMap>
  ) : <></>
}

export default React.memo(MyMapComponent)