import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
const moment = require("moment");

const DocumentList = (row, index, CRMDocument, object_key, CRMRepresentative, isDocumentOpenCollapse, customer_id) => {
  return CRMDocument?.filter((CurrentDocument) => CurrentDocument[object_key] === row?.id || CurrentDocument?.id === row?.document_id || (object_key === "ticket_id" && CurrentDocument?.id === row?.document_id)).map(CurrentDocument => {
    return (
      <>
        {isDocumentOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isDocumentOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Name</b><br /> <a href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + CurrentDocument?.crm_customers_id + "&peopleid=" + CurrentDocument?.people_id + "&documentid=" + CurrentDocument?.id}>{CurrentDocument?.documents_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Date</b><br /> {CurrentDocument?.documents_date !== null ? moment(new Date(parseInt(CurrentDocument?.documents_date))).utc().format("MM-DD-YYYY, h:mm:ss A"): ""}</div>
                  <div style={{ width: "175px", float: "left" }}><b>URL</b><br /> <a href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + CurrentDocument?.crm_customers_id + "&peopleid=" + CurrentDocument?.people_id + "&documentid=" + CurrentDocument?.id} target="_blank">View</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Status</b><br /> {CurrentDocument?.documents_status}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRepresentative) => CurrentRepresentative?.id === CurrentDocument?.representative_id).map(CurrentRepresentative => { return <div>{CurrentRepresentative?.first_name} {CurrentRepresentative?.last_name}</div> })}</div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow >
        )}
      </>
    )
  })
};

export default DocumentList;
