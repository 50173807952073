import gql from "graphql-tag";

export const CREATE_QUICKBOOK_CUSTOMER = gql`
  mutation($customer_id: Int!, $isAgency: Boolean, $data: String!) {
    createQuickbookCustomer(
      customer_id: $customer_id
      isAgency: $isAgency
      data: $data
    )
  }
`;

export const READ_QUICKBOOK_CUSTOMER = gql`
  query readQuickbookCustomer($quick_books_id: Int!) {
    readQuickbookCustomer(quick_books_id: $quick_books_id)
  }
`;

export const CREATE_CARD = gql`
  mutation($quick_books_id: Int!, $card_info: CardInfoInput!) {
    createCard(quick_books_id: $quick_books_id, card_info: $card_info) {
      id
      name
      number
      created
      cardType
      status
      expMonth
      expYear
      cvc
      errors {
        moreInfo
        detail
      }
    }
  }
`;

export const REMOVE_CARD = gql`
  mutation($quick_books_id: Int!, $card_id: String!) {
    removeCard(quick_books_id: $quick_books_id, card_id: $card_id)
  }
`;

export const LIST_CARDS = gql`
  query listCards($quick_books_id: Int!) {
    listCards(quick_books_id: $quick_books_id) {
      id
      name
      number
      created
      cardType
      status
      expMonth
      expYear
      cvc
      errors {
        moreInfo
      }
    }
  }
`;

export const CREATE_BANK = gql`
  mutation($quick_books_id: Int!, $bank_info: BankInfoInput!) {
    createBank(quick_books_id: $quick_books_id, bank_info: $bank_info) {
      id
      name
      accountNumber
      created
      accountType
      phone
      routingNumber
      errors {
        moreInfo
        detail
      }
    }
  }
`;

export const REMOVE_BANK = gql`
  mutation($quick_books_id: Int!, $payment_id: String!) {
    removeBank(quick_books_id: $quick_books_id, payment_id: $payment_id)
  }
`;

export const LIST_BANKS = gql`
  query listBanks($quick_books_id: Int!) {
    listBanks(quick_books_id: $quick_books_id) {
      id
      name
      accountNumber
      created
      accountType
      phone
      routingNumber
      errors {
        moreInfo
      }
    }
  }
`;
