import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import BudgetCard from "components/BudgetCard";
import StatsCard from "components/StatsCard";
import { GET_SOCIAL_ADS_CAMPAIGN_INFO_SUMMARY } from "queries/social";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const SocialAdsCampaigns = () => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const facebook_id = state.customers?.selected?.facebook_id;
  const { loading, data } = useQuery(GET_SOCIAL_ADS_CAMPAIGN_INFO_SUMMARY, {
    skip: !facebook_id || !state.customers?.dateRangeString,
    variables: {
      facebook_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  const summary = data?.getSocialAdsCampaignInfoSummary;
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const { loading: loadingCompare, data: compareData } = useQuery(
    GET_SOCIAL_ADS_CAMPAIGN_INFO_SUMMARY,
    {
      skip: !facebook_id || !compareDateRange,
      variables: {
        facebook_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const compareSummary = compareData?.getSocialAdsCampaignInfoSummary;
  return (
    <GridContainer>
      <StatsCard
        icon="attach_money"
        loading={loading}
        label="Total Spent"
        value={addMargin(
          (summary?.spend || 0) + (summary?.social_spend || 0),
          state
        )}
        render="currency"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin(
                (summary?.spend || 0) + (summary?.social_spend || 0),
                state
              )
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Impressions"
        value={summary?.impressions || 0}
        render="integer"
        help="Estimated Audience Impressions"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? compareSummary?.impressions || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Reach"
        value={summary?.reach || 0}
        render="integer"
        help="Number of times your ad was reached"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? compareSummary?.reach || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Clicks"
        value={summary?.clicks || 0}
        render="integer"
        previous
        help="Clicks are the count of the number of times users have clicked on your advertisement."
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? compareSummary?.clicks || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="scatter_plot"
        loading={loading}
        label="CTR"
        value={((summary?.clicks || 0) / (summary?.impressions || 1)) * 100}
        render="percentage"
        milestones={[
          {
            value: 1,
            color: "info",
            icon: "fitness_center",
            message: `Not met "Working on it.."`
          },
          {
            value: 3,
            color: "orange",
            icon: "sentiment_satisfied",
            message: "Average"
          },
          {
            color: "wbmMoney",
            icon: "sentiment_very_satisfied",
            message: "Above Average"
          }
        ]}
        help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
      />
      <StatsCard
        icon="image_aspect_ratio"
        loading={loading}
        label="CPC"
        value={addMargin(
          ((summary?.spend || 0) + (summary?.social_spend || 0)) /
            Math.max(summary?.clicks || 0, 1),
          state
        )}
        descending
        milestones={[
          {
            value: 5,
            color: "info",
            icon: "fitness_center",
            message: `Not met "Working on it.."`
          },
          {
            value: 1,
            color: "grey",
            icon: "sentiment_satisfied",
            message: "Average"
          },
          {
            value: 0.5,
            color: "orange",
            icon: "sentiment_very_satisfied",
            message: "Above Average"
          },
          {
            color: "wbmMoney",
            icon: "star",
            message: "Best Performing"
          }
        ]}
        render="currency"
        help="CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
      />
      <BudgetCard
        productLine="social_media_ads_geo_demo"
        title="SocialAds | GeoDemo®"
      />
      <BudgetCard
        productLine="social_media_ads_reengage"
        title="Social Ads | ReEngage®"
      />
      <BudgetCard
        productLine="social_media_ads_boosted_posts"
        title="SocialAds | Boosted Posts"
      />
    </GridContainer>
  );
};

export default SocialAdsCampaigns;
