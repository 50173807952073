import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { makeStyles } from "@mui/styles";

import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_COMMISSION } from "queries/commissions";
import { Context } from "../../../redux/store";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Commissions() {
  const [state] = useContext(Context);
  const classes = useStyles();
  const commission = state?.auth?.tokenInfo?.user?.commission || 0;
  const { loading: mtdLoading, data: mtd } = useQuery(GET_COMMISSION, {
    variables: {
      start_date: moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    }
  });
  const { loading: lmLoading, data: lm } = useQuery(GET_COMMISSION, {
    variables: {
      start_date: moment()
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD")
    }
  });
  const { loading: ytdLoading, data: ytd } = useQuery(GET_COMMISSION, {
    variables: {
      start_date: moment()
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    }
  });
  const { loading: lyLoading, data: ly } = useQuery(GET_COMMISSION, {
    variables: {
      start_date: moment()
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment()
        .subtract(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD")
    }
  });

  return (
    <div>
      <h2 className={classes.pageHeader}>Commissions</h2>
      <GridContainer>
        <StatsCard
          icon="donut_small"
          loading={false}
          label="Commission %"
          value={commission}
          render="percentage"
        />
        <StatsCard
          icon="attach_money"
          loading={mtdLoading}
          label="MTD"
          value={mtd?.getCommission || 0}
          render="currency"
        />
        <StatsCard
          icon="attach_money"
          loading={lmLoading}
          label="Last Month"
          value={lm?.getCommission || 0}
          render="currency"
        />
        <StatsCard
          icon="people"
          loading={!state?.customers?.data?.length}
          label="Number of Customers"
          value={state?.customers?.data?.length || 0}
          render="integer"
        />
        <StatsCard
          icon="attach_money"
          loading={ytdLoading}
          label="YTD"
          value={ytd?.getCommission || 0}
          render="currency"
        />
        <StatsCard
          icon="attach_money"
          loading={lyLoading}
          label="Last Year"
          value={ly?.getCommission || 0}
          render="currency"
        />
      </GridContainer>
    </div>
  );
}
