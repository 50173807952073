import React from "react";
import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import UploadInput from "components/UploadInput";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";
const moment = require("moment");

const filter = createFilterOptions();

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}

const AddUpdateContract = (CRMActivitySubmission, updateActivityFieldHandler, updateContractDatabase,
  CRMPeople, ContractsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, updateContractFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, CRMProducts, CRMServices, Update = false, CRMActivity = [], CRMPhotoGallery, CRMJob,
  setContractsubmissionData, contractvalue, setcontractvalue, ContractStatuses, user_level, OnboardingID) => {

  const FormatCurrency = (value) => {
    let newValue = parseFloat(value?.replaceAll(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setContractsubmissionData({
      ...ContractsubmissionData,
      ["contract_value"]: newValue
    });
  }

  if (CRMProducts && ContractsubmissionData?.products && typeof ContractsubmissionData?.products === 'string') {
    let Product_Data = [];
    ContractsubmissionData?.products?.split(",")?.forEach(async (SelectedProduct) => {
      CRMProducts?.forEach(async (ContractsubmissionDataProduct) => {
        if (SelectedProduct?.toString()?.trim() === ContractsubmissionDataProduct?.id?.toString()) {
          Product_Data.push(ContractsubmissionDataProduct?.product_name);
        }
      })
    })

    if (Product_Data?.length) {
      ContractsubmissionData.products = Product_Data;
    }
  }

  if (CRMServices && ContractsubmissionData?.services && typeof ContractsubmissionData?.services === 'string') {
    let Service_Data = [];
    ContractsubmissionData?.services?.split(",")?.forEach(async (SelectedCategory) => {
      CRMServices?.forEach(async (ContractsubmissionDataCategory) => {
        if (SelectedCategory?.toString()?.trim() === ContractsubmissionDataCategory?.id?.toString()) {
          Service_Data.push(ContractsubmissionDataCategory?.service_name);
        }
      })
    })

    if (Service_Data?.length) {
      ContractsubmissionData.services = Service_Data;
    }
  }

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Upload A"} Contract</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Contract Name:</label>
        <input
          id="contract_name"
          maxLength={255}
          type="text"
          value={ContractsubmissionData?.contract_name || ""}
          onChange={updateContractFieldHandler("contract_name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Service(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateContractFieldHandler("services")}
          inputProps={{
            name: "services",
            id: "services",
            value: (typeof ContractsubmissionData?.services === 'string' || ContractsubmissionData?.services instanceof String) ? ContractsubmissionData?.services?.split(",").map((category_text) => { return category_text }) || [] : (typeof ContractsubmissionData?.services !== 'undefined') ? ContractsubmissionData?.services || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMServices?.map((ContractsubmissionDataService) => {
            return (
              <MenuItem key={ContractsubmissionDataService?.id} value={ContractsubmissionDataService?.service_name}>
                <CustomCheckbox
                  checked={
                    ContractsubmissionData?.services?.indexOf(ContractsubmissionDataService?.service_name) >
                    -1
                  }
                />
                <ListItemText primary={ContractsubmissionDataService?.service_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Product(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateContractFieldHandler("products")}
          inputProps={{
            name: "products",
            id: "products",
            value: (typeof ContractsubmissionData?.products === 'string' || ContractsubmissionData?.products instanceof String) ? ContractsubmissionData?.products?.split(",").map((product_name) => { return product_name }) || [] : (typeof ContractsubmissionData?.products !== 'undefined') ? ContractsubmissionData?.products || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMProducts?.map((ContractsubmissionDataProduct) => {
            return (
              <MenuItem key={ContractsubmissionDataProduct?.id} value={ContractsubmissionDataProduct?.product_name}>
                <CustomCheckbox
                  checked={
                    ContractsubmissionData?.products?.indexOf(ContractsubmissionDataProduct?.product_name) >
                    -1
                  }
                />
                <ListItemText primary={ContractsubmissionDataProduct?.product_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {/*  only visible to water bears and tom's testing company */}
      {(customer_id === 38 || customer_id === 165) && typeof ContractsubmissionData?.products !== 'string' ?
        ContractsubmissionData?.products?.map((CurrentProduct, index) => {
          return (
            <div style={{ display: "flex", marginTop: "20px" }}>
              <label style={{ textAlign: "left", width: "150px" }}>{CurrentProduct === "WebsiteDesign" ||
                CurrentProduct === "WebsiteDesign | WooCommerce" || CurrentProduct === "OCA | Production"
                || CurrentProduct === "OCA | Production Two" || CurrentProduct === "OCA | Production Three" ? CurrentProduct + " Total" : CurrentProduct + " Monthly"} Budget $:</label>
              <input
                id={`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`}
                maxLength={255}
                type="text"
                value={ContractsubmissionData?.Budgets !== undefined && Object.keys(ContractsubmissionData["Budgets"])?.length ? Object.keys(ContractsubmissionData["Budgets"])?.map((item, i) => { if (ContractsubmissionData["Budgets"][i]?.LocalID === CurrentProduct?.toLowerCase()?.replaceAll(" ", "")) return ContractsubmissionData["Budgets"][i]?.monthly_budget })?.filter(CurrentBudget => CurrentBudget !== undefined) : ""}
                onBlur={(e) => {
                  ContractsubmissionData?.Budgets && Object.keys(ContractsubmissionData?.Budgets)?.length ?
                    Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                      if (ContractsubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                        let ContractsubmissionDataClone = Object.assign({}, ContractsubmissionData);
                        ContractsubmissionDataClone.Budgets[i] = Object.assign({}, {
                          "product_name": ContractsubmissionData?.Budgets[i]?.product_name, "LocalID": ContractsubmissionData?.Budgets[i]?.LocalID, "monthly_budget": ContractsubmissionData["Budgets"][i]?.monthly_budget?.length ? parseFloat(ContractsubmissionData["Budgets"][i]?.monthly_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          }) : "",
                          "budget_cycles_product_id": ContractsubmissionData?.Budgets[i]?.budget_cycles_product_id, contract_id: ContractsubmissionData?.Budgets[i]?.contract_id, product_id: ContractsubmissionData?.Budgets[i]?.product_id
                        })
                        setContractsubmissionData({
                          ...ContractsubmissionDataClone
                        });
                      }
                    })
                    : ""
                }
                }
                onChange={updateContractFieldHandler(`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`)}
                onFocus={(e) => {
                  ContractsubmissionData?.Budgets && Object.keys(ContractsubmissionData?.Budgets)?.length ?
                    Object.keys(ContractsubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                      if (ContractsubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                        let ContractsubmissionDataClone = Object.assign({}, ContractsubmissionData);
                        ContractsubmissionDataClone.Budgets[i] = Object.assign({}, { "product_name": ContractsubmissionData?.Budgets[i]?.product_name, "LocalID": ContractsubmissionData?.Budgets[i]?.LocalID, "monthly_budget": ContractsubmissionData["Budgets"][i]?.monthly_budget?.replace(/[^0-9.]/g, ""), "budget_cycles_product_id": ContractsubmissionData?.Budgets[i]?.budget_cycles_product_id, contract_id: ContractsubmissionData?.Budgets[i]?.contract_id, product_id: ContractsubmissionData?.Budgets[i]?.product_id })
                        setContractsubmissionData({
                          ...ContractsubmissionDataClone
                        });
                      }
                    })
                    : ""
                }
                }
                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
              />
            </div>
          )
        })
        : ""
      }
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {uploadPercentage && (
        <LinearDeterminate uploadPercentage={uploadPercentage} />
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ textAlign: "left", width: "150px", float: "left" }}>Upload Contract:</label>
        <div style={{ width: "100%", height: "40px !important", float: "left" }}>
          <UploadInput
            path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
            onChange={value => updateContractFieldHandler("contract_url")({ target: { value } })}
            value={ContractsubmissionData["contract_url"] || ""}
            label={ContractsubmissionData["contract_url"] ? "View" : "Upload"}
            setuploadPercentage={setuploadPercentage}
            accept={customer_id === 38 ? {"application/pdf": [".pdf"]} : "*"}
          />
        </div>
      </div>
      {customer_id !== 38 && customer_id !== 165 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Contract Value:</label>
            <input
              id="contract_value"
              maxLength={15}
              type="text"
              value={ContractsubmissionData?.contract_value}
              onBlur={() => FormatCurrency(ContractsubmissionData?.contract_value)}
              onFocus={() => {
                setContractsubmissionData({
                  ...ContractsubmissionData,
                  ["contract_value"]: ContractsubmissionData?.contract_value?.replace(/[^0-9.]/g, "")
                })
              }}
              onChange={updateContractFieldHandler("contract_value")}
              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
            /></div>
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Contract Start Date:</label>
        <TextField
          id="contract_start_date"
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={ContractsubmissionData?.contract_start_date?.indexOf("-") === -1 ? moment(parseInt(ContractsubmissionData?.contract_start_date)).format("YYYY-MM-DDTHH:mm") : ContractsubmissionData?.contract_start_date ? ContractsubmissionData?.contract_start_date : ""}
          onChange={
            updateContractFieldHandler("contract_start_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "150px" }}>Contract End Date:</label>
        <TextField
          id="contract_end_date"
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={ContractsubmissionData?.contract_end_date?.indexOf("-") === -1 ? moment(parseInt(ContractsubmissionData?.contract_end_date)).format("YYYY-MM-DDTHH:mm") : ContractsubmissionData?.contract_end_date ? ContractsubmissionData?.contract_end_date : ""}
          onChange={
            updateContractFieldHandler("contract_end_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginBottom: "20px" }}></div>
      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Contract Status:</label>
      <Select
        selected={typeof ContractsubmissionData?.contract_status === 'string' ? ContractStatuses?.map(({ label, value }) => { if (label === ContractsubmissionData?.contract_status) return value })?.filter(CurrentStatus => CurrentStatus !== undefined) : ContractsubmissionData?.contract_status ? ContractsubmissionData?.contract_status : 0}
        onSelect={updateContractFieldHandler("contract_status")}
        options={ContractStatuses?.map(({ label, value }) => ({
          key: value,
          label: label
        }))}
        style={{ marginBottom: 20, float: "left", height: "40px" }}
      />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {window.location.href.indexOf("crmpersondetail") === -1 && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", width: "125px", float: "left" }}>People:</label>
            {Update === true ?
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Contract")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Contract")
                    }
                  }}
                  value={CRMPeople ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === ContractsubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: ContractsubmissionData?.people_name ? ContractsubmissionData?.people_name : "" } :
                  CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === ContractsubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: ContractsubmissionData?.people_name ? ContractsubmissionData?.people_name : "" }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0 }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Contract")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
              :
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Contract")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Contract")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0 }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Contract")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
            }
            {PersonFound ? "" :
              <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
          </div>
        </>
      )}
      {CRMJob?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Job:</label>
          <Select
            options={CRMJob?.map(({ id, job_name }) => ({
              key: id,
              label: job_name
            }))}
            selected={ContractsubmissionData?.job_id || 0}
            onSelect={updateContractFieldHandler("job_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMPhotoGallery?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Gallery:</label>
          <Select
            options={CRMPhotoGallery?.map(({ id, photo_gallery_name }) => ({
              key: id,
              label: photo_gallery_name
            }))}
            selected={ContractsubmissionData?.photo_gallery_id || 0}
            onSelect={updateContractFieldHandler("photo_gallery_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div>
        <label style={{ marginTop: "8px", width: "125px", float: "left" }}>Assign To:</label>
        <Select
          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
            key: id,
            label: first_name + " " + last_name
          }))}
          selected={ContractsubmissionData?.assigned_to || ""}
          onSelect={updateContractFieldHandler("assigned_to")}
          style={{ marginBottom: 20, height: "40px", float: "left" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.contract_id === ContractsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.contract_id === ContractsubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.contract_id === ContractsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.contract_id === ContractsubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px", width: "100%" }} /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateContractDatabase();
      }}>
        Save
      </Button>
      <div style={{ clear: "both", marginTop: "30px" }}></div>
      {(customer_id === 38 || customer_id === 165) && 
      (ContractsubmissionData?.contract_status === "Signed" || ContractsubmissionData?.contract_status === 20) && 
      ContractsubmissionData?.id && ContractsubmissionData?.crm_customers_id && OnboardingID && (
        <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
          updateContractDatabase();
          
          window.location = "/admin/onboardingform?customer=" + customer_id + "&id=" + ContractsubmissionData?.crm_customers_id + "&peopleid=" + ContractsubmissionData?.people_id + "&contractid=" + ContractsubmissionData?.id + "&onboarding_id=" + OnboardingID
        }}>
          Send OnBoarding
        </Button>
      )}
    </div>
  )
};

export default AddUpdateContract;
