import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_BING_CAMPAIGNS_SUMMARY } from "queries/bing";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const BingCampaignSummary = () => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const { loading, data } = useQuery(GET_BING_CAMPAIGNS_SUMMARY, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const { loading: loadingCompare, data: dataCompare } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip: !state.customers?.selected?.id || !compareDateRange,
      variables: {
        customer_id: state.customers?.selected?.id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  return (
    <GridContainer>
      <StatsCard
        icon="attach_money"
        loading={loading}
        label="Total Spent"
        value={addMargin(data?.getBingCampaignsSummary?.cost || 0, state)}
        render="currency"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin(dataCompare?.getBingCampaignsSummary?.cost || 0, state)
            : ""
        }
        previous
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Impressions"
        value={data?.getBingCampaignsSummary?.impressions || 0}
        render="integer"
        help="Impressions are the number of times that your advertisement has been displayed."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? dataCompare?.getBingCampaignsSummary?.impressions || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Clicks"
        value={data?.getBingCampaignsSummary?.clicks || 0}
        render="integer"
        help="Clicks are the count of the number of times users have clicked on your advertisement."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? dataCompare?.getBingCampaignsSummary?.clicks || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="scatter_plot"
        loading={loading}
        label="CTR"
        value={
          (data?.getBingCampaignsSummary?.clicks || 0) /
          (data?.getBingCampaignsSummary?.impressions || 1) /
          0.01
        }
        render="percentage"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? (dataCompare?.getBingCampaignsSummary?.clicks || 0) /
              (dataCompare?.getBingCampaignsSummary?.impressions || 1) /
              0.01
            : ""
        }
        previous
        milestones={[
          {
            value: 1,
            color: "info",
            icon: "fitness_center",
            message: `Not met "Working on it.."`
          },
          {
            value: 3,
            color: "orange",
            icon: "sentiment_satisfied",
            message: "Average"
          },
          {
            color: "wbmMoney",
            icon: "sentiment_very_satisfied",
            message: "Above Average"
          }
        ]}
        help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
      />
      <StatsCard
        icon="image_aspect_ratio"
        loading={loading}
        label="CPC"
        value={addMargin(
          (data?.getBingCampaignsSummary?.cost || 0) /
            (data?.getBingCampaignsSummary?.clicks || 1),
          state
        )}
        render="currency"
        help="CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin(
                (dataCompare?.getBingCampaignsSummary?.cost || 0) /
                  (dataCompare?.getBingCampaignsSummary?.clicks || 1),
                state
              )
            : ""
        }
        previous
      />
      <StatsCard
        icon="compass_calibration"
        loading={loading}
        label="Web Events"
        value={data?.getBingCampaignsSummary?.conversions || 0}
        render="integer"
        help="This is the count of an action when someone interacts with your ad and then takes an action that you've defined as valuable to your business, such as a webpage visit."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? dataCompare?.getBingCampaignsSummary?.conversions || 0
            : ""
        }
        previous
      />
    </GridContainer>
  );
};

export default BingCampaignSummary;
