import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MailOutline from "@mui/icons-material/MailOutline";

import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { READ_ME } from "queries/users";
import {
  CREATE_CARD,
  REMOVE_CARD,
  LIST_CARDS,
  CREATE_BANK,
  REMOVE_BANK,
  LIST_BANKS
} from "queries/quickbooks";
import { SET_DEFAULT_PAYMENT_ID, GET_DEFAULT_PAYMENT_ID } from "queries/users";
import { Context } from "../../../redux/store";
import myTeamStyle from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

import BankAccountForm from "./BankAccountForm";
import CreditCardForm from "./CreditCardForm";
import NavItem from "./NavItem";

const styles = {
  ...myTeamStyle,
  cardName: {
    display: "flex",
    alignItems: "center",
    "& > svg": {
      marginRight: 10
    }
  },
  defaultButtonWrapper: {
    position: "absolute",
    right: 10,
    top: 5
  }
};

const useStyles = makeStyles(styles);

const Payment = () => {
  const [state] = useContext(Context);
  const [paymentId, setPaymentId] = useState();
  const [method, setMethod] = useState("credit-card");
  const [cards, setCards] = useState([]);
  const [banks, setBanks] = useState([]);
  const [error, setError] = useState("");
  const [paymentInfo, setPaymentInfo] = useState({});
  const [termsPopupOpen, setTermsPopupOpen] = useState(false);
  const classes = useStyles();
  const { data: me } = useQuery(READ_ME);
  const quick_books_id =
    me?.readMe?.user_level === "ad_consultant" || me?.readMe?.user_level === "master"
      ? Number(state?.customers?.selected?.quick_books_id || 0)
      : Number(me?.readMe?.quick_books_id || 0);
  const [defaultId, setDefaultId] = useState(
    me?.readMe?.default_payment_id || ""
  );
  let { data } = useQuery(GET_DEFAULT_PAYMENT_ID, {
    skip: !state?.customers?.selected?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: state?.customers?.selected?.id
    }
  });
  useEffect(() => {
    if (me?.readMe?.customer_id === state?.customers?.selected?.id)
      setDefaultId(me?.readMe?.default_payment_id);
    else
      setDefaultId(data?.getDefaultPaymentId);
  }, [me, state, data]);
  let [createCard, { loading: creatingCard }] = useMutation(CREATE_CARD, {
    skip: !quick_books_id,
    onCompleted: data => {
      if (data?.createCard?.errors?.length) {
        setError(data?.createCard?.errors[0]);
      } else {
        setCards([...cards, data?.createCard]);
      }
    }
  });
  let [createBank, { loading: creatingBank }] = useMutation(CREATE_BANK, {
    skip: !quick_books_id,
    onCompleted: data => {
      if (data?.createBank?.errors?.length) {
        setError(data?.createBank?.errors[0]);
      } else {
        setBanks([...banks, data?.createBank]);
      }
    }
  });
  const [
    setDefaultPaymentId,
    { loading: settingDefaultPaymentId }
  ] = useMutation(SET_DEFAULT_PAYMENT_ID, {
    onCompleted: ({ setDefaultPaymentId }) => {
      setDefaultId(setDefaultPaymentId);
    }
  });

  const { loading: listingCard } = useQuery(LIST_CARDS, {
    skip: !quick_books_id,
    variables: {
      quick_books_id
    },
    onCompleted: data => {
      setCards(data?.listCards || []);
    }
  });
  const { loading: listingBank } = useQuery(LIST_BANKS, {
    skip: !quick_books_id,
    variables: {
      quick_books_id
    },
    onCompleted: data => {
      setBanks(data?.listBanks || []);
    }
  });
  const [removeCard, { loading: removingCard }] = useMutation(REMOVE_CARD, {
    skip: !quick_books_id
  });
  const [removeBank, { loading: removingBank }] = useMutation(REMOVE_BANK, {
    skip: !quick_books_id
  });
  const addCard = () => {
    setError("");
    createCard({
      variables: {
        quick_books_id,
        card_info: paymentInfo
      }
    });
  };
  const addBank = () => {
    setError("");
    createBank({
      variables: {
        quick_books_id,
        bank_info: paymentInfo
      }
    });
  };
  const deleteCard = () => {
    removeCard({
      variables: {
        quick_books_id,
        payment_id: paymentId
      },
      onCompleted: () => {
        setCards(cards?.filter(({ id }) => id !== paymentId));
      }
    });
  };
  const deleteBank = () => {
    removeBank({
      variables: {
        quick_books_id,
        payment_id: paymentId
      },
      onCompleted: () => {
        setBanks(banks?.filter(({ id }) => id !== paymentId));
      }
    });
  };
  if (!quick_books_id) {
    return null;
  }
  const loading =
    creatingCard ||
    removingCard ||
    listingCard ||
    creatingBank ||
    removingBank ||
    listingBank ||
    settingDefaultPaymentId;
  const removable = !loading && (cards.length || 0) + (banks.length || 0) > 1;
  const renderButton = (type, isDefault, id) => (
    <Button
      size="sm"
      color={isDefault ? "primary" : "white"}
      onClick={() => {
        if (!isDefault)
          setDefaultPaymentId({
            variables: {
              default_payment_id: `${type}-${id}`,
              customer_id: state?.customers?.selected?.id
            }
          });
      }}
    >
      {isDefault ? "Default" : "Set as Default"}
    </Button>
  );
  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>Payment Methods</h2>
      <h4>{`Payment detail description`}</h4>
      <GridContainer loading={loading}>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={6}>
                {banks.map(bankInfo => (
                  <NavItem
                    active={paymentId === bankInfo.id}
                    onClick={() => {
                      setMethod("bank-account");
                      setPaymentId(bankInfo.id);
                      setPaymentInfo(bankInfo);
                      setError("");
                    }}
                    key={bankInfo.id}
                    readOnly
                    style={{ position: "relative" }}
                  >
                    <div className={classes.cardName}>
                      <AccountBalanceIcon /> {bankInfo.name}
                    </div>
                    <div className={classes.cardNumber}>
                      {bankInfo.accountNumber}
                    </div>
                    <div className={classes.defaultButtonWrapper}>
                      {renderButton(
                        "bank",
                        defaultId === `bank-${bankInfo.id}`,
                        bankInfo.id
                      )}
                    </div>
                  </NavItem>
                ))}
                {cards.map(cardInfo => (
                  <NavItem
                    active={paymentId === cardInfo.id}
                    onClick={() => {
                      setMethod("credit-card");
                      setPaymentId(cardInfo.id);
                      setPaymentInfo(cardInfo);
                      setError("");
                    }}
                    key={cardInfo.id}
                    readOnly
                    style={{ position: "relative" }}
                  >
                    <div className={classes.cardName}>
                      <CreditCardIcon /> {cardInfo.name}
                    </div>
                    <div className={classes.cardNumber}>{cardInfo.number}</div>
                    <div className={classes.defaultButtonWrapper}>
                      {renderButton(
                        "card",
                        defaultId === `card-${cardInfo.id}`,
                        cardInfo.id
                      )}
                    </div>
                  </NavItem>
                ))}
                <NavItem
                  active={paymentId === 0}
                  onClick={() => {
                    setMethod("credit-card");
                    setPaymentId(0);
                    setPaymentInfo({});
                    setError("");
                  }}
                >
                  Add +
                </NavItem>
              </GridItem>
              <GridItem xs={12} md={6}>
                {paymentId === 0 && (
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="method"
                      name="method"
                      value={method}
                      onChange={({ target: { value } }) => {
                        setMethod(value);
                        setPaymentInfo({});
                      }}
                    >
                      <FormControlLabel
                        value="credit-card"
                        control={<Radio color="primary" />}
                        label="Credit Card"
                      />
                      <FormControlLabel
                        value="bank-account"
                        control={<Radio color="primary" />}
                        label="Bank Account"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
                {paymentId !== undefined && (
                  <>
                    {method === "credit-card" && (
                      <CreditCardForm
                        info={paymentInfo}
                        onUpdate={setPaymentInfo}
                        readOnly={paymentId !== 0}
                        error={error?.detail}
                        key={paymentId}
                      />
                    )}
                    {method === "bank-account" && (
                      <BankAccountForm
                        info={paymentInfo}
                        onUpdate={setPaymentInfo}
                        readOnly={paymentId !== 0}
                        error={error?.detail}
                        key={paymentId}
                      />
                    )}
                    {error?.moreInfo}
                    <div>
                      By clicking submit I agree to the{" "}
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setTermsPopupOpen(true);
                        }}
                      >
                        Payment Terms and Conditions
                      </a>
                    </div>
                    <Dialog
                      open={Boolean(termsPopupOpen)}
                      onClose={() => setTermsPopupOpen(false)}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        Payment Terms and Conditions
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          {`By clicking submit, you represent that you have the authority to bind the payor, and hereby authorize Water Bear Marketing to charge the Default credit card (the Card) or Bank Account for the amounts owed on all Order Forms to which the above-referenced Client is a party, consistent with the Order Form and the Online Marketing Services Terms & Conditions. Should charges be declined, Water Bear Marketing will promptly re-charge the Card or Bank Account for the amount due.`}
                          <br />
                          <br />
                          {`If the services you purchased require recurring payments, the Card or Bank Account will be used for all such on-going charges. In addition, in some cases Water Bear Marketing is provided updated card information from card issuers, including regarding canceled accounts or changes to card numbers or expiration dates. Water Bear marketing will apply any updated information to your account to avoid service disruptions.`}
                          <br />
                          <br />
                          {`If you would like Water Bear Marketing not to update your account information this way or would like to use another payment method at any time, please contact your Water Bear Marketing representative.`}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => setTermsPopupOpen(false)}
                          color="primary"
                          autoFocus
                        >
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <br />
                    {paymentId === 0 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          if (method === "credit-card") {
                            addCard();
                          } else {
                            addBank();
                          }
                        }}
                      >
                        Submit
                      </Button>
                    )}
                    {paymentId !== 0 && removable && (
                      <Button
                        color="secondary"
                        onClick={() => {
                          if (method === "credit-card") {
                            deleteCard();
                          } else {
                            deleteBank();
                          }
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </>
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default Payment;
