import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { sumBy } from "lodash";
import Dialog from '@mui/material/Dialog';

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import HelpTooltip from "components/HelpTooltip";
import StatsCard from "components/StatsCard";
import ReactTable from "components/CustomReactTable";
import { addMargin } from "utils/margin";

const HydrateSubPage = ({
  classes,
  columns = [],
  state,
  customer_id,
  compareOption,
  dateRangeString,
  compareDateRange,
  gqlKey,
  queryKey,
  cardFields = [],
  TableIcon,
  heading,
  help
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  let { loading, data } = useQuery(gqlKey, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = customer_id && data ? data[queryKey] || [] : [];
  let { loading: loadingCompare, data: compareData } = useQuery(gqlKey, {
    skip: !customer_id || !compareDateRange,
    variables: {
      customer_id,
      start_date: compareDateRange?.startDate,
      end_date: compareDateRange?.endDate
    }
  });
  compareData = customer_id && compareData ? compareData[queryKey] || [] : [];

  const columnsNormalized = columns.map(column => {
    if (column.render !== "image") return column;
    return {
      Header: column.Header,
      accessor: column.accessor,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original[column.accessor] ? (
          "Unknown"
        ) : (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              setImageUrl(original[column.accessor]);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            View
          </a>
        )
    };
  });

  if(columnsNormalized?.length > 0)
  {
    return (
      <GridContainer>      
        {cardFields.map(({ icon, label, name, margin, render }) => {
          const sum = sumBy(data, name);
          const sumCompare = sumBy(compareData, name);
          return (
            <StatsCard
              icon={icon}
              loading={loading}
              label={label}
              value={margin ? addMargin(sum || 0, state) : sum}
              render={render}
              previous
              footer={
                loadingCompare
                  ? "..."
                  : compareOption
                  ? margin
                    ? addMargin(sumCompare || 0, state)
                    : sumCompare
                  : ""
              }
              footerRender
              key={name}
            />
          );
        })}
        
        <GridItem xs={12}>
          <Card>
            <CardHeader color="trackedKeywords" icon>
              <CardIcon>
                <TableIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {heading}
                {help && <HelpTooltip>{help}</HelpTooltip>}
              </h4>
            </CardHeader>
            
            <CardBody>
              <ReactTable
                key={data?.length}
                data={data}
                filterable
                loading={loading}
                columns={columnsNormalized}
                defaultPageSize={Math.min(20, data?.length || 2)}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
            
          </Card>
        </GridItem>
        <Dialog
          open={Boolean(imageUrl)}
          onClose={() => setImageUrl(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paperScrollPaper: classes.paperScrollPaper
          }}
        >
          <img src={imageUrl} alt="" />
        </Dialog>
      </GridContainer>
    );
  }else
    return (
      <GridContainer>      
        {cardFields.map(({ icon, label, name, margin, render }) => {
          let sum = sumBy(data, name);
          if(render === "currency")
          {
            sum = sum * .001;
            if(sum > 0 && sum < .01)
              sum = .01;
          }
          const sumCompare = sumBy(compareData, name);
          return (
            <StatsCard
              icon={icon}
              loading={loading}
              label={label}
              value={margin ? addMargin(sum || 0, state) : sum}
              render={render}
              previous
              footer={
                loadingCompare
                  ? "..."
                  : compareOption
                  ? margin
                    ? addMargin(sumCompare || 0, state)
                    : sumCompare
                  : ""
              }
              footerRender
              key={name}
            />
          );
        })}
        
        <Dialog
          open={Boolean(imageUrl)}
          onClose={() => setImageUrl(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paperScrollPaper: classes.paperScrollPaper
          }}
        >
          <img src={imageUrl} alt="" />
        </Dialog>
      </GridContainer>
    )
};

export default HydrateSubPage;
