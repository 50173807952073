import React, { useContext } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Icon from "@mui/material/Icon";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import { LIST_GOOGLE_ADS_GROUPS } from "queries/google";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import { READ_ME } from "queries/users";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const GoogleAdsGroups = () => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const { data: me } = useQuery(READ_ME);
  let { loading, data } = useQuery(LIST_GOOGLE_ADS_GROUPS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const classes = useStyles();

  data = data?.listGoogleAdsGroups || [];

  data = data.map((row) => ({    
    ...row,
    ctr: Number(row.impressions) !== 0 ? Number(((row.clicks / row.impressions) * 100).toFixed(2)): 0
  }));

  let columns = ""

  if(me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
     me?.readMe?.user_level === "content_manager" || me?.readMe?.user_level === "campaign_consultant" ||
     me?.readMe?.user_level === "ad_consultant")
  {
    columns = [
      {
        Header: "Name",
        accessor: "ad_group_name"
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(addMargin((original?.cost || 0) / 1000000, state))
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US").format(original?.impressions || 0)
      },
      {
        Header: "Clicks",
        accessor: "clicks",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US").format(original?.clicks || 0)
      },
      {
        Header: "CTR",
        accessor: "ctr",
        Cell: ({ original }) =>
          original?.ctr + "%"
      },
      {
        Header: "CPC",
        accessor: "avg_cpc",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(
            addMargin(
              (original?.cost || 0) / (original?.clicks || 0) / 1000000,
              state
            )
          )
      },
      {
        Header: "Top Imp Share",
        accessor: "top_impression_percentage",
        Cell: ({ original }) =>
        `${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(
          (original?.top_impression_percentage)
        )}%`
      },
      {
        Header: "Abs Top Imp Share",
        accessor: "absolute_top_impression_percentage",
        Cell: ({ original }) =>
        `${new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }).format(
          (original?.absolute_top_impression_percentage)
        )}%`
      }

    ];
  }else
  {
    columns = [
      {
        Header: "Name",
        accessor: "ad_group_name"
      },
      {
        Header: "Cost",
        accessor: "cost",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(addMargin((original?.cost || 0) / 1000000, state))
      },
      {
        Header: "Impressions",
        accessor: "impressions",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US").format(original?.impressions || 0)
      },
      {
        Header: "Clicks",
        accessor: "clicks",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US").format(original?.clicks || 0)
      },
      {
        Header: "CTR",
        accessor: "ctr",
        Cell: ({ original }) =>
          original?.ctr + "%"
      },
      {
        Header: "CPC",
        accessor: "avg_cpc",
        Cell: ({ original }) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD"
          }).format(
            addMargin(
              (original?.cost || 0) / (original?.clicks || 0) / 1000000,
              state
            )
          )
      }
    ];
}
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="google" icon>
            <CardIcon>
              <Icon>list</Icon>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Google Ad Group Breakdown
              <HelpTooltip>
                {`An ad group contains one or more ads that share similar targets. Ad groups are used to organize your ads by a common theme.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Google Ads Group (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(10, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default GoogleAdsGroups;
