import React, { useState } from "react";
import { useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import { SUBMIT_REFER_FRIEND } from "queries/formSubmission";
import withSubmissions from "hocs/withSubmissions";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const ReferFriend = ({ formSubmitted }) => {
  const classes = useStyles();
  const [submissionData, setSubmissionData] = useState({});

  const [submitReferFriend, { loading: submitting }] = useMutation(
    SUBMIT_REFER_FRIEND,
    {
      variables: {
        data: {
          ...submissionData,
          comments: submissionData.comments?.split("\n").join("<br>")
        }
      },
      onCompleted: () => {
        setSubmissionData({});
        formSubmitted();
      }
    }
  );

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>Sharing is Caring…</h2>
      <h4>
        {`We know it’s hard not to adore us, so refer a friend and share the love. Simply provide their details below and we will take it from there.`}
      </h4>
      <GridContainer loading={submitting}>
        <Card>
          <CardHeader color="myTeam" icon>
            <CardIcon>
              <MailOutline />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <form
              onSubmit={e => {
                e.preventDefault();
                submitReferFriend();
              }}
            >
              <CustomInput
                name="name"
                labelText="Name"
                id="name"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "string",
                  value: submissionData?.name || "",
                  onChange: updateFieldHandler("name")
                }}
              />
              <CustomInput
                name="company_name"
                labelText="Company Name"
                id="company_name"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "string",
                  value: submissionData?.companyName || "",
                  onChange: updateFieldHandler("companyName")
                }}
              />
              <CustomInput
                labelText="Phone Number"
                id="phone_number"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "phone_number",
                  value: submissionData?.phone || "",
                  onChange: updateFieldHandler("phone")
                }}
              />
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                  required: true
                }}
                inputProps={{
                  type: "email",
                  value: submissionData?.email || "",
                  onChange: updateFieldHandler("email")
                }}
              />
              <CustomInput
                labelText="Comments"
                id="comments"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 5,
                  value: submissionData?.comments || "",
                  onChange: updateFieldHandler("comments")
                }}
              />
              <Button color="primary" type="submit">
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(ReferFriend);
