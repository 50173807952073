import moment from "moment";

import {
  SET_CUSTOMERS,
  SELECT_CUSTOMER,
  UPDATE_CUSTOMER_SELECT,
  ADD_CUSTOMER_SELECT,
  SET_DATE_RANGE
} from "../../redux/constants";

const Reducer = (state = {}, action) => {
  let data;
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        data: action.payload || []
      };
    case SELECT_CUSTOMER:
      return {
        ...state,
        selected: state.data?.find(({ id }) => id === action.payload) || {}
      };
    case UPDATE_CUSTOMER_SELECT:
      data = state.data?.map(customer => {
        if (customer.id === action.payload.id) {
          return {
            ...customer,
            ...action.payload
          };
        }
        return customer;
      });
      return {
        ...state,
        data: data,
        selected: data?.find(({ id }) => id === action.payload.id) || {}
      };
    case ADD_CUSTOMER_SELECT:
      data = [
        ...state.data?.filter(customer => customer.id !== action.payload.id),
        action.payload
      ];
      return {
        ...state,
        data: data,
        selected: data?.find(({ id }) => id === action.payload.id) || {}
      };
    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
        dateRangeString: {
          startDate: moment(action.payload.startDate).format("YYYY-MM-DD"),
          endDate: moment(action.payload.endDate).format("YYYY-MM-DD")
        }
      };
    default:
      return state;
  }
};

export default Reducer;
