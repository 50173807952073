import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import BudgetCard from "components/BudgetCard";
import StatsCard from "components/StatsCard";
import { GET_NEURON_CAMPAIGN_SUMMARY } from "queries/neuron";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const AllOutofHomeAdsCampaigns = () => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const neuron_id = state.customers?.selected?.neuron_id;
  const customer_id = state.customers?.selected?.id;
  const { loading, data } = useQuery(GET_NEURON_CAMPAIGN_SUMMARY, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      neuron_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  const summary = data?.getNeuronCampaignSummary;
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const { loading: loadingCompare, data: compareData } = useQuery(
    GET_NEURON_CAMPAIGN_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const compareSummary = compareData?.getNeuronCampaignSummary;
  return (
    <GridContainer>
      <StatsCard
        icon="attach_money"
        loading={loading}
        label="Total Spent"
        value={addMargin(summary?.spent || 0, state)}
        render="currency"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin(compareSummary?.spent || 0, state)
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Playouts"
        value={summary?.playouts || 0}
        render="integer"
        help="Number of times your ad was shown"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? compareSummary?.playouts || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Impressions"
        value={summary?.impressions || 0}
        render="integer"
        help="Estimated Audience Impressions"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? compareSummary?.impressions || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="image_aspect_ratio"
        loading={loading}
        label="eCPM"
        value={addMargin(summary?.eCPM || 0, state)}
        render="currency"
        help="Estimated Cost Per Thousand Impressions"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin(compareSummary?.eCPM || 0, state)
            : ""
        }
        footerRender
      />
      <BudgetCard productLine="out_of_home" title="Out of Home" />
    </GridContainer>
  );
};

export default AllOutofHomeAdsCampaigns;
