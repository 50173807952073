import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
const moment = require("moment");

const TicketList = (row, index, CRMDocument, object_key, CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses) => {
  return CRMDocument?.filter((CurrentTicket) => CurrentTicket[object_key] === row?.id).map(CurrentTicket => {
    return (
      <>
        {isTicketOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isTicketOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Name</b><br /> <a href={"/admin/crmticketdetail?customer=" + customer_id + "&id=" + CurrentTicket?.crm_customers_id + "&peopleid=" + CurrentTicket?.people_id + "&ticketid=" + CurrentTicket?.id}>{CurrentTicket?.name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Date</b><br /> {CurrentTicket?.requested_completion_date !== null ? moment(new Date(CurrentTicket?.requested_completion_date)).utc().format("MM-DD-YYYY"): ""}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Status</b><br /> {CurrentTicket?.status ? CRMTicketStatuses?.map((CurrentStatus) => {if(CurrentStatus.id === CurrentTicket?.status) return CurrentStatus?.status }) : ""}</div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow >
        )}
      </>
    )
  })
};

export default TicketList;
