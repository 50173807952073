import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { pick } from "lodash";
import queryString from "query-string";


import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import { adsType } from "views/Dashboard/CreateBudget";
import { GET_BUDGET, UPDATE_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/modifyMarketingGoalsStyle";

const useStyles = makeStyles(styles);

const UpdateBudget = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [budget, setBudget] = useState({});
  const [product, setProduct] = useState();
  const history = useLocation();
  const { product: query_product } = queryString.parse(history.search);
  const customer_id = state.customers?.selected?.id;
  const disabled = !customer_id || !product;
  let { loading, data } = useQuery(GET_BUDGET, {
    skip: disabled,
    variables: {
      customer_id,
      product,
      last: true
    },
    fetchPolicy: "no-cache"
  });
  const [updateBudget, { loading: updatingBudget }] = useMutation(
    UPDATE_BUDGET,
    {
      variables: {
        data: pick(budget, ["id", "new_monthly_budget"])
      },
      onCompleted: () => {
        formSubmitted();
      }
    }
  );
  const [cancelBudget, { loading: cancelingBudget }] = useMutation(
    UPDATE_BUDGET,
    {
      variables: {
        data: {
          id: budget?.id,
          new_monthly_budget: 0
        }
      },
      onCompleted: () => {
        formSubmitted({
          text: "Budget cancelled successfully"
        });
        setBudget({});
      }
    }
  );
  const classes = useStyles();

  useEffect(() => {
    setBudget(data?.getBudget);
  }, [data]);

  useEffect(() => {
    if (customer_id)
      setProduct(Number(query_product));
  }, [customer_id, query_product]);

  const updateFieldHandler = (key, convert) => e => {
    setBudget({
      ...budget,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <div className={classes.container} key={budget?.id}>
      <h2 className={classes.pageHeader}>Update Monthly Budget</h2>
      <GridContainer justify="center" loading={loading || updatingBudget || cancelingBudget}>
        <GridItem xs={12} sm={6} md={5} lg={12}>
          <GridContainer>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => e.preventDefault()}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <label>Product</label>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      value={product || "Choose Product"}
                      onChange={e => setProduct(e.target.value)}
                      inputProps={{
                        name: "product",
                        id: "product"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value="Choose Product"
                      >
                        Choose Product{" "}
                      </MenuItem>
                      {adsType.map(({ label, value }) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={value}
                          key={value}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CustomInput
                    labelText="Current Budget"
                    id="monthly_budget"
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                      readOnly: true
                    }}
                    inputProps={{
                      type: "integer",
                      defaultValue: budget?.monthly_budget,
                      readOnly: true
                    }}
                  />
                  <CustomInput
                    labelText="New Monthly Budget"
                    id="new_monthly_budget"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "integer",
                      defaultValue: budget?.new_monthly_budget,
                      onChange: updateFieldHandler(
                        "new_monthly_budget",
                        parseFloat
                      )
                    }}
                  />
                </form>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    updateBudget();
                  }}
                  disabled={disabled || !budget?.monthly_budget}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="submit"
                  onClick={() => {
                    cancelBudget();
                  }}
                  disabled={disabled || !budget?.monthly_budget}
                >
                  Cancel Budget
                </Button>
              </CardBody>
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(UpdateBudget);
