import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import Checkbox from '@mui/material/Checkbox';
import Input from "@mui/material/Input";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Check from "@mui/icons-material/Check";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_BING_SEARCH_QUERIES } from "queries/bing";
import { SUBMIT_KEYWORDS_REMOVAL_REQUEST } from "queries/formSubmission";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = {
  ...formStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  checkRoot: {
    padding: 0
  }
};

const useStyles = makeStyles(styles);

const TopKeywordsBing = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [checks, setChecks] = useState({});
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const detailed =
    ["master", "ad_consultant", "campaign_consultant", "content_manager", "paid_ad_manager"].indexOf(user_level) !==
    -1;
  const customer_id = state.customers?.selected?.id;
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data } = useQuery(LIST_BING_SEARCH_QUERIES, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      detailed
    },
    onCompleted: () => {
      setChecks({});
    }
  });

  data = data?.listBingSearchQueries || [];

  const toggleChecked = key => {
    setChecks({
      ...checks,
      [key]: !checks[key]
    });
  };

  const [submitKeywordsRemovalRequest, { loading: removing }] = useMutation(
    SUBMIT_KEYWORDS_REMOVAL_REQUEST,
    {
      variables: {
        customerId: customer_id,
        data: {
          tableName: "Bing Campaign Keywords",
          keywords: data
            ?.filter(({ search_query }) => checks[search_query])
            .map(({ search_query }) => search_query)
            .join("<br>"),
          customerName: state.customers?.selected?.name,
          note
        }
      },
      onCompleted: () => {
        setChecks({});
        setOpen(false);
        formSubmitted();
      }
    }
  );

  const handleOpen = () => {
    setNote("");
    setOpen(true);
  };

  const handleClose = (accept = false) => () => {
    if (accept) {
      submitKeywordsRemovalRequest();
    } else {
      setOpen(false);
    }
  };

  const classes = useStyles();
  const searchQueryColumn = {
    Header: "Search Query",
    accessor: "search_query",
    // eslint-disable-next-line react/display-name,react/prop-types
    Cell: ({ original }) => {
      return (
        <span
          style={{
            fontWeight: checks[(original?.search_query)]
              ? "bold"
              : "regular"
          }}
          key={checks[(original?.search_query)]}
        >
          {original?.search_query}
        </span>
      );
    },
    renderCSV: ({ original }) => original?.search_query
  };
  const detailedColumns = detailed
    ? [
        {
          Header: "Campaign Name",
          accessor: "campaign_name"
        },
        {
          Header: "Ad Group Name",
          accessor: "ad_group_name"
        },
        searchQueryColumn,
        {
          Header: "Impressions",
          accessor: "impressions"
        },
        {
          Header: "Clicks",
          accessor: "clicks"
        },
        {
          Header: "CTR",
          accessor: "ctr",
          // eslint-disable-next-line react/display-name,react/prop-types
          Cell: ({ original }) => {
            return Number(original.impressions) === 0
              ? 0
              : `${(((original.clicks || 0) / (original.impressions || 1)) * 100).toFixed(
                  1
                )}%`;
          }
        }
      ]
    : [searchQueryColumn];
  const columns = [
    ...detailedColumns,
    {
      Header: "",
      accessor: "check",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <Checkbox
          onClick={() => toggleChecked(original?.search_query)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
          checked={checks[(original?.search_query)]}
          key={checks[(original?.search_query)]}
        />
      ),
      noCSV: true
    }
  ];
  const checkLength = Object.keys(checks).length;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Search Terms
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Bing Top Keywords (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading || removing}
              columns={columns}
              defaultPageSize={Math.min(50, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            {open && (
              <Dialog
                open={open}
                onClose={handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">
                  Keywords Removal
                </DialogTitle>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleClose(true)();
                  }}
                >
                  <DialogContent>
                    <ReactTable
                      key={data?.length}
                      data={data?.filter(
                        ({ search_query }) => checks[search_query]
                      )}
                      loading={loading || removing}
                      columns={columns.slice(0, 3)}
                      defaultPageSize={5}
                      className="-striped -highlight"
                    />
                    <br />
                    <br />
                    <h4>Note:</h4>
                    <Input
                      name="note"
                      id={`input-note`}
                      fullWidth
                      required
                      inputProps={{
                        type: "string",
                        value: note || "",
                        onChange: e => setNote(e.target.value)
                      }}
                    />
                    <br />
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" autoFocus type="submit">
                      Submit
                    </Button>
                    <Button onClick={handleClose()}>Cancel</Button>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </CardBody>
          <CardFooter>
            <Button
              onClick={handleOpen}
              color="orange"
              disabled={!checkLength || removing}
              style={{ margin: "auto" }}
            >
              Request Removal({checkLength})
            </Button>
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(TopKeywordsBing);
