import gql from "graphql-tag";

export const GET_ORGANIC_SEARCH = gql`
  query getOrganicSearch($customer_id: Int!) {
    getOrganicSearch(customer_id: $customer_id) {
      id
      location_name
      organic_search_dashboard_link
      primary
    }
  }
`;

export const SET_ORGANIC_SEARCH_PRIMARY = gql`
  mutation($customer_id: Int!, $id: Int!) {
    setOrganicSearchPrimary(customer_id: $customer_id, id: $id) {
      id
      location_name
      organic_search_dashboard_link
      primary
    }
  }
`;
