import React, { useState } from "react";
import { trim } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

let timeout = null;

const TrackingCodeMaker = () => {
  const [trackingInfo, setTrackingInfo] = useState({
    url: "",
    source: "Google",
    medium: "paid",
    campaign: ""
  });
  const [clipboardCopied, setClipboardCopied] = useState();
  const classes = useStyles();
  // const user_level = state?.auth?.tokenInfo?.user?.user_level;

  const updateFieldHandler = (key, convert) => e => {
    setTrackingInfo({
      ...trackingInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const copyCode = id => {
    var copyText = document.getElementById(id);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    setClipboardCopied(id);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setClipboardCopied("");
    }, 1500);
  };

  const copyTrackingCode = () => copyCode("tracking_code");

  const trackingCode = `${trim(trackingInfo?.url || "", "/")}/?utm_source=${
    trackingInfo?.source
  }&utm_medium=${trackingInfo?.medium}&utm_campaign=${trackingInfo?.campaign}`;

  const readOnly = true;

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>Tracking Codes</h2>
      <form>
        <GridContainer justify="flex-end">
          <GridItem xs={12}>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Url"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: trackingInfo?.url,
                    onChange: updateFieldHandler("url")
                  }}
                />
                <CustomInput
                  labelText="Source"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: trackingInfo?.source,
                    onChange: updateFieldHandler("source")
                  }}
                />
                <CustomInput
                  labelText="Medium"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: trackingInfo?.medium,
                    onChange: updateFieldHandler("medium")
                  }}
                />
                <CustomInput
                  labelText="Campaign"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: trackingInfo?.campaign,
                    onChange: updateFieldHandler("campaign")
                  }}
                />
                <CustomInput
                  labelText="Tracking Code"
                  id="tracking_code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 2,
                    value: trackingCode || "",
                    onClick: copyTrackingCode,
                    readOnly
                  }}
                />
              </CardBody>
            </Card>
            {readOnly && (
              <>
                <Button
                  color="primary"
                  type="button"
                  onClick={copyTrackingCode}
                >
                  Copy
                </Button>
                {clipboardCopied === "tag_manger_head_code" && (
                  <div>Copied To Clipboard</div>
                )}
              </>
            )}
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};

export default TrackingCodeMaker;
