import React from "react";

import GridContainer from "components/Grid/GridContainer";
import BudgetCard from "components/BudgetCard";

const AllBudgets = () => {
  return (
    <GridContainer>
      <BudgetCard productLine="paid_search" title="PaidSearch" optional />
      <BudgetCard
        productLine="local_services_ads"
        title="Local Services Ads"
        optional
      />
      <BudgetCard productLine="organic_search" title="OrganicSearch" optional />
      <BudgetCard
        productLine="banner_ads_geo_demo"
        title="BannerAds | GeoDemo®"
        optional
      />
      <BudgetCard
        productLine="banner_ads_geo_intention"
        title="BannerAds | GeoIntention®"
        optional
      />
      <BudgetCard
        productLine="banner_ads_reengage"
        title="BannerAds | ReEngage®"
        optional
      />
      <BudgetCard
        productLine="social_media_ads_geo_demo"
        title="SocialAds | GeoDemo®"
        optional
      />
      <BudgetCard
        productLine="social_media_ads_boosted_posts"
        title="SocialAds | Boosted Posts"
        optional
      />
      <BudgetCard
        productLine="social_media_ads_reengage"
        title="Social Ads | ReEngage®"
        optional
      />
      <BudgetCard
        productLine="video_ads_geo_demo"
        title="OnlineVideo | GeoDemo®"
        optional
      />
      <BudgetCard
        productLine="video_ads_geo_intention"
        title="OnlineVideo | GeoIntention®"
        optional
      />
      <BudgetCard
        productLine="video_ads_reengage"
        title="OnlineVideo | ReEngage®"
        optional
      />
      <BudgetCard productLine="out_of_home" title="Out of Home" optional />
      <BudgetCard
        productLine="offline_tracking"
        title="OfflineTracking"
        optional
      />
      <BudgetCard productLine="hosting" title="Hosting" optional />
      <BudgetCard productLine="hydrate" title="Hydrate" optional />
    </GridContainer>
  );
};

export default AllBudgets;
