import React, { useState } from "react";
import PropTypes from "prop-types";


import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Select from "components/Select/Select";

import TrackedLeadsSummary from "../TrackedLeadsSummary";
import CallRecording from "./CallRecording";
import FormSubmissions from "./FormSubmissions";

const useStyles = makeStyles(styles);

const filterOptions = ["Radio", "Newspaper", "TV", "Billboard", "Offline"];

export default function TrackedLeads({ offline }) {
  const classes = useStyles();
  const [filter, setFilter] = useState("Radio");
  return (
    <div>
      {offline && (
        <>
          <span>Source:</span>
          <Select
            options={filterOptions.map(option => ({
              key: option,
              label: option
            }))}
            selected={filter}
            onSelect={value => {
              setFilter(value);
            }}
            style={{ marginLeft: 30, marginBottom: 20 }}
          />
        </>
      )}
      <h2 className={classes.pageHeader}>Tracked Conversions</h2>
      <TrackedLeadsSummary offline={offline} filter={filter} />
      <h2 className={classes.pageHeader}>Tracked Conversions Details</h2>
      <CallRecording offline={offline} filter={filter} />
      {!offline && <FormSubmissions offline={offline} />}
    </div>
  );
}

TrackedLeads.propTypes = {
  offline: PropTypes.bool
};
