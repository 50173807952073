import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { orderBy } from "lodash";

import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import ListItemIcon from '@mui/material/ListItemIcon';

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import { LIST_ACCOUNTS, LIST_ALL_ACCOUNTS } from "queries/customers";

const useStyles = makeStyles(styles);

const CustomSelect = ({
  label,
  name,
  value,
  onChange,
  data,
  required,
  validation,
  dataSource,
  defaultValue = 0,
  sort,
  onLoaded,
  readOnly,
  deleteable
}) => {
  const classes = useStyles();
  const { data: loadedData, loading } = useQuery(
    dataSource?.all ? LIST_ALL_ACCOUNTS : LIST_ACCOUNTS,
    {
      skip:
        (!dataSource?.all && !dataSource?.parent_id && dataSource?.skip) ||
        !dataSource?.type,
      variables: dataSource,
      fetchPolicy: dataSource?.all ? undefined : "no-cache",
      onCompleted: data => {
        if (onLoaded && (data?.listAccounts || data?.listAllAccounts)) {
          let items =
            data?.listAccounts ||
            data?.listAllAccounts?.map(account => JSON.parse(account));
          if (sort) {
            items = orderBy(items, ["name"], ["asc"]);
          }
          onLoaded(items);
        }
      }
    }
  );
  const loadedDataNormalized = (
    loadedData?.listAccounts ||
    loadedData?.listAllAccounts?.map(account => JSON.parse(account))
  )?.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  let items = loadedDataNormalized || data || [];
  if (sort) {
    items = orderBy(items, ["label"], ["asc"]);
  }

  useEffect(() => {
    if (`${value}`.startsWith("label_")) {
      const optionLabel = value.substr(6);
      const optionValue = items.find(
        ({ label }) => label.toLowerCase() === optionLabel.toLowerCase()
      )?.value;
      if (optionValue) onChange({ target: { value: optionValue } });
    }
  }, [items, value]);

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
      required={required}
    >
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        inputProps={{
          name,
          id: name
        }}
        readOnly={readOnly}
        value={
          loading
            ? 1
            : `${value}`.startsWith("label_")
            ? null
            : value || defaultValue
        }
        onChange={e => {
          onChange(
            e,
            loadedData?.listAllAccounts?.find(
              account => JSON.parse(account)?.id === e.target.value
            )
          );
        }}
      >
        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}
        >
          {label}
        </MenuItem>
        {loading ? (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={1}
          >
            Loading ...
          </MenuItem>
        ) : null}
        {items?.map(({ value, label }) => (
          <MenuItem
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected
            }}
            value={value}
            key={value || Math.random()}
          >
            { deleteable && (
            <ListItemIcon>
                  <DeleteForeverRoundedIcon />
            </ListItemIcon>
            )}
            {label || value}
          </MenuItem>
        ))}
      </Select>
      {validation && !value && (
        <FormHelperText>This is required</FormHelperText>
      )}
    </FormControl>
  );
};

CustomSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  required: PropTypes.bool,
  validation: PropTypes.bool,
  dataSource: PropTypes.shape({
    type: PropTypes.string,
    parent_id: PropTypes.number
  }),
  onLoaded: PropTypes.func
};

CustomSelect.defaultProps = {
  value: null,
  data: [],
  required: false,
  validation: false,
  dataSource: null,
  hoverColor: "primary !important"
};

export default CustomSelect;
