import React from "react";
import PropTypes from "prop-types";
import { orderBy, startCase } from "lodash";

import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle";

const useStyles = makeStyles(styles);

const CustomSelect = ({
  label,
  name,
  value,
  defaultValue = "",
  onChange,
  data
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
        {label}
      </InputLabel>
      <Select
        MenuProps={{
          className: classes.selectMenu
        }}
        classes={{
          select: classes.select
        }}
        inputProps={{
          name,
          id: name
        }}
        value={value || defaultValue}
        onChange={onChange}
      >
        {orderBy(data || [], ["name"], ["asc"])?.map(
          ({ type, isDivider, id, name, user_id }) => {
            if (isDivider) {
              return (
                <ListSubheader key={type}>{startCase(type)}</ListSubheader>
              );
            }
            return (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value={`${type}-${id}`}
                key={`${type}-${id}`}
              >
                {name}
                {user_id ? " ✓" : ""}
              </MenuItem>
            );
          }
        )}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({}))
};

CustomSelect.defaultProps = {
  value: null,
  data: []
};

export default CustomSelect;
