import React, { useContext, useState } from "react";
import { useCSVReader } from "react-papaparse";
import Papaparse from 'papaparse';
import { Container, Row, Col } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link'

import { Context } from "../../redux/store";

import CustomerListExample from "assets/csvs/customer-list-example.csv";
import CRMLeadsExample from "assets/csvs/Upload_Leads_LinearCRM_Example.csv";
import CRMCustomersExample from "assets/csvs/Upload_Customers_LinearCRM_Example.csv";
import CRMPeopleExample from "assets/csvs/Upload_People_LinearCRM_Example.csv";

import Button from "components/CustomButtons/Button";

const styles = {
  dropzone: {
    padding: 10,
    "& > div": {
      width: "100%",
      height: "100%",
      border: "1px dashed gray",
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }
  },
};

const useStyles = makeStyles(styles);

const UploadFile = ({ inputs, onLoaded, content, Customers = null, People = null }) => {
  const [state] = useContext(Context);
  const { CSVReader } = useCSVReader();
  const classes = useStyles();
  const [loaded, setLoaded] = useState(content);
  const history = useLocation();

  const handleOnFileLoad = data => {
    // console.log('---------------------------')
    const { data: header } = data[0];
    onLoaded(data);
    setLoaded(data);
    // console.log('---------------------------')
  };

  const handleOnError = err => {
    console.log(err);
  };

  const handleOnRemoveFile = data => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };
  return (
    <div>
      {Customers === 0 && (
        <>
          <span>You can import leads or customers separately. Alternatively, you can import people, customers, and leads at the same time by grouping them in a single .csv file on the people upload page.<br /><br />
            Please note: If you have multiple leads or customers in the same file, the import tool will import the last row in the .csv file.</span>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h4><b>Before uploading your file</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <h4><a href={"/admin/crm_data_structures?customer=" + state.customers?.selected?.id}><b>Learn about our data structure and required fields</b></a></h4>
                <div>Download and use our sample file to see exactly how we organize data in LinearCRM.</div>
                <a href={CRMLeadsExample}>Download .csv sample file</a>
                <h4><b>Fill in mandatory fields</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <br />
                <div style={{ color: "red" }}>Do not designate people who were acquired through purchases, obtained from business cards or a personal address book, extracted from the internet, or any other list where you lack confirmation of their opt-in to receive communication from you.</div>
              </Col>
              <Col
                md={6}
                className={classes.dropzone}
              >
                <CSVReader
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  onRemoveFile={handleOnRemoveFile}
                >
                  {() => (
                    <>
                      <div>
                        <input
                          accept=".csv"
                          id="csvInput"
                          hidden
                          onChange={() => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              Papaparse.parse(reader.result, {
                                complete: function (results) {
                                  handleOnFileLoad(results.data);
                                },
                              });
                            };
                            // start reading the file. When it is done, calls the onload event defined above.
                            // @ts-ignore
                            if (reader)
                              reader.readAsText(document.getElementById('csvInput').files[0]);
                          }}
                          type="file"
                        />
                        <Button
                          color="primary"
                          onClick={() => document.getElementById('csvInput').click()}
                        >
                          Upload file
                        </Button>
                        <div>Only .csv file types are supported.</div>
                        {loaded && <div>File loaded ({loaded?.length} rows)</div>}
                      </div>
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {Customers === 1 && (
        <>
          <span>You can import leads or customers separately. Alternatively, you can import people, customers, and leads at the same time by grouping them in a single .csv file on the people upload page.<br /><br />
            Please note: If you have multiple leads or customers in the same file, the import tool will import the last row in the .csv file.</span>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h4><b>Before uploading your file</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <h4><b><HashLink to={"/admin/crm_data_structures?customer=" + state.customers?.selected?.id + "#Customer"}>Learn about our data structure and required fields</HashLink></b></h4>
                <div>Download and use our sample file to see exactly how we organize data in LinearCRM.</div>
                <a href={CRMCustomersExample}>Download .csv sample file</a>
                <h4><b>Fill in mandatory fields</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <br />
                <div style={{ color: "red" }}>Do not designate people who were acquired through purchases, obtained from business cards or a personal address book, extracted from the internet, or any other list where you lack confirmation of their opt-in to receive communication from you.</div>
              </Col>
              <Col
                md={6}
                className={classes.dropzone}
              >
                <CSVReader
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  onRemoveFile={handleOnRemoveFile}
                >
                  {() => (
                    <>
                      <div>
                        <input
                          accept=".csv"
                          id="csvInput"
                          hidden
                          onChange={() => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              Papaparse.parse(reader.result, {
                                complete: function (results) {
                                  handleOnFileLoad(results.data);
                                },
                              });
                            };
                            // start reading the file. When it is done, calls the onload event defined above.
                            // @ts-ignore
                            if (reader)
                              reader.readAsText(document.getElementById('csvInput').files[0]);
                          }}
                          type="file"
                        />
                        <Button
                          color="primary"
                          onClick={() => document.getElementById('csvInput').click()}
                        >
                          Upload file
                        </Button>
                        <div>Only .csv file types are supported.</div>
                        {loaded && <div>File loaded ({loaded?.length} rows)</div>}
                      </div>
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {People === 1 && (
        <>
          <span>You can import people separately. Alternatively, you can import people, customers, and leads at the same time by grouping them in a single .csv file.<br /><br />
          Please note: If you have multiple people in the same file, the import tool will import the last row in the .csv file.</span>
          <Container fluid>
            <Row>
              <Col md={6}>
                <h4><b>Before uploading your file</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <h4><HashLink to={"/admin/crm_data_structures?customer=" + state.customers?.selected?.id + "#People"} rel="noopener noreferrer"><b>Learn about our data structure and required fields</b></HashLink></h4>
                <div>Download and use our sample file to see exactly how we organize data in LinearCRM.</div>
                <a href={CRMPeopleExample}>Download .csv sample file</a>
                <h4><b>Fill in mandatory fields</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <br />
                <div style={{ color: "red" }}>Do not designate people who were acquired through purchases, obtained from business cards or a personal address book, extracted from the internet, or any other list where you lack confirmation of their opt-in to receive communication from you.</div>
              </Col>
              <Col
                md={6}
                className={classes.dropzone}
              >
                <CSVReader
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  onRemoveFile={handleOnRemoveFile}
                >
                  {() => (
                    <>
                      <div>
                        <input
                          accept=".csv"
                          id="csvInput"
                          hidden
                          onChange={() => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              Papaparse.parse(reader.result, {
                                complete: function (results) {
                                  handleOnFileLoad(results.data);
                                },
                              });
                            };
                            // start reading the file. When it is done, calls the onload event defined above.
                            // @ts-ignore
                            if (reader)
                              reader.readAsText(document.getElementById('csvInput').files[0]);
                          }}
                          type="file"
                        />
                        <Button
                          color="primary"
                          onClick={() => document.getElementById('csvInput').click()}
                        >
                          Upload file
                        </Button>
                        <div>Only .csv file types are supported.</div>
                        {loaded && <div>File loaded ({loaded?.length} rows)</div>}
                      </div>
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {history.pathname.indexOf("customer-list") > -1 && (
        <>
          <span>You can import people, organizations, notes and leads separately, or you can speed things up by grouping them in a single import file.</span>
          <Container fluid>
            <Row>
              <Col md={6}>
              <h4><b>Before uploading your file</b></h4>
                <h4>Learn our data structure</h4>
                <div>Download and use our sample file to see exactly how we organize data in Hydrate.</div>
                <a href={CustomerListExample}>Download .csv sample file</a>
                <h4><b>Fill in mandatory fields</b></h4>
                <div>Certain fields need to be entered in order for your import to be successful. Make sure they are complete before you start importing.</div>
                <br />
                <div style={{ color: "red" }}>Do not import lists that were purchased, came from from business cards or a personal address book, scraped from the internet, or any other list where you do not have confirmation they opt-in to receive communication from you.</div>
              </Col>
              <Col
                md={6}
                className={classes.dropzone}
              >
                <CSVReader
                  onFileLoad={handleOnFileLoad}
                  onError={handleOnError}
                  noClick
                  onRemoveFile={handleOnRemoveFile}
                >
                  {() => (
                    <>
                      <div>
                        <input
                          accept=".csv"
                          id="csvInput"
                          hidden
                          onChange={() => {
                            const reader = new FileReader();
                            reader.onload = () => {
                              Papaparse.parse(reader.result, {
                                complete: function (results) {
                                  handleOnFileLoad(results.data);
                                },
                              });
                            };
                            // start reading the file. When it is done, calls the onload event defined above.
                            // @ts-ignore
                            if (reader)
                              reader.readAsText(document.getElementById('csvInput').files[0]);
                          }}
                          type="file"
                        />
                        <Button
                          color="primary"
                          onClick={() => document.getElementById('csvInput').click()}
                        >
                          Upload file
                        </Button>
                        <div>Only .csv file types are supported.</div>
                        {loaded && <div>File loaded ({loaded?.length} rows)</div>}
                      </div>
                    </>
                  )}
                </CSVReader>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default UploadFile;
