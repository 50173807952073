import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { startCase, camelCase } from "lodash";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Button from "components/CustomButtons/Button";
import StatsCard from "components/StatsCard";
import { GET_BUDGET, UPDATE_BUDGET } from "queries/budget";
import { Context } from "../../redux/store";

const BudgetCard = ({ productLine, title, optional, increaseRecommend }) => {
  const [state] = useContext(Context);
  const [data, setData] = useState({});
  const [increaseBudgetPopup, setIncreaseBudgetPopup] = useState(false);
  const { loading, data: budgetData, refetch } = useQuery(GET_BUDGET, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
      product_line: productLine
    }
  });
  
  useEffect(() => {
    setData(budgetData?.getBudget);
    //if (productLine === budgetData?.getBudget?.product_line) {
     // setData(budgetData?.getBudget);
    //} else {
      //setData({});
      // console.log(productLine, budgetData?.getBudget?.product_line);
    //}
  }, [budgetData, productLine]);

  const monthlyBudget = data?.monthly_budget || 0;
  const newlyMonthlyBudget = data?.new_monthly_budget || 0;
  const dailyBudget = data?.daily_budget || 0;
  const budget = newlyMonthlyBudget || monthlyBudget;
  const budget_spent_carryover = data?.budget_spent_carryover || 0;
  const status = data?.status || "";
  const start_date = data?.start_date || "";
  const current_cycle = data?.current_cycle || 1;

  const pace = dailyBudget * 30 + budget_spent_carryover;

  const exactPercentage = budget ? pace / budget - 1 : 0;
  let suggestPercentage = 0;

  [10, 17, 29, 46].forEach(per => {
    if (exactPercentage * 100 > per) suggestPercentage = per;
  });

  const onIncreaseBudgetPopup = () => {
    setIncreaseBudgetPopup(true);
  };

  const increaseBudgetAmount = Math.round(
    budget * (100 + suggestPercentage) * 0.01
  );

  const [updateBudget, { loading: updatingBudget }] = useMutation(
    UPDATE_BUDGET,
    {
      variables: {
        data: {
          new_monthly_budget: increaseBudgetAmount,
          id: data?.id
        }
      },
      onCompleted: () => {
        refetch();
      }
    }
  );

  const handleClose = (accept = false) => () => {
    setIncreaseBudgetPopup(false);
    if (accept) {
      updateBudget();
    }
  };

  if (Number(monthlyBudget || 0) === 0 && optional) return null;

  let milestones = [];

  /* milestones = [
    {
      value: pace / 1.2,
      color: "wbmMoney",
      icon: "star",
      message: "Pacing: Fast"
    },
    {
      value: pace,
      color: "orange",
      icon: "sentiment_very_satisfied",
      message: "Pacing: Good"
    },
    {
      value: pace / 0.8,
      color: "grey",
      icon: "sentiment_satisfied",
      message: "Pacing: Ok"
    },
    {
      color: "info",
      icon: "fitness_center",
      message: "Pacing: Slow"
    }
  ]; */

  return (
    <>
      <StatsCard
        icon="attach_money"
        loading={loading || updatingBudget}
        label={
          title ? `${title} Current Monthly Budget` : "Current Monthly Budget"
        }
        value={monthlyBudget}
        subheading={
          newlyMonthlyBudget
            ? `New Monthly Budget: ${
              new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
              }).format(newlyMonthlyBudget)
            }`
            : ""}
        render="currency"
        action={
          suggestPercentage > 0 && increaseRecommend ? (
            <a href={"#"} onClick={onIncreaseBudgetPopup}>
              Increase Monthly Budget to $ {increaseBudgetAmount}
            </a>
          ) : null
        }
        footer={`Cycle ${current_cycle}, Start Date: ${
          start_date ? moment(start_date).format("MM/DD") : "-"
        } - ${status}`}
        milestones={milestones}
      />
      {increaseRecommend && (
        <Dialog
          open={Boolean(increaseBudgetPopup)}
          onClose={handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Increase Monthly Budget
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              I agree to increase my monthly budget at the start of my next
              month to ${increaseBudgetAmount}
              for {startCase(camelCase(productLine))}.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose()}>Disagree</Button>
            <Button onClick={handleClose(true)} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

BudgetCard.propTypes = {
  productLine: PropTypes.string,
  title: PropTypes.string,
  optional: PropTypes.bool,
  increaseRecommend: PropTypes.bool
};

export default BudgetCard;
