import React from "react";


import { makeStyles } from "@mui/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import SubmitForm from "components/SubmitForm";

import support_profile from "assets/img/support.jpg";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle.js";

const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>Support Team</h2>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <img src={support_profile} alt="support_profile" />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}></div>
              <h2 className={classes.cardNameTitle}>Support Team</h2>
              <p className={classes.cardProductDesciprion}>
                <ul>
                  <li>Office: 800-341-7138 x 111</li>
                  <li>Email: Support@WaterBearMarketing.com</li>
                </ul>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} lg={6}>
          <SubmitForm toEmail="support@waterbearmarketing.com" />
        </GridItem>
      </GridContainer>
    </div>
  );
}
