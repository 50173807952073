import React from 'react';
import { useQuery } from '@apollo/client';
import { addMonths } from 'date-fns';
import moment from 'moment';

import NoContent from 'components/NoContent';

const NoContentWrapper = ({
  skip,
  product,
  title,
  isAn,
  isLoading,
  hasNoData = null,
  queries = [],
  noContent,
  children,
  noTemplate
}) => {
  const queryResults = queries.map(({ name, query, options: { variables, ...options } }) => {
    const { loading, error, data } = useQuery(query, {
      fetchPolicy: "no-cache",
      skip,
      variables: {
        start_date: moment(addMonths(new Date(), -1)).format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        ...variables
      },
      ...options
    });

    return { [name]: { loading, error, data } };
  });

  const props = Object.assign({}, ...queryResults);

  if (skip) {
    return null;
  } else if ((props?.budget?.loading !== undefined && props?.budget?.loading === false && product !== "PaidSearch" && product !== "Hydrate") || ((props) && typeof hasNoData !== "function" && hasNoData) || ((props) && typeof hasNoData === "function" && hasNoData(props) === false)) {
    if (noTemplate) {      
      return null;
    }
    return (
      <NoContent product={product} loading={typeof isLoading === "function" ? isLoading(props) : isLoading} title={title} isAn={isAn}>
        {noContent}
      </NoContent>
    );
  }
  return children;
};

export default NoContentWrapper;