import React, { useEffect, useState, useContext } from "react";
import { omit } from "lodash";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from "notistack";

import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";

import HydrateFunnelImg from "assets/img/hydrate_funnel.png";
import { Context } from "../../../../redux/store";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import Button from "components/CustomButtons/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CampaignConfigure from "./CampaignConfigure";
import HydrateAssetSubPage from "./HydrateAssetSubPage";
import data from "./data";
import { READ_ME } from "queries/users";
import { useQuery, useMutation } from "@apollo/client";

import { GET_LEAD_STATUS, 
         ADD_LEAD_STATUS, 
         GET_LEAD_TYPE, 
         DELETE_LEAD_STATUS,
         EDIT_MESSAGE_NAMES,
         GET_MESSAGE_NAMES,
         UPDATE_HYDRATE_CAMPAIGN } from "queries/hydrate";

import { makeStyles } from "@mui/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const AssetsTemplate = () => {
  const classes = useStyles();
  const [state] = useContext(Context);
  const [leadStatus, setLeadStatus] = useState(3);
  const [addleadStatus, setAddLeadStatus] = useState(null);
  const [messages, setMessages] = useState([]);
  const [messageNumber, setMessageNumber] = useState(1);
  const [submissionData, setSubmissionData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [editMessageData, setEditMessageData] = useState({});
  const [selectedCall, setSelectedCall] = useState(null);
  const [deleteStatus, setSelectedDeleteStatus] = useState(null);
  const [editMessageNames, setSelectedEditMessageNames] = useState(null);
  const [deleteType, setSelectedDeleteType] = useState(null);

  const { data: me } = useQuery(READ_ME);

  const [updateCampaign, { loading: updatingCampaign }] = useMutation(
    UPDATE_HYDRATE_CAMPAIGN
  );
  
  const [campaign, setCampaign] = useState({
    sms_text: 1,
    mms_text: 1,
    email: 1,
    letter: 1,
    postcard: 1,
    voice_message: 1
  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const customer_id = state.customers?.selected?.id;
  const messagesKey = `hydrate_message_titles_${leadStatus}`;
  const statusKey = `hydrate_lead_status`;

  // const history = useNavigate();

  const { loading, data: LeadStatus_data, refetch } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: { customer_id: customer_id,
      skip_message_to_all: false }
  });

  const [addLeadStatusForm, { loading: completing }] = useMutation(ADD_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchleadtypes();
      messagenamesrefetch();
    }
  });

  const { loading: loadingmessagenames, data: messagename_data, refetch: messagenamesrefetch } = useQuery(GET_MESSAGE_NAMES, {
    skip: !customer_id || !leadStatus,
    variables: { customer_id: customer_id,
                 lead_status: leadStatus }
  });

  const [DeleteLeadStatusForm] = useMutation(DELETE_LEAD_STATUS, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchleadtypes();
      messagenamesrefetch()
    }
  });

  const [EditMessgeForm] = useMutation(EDIT_MESSAGE_NAMES, {
    skip: !customer_id,
    onCompleted: () => {
      refetch();
      refetchleadtypes();
      messagenamesrefetch();
    }
  });

  let LeadStatus_data_temp = LeadStatus_data?.getHydrateLeadStatus || [];

  let defaultLeadStatuses = [];
  let defaultLeadCustomerStatuses = [];

  if(LeadStatus_data_temp)
  {
    LeadStatus_data_temp.forEach(obj => {
      defaultLeadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
    })

    LeadStatus_data_temp.forEach(obj => {
      if(obj.lead_status > 11)
        defaultLeadCustomerStatuses.push({ label: obj.lead_status_text, value: obj.lead_status })
      })
  }

  const { data: LeadType_data, refetch: refetchleadtypes } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  let LeadType_data_temp = LeadType_data?.getHydrateLeadType || [];

  let defaultLeadTypes = [];
  let defaultLeadCustomerTypes = [];

  if(LeadType_data_temp)
  {
    defaultLeadTypes.push({ label: "Add Custom Lead Type", value: -1 })

    LeadType_data_temp.forEach(obj => {
      defaultLeadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })

    LeadType_data_temp.forEach(obj => {
      if(obj.lead_type > 4)
        defaultLeadCustomerTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })    
  }

  const Message_Name_data_temp = messagename_data?.getHydrateMessageNames || [];

  useEffect(() => {
    if (leadStatus && messages.length === 0) {
      const defaultMessages = new Array(6).fill(0).map((msg, index) => ({
        label: `Message ${index + 1}`,
        value: index + 1,
      }));
      setMessages(defaultMessages);
      setMessageNumber(1);
    }

    if (leadStatus && !loadingmessagenames) {
      const defaultMessages = new Array(6).fill(0).map((msg, index ) => {
        let db_message = Message_Name_data_temp.filter( message => { if(message.message_id === `Message${index + 1}` ) return message })
        if(db_message[0]?.message_name !== undefined)
        {
          return  ({        
            label: db_message[0]?.message_name,
            value: index + 1,    
          })
        }else
        {
          return ({        
            label: `Message ${index + 1}`,
            value: index + 1
          })
        }
    });
      setMessages(defaultMessages);
      setMessageNumber(1);
    }
  }, [leadStatus, Message_Name_data_temp, loadingmessagenames]);

  const updateFieldHandler = (key, convert) => e => {
    if(key === "add_new_lead")
    {
      setSubmissionData({
        ...omit(submissionData, ["lead_status"]),
        [key]: String(e.target.value)
      });
    }else if(key === "lead_status")
    {
      setSubmissionData({
        ...omit(submissionData, ["add_new_lead"]),
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
    else
      setSubmissionData({
        ...submissionData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
  }

  const editMessageFieldHandler = (key, convert) => e => {
    setEditMessageData({
      ...editMessageData,
      [key]: convert ? convert(e.target.value) : e.target.value,
      lead_status: leadStatus
    });
  }

  const deleteFieldHandler = (key, convert) => e => {
    setDeleteData({
      ...deleteData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  }

  const currentLeadStatus = LeadStatus_data_temp.find(({ value }) => value === leadStatus);
  return (
    <Container>
      <Row>
        <Col md={6}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: -10 }}>
              Lead Status
            </h4>
            <CustomSelect
              value={leadStatus}
              onChange={e => {
                setLeadStatus(e.target.value);
              }}
              data={defaultLeadStatuses}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: -10 }}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setSelectedCall(true);
                }}
              >
                Add Custom Lead { me?.readMe?.user_level === "master" && (`Type and`)} Status
              </a>
            </h4>
          </div>
          <br />
          { defaultLeadCustomerStatuses.length > 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: -10 }}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setSelectedDeleteStatus(true);
                }}
              >
                Delete Custom Lead Statuses
              </a>
            </h4>
          </div>
          )}
          <br />
          { me?.readMe?.user_level === "master" && defaultLeadCustomerTypes.length > 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: -10 }}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setSelectedDeleteType(true);
                }}
              >
                Delete Custom Lead Types
              </a>
            </h4>
          </div>
          )}
          {Boolean(customer_id && leadStatus) && (
            <>
              <CampaignConfigure
                customerId={customer_id}
                leadStatus={leadStatus}
                campaign={campaign}
                setCampaign={setCampaign}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomSelect
                  value={messageNumber}
                  onChange={e => {
                    setMessageNumber(e.target.value);
                  }}
                  data={messages}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4 style={{ whiteSpace: "nowrap", marginRight: 20, marginBottom: -10 }}>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setSelectedEditMessageNames(true);
                    }}
                  >
                    Edit Message Names
                  </a>
                </h4>
              </div>
            </>
          )}
        </Col>
        <Col md={6} style={{ padding: 20 }}>
          <img
            src={HydrateFunnelImg}
            style={{
              width: "100%",
              maxWidth: 450,
              marginLeft: "auto",
              display: "block"
            }}
          />
        </Col>
      </Row>
      {data.map(item => (
        <HydrateAssetSubPage
          key={item.key}
          customer_id={customer_id}
          leadStatus={leadStatus}
          messageNumber={messageNumber}
          {...item}
        />
      ))}
      <br />
      <br />
      <br />
      <br />
      <Dialog
        open={Boolean(selectedCall)}
        onClose={() => setSelectedCall(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Add Custom Lead { me?.readMe?.user_level === "master" && (`Type and`)} Status
        </DialogTitle>
        <DialogContent>
          <div>
            <lable>Select Lead Type</lable><CustomSelect
            value={submissionData?.lead_type ? submissionData?.lead_type: ""}
            onChange={ updateFieldHandler("lead_type") }
            data={defaultLeadTypes}
          />
          </div>
          { me?.readMe?.user_level === "master" && (!submissionData?.lead_type || submissionData?.lead_type === -1) ?
          <div>
            <lable>Or Add Custom Lead Type</lable>
            <TextField
              fullWidth
              onChange={ updateFieldHandler("lead_type_text") }
              inputProps={{
                className: classes.textField
              }}
            />
          </div>: ""}
        { me?.readMe?.user_level === "master" && (
          <div>
            <label>Select Lead Status</label><CustomSelect
              value={submissionData?.lead_status ? submissionData?.lead_status: ""}
              onChange={ updateFieldHandler("lead_status") }
              data={defaultLeadStatuses}
            />
          </div>
        )}
        { me?.readMe?.user_level === "master" && (`Or`)} Add Custom Lead Status<TextField
          fullWidth
          value={submissionData?.add_new_lead ? submissionData?.add_new_lead: ""}
          onChange={ updateFieldHandler("add_new_lead") }
          inputProps={{
            className: classes.textField
          }}
          />
        </DialogContent>
        <DialogActions>
        <Button
              color="primary" 
              type="submit"
              style={{ float: "right" }}
              onClick={async (e) => {
                e.preventDefault();

                let New_lead_status_id = 0, ReturnedPromise = '';

                if(me?.readMe?.user_level === "master")
                {
                  if(!submissionData?.add_new_lead && !submissionData?.lead_status)
                  {
                    alert("Lead Status Required.  Please choose or add a Lead Status.");
                    return;
                  }else if(!submissionData?.lead_type_text && (!submissionData?.lead_type || submissionData?.lead_type === -1 ))
                  {
                    alert("Lead Type Required.  Please choose or add a Lead Type.");
                    return;
                  }
                }
                else
                {
                  if(!submissionData?.add_new_lead)
                  {
                    alert("Lead Status Required.  Please add a Lead Status.");
                    return;
                  }
                }
                  if(submissionData){
                    ReturnedPromise = await addLeadStatusForm({
                      variables: {
                        customer_id: customer_id,
                        data: submissionData
                      }
                    });

                    if(ReturnedPromise.data.addLeadStatusHydrate !== 0)
                    {
                      updateCampaign({
                        variables: {
                          data: {
                            customer_id,
                            list_name: ReturnedPromise.data.addLeadStatusHydrate,
                            sms_text: 1,
                            mms_text: 1,
                            email: 1, 
                            postcard: 1,
                            letter: 1,
                            voice_message: 1,
                            sms_text_day: 1,
                            mms_text_day: 5,
                            email_day: 3,
                            letter_day: 11,
                            postcard_day: 9,
                            voice_message_day: 7,
                            frequency_template: 3
                          }
                        }
                      });
                    }

                    setSelectedCall(null);
                    setSubmissionData({});
                  }
                  const notify = options => {
                    const key = enqueueSnackbar(options?.text, {
                      variant: options?.variant,
                      persist: true
                    });
                    setTimeout(() => {
                      closeSnackbar(key);
                    }, options?.delay || 2000);
                  }
                  notify({
                    text: "Information has been successfully submitted",
                    variant: "success"
                  });                
                }}
              >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(deleteStatus)}
        onClose={() => setSelectedDeleteStatus(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Custom Lead Statuses
        </DialogTitle>
        <DialogContent>
          Select Lead Status to Delete<CustomSelect
              value={deleteData?.lead_status ? deleteData?.lead_status: ""}
              onChange={ deleteFieldHandler("lead_status") }
              data={defaultLeadCustomerStatuses}
              deleteable={true}
            />
        </DialogContent>
        <DialogActions>
        <Button
              color="primary" 
              type="submit"
              style={{ float: "right" }}
              onClick={(e) => {
                e.preventDefault();
                let lead_type = 0;
                if(!deleteData?.lead_status)
                {
                  alert("Lead Status Required.  Please choose a Lead Status to Delete");
                  return;
                }
                if(deleteData){
                  DeleteLeadStatusForm({
                    variables: {
                      customer_id: customer_id,
                      data: deleteData
                    }
                  });

                  setSelectedDeleteStatus(null);
                  setDeleteData({});
                }
                const notify = options => {
                  const key = enqueueSnackbar(options?.text, {
                    variant: options?.variant,
                    persist: true
                  });
                  setTimeout(() => {
                    closeSnackbar(key);
                  }, options?.delay || 2000);
                }
                notify({
                  text: "Information has been successfully submitted",
                  variant: "success"
                });                
              }}
              >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      { me?.readMe?.user_level === "master" && (
      <Dialog
        open={Boolean(deleteType)}
        onClose={() => setSelectedDeleteType(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Delete Custom Lead Types
        </DialogTitle>
        <DialogContent>
          Select Lead Type to Delete<CustomSelect
              value={deleteData?.lead_type ? deleteData?.lead_type: ""}
              onChange={ deleteFieldHandler("lead_type") }
              data={defaultLeadCustomerTypes}
              deleteable={true}
            />
        </DialogContent>
        <DialogActions>
        <Button
              color="primary" 
              type="submit"
              style={{ float: "right" }}
              onClick={(e) => {
                e.preventDefault();
                if(!deleteData?.lead_type_text && (!deleteData?.lead_type || deleteData?.lead_type === -1 ))
                {
                  alert("Lead Type Required.  Please choose a Lead Type to Delete");
                  return;
                }
                if(deleteData){
                  DeleteLeadStatusForm({
                    variables: {
                      customer_id: customer_id,
                      data: deleteData
                    }
                  });

                  setSelectedDeleteType(null);
                  setDeleteData({});
                }
                const notify = options => {
                  const key = enqueueSnackbar(options?.text, {
                    variant: options?.variant,
                    persist: true
                  });
                  setTimeout(() => {
                    closeSnackbar(key);
                  }, options?.delay || 2000);
                }
                notify({
                  text: "Information has been successfully submitted",
                  variant: "success"
                });                
              }}
              >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      )}
      <Dialog
        open={Boolean(editMessageNames)}
        onClose={() => setSelectedEditMessageNames(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Edit Message Names
        </DialogTitle>
        <DialogContent>
          { messages.map( message =>
            <div style={{padding: "10px", width: "800px"}}>
              <label>{"Message " + message.value }</label>
                <Input
                  fullWidth
                  inputProps={{
                    type: "string",
                    defaultValue: message.label,
                    onChange: editMessageFieldHandler("Message" + message.value),
                    maxLength: 145
                  }}
                />
              </div>
            )}
        </DialogContent>
        <DialogActions>
        <Button
              color="primary" 
              type="submit"
              style={{ float: "right" }}
              onClick={(e) => {
                e.preventDefault();
                let lead_type = 0;
                if(!editMessageData)
                {
                  alert("Please edit at least 1 Message Name.");
                  return;
                }
                if(editMessageData){
                  EditMessgeForm({
                    variables: {
                      customer_id: customer_id,
                      data: editMessageData
                    }
                  });

                  setSelectedEditMessageNames(null);
                  setEditMessageData({});
                }
                const notify = options => {
                  const key = enqueueSnackbar(options?.text, {
                    variant: options?.variant,
                    persist: true
                  });
                  setTimeout(() => {
                    closeSnackbar(key);
                  }, options?.delay || 2000);
                }
                notify({
                  text: "Information has been successfully submitted",
                  variant: "success"
                });                
              }}
              >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const WrappedAssets = () => (
    <AssetsTemplate />
);

export default WrappedAssets;
