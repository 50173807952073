const styles = {
  helpIcon: {
    width: "20px !important",
    height: "20px !important",
    verticalAlign: "top",
    margin: "0 0 0 5px !important",
    cursor: "pointer"
  }
};

export default styles;
