import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";

import Button from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dropzone from "components/Dropzone";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select";
import SelectFromState from "components/Select/Select";
import MenuItem from "@mui/material/MenuItem";

import { READ_ME } from "queries/users";

import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";

import ServicesDefaultImage from "assets/img/Services.png";

import {
  UPDATE_SERVICE,
  GET_SERVICE_CATEGORIES
} from "queries/customers";
import { Context } from "../../../redux/store";

const styles = theme => ({
  stepIcon: {
    color: "#0071ce"
  },
  sidebyside: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  paper: { minWidth: "60%" },
  cursor: { cursor: 'pointer' }
});

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

const UpdateCRMService = ({ handleClose, refetch, current, setCurrentService, setservice_cost,
  service_cost, service_margin, setservice_margin, service_price, setservice_price,
  service_discount, setservice_discount, service_sale_price, setservice_sale_price,
  service_tax, setservice_tax }) => {
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const me = useQuery(READ_ME)?.data?.readMe;
  const classes = useStyles(styles);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submissionData, setSubmissionData] = useState(null);
  const [CategoryData, setCategoryData] = React.useState(null);
  const [user_id, setuser_id] = useState(null);

  const customer_id = state.customers?.selected?.id;

  const { data: Category_Data } = useQuery(GET_SERVICE_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  useEffect(() => {
    setuser_id(me?.id);
    setCategoryData([{ id: 0, category_text: "Select" }, ...Category_Data?.listServiceCategories || []]);
    setSubmissionData(current);
  }, [Category_Data, current]);

  const updateFieldHandler = (key) => e => {
    if (key === "service_taxable") {
      if (e.target.checked === false)
        setSubmissionData({
          ...omit(submissionData, ["service_tax"]),
          [key]: e.target.checked === true ? 1 : 0,
        });
      else
        setSubmissionData({
          ...submissionData,
          [key]: e.target.checked === true ? 1 : 0,
        });
    }
    else if (key === "service_category" || key === "parent_category" || key === "service_description") {
      setSubmissionData({
        ...submissionData,
        [key]: e
      });
    } else {
      if (key === "service_billing_type" && e.target.value !== "2")
        setSubmissionData({
          ...omit(submissionData, ["service_billing_frequency"]),
          [key]: e.target.value
        });
      else
        setSubmissionData({
          ...submissionData,
          [key]: e.target.value
        });
    }
  };

  const handleSave = async () => {
    if (submissionData?.service_name === undefined && current?.service_name === "") {
      alert("Service Name is required");
      return false;
    }
    if (submissionData?.service_category === undefined && current?.service_category === "") {
      alert("Service Category is required");
      return false;
    }

    submitUpdateRequest({
      variables: {
        customer_id,
        Service: {
          ...omit(submissionData, ["__typename", "category_text", "service_category"]),
          service_category: submissionData?.service_category ? parseInt(submissionData?.service_category) : null,
          service_cost: submissionData?.service_cost ? submissionData?.service_cost?.replace(/[^0-9.]+/g, "") : null,
          service_margin: submissionData?.service_margin ? submissionData?.service_margin?.replace(/[^0-9.]+/g, "") : null,
          service_price: submissionData?.service_price ? submissionData?.service_price?.replace(/[^0-9.]+/g, "") : null,
          service_discount: submissionData?.service_discount ? submissionData?.service_discount?.replace(/[^0-9.]+/g, "") : null,
          service_sale_price: submissionData?.service_sale_price ? submissionData?.service_sale_price?.replace(/[^0-9.]+/g, "") : null,
          service_tax: submissionData?.service_tax ? submissionData?.service_tax?.replace(/[^0-9.]+/g, "") : null
        },
      }
    });

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });
  };

  const [submitUpdateRequest] = useMutation(
    UPDATE_SERVICE,
    {
      onCompleted: () => {
        refetch();
        setCurrentService(null);
      }
    }
  );

  const FormatCurrency = (field) => {
    let value = '';

    if (field === "service_cost")
      value = service_cost;
    if (field === "service_margin")
      value = service_margin;
    if (field === "service_price")
      value = service_price;
    if (field === "service_discount")
      value = service_discount;
    if (field === "service_sale_price")
      value = service_sale_price;
    if (field === "service_tax")
      value = service_tax;

    if (value === '')
      value = "0";

    let newValue = parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setSubmissionData({
      ...submissionData,
      [field]: newValue
    });

    if (field === "service_cost")
      setservice_cost(newValue);
    if (field === "service_margin")
      setservice_margin(newValue);
    if (field === "service_price")
      setservice_price(newValue);
    if (field === "service_discount")
      setservice_discount(newValue);
    if (field === "service_sale_price")
      setservice_sale_price(newValue);
    if (field === "service_tax")
      setservice_tax(newValue);
  }

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null)
      value = "0";
    return parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateProductValueFieldHandler = (field, e) => {
    if (field === "service_cost")
      setservice_cost(e.target.value);
    if (field === "service_margin")
      setservice_margin(e.target.value);
    if (field === "service_price")
      setservice_price(e.target.value);
    if (field === "service_discount")
      setservice_discount(e.target.value);
    if (field === "service_sale_price")
      setservice_sale_price(e.target.value);
    if (field === "service_tax")
      setservice_tax(e.target.value);
  };

  return (
    <div style={{ minWidth: "60%" }}>
      <Dialog open onClose={handleClose()} classes={{ paper: classes.paper }}>
        <DialogContent>
          <div style={{ float: "right" }}><Button onClick={handleClose()}><CloseIcon /></Button></div>
          <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{submissionData?.service_name || ""}</b></h2>
          <>
            <GridContainer justifyContent="center" alignItems="center">
              <GridItem style={{ marginTop: "30px" }}>
                Upload Service Image
                <Dropzone
                  path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                  accept="image/png, image/jpeg"
                  onChange={value => updateFieldHandler("service_image")({ target: { value } })}
                  setuploadPercentage={setuploadPercentage}
                >
                  {() => (
                    <img
                      src={submissionData?.service_image || current?.service_image || ServicesDefaultImage || ""}
                      height="100px"
                      style={{ lineHeight: "50px", textAlign: "center", marginLeft: "18px" }}
                    />
                  )}
                </Dropzone>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}
                >Service Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                <input
                  id="service_name"
                  maxLength={255}
                  type="text"
                  defaultValue={submissionData?.service_name || current?.service_name || ""}
                  onChange={updateFieldHandler("service_name")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service SKU</label>
                <input
                  id="service_sku"
                  type="text"
                  defaultValue={submissionData?.service_sku || current?.service_sku || ""}
                  onChange={updateFieldHandler("service_sku")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <label
                  style={{ marginTop: "30px" }}>Service Description</label>
                <div style={{ clear: "both" }}></div>
                <div className="App" style={{ float: 'left' }}>
                  <RichTextEditor updateFieldHandler={updateFieldHandler} field={"service_description"} current={current} readonly={false} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Cost</label>
                <input
                  id="service_cost"
                  maxLength={20}
                  type="text"
                  value={service_cost === '' && submissionData?.service_cost !== undefined && submissionData?.service_cost !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_cost) : service_cost}
                  onBlur={() => FormatCurrency("service_cost")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["service_cost"]: submissionData?.service_cost?.replace(/[^0-9.]/g, "")
                    })
                    setservice_cost(submissionData?.service_cost?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("service_cost", e); updateProductValueFieldHandler("service_cost", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Margin</label>
                <input
                  id="service_margin"
                  maxLength={20}
                  type="text"
                  value={service_margin === '' && submissionData?.service_margin !== undefined && submissionData?.service_margin !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_margin) : service_margin}
                  onBlur={() => FormatCurrency("service_margin")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["service_margin"]: submissionData?.service_margin?.replace(/[^0-9.]/g, "")
                    })
                    setservice_margin(submissionData?.service_margin?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("service_margin", e); updateProductValueFieldHandler("service_margin", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Price</label>
                <input
                  id="service_price"
                  type={"string"}
                  maxLength={20}
                  value={service_price === '' && submissionData?.service_price !== undefined && submissionData?.service_price !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_price) : service_price}
                  onBlur={() => FormatCurrency("service_price")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["service_price"]: submissionData?.service_price?.replace(/[^0-9.]/g, "")
                    })
                    setservice_price(submissionData?.service_price?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("service_price", e); updateProductValueFieldHandler("service_price", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Discount</label>
                <input
                  id="service_discount"
                  type={"string"}
                  maxLength={20}
                  value={service_discount === '' && submissionData?.service_discount !== undefined && submissionData?.service_discount !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_discount) : service_discount}
                  onBlur={() => FormatCurrency("service_discount")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["service_discount"]: submissionData?.service_discount?.replace(/[^0-9.]/g, "")
                    })
                    setservice_discount(submissionData?.service_discount?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("service_discount", e); updateProductValueFieldHandler("service_discount", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Sale Price</label>
                <input
                  id="service_sale_price"
                  type={"string"}
                  maxLength={20}
                  value={service_sale_price === '' && submissionData?.service_sale_price !== undefined && submissionData?.service_sale_price !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_sale_price) : service_sale_price}
                  onBlur={() => FormatCurrency("service_sale_price")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["service_sale_price"]: submissionData?.service_sale_price?.replace(/[^0-9.]/g, "")
                    })
                    setservice_sale_price(submissionData?.service_sale_price?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("service_sale_price", e); updateProductValueFieldHandler("service_sale_price", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}></GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Taxable</label>
                <Checkbox
                  checked={submissionData?.service_taxable === 1 ? 1 : 0}
                  style={{ "width": "20px", marginLeft: "20px" }}
                  onChange={updateFieldHandler("service_taxable")}>
                </Checkbox>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {submissionData?.service_taxable === 1 ?
                  <>
                    <label
                      style={{ marginTop: "30px" }}>Service Tax</label>
                    <input
                      id="service_tax"
                      type={"string"}
                      maxLength={20}
                      value={service_tax === '' && submissionData?.service_tax !== undefined && submissionData?.service_tax !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.service_tax) : service_tax}
                      onBlur={() => FormatCurrency("service_tax")}
                      onFocus={() => {
                        setSubmissionData({
                          ...submissionData,
                          ["service_tax"]: ''
                        })
                        setservice_tax('');
                      }}
                      onChange={(e) => { updateFieldHandler("service_tax", e); updateProductValueFieldHandler("service_tax", e) }}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </> : ""}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Billing Type</label>
                <Select
                  onChange={updateFieldHandler("service_billing_type")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                  inputProps={{
                    value: submissionData?.service_billing_type || current?.service_billing_type || 0
                  }}
                >
                  <MenuItem value="0" label="Select">Select</MenuItem>
                  <MenuItem value="1">One Time</MenuItem>
                  <MenuItem value="2">Recurring</MenuItem>
                </Select>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {submissionData?.service_billing_type === "2" ?
                  <>
                    <label
                      style={{ marginTop: "30px" }}>Service Billing Fequency</label>
                    <Select
                      onChange={updateFieldHandler("service_billing_frequency")}
                      style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                      inputProps={{
                        value: submissionData?.service_billing_frequency || 0
                      }}
                    >
                      <MenuItem value="0" label="Select">Select</MenuItem>
                      <MenuItem value="1">Daily</MenuItem>
                      <MenuItem value="2">Weekly</MenuItem>
                      <MenuItem value="3">Monthly</MenuItem>
                      <MenuItem value="4">Bi-Monthly</MenuItem>
                      <MenuItem value="5">Quarterly</MenuItem>
                      <MenuItem value="6">Semi-Annually</MenuItem>
                      <MenuItem value="6">Annually</MenuItem>
                    </Select>
                  </>
                  : ""}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Unit</label>
                <input
                  id="service_unit"
                  type={"string"}
                  maxLength={155}
                  defaultValue={submissionData?.service_unit || current?.service_unit || ""}
                  onChange={updateFieldHandler("service_unit")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Category</label>
                <SelectFromState
                  options={CategoryData?.map(({ id, category_text }) => ({
                    key: id,
                    label: category_text
                  }))
                  }
                  selected={submissionData?.service_category || current?.service_category || 0}
                  onSelect={updateFieldHandler("service_category")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Parent Category</label>
                <SelectFromState
                  options={CategoryData?.map(({ id, category_text }) => ({
                    key: id,
                    label: category_text
                  }))}
                  selected={submissionData?.parent_category || current?.parent_category || 0}
                  onSelect={updateFieldHandler("parent_category")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Service Status</label>
                <Select
                  onChange={updateFieldHandler("service_status")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                  inputProps={{
                    value: submissionData?.service_status || current?.service_status || 0
                  }}
                >
                  <MenuItem value="0" label="Select">Select</MenuItem>
                  <MenuItem value="1">Available</MenuItem>
                  <MenuItem value="2">Not Available</MenuItem>
                </Select>
              </GridItem>
            </GridContainer>
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => handleSave(false)}>
                Save
              </Button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateCRMService;