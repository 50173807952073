import gql from "graphql-tag";

export const GET_MARKETING_GOAL = gql`
  query getMarketingGoal($customer_id: Int!) {
    getMarketingGoal(customer_id: $customer_id) {
      id
      monthly_leads_goal
      cost_per_lead_goal
      average_sale_goal
      lifetime_value
      monthly_leads_needed_breakeven
      leads_needed_increase_budget
      leads_needed_write_review
      leads_needed_referral
    }
  }
`;

export const UPDATE_MARKETING_GOAL = gql`
  mutation(
    $id: Int
    $monthly_leads_goal: Int
    $cost_per_lead_goal: Float
    $average_sale_goal: Int
    $lifetime_value: Float
    $monthly_leads_needed_breakeven: Int
    $leads_needed_increase_budget: Int
    $leads_needed_write_review: Int
    $leads_needed_referral: Int
    $customer_id: Int
  ) {
    updateMarketingGoal(
      id: $id
      monthly_leads_goal: $monthly_leads_goal
      cost_per_lead_goal: $cost_per_lead_goal
      average_sale_goal: $average_sale_goal
      lifetime_value: $lifetime_value
      monthly_leads_needed_breakeven: $monthly_leads_needed_breakeven
      leads_needed_increase_budget: $leads_needed_increase_budget
      leads_needed_write_review: $leads_needed_write_review
      leads_needed_referral: $leads_needed_referral
      customer_id: $customer_id
    ) {
      id
      monthly_leads_goal
      cost_per_lead_goal
      average_sale_goal
      lifetime_value
      monthly_leads_needed_breakeven
      leads_needed_increase_budget
      leads_needed_write_review
      leads_needed_referral
    }
  }
`;
