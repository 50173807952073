import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import SwapScreenshotsReplaceWithS3URL from "components/SwapScreenshotsReplaceWithS3URL";
import CardAvatar from "components/Card/CardAvatar";
import Avatar from "components/Avatar";
import Dropzone from "components/Dropzone";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import CustomInput from "components/CustomInput/CustomInput";
import SaveButton from "components/CustomButtons/Button";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Select from "components/Select/Select";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import AddUpdateProposal from "./AddUpdateProposal";
import LatestActivity from "./LatestActivity";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CustomersDefaultImage from "assets/img/Customers.png";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LinearNoContentWrapper from "components/LinearNoContentWrapper";
import { startCase, snakeCase } from "lodash";
import FormatPhone from "../Format/FormatPhone";

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CRM_CUSTOMERS,
  LIST_CURRENT_SOCIALS,
  UPDATE_CURRENT_SOCIALS,
  LIST_ALL_SOCIALS,
  REMOVE_CUSTOMER_SOCIAL,
  DELETE_PROPOSAL,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_MONTHY_BUDGETS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_TICKETS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  CREATE_CRM_CONTRACT_FROM_PROPOSAL,
  UPDATE_CRM_ACTIVITY,
  CREATE_CRM_PROPOSAL,
  LIST_PHOTOS,
  LIST_ESTIMATES,
  GET_PROPOSAL_STATUS,
  UPDATE_CUSTOMER_EMAIL,
  LIST_CUSTOMERS,
  GET_CUSTOMER_STATUS,
  GET_SIC_CODES,
  GET_NAICS_CODES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES,
  GET_FORM_DATA,
  GET_CALL_DATA,
  GET_FOLLOWING,
  UPDATE_FOLLOWING,
  GET_CRM_SETTINGS,
  LIST_SERVICES,
  LIST_PRODUCT
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { LIST_CUSTOMER_USERS } from "queries/users";

import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CRMProposalDetail = (props) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const [representative, setRepresentative] = React.useState('');
  const [SICCodes, setSICCodes] = useState(null);
  const [NAICSCodes, setNAICSCodes] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);
  const [PeopleStates, setPeopleStates] = useState(null);
  const [PeopleCountries, setPeopleCountries] = useState(null);
  const [PeoplePostalCode, setPeoplePostalCode] = useState(null);
  const [PeopleCity, setPeopleCity] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [Following, setFollowing] = useState(0);
  const [FollowingPeople, setFollowingPeople] = useState(0);
  const [CRMCustomersReferrer, setCRMCustomersReferrer] = useState(null);
  const [ReferrerFound, setReferrerFound] = useState(null);
  const [referrer_name, setreferrer_name] = useState(null);
  const [CRMPersonReferrer, setCRMPersonReferrer] = useState(null);
  const [personreferrer_name, setpersonreferrer_name] = useState(null);
  const [PersonReferrerFound, setPersonReferrerFound] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [OriginalCRMCustomers, setOriginalCRMCustomers] = useState(null);
  const [key, setKey] = useState(0);
  const [CRMActivitySubmission, setCRMActivitySubmission] = useState({ "activity_completed": 1 });
  const [DocumentsubmissionData, setDocumentsubmissionData] = useState({});
  const [ContractsubmissionData, setContractsubmissionData] = useState({});
  const [ProposalsubmissionData, setProposalsubmissionData] = useState({});
  const [GallerysubmissionData, setGallerysubmissionData] = useState({});
  const [JobsubmissionData, setJobsubmissionData] = useState({});
  const [ProposalData, setProposalData] = useState(false);
  const [PersonFound, setPersonFound] = useState(true);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMEstimate, setCRMEstimate] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CallFilterImageSelected, setCallFilterImageSelected] = useState(false);
  const [EmailFilterImageSelected, setEmailFilterImageSelected] = useState(false);
  const [TextFilterImageSelected, setTextFilterImageSelected] = useState(false);
  const [NoteFilterImageSelected, setNoteFilterImageSelected] = useState(false);
  const [OnboardingFilterImageSelected, setOnboardingFilterImageSelected] = useState(false);
  const [MeetingFilterImageSelected, setMeetingFilterImageSelected] = useState(false);
  const [TaskFilterImageSelected, setTaskFilterImageSelected] = useState(false);
  const [DeadlineFilterImageSelected, setDeadlineFilterImageSelected] = useState(false);
  const [UploadFilterImageSelected, setUploadFilterImageSelected] = useState(false);
  const [EstimateFilterImageSelected, setEstimateFilterImageSelected] = useState(false);
  const [CRMTickets, setCRMTickets] = useState(null);
  const [TicketFilterImageSelected, setTicketFilterImageSelected] = useState(false);
  const [JobFilterImageSelected, setJobFilterImageSelected] = useState(false);
  const [PhotoFilterImageSelected, setPhotoFilterImageSelected] = useState(false);
  const [ContractFilterImageSelected, setContractFilterImageSelected] = useState(false);
  const [ProposalFilterImageSelected, setProposalFilterImageSelected] = useState(false);
  const [InboundCallsFilterImageSelected, setInboundCallsFilterImageSelected] = useState(false);
  const [InboundFormsFilterImageSelected, setInboundFormsFilterImageSelected] = useState(false);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [crm_customer_id, setcrm_customer_id] = useState(null);
  const [crm_people_id, setcrm_people_id] = useState(null);
  const [crm_proposal_id, setcrm_proposal_id] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMProducts, setCRMProducts] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [showMorePeople, setshowMorePeople] = useState(false);
  const [showMoreActivity, setshowMoreActivity] = useState(false);
  const [showMoreDocuments, setshowMoreDocuments] = useState(false);
  const [showMorePhotos, setshowMorePhotos] = useState(false);
  const [showMoreContracts, setshowMoreContracts] = useState(false);
  const [showMoreProposals, setshowMoreProposals] = useState(false);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const [user_id, setuser_id] = useState(null);
  const [Hover, setHover] = useState(false);
  const [proposalvalue, setproposalvalue] = useState('');
  const [PersonsubmissionData, setPersonSubmissionData] = useState(null);
  const [CRMOriginalPeople, setCRMOriginalPeople] = useState(null);
  const [B2B, setB2B] = useState(null);
  const [revenue, setrevenue] = useState('');
  const [FormData, setFormData] = useState([]);
  const [CallData, setCallData] = useState([]);
  const [CRMMonthlyBudget, setCRMMonthlyBudget] = useState(null);
  const [AllSocials, setAllSocials] = useState(null);
  const [CRMSocials, setCRMSocials] = useState(null);
  const [highestSocialID, sethighestSocialID] = useState(1);
  const [CRMSettings, setCRMSettings] = useState([]);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const navigate = useNavigate();
  const crm_user_level = me?.readMe?.crm_user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const FormatCurrency = () => {
    let Customer_Revenue = "0";
    if (revenue !== null && revenue !== "")
      Customer_Revenue = revenue;
    let newValue = parseFloat(Customer_Revenue?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setCRMCustomers({
      ...CRMCustomers,
      ["revenue"]: newValue
    });

    setrevenue(newValue);
  }

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null || value === "")
      value = "0";
    return parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateCustomerRevenueValueFieldHandler = (e) => {
    setrevenue(e.target.value);
  };

  const [GetFormDataQuery, { called }] = useLazyQuery(GET_FORM_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormData((data?.GetFormData || []).map(
        ({ form_data: form_data_str, ...rest }) => {
          const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
          const form_data_obj_normalized = {};
          Object.keys(form_data_obj).forEach(key => {
            const title = startCase(key.split("<")[0]);
            if (title === "G Recaptcha Response") {
              form_data_obj_normalized[title] = form_data_obj[key];
              return;
            }
            form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
          });
          return {
            form_data: form_data_obj_normalized,
            ...rest
          };
        }
      ))
    }
  });

  const [GetCallDataQuery, { called: callsqueried }] = useLazyQuery(GET_CALL_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCallData(data?.GetCallData || []);
    }
  });

  //  looks for inbound calls and forms so we can get there info
  let CallIDS = [];
  let FormIDS = [];
  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Form") {
      FormIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Call") {
      CallIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  let { data: SICCodes_data } = useQuery(GET_SIC_CODES);
  let { data: NAICSCodes_data } = useQuery(GET_NAICS_CODES);
  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  useQuery(GET_POSTAL_CODE,
    {
      skip: !CRMCustomers?.office_zip,
      variables: {
        name: CRMCustomers?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !CRMCustomers?.office_city,
      variables: {
        name: CRMCustomers?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  useQuery(GET_POSTAL_CODE,
    {
      skip: !PersonsubmissionData?.zip_code,
      variables: {
        name: PersonsubmissionData?.zip_code
      },
      onCompleted: (data) => {
        setPeoplePostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !PersonsubmissionData?.city,
      variables: {
        name: PersonsubmissionData?.city
      },
      onCompleted: (data) => {
        setPeopleCity(data?.getCity || []);
      }
    }
  );

  let { data: ticket_data } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  useEffect(() => {
    if (called === false && FormIDS?.length) {
      GetFormDataQuery({
        variables: {
          customer_id,
          formIDS: FormIDS?.join(", ")
        }
      })
    }
    if (callsqueried === false && CallIDS?.length) {
      GetCallDataQuery({
        variables: {
          customer_id,
          callIDS: CallIDS?.join(", ")
        }
      })
    }
  }, [FormIDS, CallIDS]);

  const { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: product_data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Following_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_customer_id,
    variables: {
      customer_id: customer_id,
      object_type: "Customer",
      object_id: crm_customer_id,
    }
  });

  let { data: FollowingPeople_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_people_id,
    variables: {
      customer_id: customer_id,
      object_type: "Person",
      object_id: crm_people_id,
    }
  });

  const [submitUpdateCRMFollowingRequest] = useMutation(
    UPDATE_FOLLOWING,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        if (crm_customer_id)
          setFollowing(data?.updateCRMFollowing);
        if (crm_people_id)
          setFollowingPeople(data?.updateCRMFollowing);
      }
    }
  );

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  let { data: Activity_data, refetch: refetchActivity } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      proposal_id: crm_proposal_id
    }
  });

  let { data: Proposal_data, refetch: refetchProposal } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      proposal_id: crm_proposal_id
    }
  });

  let { data: Contract_data, refetch: refetchContracts } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id || !crm_customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      proposal_id: crm_proposal_id
    }
  });

  let { data: Monthly_Budget_data } = useQuery(LIST_MONTHY_BUDGETS, {
    skip: !customer_id || !crm_customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      proposal_id: crm_proposal_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
    }
  });

  let { data: Job_data, refetch: refetchJobs } = useQuery(LIST_JOBS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_Gallery_data, refetch: refetchGallery } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_data, refetch: refetchPhotos } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !crm_customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      id: crm_customer_id ? parseInt(crm_customer_id) : 0,
      status: -1,
      proposal_id: crm_proposal_id
    }
  });

  useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: referrer_name,
      status: -1,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMCustomersReferrer(data?.CRMCustomers || []);
    }
  });

  let { data: Representative_data, refetch: refetchRepresentatives } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data, refetch: refetchDocument } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !crm_customer_id || !crm_proposal_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0,
      id: crm_proposal_id
    }
  });

  let { data: people_data, refetch: refetchPeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !crm_people_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      id: crm_people_id
    }
  });

  let { data: social_data, refetch: refetchSocial } = useQuery(LIST_CURRENT_SOCIALS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id: crm_customer_id
    }
  });

  let { data: all_social_data } = useQuery(LIST_ALL_SOCIALS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    }
  });


  useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || (!personreferrer_name && !PersonsubmissionData?.referrer_id),
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: personreferrer_name,
      id: PersonsubmissionData?.referrer_id ? PersonsubmissionData?.referrer_id : null,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMPersonReferrer(data?.listCustomerEmails || []);
    }
  });  

  let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  let { data: ProposalStatus_data } = useQuery(GET_PROPOSAL_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id
    }
  });

  ProposalStatus_data = ProposalStatus_data?.getProposalStatus || [];

  let ProposalStatuses = [];

  if (ProposalStatus_data) {
    ProposalStatuses.push({ label: "select", value: 0 });
    ProposalStatus_data.forEach(obj => {
      ProposalStatuses.push({ label: obj.proposal_status_text, value: obj.id })
    })
  }

  let { data: CustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id || !CRMCustomers?.status,
    variables: {
      customer_id: customer_id,
      status: CRMCustomers?.status === 1 ? 1 : 0
    }
  });

  CustomerStatus_data = CustomerStatus_data?.getCustomerStatus || [];

  let CustomerStatuses = [];

  if (CustomerStatus_data) {
    CustomerStatuses.push({ label: "select", value: 0 });
    CustomerStatus_data.forEach(obj => {
      CustomerStatuses.push({ label: obj.customer_status_text, value: obj.id })
    })
  }

  useEffect(() => {
    if (CRMPeople && !PersonsubmissionData) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setPersonSubmissionData({
          ...CurrentPerson,
          ["office_phone_number"]: FormatPhone(CurrentPerson?.office_phone_number),
          ["cell_phone_number"]: FormatPhone(CurrentPerson?.cell_phone_number)
        })
      })
    }

    if (CRMPeople && !CRMOriginalPeople) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setCRMOriginalPeople({
          ...CurrentPerson,
          ["office_phone_number"]: FormatPhone(CurrentPerson?.office_phone_number),
          ["cell_phone_number"]: FormatPhone(CurrentPerson?.cell_phone_number),
        })
      })
    }

    if (CRMPersonReferrer?.length && PersonsubmissionData?.referrer_id && !PersonsubmissionData?.referrer_name) {
      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        setPersonSubmissionData({
          ...CurrentPerson,
          ["referrer_name"]: CurrentPerson?.referrer_id ? CRMPersonReferrer?.filter((CurrentPersonReferrer) =>
            CurrentPersonReferrer?.id === CurrentPerson?.referrer_id)?.map((CurrentPersonReferrer) => { setPersonReferrerFound(true); return (CurrentPersonReferrer?.first_name + " " + CurrentPersonReferrer?.last_name) })?.join("") : ""
        });

        setCRMOriginalPeople({
          ...CurrentPerson,
          ["referrer_name"]: CurrentPerson?.referrer_id ? CRMPersonReferrer?.filter((CurrentPersonReferrer) =>
            CurrentPersonReferrer?.id === CurrentPerson?.referrer_id)?.map((CurrentPersonReferrer) => { setPersonReferrerFound(true); return (CurrentPersonReferrer?.first_name + " " + CurrentPersonReferrer?.last_name) })?.join("") : ""
        });
      })
    }

    setFollowing(Following_data?.getFollowing);
    setFollowingPeople(FollowingPeople_data?.getFollowing || []);
  }, [Following_data, FollowingPeople_data]);


  useEffect(() => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);

    if (urlParams.get("id")) {
      setcrm_customer_id(parseInt(urlParams.get("id")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    if (urlParams.get("peopleid")) {
      setcrm_people_id(parseInt(urlParams.get("peopleid")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    if (urlParams.get("proposalid")) {
      setcrm_proposal_id(parseInt(urlParams.get("proposalid")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    setuser_id(me?.readMe?.id);

    GetCRMSettings();
    setCRMPeople(people_data?.listCustomerEmails || []);

    setCRMAssignedToUsers(users?.listCustomerUsers || []);

    setCRMCustomers({
      ...data?.CRMCustomers[0], ["office_phone_number"]: FormatPhone(data?.CRMCustomers[0]?.office_phone_number),
      ["referrer_name"]: data?.CRMCustomers[0]?.referrer_id ? CRMCustomersReferrer?.filter(CurrentCustomer =>
        CurrentCustomer?.id === data?.CRMCustomers[0]?.referrer_id)?.map((CurrentCustomer) => { setReferrerFound(true); return CurrentCustomer?.business_name })?.join("") : ""
    });

    setCRMSocials(social_data?.listCurrentSocials || []);
    setAllSocials(all_social_data?.listAllSocials || []);

    setSICCodes(SICCodes_data?.getSICCodes || []);
    setNAICSCodes(NAICSCodes_data?.getNAICSCodes || []);
    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);
    setPeopleStates(States_data?.getStates || []);
    setPeopleCountries(Country_data?.getCountries || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMProducts(product_data?.ListProduct || []);
    setCRMActivity(Activity_data?.ListActivity || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMMonthlyBudget(Monthly_Budget_data?.listMonthlyBudgets || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMTickets(ticket_data?.ListTickets || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");
  }, [data, Activity_data, Proposal_data, Contract_data, Job_data, Photo_Gallery_data, Representative_data,
    document_data, people_data, users, service_data, product_data, budget_data]);

  useEffect(() => {
    if (CRMProposal && CRMProducts?.length && !ProposalData) {
      let ProposalsubmissionDataClone = {};

      CRMProposal?.filter((CurrentProposal) => CurrentProposal?.id === crm_proposal_id)?.map((CurrentProposal) => {
        ProposalsubmissionDataClone = Object.assign({}, CurrentProposal);
        ProposalsubmissionDataClone.Budgets = {};

        CRMMonthlyBudget?.map((CurrentBudget, i) => {
          let tempid = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
          if (tempid == '')
            tempid = null;
          else
            tempid = parseInt(tempid);

          let product_id = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
          if (product_id == '')
            product_id = null;
          else
            product_id = parseInt(product_id);

          let local_id = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");

          ProposalsubmissionDataClone.Budgets[i] = Object.assign({}, {
            "product_name": CurrentBudget?.product_name, "LocalID": local_id?.toLowerCase()?.replaceAll(" ", ""), "monthly_budget": parseFloat(CurrentBudget?.monthly_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
              style: 'decimal',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
            "budget_cycles_product_id": tempid, proposal_id: crm_proposal_id, product_id: product_id
          })
        })

        setProposalsubmissionData(ProposalsubmissionDataClone)
        setProposalData(true);
        setRepresentative(CurrentProposal?.representative_id);
      })
    }
  }, [CRMProposal, ProposalData, CRMProducts, CRMMonthlyBudget]);

  const handleOpen = () => {
    setUpdatedCustomerEmail({});
  };

  const handleClose = () => () => {
    setUpdatedCustomerEmail(null);
    refetchPeople();
  };

  const social_columns = [
    {
      Header: "Social",
      accessor: "social_channel"
    },
    {
      Header: "Remove"
    }
  ];

  function updateSocialssocial_channel_url(index, e) {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_url: e.target.value });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const updateSocialsocial_channel_id = (index) => e => {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_id: e });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const AddSocial = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestSocialID, social_channel_id: '', social_channel_url: '', people_id: null, crm_customer_id: crm_customer_id })
    sethighestSocialID(highestSocialID + 1)
    setCRMSocials({ ...CRMSocials, [Object.keys(CRMSocials)?.length]: NewArray });
  }

  const [updateSocials] = useMutation(
    UPDATE_CURRENT_SOCIALS,
    {
      skip: !state.customers?.selected?.id,
      skip: !CRMSocials
    });

  const [RemoveCustomerSocial] = useMutation(
    REMOVE_CUSTOMER_SOCIAL
  );

  const RemoveSocial = (key) => e => {
    RemoveCustomerSocial({
      fetchPolicy: "no-cache",
      variables: {
        customer_id: state.customers?.selected?.id,
        id: key
      },
      onCompleted: () => {
        setCRMSocials(Object.keys(CRMSocials)?.map((Social, index) => {
          if (CRMSocials[index].id !== key)
            return CRMSocials[index]
        })?.filter(Social => Social !== undefined))

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    });
  };

  const classes = useStyles();

  const updateFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.length) {
        CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.map((CurrentCustomer) => {
          setCRMCustomers({
            ...CRMCustomers,
            [key]: e.target.value,
            ["referrer_name"]: CurrentCustomer?.business_name
          });

          setreferrer_name(e.target.value);
          setReferrerFound(true);
        })
      }
      else {
        setCRMCustomers({
          ...CRMCustomers,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        setreferrer_name(e.target.value);
        setReferrerFound(false);
      }
    } else if (key === "assigned_to" || key === "customer_status") {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e
      });
    }
    else {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.value
      });
    }
  };

  const updateSICFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateCountryFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateReferrerHandler = (key, id, name) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: id,
      ["referrer_name"]: name
    });

    setReferrerFound(true);
  };

  const updatePersonReferrerHandler = (key, id, name) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: id,
      ["referrer_name"]: name
    });

    setPersonReferrerFound(true);
  };

  const updateCityHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePersonCountryFieldHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonStateHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonPostalCodeHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updatePersonCityHandler = (key, e) => {
    setPersonSubmissionData({
      ...PersonsubmissionData,
      [key]: e
    });
  };

  const updateNAICSFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const [submitUpdateCRMPersonRequest] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: () => {
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
      }
    }
  );

  const updateProposalFieldHandler = (key) => e => {
    let BudgetFound = false;

    if (ProposalsubmissionData?.Budgets !== undefined) {
      Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
        if (key?.split("_")[0] === ProposalsubmissionData?.Budgets[index]?.LocalID)
          BudgetFound = true;
      })
    }

    if (key === "proposal_status" && typeof e === 'string') {
      ProposalStatuses?.map((CurrentProposal) => {
        if (CurrentProposal?.label === e) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            [key]: CurrentProposal?.value
          });
        }
      })
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "job_id"
      || key === "photo_gallery_id" || key === "proposal_status") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        [key]: e
      });
    } else if (key?.indexOf("monthlybudget") > -1) {
      if (ProposalsubmissionData?.Budgets === undefined || BudgetFound === false) {
        let tempid = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (tempid == '')
          tempid = null;
        else
          tempid = parseInt(tempid);

        let product_id = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (product_id == '')
          product_id = null;
        else
          product_id = parseInt(product_id);

        let product_name = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        setProposalsubmissionData({
          ...ProposalsubmissionData,
          ["Budgets"]: { [ProposalsubmissionData?.Budgets ? Object.keys(ProposalsubmissionData?.Budgets)?.length : 0]: { "product_name": product_name, "LocalID": key?.split("_")[0], "monthly_budget": e.target.value, "budget_cycles_product_id": tempid, proposal_id: crm_proposal_id, product_id: product_id }, ...ProposalsubmissionData?.Budgets }
        });
      } else if (BudgetFound) {
        Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => {
          if (ProposalsubmissionData?.Budgets[index]?.LocalID === key?.split("_")[0]) {
            let NewArray = Object.assign({}, { "product_name": ProposalsubmissionData?.Budgets[index]?.product_name, "LocalID": ProposalsubmissionData?.Budgets[index]?.LocalID, "monthly_budget": e.target.value, "budget_cycles_product_id": ProposalsubmissionData?.Budgets[index]?.budget_cycles_product_id, proposal_id: ProposalsubmissionData?.Budgets[index]?.proposal_id, product_id: ProposalsubmissionData?.Budgets[index]?.product_id })
            setProposalsubmissionData({
              ...ProposalsubmissionData,
              ["Budgets"]: { [index]: NewArray, ...Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, i) => { if (index !== i) return ProposalsubmissionData?.Budgets[i] })?.filter(CurrentBudget => CurrentBudget !== undefined) }
            });
          }
        })
      }
    }
    else {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updatePeopleNameFieldHandler = (value, object) => {
    if (object === "Contract") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setContractsubmissionData({
            ...ContractsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setDocumentsubmissionData({
            ...DocumentsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setGallerysubmissionData({
            ...GallerysubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setJobsubmissionData({
            ...JobsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setCRMActivitySubmission({
            ...CRMActivitySubmission,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    }
  };

  const updatePeopleFieldHandler = (key, object) => e => {
    if (object === "Contract") {
      setContractsubmissionData({
        ...ContractsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    }
  };

  const updatePersonFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMPersonReferrer?.filter(CurrentPerson => CurrentPerson?.business_name === e.target.value)?.length) {
        CRMPersonReferrer?.filter(CurrentPerson => CurrentPerson?.business_name === e.target.value)?.map((CurrentPerson) => {
          setPersonSubmissionData({
            ...PersonsubmissionData,
            [key]: e.target.value,
            ["referrer_name"]: CurrentPerson?.first_name + " " + CurrentPerson?.last_name
          });

          CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
            CurrentPerson[key] = e.target.value;
            CurrentPerson["referrer_name"] = CurrentPerson?.first_name + " " + CurrentPerson?.last_name;
          });

          setpersonreferrer_name(e.target.value);
          setPersonReferrerFound(true);
        })
      }
      else {
        setPersonSubmissionData({
          ...PersonsubmissionData,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
          CurrentPerson[key] = e.target.value;
          CurrentPerson["referrer_id"] = 0;
        });

        setpersonreferrer_name(e.target.value);
        setPersonReferrerFound(false);
      }
    } else if (key === "assigned_to") {
      setPersonSubmissionData({
        ...PersonsubmissionData,
        [key]: e
      });

      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        CurrentPerson[key] = e;
      });
    }
    else {
      setPersonSubmissionData({
        ...PersonsubmissionData,
        [key]: e.target.value
      });

      CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
        CurrentPerson[key] = e.target.value;
      });
    }
  };

  const updateActivityFieldHandler = (key) => e => {
    if (key === "activity_completed") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (key === "activity_date") {
      setCRMActivitySubmission({
        ...omit(CRMActivitySubmission, ["activity_completed"]),
        [key]: e.target.value,
        ["activity_completed"]: 0
      });
    } else if (key === "assigned_to" || key === "representative_id" || key === "activity_notes") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e
      });
    } else {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.value
      });
    }
  };

  const updatePersonDatabase = async (profile_image, lead_status, lead_type) => {
    if (PersonsubmissionData?.first_name === CRMOriginalPeople?.first_name &&
      PersonsubmissionData?.last_name === CRMOriginalPeople?.last_name &&
      PersonsubmissionData?.email === CRMOriginalPeople?.email &&
      PersonsubmissionData?.cell_phone_number === CRMOriginalPeople?.cell_phone_number &&
      PersonsubmissionData?.address_line_one === CRMOriginalPeople?.address_line_one &&
      PersonsubmissionData?.address_line_two === CRMOriginalPeople?.address_line_two &&
      PersonsubmissionData?.city === CRMOriginalPeople?.city &&
      PersonsubmissionData?.country === CRMOriginalPeople?.country &&
      PersonsubmissionData?.state === CRMOriginalPeople?.state &&
      PersonsubmissionData?.zip_code === CRMOriginalPeople?.zip_code &&
      PersonsubmissionData?.list_name === CRMOriginalPeople?.list_name &&
      PersonsubmissionData?.opt_in_text === CRMOriginalPeople?.opt_in_text &&
      PersonsubmissionData?.opt_in_voice === CRMOriginalPeople?.opt_in_voice &&
      PersonsubmissionData?.opt_int_email === CRMOriginalPeople?.opt_int_email &&
      PersonsubmissionData?.unsubscribe === CRMOriginalPeople?.unsubscribe &&
      PersonsubmissionData?.contact_business_only === CRMOriginalPeople?.contact_business_only &&
      PersonsubmissionData?.referrer_id === CRMOriginalPeople?.referrer_id &&
      PersonsubmissionData?.assigned_to === CRMOriginalPeople?.assigned_to &&
      (!profile_image && !lead_status && !lead_type)) {
      return
    }

    if (PersonsubmissionData || (profile_image || lead_status || lead_type)) {
      await submitUpdateCRMPersonRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(PersonsubmissionData, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text", "referrer_name"]),
            cell_phone_number: PersonsubmissionData?.cell_phone_number !== undefined ? PersonsubmissionData?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
            first_name: PersonsubmissionData?.first_name ? PersonsubmissionData?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
            id: crm_people_id,
            profile_image: profile_image ? profile_image : null,
            users_id: user_id,
            crm_representative_id: representative ? representative : 1,
            lead_type: lead_type ? lead_type : null,
            lead_status: lead_status ? lead_status : null
          }
        }
      });

      await submitUpdateCRMActivity({
        variables: {
          customer_id,
          ActivityData: {
            ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "key"]),
            users_id: user_id,
            people_id: crm_people_id,
            activity_type: "Person",
            activity_description: "Updated Person " + PersonsubmissionData?.first_name + " " + PersonsubmissionData?.last_name,
            representative_id: PersonsubmissionData?.representative_id,
            crm_customers_id: crm_customer_id ? crm_customer_id : null,
            activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const CreateContractfromProposal = async (ProposalID) => {
    await submitCreateCRMContractFromProposal({
      variables: {
        customer_id,
        proposal_id: ProposalID
      }
    })
  }

  const [submitCreateCRMContractFromProposal] = useMutation(CREATE_CRM_CONTRACT_FROM_PROPOSAL,
    {
      onCompleted: async (data) => {
        if (data?.createCRMContractFromProposal !== -1) {
          setTimeout(() => navigate("/admin/crmcontractdetail?customer=" + customer_id + "&id=" + crm_customer_id + "&peopleid=null&contractid=" + data?.createCRMContractFromProposal), 3000);
        }
      }
    });

  const updateProposalDatabase = async () => {
    if (!ProposalsubmissionData?.proposal_name) {
      alert("Proposal Name is Required");
      return false;
    }

    if (!ProposalsubmissionData?.proposal_url) {
      alert("Proposal File is Required");
      return false;
    }

    if (!ProposalsubmissionData?.Budgets) {
      alert("Product Budgets are Required");
      return false;
    }

    if (ProposalsubmissionData?.products?.filter((CurrentProduct) => CurrentProduct)?.length !== Object.keys(ProposalsubmissionData?.Budgets)?.filter((CurrentBudget) => CurrentBudget)?.length) {
      alert("All Product Budgets are Required");
      return false;
    }

    if (ProposalsubmissionData) {
      await submitCreateCRMProposal({
        variables: {
          customer_id,
          ProposalData: {
            ...omit(ProposalsubmissionData, ["__typename", "activity_completed", "first_name", "last_name", "people_name", "proposal_status"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            proposal_value: ProposalsubmissionData?.proposal_value ? ProposalsubmissionData?.proposal_value?.replace(/[^0-9.]+/g, "") : "",
            services: ProposalsubmissionData?.services ? ProposalsubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: ProposalsubmissionData?.products ? ProposalsubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            proposal_status: typeof ProposalsubmissionData?.proposal_status === 'string' ? parseInt(ProposalStatuses?.map((CurrentProposal) => { if (CurrentProposal?.label === ProposalsubmissionData?.proposal_status) return CurrentProposal?.value })?.filter(CurrentProposal => CurrentProposal !== undefined)?.join("")) : ProposalsubmissionData?.proposal_status ? ProposalsubmissionData?.proposal_status : null,
            Budgets: Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, index) => { return omit(ProposalsubmissionData?.Budgets[index], "LocalID") })
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateDatabase = async (profile_image, customer_status) => {
    if (CRMCustomers?.business_name === OriginalCRMCustomers?.business_name &&
      CRMCustomers?.office_phone_number === OriginalCRMCustomers?.office_phone_number &&
      CRMCustomers?.office_address_line_one === OriginalCRMCustomers?.office_address_line_one &&
      CRMCustomers?.office_address_line_two === OriginalCRMCustomers?.office_address_line_two &&
      CRMCustomers?.office_city === OriginalCRMCustomers?.office_city &&
      CRMCustomers?.office_country === OriginalCRMCustomers?.office_country &&
      CRMCustomers?.office_state === OriginalCRMCustomers?.office_state &&
      CRMCustomers?.office_zip === OriginalCRMCustomers?.office_zip &&
      CRMCustomers?.website === OriginalCRMCustomers?.website &&
      CRMCustomers?.industry === OriginalCRMCustomers?.industry &&
      CRMCustomers?.revenue === OriginalCRMCustomers?.revenue &&
      CRMCustomers?.sic_code === OriginalCRMCustomers?.sic_code &&
      CRMCustomers?.naics_code === OriginalCRMCustomers?.naics_code &&
      CRMCustomers?.employees === OriginalCRMCustomers?.employees &&
      CRMCustomers?.established === OriginalCRMCustomers?.established &&
      !profile_image && !customer_status) {
      return
    }

    if (customer_status !== OriginalCRMCustomers?.customer_status && OriginalCRMCustomers?.customer_status) {
      if (!window.confirm("Are you sure you want to switch the Customer Status?"))
        return
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status", "referrer_name"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
            status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
            customer_status: customer_status ? customer_status : null,
            profile_image: profile_image ? profile_image : null,
            users_id: user_id,
            lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (CRMCustomers?.length) {
    csvData.push(
      ...CRMCustomers.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: () => {
        LoadNewNotifications();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
      }
    }
  );

  const [submitUpdateCRMActivity] = useMutation(
    UPDATE_CRM_ACTIVITY,
    {
      onCompleted: async () => {
        setKey(key + 1);
        LoadNewNotifications();
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();
      }
    }
  );

  const [submitCreateCRMProposal] = useMutation(
    CREATE_CRM_PROPOSAL,
    {
      onCompleted: async (ProposalData) => {
        // If user added proposal activity notes  
        let newActivityNotes = '';

        if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
          newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
        }

        if (CRMActivitySubmission && ProposalData?.createCRMProposal?.update_status !== 1) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_description", "activity_notes"]),
                users_id: user_id,
                activity_type: "Proposal",
                activity_description: CRMCustomers?.business_name ? "Updated Proposal " + ProposalsubmissionData?.proposal_name + " for " + CRMCustomers?.business_name : "Updated Proposal " + ProposalsubmissionData?.proposal_name + " for " + PersonsubmissionData?.first_name + " " + PersonsubmissionData?.last_name,
                assigned_to: ProposalsubmissionData?.assigned_to,
                proposal_id: ProposalData?.createCRMProposal?.id,
                representative_id: ProposalData?.createCRMProposal?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        } else {
          LoadNewNotifications();
        }
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_PROPOSAL,
    {
      onCompleted: () => {
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEsimates();

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  let ShownActivityCount = 0;

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <div>
            <Card>
              <CardBody>
                <div style={{ display: "block" }}>
                  <div style={{ float: "left" }}>
                    <label
                      style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}
                    ><DescriptionOutlinedIcon /> {ProposalsubmissionData?.proposal_name || ""}</label>
                  </div>
                </div>
                {crm_user_level === "crm_admin" && (
                  <Tooltip title="Delete Proposal" placement="top">
                    <Button style={{ color: Hover === true ? "red" : "#000", float: "right", backgroundColor: "#FFF", marginTop: "13px" }} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        submitDeleteRequest({ skip: !customer_id, variables: { customer_id: customer_id, id: ProposalsubmissionData?.id } });
                        navigate("/admin/proposals?customer=" + customer_id);
                      }
                    }}>
                      <FontAwesomeIcon size="lg" icon={Hover ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} />
                    </Button>
                  </Tooltip>
                )}
              </CardBody>
            </Card>
            <GridContainer marginTop="20px">
              <GridItem xs={12} sm={12} md={4}>
                {crm_customer_id ? (
                  <Card profile>
                    <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                      <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmcustomerdetail") === -1 && window.location.href.indexOf("crmleaddetail") === -1 ? <a href={`/admin/crm${CRMCustomers?.status === 1 ? `customer` : `lead`}detail?customer=${customer_id}&id=${crm_customer_id}`} >{CRMCustomers?.status === 1 ? <HomeWorkOutlinedIcon /> : <FlagOutlinedIcon />} View Profile</a> : <div style={{ visibility: "hidden" }}>{CRMCustomers?.status === 1 ? <HomeWorkOutlinedIcon /> : <FlagOutlinedIcon />} View Profile</div>}</div>
                      <CardAvatar profile style={{ display: "inline-flex", alignItems: "right", float: "right !important" }}>
                        <Dropzone
                          path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                          accept={{
                            'image/jpeg': [".jpeg"],
                            'image/png': [".png"]
                          }}
                          onChange={(value) => {
                            updateFieldHandler("profile_image")({ target: { value } });
                            updateDatabase(value);
                          }}
                        >
                          {() => (
                            <Avatar
                              url={CRMCustomers?.profile_image || CustomersDefaultImage}
                              size={130}
                            />
                          )}
                        </Dropzone>
                      </CardAvatar>

                      <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                        onClick={
                          async () => {
                            if (Following > 0) {
                              await submitUpdateCRMFollowingRequest({
                                variables: {
                                  customer_id: customer_id,
                                  object_type: "Customer",
                                  object_id: crm_customer_id,
                                  add: 0,
                                  id: Following
                                }
                              });
                            } else {
                              await submitUpdateCRMFollowingRequest({
                                variables: {
                                  customer_id: customer_id,
                                  object_type: "Customer",
                                  object_id: crm_customer_id,
                                  add: 1,
                                }
                              });
                            }
                          }} >{Following > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                    <CardBody>
                      <h6 className={classes.cardCategory}>
                        Ideal image upload size is 400 x 400
                      </h6>
                      <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                      <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Business Name</b></label>
                      <input
                        id="business_name"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.business_name || ""}
                        onChange={updateFieldHandler("business_name")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", textAlign: "center" }}
                      />
                      <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Address</b></label>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Line 1</label>
                        <input
                          id="office_address_line_one"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_address_line_one || ""}
                          onChange={updateFieldHandler("office_address_line_one")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Line 2</label>
                        <input
                          id="office_address_line_two"
                          maxLength={255}
                          type="text"
                          value={CRMCustomers?.office_address_line_two || ""}
                          onChange={updateFieldHandler("office_address_line_two")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>City</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="office_city"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updateCityHandler("office_city", newValue.key);
                            }}
                            value={CRMCustomers?.office_city ? { key: CRMCustomers?.office_city, label: CRMCustomers?.office_city } :
                              { key: "", label: "" }}
                            options={City?.map(({ name }) => ({
                              key: name,
                              label: name
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { updateFieldHandler("office_city")(e); }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>State</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="office_state"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updateStateHandler("office_state", newValue.key);
                            }}
                            value={CRMCustomers?.office_state ? { key: CRMCustomers?.office_state, label: CRMCustomers?.office_state } :
                              { key: "", label: "" }}
                            options={States?.map(({ name }) => ({
                              key: name,
                              label: name
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { if (e.target.value === "") { updateFieldHandler("office_state")(e); } }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Zip</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="office_zip"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updatePostalCodeHandler("office_zip", newValue.key);
                            }}
                            value={CRMCustomers?.office_zip ? { key: CRMCustomers?.office_zip, label: CRMCustomers?.office_zip } :
                              { key: "", label: "" }}
                            options={PostalCode?.map(({ name }) => ({
                              key: name,
                              label: name
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { updateFieldHandler("office_zip")(e); }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Country</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="office_country"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updateCountryFieldHandler("office_country", newValue.key);
                            }}
                            value={CRMCustomers?.office_country ? { key: CRMCustomers?.office_country, label: CRMCustomers?.office_country } :
                              { key: "", label: "" }}
                            options={Countries?.map(({ name }) => ({
                              key: name,
                              label: name
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { if (e.target.value === "") { updateFieldHandler("office_country")(e); } }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Phone</label>
                        <input
                          id="office_phone_number"
                          maxLength={255}
                          type="text"
                          value={FormatPhone(CRMCustomers?.office_phone_number) || ""}
                          onChange={updateFieldHandler("office_phone_number")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", textAlign: "left", width: "22%" }}>Website</label>
                        <input
                          id="website"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.website || ""}
                          onChange={updateFieldHandler("website")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left" }}>Industry</label>
                        <input
                          id="industry"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.industry || ""}
                          onChange={updateFieldHandler("industry")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left" }}>Revenue</label>
                        <input
                          id="revenue"
                          maxLength={15}
                          type="text"
                          value={revenue === '' && CRMCustomers?.revenue !== undefined && CRMCustomers?.revenue !== '' ? FormatCurrencyWithoutUpdatingState(CRMCustomers?.revenue) : revenue}
                          onBlur={() => {
                            FormatCurrency();
                            if (CRMCustomers?.revenue)
                              updateDatabase();
                          }}
                          onFocus={() => {
                            setCRMCustomers({
                              ...CRMCustomers,
                              ["revenue"]: ''
                            });
                            setrevenue('');
                          }}
                          onChange={(e) => { updateFieldHandler("revenue", e); updateCustomerRevenueValueFieldHandler(e) }}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>SIC Code</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="sic_code"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updateSICFieldHandler("sic_code", newValue.key);
                            }}
                            value={SICCodes?.filter((CurrentSICCode) => CurrentSICCode?.sic_code === CRMCustomers?.sic_code)?.map((CurrentSICCode) => {
                              return { key: CurrentSICCode?.sic_code, label: CurrentSICCode?.sic_code + " - " + CurrentSICCode?.description }
                            })[0] || ""}
                            options={SICCodes?.map(({ sic_code, description }) => ({
                              key: sic_code,
                              label: sic_code + " - " + description
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { if (e.target.value === "") { updateFieldHandler("sic_code")(e); } }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>NAICS Code</label>
                        <Stack spacing={2}>
                          <Autocomplete
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            disableClearable
                            id="naics_code"
                            style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                            onChange={(event, newValue) => {
                              updateNAICSFieldHandler("naics_code", newValue.key);
                            }}
                            value={NAICSCodes?.filter((CurrentNAICSCode) => CurrentNAICSCode?.naics_code === CRMCustomers?.naics_code)?.map((CurrentNAICSCode) => {
                              return { key: CurrentNAICSCode?.naics_code, label: CurrentNAICSCode?.naics_code + " - " + CurrentNAICSCode?.title }
                            })[0] || ""}
                            options={NAICSCodes?.map(({ naics_code, title }) => ({
                              key: naics_code,
                              label: naics_code + " - " + title
                            })) || []}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              const { inputValue } = params;

                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (option === "")
                                return "";
                              // Regular option
                              return option.label;
                            }}
                            renderOption={(props, option) => <li {...props}>{option.label}</li>}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { textAlign: "center", width: "100%" }
                                }}
                                onChange={e => { if (e.target.value === "") { updateFieldHandler("naics_code")(e); } }}
                                onBlur={() => updateDatabase()}
                                maxLength={255}
                              />
                            )}
                          />
                        </Stack>
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left" }}>Employees</label>
                        <input
                          id="employees"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.employees || ""}
                          onChange={updateFieldHandler("employees")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both" }}></div>
                      <div style={{ display: "block" }}>
                        <label style={{ marginTop: "15px", float: "left" }}>Established</label>
                        <input
                          id="established"
                          maxLength={2000}
                          type="text"
                          value={CRMCustomers?.established || ""}
                          onChange={updateFieldHandler("established")}
                          onBlur={() => updateDatabase()}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                        />
                      </div>
                      <div style={{ clear: "both", marginBottom: "20px" }}></div>
                      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>{CRMCustomers?.status === 1 ? "Customer" : "Lead"} Status:</label>
                      <Select
                        selected={CRMCustomers?.customer_status || 0}
                        onSelect={(value) => {
                          updateFieldHandler("customer_status")(value);
                          if (value !== "select") {
                            updateDatabase("", value);
                          }
                        }}
                        options={CustomerStatuses?.map(({ label, value }) => ({
                          key: value,
                          label: label
                        }))}
                        style={{ marginBottom: 20, float: "left", height: "40px" }}
                      />
                      <div style={{ clear: "both", marginBottom: "20px" }}></div>
                      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Lead Status:</label>
                      <Select
                        selected={CRMCustomers?.customer_status || 0}
                        onSelect={(value) => {
                          updateFieldHandler("customer_status")(value);
                          if (value !== "select") {
                            updateDatabase("", value)
                          }
                        }}
                        options={CustomerStatuses?.map(({ label, value }) => ({
                          key: value,
                          label: label
                        }))}
                        style={{ marginBottom: 20, float: "left", height: "40px" }}
                      />
                      <div style={{ clear: "both", marginBottom: "20px" }}></div>

                      <div>
                        <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                        <Select
                          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                            key: id,
                            label: first_name + " " + last_name
                          }))}
                          selected={CRMCustomers?.assigned_to || ""}
                          onSelect={async (value) => {
                            if (value !== OriginalCRMCustomers?.assigned_to && OriginalCRMCustomers?.assigned_to) {
                              if (!window.confirm("Are you sure you want to switch the Assigned To?"))
                                return
                            }

                            updateFieldHandler("assigned_to")(value);

                            await submitUpdateCRMRequest({
                              variables: {
                                customer_id,
                                BusinessData: {
                                  ...omit(CRMCustomers, ["__typename", "update_time", "last_name", "first_name", "status", "revenue", "lead_status", "referrer_name"]),
                                  id: crm_customer_id,
                                  assigned_to: value,
                                  revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
                                  status: CRMCustomers?.status === undefined ? "0" : `${CRMCustomers?.status}`,
                                  lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
                                }
                              }
                            });

                            const notify = options => {
                              const key = enqueueSnackbar(options?.text, {
                                variant: options?.variant,
                                persist: true
                              });
                              setTimeout(() => {
                                closeSnackbar(key);
                              }, options?.delay || 2000);
                            }
                            notify({
                              text: "Information has been successfully submitted",
                              variant: "success"
                            });
                          }}
                          style={{ marginBottom: 20, height: "40px", float: "left" }}
                        />
                      </div>
                      <div style={{ clear: "both", marginBottom: "20px" }}></div>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="referrer_id"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(e, newValue) => {
                            updateReferrerHandler("referrer_id", newValue.key, newValue.label);
                          }}
                          value={CRMCustomers?.referrer_name ? { key: CRMCustomers?.referrer_id, label: CRMCustomers?.referrer_name } :
                            { key: "", label: "" }}
                          options={CRMCustomersReferrer?.map(({ business_name, id }) => ({
                            key: id,
                            label: business_name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { updateFieldHandler("referrer_name")(e); }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                      {CRMCustomers?.referrer_name && !ReferrerFound ?
                        <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                      <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    </CardBody>
                  </Card>
                ) : ""}
                {/* PEOPLE */}
                {!crm_customer_id && crm_people_id !== null && (
                  CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)?.map((CurrentPerson) => {
                    return (
                      <>
                        <Card profile>
                          <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                            <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmpersondetail") === -1 ? <a href={`/admin/crmpersondetail?customer=${customer_id}&id=${crm_customer_id ? crm_customer_id : `null`}&peopleid=${crm_people_id}`} ><PermIdentityOutlinedIcon /> View Profile</a> : <div style={{ visibility: "hidden" }}><PermIdentityOutlinedIcon /> View Profile</div>}</div>
                            <CardAvatar profile>
                              <Dropzone
                                path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                                accept={{
                                  'image/jpeg': [".jpeg"],
                                  'image/png': [".png"]
                                }}
                                onChange={(value) => {
                                  updatePersonFieldHandler("profile_image")({ target: { value } });
                                  updatePersonDatabase(value, "");
                                }}
                                setuploadPercentage={setuploadPercentage}
                              >
                                {() => (
                                  <Avatar
                                    url={CurrentPerson?.profile_image || ""}
                                    size={130}
                                  />
                                )}
                              </Dropzone>
                            </CardAvatar>
                            <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                              onClick={
                                async () => {
                                  if (FollowingPeople > 0) {
                                    await submitUpdateCRMFollowingRequest({
                                      variables: {
                                        customer_id: customer_id,
                                        object_type: "Person",
                                        object_id: crm_people_id,
                                        add: 0,
                                        id: FollowingPeople
                                      }
                                    });
                                  } else {
                                    await submitUpdateCRMFollowingRequest({
                                      variables: {
                                        customer_id: customer_id,
                                        object_type: "Person",
                                        object_id: crm_people_id,
                                        add: 1,
                                      }
                                    });
                                  }
                                }} >{FollowingPeople > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                          <CardBody>
                            <h6 className={classes.cardCategory}>
                              Ideal image upload size is 400 x 400
                            </h6>
                            <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>First Name</label>
                              <input
                                id="first_name"
                                maxLength={255}
                                type="text"
                                value={PersonsubmissionData?.first_name || CurrentPerson?.first_name || ""}
                                onChange={updatePersonFieldHandler("first_name")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>Last Name</label>
                              <input
                                id="last_name"
                                maxLength={255}
                                type="text"
                                value={PersonsubmissionData?.last_name || CurrentPerson?.last_name || ""}
                                onChange={updatePersonFieldHandler("last_name")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>Email</label>
                              <input
                                id="email"
                                maxLength={255}
                                type="text"
                                value={PersonsubmissionData?.email || CurrentPerson?.email || ""}
                                onChange={updatePersonFieldHandler("email")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>Phone</label>
                              <input
                                id="cell_phone_number"
                                maxLength={255}
                                type="text"
                                value={FormatPhone(PersonsubmissionData?.cell_phone_number) || FormatPhone(CurrentPerson?.cell_phone_number) || ""}
                                onChange={updatePersonFieldHandler("cell_phone_number")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Address</b></label>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>Line 1</label>
                              <input
                                id="address_line_one"
                                maxLength={255}
                                type="text"
                                value={PersonsubmissionData?.address_line_one || CurrentPerson?.address_line_one || ""}
                                onChange={updatePersonFieldHandler("address_line_one")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "15px", float: "left", textAlign: "left", width: "22%" }}>Line 2</label>
                              <input
                                id="address_line_two"
                                maxLength={255}
                                type="text"
                                value={PersonsubmissionData?.address_line_two || CurrentPerson?.address_line_two || ""}
                                onChange={updatePersonFieldHandler("address_line_two")}
                                onBlur={() => updatePersonDatabase()}
                                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                              />
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>City</label>
                              <Stack spacing={2}>
                                <Autocomplete
                                  freeSolo
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disableClearable
                                  id="city"
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                  onChange={(event, newValue) => {
                                    updatePersonCityHandler("city", newValue.key);
                                  }}
                                  value={PersonsubmissionData?.city ? { key: PersonsubmissionData?.city, label: PersonsubmissionData?.city } :
                                    { key: "", label: "" }}
                                  options={PeopleCity?.map(({ name }) => ({
                                    key: name,
                                    label: name
                                  })) || []}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;

                                    return filtered;
                                  }}
                                  getOptionLabel={(option) => {
                                    if (option === "")
                                      return "";
                                    // Regular option
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { textAlign: "center", width: "100%" }
                                      }}
                                      onChange={e => { updatePersonFieldHandler("city")(e); }}
                                      onBlur={() => updatePersonDatabase()}
                                      maxLength={255}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>State</label>
                              <Stack spacing={2}>
                                <Autocomplete
                                  freeSolo
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disableClearable
                                  id="state"
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                  onChange={(event, newValue) => {
                                    updatePersonStateHandler("state", newValue.key);
                                  }}
                                  value={PersonsubmissionData?.state ? { key: PersonsubmissionData?.state, label: PersonsubmissionData?.state } :
                                    { key: "", label: "" }}
                                  options={PeopleStates?.map(({ name }) => ({
                                    key: name,
                                    label: name
                                  })) || []}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;

                                    return filtered;
                                  }}
                                  getOptionLabel={(option) => {
                                    if (option === "")
                                      return "";
                                    // Regular option
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { textAlign: "center", width: "100%" }
                                      }}
                                      onChange={e => { if (e.target.value === "") { updatePersonFieldHandler("state")(e); } }}
                                      onBlur={() => updatePersonDatabase()}
                                      maxLength={255}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Zip</label>
                              <Stack spacing={2}>
                                <Autocomplete
                                  freeSolo
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disableClearable
                                  id="zip_code"
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                  onChange={(event, newValue) => {
                                    updatePersonPostalCodeHandler("zip_code", newValue.key);
                                  }}
                                  value={PersonsubmissionData?.zip_code ? { key: PersonsubmissionData?.zip_code, label: PersonsubmissionData?.zip_code } :
                                    { key: "", label: "" }}
                                  options={PeoplePostalCode?.map(({ name }) => ({
                                    key: name,
                                    label: name
                                  })) || []}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;

                                    return filtered;
                                  }}
                                  getOptionLabel={(option) => {
                                    if (option === "")
                                      return "";
                                    // Regular option
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { textAlign: "center", width: "100%" }
                                      }}
                                      onChange={e => { updatePersonFieldHandler("zip_code")(e); }}
                                      onBlur={() => updatePersonDatabase()}
                                      maxLength={255}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <div style={{ display: "block" }}>
                              <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Country</label>
                              <Stack spacing={2}>
                                <Autocomplete
                                  freeSolo
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  disableClearable
                                  id="country"
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                  onChange={(event, newValue) => {
                                    updatePersonCountryFieldHandler("country", newValue.key);
                                  }}
                                  value={PersonsubmissionData?.country ? { key: PersonsubmissionData?.country, label: PersonsubmissionData?.country } :
                                    { key: "", label: "" }}
                                  options={PeopleCountries?.map(({ name }) => ({
                                    key: name,
                                    label: name
                                  })) || []}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;

                                    return filtered;
                                  }}
                                  getOptionLabel={(option) => {
                                    if (option === "")
                                      return "";
                                    // Regular option
                                    return option.label;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      InputProps={{
                                        ...params.InputProps,
                                        style: { textAlign: "center", width: "100%" }
                                      }}
                                      onChange={e => { if (e.target.value === "") { updatePersonFieldHandler("country")(e); } }}
                                      onBlur={() => updatePersonDatabase()}
                                      maxLength={255}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                            {B2B === "B2C" && (
                              <>
                                <div style={{ clear: "both", marginBottom: "20px" }}></div>
                                <label
                                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "38px", float: "left" }}>Lead Type</label>
                                <Select
                                  selected={PersonsubmissionData?.lead_type || CurrentPerson?.lead_type || ""}
                                  onSelect={(value) => {
                                    updatePersonFieldHandler("lead_type")({ target: { value } });
                                    updatePersonDatabase("", "", value);
                                  }}
                                  options={leadTypes?.map(({ label, value }) => ({
                                    key: value,
                                    label: label
                                  }))}
                                  style={{ marginBottom: 20, float: "left", height: "40px" }}
                                />
                              </>
                            )}
                            <div style={{ clear: "both", marginBottom: "20px" }}></div>
                            <label
                              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "38px", float: "left" }}>Lead Status</label>
                            <Select
                              selected={PersonsubmissionData?.lead_status || CurrentPerson?.lead_status || ""}
                              onSelect={(value) => {
                                updatePersonFieldHandler("lead_status")({ target: { value } });
                                updatePersonDatabase("", value);
                              }}
                              options={leadStatuses.map(({ label, value }) => ({
                                key: value,
                                label: label
                              }))}
                              style={{ marginBottom: 20, float: "left", height: "40px" }}
                            />
                            <div style={{ clear: "both", marginBottom: "20px" }}></div>
                            <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                            <Select
                              options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                                key: id,
                                label: first_name + " " + last_name
                              }))}
                              selected={PersonsubmissionData?.assigned_to || ""}
                              onSelect={async (value) => {
                                updatePersonFieldHandler("assigned_to")(value);

                                await submitUpdateCRMPersonRequest({
                                  variables: {
                                    customer_id,
                                    customerEmail: {
                                      ...omit(PersonsubmissionData, ["__typename", "update_time", "first_name", "crm_representative_id", "count", "lead_status_text", "referrer_name"]),
                                      cell_phone_number: PersonsubmissionData?.cell_phone_number !== undefined ? PersonsubmissionData?.cell_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
                                      office_phone_number: PersonsubmissionData?.office_phone_number !== undefined ? PersonsubmissionData?.office_phone_number?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll(" ", "")?.split("-").join("") : undefined,
                                      first_name: PersonsubmissionData?.first_name ? PersonsubmissionData?.first_name : CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id)[0]?.first_name : "",
                                      id: crm_people_id,
                                      users_id: user_id,
                                      crm_representative_id: representative ? representative : 1,
                                      assigned_to: value
                                    }
                                  }
                                });

                                let newActivityNotes = '';

                                if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
                                  newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0);
                                }

                                await submitUpdateCRMActivity({
                                  variables: {
                                    customer_id,
                                    ActivityData: {
                                      ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                                      users_id: user_id,
                                      people_id: crm_people_id,
                                      activity_type: "Person",
                                      activity_description: "Updated Person " + PersonsubmissionData?.first_name + " " + PersonsubmissionData?.last_name,
                                      representative_id: PersonsubmissionData?.representative_id,
                                      crm_customers_id: crm_customer_id ? crm_customer_id : null,
                                      activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                                      activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
                                    }
                                  }
                                });

                                const notify = options => {
                                  const key = enqueueSnackbar(options?.text, {
                                    variant: options?.variant,
                                    persist: true
                                  });
                                  setTimeout(() => {
                                    closeSnackbar(key);
                                  }, options?.delay || 2000);
                                }
                                notify({
                                  text: "Information has been successfully submitted",
                                  variant: "success"
                                });
                              }}
                              style={{ marginBottom: 20, height: "40px", float: "left" }}
                            />
                            <div style={{ clear: "both", marginBottom: "20px" }}></div>
                            {B2B === "B2C" && (
                              <>
                                <div style={{ clear: "both", marginBottom: "20px" }}></div>
                                <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                                <Stack spacing={2}>
                                  <Autocomplete
                                    freeSolo
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disableClearable
                                    id="referrer_id"
                                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                                    onChange={(e, newValue) => {
                                      updatePersonReferrerHandler("referrer_id", newValue.key, newValue.label);
                                    }}
                                    value={PersonsubmissionData?.referrer_name ? { key: PersonsubmissionData?.referrer_id, label: PersonsubmissionData?.referrer_name } :
                                      { key: "", label: "" }}
                                    options={CRMPersonReferrer?.map(({ first_name, last_name, id }) => ({
                                      key: id,
                                      label: first_name + " " + last_name
                                    })) || []}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;

                                      return filtered;
                                    }}
                                    getOptionLabel={(option) => {
                                      if (option === "")
                                        return "";
                                      // Regular option
                                      return option.label;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        InputProps={{
                                          ...params.InputProps,
                                          style: { textAlign: "center", width: "100%" }
                                        }}
                                        onChange={e => { updatePersonFieldHandler("referrer_name")(e); }}
                                        onBlur={() => updatePersonDatabase()}
                                        maxLength={255}
                                      />
                                    )}
                                  />
                                </Stack>
                                {PersonsubmissionData?.referrer_name && !PersonReferrerFound ?
                                  <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                              </>
                            )}
                            <div style={{ clear: "both", marginBottom: "20px" }}></div>
                          </CardBody>
                        </Card>
                      </>
                    )
                  })
                )}
                {AllSocials?.length ?
                  <>
                    <Card>
                      <CardBody>
                        <label>Social Channels</label>
                        <br />
                        <br />
                        <TableContainer>
                          <Table stickyHeader>
                            <TableRow>
                              {social_columns?.map((datacolumns) => (
                                <TableCell style={{ textAlign: "left", padding: "0px", margin: "0px" }}><label className={classes.selectFormControl}>{datacolumns?.Header}</label></TableCell>
                              ))}
                            </TableRow>
                            {CRMSocials ? Object.keys(CRMSocials)?.map((keyName, index) => (
                              <>
                                <TableRow key={CRMSocials[keyName]?.id}>
                                  <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                    <Select
                                      options={AllSocials?.map(({ id, social_channel }) => ({
                                        key: id,
                                        label: social_channel
                                      }))}
                                      selected={CRMSocials[keyName].social_channel_id || 0}
                                      onSelect={updateSocialsocial_channel_id(index, "social_channel_id")}
                                      style={{ marginBottom: 2, height: "40px" }}
                                    />&nbsp;&nbsp;
                                    {CRMSocials[keyName]?.social_channel_url ? <a target="_blank" href={CRMSocials[keyName].social_channel_url}>Go To Link</a> : ""}
                                  </TableCell>
                                  <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                    <a style={{ cursor: "pointer" }} onClick={RemoveSocial(CRMSocials[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ padding: "5px", margin: "0px", width: "100%" }} colSpan="2">
                                    <CustomInput
                                      id={`customers_top_products${CRMSocials[keyName].id}`}
                                      maxLength="2048"
                                      type="text"
                                      key={`customers_top_products${CRMSocials[keyName].id}`}
                                      formControlProps={{
                                        fullWidth: true,
                                      }}
                                      inputProps={{
                                        onChange: event => { updateSocialssocial_channel_url(index, event) },
                                        defaultValue: CRMSocials[keyName]?.social_channel_url ? CRMSocials[keyName].social_channel_url : ''
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </>
                            )) : ""}
                          </Table>
                        </TableContainer>
                        <br />
                        <a style={{ cursor: "pointer" }} onClick={AddSocial}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Social Channel</a>
                        <div style={{ clear: "both", height: "30px" }}></div>
                        <SaveButton
                          color="orange"
                          type="submit"
                          onClick={() => {
                            let ErrorPresent = false;
                            Object.keys(CRMSocials)?.map((keyName, index) => {
                              if (CRMSocials[keyName].social_channel_id === null || CRMSocials[keyName].social_channel_id === '') {
                                alert("Please Select a Social Channel");
                                ErrorPresent = true;
                                return;
                              }
                              else if (CRMSocials[keyName].social_channel_url === null || CRMSocials[keyName].social_channel_url === '') {
                                alert("Please Enter a Social URL");
                                ErrorPresent = true;
                                return;
                              }

                              updateSocials({
                                variables: {
                                  customer_id: state.customers?.selected?.id,
                                  data: omit(CRMSocials[keyName], ["__typename"])
                                }
                              })
                            })

                            if (ErrorPresent === false) {
                              refetchSocial();

                              const notify = options => {
                                const key = enqueueSnackbar(options?.text, {
                                  variant: options?.variant,
                                  persist: true
                                });
                                setTimeout(() => {
                                  closeSnackbar(key);
                                }, options?.delay || 2000);
                              }
                              notify({
                                text: "Information has been successfully submitted",
                                variant: "success"
                              });
                            }
                          }
                          }
                        >
                          Submit
                        </SaveButton>
                      </CardBody>
                    </Card>
                  </>
                  : ""}
                {/* PEOPLE */}
                {crm_customer_id !== null && CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>People</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).map((CurrentPerson, index) => {
                        if (index > 4 && showMorePeople === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Name</label>
                                <a href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + CurrentPerson?.crm_customers_id + "&peopleid=" + CurrentPerson?.id} style={{ float: "left", textAlign: "left", width: "60%" }}>
                                  {CurrentPerson?.first_name} {CurrentPerson?.last_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.cell_phone_number ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Cell</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.cell_phone_number) : ""}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.office_phone_number ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Office</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? FormatPhone(CurrentPerson?.office_phone_number) : ""}
                                  &nbsp;&nbsp;&nbsp;{CurrentPerson?.office_extension ? "X   " + CurrentPerson?.office_extension : ""}{CurrentPerson?.office_phone_number ? <br /> : ""}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentPerson?.email ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "20%" }}>Email</label>
                                <label style={{ width: "80%", textAlign: "left" }}>
                                  {CurrentPerson?.cell_phone_number ? CurrentPerson?.email : ""}</label>
                              </div> : ""}
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === crm_people_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMorePeople) {
                            setshowMorePeople(false);
                          } else {
                            setshowMorePeople(true)
                          }
                        }}>
                          {showMorePeople ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                      <div>
                        <a href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleOpen()
                          }}>+ People</a>
                      </div>
                    </CardBody>
                  </Card>
                )}
                {/* PHOTO GALLERY */}
                {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.proposal_id === crm_proposal_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>Photo Gallery</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.proposal_id === crm_proposal_id).map((CurrentPhoto, index) => {
                        if (index > 4 && showMorePhotos === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                                <a href="" style={{ float: "left" }}>
                                  {CurrentPhoto?.photo_gallery_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentPhoto?.photo_gallery_description ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Description</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentPhoto?.photo_gallery_description}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentPhoto?.photo_gallery_last_updated ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {moment(new Date(parseInt(CurrentPhoto?.photo_gallery_last_updated))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.proposal_id === crm_proposal_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMorePhotos) {
                            setshowMorePhotos(false);
                          } else {
                            setshowMorePhotos(true)
                          }
                        }}>
                          {showMorePhotos ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                    </CardBody>
                  </Card>
                )}
                {/* DOCUMENTS */}
                {CRMDocument?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>Documents</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMDocument?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).map((CurrentProposal, index) => {
                        if (index > 4 && showMoreDocuments === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                                <a href="" style={{ float: "left" }}>
                                  {CurrentProposal?.documents_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.documents_status ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.documents_status}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.documents_update_date ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {moment(new Date(parseInt(CurrentProposal?.documents_update_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMDocument?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMoreDocuments) {
                            setshowMoreDocuments(false);
                          } else {
                            setshowMoreDocuments(true)
                          }
                        }}>
                          {showMoreDocuments ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                    </CardBody>
                  </Card>
                )}
                {/* CONTRACT */}
                {CRMContract?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>Contracts</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMContract?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).map((CurrentProposal, index) => {
                        if (index > 4 && showMoreContracts === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                                <a href="" style={{ float: "left" }}>
                                  {CurrentProposal?.contract_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.contract_url ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                                <a
                                  rel="noopener noreferrer"
                                  href={CurrentProposal?.contract_url}
                                  target="_blank"
                                >
                                  View
                                </a></div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.contract_value ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.contract_value}</label>
                              </div> : ""}
                              {CurrentProposal?.contract_start_date ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Start Date</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.contract_start_date}</label>
                              </div> : ""}
                              {CurrentProposal?.contract_end_date ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>End Date</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.contract_end_date}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMContract?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMoreContracts) {
                            setshowMoreContracts(false);
                          } else {
                            setshowMoreContracts(true)
                          }
                        }}>
                          {showMoreContracts ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                    </CardBody>
                  </Card>
                )}
                {/* PROPOSAL */}
                {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 0 && (
                  <Card profile>
                    <CardBody>
                      <label style={{ marginTop: "30px" }}>Proposals</label>
                      <br />
                      <br />
                      {crm_customer_id ? CRMProposal?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).map((CurrentProposal, index) => {
                        if (index > 4 && showMoreProposals === false) {
                          return null
                        }
                        else {
                          return (
                            <>
                              <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                                <a href="" style={{ float: "left" }}>
                                  {CurrentProposal?.proposal_name}</a>
                              </div>
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.proposal_status ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.proposal_status}</label>
                              </div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.contract_url ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                                <a
                                  rel="noopener noreferrer"
                                  href={CurrentProposal?.proposal_url}
                                  target="_blank"
                                >
                                  View
                                </a></div> : ""}
                              <div style={{ clear: "both" }}></div>
                              {CurrentProposal?.proposal_value ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {CurrentProposal?.proposal_value}</label>
                              </div> : ""}
                              {CurrentProposal?.proposal_date ? <div style={{ display: "flex" }}>
                                <label style={{ textAlign: "left", width: "40%" }}>Date</label>
                                <label style={{ width: "60%", textAlign: "left" }}>
                                  {moment(new Date(parseInt(CurrentProposal?.proposal_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                              </div> : ""}
                              <br />
                            </>
                          )
                        }
                      }) : ""}
                      {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.proposal_id === crm_proposal_id).length > 5 ?
                        <a href="#" onClick={e => {
                          e.preventDefault();
                          if (showMoreProposals) {
                            setshowMoreProposals(false);
                          } else {
                            setshowMoreProposals(true)
                          }
                        }}>
                          {showMoreProposals ? "See Less..." : "See More..."}
                        </a>
                        : ""}
                      <br />
                    </CardBody>
                  </Card>
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <Card>
                  <CardBody>
                    {AddUpdateProposal(CRMActivitySubmission, updateActivityFieldHandler, updateProposalDatabase,
                      CRMPeople, ProposalsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                      updatePeopleNameFieldHandler, updateProposalFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                      customer_id, CRMProducts, CRMServices, true, CRMActivity, CRMPhotoGallery, CRMJob,
                      setProposalsubmissionData, proposalvalue, setproposalvalue, ProposalStatuses, CreateContractfromProposal, CRMContract)}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody style={{ textAlign: "center" }}>
                    {LatestActivity(NoteFilterImageSelected, CallFilterImageSelected, EmailFilterImageSelected, TextFilterImageSelected,
                      MeetingFilterImageSelected, TaskFilterImageSelected, DeadlineFilterImageSelected, JobFilterImageSelected, TicketFilterImageSelected,
                      PhotoFilterImageSelected, UploadFilterImageSelected, InboundFormsFilterImageSelected, OnboardingFilterImageSelected, setOnboardingFilterImageSelected,
                      InboundCallsFilterImageSelected, ProposalFilterImageSelected, ContractFilterImageSelected, EstimateFilterImageSelected, setEstimateFilterImageSelected,
                      setNoteFilterImageSelected, setJobFilterImageSelected, setUploadFilterImageSelected, setDeadlineFilterImageSelected,
                      setTaskFilterImageSelected, setMeetingFilterImageSelected, setEmailFilterImageSelected, setTicketFilterImageSelected,
                      setTextFilterImageSelected, setPhotoFilterImageSelected, setContractFilterImageSelected, setProposalFilterImageSelected,
                      setInboundCallsFilterImageSelected, setInboundFormsFilterImageSelected, setCallFilterImageSelected,
                      ShownActivityCount, showMoreActivity, setshowMoreActivity, CRMActivity, users, CRMRepresentative, CRMContract, CRMProposal, CRMDocument,
                      CRMPhotoGallery, CRMJob, customer_id, FormData, CallData, CRMPeople, [], [], CRMEstimate, CRMTickets, [])}
                    {updatedCustomerEmail && (
                      <Dialog
                        open={Boolean(updatedCustomerEmail)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                        fullWidth
                      >
                        <DialogContent>
                          <UpdateCustomerProfile
                            updated={updatedCustomerEmail}
                            handleClose={handleClose}
                            setCustomers={setCRMCustomers}
                            customers={CRMCustomers}
                            crm_customer_id={crm_customer_id}
                            source={"Linear"}
                          />
                        </DialogContent>
                      </Dialog>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

CRMProposalDetail.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMProposalDetail;
