import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import { sumBy } from "lodash";
import StatsCard from "components/StatsCard";
import {
  GET_GOOGLE_ADS_INFO_SUMMARY,
  GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
  GET_GOOGLE_LSA_SUMMARY
} from "queries/google";
import { GET_BING_CAMPAIGNS_SUMMARY } from "queries/bing";
import { GET_TOTAL_BUDGET_SPENT } from "queries/budget";
import { GET_NEURON_CAMPAIGN_SUMMARY } from "queries/neuron";
import { LIST_HYDRATE_TEXT_REPORT,
  LIST_HYDRATE_MMS_REPORT,
  LIST_HYDRATE_VOICE_REPORT,
  LIST_HYDRATE_EMAIL_REPORT,
  LIST_HYDRATE_LETTER_REPORT,
  LIST_HYDRATE_POSTCARD_REPORT,
  LIST_HYDRATE_EMAIL_DETAIL_REPORT } from "queries/hydrate";
// import { GET_SOCIAL_ADS_CAMPAIGN_INFO_SUMMARY } from "queries/social";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const AllProducts = () => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const neuron_id = state.customers?.selected?.neuron_id;
  const compareDateRange = getPrevious(dateRangeString, compareOption);

  let { loading: hydrateTextLoading, data: hydrateTextData } = useQuery(LIST_HYDRATE_TEXT_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydrateTextCompareLoading, data: hydrateTextCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  let { loading: hydrateMMSLoading, data: hydrateMMSData } = useQuery(LIST_HYDRATE_MMS_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydrateMMSCompareLoading, data: hydrateMMSCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  let { loading: hydrateVoiceLoading, data: hydrateVoiceData } = useQuery(LIST_HYDRATE_VOICE_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydrateVoiceCompareLoading, data: hydrateVoiceCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  let { loading: hydrateEmailLoading, data: hydrateEmailData } = useQuery(LIST_HYDRATE_EMAIL_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydrateEmailCompareLoading, data: hydrateEmailCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  let { loading: hydrateLetterLoading, data: hydrateLetterData } = useQuery(LIST_HYDRATE_LETTER_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydrateLetterCompareLoading, data: hydrateLetterCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );


  let { loading: hydratePostCardLoading, data: hydratePostCardData } = useQuery(LIST_HYDRATE_POSTCARD_REPORT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: hydratePostCardCompareLoading, data: hydratePostCardCompareData } = useQuery(
    LIST_HYDRATE_TEXT_REPORT,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  const { loading: adsLoading, data: adsData } = useQuery(
    GET_GOOGLE_ADS_INFO_SUMMARY,
    {
      skip: !customer_id || !dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate,
        filter: "Awareness,TargetedBanner,ReEngage,GeoIntention",
        type_filter: "DISPLAY,VIDEO"
      }
    }
  );
  const { loading: googleLoading, data: googleData } = useQuery(
    GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
    {
      skip: !customer_id || !dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const { loading: bingLoading, data: bingData } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip: !customer_id || !dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const { loading: lsaLoading, data: lsaData } = useQuery(
    GET_GOOGLE_LSA_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const {
    loading: totalBudgetSpentLoading,
    data: totalBudgetSpentData
  } = useQuery(GET_TOTAL_BUDGET_SPENT, {
    skip: !customer_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });

  const { loading: adsCompareLoading, data: adsCompareData } = useQuery(
    GET_GOOGLE_ADS_INFO_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate,
        filter: "Awareness,TargetedBanner,ReEngage,GeoIntention",
        type_filter: "DISPLAY"
      }
    }
  );
  const { loading: googleCompareLoading, data: googleCompareData } = useQuery(
    GET_GOOGLE_CAMPAIGN_INFO_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const { loading: bingCompareLoading, data: bingCompareData } = useQuery(
    GET_BING_CAMPAIGNS_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const { loading: lsaCompareLoading, data: lsaCompareData } = useQuery(
    GET_GOOGLE_LSA_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );
  const {
    loading: totalCompareBudgetSpentLoading,
    data: totalCompareBudgetSpentData
  } = useQuery(GET_TOTAL_BUDGET_SPENT, {
    skip: !customer_id || !compareDateRange,
    variables: {
      customer_id,
      start_date: compareDateRange?.startDate,
      end_date: compareDateRange?.endDate
    }
  });

  const { loading: neuronLoading, data: neuronData } = useQuery(
    GET_NEURON_CAMPAIGN_SUMMARY,
    {
      skip: !customer_id || !dateRangeString,
      variables: {
        neuron_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );
  const { loading: nueronCompareLoading, data: neuronCompareData } = useQuery(
    GET_NEURON_CAMPAIGN_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        neuron_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  const loading =
    adsLoading ||
    googleLoading ||
    bingLoading ||
    lsaLoading ||
    totalBudgetSpentLoading ||
    neuronLoading ||
    hydrateTextLoading ||
    hydrateEmailLoading ||
    hydrateMMSLoading ||
    hydrateVoiceLoading ||
    hydrateLetterLoading ||
    hydratePostCardLoading;

  const loadingCompare =
    adsCompareLoading ||
    googleCompareLoading ||
    bingCompareLoading ||
    lsaCompareLoading ||
    totalCompareBudgetSpentLoading ||
    nueronCompareLoading ||
    hydrateTextCompareLoading ||
    hydrateMMSCompareLoading ||
    hydrateVoiceCompareLoading ||
    hydrateEmailCompareLoading ||
    hydrateLetterCompareLoading ||
    hydratePostCardCompareLoading;

    return (
    <GridContainer>
      <StatsCard
        icon="attach_money"
        loading={loading}
        label="Total Spent"
        value={
          addMargin((lsaData?.getGoogleLsaSummary?.cost || 0) / 1000000, state, true) +
          addMargin(
            (adsData?.getGoogleAdsInfoSummary?.cost || 0) / 1000000 +
              (googleData?.getGoogleCampaignInfoSummary?.cost || 0) / 1000000 +
              
              (bingData?.getBingCampaignsSummary?.cost || 0) +
              (neuronData?.getNeuronCampaignSummary?.spent || 0) +
              (sumBy(hydrateEmailData?.listHydrateEmailReport, "delivered") * .001 > 0 && 
              sumBy(hydrateEmailData?.listHydrateEmailReport, "delivered") * .001 < .01 ? .01 : 
              sumBy(hydrateEmailData?.listHydrateEmailReport, "delivered") * .001 || 0 ) +
              (sumBy(hydrateTextData?.listHydrateTextReport, "message_pice")) + 
              (sumBy(hydrateMMSData?.listHydrateMMSReport, "message_price")) +
              (sumBy(hydrateVoiceData?.listHydrateVoiceReport, "message_price")) +
              (sumBy(hydrateLetterData?.listHydrateLetterReport, "post_price")) +
              (sumBy(hydratePostCardData?.listHydratePostcardReport, "post_price")),
            state
          ) + totalBudgetSpentData?.getTotalBudgetSpent || 0
        }
        render="currency"
        previous
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin((lsaCompareData?.getGoogleLsaSummary?.cost || 0) / 1000000, state, true) +
            addMargin(
                (adsCompareData?.getGoogleAdsInfoSummary?.cost || 0) / 1000000 +
                  (googleCompareData?.getGoogleCampaignInfoSummary?.cost || 0) /
                    1000000 +
                  (bingCompareData?.getBingCampaignsSummary?.cost || 0) +
                  (neuronCompareData?.getNeuronCampaignSummary?.spent || 0) + 
                  (sumBy(hydrateEmailCompareData?.listHydrateEmailReport, "delivered") * .001 > 0 && sumBy(hydrateEmailCompareData?.listHydrateEmailReport, "delivered") * .001 < .01 ? .01 : sumBy(hydrateEmailCompareData?.listHydrateEmailReport, "delivered") * .001 || 0 ) +
                  (sumBy(hydrateTextCompareData?.listHydrateTextReport, "message_price")) + 
                  (sumBy(hydrateMMSCompareData?.listHydrateMMSReport, "message_price")) +
                  (sumBy(hydrateVoiceCompareData?.listHydrateVoiceReport, "message_price")) +
                  (sumBy(hydrateLetterCompareData?.listHydrateLetterReport, "post_price")) +
                  (sumBy(hydratePostCardCompareData?.listHydratePostcardReport, "post_price")),
                state
              ) + totalCompareBudgetSpentData?.getTotalBudgetSpent || 0
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Impressions"
        value={
          (adsData?.getGoogleAdsInfoSummary?.impressions || 0) +
          (googleData?.getGoogleCampaignInfoSummary?.impressions || 0) +
          (lsaData?.getGoogleLsaSummary?.impressions || 0) +
          (bingData?.getBingCampaignsSummary?.impressions || 0) +
          (sumBy(hydrateEmailData?.listHydrateEmailReport, "delivered") || 0) +
          (hydrateTextData?.listHydrateTextReport?.length || 0) +
          (hydrateMMSData?.listHydrateMMSReport?.length || 0) +
          (hydrateVoiceData?.listHydrateVoiceReport?.length || 0) +
          (hydrateLetterData?.listHydrateLetterReport?.length || 0) +
          (hydratePostCardData?.listHydratePostcardReport?.length || 0)
        }
        render="integer"
        previous
        help="Impressions are the number of times that your advertisement has been displayed."
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? (adsCompareData?.getGoogleAdsInfoSummary?.impressions || 0) +
              (googleCompareData?.getGoogleCampaignInfoSummary?.impressions ||
                0) +
              (lsaCompareData?.getGoogleLsaSummary?.impressions || 0) +
              (bingCompareData?.getBingCampaignsSummary?.impressions || 0) + 
              (sumBy(hydrateEmailCompareData?.listHydrateEmailReport, "delivered") || 0) +
              (hydrateTextCompareData?.listHydrateTextReport?.length || 0) +
              (hydrateMMSCompareData?.listHydrateMMSReport?.length || 0) +
              (hydrateVoiceCompareData?.listHydrateVoiceReport?.length || 0) +
              (hydrateLetterCompareData?.listHydrateLetterReport?.length || 0) +
              (hydratePostCardCompareData?.listHydratePostcardReport?.length || 0)
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Clicks"
        value={
          (adsData?.getGoogleAdsInfoSummary?.clicks || 0) +
          (googleData?.getGoogleCampaignInfoSummary?.clicks || 0) +
          (lsaData?.getGoogleLsaSummary?.clicks || 0) +
          (bingData?.getBingCampaignsSummary?.clicks || 0)
        }
        render="integer"
        previous
        help="Clicks are the count of the number of times users have clicked on your advertisement."
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? (adsCompareData?.getGoogleAdsInfoSummary?.clicks || 0) +
              (googleCompareData?.getGoogleCampaignInfoSummary?.clicks || 0) +
              (lsaCompareData?.getGoogleLsaSummary?.clicks || 0) +
              (bingCompareData?.getBingCampaignsSummary?.clicks || 0)
            : ""
        }
        footerRender
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Views"
        value={adsData?.getGoogleAdsInfoSummary?.views || 0}
        render="integer"
        help="Views are the number of times your video was viewed."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? adsCompareData?.getGoogleAdsInfoSummary?.views || 0
            : ""
        }
        previous
      />
    </GridContainer>
  );
};

export default AllProducts;
