import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import GridItem from "components/Grid/GridItem";
import Link from '@mui/material/Link';
import { API_URL } from "config";

import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const ParaDocuments = () => {
  const [Width] = useState(Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  ));
  const classes = useStyles();

  return (
    <>{ Width > 768 ? // This jsx is for desktops
    <GridContainer style={{ marginLeft: "auto", marginRight: "auto", width: "600px"}}>
      <GridItem>
      <h4 className={classes.pageHeader}>Download Para Resource Documents</h4>
      <div style={{float: "left"}}>
        <h4>BannerAds Library Page</h4>&nbsp;&nbsp;&nbsp;<Link href={`${API_URL}/banneradslibrarypage`}>
          <Button color="primary" type="button">
            Download Now
        </Button></Link>
      </div>
      <div style={{float: "right"}}>
        <h4>GeoIntention Library Page</h4>
      </div><br />
      <div style={{display: 'block', float: 'right'}}>
        <Link href={`${API_URL}/geointentionlibrarypage`}><Button color="primary" type="button" style={{marginLeft: "5px"}}>
            Download Now
          </Button></Link>
      </div>
      <div style={{clear: "both"}} />
      <div style={{float: "left"}}>
        <h4>OfflineTracking Library Page</h4>&nbsp;&nbsp;&nbsp;<Link href={`${API_URL}/offlinetrackinglibrarypage`}>
          <Button color="primary" type="button">
            Download Now
        </Button></Link>
      </div>
      <div style={{float: "right"}}>
        <h4>OnlineVideo Library Page</h4>
      </div><br />
      <div style={{display: 'block', float: 'right'}}>
        <Link href={`${API_URL}/onlinevideolibrarypage`}><Button color="primary" type="button">
            Download Now
          </Button></Link>
      </div>
      <div style={{clear: "both"}} />
      <div style={{float: "left"}}>
        <h4>OOH Library Page</h4>&nbsp;&nbsp;&nbsp;<Link href={`${API_URL}/oohlibrarypage`}>
          <Button color="primary" type="button">
            Download Now
        </Button></Link>
      </div>
      <div style={{float: "right"}}>
        <h4>OrganicSearch Library Page</h4>
      </div><br />
      <div style={{display: 'block', float: 'right'}}>
        <Link href={`${API_URL}/organicsearchlibrarypage`}><Button color="primary" type="button" style={{marginLeft: "21px"}}>
            Download Now
          </Button></Link>
      </div>
      <div style={{clear: "both"}} />
      <div style={{float: "left"}}>
        <h4>PaidSearch Library Page</h4>&nbsp;&nbsp;&nbsp;<Link href={`${API_URL}/paidsearchlibrarypage`}>
          <Button color="primary" type="button">
            Download Now
        </Button></Link>
      </div>
      <div style={{float: "right"}}>
        <h4>ReEngage Library Page</h4>
      </div><br />
      <div style={{display: 'block', float: 'right'}}>
        <Link href={`${API_URL}/reengagelibrarypage`}><Button color="primary" type="button">
            Download Now
          </Button></Link>
      </div>
      <div style={{clear: "both"}} />
      </GridItem>
      </GridContainer>
      :
       // This jsx is for mobile
       <GridContainer style={{display: 'flex', justifyContent: 'center'}}>
      <GridItem>
       <h4 className={classes.pageHeader}>Download Para Resource Documents</h4>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>BannerAds Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/banneradslibrarypage`}>
           <Button color="primary" type="button">
             Download Now
         </Button></Link>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>GeoIntention Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/geointentionlibrarypage`}><Button color="primary" type="button">
             Download Now
           </Button></Link>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>OfflineTracking Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/offlinetrackinglibrarypage`}>
           <Button color="primary" type="button">
             Download Now
         </Button></Link>
       </div>
       <div style={{clear: "both"}} />
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>OnlineVideo Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/onlinevideolibrarypage`}><Button color="primary" type="button">
             Download Now
           </Button></Link>
       </div>
       <div style={{clear: "both"}} />
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>OOH Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/oohlibrarypage`}>
           <Button color="primary" type="button">
             Download Now
         </Button></Link>
       </div>
       <div style={{clear: "both"}} />
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>OrganicSearch Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/organicsearchlibrarypage`}><Button color="primary" type="button">
             Download Now
           </Button></Link>
       </div>
       <div style={{clear: "both"}} />
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>PaidSearch Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/paidsearchlibrarypage`}>
           <Button color="primary" type="button">
             Download Now
         </Button></Link>
       </div>
       <div style={{clear: "both"}} />
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <h4>ReEngage Library</h4>
       </div>
       <div style={{display: 'grid', justifyContent: 'center'}}>
         <Link href={`${API_URL}/reengagelibrarypage`}><Button color="primary" type="button">
             Download Now
           </Button></Link>
       </div>
       <div style={{clear: "both"}} />
      </GridItem>
      </GridContainer>
      }
    </>
  );
};

export default ParaDocuments;
