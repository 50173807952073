import gql from "graphql-tag";

export const GET_GOOGLE_ACCOUNT_INFO_SUMMARY = gql`
  query getGoogleAccountInfoSummary(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getGoogleAccountInfoSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      ctr
      avg_cpc
    }
  }
`;

export const GET_GOOGLE_LSA_SUMMARY = gql`
  query getGoogleLsaSummary(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getGoogleLsaSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
    }
  }
`;

export const GET_GOOGLE_CAMPAIGN_INFO_SUMMARY = gql`
  query getGoogleCampaignInfoSummary(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getGoogleCampaignInfoSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      ctr
      avg_cpc
      conversions
    }
  }
`;

export const GET_GOOGLE_ADS_INFO_SUMMARY = gql`
  query getGoogleAdsInfoSummary(
    $customer_id: Int!
    $filter: String
    $type_filter: String
    $start_date: String
    $end_date: String
  ) {
    getGoogleAdsInfoSummary(
      customer_id: $customer_id
      filter: $filter
      type_filter: $type_filter
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      views
    }
  }
`;

export const GET_GOOGLE_VIDEO_ADS_INFO_SUMMARY = gql`
  query getGoogleVideoAdsInfoSummary(
    $customer_id: Int!
    $filter: String
    $type_filter: String
    $start_date: String
    $end_date: String
  ) {
    getGoogleVideoAdsInfoSummary(
      customer_id: $customer_id
      filter: $filter
      type_filter: $type_filter
      start_date: $start_date
      end_date: $end_date
    ) {
      cost
      impressions
      clicks
      views
    }
  }
`;

export const LIST_PAIDSEARCH_CAMPAIGN_AD_SCHEDULES = gql`
  query listPaidSearchCampaignAdSchedules(
    $customer_id: Int!
    $start_date: String
    $end_date: String
    $type: String
    $channel_type: String
    $type_filter: String
    $filter: String
    $campaign_name: String
  ) {
    listPaidSearchCampaignAdSchedules(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      type: $type
      channel_type: $channel_type
      type_filter: $type_filter
      filter: $filter
      campaign_name: $campaign_name
    ) {
      campaign_name
      campaign_status
      day_of_week
      start_hour
      start_minute
      end_hour
      end_minute
      radius
      latitude_in_micro_degrees
      longitude_in_micro_degrees
      google_account_info{
        time_zone
      }
    }
  }
`;

export const LIST_BANNERADS_CAMPAIGN_AD_LOCATION = gql`
  query listBannerAdsCampaignAdLocation(
    $customer_id: Int!
    $start_date: String
    $end_date: String
    $type: String
    $negative: Int
    $filter: String
    $campaign_name: String
  ) {
    listBannerAdsCampaignAdLocation(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      type: $type
      negative: $negative
      filter: $filter
      campaign_name: $campaign_name
    ) {
      campaign_name
      campaign_status
      day_of_week
      start_hour
      start_minute
      end_hour
      end_minute
      street_address
      radius
      latitude_in_micro_degrees
      longitude_in_micro_degrees
      google_campaigns_targets{
        canonical_name
        target_type
      }
      google_location_lookup{
        name
        canonical_name
        target_type
      }
    }
  }
`;

export const LIST_BANNERADS_CAMPAIGN_AD_PROXIMITY = gql`
  query listBannerAdsCampaignAdProximity(
    $customer_id: Int!
    $start_date: String
    $end_date: String
    $type: String
    $negative: Int
    $filter: String
    $campaign_name: String
  ) {
    listBannerAdsCampaignAdProximity(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      type: $type
      negative: $negative
      filter: $filter
      campaign_name: $campaign_name
    ) {
      campaign_name
      campaign_status
      day_of_week
      start_hour
      start_minute
      end_hour
      end_minute
      street_address
      radius
      latitude_in_micro_degrees
      longitude_in_micro_degrees
      google_campaigns_targets{
        canonical_name
        target_type
      }
      google_location_lookup{
        name
        canonical_name
        target_type
      }
    }
  }
`;

export const LIST_PAIDSEARCH_CAMPAIGN_AD_LOCATION = gql`
  query listPaidSearchCampaignAdLocation(
    $customer_id: Int!
    $start_date: String
    $end_date: String
    $type: String
    $negative: Int
    $filter: String
    $campaign_name: String
  ) {
    listPaidSearchCampaignAdLocation(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      type: $type
      negative: $negative
      filter: $filter
      campaign_name: $campaign_name
    ) {
      campaign_name
      campaign_status
      day_of_week
      start_hour
      start_minute
      end_hour
      end_minute
      street_address
      radius
      latitude_in_micro_degrees
      longitude_in_micro_degrees
      google_campaigns_targets{
        canonical_name
        target_type
      }
      google_location_lookup{
        name
        canonical_name
        target_type
      }
    }
  }
`;

export const LIST_PAIDSEARCH_CAMPAIGN_AD_PROXIMITY = gql`
  query listPaidSearchCampaignAdProximity(
    $customer_id: Int!
    $start_date: String
    $end_date: String
    $type: String
    $negative: Int
    $filter: String
    $campaign_name: String
  ) {
    listPaidSearchCampaignAdProximity(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      type: $type
      negative: $negative
      filter: $filter
      campaign_name: $campaign_name
    ) {
      campaign_name
      campaign_status
      day_of_week
      start_hour
      start_minute
      end_hour
      end_minute
      street_address
      latitude_in_micro_degrees
      longitude_in_micro_degrees
      radius
      google_campaigns_targets{
        canonical_name
        target_type
      }
      google_location_lookup{
        name
        canonical_name
        target_type
      }
    }
  }
`;

export const LIST_GOOGLE_ADS_GROUPS = gql`
  query listGoogleAdsGroups(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    listGoogleAdsGroups(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_group_name
      ad_group_id
      cost
      impressions
      clicks
      views
      ctr
      avg_cpc
      absolute_top_impression_percentage
      top_impression_percentage
    }
  }
`;

export const LIST_GOOGLE_ADS_GROUPS_BY_FILTER = gql`
  query listGoogleAdsGroupsByFilter(
    $customer_id: Int!
    $filter: String
    $type_filter: String
    $start_date: String
    $end_date: String
  ) {
    listGoogleAdsGroupsByFilter(
      customer_id: $customer_id
      filter: $filter
      type_filter: $type_filter
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_group_name
      ad_group_id
      cost
      impressions
      clicks
      views
    }
  }
`;

export const LIST_GOOGLE_SEARCH_QUERIES = gql`
  query listGoogleSearchQueries(
    $customer_id: Int!
    $customer_name: String!
    $start_date: String!
    $end_date: String!
    $detailed: Boolean
  ) {
    listGoogleSearchQueries(
      customer_id: $customer_id
      customer_name: $customer_name
      start_date: $start_date
      end_date: $end_date
      detailed: $detailed
    ) {
      search_term_view
      campaign_name
      ad_group_name
      keyword_match_type
      impressions
      clicks
      ctr
    }
  }
`;

export const LIST_GOOGLE_NEGATIVE_KEYWORDS_SEARCH_QUERIES = gql`
  query listGoogleNegativeKeywordSearchQueries(
    $customer_id: Int!
    $customer_name: String!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleNegativeKeywordSearchQueries(
      customer_id: $customer_id
      customer_name: $customer_name
      start_date: $start_date
      end_date: $end_date
    ) {
      campaign_name
      ad_group_name
      match_type
      keywords_text
    }
  }
`;

export const LIST_GOOGLE_KEYWORDS_SEARCH_QUERIES = gql`
  query listGoogleKeywordSearchQueries(
    $customer_id: Int!
    $customer_name:  String!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleKeywordSearchQueries(
      customer_id: $customer_id
      customer_name: $customer_name
      start_date: $start_date
      end_date: $end_date
    ) {
      campaign_name
      ad_group_name
      keyword_match_type
      keywords_text
      impressions
      clicks
    }
  }
`;

export const LIST_GOOGLE_RESPONSIVE_SEARCH_ADS = gql`
  query listGooglerResponsiveSearchAds(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGooglerResponsiveSearchAds(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_id
      type
      status
      impressions
      clicks
      cost
      responsive_descriptions      
      responsive_descriptions_2
      responsive_descriptions_3
      responsive_descriptions_4
      responsive_headlines
      responsive_headlines_2
      responsive_headlines_3
      responsive_headlines_4
      responsive_headlines_5
      responsive_headlines_6
      responsive_headlines_7
      responsive_headlines_8
      responsive_headlines_9
      responsive_headlines_10
      responsive_headlines_11
      responsive_headlines_12
      responsive_headlines_13
      responsive_headlines_14
      responsive_headlines_15
      responsive_path_1
      responsive_path_2
      final_url
      display_url
      date
      ad_group_name
    }
  }
`;

export const LIST_GOOGLE_TEXT_ADS = gql`
  query listGoogleTextAds(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleTextAds(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_id
      ad_group_id      
      type
      headline_pt_1
      headline_pt_2
      headline_pt_3
      description1
      description2
      path1
      path2
      status
      final_url
      display_url
      impressions
      clicks
      cost
      google_ads_group_name{
        ad_group_name
        ad_group_id
      }
    }
  }
`;

export const LIST_GOOGLE_SITE_LINKS = gql`
  query listGoogleAdExtensionStructuredSnippets(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionStructuredSnippets(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      header
      values
      status
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS = gql`
  query listGoogleAdExtensionLocations(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionLocations(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      business_name
      address_line_1
      address_line_2
      phone_number
      impressions
      clicks
      cost
      ctr
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS_PRICE = gql`
  query listGoogleAdExtensionPrice(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionPrice(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      final_urls
      language_code
      price_offerings
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS_CALLOUTS = gql`
  query listGoogleAdExtensionCallouts(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionCallouts(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      callout_text
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS_CALLS = gql`
  query listGoogleAdExtensionCalls(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionCalls(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      phone_number
      phone_impressions
      country_code
      phone_calls
      phone_through_rate
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS_PROMOTIONS = gql`
  query listGoogleAdExtensionsPromotions(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionsPromotions(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      discount_modifier
      occasion
      language_code
      currency_code
      percent_off
      promotion_target
      promotion_code
      final_mobile_urls
      final_url_suffix
      start_date
      end_date
      final_urls
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
      money_amount_off_amount_micros
    }
  }
`;

export const LIST_GOOGLE_AD_EXTENSIONS_SITE_LINKS = gql`
  query listGoogleAdExtensionsSiteLinks(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listGoogleAdExtensionsSiteLinks(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      asset_id
      final_urls
      line1
      line2
      link_text
      impressions
      clicks
      cost
      ctr
      ad_group_name
      campaign_name
    }
  }
`;

export const LIST_VIDEO_ASSETS = gql`
  query listVideoAssets(
    $customer_id: Int!
    $filter: String
    $start_date: String!
    $end_date: String!
  ) {
    listVideoAssets(
      customer_id: $customer_id
      filter: $filter
      start_date: $start_date
      end_date: $end_date
    ) {
      title
      cost
      impressions
      video_views
      video_view_rate
      video_quartile_p25_rate
      video_quartile_p50_rate
      video_quartile_p75_rate
      video_quartile_p100_rate
      duration_millis
      video_id
      final_urls
    }
  }
`;

export const LIST_IMAGE_ASSETS = gql`
  query listImageAssets(
    $customer_id: Int!
    $filter: String
    $start_date: String!
    $end_date: String!
  ) {
    listImageAssets(
      customer_id: $customer_id
      filter: $filter
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_id
      ad_group_name
      status
      mime_type
      pixel_height
      pixel_width
      final_urls
      image_url
      impressions
      clicks
      ctr
    }
  }
`;
