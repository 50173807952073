const FormatPhone = (input) => {
  if (input === "" || input === null || input === undefined) {
    return input
  }
  if(input.replace(/\D+/g, '').length > 11)
  {
    return input.replace(/\D+/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '+$1 $2 $3 $4')
  }
  else if(input.replace(/\D+/g, '').length === 11)
  {
    return input.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
  }
  let output = "(";
  input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
    if (g1.length) {
      output += g1;
      if (g1.length == 3) {
        output += ")";
        if (g2.length) {
          output += " " + g2;
          if (g2.length == 3) {
            output += "-";
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  }
  );
  return output;
}
export default FormatPhone;
