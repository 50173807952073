import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import { addMargin } from "utils/margin";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_SITE_LINKS } from "queries/google";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Snippets = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_GOOGLE_SITE_LINKS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGoogleAdExtensionStructuredSnippets || [];
  const classes = useStyles();

  const inputs = [
    {
      field: "header",
      label: "Structured Snippet Feed Item Header",
      inputProps: {
        maxLength: 25,
        validation: v => `${v.length}/25 Characters`
      }
    },
    {
      field: "values",
      label: "Structured Snipped Feed Item Values"
    },
    { field: "notes", label: "Notes", optional: true }
  ];

  data = data.filter(row => inputs.some(({ field }) => row[field]));

  const columns = compact([
    {
      Header: "Structured Snippets",
      accessor: "structured_snippet",
      width: 300,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{original?.header}</div>
          <div style={{ whiteSpace: "pre-line" }}>
            {original?.values
              ?.split(";")
              .join("\n")}
          </div>
        </>
      ),
      renderCSV: ({ original }) =>
        [
          original?.header,
          original?.values
        ].join("\n")
    },
    {
      Header: "Campaign Name",
      accessor: "campaign_name",
      width: 150
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name",
      width: 150
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
        (original?.ctr * 100).toFixed(2) + "%"
    }
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Structured Snippets
              <HelpTooltip>
                {`Structured snippets are assets that highlight specific aspects of your products and services. Structured snippets show beneath your text ad in the form of a header.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Structured Snippets(${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(Snippets);
