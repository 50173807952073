import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CardHeader from "components/Card/CardHeader";
import HelpTooltip from "components/HelpTooltip";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_BUDGETS } from "queries/budget";


const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const columns = [
  {
    Header: "Account Name",
    accessor: "customer_name"
  },
  {
    Header: "Product",
    accessor: "product_line"
  },
  {
    Header: "Cycle",
    accessor: "current_cycle"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Budget",
    accessor: "monthly_budget"
  },
  {
    Header: "Spent",
    accessor: "budget_spent"
  },
  {
    Header: "Ideal Spend",
    accessor: "ideal_spend"
  },
  {
    Header: "Spend Pacing",
    accessor: "pacing"
  },
  {
    Header: "Ideal Pacing",
    accessor: "projected_pacing"
  },
  {
    Header: "Total Conversions",
    accessor: "totalLeads"
  },
  {
    Header: "Lead Goal",
    accessor: "monthly_leads_goal"
  },
  {
    Header: "CPL",
    accessor: "CPL"
  },
  {
    Header: "CPLG",
    accessor: "CPLG"
  },
  {
    Header: "Start Date",
    accessor: "cycle_start_time"
  },
  {
    Header: "End Date",
    accessor: "cycle_end_time"
  }
];

const MyAccounts = () => {
  const [open, setOpen] = React.useState({ open: false });
  let { data } = useQuery(LIST_BUDGETS);

  let rawdata = data?.listBudgets || [];

  rawdata = rawdata?.map((row, index) => {
    if (row === null)
      return null
    let customer_name = "";
    let product_line = "";
    if (row?.customer_name !== data[index - 1]?.customer_name) {
      customer_name = row.customer_name;
    }
    if (
      row?.product_line !== data[index - 1]?.product_line ||
      row?.customer_name !== data[index - 1]?.customer_name
    ) {
      product_line = row.product_line;
    }
    if (row?.budget_spent > 0 && row?.monthly_budget > 0 && row?.cycle_end_time === null) {
      row = Object.assign({}, row, { pacing: String(Math.round((row.budget_spent / row.monthly_budget) * 100)) + "%" })
      var diff = moment().diff(moment(new Date(row.cycle_start_time)), 'days');
      if (diff > 30) {
        diff = 30;
        row = Object.assign({}, row, { projected_pacing: "100%" })
      } else
        row = Object.assign({}, row, { projected_pacing: String(Math.round((row.monthly_budget / 30 * diff) / row.monthly_budget * 100)) + "%" })
      row = Object.assign({}, row, { ideal_spend: String(parseFloat((row.monthly_budget / 30) * diff).toFixed(2)) })
    }
    

    return {
      ...row,
      customer_name,
      product_line
    };
  });

  function Row(props) {
    const { row } = props;

    return (
      <React.Fragment>
        {row?.status === "Running" && (
          <TableRow key={row?.length}>
            <TableCell>{row?.customer_name ?
              <IconButton aria-label="expand row" size="small" onClick={() => { if (open?.customer_id === row.customer_id) { setOpen({ customer_id: row.customer_id, open: !open.open }) } else { setOpen({ customer_id: row.customer_id, open: true }) } }}>
                {open.customer_id === row?.customer_id && open?.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton> : <div>&nbsp;</div>}
            </TableCell>
            <TableCell><div>{row?.customer_name}</div>
            </TableCell>
            <TableCell><div>{row?.product_line}</div>
            </TableCell>
            <TableCell><div>{row?.current_cycle}</div>
            </TableCell>
            <TableCell><div>{row?.status}</div>
            </TableCell>
            <TableCell><div>{row?.monthly_budget}</div>
            </TableCell>
            <TableCell><div>{row?.budget_spent}</div>
            </TableCell>
            <TableCell><div>{row?.ideal_spend}</div>
            </TableCell>
            <TableCell><div>{row?.pacing}</div>
            </TableCell>
            <TableCell><div>{row?.projected_pacing}</div>
            </TableCell>
            <TableCell><div>{row?.totalLeads}</div>
            </TableCell>
            <TableCell><div>{row?.monthly_leads_goal}</div>
            </TableCell>
            <TableCell><div>{row?.CPL}</div>
            </TableCell>
            <TableCell>{row.CPL > row.CPLG ? <div style={{ color: "red", fontWeight: "bold" }}>{row?.CPLG}</div> : <div style={{ color: "green", fontWeight: "bold" }}>{row?.CPLG}</div>}
            </TableCell>
            <TableCell><div>{row?.cycle_start_time}</div>
            </TableCell>
            <TableCell><div>{row?.cycle_end_time}</div>
            </TableCell>
          </TableRow>
        )}

        {row?.status === "Ended" && (open.customer_id === row?.customer_id && open?.open) && (
          <TableRow key={row?.length}>
            <TableCell><div>&nbsp;</div>
            </TableCell>
            <TableCell><div>{row?.customer_name}</div>
            </TableCell>
            <TableCell><div>{row?.product_line}</div>
            </TableCell>
            <TableCell><div>{row?.current_cycle}</div>
            </TableCell>
            <TableCell><div>{row?.status}</div>
            </TableCell>
            <TableCell><div>{row?.monthly_budget}</div>
            </TableCell>
            <TableCell><div>{row?.budget_spent}</div>
            </TableCell>
            <TableCell><div>{row?.ideal_spend}</div>
            </TableCell>
            <TableCell><div>{row?.pacing}</div>
            </TableCell>
            <TableCell><div>{row?.projected_pacing}</div>
            </TableCell>
            <TableCell><div>{row?.totalLeads}</div>
            </TableCell>
            <TableCell><div>{row?.monthly_leads_goal}</div>
            </TableCell>
            <TableCell><div>{row?.CPL}</div>
            </TableCell>
            <TableCell>{row.CPL > row.CPLG ? <div style={{ color: "red", fontWeight: "bold" }}>{row?.CPLG}</div> : <div style={{ color: "green", fontWeight: "bold" }}>{row?.CPLG}</div>}
            </TableCell>
            <TableCell><div>{row?.cycle_start_time}</div>
            </TableCell>
            <TableCell><div>{row?.cycle_end_time}</div>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    )
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Budget Cycles
              <HelpTooltip>
                {`Cycle Data may not be accurate prior to 09/01/2022.`}
              </HelpTooltip></h4>
          </CardHeader>
          <CardBody>
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" className="-striped -highlight">
                <TableHead>
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    {columns.map((column) => (
                      <TableCell>
                        {column.Header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>&nbsp;</TableCell>
                  {rawdata?.map((datarow) => (
                    <Row key={datarow?.id} row={datarow} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default MyAccounts;
