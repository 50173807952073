import React from "react";
import Input from "@mui/material/Input";
import TextField from "@mui/material/TextField";
import Inputmask from "inputmask";
import { omit } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SelectStatic from "views/Dashboard/Accounts/SelectStatic";

export const renderInputGroup = (current, updated, updateFieldHandler) => ({
  field,
  label,
  inputProps,
  type,
  defaultValue,
  options,
  optionsFilter,
  customOption,
  optional
}) => {
  if (inputProps?.mask) {
    const selector = document.getElementById(`input-${field}`);
    if (selector && !selector.getAttribute("inputmode")) {
      Inputmask(inputProps?.mask).mask(selector);
    }
  }
  let inputControl = null;
  if (type === "label") {
    inputControl = <span>{updated[field]}</span>;
  } else if (type === "select") {
    inputControl = (
      <SelectStatic
        name={`select-${field}`}
        value={updated[field]}
        onChange={updateFieldHandler(field)}
        data={optionsFilter ? optionsFilter(options, updated) : options}
        customOption={customOption}
        required={!optional}
        narrow
      />
    );
  } else if (type === "date") {
    inputControl = (
      <TextField
        type="date"
        value={updated[field]}
        InputLabelProps={{
          shrink: true
        }}
        style={{ width: 220 }}
        onChange={updateFieldHandler(field)}
      />
    );
  } else {
    inputControl = (
      <Input
        name={field}
        id={`input-${field}`}
        fullWidth
        required={!optional}
        inputProps={{
          type: "string",
          value: updated[field] || "",
          onChange: updateFieldHandler(
            field,
            inputProps?.type === "number" ? parseFloat : undefined
          ),
          ...omit(inputProps, ["validation"])
        }}
      />
    );
  }
  const labelComponent = (
    <b>
      {label}
      {optional ? "" : " *"}
      <span
        style={{
          color: "red",
          fontSize: 12,
          fontWeight: "100",
          marginLeft: "5px"
        }}
      >
        {inputProps?.validation
          ? `(${
              typeof inputProps.validation === "string"
                ? inputProps.validation
                : inputProps.validation(updated[field])
            })`
          : null}
      </span>
    </b>
  );
  if (!current) {
    return (
      <div key={field} style={{ marginBottom: 10 }}>
        {labelComponent}
        <GridContainer justify="center" alignItems="center">
          <GridItem xs={12}>{inputControl}</GridItem>
        </GridContainer>
      </div>
    );
  }
  return (
    <div key={field} style={{ marginBottom: 10 }}>
      {labelComponent}
      {(current[field] || defaultValue) !== (updated[field] || defaultValue) ? (
        <i> (updated)</i>
      ) : (
        ""
      )}
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={6}>
          {type === "select"
            ? options.find(
                ({ value }) => (current[field] || defaultValue) === value
              )?.label
            : current[field]}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          {inputControl}
        </GridItem>
      </GridContainer>
    </div>
  );
};

export const renderUpdateTable = (current, updated, inputs) => {
  if (!updated || !inputs) return "";
  const labels = inputs.filter(({ type }) => type === "label");
  const updateInputs = inputs.filter(({ type }) => type !== "label");
  return `
    ${labels
      .map(({ field, label }) => `<div>${label}: ${updated[field]}</div>`)
      .join("")}
    <table style="border-collapse: collapse; table-layout: fixed; min-width: 100%;">
      <thead>
        <tr>
          <th style="border: 1px solid black;">Field</th>
          ${current ? `<th style="border: 1px solid black;">Existing</th>` : ""}
          <th style="border: 1px solid black;">Updated</th>
        </tr>
      </thead>
      <tbody>
        ${updateInputs
          .map(
            ({ field, label }) => `
              <tr>
                <td style="border: 1px solid black; width: 110px">${label}</td>
                ${
                  current
                    ? `<td style="border: 1px solid black;">${current[field]}</td>`
                    : ""
                }
                <td style="border: 1px solid black; font-weight: ${
                  !current || current[field] === updated[field]
                    ? "regular"
                    : "bold"
                }">${updated[field]}</td>
              </t>
            `
          )
          .join("")}
      </tbody>
    </table>
  `;
};
