import React, { useState, useContext, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";

import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import TaskOutlined from '@mui/icons-material/TaskOutlined';
import PhotoLibraryOutlined from '@mui/icons-material/PhotoLibraryOutlined';
import MailOutline from "@mui/icons-material/MailOutline";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_READ,
  MARK_ALL_NOTIFICATIONS_READ
} from "queries/customers";

export default function Notifications() {
  const [state, dispatch] = useContext(Context);
  const { data: me } = useQuery(READ_ME);
  const [Hover, setHover] = useState(null);
  const [Notifications, setNotifications] = useState([]);

  let { data: notifications_data, refetch } = useQuery(GET_NOTIFICATIONS, {
    skip: !me?.readMe?.customer_id || !me?.readMe?.id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id: me?.readMe?.customer_id,
      user_id: me?.readMe?.id
    }
  });

  const [updateNotifications] = useMutation(MARK_NOTIFICATION_READ);

  const [updateAllNotifications] = useMutation(MARK_ALL_NOTIFICATIONS_READ, {
    onCompleted: () => {
      refetch();
    }
  });

  useEffect(() => {
    setNotifications(notifications_data?.getNotifications || []);
  }, [notifications_data]);

  return (
    <div>
      <h4 style={{ fontSize: "30px", fontFamily: "Inter", color: "#666666" }}>Notifications</h4>
      {Notifications?.map((CurrentNotification, index) => {
        return <>
          {index === 0 ?
            <>
              <div style={{ padding: "20px" }}><a href="#" style={{ float: "right" }} onClick={(e) => {
                e.preventDefault();
                updateAllNotifications({
                  variables: {
                    customer_id: state.customers?.selected?.id,
                    user_id: me?.readMe?.id
                  }
                });
              }}>mark all read</a></div><div style={{ clear: "both", height: "30px" }} /></>
            : ""}
          <div style={{ cursor: "pointer", padding: "20px", backgroundColor: Hover === CurrentNotification?.id ? "#f4f4f5" : CurrentNotification?.notificationread === 0 ? "#EEF3F9" : "#fff", width: "100%" }} onMouseOver={() => { setHover(CurrentNotification?.id) }} onMouseOut={() => { setHover(null) }}
            onClick={() => {
              updateNotifications({
                variables: {
                  customer_id: state.customers?.selected?.id,
                  notification_id: CurrentNotification?.id
                }
              })
              window.location = CurrentNotification?.detailPage;
            }}>
            <div style={{ float: "left", display: "inline-block" }}>
              <div style={{ borderRadius: "50%", backgroundColor: "#007FFF", height: "40px", width: "40px", textAlign: "center", float: "left", display: "inline-block" }}>
                {CurrentNotification?.image_object === "Customer" ? <HomeWorkOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "2px", marginTop: "3px" }} />
                  : CurrentNotification?.image_object === "Lead" ? <FlagOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                    : CurrentNotification?.image_object === "Person" ? <PermIdentityOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                      : CurrentNotification?.image_object === "Task" ? <TaskAltOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                        : CurrentNotification?.image_object === "Deadline" ? <MenuOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                          : CurrentNotification?.image_object === "Meeting" ? <GroupAddOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                            : CurrentNotification?.image_object === "Call" ? <PhoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                              : CurrentNotification?.image_object === "Text" ? <SmartphoneOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                : CurrentNotification?.image_object === "Contract" ? <RequestPageOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                  : CurrentNotification?.image_object === "Proposal" ? <DescriptionOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                    : CurrentNotification?.image_object === "Document" ? <UploadFileOutlinedIcon style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                      : CurrentNotification?.image_object === "Estimate" ? <TaskOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                        : CurrentNotification?.image_object === "Photo" ? <PhotoLibraryOutlined style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                          : CurrentNotification?.image_object === "Customer Submitted Onboarding" || CurrentNotification?.image_object === "Onboarding Sent to Customer" ? <MailOutline style={{ display: "block", height: "32px", width: "32px", color: "#fff", marginLeft: "5px", marginTop: "3px" }} />
                                            : ""}
              </div></div><div style={{ marginLeft: "30px", width: "70%", float: "left", display: "inline-block" }} >{CurrentNotification.message}</div>
            <div style={{ clear: "both" }} />
            <div style={{ marginTop: "10px", borderRadius: Notifications?.length - 1 === index ? "5%" : "0%" }}>{moment(new Date(parseInt(CurrentNotification.created_time))).fromNow()}{CurrentNotification?.notificationread === 0 ?
              <a style={{ float: "right" }} href="#" onClick={() => {
                updateNotifications({
                  variables: {
                    customer_id: state.customers?.selected?.id,
                    notification_id: CurrentNotification?.id
                  }
                })
              }}>mark read</a> : ""}</div></div>
          {Notifications?.length - 1 !== index ? <hr style={{ color: "black", borderTop: "1px solid #000", padding: "0px", margin: "0px" }} /> : ""}
        </>
      })}
    </div>
  );
}
