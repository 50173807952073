import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_OUT_OF_HOME_ASSETS } from "queries/neuron";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const OutOfHomeAssets = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  // const customer_id = state.customers?.selected?.id;
  const neuron_id = state.customers?.selected?.neuron_id;
  let { loading, data } = useQuery(LIST_OUT_OF_HOME_ASSETS, {
    skip: !neuron_id || !state.customers?.dateRangeString,
    variables: {
      neuron_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = neuron_id ? data?.listOutOfHomeAssets || [] : [];
  const classes = useStyles();
  const columns = [
    {
      Header: "Type",
      accessor: "type"
    },
    {
      Header: "Asset Name",
      accessor: "name"
    },
    {
      Header: "Width",
      accessor: "width"
    },
    {
      Header: "Height",
      accessor: "height"
    },
    {
      Header: "View Ad",
      accessor: "url",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original?.url ? (
          "Unknown"
        ) : (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              setImageUrl(original?.url);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            View
          </a>
        )
    }
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>View Out of Home Ads</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        open={Boolean(imageUrl)}
        onClose={() => setImageUrl(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paperScrollPaper: classes.paperScrollPaper
        }}
      >
        <img src={imageUrl} alt="" />
      </Dialog>
    </GridContainer>
  );
};

export default OutOfHomeAssets;
