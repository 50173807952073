import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SwapScreenshotsReplaceWithS3URL from "components/SwapScreenshotsReplaceWithS3URL";
import CardAvatar from "components/Card/CardAvatar";
import Avatar from "components/Avatar";
import Dropzone from "components/Dropzone";
import TaskOutlined from '@mui/icons-material/TaskOutlined';

import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import TableCell from '@mui/material/TableCell';
import CustomInput from "components/CustomInput/CustomInput";
import SaveButton from "components/CustomButtons/Button";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Select from "components/Select/Select";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import AddUpdateEstimate from "./AddUpdateEstimate";
import LatestActivity from "./LatestActivity";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import SensorsOutlinedIcon from '@mui/icons-material/SensorsOutlined';
import SensorsOffOutlinedIcon from '@mui/icons-material/SensorsOffOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CustomersDefaultImage from "assets/img/Customers.png";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LinearNoContentWrapper from "components/LinearNoContentWrapper";
import { startCase, snakeCase } from "lodash";
import FormatPhone from "../Format/FormatPhone";

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CRM_CUSTOMERS,
  LIST_CURRENT_SOCIALS,
  UPDATE_CURRENT_SOCIALS,
  LIST_ALL_SOCIALS,
  REMOVE_CUSTOMER_SOCIAL,
  DELETE_CONTRACT,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  GET_ESTIMATE_STATUS,
  UPDATE_CRM_ACTIVITY,
  CREATE_CRM_ESTIMATE,
  CREATE_CRM_PROPOSAL_FROM_ESTIMATE,
  LIST_PHOTOS,
  LIST_TICKETS,
  LIST_SERVICES,
  LIST_PRODUCT,
  GET_CONTRACT_STATUS,
  LIST_CUSTOMERS,
  GET_CUSTOMER_STATUS,
  GET_FORM_DATA,
  GET_CALL_DATA,
  LIST_MONTHY_BUDGETS,
  GET_SIC_CODES,
  GET_NAICS_CODES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES,
  GET_TOP_COMPETITORS,
  GET_TOP_PRODUCTS,
  GET_NO_FOCUS_PRODUCTS,
  GET_TARGET_AREAS,
  GET_FOLLOWING,
  UPDATE_FOLLOWING,
  GET_CRM_SETTINGS
} from "queries/customers";

import { GET_BUDGET } from "queries/budget";

import { LIST_CUSTOMER_USERS } from "queries/users";

import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const CRMEstimateDetail = (props) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const [competitorData, setcompetitorData] = useState([]);
  const [productData, setproductData] = useState([]);
  const [duplicateTopProducts, setduplicateTopProducts] = useState(false);
  const [duplicateNoFocusProducts, setduplicateNoFocusProducts] = useState(false);
  const [duplicateHighPriorityLocations, setduplicateHighPriorityLocations] = useState(false);
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [Following, setFollowing] = useState(0);
  const [CRMCustomersReferrer, setCRMCustomersReferrer] = useState(null);
  const [ReferrerFound, setReferrerFound] = useState(null);
  const [referrer_name, setreferrer_name] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [CRMActivity, setCRMActivity] = useState(null);
  const [OriginalCRMCustomers, setOriginalCRMCustomers] = useState(null);
  const [SICCodes, setSICCodes] = useState(null);
  const [NAICSCodes, setNAICSCodes] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);
  const [key, setKey] = useState(0);
  const [CRMActivitySubmission, setCRMActivitySubmission] = useState({ "activity_completed": 1 });
  const [DocumentsubmissionData, setDocumentsubmissionData] = useState({});
  const [EstimatesubmissionData, setEstimatesubmissionData] = useState({});
  const [ProposalsubmissionData, setProposalsubmissionData] = useState({});
  const [GallerysubmissionData, setGallerysubmissionData] = useState({});
  const [JobsubmissionData, setJobsubmissionData] = useState({});
  const [EstimateData, setEstimateData] = useState(false);
  const [PersonFound, setPersonFound] = useState(true);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMMonthlyBudget, setCRMMonthlyBudget] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMEstimate, setCRMEstimate] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CallFilterImageSelected, setCallFilterImageSelected] = useState(false);
  const [EmailFilterImageSelected, setEmailFilterImageSelected] = useState(false);
  const [TextFilterImageSelected, setTextFilterImageSelected] = useState(false);
  const [NoteFilterImageSelected, setNoteFilterImageSelected] = useState(false);
  const [OnboardingFilterImageSelected, setOnboardingFilterImageSelected] = useState(false);
  const [MeetingFilterImageSelected, setMeetingFilterImageSelected] = useState(false);
  const [TaskFilterImageSelected, setTaskFilterImageSelected] = useState(false);
  const [DeadlineFilterImageSelected, setDeadlineFilterImageSelected] = useState(false);
  const [UploadFilterImageSelected, setUploadFilterImageSelected] = useState(false);
  const [EstimateFilterImageSelected, setEstimateFilterImageSelected] = useState(false);
  const [CRMTickets, setCRMTickets] = useState(null);
  const [TicketFilterImageSelected, setTicketFilterImageSelected] = useState(false);
  const [JobFilterImageSelected, setJobFilterImageSelected] = useState(false);
  const [PhotoFilterImageSelected, setPhotoFilterImageSelected] = useState(false);
  const [ContractFilterImageSelected, setContractFilterImageSelected] = useState(false);
  const [ProposalFilterImageSelected, setProposalFilterImageSelected] = useState(false);
  const [InboundCallsFilterImageSelected, setInboundCallsFilterImageSelected] = useState(false);
  const [InboundFormsFilterImageSelected, setInboundFormsFilterImageSelected] = useState(false);
  const [crm_customer_id, setcrm_customer_id] = useState(null);
  const [crm_estimate_id, setcrm_estimate_id] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMProducts, setCRMProducts] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [showMorePeople, setshowMorePeople] = useState(false);
  const [showMoreActivity, setshowMoreActivity] = useState(false);
  const [showMoreDocuments, setshowMoreDocuments] = useState(false);
  const [showMorePhotos, setshowMorePhotos] = useState(false);
  const [showMoreContracts, setshowMoreContracts] = useState(false);
  const [showMoreProposals, setshowMoreProposals] = useState(false);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [LinearCustomer, setLinearCustomer] = useState(true);
  const [user_id, setuser_id] = useState(null);
  const [contractvalue, setcontractvalue] = useState('');
  const [Hover, setHover] = useState(false);
  const [product_cost, setproduct_cost] = useState('');
  const [product_margin, setproduct_margin] = useState('');
  const [product_price, setproduct_price] = useState('');
  const [product_discount, setproduct_discount] = useState('');
  const [product_sale_price, setproduct_sale_price] = useState('');
  const [product_tax, setproduct_tax] = useState('');
  const [service_cost, setservice_cost] = useState('');
  const [service_margin, setservice_margin] = useState('');
  const [service_price, setservice_price] = useState('');
  const [service_discount, setservice_discount] = useState('');
  const [service_sale_price, setservice_sale_price] = useState('');
  const [service_tax, setservice_tax] = useState('');
  const [PersonsubmissionData, setPersonSubmissionData] = useState({});
  const [CRMOriginalPeople, setCRMOriginalPeople] = useState(null);
  const [B2B, setB2B] = useState(null);
  const [revenue, setrevenue] = useState('');
  const [FormData, setFormData] = useState([]);
  const [CallData, setCallData] = useState([]);
  const [targetAreaData, settargetAreaData] = useState([]);
  const [noFocusProductData, setnoFocusProductData] = useState([]);
  const [highestCompetitorID, sethighestCompetitorID] = useState(1);
  const [highestProductID, sethighestProductID] = useState(1);
  const [highestNoFocusProductID, sethighestNoFocusProductID] = useState(1);
  const [highestTopTargetAreasID, sethighestTopTargetAreasID] = useState(1);
  const [HighTopProductHover, setHighTopProductHover] = useState([]);
  const [HighTopCompetitorsHover, setHighTopCompetitorsHover] = useState([]);
  const [HighTargetAreasHover, setHighTargetAreasHover] = useState([]);
  const [HighNoFocusHover, setHighNoFocusHover] = useState([]);
  const [AllSocials, setAllSocials] = useState(null);
  const [CRMSocials, setCRMSocials] = useState(null);
  const [highestSocialID, sethighestSocialID] = useState(1);
  const [CRMSettings, setCRMSettings] = useState([]);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const navigate = useNavigate();
  const crm_user_level = me?.readMe?.crm_user_level;
  const user_level = me?.readMe?.user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const FormatCurrency = () => {
    let Customer_Revenue = "0";
    if (revenue !== null && revenue !== "")
      Customer_Revenue = revenue;
    let newValue = parseFloat(Customer_Revenue?.replaceAll(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setCRMCustomers({
      ...CRMCustomers,
      ["revenue"]: newValue
    });

    setrevenue(newValue);
  }

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null || value === "")
      value = "0";
    return parseFloat(value?.replaceAll(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateCustomerRevenueValueFieldHandler = (e) => {
    setrevenue(e.target.value);
  };

  const [GetFormDataQuery, { called }] = useLazyQuery(GET_FORM_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setFormData((data?.GetFormData || []).map(
        ({ form_data: form_data_str, ...rest }) => {
          const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
          const form_data_obj_normalized = {};
          Object.keys(form_data_obj).forEach(key => {
            const title = startCase(key.split("<")[0]);
            if (title === "G Recaptcha Response") {
              form_data_obj_normalized[title] = form_data_obj[key];
              return;
            }
            form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
          });
          return {
            form_data: form_data_obj_normalized,
            ...rest
          };
        }
      ))
    }
  });

  const [GetCallDataQuery, { called: callsqueried }] = useLazyQuery(GET_CALL_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCallData(data?.GetCallData || []);
    }
  });

  //  looks for inbound calls and forms so we can get there info
  let CallIDS = [];
  let FormIDS = [];
  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Form") {
      FormIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  CRMActivity?.map(CurrentActivity => {
    if (CurrentActivity?.activity_type === "Inbound Call") {
      CallIDS.push(CurrentActivity?.activity_description?.split(":")[1]);
    }
  })

  let { data: SICCodes_data } = useQuery(GET_SIC_CODES);
  let { data: NAICSCodes_data } = useQuery(GET_NAICS_CODES);
  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  let { data: Postal_Code_data } = useQuery(GET_POSTAL_CODE,
    {
      skip: !CRMCustomers?.office_zip,
      variables: {
        name: CRMCustomers?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !CRMCustomers?.office_city,
      variables: {
        name: CRMCustomers?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  let { data: ticket_data } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: product_data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  useEffect(() => {
    if (called === false && FormIDS?.length) {
      GetFormDataQuery({
        variables: {
          customer_id,
          formIDS: FormIDS?.join(", ")
        }
      })
    }
    if (callsqueried === false && CallIDS?.length) {
      GetCallDataQuery({
        variables: {
          customer_id,
          callIDS: CallIDS?.join(", ")
        }
      })
    }
  }, [FormIDS, CallIDS]);

  const { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  let { data: Following_data } = useQuery(GET_FOLLOWING, {
    skip: !customer_id || !crm_customer_id,
    variables: {
      customer_id: customer_id,
      object_type: "Customer",
      object_id: crm_customer_id,
    }
  });

  let { data: budget_data } = useQuery(GET_BUDGET, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      product_line: "linear"
    }
  });

  let { data: Activity_data, refetch: refetchActivity } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Proposal_data, refetch: refetchProposal } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Monthly_Budget_data } = useQuery(LIST_MONTHY_BUDGETS, {
    skip: !customer_id || !crm_customer_id || !crm_estimate_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      estimate_id: crm_estimate_id
    }
  });

  let { data: Contract_data, refetch: refetchContracts } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEstimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id || !crm_estimate_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      estimate_id: crm_estimate_id
    }
  });

  let { data: Job_data, refetch: refetchJobs } = useQuery(LIST_JOBS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
    }
  });

  let { data: Photo_Gallery_data, refetch: refetchGallery } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id
    }
  });

  let { data: Photo_data, refetch: refetchPhotos } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id || !CRMCustomers,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      id: crm_customer_id ? parseInt(crm_customer_id) : 0,
      status: -1,
    }
  });

  useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      name: referrer_name,
      status: -1,
      page: 0,
      rows_per_page: 10,
    },
    onCompleted: (data) => {
      setCRMCustomersReferrer(data?.CRMCustomers || []);
    }
  });

  let { data: Representative_data, refetch: refetchRepresentatives } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data, refetch: refetchDocument } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0
    }
  });

  let { data: people_data, refetch: refetchPeople } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id,
      count: 0,
      page: 0,
      rows_per_page: 100,
    }
  });

  const [GetCRMSettings] = useLazyQuery(GET_CRM_SETTINGS, {
    fetchPolicy: "no-cache",
    skip: !me?.readMe?.customer_id,
    variables: {
      customer_id: me?.readMe?.customer_id,
    },
    onCompleted: (data) => {
      setCRMSettings(data?.getCRMSettings);
    }
  });

  let { data: social_data, refetch: refetchSocial } = useQuery(LIST_CURRENT_SOCIALS, {
    skip: !crm_customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      crm_customer_id: crm_customer_id
    }
  });

  let { data: all_social_data } = useQuery(LIST_ALL_SOCIALS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
    }
  });

    let { data: customer_data } = useQuery(LIST_CUSTOMERS, {
    skip: !state.customers?.selected?.id,
    variables: { customer_id: state.customers?.selected?.id }
  });

  let { data: ContractStatus_data } = useQuery(GET_CONTRACT_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id
    }
  });

  ContractStatus_data = ContractStatus_data?.getContractStatus || [];

  let ContractStatuses = [];

  if (ContractStatus_data) {
    ContractStatuses.push({ label: "select", value: 0 });
    ContractStatus_data.forEach(obj => {
      ContractStatuses.push({ label: obj.contract_status_text, value: obj.id })
    })
  }

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: CustomerStatus_data } = useQuery(GET_CUSTOMER_STATUS, {
    skip: !customer_id || !CRMCustomers,
    variables: {
      customer_id: customer_id,
      status: CRMCustomers?.status === 1 ? 1 : 0
    }
  });

  let { data: EstimateStatus_data } = useQuery(GET_ESTIMATE_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      customonly: 0
    }
  });

  let { data: all_data } = useQuery(GET_TOP_COMPETITORS, {
    skip: !customer_id,
    variables: { customer_id: customer_id, estimate_id: crm_estimate_id },
  });

  let { data: top_product_data } = useQuery(GET_TOP_PRODUCTS, {
    skip: !customer_id,
    variables: { customer_id: customer_id, estimate_id: crm_estimate_id },
  });
  let { data: no_focus_product_data } = useQuery(GET_NO_FOCUS_PRODUCTS, {
    skip: !customer_id,
    variables: { customer_id: customer_id, estimate_id: crm_estimate_id },
  });

  let { data: target_areas_data } = useQuery(GET_TARGET_AREAS, {
    skip: !customer_id,
    variables: { customer_id: customer_id, estimate_id: crm_estimate_id },
  });

  CustomerStatus_data = CustomerStatus_data?.getCustomerStatus || [];
  EstimateStatus_data = EstimateStatus_data?.getEstimateStatus || [];

  let EstimateStatuses = [];

  if (EstimateStatus_data) {
    EstimateStatuses.push({ label: "select", value: 0 });
    EstimateStatus_data.forEach(obj => {
      EstimateStatuses.push({ label: obj.estimate_status_text, value: obj.id })
    })
  }

  let CustomerStatuses = [];

  if (CustomerStatus_data) {
    CustomerStatuses.push({ label: "select", value: 0 });
    CustomerStatus_data.forEach(obj => {
      CustomerStatuses.push({ label: obj.customer_status_text, value: obj.id })
    })
  }

  const [submitUpdateCRMFollowingRequest] = useMutation(
    UPDATE_FOLLOWING,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (data) => {
        setFollowing(data?.updateCRMFollowing);
      }
    }
  );

  useEffect(() => {
    const siteUrl = window.location.search;
    const urlParams = new URLSearchParams(siteUrl);

    if (urlParams.get("id")) {
      setcrm_customer_id(parseInt(urlParams.get("id")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    if (urlParams.get("estimateid")) {
      setcrm_estimate_id(parseInt(urlParams.get("estimateid")));
    } else {
      navigate("/admin/crmcustomers?customer=" + customer_id);
    }

    setuser_id(me?.readMe?.id);

    GetCRMSettings();
    setFollowing(Following_data?.getFollowing);

    setCRMAssignedToUsers(users?.listCustomerUsers || []);

    setCRMCustomers({
      ...data?.CRMCustomers[0], ["office_phone_number"]: FormatPhone(data?.CRMCustomers[0]?.office_phone_number),
      ["referrer_name"]: data?.CRMCustomers[0]?.referrer_id ? CRMCustomersReferrer?.filter(CurrentCustomer =>
        CurrentCustomer?.id === data?.CRMCustomers[0]?.referrer_id)?.map((CurrentCustomer) => { setReferrerFound(true); return CurrentCustomer?.business_name })?.join("") : ""
    });

    setCRMSocials(social_data?.listCurrentSocials || []);
    setAllSocials(all_social_data?.listAllSocials || []);

    setSICCodes(SICCodes_data?.getSICCodes || []);
    setNAICSCodes(NAICSCodes_data?.getNAICSCodes || []);
    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);

    setCRMActivity(Activity_data?.ListActivity || []);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMMonthlyBudget(Monthly_Budget_data?.listMonthlyBudgets || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMTickets(ticket_data?.ListTickets || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMProducts(product_data?.ListProduct || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setLinearCustomer(budget_data?.getBudget?.monthly_budget);

    customer_data = customer_data?.listCustomers[0];
    setB2B(customer_data?.B2B === 1 ? "B2B" : "B2C");

    setcompetitorData(all_data?.getTopCompetitors || []);
    setproductData(top_product_data?.getTopProducts || []);
    setnoFocusProductData(no_focus_product_data?.getNoFocusProducts || []);
    settargetAreaData(target_areas_data?.getTargetAreas || []);

    if (competitorData?.length)
      sethighestCompetitorID(competitorData[competitorData.length - 1].id + 1);

    if (productData?.length)
      sethighestProductID(productData[productData.length - 1].id + 1);

    if (noFocusProductData?.length)
      sethighestNoFocusProductID(noFocusProductData[noFocusProductData.length - 1].id + 1);

    if (targetAreaData?.length)
      sethighestTopTargetAreasID(targetAreaData[targetAreaData.length - 1].id + 1);
  }, [data, Activity_data, Proposal_data, Contract_data, Job_data, Photo_Gallery_data, Representative_data,
    document_data, people_data, users, budget_data, service_data, product_data,
    top_product_data, no_focus_product_data, target_areas_data, Estimate_data,
    Following_data]);

  useEffect(() => {
    if (CRMEstimate && CRMProducts?.length && !EstimateData) {

      let EstimatesubmissionDataClone = {};

      CRMEstimate?.filter((CurrentEstimate) => CurrentEstimate?.id === crm_estimate_id)?.map((CurrentEstimate) => {
        EstimatesubmissionDataClone = Object.assign({}, CurrentEstimate);
        EstimatesubmissionDataClone.Budgets = {};

        CRMMonthlyBudget?.map((CurrentBudget, i) => {
          let tempid = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
          if (tempid == '')
            tempid = null;
          else
            tempid = parseInt(tempid);

          let product_id = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
          if (product_id == '')
            product_id = null;
          else
            product_id = parseInt(product_id);

          let local_id = CRMProducts?.map((CurrentProduct) => { if (CurrentBudget?.product_id === CurrentProduct?.id) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");

          EstimatesubmissionDataClone.Budgets[i] = Object.assign({}, {
            "product_name": CurrentBudget?.product_name, "LocalID": local_id?.toLowerCase()?.replaceAll(" ", ""), "monthly_budget": parseFloat(CurrentBudget?.monthly_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
              style: 'decimal',
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            }),
            "budget_cycles_product_id": tempid, estimate_id: crm_estimate_id, product_id: product_id
          })
        })

        setEstimatesubmissionData(EstimatesubmissionDataClone);
        setEstimateData(true);
        setRepresentative(CurrentEstimate?.representative_id);
      })
    }

  }, [EstimateData, CRMProducts, CRMMonthlyBudget]);

  const AddCompetitor = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestCompetitorID, customers_top_competitors: '', top_competitors_url: '' })
    sethighestCompetitorID(highestCompetitorID + 1)
    setcompetitorData({ ...competitorData, [competitorData?.length || Object.keys(competitorData)?.length]: NewArray });
  }

  const AddTargetArea = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestTopTargetAreasID, customer_top_target_area: '', customer_top_target_type: '', product_id: product_id })
    sethighestTopTargetAreasID(highestTopTargetAreasID + 1)
    settargetAreaData({ ...targetAreaData, [targetAreaData?.length || Object.keys(targetAreaData)?.length]: NewArray });
  }

  const AddProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestProductID, product_focus: '', product_id: product_id })
    sethighestProductID(highestProductID + 1)
    setproductData({ ...productData, [productData?.length || Object.keys(productData)?.length]: NewArray });
  }

  const AddNoFocusProduct = (product_id) => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestNoFocusProductID, no_product_focus: '', product_id: product_id })
    sethighestNoFocusProductID(highestNoFocusProductID + 1)
    setnoFocusProductData({ ...noFocusProductData, [noFocusProductData?.length || Object.keys(noFocusProductData)?.length]: NewArray });
  }

  const RemoveCompetitor = (key) => e => {
    setcompetitorData(Object.keys(competitorData)?.filter((Competitor) => competitorData[Competitor].id !== key)?.map((Competitor) => competitorData[Competitor]));
  };

  const RemoveProduct = (key) => e => {
    setproductData(Object.keys(productData)?.filter((CurrentProduct) => productData[CurrentProduct].id !== key)?.map((CurrentProduct) => productData[CurrentProduct]));
  };

  const RemoveNoFocusProduct = (key) => e => {
    setnoFocusProductData(Object.keys(noFocusProductData)?.filter((noFocusProduct) => noFocusProductData[noFocusProduct].id !== key)?.map((noFocusProduct) => noFocusProductData[noFocusProduct]));
  };

  const RemoveTargetArea = (key) => e => {
    settargetAreaData(Object.keys(targetAreaData)?.filter((newTargetArea) => targetAreaData[newTargetArea].id !== key)?.map((newTargetArea) => targetAreaData[newTargetArea]));
  };

  function updateTopCompetitors(index, key, e) {
    let EstimatesubmissionDataClone = Object.assign({}, competitorData);
    Object.keys(EstimatesubmissionDataClone).map((item, i) => {
      if (index === i) {
        if (key === "customers_top_competitors") {
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { customers_top_competitors: e.target.value });
        }
        else
          EstimatesubmissionDataClone[i] = Object.assign({}, EstimatesubmissionDataClone[i], { top_competitors_url: e.target.value });
      }
      setcompetitorData({ ...EstimatesubmissionDataClone });
    })
  };

  function updateTopProducts(index, e, product_id) {
    let ProductsubmissionDataClone = Object.assign({}, productData);
    Object.keys(ProductsubmissionDataClone).map((item, i) => {
      if (index === i && product_id === ProductsubmissionDataClone[i]?.product_id) {
        ProductsubmissionDataClone[i] = Object.assign({}, ProductsubmissionDataClone[i], { product_focus: e.target.value, product_id: product_id });
      }
      setproductData({ ...ProductsubmissionDataClone });
    })
  };

  function updateNoFocusProducts(index, e, product_id) {
    let NoFocussubmissionDataClone = Object.assign({}, noFocusProductData);
    Object.keys(NoFocussubmissionDataClone).map((item, i) => {
      if (index === i && product_id === NoFocussubmissionDataClone[i]?.product_id) {
        NoFocussubmissionDataClone[i] = Object.assign({}, NoFocussubmissionDataClone[i], { no_product_focus: e.target.value, product_id: product_id });
      }
      setnoFocusProductData({ ...NoFocussubmissionDataClone });
    })
  };

  function updateTargetAreas(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_area: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  function updateTargetType(index, e, product_id) {
    let TargetAreasubmissionDataClone = Object.assign({}, targetAreaData);
    Object.keys(TargetAreasubmissionDataClone).map((item, i) => {
      if (index === i && product_id === TargetAreasubmissionDataClone[i]?.product_id) {
        TargetAreasubmissionDataClone[i] = Object.assign({}, TargetAreasubmissionDataClone[i], { customer_top_target_type: e.target.value, product_id: product_id });
      }
      settargetAreaData({ ...TargetAreasubmissionDataClone });
    })
  };

  const classes = useStyles();

  const [submitCreateCRMProposalFromEstimate] = useMutation(CREATE_CRM_PROPOSAL_FROM_ESTIMATE,
    {
      onCompleted: async (data) => {
        if (data?.createCRMProposalFromEstimate !== -1) {
          setTimeout(() => navigate("/admin/crmproposaldetail?customer=" + customer_id + "&id=" + crm_customer_id + "&peopleid=null&proposalid=" + data?.createCRMProposalFromEstimate), 3000);
        }
      }
    });

  const [submitCreateCRMEstimate] = useMutation(
    CREATE_CRM_ESTIMATE,
    {
      onCompleted: async (EstimateData) => {
        // If user added document activity notes
        if (CRMActivitySubmission && EstimateData?.createCRMEstimate?.update_status !== 1) {
          let newActivityNotes = '';

          if (CRMActivitySubmission?.activity_notes?.indexOf(";base64,") > -1) {
            newActivityNotes = await SwapScreenshotsReplaceWithS3URL(CRMActivitySubmission?.activity_notes, customer_id, 0 );
          }

          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                ...omit(CRMActivitySubmission, ["__typename", "activity_completed", "first_name", "last_name", "activity_date", "activity_notes"]),
                users_id: user_id,
                activity_type: "Estimate",
                activity_description: "Updated Estimate " + EstimatesubmissionData?.estimate_name + " for " + CRMCustomers?.business_name,
                estimate_id: EstimateData?.createCRMEstimate?.id,
                representative_id: EstimateData?.createCRMEstimate?.representative_id,
                crm_customers_id: crm_customer_id,
                activity_date: CRMActivitySubmission?.activity_date?.indexOf("-") === -1 ? moment(new Date(parseInt(CRMActivitySubmission?.activity_date))).format("YYYY-MM-DD hh:mm:ss") : CRMActivitySubmission?.activity_date,
                activity_notes: newActivityNotes ? newActivityNotes : CRMActivitySubmission?.activity_notes
              }
            }
          });
        }else
        {
          LoadNewNotifications();
          refetch();
          refetchActivity();
          refetchProposal();
          refetchContracts();
          refetchJobs();
          refetchGallery();
          refetchPhotos();
          refetchRepresentatives();
          refetchDocument();
          refetchPeople();
          refetchEstimates();
        }

        if(EstimateData?.createCRMEstimate?.estimate_status === 3)
          window.location.reload();
      }
    }
  );

  const updateFieldHandler = (key) => e => {
    if (key === "referrer_name") {
      if (CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.length) {
        CRMCustomersReferrer?.filter(CurrentCustomer => CurrentCustomer?.business_name === e.target.value)?.map((CurrentCustomer) => {
          setCRMCustomers({
            ...CRMCustomers,
            [key]: e.target.value,
            ["referrer_name"]: CurrentCustomer?.business_name
          });

          setreferrer_name(e.target.value);
          setReferrerFound(true);
        })
      }
      else {
        setCRMCustomers({
          ...CRMCustomers,
          [key]: e.target.value,
          ["referrer_id"]: 0
        });

        setreferrer_name(e.target.value);
        setReferrerFound(false);
      }
    }
    else {
      setCRMCustomers({
        ...CRMCustomers,
        [key]: e.target.value
      });
    }
  };

  const updateSICFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateCountryFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateReferrerHandler = (key, id, name) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: id,
      ["referrer_name"]: name
    });

    setReferrerFound(true);
  };

  const updateCityHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateNAICSFieldHandler = (key, e) => {
    setCRMCustomers({
      ...CRMCustomers,
      [key]: e
    });
  };

  const updateEstimateFieldHandler = (key) => e => {
    let BudgetFound = false;

    if (EstimatesubmissionData?.Budgets !== undefined) {
      Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => {
        if (key?.split("_")[0] === EstimatesubmissionData?.Budgets[index]?.LocalID)
          BudgetFound = true;
      })
    }

    if (key === "estimate_status" && typeof e === 'string') {
      EstimateStatuses?.map((CurrentEstimate) => {
        if (CurrentEstimate?.label === e) {
          setEstimatesubmissionData({
            ...EstimatesubmissionData,
            [key]: CurrentEstimate?.value
          });
        }
      })
    } else if (key === "estimate_status") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e
      });
    }
    else if (key === "representative_id" || key === "assigned_to" || key === "job_id"
      || key === "photo_gallery_id" || key === "contract_status") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e
      });
    } else if (key?.indexOf("monthlybudget") > -1) {
      if (EstimatesubmissionData?.Budgets === undefined || BudgetFound === false) {
        let tempid = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.budget_cycles_product_id) { return parseInt(CurrentProduct?.budget_cycles_product_id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (tempid == '')
          tempid = null;
        else
          tempid = parseInt(tempid);

        let product_id = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.id) { return parseInt(CurrentProduct?.id) } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        if (product_id == '')
          product_id = null;
        else
          product_id = parseInt(product_id);

        let product_name = CRMProducts?.map((CurrentProduct) => { if (key?.split("_")[0] === CurrentProduct?.product_name?.toLowerCase()?.replaceAll(" ", "")) { if (CurrentProduct?.product_name) { return CurrentProduct?.product_name } else { return null } } })?.filter(CurrentProduct => CurrentProduct !== undefined)?.join("");
        setEstimatesubmissionData({
          ...EstimatesubmissionData,
          ["Budgets"]: { [EstimatesubmissionData?.Budgets ? Object.keys(EstimatesubmissionData?.Budgets)?.length : 0]: { "product_name": product_name, "LocalID": key?.split("_")[0], "monthly_budget": e.target.value, "budget_cycles_product_id": tempid, product_id: product_id }, ...EstimatesubmissionData?.Budgets }
        });
      } else if (BudgetFound) {
        Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => {
          if (EstimatesubmissionData?.Budgets[index]?.LocalID === key?.split("_")[0]) {
            let NewArray = Object.assign({}, { "product_name": EstimatesubmissionData?.Budgets[index]?.product_name, "LocalID": EstimatesubmissionData?.Budgets[index]?.LocalID, "monthly_budget": e.target.value, "budget_cycles_product_id": EstimatesubmissionData?.Budgets[index]?.budget_cycles_product_id, product_id: EstimatesubmissionData?.Budgets[index]?.product_id })
            setEstimatesubmissionData({
              ...EstimatesubmissionData,
              ["Budgets"]: { [index]: NewArray, ...Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, i) => { if (index !== i) return EstimatesubmissionData?.Budgets[i] })?.filter(CurrentBudget => CurrentBudget !== undefined) }
            });
          }
        })
      }
    } else {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        [key]: e.target.value
      });
    }
  };

  const updatePeopleNameFieldHandler = (value, object) => {
    if (object === "Contract") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setEstimatesubmissionData({
            ...EstimatesubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setDocumentsubmissionData({
            ...DocumentsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setProposalsubmissionData({
            ...ProposalsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setGallerysubmissionData({
            ...GallerysubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setJobsubmissionData({
            ...JobsubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setCRMActivitySubmission({
            ...CRMActivitySubmission,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    } else if (object === "Estimate") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        "people_id": null
      })

      CRMPeople?.forEach((CurrentPerson) => {
        if (value === CurrentPerson?.first_name + " " + CurrentPerson?.last_name) {
          setEstimatesubmissionData({
            ...EstimatesubmissionData,
            "people_id": CurrentPerson?.id
          })
          setPersonFound(true);
        }
      })
    }
  };

  const updatePeopleFieldHandler = (key, object) => e => {
    if (object === "Estimate") {
      setEstimatesubmissionData({
        ...EstimatesubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Contract") {
      setContractsubmissionData({
        ...ContractubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Document") {
      setDocumentsubmissionData({
        ...DocumentsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Proposal") {
      setProposalsubmissionData({
        ...ProposalsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Photo") {
      setGallerysubmissionData({
        ...GallerysubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Job") {
      setJobsubmissionData({
        ...JobsubmissionData,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    } else if (object === "Activity") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        "people_id": null,
        [key]: e.target.value
      })
      setPersonFound(false);
    }
  };
  const updateActivityFieldHandler = (key) => e => {
    if (key === "activity_completed") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (key === "activity_date") {
      setCRMActivitySubmission({
        ...omit(CRMActivitySubmission, ["activity_completed"]),
        [key]: e.target.value,
        ["activity_completed"]: 0
      });
    } else if (key === "assigned_to" || key === "representative_id" || key === "activity_notes") {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e
      });
    } else {
      setCRMActivitySubmission({
        ...CRMActivitySubmission,
        [key]: e.target.value
      });
    }
  };

  const CreateProposalfromEstimate = async (EstimateID) => {
    await submitCreateCRMProposalFromEstimate({
      variables: {
        customer_id,
        estimate_id: EstimateID
      }
    })
  }

  const updateEstimateDatabase = async () => {
    if (!EstimatesubmissionData?.estimate_name) {
      alert("Estimate Name is Required");
      return false;
    }

    if (!EstimatesubmissionData?.Budgets) {
      alert("Product Budgets are Required");
      return false;
    }

    if (EstimatesubmissionData?.products?.filter((CurrentProduct) => CurrentProduct)?.length !== Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => CurrentBudget)?.length) {
      alert("All Product Budgets are Required");
      return false;
    }

    if (EstimatesubmissionData) {
      await submitCreateCRMEstimate({
        variables: {
          customer_id,
          EstimateData: {
            ...omit(EstimatesubmissionData, ["__typename", "activity_completed", "representative_id", "first_name", "last_name", "people_name", "contract_status"]),
            users_id: user_id,
            crm_customers_id: crm_customer_id,
            services: EstimatesubmissionData?.services ? EstimatesubmissionData?.services?.map((SelectedService) => { const ReturnedValue = CRMServices?.map((CurrentService) => { if (SelectedService === CurrentService?.service_name) { return CurrentService?.id } })?.filter(CurrentService => CurrentService !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            products: EstimatesubmissionData?.products ? EstimatesubmissionData?.products?.map((SelectedProduct) => { const ReturnedValue = CRMProducts?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : "",
            representative_id: EstimatesubmissionData?.representative_id ? EstimatesubmissionData?.representative_id : 1,
            estimate_status: typeof EstimatesubmissionData?.estimate_status === 'string' ? parseInt(EstimateStatuses?.map((CurrentEstimate) => { if (CurrentEstimate?.label === EstimatesubmissionData?.estimate_status) return CurrentEstimate?.value })?.filter(CurrentEstimate => CurrentEstimate !== undefined)?.join("")) : EstimatesubmissionData?.estimate_status ? EstimatesubmissionData?.estimate_status : null,
            TopCompetitors: Object.keys(competitorData).map((keyName, index) => { return omit(competitorData[keyName], ["__typename"]) }),
            TopProducts: Object.keys(productData).map((keyName, index) => { return omit(productData[keyName], ["__typename"]) }),
            NoFocusProducts: Object.keys(noFocusProductData).map((keyName, index) => { return omit(noFocusProductData[keyName], ["__typename"]) }),
            TargetAreas: Object.keys(targetAreaData).map((keyName, index) => { return omit(targetAreaData[keyName], ["__typename"]) }),
            Budgets: EstimatesubmissionData?.Budgets ? Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, index) => { return omit(EstimatesubmissionData?.Budgets[index], "LocalID") }) : null,
            business_name: CRMCustomers?.business_name,
            business_address: CRMCustomers?.office_address_line_one +
              CRMCustomers?.office_address_line_two + " " +
              CRMCustomers?.office_city + " " +
              CRMCustomers?.office_state + " " +
              CRMCustomers?.office_zip + " " +
              (CRMCustomers?.office_country !== null ? CRMCustomers?.office_country : ""),
            business_type: CRMCustomers?.industry,
            website_url: CRMCustomers?.website
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const updateDatabase = async (profile_image, customer_status) => {
    if (CRMCustomers?.business_name === OriginalCRMCustomers?.business_name &&
      CRMCustomers?.office_phone_number === OriginalCRMCustomers?.office_phone_number &&
      CRMCustomers?.office_address_line_one === OriginalCRMCustomers?.office_address_line_one &&
      CRMCustomers?.office_address_line_two === OriginalCRMCustomers?.office_address_line_two &&
      CRMCustomers?.office_city === OriginalCRMCustomers?.office_city &&
      CRMCustomers?.office_country === OriginalCRMCustomers?.office_country &&
      CRMCustomers?.office_state === OriginalCRMCustomers?.office_state &&
      CRMCustomers?.office_zip === OriginalCRMCustomers?.office_zip &&
      CRMCustomers?.website === OriginalCRMCustomers?.website &&
      CRMCustomers?.industry === OriginalCRMCustomers?.industry &&
      CRMCustomers?.revenue === OriginalCRMCustomers?.revenue &&
      CRMCustomers?.sic_code === OriginalCRMCustomers?.sic_code &&
      CRMCustomers?.naics_code === OriginalCRMCustomers?.naics_code &&
      CRMCustomers?.employees === OriginalCRMCustomers?.employees &&
      CRMCustomers?.established === OriginalCRMCustomers?.established &&
      !profile_image && !customer_status) {
      return
    }

    if (customer_status !== OriginalCRMCustomers?.customer_status && OriginalCRMCustomers?.customer_status) {
      if (!window.confirm("Are you sure you want to switch the Customer Status?"))
        return
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (CRMCustomers) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(CRMCustomers, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status", "referrer_name"]),
            office_phone_number: `${CRMCustomers?.office_phone_number !== undefined ? CRMCustomers?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replaceAll(/[^0-9.]+/g, "") : "",
            status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
            customer_status: customer_status ? customer_status : null,
            profile_image: profile_image ? profile_image : null,
            users_id: user_id,
            lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
  };

  const social_columns = [
    {
      Header: "Social",
      accessor: "social_channel"
    },
    {
      Header: "Remove"
    }
  ];

  function updateSocialssocial_channel_url(index, e) {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_url: e.target.value });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const updateSocialsocial_channel_id = (index) => e => {
    Object.keys(CRMSocials)?.map((item, i) => {
      if (index === i) {
        CRMSocials[i] = Object.assign({}, CRMSocials[i], { social_channel_id: e });
      }
      setCRMSocials({ ...CRMSocials });
    })
  };

  const AddSocial = () => {
    let NewArray = [];
    NewArray = Object.assign({}, { id: highestSocialID, social_channel_id: '', social_channel_url: '', people_id: null, crm_customer_id: crm_customer_id })
    sethighestSocialID(highestSocialID + 1)
    setCRMSocials({ ...CRMSocials, [Object.keys(CRMSocials)?.length]: NewArray });
  }

  const [updateSocials] = useMutation(
    UPDATE_CURRENT_SOCIALS,
    {
      skip: !state.customers?.selected?.id,
      skip: !CRMSocials
    });

  const [RemoveCustomerSocial] = useMutation(
    REMOVE_CUSTOMER_SOCIAL
  );

  const RemoveSocial = (key) => e => {
    RemoveCustomerSocial({
      fetchPolicy: "no-cache",
      variables: {
        customer_id: state.customers?.selected?.id,
        id: key
      },
      onCompleted: () => {
        setCRMSocials(Object.keys(CRMSocials)?.map((Social, index) => {
          if (CRMSocials[index].id !== key)
            return CRMSocials[index]
        })?.filter(Social => Social !== undefined))

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    });
  };

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (CRMCustomers?.length) {
    csvData.push(
      ...CRMCustomers.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: () => {
        LoadNewNotifications();
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEstimates();
      }
    }
  );

  const [submitUpdateCRMActivity] = useMutation(
    UPDATE_CRM_ACTIVITY,
    {
      onCompleted: () => {
        setKey(key + 1);
        LoadNewNotifications();
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEstimates();
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CONTRACT,
    {
      onCompleted: () => {
        refetch();
        refetchActivity();
        refetchProposal();
        refetchContracts();
        refetchJobs();
        refetchGallery();
        refetchPhotos();
        refetchRepresentatives();
        refetchDocument();
        refetchPeople();
        refetchEstimates();

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const isLoading = loading || deleting || !customer_id;

  const userInfo = {};

  let ShownActivityCount = 0;

  return (
    <>
      {LinearCustomer || LinearCustomer === undefined ?
        <>
          <Card>
            <CardBody>
              <div style={{ display: "block" }}>
                <div style={{ float: "left" }}>
                  <label
                    style={{ zIndex: 5, borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "5px", marginBottom: "2px", fontSize: "30px", fontFamily: "Inter", color: "#666666" }}
                  ><TaskOutlined /> {EstimatesubmissionData?.estimate_name || ""}</label>
                </div>
              </div>
              {crm_user_level === "crm_admin" && (
                <Tooltip title="Delete Contract" placement="top">
                  <Button style={{ color: Hover === true ? "red" : "#000", float: "right", backgroundColor: "#FFF", marginTop: "13px" }} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} onClick={() => {
                    if (window.confirm("Are you sure?")) {
                      submitDeleteRequest({ skip: !customer_id, variables: { customer_id: customer_id, id: EstimatesubmissionData?.id } });
                      navigate("/admin/contracts?customer=" + customer_id);
                    }
                  }}>
                    <FontAwesomeIcon size="lg" icon={Hover ? faDumpsterFire : faDumpster} onMouseOver={() => { setHover(true) }} onMouseLeave={() => { setHover(false) }} />
                  </Button>
                </Tooltip>
              )}
            </CardBody>
          </Card>
          <GridContainer marginTop="20px">
            <GridItem xs={12} sm={12} md={4}>
              {crm_customer_id !== null && (
                <Card profile>
                  <div style={{ display: "inline-flex", alignItems: "right", float: "right" }}>
                  <div style={{ float: "left", marginLeft: "10px", marginTop: "10px" }}>{window.location.href.indexOf("crmcustomerdetail") === -1 && window.location.href.indexOf("crmleaddetail") === -1 ? <a href={`/admin/crm${CRMCustomers?.status === 1 ? `customer` : `lead`}detail?customer=${customer_id}&id=${crm_customer_id}`} >{CRMCustomers?.status === 1 ?<HomeWorkOutlinedIcon />:<FlagOutlinedIcon />} View Profile</a> : <div style={{ visibility: "hidden" }}>{CRMCustomers?.status === 1 ?<HomeWorkOutlinedIcon />:<FlagOutlinedIcon />} View Profile</div>}</div>
                    <CardAvatar profile style={{ display: "inline-flex", alignItems: "right", float: "right !important" }}>
                      <Dropzone
                        path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                        accept={{
                          'image/jpeg': [".jpeg"],
                          'image/png': [".png"]
                        }}
                        onChange={(value) => {
                          updateFieldHandler("profile_image")({ target: { value } });
                          updateDatabase(value);
                        }}
                      >
                        {() => (
                          <Avatar
                            url={CRMCustomers?.profile_image || CustomersDefaultImage}
                            size={130}
                          />
                        )}
                      </Dropzone>
                    </CardAvatar>

                    <div style={{ float: "right", marginLeft: "25px", marginRight: "10px", marginTop: "10px" }}> <a href="#"
                      onClick={
                        async () => {
                          if (Following > 0) {
                            await submitUpdateCRMFollowingRequest({
                              variables: {
                                customer_id: customer_id,
                                object_type: "Customer",
                                object_id: crm_customer_id,
                                add: 0,
                                id: Following
                              }
                            });
                          } else {
                            await submitUpdateCRMFollowingRequest({
                              variables: {
                                customer_id: customer_id,
                                object_type: "Customer",
                                object_id: crm_customer_id,
                                add: 1,
                              }
                            });
                          }
                        }} >{Following > 0 ? <><SensorsOffOutlinedIcon /> - Unfollow</> : <>&nbsp;&nbsp;&nbsp;<SensorsOutlinedIcon /> + Follow</>}</a></div></div>
                  <CardBody>
                    <h6 className={classes.cardCategory}>
                      Ideal image upload size is 400 x 400
                    </h6>
                    <hr style={{ color: "grey", borderColor: "grey", borderWidth: 3 }} />
                  <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Business Name</b></label>
                  <input
                    id="business_name"
                    maxLength={255}
                    type="text"
                    value={CRMCustomers?.business_name || ""}
                    onChange={updateFieldHandler("business_name")}
                    onBlur={() => updateDatabase()}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", textAlign:"center" }}
                  />
                  <label style={{ marginTop: "30px", fontSize: "15pt" }}><b>Address</b></label>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Line 1</label>
                      <input
                        id="office_address_line_one"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.office_address_line_one || ""}
                        onChange={updateFieldHandler("office_address_line_one")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Line 2</label>
                      <input
                        id="office_address_line_two"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.office_address_line_two || ""}
                        onChange={updateFieldHandler("office_address_line_two")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>City</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_city"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateCityHandler("office_city", newValue.key);
                          }}
                          value={CRMCustomers?.office_city ? { key: CRMCustomers?.office_city, label: CRMCustomers?.office_city } :
                            { key: "", label: "" }}
                          options={City?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { updateFieldHandler("office_city")(e); }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>State</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_state"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateStateHandler("office_state", newValue.key);
                          }}
                          value={States?.filter((CurrentState) => CurrentState?.name === CRMCustomers?.office_state)?.map((CurrentState) => {
                            return { key: CurrentState?.name, label: CurrentState?.name }
                          })[0] || ""}
                          options={States?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("office_state")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Zip</label>
                      <input
                        id="office_zip"
                        maxLength={255}
                        type="text"
                        value={CRMCustomers?.office_zip || ""}
                        onChange={updateFieldHandler("office_zip")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>Country</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="office_country"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateCountryFieldHandler("office_country", newValue.key);
                          }}
                          value={CRMCustomers?.office_country ? { key: CRMCustomers?.office_country, label: CRMCustomers?.office_country } :
                            { key: "", label: "" }}
                          options={Countries?.map(({ name }) => ({
                            key: name,
                            label: name
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("office_country")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Phone</label>
                      <input
                        id="office_phone_number"
                        maxLength={255}
                        type="text"
                        value={FormatPhone(CRMCustomers?.office_phone_number) || ""}
                        onChange={updateFieldHandler("office_phone_number")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Website</label>
                      <input
                        id="website"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.website || ""}
                        onChange={updateFieldHandler("website")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "80%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Industry</label>
                      <input
                        id="industry"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.industry || ""}
                        onChange={updateFieldHandler("industry")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Revenue</label>
                      <input
                        id="revenue"
                        maxLength={15}
                        type="text"
                        value={revenue === '' && CRMCustomers?.revenue !== undefined && CRMCustomers?.revenue !== '' ? FormatCurrencyWithoutUpdatingState(CRMCustomers?.revenue) : revenue}
                        onBlur={() => {
                          FormatCurrency();
                          if (CRMCustomers?.revenue)
                            updateDatabase();
                        }}
                        onFocus={() => {
                          setCRMCustomers({
                            ...CRMCustomers,
                            ["revenue"]: ''
                          });
                          setrevenue('');
                        }}
                        onChange={(e) => { updateFieldHandler("revenue", e); updateCustomerRevenueValueFieldHandler(e) }}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>SIC Code</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="sic_code"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateSICFieldHandler("sic_code", newValue.key);
                          }}
                          value={SICCodes?.filter((CurrentSICCode) => CurrentSICCode?.sic_code === CRMCustomers?.sic_code)?.map((CurrentSICCode) => {
                            return { key: CurrentSICCode?.sic_code, label: CurrentSICCode?.sic_code + " - " + CurrentSICCode?.description }
                          })[0] || ""}
                          options={SICCodes?.map(({ sic_code, description }) => ({
                            key: sic_code,
                            label: sic_code + " - " + description
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("sic_code")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>NAICS Code</label>
                      <Stack spacing={2}>
                        <Autocomplete
                          freeSolo
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          disableClearable
                          id="naics_code"
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                          onChange={(event, newValue) => {
                            updateNAICSFieldHandler("naics_code", newValue.key);
                          }}
                          value={NAICSCodes?.filter((CurrentNAICSCode) => CurrentNAICSCode?.naics_code === CRMCustomers?.naics_code)?.map((CurrentNAICSCode) => {
                            return { key: CurrentNAICSCode?.naics_code, label: CurrentNAICSCode?.naics_code + " - " + CurrentNAICSCode?.title }
                          })[0] || ""}
                          options={NAICSCodes?.map(({ naics_code, title }) => ({
                            key: naics_code,
                            label: naics_code + " - " + title
                          })) || []}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;

                            return filtered;
                          }}
                          getOptionLabel={(option) => {
                            if (option === "")
                              return "";
                            // Regular option
                            return option.label;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.label}</li>}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { textAlign: "center", width: "100%" }
                              }}
                              onChange={e => { if (e.target.value === "") { updateFieldHandler("naics_code")(e); } }}
                              onBlur={() => updateDatabase()}
                              maxLength={255}
                            />
                          )}
                        />
                      </Stack>
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Employees</label>
                      <input
                        id="employees"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.employees || ""}
                        onChange={updateFieldHandler("employees")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both" }}></div>
                    <div style={{ display: "block" }}>
                      <label style={{ marginTop: "15px", float: "left" }}>Established</label>
                      <input
                        id="established"
                        maxLength={2000}
                        type="text"
                        value={CRMCustomers?.established || ""}
                        onChange={updateFieldHandler("established")}
                        onBlur={() => updateDatabase()}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "78%", marginTop: "10px", float: "right" }}
                      />
                    </div>
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>{CRMCustomers?.status === 1 ? "Customer" : "Lead"} Status:</label>
                    <Select
                      selected={CRMCustomers?.customer_status || 0}
                      onSelect={(value) => {
                        updateFieldHandler("customer_status")(value);
                        if (value !== "select") {
                          updateDatabase("", value);
                        }
                      }}
                      options={CustomerStatuses?.map(({ label, value }) => ({
                        key: value,
                        label: label
                      }))}
                      style={{ marginBottom: 20, float: "left", height: "40px" }}
                    />
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <div>
                      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Assign To:</label>
                      <Select
                        options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                          key: id,
                          label: first_name + " " + last_name
                        }))}
                        selected={CRMCustomers?.assigned_to || ""}
                        onSelect={async (value) => {
                          if (value !== OriginalCRMCustomers?.assigned_to && OriginalCRMCustomers?.assigned_to) {
                            if (!window.confirm("Are you sure you want to switch the Assigned To?"))
                              return
                          }

                          updateFieldHandler("assigned_to")(value);

                          await submitUpdateCRMRequest({
                            variables: {
                              customer_id,
                              BusinessData: {
                                ...omit(CRMCustomers, ["__typename", "update_time", "last_name", "first_name", "status", "revenue", "lead_status", "referrer_name"]),
                                id: crm_customer_id,
                                assigned_to: value,
                                revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
                                status: CRMCustomers?.status === undefined ? "1" : `${CRMCustomers?.status}`,
                                lead_status: CRMCustomers?.lead_status && typeof CRMCustomers?.lead_status === 'string' ? parseInt(CustomerStatuses.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === CRMCustomers?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : CRMCustomers?.lead_status ? CRMCustomers?.lead_status : null
                              }
                            }
                          });

                          const notify = options => {
                            const key = enqueueSnackbar(options?.text, {
                              variant: options?.variant,
                              persist: true
                            });
                            setTimeout(() => {
                              closeSnackbar(key);
                            }, options?.delay || 2000);
                          }
                          notify({
                            text: "Information has been successfully submitted",
                            variant: "success"
                          });
                        }}
                        style={{ marginBottom: 20, height: "40px", float: "left" }}
                      />
                    </div>
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                    <label style={{ marginTop: "30px", float: "left", textAlign: "left", width: "22%" }}>{customer_id === 38 ? "Para" : "Referrer"}</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="referrer_id"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(e, newValue) => {
                          updateReferrerHandler("referrer_id", newValue.key, newValue.label);
                        }}
                        value={CRMCustomers?.referrer_name ? { key: CRMCustomers?.referrer_id, label: CRMCustomers?.referrer_name } :
                          { key: "", label: "" }}
                        options={CRMCustomersReferrer?.map(({ business_name, id }) => ({
                          key: id,
                          label: business_name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("referrer_name")(e); }}
                            onBlur={() => updateDatabase()}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                    {CRMCustomers?.referrer_name && !ReferrerFound ?
                      <label style={{ marginLeft: "65px", color: "red" }}>Referrer not found</label> : ""}
                    <div style={{ clear: "both", marginBottom: "20px" }}></div>
                  </CardBody>
                </Card>
              )}
              {AllSocials?.length ?
                <>
                  <Card>
                    <CardBody>
                      <label>Social Channels</label>
                      <br />
                      <br />
                      <TableContainer>
                        <Table stickyHeader>
                          <TableRow>
                            {social_columns?.map((datacolumns) => (
                              <TableCell style={{ textAlign: "left", padding: "0px", margin: "0px" }}><label className={classes.selectFormControl}>{datacolumns?.Header}</label></TableCell>
                            ))}
                          </TableRow>
                          {CRMSocials ? Object.keys(CRMSocials)?.map((keyName, index) => (
                            <>
                              <TableRow key={CRMSocials[keyName]?.id}>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <Select
                                    options={AllSocials?.map(({ id, social_channel }) => ({
                                      key: id,
                                      label: social_channel
                                    }))}
                                    selected={CRMSocials[keyName].social_channel_id || 0}
                                    onSelect={updateSocialsocial_channel_id(index, "social_channel_id")}
                                    style={{ marginBottom: 2, height: "40px" }}
                                  />&nbsp;&nbsp;
                                  {CRMSocials[keyName]?.social_channel_url ? <a target="_blank" href={CRMSocials[keyName].social_channel_url}>Go To Link</a> : ""}
                                </TableCell>
                                <TableCell style={{ textAlign: "left", padding: "5px", margin: "0px" }}>
                                  <a style={{ cursor: "pointer" }} onClick={RemoveSocial(CRMSocials[keyName]?.id)}><DeleteForeverRoundedIcon /></a>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ padding: "5px", margin: "0px", width: "100%" }} colSpan="2">
                                  <CustomInput
                                    id={`customers_top_products${CRMSocials[keyName].id}`}
                                    maxLength="2048"
                                    type="text"
                                    key={`customers_top_products${CRMSocials[keyName].id}`}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: event => { updateSocialssocial_channel_url(index, event) },
                                      defaultValue: CRMSocials[keyName]?.social_channel_url ? CRMSocials[keyName].social_channel_url : ''
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            </>
                          )) : ""}
                        </Table>
                      </TableContainer>
                      <br />
                      <a style={{ cursor: "pointer" }} onClick={AddSocial}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add Social Channel</a>
                      <div style={{ clear: "both", height: "30px" }}></div>
                      <SaveButton
                        color="orange"
                        type="submit"
                        onClick={() => {
                          let ErrorPresent = false;
                          Object.keys(CRMSocials)?.map((keyName, index) => {
                            if (CRMSocials[keyName].social_channel_id === null || CRMSocials[keyName].social_channel_id === '') {
                              alert("Please Select a Social Channel");
                              ErrorPresent = true;
                              return;
                            }
                            else if (CRMSocials[keyName].social_channel_url === null || CRMSocials[keyName].social_channel_url === '') {
                              alert("Please Enter a Social URL");
                              ErrorPresent = true;
                              return;
                            }

                            updateSocials({
                              variables: {
                                customer_id: state.customers?.selected?.id,
                                data: omit(CRMSocials[keyName], ["__typename"])
                              }
                            })
                          })

                          if (ErrorPresent === false) {
                            refetchSocial();

                            const notify = options => {
                              const key = enqueueSnackbar(options?.text, {
                                variant: options?.variant,
                                persist: true
                              });
                              setTimeout(() => {
                                closeSnackbar(key);
                              }, options?.delay || 2000);
                            }
                            notify({
                              text: "Information has been successfully submitted",
                              variant: "success"
                            });
                          }
                        }
                        }
                      >
                        Submit
                      </SaveButton>
                    </CardBody>
                  </Card>
                </>
                : ""}
              {/* PHOTO GALLERY */}
              {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customer_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Photo Gallery</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customer_id === crm_customer_id).map((CurrentPhoto, index) => {
                      if (index > 4 && showMorePhotos === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentPhoto?.photo_gallery_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_description ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Description</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentPhoto?.photo_gallery_description}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentPhoto?.photo_gallery_last_updated ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentPhoto?.photo_gallery_last_updated))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMPhotoGallery?.filter((CurrentPhoto) => CurrentPhoto?.crm_customer_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMorePhotos) {
                          setshowMorePhotos(false);
                        } else {
                          setshowMorePhotos(true)
                        }
                      }}>
                        {showMorePhotos ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* DOCUMENTS */}
              {CRMDocument?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Documents</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMDocument?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).map((CurrentContract, index) => {
                      if (index > 4 && showMoreDocuments === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentContract?.documents_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.documents_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.documents_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.documents_update_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Last Updated</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentContract?.documents_update_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMDocument?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreDocuments) {
                          setshowMoreDocuments(false);
                        } else {
                          setshowMoreDocuments(true)
                        }
                      }}>
                        {showMoreDocuments ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* CONTRACT */}
              {CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Contracts</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).map((CurrentContract, index) => {
                      if (index > 4 && showMoreContracts === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentContract?.contract_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentContract?.contract_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentContract?.contract_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_value}</label>
                            </div> : ""}
                            {CurrentContract?.contract_start_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Start Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_start_date}</label>
                            </div> : ""}
                            {CurrentContract?.contract_end_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>End Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentContract?.contract_end_date}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMContract?.filter((CurrentContract) => CurrentContract?.crm_customer_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreContracts) {
                          setshowMoreContracts(false);
                        } else {
                          setshowMoreContracts(true)
                        }
                      }}>
                        {showMoreContracts ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
              {/* PROPOSAL */}
              {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customer_id === crm_customer_id).length > 0 && (
                <Card profile>
                  <CardBody>
                    <label style={{ marginTop: "30px" }}>Proposals</label>
                    <br />
                    <br />
                    {crm_customer_id ? CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customer_id === crm_customer_id).map((CurrentProposal, index) => {
                      if (index > 4 && showMoreProposals === false) {
                        return null
                      }
                      else {
                        return (
                          <>
                            <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Name</label>
                              <a href="" style={{ float: "left" }}>
                                {CurrentProposal?.proposal_name}</a>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_status ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Status</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_status}</label>
                            </div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.contract_url ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>URL</label>
                              <a
                                rel="noopener noreferrer"
                                href={CurrentProposal?.proposal_url}
                                target="_blank"
                              >
                                View
                              </a></div> : ""}
                            <div style={{ clear: "both" }}></div>
                            {CurrentProposal?.proposal_value ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Value</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {CurrentProposal?.proposal_value}</label>
                            </div> : ""}
                            {CurrentProposal?.proposal_date ? <div style={{ display: "flex" }}>
                              <label style={{ textAlign: "left", width: "40%" }}>Date</label>
                              <label style={{ width: "60%", textAlign: "left" }}>
                                {moment(new Date(parseInt(CurrentProposal?.proposal_date))).utc().format("MM-DD-YYYY, h:mm:ss A")}</label>
                            </div> : ""}
                            <br />
                          </>
                        )
                      }
                    }) : ""}
                    {CRMProposal?.filter((CurrentProposal) => CurrentProposal?.crm_customer_id === crm_customer_id).length > 5 ?
                      <a href="#" onClick={e => {
                        e.preventDefault();
                        if (showMoreProposals) {
                          setshowMoreProposals(false);
                        } else {
                          setshowMoreProposals(true)
                        }
                      }}>
                        {showMoreProposals ? "See Less..." : "See More..."}
                      </a>
                      : ""}
                    <br />
                  </CardBody>
                </Card>
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Card>
                <CardBody>
                  {AddUpdateEstimate(CRMActivitySubmission, updateActivityFieldHandler, updateEstimateDatabase,
                    CRMPeople, EstimatesubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
                    updatePeopleNameFieldHandler, updateEstimateFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
                    customer_id, CRMProducts, CRMServices, true, CRMActivity, CRMPhotoGallery, CRMJob,
                    setEstimatesubmissionData, EstimateStatuses, me?.readMe?.user_level,
                    competitorData, productData, targetAreaData, noFocusProductData, AddCompetitor, AddProduct,
                    AddNoFocusProduct, AddTargetArea, HighTopProductHover, setHighTopProductHover,
                    RemoveCompetitor, RemoveProduct, RemoveNoFocusProduct, RemoveTargetArea, updateTopCompetitors,
                    updateTopProducts, updateNoFocusProducts, updateTargetAreas, updateTargetType,
                    HighTopCompetitorsHover, setHighTopCompetitorsHover, HighTargetAreasHover, setHighTargetAreasHover,
                    HighNoFocusHover, setHighNoFocusHover, CreateProposalfromEstimate, CRMProposal, setproductData,
                    setnoFocusProductData, settargetAreaData, duplicateTopProducts, duplicateNoFocusProducts, duplicateHighPriorityLocations,
                    setduplicateTopProducts, setduplicateNoFocusProducts, setduplicateHighPriorityLocations)}
                </CardBody>
              </Card>
              <Card>
                <CardBody style={{ textAlign: "center" }}>
                  {LatestActivity(NoteFilterImageSelected, CallFilterImageSelected, EmailFilterImageSelected, TextFilterImageSelected,
                    MeetingFilterImageSelected, TaskFilterImageSelected, DeadlineFilterImageSelected, JobFilterImageSelected, TicketFilterImageSelected, 
                    PhotoFilterImageSelected, UploadFilterImageSelected, InboundFormsFilterImageSelected, OnboardingFilterImageSelected, setOnboardingFilterImageSelected,
                    InboundCallsFilterImageSelected, ProposalFilterImageSelected, ContractFilterImageSelected, EstimateFilterImageSelected, setEstimateFilterImageSelected,
                    setNoteFilterImageSelected, setJobFilterImageSelected, setUploadFilterImageSelected, setDeadlineFilterImageSelected,
                    setTaskFilterImageSelected, setMeetingFilterImageSelected, setEmailFilterImageSelected, setTicketFilterImageSelected, 
                    setTextFilterImageSelected, setPhotoFilterImageSelected, setContractFilterImageSelected, setProposalFilterImageSelected,
                    setInboundCallsFilterImageSelected, setInboundFormsFilterImageSelected, setCallFilterImageSelected,
                    ShownActivityCount, showMoreActivity, setshowMoreActivity, CRMActivity, users, CRMRepresentative, CRMContract, CRMProposal, CRMDocument,
                    CRMPhotoGallery, CRMJob, customer_id, FormData, CallData, CRMPeople, [], [], CRMEstimate, CRMTickets, [])}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </>
        :
        <LinearNoContentWrapper />
      }
    </>
  );
};

CRMEstimateDetail.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMEstimateDetail;
