import React from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import bgAuth from "assets/img/WBMNavBar.png";
import bgImageAuth from "assets/img/login.png";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle";

const useStyles = makeStyles(styles);

const useCustomStyle = makeStyles(theme => ({
  BackgroundImage: {
    '@media (min-width: 780px)': {
      backgroundImage:`url(${bgImageAuth})`, 
      backgroundSize: "100% auto"
    }
  }
}));

export default function Pages() {
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  const customclasses = useCustomStyle();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  // const getBgImage = () => {
  //   if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
  //     return register;
  //   } else if (window.location.pathname.indexOf("/auth/login") !== -1) {
  //     return login;
  //   } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
  //     return pricing;
  //   } else if (
  //     window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
  //   ) {
  //     return lock;
  //   } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
  //     return error;
  //   }
  // };
  return (
    <div className={customclasses.BackgroundImage} >
      <div
        className={classes.wrapper}
        ref={wrapper}
      >
        <img
          src={bgAuth}
          style={{
            width: "400px",
            height: "auto",
            position: "absolute",
            left: "50%",
            top: "13vh",
            transform: "translate(-50%, -50%)",
          }}
        />
        <div className={classes.fullPage}>
      <Outlet />
           </div>
      </div>
    </div>
  );
}
