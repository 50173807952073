import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";
import HelpTooltip from "components/HelpTooltip";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";
import { addMargin } from "utils/margin";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_AD_EXTENSIONS } from "queries/google";
import { Context } from "../../../redux/store";
import { formatMobileNumber } from "utils/format";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const Locations = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [currentAds, setCurrentAds] = useState(null);
  const [updatedAds, setUpdatedAds] = useState(null);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_GOOGLE_AD_EXTENSIONS, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGoogleAdExtensionLocations || [];

  const classes = useStyles();

  const inputs = [
    {
      field: "business_name",
      label: "Location Feed Item Business Name"
    },
    {
      field: "address_line_1",
      label: "Location Feed Item Address Line 1"
    },
    {
      field: "address_line_2",
      label: "Location Feed Item Address Line 2"
    },
    {
      field: "phone_number",
      label: "Location Feed Item Phone Number"
    },
    { field: "notes", label: "Notes", optional: true }
  ];

  data = data.filter(row => inputs.some(({ field }) => row[field]));

  const hasUpdate =
    user_level !== "customer" && user_level !== "agency_customer";

  const columns = compact([
    {
      Header: "Locations",
      accessor: "locations",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <div>{original?.business_name}</div>
          <div>{original?.address_line_1}</div>
          <div>{original?.address_line_2}</div>
          <div>{formatMobileNumber(original?.phone_number)}</div>
        </>
      ),
      renderCSV: ({ original }) =>
        [
          original?.business_name,
          original?.address_line_1,
          original?.address_line_2,
          formatMobileNumber(original?.phone_number)
        ].join("\n")
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
      (original?.ctr * 100).toFixed(2) + "%"
    }
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Locations
              <HelpTooltip>
                {`Location extensions let you show a business address, phone number, or map marker along with your ad text.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Locations(${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(Locations);
