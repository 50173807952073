import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery } from "@apollo/client";

import Button from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import Select from "components/Select/Select";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import {
  CRM_CUSTOMERS,
  LIST_CUSTOMER_REPRESENTATIVES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES
} from "queries/customers";

import { Context } from "../../../redux/store";

import FormatPhone from "../Format/FormatPhone";

const styles = theme => ({
  stepIcon: {
    color: "#0071ce"
  },
  sidebyside: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  paper: { minWidth: "60%" },
  cursor: { cursor: 'pointer' }
});

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

const UpdateCRMCustomerProfile = ({ handleClose, setCustomers, customers, current, updated, type, setcrm_customer_id, crm_customer_id }) => {
  const [state] = useContext(Context);
  const classes = useStyles(styles);
  const customer_id = state.customers?.selected?.id;
  const [BusinessData, setBusinessData] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);


  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  useQuery(GET_POSTAL_CODE,
    {
      skip: !BusinessData?.office_zip,
      variables: {
        name: BusinessData?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !BusinessData?.office_city,
      variables: {
        name: BusinessData?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  const updateBusinessFieldHandler = (key, convert) => e => {
    if (key === "status") {
      setBusinessData({
        ...BusinessData,
        [key]: e.target.checked === true ? 1 : 0
      });
    } else if (key === "representative_id") {
      setBusinessData({
        ...BusinessData,
        [key]: e
      });
    }
    else {
      setBusinessData({
        ...BusinessData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  let { loading, data, refetch } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  const CRMRepresentativesdata = data?.listCustomerRepresentatives || [];

  const updateBusinessNameFieldHandler = (key, value) => {
    setBusinessData({ [key]: value });

    CRMCustomers?.forEach((CurrentCustomer) => {
      if (CurrentCustomer?.business_name === value) {
        setBusinessData(CurrentCustomer);
        setcrm_customer_id(CurrentCustomer?.id);
      }
    })
  };

  useEffect(() => {
    setCRMCustomers(crm_data?.CRMCustomers || []);

    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);

    setBusinessData({
      ["representative_id"]: 1
    });
  }, [crm_data]);

  const updateCountryFieldHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updateCityHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  return (
    <div style={{ minWidth: "60%" }}>
      <Dialog open onClose={() => handleClose()} classes={{ paper: classes.paper }}>
        <DialogContent>
          <div style={{ float: "right" }}><Button onClick={() => handleClose()}><CloseIcon /></Button></div>
          <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{BusinessData?.business_name || current?.business_name || ""}</b></h2>
          <div>
            <>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Name</label>
                  {type === "add" ?
                    <input
                      id="business_name"
                      maxLength={255}
                      type="text"
                      value={BusinessData?.business_name || ""}
                      onChange={updateBusinessFieldHandler("business_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                    :
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="business_name"
                        inputValue={BusinessData?.business_name || ""}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            updateBusinessNameFieldHandler("business_name", newValue.label)
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setBusinessData(null);
                            updateBusinessNameFieldHandler("business_name", newValue.inputValue)
                          } else if (newValue) {
                            updateBusinessNameFieldHandler("business_name", newValue.label)
                          }
                        }}
                        options={CRMCustomers?.map(({ business_name }) => ({
                          label: business_name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.label);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              label: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { padding: 0 }
                            }}
                            onChange={updateBusinessFieldHandler("business_name")}
                            value={BusinessData?.business_name || BusinessData?.business_name}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  }
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Phone</label>
                  <input
                    id="office_phone_number"
                    maxLength={20}
                    type="text"
                    value={BusinessData?.office_phone_number || ""}
                    onChange={updateBusinessFieldHandler("office_phone_number")}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    onBlur={() => {
                      setBusinessData({
                        ...BusinessData,
                        ["office_phone_number"]: FormatPhone(BusinessData?.office_phone_number)
                      });
                    }}
                    onFocus={() => {
                      setBusinessData({
                        ...BusinessData,
                        ["office_phone_number"]: BusinessData?.office_phone_number?.replace(/[^0-9]/g, "")
                      });
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Address Line 1</label>
                  <input
                    id="office_address_line_one"
                    maxLength={255}
                    type="text"
                    value={BusinessData?.office_address_line_one || ""}
                    onChange={updateBusinessFieldHandler("office_address_line_one")}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Address Line 2</label>
                  <input
                    id="office_address_line_two"
                    maxLength={455}
                    type="text"
                    value={BusinessData?.office_address_line_two || ""}
                    onChange={updateBusinessFieldHandler("office_address_line_two")}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business City</label>
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="office_city"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                      onChange={(event, newValue) => {
                        updateCityHandler("office_city", newValue.key);
                      }}
                      value={BusinessData?.office_city ? { key: BusinessData?.office_city, label: BusinessData?.office_city } :
                        { key: "", label: "" }}
                      options={City?.map(({ name }) => ({
                        key: name,
                        label: name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        if (option === "")
                          return "";
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center", width: "100%" }
                          }}
                          onChange={e => { updateBusinessFieldHandler("office_city")(e); }}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business State</label>
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="office_state"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                      onChange={(event, newValue) => {
                        updateStateHandler("office_state", newValue.key);
                      }}
                      value={BusinessData?.office_state ? { key: BusinessData?.office_state, label: BusinessData?.office_state } :
                        { key: "", label: "" }}
                      options={States?.map(({ name }) => ({
                        key: name,
                        label: name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        if (option === "")
                          return "";
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center", width: "100%" }
                          }}
                          onChange={e => { if (e.target.value === "") { updateBusinessFieldHandler("office_state")(e); } }}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Zip Code</label>
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="office_zip"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                      onChange={(event, newValue) => {
                        updatePostalCodeHandler("office_zip", newValue.key);
                      }}
                      value={BusinessData?.office_zip ? { key: BusinessData?.office_zip, label: BusinessData?.office_zip } :
                        { key: "", label: "" }}
                      options={PostalCode?.map(({ name }) => ({
                        key: name,
                        label: name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        if (option === "")
                          return "";
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center", width: "100%" }
                          }}
                          onChange={e => { updateBusinessFieldHandler("office_zip")(e); }}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Business Country</label>
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="office_country"
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                      onChange={(event, newValue) => {
                        updateCountryFieldHandler("office_country", newValue.key);
                      }}
                      value={BusinessData?.office_country ? { key: BusinessData?.office_country, label: BusinessData?.office_country } :
                        { key: "", label: "" }}
                      options={Countries?.map(({ name }) => ({
                        key: name,
                        label: name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        if (option === "")
                          return "";
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { textAlign: "center", width: "100%" }
                          }}
                          onChange={e => { if (e.target.value === "") { updateBusinessFieldHandler("office_country")(e); } }}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Website</label>
                  <input
                    id="website"
                    maxLength={2000}
                    type="text"
                    value={BusinessData?.website ? BusinessData?.website : ""}
                    onChange={updateBusinessFieldHandler("website")}
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                  />
                </GridItem>
                <div style={{ clear: "both", marginBottom: "20px" }}></div>
                {!crm_customer_id && (
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Make Customer</label>
                    <Checkbox
                      value={BusinessData?.status || 0}
                      onChange={updateBusinessFieldHandler("status")}
                      inputProps={{
                        name: "status",
                        id: "status"
                      }}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={12} md={6}>
                  <label
                    style={{ marginTop: "30px" }}>Representative</label>
                  <Select
                    options={CRMRepresentativesdata?.map(({ id, first_name, last_name }) => ({
                      key: id,
                      label: first_name + " " + last_name
                    }))}
                    selected={BusinessData?.representative_id || 1}
                    onSelect={updateBusinessFieldHandler("representative_id")}
                    style={{ marginLeft: 30, marginBottom: 20 }}
                  />
                </GridItem>
              </GridContainer>
              <div style={{ marginTop: "20px" }}>
                <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }}
                  onClick={() => {
                    if (BusinessData?.business_name === undefined || BusinessData?.business_name?.length === 0) {
                      alert("Business Name is Required.");
                      return;
                    }
                    else if (BusinessData?.office_address_line_one === undefined || BusinessData?.office_address_line_one?.length === 0) {
                      alert("Business Address Line 1 is Required.");
                      return;
                    }
                    else if (BusinessData?.office_phone_number === undefined || BusinessData?.office_phone_number?.length === 0) {
                      alert("Business Office Phone is Required.");
                      return;
                    }

                    handleClose(BusinessData)
                  }
                  }>
                  Save
                </Button>
              </div>
            </>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default UpdateCRMCustomerProfile;