export const addMargin = (value, state, lsa = false) => {
  const customer = state?.customers?.selected;
  const margin = parseFloat(customer?.margin || 0);
  const agencyMargin = parseFloat(customer?.agency_margin || 0);
  const lsaMargin = parseFloat(customer?.lsa_margin || 0);
  let valueFixed = value.toFixed(5) * 1;
  const marginValue = (valueFixed * margin * 0.01).toFixed(5) * 1;
  const agencyMarginValue = (valueFixed * agencyMargin * 0.01).toFixed(5) * 1;
  const lsaMarginValue = (valueFixed * lsaMargin * 0.01).toFixed(5) * 1;
  if (lsa) {
    return valueFixed + lsaMarginValue;
  }
  return valueFixed + marginValue + agencyMarginValue;
};

export const addUserMargin = (value, state) => {
  const margin = parseFloat(
    state?.auth?.tokenInfo?.user?.customer?.margin || 0
  );
  const agencyMargin = parseFloat(
    state?.auth?.tokenInfo?.user?.customer?.agency_margin || 0
  );
  let valueFixed = value.toFixed(5) * 1;
  const marginValue = (valueFixed * margin * 0.01).toFixed(5) * 1;
  const agencyMarginValue = (valueFixed * agencyMargin * 0.01).toFixed(5) * 1;
  return valueFixed + marginValue + agencyMarginValue;
};

export default addMargin;
