import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import Switch from '@mui/material/Switch';
import { UPDATE_ACCOUNT } from "queries/customers";
import { UPDATE_CUSTOMER_SELECT } from "../../../../redux/constants";
import { READ_ME } from "queries/users";
import { Context } from "../../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

// import styles

import styles from "../styles";
import { allAccountTypes, updateAccountFields } from "../data";
import CustomSelect from "../CustomSelect";
import SelectStatic from "../SelectStatic";

import PushToQuickbooks from "./PushToQuickbooks";

const useStyles = makeStyles(styles);

const UpdateAccount = ({ formSubmitted }) => {
  const [, dispatch] = useContext(Context);
  const { data: meData, loading: meLoading } = useQuery(READ_ME);
  const me = meData?.readMe;
  const accountTypeStart = allAccountTypes.findIndex(
    ({ key }) => key === me?.user_level
  );
  const accountTypes = allAccountTypes.slice(accountTypeStart + 1);
  const [accountTypeKey, setAccountTypeKey] = useState(undefined);
  const [accountInfo, setAccountInfo] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [validation, setValidation] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const accountTypeStart = allAccountTypes.findIndex(
      ({ key }) => key === me?.user_level
    );
    const accountTypes = allAccountTypes.slice(accountTypeStart + 1);
    if (accountTypes.length) {
      setAccountTypeKey(accountTypes[0].key);
    }
  }, [me]);

  const updateFieldHandler = (key, convert) => e => {
    if(key === "google_id")
    {
      e.target.value = e.target.value.replace(/\D/g,'')
    }
    else if(key === "phone")
    {
      e.target.value = e.target.value.replace(/[^0-9\-]/g,'');
    }
    
    if(key === "ecommerce")
    {
      setAccountInfo({
        ...accountInfo,
        [key]: e.target.checked === false ? 0 : 1
      });
    }
    else{
      setAccountInfo({
        ...accountInfo,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const [updateAccount, { loading: updatingAccount }] = useMutation(
    UPDATE_ACCOUNT,
    {
      variables: {
        id: accountInfo?.id,
        type: accountTypeKey,
        data: JSON.stringify({
          ...accountInfo,
          id: undefined,
          __typename: undefined
        })
      },
      onCompleted: () => {
        if (accountTypeKey === "agency_customer") {
          dispatch({
            type: UPDATE_CUSTOMER_SELECT,
            payload: accountInfo
          });
        }
        formSubmitted();
      }
    }
  );

  const idField = updateAccountFields[accountTypeKey]?.find(
    ({ key }) => key === "id"
  );

  const availableFields = updateAccountFields[accountTypeKey]?.filter(
    ({ permissions }) =>
      !permissions || permissions.indexOf(me?.user_level) !== -1
  );

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>
        Update Account/Contact Form Submission
      </h2>
      <GridContainer justify="center" loading={updatingAccount}>
        <GridItem xs={12}>
          <GridContainer>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
                {(accountTypeKey?.endsWith("customer") ||
                  accountTypeKey === "agency") && (
                  <PushToQuickbooks
                    customer={accountInfo}
                    isAgency={accountTypeKey === "agency"}
                  />
                )}
              </CardHeader>
              <CardBody>
                <CustomSelect
                  label="Type"
                  name="account_type"
                  value={accountTypeKey}
                  onChange={e => {
                    setAccountTypeKey(e.target.value);
                    setAccountInfo({});
                  }}
                  data={
                    meLoading
                      ? []
                      : accountTypes.map(({ label, key }) => ({
                          label,
                          value: key
                        }))
                  }
                />
                <CustomSelect
                  label={idField?.label}
                  name="id"
                  value={accountInfo && accountInfo.id}
                  onChange={(e, info) => {
                    setAccountInfo({
                      ...JSON.parse(info),
                      id: e.target.value
                    });
                    setTimeout(() => {
                      setRefresh(refresh + 1);
                    }, 100);
                  }}
                  sort
                  dataSource={idField?.dataSource}
                  onLoaded={data => {
                    setAccountInfo({
                      ...accountInfo,
                      ...data[0],
                      id: accountInfo?.id || data[0]?.id
                    });
                    setTimeout(() => {
                      setRefresh(refresh + 1);
                    }, 100);
                  }}
                  required
                />
                <form
                  key={refresh}
                  onSubmit={e => {
                    e.preventDefault();
                    if (
                      updateAccountFields[accountTypeKey]?.find(
                        ({ key, required, dataSource }) =>
                          required &&
                          !accountInfo[key] &&
                          accountTypes.find(
                            ({ key }) => dataSource?.type === key
                          )
                      )
                    ) {
                      setValidation(true);
                      return;
                    }
                    updateAccount();
                  }}
                >
                  {availableFields?.map(
                    (
                      { key, label, required, type, dataSource, data },
                      index
                    ) => {
                      if (key === "ecommerce") {
                        return (
                          <div>
                          <label className={classes.selectFormControl}>Ecommerce Website</label>
                          <Switch
                            checked={accountInfo && accountInfo?.ecommerce !== null ? accountInfo?.ecommerce : 0}
                            onChange={ updateFieldHandler(key)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </div>
                          );
                      }
                      else if (type === "input" || !type) {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              fullWidth: true,
                              required
                            }}
                            inputProps={{
                              type: "string",
                              onChange: updateFieldHandler(key),
                              defaultValue: accountInfo[key] || ""
                            }}
                          />
                        );
                      } else if (type === "integer" || type === "number") {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              fullWidth: true,
                              required
                            }}
                            inputProps={{
                              type: "number",
                              onChange: updateFieldHandler(
                                key,
                                type === "integer" ? parseInt : parseFloat
                              ),
                              defaultValue: accountInfo[key] || 0
                            }}
                          />
                        );
                      } else if (type === "date") {
                        return (
                          <CustomInput
                            labelText={label}
                            id={key}
                            key={key}
                            formControlProps={{
                              required
                            }}
                            style={{ width: 220 }}
                            inputProps={{
                              type: "date",
                              onChange: updateFieldHandler(key),
                              defaultValue: accountInfo[key] || ""
                            }}
                          />
                        );
                      } else if (type === "select-static") {
                        return (
                          <SelectStatic
                            label={label}
                            name={key}
                            key={key}
                            value={accountInfo && accountInfo[key]}
                            onChange={updateFieldHandler(key)}
                            data={data}
                            required={required}
                            validation={validation}
                          />
                        );
                      } else if (type === "select") {
                        if (
                          dataSource?.type &&
                          !accountTypes.find(
                            ({ key }) => key === dataSource?.type
                          )
                        ) {
                          return null;
                        }
                        if (key === "id") return null;
                        return (
                          <CustomSelect
                            label={label}
                            name={key}
                            key={key}
                            value={accountInfo && accountInfo[key]}
                            onChange={e => {
                              updateAccountFields[accountTypeKey]
                                .filter(
                                  ({ dataSource }, idx) =>
                                    dataSource?.type && idx > index
                                )
                                .forEach(({ key }) => {
                                  accountInfo[key] = undefined;
                                });
                              setAccountInfo({
                                ...accountInfo,
                                [key]: e.target.value
                              });
                            }}
                            dataSource={{
                              ...dataSource,
                              parent_id:
                                accountInfo &&
                                accountInfo[(dataSource?.parent_id)],
                              skip:
                                dataSource?.parent_id === `${me?.user_level}_id`
                            }}
                            onLoaded={data => {
                              if (required) {
                                updateFieldHandler(key)({
                                  target: {
                                    value: accountInfo[key] || data[0]?.id
                                  }
                                });
                              }
                            }}
                            required={required}
                            validation={validation}
                          />
                        );
                      }
                      return null;
                    }
                  )}
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(UpdateAccount);
