import React from "react";
import { API_URL } from "config";

import {
  GET_HYDRATE_TEXT,
  UPDATE_HYDRATE_TEXT,
  GET_HYDRATE_MMS,
  UPDATE_HYDRATE_MMS,
  GET_HYDRATE_VOICE_MESSAGE,
  UPDATE_HYDRATE_VOICE_MESSAGE,
  GET_HYDRATE_EMAIL,
  UPDATE_HYDRATE_EMAIL,
  GET_HYDRATE_LETTER,
  UPDATE_HYDRATE_LETTER,
  GET_HYDRATE_POSTCARD,
  UPDATE_HYDRATE_POSTCARD
} from "queries/hydrate";

export default [
  {
    key: "sms_text",
    title: "Text",
    getQuery: GET_HYDRATE_TEXT,
    getQueryKey: "getHydrateText",
    updateQuery: UPDATE_HYDRATE_TEXT,
    hasPlaceholders: true,
    fields: [
      {
        name: "sms_message_1",
        label: "Message",
        type: "long-text-input",
        maxLength: 1190,
        required: true,
        parts: [160, 306, 459, 612, 765, 918, 1071, 1224],
        append: 26
      }
    ]
  },
  {
    key: "mms_text",
    title: "MMS",
    getQuery: GET_HYDRATE_MMS,
    getQueryKey: "getHydrateMMS",
    updateQuery: UPDATE_HYDRATE_MMS,
    hasPlaceholders: true,
    help: (
      <>
        You can send a single attachment with a size of up to 250 kB. * Some older devices can only accept attachments with up to 30 kB.
      </>
    ),
    fields: [
      {
        name: "body",
        label: "MMS Message",
        type: "long-text-input",
        maxLength: 1500,
        required: true
      },
      {
        name: "media_file",
        label: "View File",
        type: "image",
        maxSize: 250000,
        sizeHelper: "Please upload an image smaller than 250 kB."
      }
    ]
  },
  {
    key: "email",
    title: "Email",
    getQuery: GET_HYDRATE_EMAIL,
    getQueryKey: "getHydrateEmail",
    updateQuery: UPDATE_HYDRATE_EMAIL,
    hasPlaceholders: true,
    fields: [
      {
        name: "subject",
        label: "Subject",
        maxLength: 145,
        required: true
      },
      {
        name: "body",
        label: "Body",
        type: "long-text-input",
        maxLength: 1500,
        required: true
      }
    ]
  }
];
