import axios from "axios";
const moment = require("moment");

import { API_URL } from "config";

const SwapScreenshotsReplaceWithS3URL = async (activity_notes, customer_id, index = 0) => {
    let newGetUrl = '';

    let base64file = activity_notes?.substring(
        activity_notes?.indexOf(";base64,") + 8,
        activity_notes?.indexOf("\"",
            activity_notes?.indexOf(";base64,")
        ));
    const generateGetUrl = `${API_URL}/generate-get-url`;
    const generatePutUrl = `${API_URL}/generate-put-url`;
    const options = {
        params: {
            Key: "customer_creatives/assets/" + customer_id + "/" + moment().format("MMDDYYYYhhmmss") + index,
            ContentType: activity_notes?.substring(
                activity_notes?.indexOf("data:") + 5,
                activity_notes?.indexOf(";base64,")
            )
        },
        headers: {
            "Content-Type": activity_notes?.substring(
                activity_notes?.indexOf("data:") + 5,
                activity_notes?.indexOf(";base64,")
            )
        }
    };

    var u = base64file,
        binary = atob(u),
        array = [];

    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }

    await axios.get(generatePutUrl, options).then(async res => {
        const {
            data: { putURL }
        } = await res;
        await axios
            .put(putURL, new Uint8Array(array), options)
            .then(async () => {
                await axios.get(generateGetUrl, options).then(async res => {
                    const { data: getURL } = await res;
                    newGetUrl = getURL;
                });
            })
    });

    // now replace the file in the json

    let newActivityNotes = activity_notes?.replace(activity_notes?.substring(
        activity_notes?.indexOf("data:"),
        activity_notes?.indexOf("\"",
            activity_notes?.indexOf(";base64,")
        )), newGetUrl);

    if (newActivityNotes?.indexOf(";base64,") > -1) {
        return await SwapScreenshotsReplaceWithS3URL(newActivityNotes, customer_id, index + 1);
    } else
        return newActivityNotes;
};

export default SwapScreenshotsReplaceWithS3URL;
