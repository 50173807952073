import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import Input from "@mui/material/Input";
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import Check from "@mui/icons-material/Check";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_NEGATIVE_KEYWORDS_SEARCH_QUERIES } from "queries/google";
import { SUBMIT_KEYWORDS_REMOVAL_REQUEST } from "queries/formSubmission";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = {
  ...formStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  checkRoot: {
    padding: 0
  }
};

const useStyles = makeStyles(styles);

const NegativeKeywordsGoogle = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [checks, setChecks] = useState({});
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  const detailed =
    ["master", "campaign_consultant", "paid_ad_manager", "ad_consultant"].indexOf(user_level) !==
    -1;
  const customer_id = state.customers?.selected?.id;
  const customer_name = state.customers?.selected?.name;
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data } = useQuery(LIST_GOOGLE_NEGATIVE_KEYWORDS_SEARCH_QUERIES, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      customer_name,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    },
    onCompleted: () => {
      setChecks({});
    }
  });
  data = data?.listGoogleNegativeKeywordSearchQueries || [{}];

  const toggleChecked = key => {
    setChecks({
      ...checks,
      [key]: !checks[key]
    });
  };

  const [submitKeywordsRemovalRequest, { loading: removing }] = useMutation(
    SUBMIT_KEYWORDS_REMOVAL_REQUEST,
    {
      variables: {
        customerId: customer_id,
        data: {
          tableName: "Google Campaign Keywords",
          keywords: data
            ?.filter(({ search_term_view }) => checks[search_term_view])
            .map(({ search_term_view }) => search_term_view)
            .join("<br>"),
          customerName: state.customers?.selected?.name,
          note
        }
      },
      onCompleted: () => {
        setChecks({});
        setOpen(false);
        formSubmitted();
      }
    }
  );

  const handleOpen = () => {
    setNote("");
    setOpen(true);
  };

  const handleClose = (accept = false) => () => {
    if (accept) {
      submitKeywordsRemovalRequest();
    } else {
      setOpen(false);
    }
  };

  const classes = useStyles();
  const searchQueryColumn = {
    Header: "Search Query",
    accessor: "search_term_view",
    // eslint-disable-next-line react/display-name,react/prop-types
    Cell: ({ original }) => {
      return (
        <span
          style={{
            fontWeight: checks[(original?.search_term_view)]
              ? "bold"
              : "regular"
          }}
          key={checks[(original?.search_term_view)]}
        >
          {original?.search_term_view}
        </span>
      );
    },
    renderCSV: ({ original }) => original?.search_term_view
  };
  const detailedColumns = detailed
    ? [
        {
          Header: "Campaign Name",
          accessor: "campaign_name"
        },
        {
          Header: "Ad Group Name",
          accessor: "ad_group_name"
        },
        {
          Header: "Match Type",
          accessor: "match_type"
        },
        {
          Header: "Keyword Text",
          accessor: "keywords_text"
        }
      ]
    : [searchQueryColumn];
  const columns = [
    ...detailedColumns/*,
    {
      Header: "",
      accessor: "check",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <Checkbox
          onClick={() => toggleChecked(original?.search_term_view)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
          checked={checks[(original?.search_term_view)]}
          key={checks[(original?.search_term_view)]}
        />
      ),
      noCSV: true
    }*/
  ];
  const checkLength = Object.keys(checks).length;
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Negative Keywords
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Google Negative Keywords (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading || removing}
              columns={columns}
              defaultPageSize={Math.min(50, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            {open && (
              <Dialog
                open={open}
                onClose={handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">
                  Keywords Removal
                </DialogTitle>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleClose(true)();
                  }}
                >
                  <DialogContent>
                    <ReactTable
                      key={data?.length}
                      data={data?.filter(
                        ({ search_term_view }) => checks[search_term_view]
                      )}
                      loading={loading || removing}
                      columns={columns.slice(0, 3)}
                      defaultPageSize={5}
                      className="-striped -highlight"
                    />
                    <br />
                    <br />
                    <h4>Note:</h4>
                    <Input
                      name="note"
                      id={`input-note`}
                      fullWidth
                      required
                      inputProps={{
                        type: "string",
                        value: note || "",
                        onChange: e => setNote(e.target.value)
                      }}
                    />
                    <br />
                  </DialogContent>
                  <DialogActions>
                    <Button color="primary" autoFocus type="submit">
                      Submit
                    </Button>
                    <Button onClick={handleClose()}>Cancel</Button>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </CardBody>
          {/* fix <CardFooter>
            <Button
              onClick={handleOpen}
              color="orange"
              disabled={!checkLength || removing}
              style={{ margin: "auto" }}
            >
              Add Negative Keywords({checkLength})
            </Button>
          </CardFooter>*/}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(NegativeKeywordsGoogle);
