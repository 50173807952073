import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery, useMutation } from "@apollo/client";
import { startCase, omit } from "lodash";
import stringSimilarity from "string-similarity";


import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import { allAccountTypes } from "views/Dashboard/Accounts/data";
import {
  LIST_ALL_ACCOUNTS,
  GET_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER
} from "queries/customers";
import withSubmissions from "hocs/withSubmissions";

import CustomAccountSelect from "../CustomSelect";

// import styles
import styles from "../styles";

export const REPORT_EMAILS_OPTIONS = [
  { value: "0", label: "No" },
  { value: "2", label: "Monthly" },
];

const useStyles = makeStyles(styles);

const AddUser = ({ formSubmitted }) => {
  const [is_sub, setIsSub] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [accountId, setAccountId] = useState();
  const [userInfo, setUserInfo] = useState({ commission: 0 });
  const [accountTypeKey, setAccountTypeKey] = useState("");
  const classes = useStyles();

  const { data, loading } = useQuery(LIST_ALL_ACCOUNTS, {
    variables: { type: "all" }
  });

  const [getAccountUser, { data: user, loading: userLoading }] = useLazyQuery(
    GET_ACCOUNT_USER,
    {
      skip: !accountId,
      fetchPolicy: "no-cache"
    }
  );

  const [updateAccountUser, { loading: updatingAccountUser }] = useMutation(
    UPDATE_ACCOUNT_USER,
    {
      skip: !accountId,
      variables: {
        account_id: accountId,
        user: {...omit(userInfo, ["__typename", "id", "sub", "auto_reporting_emails"]), auto_reporting_emails: userInfo?.auto_reporting_emails?.toString(), billing_contact: userInfo?.billing_contact?.toString()},
        is_sub
      },
      onCompleted: data => {
        setAccounts(
          accounts.map(({ id, type, ...rest }) =>
            `${type}-${id}` === accountId
              ? { id, type, ...rest, user_id: data?.updateAccountUser?.id }
              : { id, type, ...rest }
          )
        );
        formSubmitted();
      }
    }
  );

  const updateFieldHandler = (key, convert) => e => {
    setUserInfo({
      ...userInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  useEffect(() => {
    setUserInfo(user?.getAccountUser);
    if (user?.getAccountUser) {
      setIsSub(Boolean(user?.getAccountUser?.sub));
    }
  }, [user]);

  useEffect(() => {
    setAccounts(data?.listAllAccounts?.map(account => JSON.parse(account)));
  }, [data]);

  const selectedAccounts = accounts?.filter(
    ({ type }) => type === accountTypeKey
  );
  const onImport = ({ account_id, is_sub, ...importedData }) => {
    const { bestMatchIndex } = stringSimilarity.findBestMatch(
      account_id,
      selectedAccounts.map(({ name }) => name)
    );
    const accountId = `${selectedAccounts[bestMatchIndex].type}-${selectedAccounts[bestMatchIndex].id}`;
    const newUserInfo = {
      ...userInfo,
      ...importedData,
      billing_contact: importedData?.billing_contact === "Yes" ? "1" : "0",
      office_extension: `${importedData?.office_extension}`,
      auto_reporting_emails: REPORT_EMAILS_OPTIONS.find(
        ({ label }) => label === importedData?.auto_reporting_emails
      )?.value
    };
    setUserInfo(newUserInfo);
    setAccountId(accountId);
    setIsSub(is_sub === "YES");
  };
  // Render Page
  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>User Registration</h2>
      <GridContainer
        justifyContent="center"
        loading={loading || userLoading || updatingAccountUser}
      >
        <GridItem xs={12}>
          <GridContainer>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    updateAccountUser();
                  }}
                >
                  <label>Type</label>
                  <CustomSelect
                    name="account_type"
                    value={accountTypeKey}
                    onChange={e => {
                      setAccountTypeKey(e.target.value);
                      setUserInfo({ commission: 0 });
                      setAccountId(undefined);
                      setIsSub(false);
                    }}
                    data={allAccountTypes.map(({ label, key }) => ({
                      label,
                      value: key
                    }))}
                    defaultValue=""
                  />
                  <label>Select Account</label>
                  <CustomAccountSelect
                    name="account_id"
                    value={accountId}
                    onChange={e => {
                      setAccountId(e.target.value);
                      setUserInfo({});
                      getAccountUser({
                        variables: { account_id: e.target.value, is_sub }
                      });
                      setIsSub(false);
                    }}
                    data={selectedAccounts}
                  />
                  {startCase(accountId?.split("-")[0] || "").indexOf(
                    "Customer"
                  ) !== -1 && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={is_sub}
                          onChange={(e, v) => {
                            setIsSub(v);
                            getAccountUser({
                              variables: { account_id: accountId, is_sub: v }
                            });
                          }}
                          color="primary"
                          inputProps={{ "aria-label": "primary checkbox" }}
                        />
                      }
                      label="Sub User"
                    />
                  )}
                  <CustomInput
                    labelText="User Level"
                    id="user_level"
                    disabled
                    readOnly
                    formControlProps={{
                      fullWidth: true,
                      disabled: true,
                      readOnly: true
                    }}
                    inputProps={{
                      value: startCase(accountId?.split("-")[0] || ""),
                      type: "string"
                    }}
                  />
                  <CustomInput
                    labelText="First Name"
                    id="first_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: userInfo?.first_name || "",
                      onChange: updateFieldHandler("first_name")
                    }}
                  />
                  <CustomInput
                    labelText="Last Name"
                    id="last_name"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "string",
                      value: userInfo?.last_name || "",
                      onChange: updateFieldHandler("last_name")
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "email",
                      value: userInfo?.email || "",
                      onChange: updateFieldHandler("email")
                    }}
                  />
                  <CustomInput
                    labelText="Cell Phone Number"
                    id="cell_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: userInfo?.cell_phone || "",
                      onChange: updateFieldHandler("cell_phone", e => `${e}`)
                    }}
                  />
                  <CustomInput
                    labelText="Birthday"
                    id="birthday"
                    style={{ width: 220 }}
                    inputProps={{
                      type: "date",
                      value: userInfo?.birthday || "",
                      onChange: updateFieldHandler("birthday")
                    }}
                  />
                  {accountTypeKey !== "customer" &&
                    accountTypeKey !== "agency_customer" && (
                      <CustomInput
                        labelText="Commission"
                        id="commission"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "integer",
                          value: userInfo?.commission || 0,
                          onChange: updateFieldHandler("commission", parseInt)
                        }}
                      />
                    )}
                  <CustomInput
                    labelText="Office Phone Number"
                    id="office_phone"
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: userInfo?.office_phone || "",
                      onChange: updateFieldHandler("office_phone", e => `${e}`)
                    }}
                  />
                  <CustomInput
                    labelText="Office Extension"
                    id="office_extension"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "integer",
                      value: userInfo?.office_extension || "",
                      onChange: updateFieldHandler(
                        "office_extension",
                        e => `${e}`
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Fax"
                    id="fax_number"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "phone_number",
                      value: userInfo?.fax_number || "",
                      onChange: updateFieldHandler("fax_number", e => `${e}`)
                    }}
                  />
                  <label>Billing Contact</label>
                  <CustomSelect
                    data={[
                      { value: "0", label: "No" },
                      { value: "1", label: "Yes" }
                    ]}
                    name="billing_contact"
                    value={`${userInfo?.billing_contact}`}
                    onChange={updateFieldHandler("billing_contact", e => Number(e))}
                    defaultValue=""
                  />
                  <label>Reporting Emails</label>
                  <CustomSelect
                    data={REPORT_EMAILS_OPTIONS}
                    name="reporting_emails"
                    value={`${userInfo?.auto_reporting_emails}`}
                    onChange={updateFieldHandler("auto_reporting_emails", e => Number(e))}
                    defaultValue=""
                  />
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </form>
              </CardBody>
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(AddUser);
