import gql from "graphql-tag";

export const GET_SOCIAL_ADS_CAMPAIGN_INFO_SUMMARY = gql`
  query getSocialAdsCampaignInfoSummary(
    $facebook_id: String!
    $start_date: String
    $end_date: String
  ) {
    getSocialAdsCampaignInfoSummary(
      facebook_id: $facebook_id
      start_date: $start_date
      end_date: $end_date
    ) {
      spend
      social_spend
      impressions
      clicks
      reach
    }
  }
`;

export const LIST_SOCIAL_ADS_CAMPAIGN_DETAILS = gql`
  query listSocialAdsCampaignDetails(
    $facebook_id: String!
    $start_date: String
    $end_date: String
  ) {
    listSocialAdsCampaignDetails(
      facebook_id: $facebook_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date_start
      date_stop
      objective
      impressions
      clicks
      reach
      social_spend
      spend
      campaign_name
    }
  }
`;

export const LIST_SOCIAL_ADS_RESULTS = gql`
  query listSocialAdsResults(
    $facebook_id: String!
    $start_date: String
    $end_date: String
  ) {
    listSocialAdsResults(
      facebook_id: $facebook_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date_start
      date_stop
      objective
      impressions
      clicks
      reach
      social_spend
      spend
      ad_set_name
    }
  }
`;

export const LIST_SOCIAL_ASSETS = gql`
  query listSocialAssets(
    $facebook_id: String!
    $start_date: String
    $end_date: String
  ) {
    listSocialAssets(
      facebook_id: $facebook_id
      start_date: $start_date
      end_date: $end_date
    ) {
      ad_id
      ad_name
      status
      instagram_permalink_url
      call_to_action_type
      image_url
      thumbnail_url
      title
      body
      object_story_spec_link_data_call_to_action_type
      object_story_spec_link_data_name
      object_story_spec_link_data_message
      object_story_spec_link_data_link
    }
  }
`;
