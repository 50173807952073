import gql from "graphql-tag";

export const GET_BIRDEYE_CUSTOMERS_AND_LEADS = gql`
  query getBirdeyeCustomersAndLeads(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getBirdeyeCustomersAndLeads(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      total
      google
      webchat
      facebook
      other
    }
  }
`;

export const GET_BIRDEYE_REVIEWS_TRACKING = gql`
  query getBirdeyeReviewsTracking($customer_id: Int!) {
    getBirdeyeReviewsTracking(customer_id: $customer_id) {
      AllTimeReviewCountCount
      AllTimeAvgRatingRating
      Last30DaysAvgRatingRating
      AllTimeSentimentsPositive
      Last30DaysSentimentsPositive
      AllTimeSentimentsNeutral
      Last30DaysSentimentsNeutral
      AllTimeSentimentsNegative
      Last30DaysSentimentsNegative
      EmailSentInfoTotal
      SmsDetailMsgSent
    }
  }
`;
