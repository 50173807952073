import React from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Avatar from "components/Avatar";
import SubmitForm from "components/SubmitForm";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
import { GET_AD_CONSULTANT } from "queries/users";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const AdConsultant = ({ title }) => {
  const classes = useStyles();
  const { loading, data } = useQuery(GET_AD_CONSULTANT);
  const info = data?.getAdConsultant;

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>
        {title || "Advertising Consultant"}
      </h2>
      <GridContainer justify="center" loading={loading}>
        <GridItem xs={12} sm={12} md={4}>
          <Card product className={classes.cardHover}>
            <CardHeader image className={classes.cardHeaderHover}>
              <Avatar userId={info?.id} size="100%" />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}></div>
              <h2 className={classes.cardNameTitle}>
                {info?.first_name} {info?.last_name}
              </h2>
              <p className={classes.cardProductDesciprion}>
                <ul>
                  <li>
                    Office: {info?.office_phone} x {info?.office_extension}
                  </li>
                  <li>Cell: {info?.cell_phone}</li>
                  <li>Email: {info?.email}</li>
                </ul>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} lg={6}>
          <SubmitForm toEmail={info?.email} />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default AdConsultant;
