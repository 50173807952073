import React, { useContext } from "react";

import NoContentWrapper from "components/NoContentWrapper";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";

import TrackedLeads from "../TrackedLeads";

export default function OfflineCalltrack() {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  const content =
    "Want to know how your other types of advertising are doing? With Water Bear Marketing's Offline Tracking, we are able to provide you with 24/7 access to call reporting which includes call recording and rating to ensure you invest your ad dollars wisely.";
  return (
    <NoContentWrapper
      skip={!customer_id}
      queries={[
        {
          name: "budgetData",
          query: GET_BUDGET,
          options: {
            variables: { customer_id, product_line: "offline_tracking" }
          }
        }
      ]}
      product="Offline tracking"
      isLoading={({ budgetData }) => budgetData?.loading}
      hasNoData={({ budgetData }) => {
        const spent = Number(
          budgetData?.data?.getBudget?.new_monthly_budget ||
          budgetData?.data?.getBudget?.monthly_budget ||
          0
        );
        if (spent === 0) {
          return false;
        }
        return true;
      }}
      noContent={content}
      isAn
    >
      <TrackedLeads offline />
    </NoContentWrapper>
  );
}
