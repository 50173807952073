import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from "@mui/material/TextField";

import Button from "components/CustomButtons/Button";
import ReactTable from "components/CustomReactTable";
import {
  CREATE_QUICKBOOK_CUSTOMER,
  READ_QUICKBOOK_CUSTOMER
} from "queries/quickbooks";

const columns = (onChangeField, readOnly = false) => [
  {
    Header: readOnly ? "Name" : "Information Needed",
    accessor: "name",
    // eslint-disable-next-line react/display-name,react/prop-types
    Cell: ({ original }) => original?.label || "",
    sortable: false,
    width: 150
  },
  {
    Header: readOnly ? "Value" : "Billing Information",
    accessor: "value",
    // eslint-disable-next-line react/display-name,react/prop-types
    Cell: ({ original }) => {
      if (readOnly) {
        return original.displayValue || original.value || null;
      }
      if (!original.type || original.type === "text") {
        return (
          <TextField
            defaultValue={original.value}
            onChange={onChangeField(original.key)}
            fullWidth
          />
        );
      } else if (original.type === "label") {
        return original.displayValue || original.value || null;
      }
      return null;
    },
    sortable: false
  }
];

const PushToQuickbooks = ({ customer, isAgency }) => {
  const [pushDialogOpen, setPushDialogOpen] = useState(false);
  const [updated, setUpdated] = useState(0);
  const info = useRef([]);
  const quick_books_id = Number(customer?.quick_books_id || 0);
  const [createQuickbookCustomer, { loading: updating }] = useMutation(
    CREATE_QUICKBOOK_CUSTOMER,
    {
      onCompleted: () => {
        setPushDialogOpen(false);
      }
    }
  );
  const [
    readQuickbookCustomer,
    { loading: reading, data: quickbookCustomer }
  ] = useLazyQuery(READ_QUICKBOOK_CUSTOMER, {
    fetchPolicy: "network-only"
  });
  const setDefaultInfo = useCallback(() => {
    info.current = [
      {
        key: "firstName",
        label: "First Name",
        value: customer.user?.first_name || ""
      },
      {
        key: "lastName",
        label: "Last Name",
        value: customer.user?.last_name || ""
      },
      { key: "email", label: "Email", value: customer.user?.email || "" },
      {
        key: "mobile",
        label: "Mobile",
        value: customer.user?.cell_phone || ""
      },
      { key: "name", label: "Display Name", value: customer.name },
      { key: "address_1", label: "Address 1", value: customer.address_1 },
      { key: "address_2", label: "Address 2", value: customer.address_2 },
      { key: "city", label: "City", value: customer.city },
      { key: "state", label: "State", value: customer.state },
      { key: "zip", label: "Zip", value: customer.zipcode },
      { key: "country", label: "Country", value: customer.country },
      {
        key: "phone",
        label: "Phone (10 digits)",
        value: customer.user?.office_phone || ""
      },
      { key: "fax", label: "Fax", value: customer.user?.fax_number || "" },
      { key: "website", label: "Website (https://)", value: customer.website }
    ];
    if (customer.agency) {
      info.current.push({
        key: "parent",
        label: "Parent Customer",
        value: `${customer.agency.quick_books_id}:${customer.agency.name}`,
        type: "label",
        displayValue: customer.agency.name
      });
      const billParent =
        customer.invoicing_type === 3 || customer.invoicing_type === 4;
      info.current.push({
        key: "billParent",
        label: "Bill Parent",
        value: billParent,
        type: "label",
        displayValue: billParent ? "Yes" : "No"
      });
    }
    setUpdated(updated => updated + 1);
  }, [customer]);

  useEffect(() => {
    if (pushDialogOpen) {
      if (quick_books_id) {
        console.log("loading");
        readQuickbookCustomer({
          variables: {
            quick_books_id
          }
        });
      } else {
        setTimeout(setDefaultInfo, 500);
      }
    } else {
      info.current = [];
      setUpdated(updated => updated + 1);
    }
  }, [pushDialogOpen]);

  useEffect(() => {
    try {
      const qcObject = JSON.parse(quickbookCustomer?.readQuickbookCustomer);
      info.current = [
        { key: "firstName", label: "First Name", value: qcObject.GivenName },
        { key: "lastName", label: "Last Name", value: qcObject.FamilyName },
        {
          key: "email",
          label: "Email",
          value: qcObject.PrimaryEmailAddr?.Address
        },
        {
          key: "mobile",
          label: "Mobile",
          value: qcObject.Mobile?.FreeFormNumber
        },
        { key: "companyName", label: "Company", value: qcObject.CompanyName },
        { key: "name", label: "Display Name", value: qcObject.DisplayName },
        {
          key: "address_1",
          label: "Address 1",
          value: qcObject.BillAddr?.Line1
        },
        {
          key: "address_2",
          label: "Address 2",
          value: qcObject.BillAddr?.Line2
        },
        { key: "city", label: "City", value: qcObject.BillAddr?.City },
        {
          key: "state",
          label: "State",
          value: qcObject.BillAddr?.CountrySubDivisionCode
        },
        { key: "zip", label: "Zip", value: qcObject.BillAddr?.PostalCode },
        { key: "country", label: "Country", value: qcObject.BillAddr?.Country },
        {
          key: "phone",
          label: "Phone",
          value: qcObject.PrimaryPhone?.FreeFormNumber
        },
        { key: "fax", label: "Fax", value: qcObject.Fax?.FreeFormNumber },
        { key: "website", label: "Website", value: qcObject.WebAddr?.URI }
      ];
      if (qcObject.ParentRef?.value) {
        info.current.push({
          key: "parent",
          label: "Parent Customer",
          value: qcObject.ParentRef?.value,
          displayValue: qcObject.ParentRef?.name
        });
        info.current.push({
          key: "billParent",
          label: "Bill Parent",
          value: qcObject.BillWithParent,
          displayValue: qcObject.BillWithParent ? "Yes" : "No"
        });
      }
      setUpdated(updated => updated + 1);
    } catch (e) {
      console.log(e);
    }
  }, [quickbookCustomer]);

  const handleClose = pushStatus => () => {
    if (pushStatus) {
      console.log("pushing...");
      const data = {};
      info.current.forEach(({ key, value }) => {
        data[key] = value;
      });
      console.log(data);
      createQuickbookCustomer({
        variables: {
          customer_id: customer.id,
          isAgency,
          data: JSON.stringify(data)
        }
      });
    } else {
      setPushDialogOpen(false);
    }
  };

  const onChangeField = key => e => {
    info.current = info.current?.map(item => {
      if (item.key !== key) return item;
      return {
        ...item,
        value: e.target.value
      };
    });
  };

  const readOnly = Boolean(quick_books_id);

  return (
    <>
      <Button
        onClick={() => setPushDialogOpen(true)}
        style={{ float: "right", marginTop: 15 }}
        color="primary"
      >
        {readOnly ? "View Billing Information" : "Send to Accounting"}
      </Button>
      {pushDialogOpen && (
        <Dialog
          open={Boolean(pushDialogOpen)}
          onClose={handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {readOnly ? "View Billing Information" : "Send to Accounting"}
          </DialogTitle>
          <form
            onSubmit={e => {
              e.preventDefault();
              handleClose(true)();
            }}
          >
            <DialogContent>
              <ReactTable
                data={info.current}
                columns={columns(onChangeField, readOnly)}
                showPaginationTop={false}
                showPaginationBottom={false}
                minRows={5}
                key={updated}
                className="-striped -highlight"
                loading={updating || reading}
              />
            </DialogContent>
            <DialogActions>
              {!readOnly && (
                <>
                  <Button
                    color="orange"
                    onClick={setDefaultInfo}
                    disabled={updating || reading}
                  >
                    Reset to Default
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    type="submit"
                    disabled={updating || reading}
                  >
                    Push
                  </Button>
                </>
              )}
              <Button onClick={handleClose()} disabled={updating || reading}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default PushToQuickbooks;
