import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";

import Button from '@mui/material/Button';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dropzone from "components/Dropzone";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { useSnackbar } from "notistack";
import Select from "@mui/material/Select";
import SelectFromState from "components/Select/Select";
import MenuItem from "@mui/material/MenuItem";

import { READ_ME } from "queries/users";

import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";

import ProductsDefaultImage from "assets/img/Products.png";

import {
  UPDATE_PRODUCT,
  GET_PRODUCT_CATEGORIES
} from "queries/customers";
import { Context } from "../../../redux/store";

const styles = theme => ({
  stepIcon: {
    color: "#0071ce"
  },
  sidebyside: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  paper: { minWidth: "60%" },
  cursor: { cursor: 'pointer' }
});

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);


const UpdateCRMProduct = ({ handleClose, refetch, current, setCurrentProduct, setproduct_cost,
  product_cost, product_margin, setproduct_margin, product_price, setproduct_price,
  product_discount, setproduct_discount, product_sale_price, setproduct_sale_price,
  product_tax, setproduct_tax }) => {
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const me = useQuery(READ_ME)?.data?.readMe;
  const classes = useStyles(styles);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submissionData, setSubmissionData] = useState(null);
  const [CategoryData, setCategoryData] = React.useState(null);
  const [user_id, setuser_id] = useState(null);

  const customer_id = state.customers?.selected?.id;

  const { data: Category_Data } = useQuery(GET_PRODUCT_CATEGORIES, {
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });

  useEffect(() => {
    setuser_id(me?.id);
    setCategoryData([{ id: 0, category_text: "Select" }, ...Category_Data?.listProductCategories || []]);
    setSubmissionData(current);
  }, [Category_Data, current]);

  const updateFieldHandler = (key) => e => {
    if (key === "product_taxable") {
      if (e.target.checked === false)
        setSubmissionData({
          ...omit(submissionData, ["product_tax"]),
          [key]: e.target.checked === true ? 1 : 0,
        });
      else
        setSubmissionData({
          ...submissionData,
          [key]: e.target.checked === true ? 1 : 0,
        });
    } else if (key === "product_category" || key === "parent_category" || key === "product_description") {
      setSubmissionData({
        ...submissionData,
        [key]: e
      });
    } else {
      if (key === "product_billing_type" && e.target.value !== "2")
        setSubmissionData({
          ...omit(submissionData, ["product_billing_frequency"]),
          [key]: e.target.value
        });
      else
        setSubmissionData({
          ...submissionData,
          [key]: e.target.value
        });
    }
  };

  const handleSave = async () => {
    if (submissionData?.product_name === undefined && current?.product_name === "") {
      alert("Product Name is required");
      return false;
    }
    if (submissionData?.product_category === undefined && current?.product_category === "") {
      alert("Product Category is required");
      return false;
    }

    submitUpdateRequest({
      variables: {
        customer_id,
        Product: {
          ...omit(submissionData, ["__typename", "product_cost", "product_margin", "product_price", "product_discount", "product_sale_price", "product_tax", "budget_cycles_product_id"]),
          product_cost: submissionData?.product_cost ? submissionData?.product_cost?.replace(/[^0-9.]+/g, "") : null,
          product_margin: submissionData?.product_margin ? submissionData?.product_margin?.replace(/[^0-9.]+/g, "") : null,
          product_price: submissionData?.product_price ? submissionData?.product_price?.replace(/[^0-9.]+/g, "") : null,
          product_discount: submissionData?.product_discount ? submissionData?.product_discount?.replace(/[^0-9.]+/g, "") : null,
          product_sale_price: submissionData?.product_sale_price ? submissionData?.product_sale_price?.replace(/[^0-9.]+/g, "") : null,
          product_tax: submissionData?.product_tax ? submissionData?.product_tax?.replace(/[^0-9.]+/g, "") : null
        },
      }
    });

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });
  };

  const [submitUpdateRequest] = useMutation(
    UPDATE_PRODUCT,
    {
      onCompleted: () => {
        refetch();
        setCurrentProduct(null);
      }
    }
  );

  const FormatCurrency = (field) => {
    let value = '';

    if (field === "product_cost")
      value = product_cost;
    if (field === "product_margin")
      value = product_margin;
    if (field === "product_price")
      value = product_price;
    if (field === "product_discount")
      value = product_discount;
    if (field === "product_sale_price")
      value = product_sale_price;
    if (field === "product_tax")
      value = product_tax;

    if (value === '')
      value = "0";

    let newValue = parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setSubmissionData({
      ...submissionData,
      [field]: newValue
    });

    if (field === "product_cost")
      setproduct_cost(newValue);
    if (field === "product_margin")
      setproduct_margin(newValue);
    if (field === "product_price")
      setproduct_price(newValue);
    if (field === "product_discount")
      setproduct_discount(newValue);
    if (field === "product_sale_price")
      setproduct_sale_price(newValue);
    if (field === "product_tax")
      setproduct_tax(newValue);
  }

  const FormatCurrencyWithoutUpdatingState = (value) => {
    if (value === null)
      value = "0";
    return parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  }

  const updateProductValueFieldHandler = (field, e) => {
    if (field === "product_cost")
      setproduct_cost(e.target.value);
    if (field === "product_margin")
      setproduct_margin(e.target.value);
    if (field === "product_price")
      setproduct_price(e.target.value);
    if (field === "product_discount")
      setproduct_discount(e.target.value);
    if (field === "product_sale_price")
      setproduct_sale_price(e.target.value);
    if (field === "product_tax")
      setproduct_tax(e.target.value);
  };

  return (
    <div style={{ minWidth: "60%" }}>
      <Dialog open onClose={handleClose()} classes={{ paper: classes.paper }}>
        <DialogContent>
          <div style={{ float: "right" }}><Button onClick={handleClose()}><CloseIcon /></Button></div>
          <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{submissionData?.product_name || ""}</b></h2>
          <>
            <GridContainer justifyContent="center" alignItems="center">
              <GridItem style={{ marginTop: "30px" }}>
                <label>Upload Product Image</label>
                <Dropzone
                  path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                  accept="image/png, image/jpeg"
                  onChange={value => updateFieldHandler("product_image")({ target: { value } })}
                  setuploadPercentage={setuploadPercentage}
                >
                  {() => (
                    <img
                      src={submissionData?.product_image || current?.product_image || ProductsDefaultImage || ""}
                      height="100px"
                      style={{ lineHeight: "50px", textAlign: "center", marginLeft: "18px" }}
                    />
                  )}
                </Dropzone>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}
                >Product Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                <input
                  id="product_name"
                  maxLength={255}
                  type="text"
                  readOnly={customer_id === 38 && current?.product_name ? true : false}
                  defaultValue={submissionData?.product_name || current?.product_name || ""}
                  onChange={updateFieldHandler("product_name")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product SKU</label>
                <input
                  id="product_SKU"
                  type="text"
                  defaultValue={submissionData?.product_SKU || current?.product_SKU || ""}
                  onChange={updateFieldHandler("product_SKU")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <label
                  style={{ marginTop: "30px" }}>Product Description</label>
                <div style={{ clear: "both" }}></div>
                <div className="App" style={{ float: 'left' }}>
                  <RichTextEditor updateFieldHandler={updateFieldHandler} field={"product_description"} current={current} readonly={false} />
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Cost</label>
                <input
                  id="product_cost"
                  maxLength={20}
                  type="text"
                  value={product_cost === '' && submissionData?.product_cost !== undefined && submissionData?.product_cost !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_cost) : product_cost}
                  onBlur={() => FormatCurrency("product_cost")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["product_cost"]: submissionData?.product_cost?.replace(/[^0-9.]/g, "")
                    })
                    setproduct_cost(submissionData?.product_cost?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("product_cost", e); updateProductValueFieldHandler("product_cost", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Margin</label>
                <input
                  id="product_margin"
                  maxLength={20}
                  type="text"
                  value={product_margin === '' && submissionData?.product_margin !== undefined && submissionData?.product_margin !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_margin) : product_margin}
                  onBlur={() => FormatCurrency("product_margin")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["product_margin"]: submissionData?.product_margin?.replace(/[^0-9.]/g, "")
                    })
                    setproduct_margin(submissionData?.product_margin?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("product_margin", e); updateProductValueFieldHandler("product_margin", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Price</label>
                <input
                  id="product_price"
                  type={"string"}
                  maxLength={20}
                  value={product_price === '' && submissionData?.product_price !== undefined && submissionData?.product_price !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_price) : product_price}
                  onBlur={() => FormatCurrency("product_price")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["product_price"]: submissionData?.product_price?.replace(/[^0-9.]/g, "")
                    })
                    setproduct_price(submissionData?.product_price?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("product_price", e); updateProductValueFieldHandler("product_price", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Discount</label>
                <input
                  id="product_discount"
                  type={"string"}
                  maxLength={20}
                  value={product_discount === '' && submissionData?.product_discount !== undefined && submissionData?.product_discount !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_discount) : product_discount}
                  onBlur={() => FormatCurrency("product_discount")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["product_discount"]: submissionData?.product_discount?.replace(/[^0-9.]/g, "")
                    })
                    setproduct_discount(submissionData?.product_discount?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("product_discount", e); updateProductValueFieldHandler("product_discount", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Sale Price</label>
                <input
                  id="product_sale_price"
                  type={"string"}
                  maxLength={20}
                  value={product_sale_price === '' && submissionData?.product_sale_price !== undefined && submissionData?.product_sale_price !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_sale_price) : product_sale_price}
                  onBlur={() => FormatCurrency("product_sale_price")}
                  onFocus={() => {
                    setSubmissionData({
                      ...submissionData,
                      ["product_sale_price"]: submissionData?.product_sale_price?.replace(/[^0-9.]/g, "")
                    })
                    setproduct_sale_price(submissionData?.product_sale_price?.replace(/[^0-9.]/g, ""));
                  }}
                  onChange={(e) => { updateFieldHandler("product_sale_price", e); updateProductValueFieldHandler("product_sale_price", e) }}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Inventory</label>
                <input
                  id="product_inventory"
                  type={"string"}
                  maxLength={155}
                  defaultValue={submissionData?.product_inventory || current?.product_inventory || ""}
                  onChange={updateFieldHandler("product_inventory")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Taxable</label>
                <Checkbox
                  checked={submissionData?.product_taxable === 1 ? 1 : 0}
                  style={{ "width": "20px", marginLeft: "20px" }}
                  onChange={updateFieldHandler("product_taxable")}>
                </Checkbox>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {submissionData?.product_taxable === 1 ?
                  <>
                    <label
                      style={{ marginTop: "30px" }}>Product Tax</label>
                    <input
                      id="product_tax"
                      type={"string"}
                      maxLength={20}
                      value={product_tax === '' && submissionData?.product_tax !== undefined && submissionData?.product_tax !== '' ? FormatCurrencyWithoutUpdatingState(submissionData?.product_tax) : product_tax}
                      onBlur={() => FormatCurrency("product_tax")}
                      onFocus={() => {
                        setSubmissionData({
                          ...submissionData,
                          ["product_tax"]: ''
                        })
                        setproduct_tax('');
                      }}
                      onChange={(e) => { updateFieldHandler("product_tax", e); updateProductValueFieldHandler("product_tax", e) }}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    /></> : ""}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Billing Type</label>
                <Select
                  onChange={updateFieldHandler("product_billing_type")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                  inputProps={{
                    value: submissionData?.product_billing_type || current?.product_billing_type || 0
                  }}
                >
                  <MenuItem value="0" label="Select">Select</MenuItem>
                  <MenuItem value="1">One Time</MenuItem>
                  <MenuItem value="2">Recurring</MenuItem>
                </Select>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {submissionData?.product_billing_type === "2" ?
                  <>
                    <label
                      style={{ marginTop: "30px" }}>Product Billing Fequency</label>
                    <Select
                      onChange={updateFieldHandler("product_billing_frequency")}
                      style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                      inputProps={{
                        value: submissionData?.product_billing_frequency || current?.product_billing_frequency || 0
                      }}
                    >
                      <MenuItem value="0" label="Select">Select</MenuItem>
                      <MenuItem value="1">Daily</MenuItem>
                      <MenuItem value="2">Weekly</MenuItem>
                      <MenuItem value="3">Monthly</MenuItem>
                      <MenuItem value="4">Bi-Monthly</MenuItem>
                      <MenuItem value="5">Quarterly</MenuItem>
                      <MenuItem value="6">Semi-Annually</MenuItem>
                      <MenuItem value="6">Annually</MenuItem>
                    </Select>
                  </>
                  : ""}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Unit</label>
                <input
                  id="product_unit"
                  type={"string"}
                  maxLength={155}
                  defaultValue={submissionData?.product_unit || current?.product_unit || ""}
                  onChange={updateFieldHandler("product_unit")}
                  style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Category</label>
                <SelectFromState
                  options={CategoryData?.map(({ id, category_text }) => ({
                    key: id,
                    label: category_text
                  }))
                  }
                  selected={submissionData?.product_category || current?.product_category || 0}
                  onSelect={updateFieldHandler("product_category")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Parent Category</label>
                <SelectFromState
                  options={CategoryData?.map(({ id, category_text }) => ({
                    key: id,
                    label: category_text
                  }))}
                  selected={submissionData?.parent_category || current?.parent_category || 0}
                  onSelect={updateFieldHandler("parent_category")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label
                  style={{ marginTop: "30px" }}>Product Stock Status</label>
                <Select
                  onChange={updateFieldHandler("product_stock_status")}
                  style={{ marginBottom: 20, verticalAlign: "middle", marginLeft: "20px" }}
                  inputProps={{
                    value: submissionData?.product_stock_status || current?.product_stock_status || 0
                  }}
                >
                  <MenuItem value="0" label="Select">Select</MenuItem>
                  <MenuItem value="1">In Stock</MenuItem>
                  <MenuItem value="2">Out Of Stock</MenuItem>
                  <MenuItem value="3">Backordered</MenuItem>
                </Select>
              </GridItem>
            </GridContainer>
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => handleSave(false)}>
                Save
              </Button>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default UpdateCRMProduct;