import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

import { makeStyles } from "@mui/styles";
// @mui/icons-material

// core components
import styles from "assets/jss/material-dashboard-pro-react/components/cardIconStyle.js";

const useStyles = makeStyles(styles);

export default function CardIcon(props) {
  const classes = useStyles();
  const { className, children, color, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + "CardHeader"]]: color,
    [className]: className !== undefined
  });
  return (
    <div className={cardIconClasses} {...rest}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </div>
  );
}

CardIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "orange",
    "info",
    "primary",
    "red",
    "rose",
    "google",
    "trackedKeywords",
    "grey",
    "gray",
    "wbmBlue",
    "wbmMoney"
  ]),
  children: PropTypes.node
};

CardIcon.defaultProps = {
  color: "wbmBlue"
};
