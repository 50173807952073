import React, { useContext } from "react";

import NoContentWrapper from "components/NoContentWrapper";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../redux/store";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

const LinearNoContentWrapper = ({ title, children, noTemplate = false }) => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  return (
    <NoContentWrapper
      skip={!customer_id}
      queries={[
        {
          name: "budget",
          query: GET_BUDGET,
          options: { variables: { customer_id, product_line: "linear" } }
        }
      ]}
      product="LinearCRM"
      title={title}
      isLoading={({ budget }) => budget?.loading}
      hasNoData={({ budget }) => {
        let hydratebudget = budget?.data?.getBudget;
        const spent = hydratebudget?.monthly_budget || 0;
        if (spent === 0) {
          return false;
        }
        return true;
      }}
      noContent="LinearCRM™, is a customer relationship management tool that simplifies how our customers interact, manage, communicate, and maintain their leads and customers in one platform.
      To enable LinearCRM, please contact your advertising consultant."
      noTemplate={noTemplate}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NoContentWrapper>
  );
};

export default LinearNoContentWrapper;
