const styles = {
  container: {
    position: "relative",
    display: "inline-block",
    marginLeft: 30
  },
  picker: {
    position: "absolute",
    right: 0,
    top: 30
  },
  pickerPhone: {
    position: "absolute",
    left: -30,
    right: 0,
    top: 30,
    zIndex: 1000,
    width: 300,
    transform: "scale(0.9)",
    transformOrigin: "left top",
    "& > div > div > div": {
      flexDirection: "column",
      "& > div:first-child": {
        "& > div:first-child": {
          display: "none"
        },
        "& > div:last-child": {
          flexDirection: "column"
        }
      }
    }
  },
  input: {
    width: 180
  }
};

export default styles;
