import { SELECT_COMPARE_OPTION } from "../../redux/constants";

const Reducer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_COMPARE_OPTION:
      return {
        ...state,
        compareOption: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
