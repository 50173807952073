import React from "react";
import { omit } from "lodash";

import Select from "@mui/material/Select";
import StatusSelect from "components/Select/Select";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CustomInput from "components/CustomInput/CustomInput";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'
import "assets/css/richtext_styles.css";

const filter = createFilterOptions();

const AddUpdateEstimate = (CRMActivitySubmission, updateActivityFieldHandler, updateEstimateDatabase,
  CRMPeople, EstimatesubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, updateEstimateFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, CRMProducts, CRMServices, Update = false, CRMActivity = [], CRMPhotoGallery, CRMJob,
  setEstimatesubmissionData, EstimateStatuses, user_level,
  competitorData, productData, targetAreaData, noFocusProductData, AddCompetitor, AddProduct,
  AddNoFocusProduct, AddTargetArea, ProductsHover, setProductsHover,
  RemoveCompetitor, RemoveProduct, RemoveNoFocusProduct, RemoveTargetArea, updateTopCompetitors,
  updateTopProducts, updateNoFocusProducts, updateTargetAreas, updateTargetType,
  TopCompetitorsHover, setTopCompetitorsHover, HighPriorityLocationsHover, setHighPriorityLocationsHover,
  NoFocusProductHover, setNoFocusProductHover, CreateProposalfromEstimate, CRMProposal, setproductData,
  setnoFocusProductData, settargetAreaData, duplicateTopProducts, duplicateNoFocusProducts, duplicateHighPriorityLocations,
  setduplicateTopProducts, setduplicateNoFocusProducts, setduplicateHighPriorityLocations) => {

  const PaidSearch_id = 14;
  const Hydrate_id = 1;
  const BannerAdsGeoDemo_id = 2;
  const BannerAdsGeoIntention_id = 3;
  const BannerAdsReEngage_id = 12;
  const LocalServicesAds_id = 13;
  const OrganicSearchTwo_id = 15;
  const OrganicSearchFour_id = 16;
  const OrganicSearchRegional_id = 17;
  const OrganicSearchAdditionalBusinessListingManagement_id = 18;
  const HostingMonthlyManagedServices_id = 19;
  const WebsiteDesign_id = 20;
  const WebsiteDesignWooCommerce_id = 21;
  const OfflineTracking_id = 22;
  const OnlineVideoGeoDemo_id = 24;
  const OnlineVideoReEngage_id = 25;
  const OnlineVideoGeoIntention_id = 26;
  const OCAProduction_id = 27;
  const OCAProductionTwo_id = 28;
  const OCAProductionThree_id = 29;
  const Chat_id = 30;
  const SocialAdsReEngage_id = 31;
  const OutOfHome_id = 32;
  const LinearCRM_id = 33;
  const OCAGeoDemo_id = 34;
  const OCAReEngage_id = 35;
  const OCAGeoIntention_id = 36;

  const top_competitors_columns = [
    {
      Header: "Top Competitors",
      accessor: "customers_top_competitors"
    },
    {
      Header: "Top Competitor's Url",
      accessor: "top_competitors_url"
    },
    {
      Header: "Remove"
    }
  ];

  const top_products_columns = [
    {
      Header: "Top Products",
      accessor: "product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const no_focus_products_columns = [
    {
      Header: "No Focus Products",
      accessor: "no_product_focus"
    },
    {
      Header: "Remove"
    }
  ];

  const target_areas_columns = [
    {
      Header: "Target Area",
      accessor: "customer_top_target_area"
    },
    {
      Header: "Target Type",
      accessor: "customer_top_target_type"
    },
    {
      Header: "Remove"
    }
  ];

  if (CRMProducts && EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products === 'string') {
    let Product_Data = [];
    EstimatesubmissionData?.products?.split(",")?.forEach(async (SelectedProduct) => {
      CRMProducts?.forEach(async (EstimatesubmissionDataProduct) => {
        if (SelectedProduct?.toString()?.trim() === EstimatesubmissionDataProduct?.id?.toString()) {
          Product_Data.push(EstimatesubmissionDataProduct?.product_name);
        }
      })
    })

    if (Product_Data?.length) {
      EstimatesubmissionData.products = Product_Data;
    }
  }

  if (CRMServices && EstimatesubmissionData?.services && typeof EstimatesubmissionData?.services === 'string') {
    let Service_Data = [];
    EstimatesubmissionData?.services?.split(",")?.forEach(async (SelectedCategory) => {
      CRMServices?.forEach(async (EstimatesubmissionDataCategory) => {
        if (SelectedCategory?.toString()?.trim() === EstimatesubmissionDataCategory?.id?.toString()) {
          Service_Data.push(EstimatesubmissionDataCategory?.service_name);
        }
      })
    })

    if (Service_Data?.length) {
      EstimatesubmissionData.services = Service_Data;
    }
  }

  function DuplicateTopProducts(product_id) {
    if (duplicateTopProducts === false) {
      let TopProductsubmissionDataClone = Object.keys(productData)?.filter((keyName) =>
        productData[keyName]?.product_id === product_id)
        ?.map((keyName) => productData[keyName])


      Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) =>
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id !== product_id && (
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id))?.map((CurrentBudget) => {
            if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)
              Object.keys(TopProductsubmissionDataClone)?.filter((keyName) =>
                TopProductsubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                  TopProductsubmissionDataClone.push(Object.assign({}, omit(TopProductsubmissionDataClone[keyName], ["product_id", "id"]), {
                    product_id: EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id
                  }));
                })
          })

      setproductData({ ...TopProductsubmissionDataClone });
      setduplicateTopProducts(true);
    }
  };

  function DuplicateNoFocusProducts(product_id) {
    if (duplicateNoFocusProducts === false) {
      let NoFocusProductsubmissionDataClone = Object.keys(noFocusProductData)?.filter((keyName) =>
        noFocusProductData[keyName]?.product_id === product_id)
        ?.map((keyName) => noFocusProductData[keyName])

      Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => 
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id !== product_id && (
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id))?.map((CurrentBudget) => {
          if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)
            Object.keys(NoFocusProductsubmissionDataClone)?.filter((keyName) =>
              NoFocusProductsubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
                NoFocusProductsubmissionDataClone.push(Object.assign({}, omit(NoFocusProductsubmissionDataClone[keyName], ["product_id", "id"]), {
                  product_id: EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id
                }));
              })
        })

      setnoFocusProductData({ ...NoFocusProductsubmissionDataClone });
      setduplicateNoFocusProducts(true);
    }
  };

  function DuplicateHighPriorityLocations(product_id) {
    if (duplicateHighPriorityLocations === false) {
      let TargetAreasubmissionDataClone = Object.keys(targetAreaData)?.filter((keyName) =>
        targetAreaData[keyName]?.product_id === product_id)
        ?.map((keyName) => targetAreaData[keyName])

      Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id !== product_id && (
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id ||
        EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LinearCRM_id
      ))?.map((CurrentBudget) => {
        if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id ||
          EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LinearCRM_id)
          Object.keys(TargetAreasubmissionDataClone)?.filter((keyName) =>
            TargetAreasubmissionDataClone[keyName]?.product_id === product_id)?.map((keyName, i) => {
              TargetAreasubmissionDataClone.push(Object.assign({}, omit(TargetAreasubmissionDataClone[keyName], ["product_id", "id"]), {
                product_id: EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id
              }));
            })
      })

      settargetAreaData({ ...TargetAreasubmissionDataClone });
      setduplicateHighPriorityLocations(true);
    }
  };
  
  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update === false ? "Create" : user_level === "master" || user_level === "paid_ad_manager" ? "Update" : ""} Estimate</label>
      {(user_level === "master" || user_level === "paid_ad_manager") && (Update === false || (EstimatesubmissionData?.id &&
        CRMProposal?.filter((CurrentProposal) => CurrentProposal.estimate_id === EstimatesubmissionData?.id)?.length === 0)) ?
        <>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Estimate Name:</label>
            <input
              id="estimate_name"
              maxLength={255}
              type="text"
              value={EstimatesubmissionData?.estimate_name || ""}
              onChange={updateEstimateFieldHandler("estimate_name")}
              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
            /></div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Ad Budget:</label>
            <input
              id="estimate_ad_budget"
              maxLength={255}
              type="text"
              value={EstimatesubmissionData?.estimate_ad_budget || ""}
              onBlur={() => {
                if (EstimatesubmissionData.estimate_ad_budget) {
                  EstimatesubmissionData.estimate_ad_budget = parseFloat(EstimatesubmissionData?.estimate_ad_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })

                  setEstimatesubmissionData({
                    ...EstimatesubmissionData
                  });
                }
              }
              }
              onFocus={() => {
                if (EstimatesubmissionData.estimate_ad_budget) {
                  EstimatesubmissionData.estimate_ad_budget = EstimatesubmissionData?.estimate_ad_budget?.replace(/[^0-9.]/g, "")

                  setEstimatesubmissionData({
                    ...EstimatesubmissionData
                  });
                }
              }
              }
              onChange={updateEstimateFieldHandler("estimate_ad_budget")}
              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
            /></div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Needed by Date:</label>
            <TextField
              id="estimate_needed_by_date"
              type="date"
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              value={EstimatesubmissionData?.contract_start_date?.indexOf("-") === -1 ? moment(parseInt(EstimatesubmissionData?.estimate_needed_by_date)).format("YYYY-MM-DDTHH:mm") : EstimatesubmissionData?.estimate_needed_by_date ? EstimatesubmissionData?.estimate_needed_by_date : ""}
              onChange={
                updateEstimateFieldHandler("estimate_needed_by_date")
              }
              size={"small"}
            />
          </div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Service(s):</label>
            <MultiSelect
              multiple
              input={<Input />}
              renderValue={selected => selected.join(", ").substring(0, 82)}
              onChange={updateEstimateFieldHandler("services")}
              inputProps={{
                name: "services",
                id: "services",
                value: (typeof EstimatesubmissionData?.services === 'string' || EstimatesubmissionData?.services instanceof String) ? EstimatesubmissionData?.services?.split(",").map((category_text) => { return category_text }) || [] : (typeof EstimatesubmissionData?.services !== 'undefined') ? EstimatesubmissionData?.services || [] : []
              }}
              style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
            >
              {CRMServices?.map((EstimatesubmissionDataService) => {
                return (
                  <MenuItem key={EstimatesubmissionDataService?.id} value={EstimatesubmissionDataService?.service_name}>
                    <CustomCheckbox
                      checked={
                        EstimatesubmissionData?.services?.indexOf(EstimatesubmissionDataService?.service_name) >
                        -1
                      }
                    />
                    <ListItemText primary={EstimatesubmissionDataService?.service_name} />
                  </MenuItem>
                )
              })}
            </MultiSelect>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Product(s):</label>
            <MultiSelect
              multiple
              input={<Input />}
              renderValue={selected => selected.join(", ").substring(0, 82)}
              onChange={updateEstimateFieldHandler("products")}
              inputProps={{
                name: "products",
                id: "products",
                value: (typeof EstimatesubmissionData?.products === 'string' || EstimatesubmissionData?.products instanceof String) ? EstimatesubmissionData?.products?.split(",").map((product_name) => { return product_name }) || [] : (typeof EstimatesubmissionData?.products !== 'undefined') ? EstimatesubmissionData?.products || [] : []
              }}
              style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
            >
              {CRMProducts?.map((EstimatesubmissionDataProduct) => {
                return (
                  <MenuItem key={EstimatesubmissionDataProduct?.id} value={EstimatesubmissionDataProduct?.product_name}>
                    <CustomCheckbox
                      checked={
                        EstimatesubmissionData?.products?.indexOf(EstimatesubmissionDataProduct?.product_name) >
                        -1
                      }
                    />
                    <ListItemText primary={EstimatesubmissionDataProduct?.product_name} />
                  </MenuItem>
                )
              })}
            </MultiSelect>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          {EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.map((CurrentProduct, index) => {
              return (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  <label style={{ textAlign: "left", width: "150px" }}>{CurrentProduct === "WebsiteDesign" ||
                    CurrentProduct === "WebsiteDesign | WooCommerce" || CurrentProduct === "OCA | Production"
                    || CurrentProduct === "OCA | Production Two" || CurrentProduct === "OCA | Production Three" ? CurrentProduct + " Total" : CurrentProduct + " Monthly"} Budget $:</label>
                  <input
                    id={`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`}
                    maxLength={255}
                    type="text"
                    value={EstimatesubmissionData?.Budgets !== undefined && Object.keys(EstimatesubmissionData["Budgets"])?.length ? Object.keys(EstimatesubmissionData["Budgets"])?.map((item, i) => { if (EstimatesubmissionData["Budgets"][i]?.LocalID === CurrentProduct?.toLowerCase()?.replaceAll(" ", "")) return EstimatesubmissionData["Budgets"][i]?.monthly_budget })?.filter(CurrentBudget => CurrentBudget !== undefined) : ""}
                    onBlur={(e) => {
                      EstimatesubmissionData?.Budgets && Object.keys(EstimatesubmissionData?.Budgets)?.length ?
                        Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                          if (EstimatesubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                            let EstimatesubmissionDataClone = Object.assign({}, EstimatesubmissionData);
                            EstimatesubmissionDataClone.Budgets[i] = Object.assign({}, {
                              "product_name": EstimatesubmissionData?.Budgets[i]?.product_name, "LocalID": EstimatesubmissionData?.Budgets[i]?.LocalID, "monthly_budget": parseFloat(EstimatesubmissionData["Budgets"][i]?.monthly_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
                                style: 'decimal',
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2
                              }),
                              "budget_cycles_product_id": EstimatesubmissionData?.Budgets[i]?.budget_cycles_product_id, product_id: EstimatesubmissionData?.Budgets[i]?.product_id
                            })
                            setEstimatesubmissionData({
                              ...EstimatesubmissionDataClone
                            });
                          }
                        })
                        : ""
                    }
                    }
                    onChange={updateEstimateFieldHandler(`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`)}
                    onFocus={(e) => {
                      EstimatesubmissionData?.Budgets && Object.keys(EstimatesubmissionData?.Budgets)?.length ?
                        Object.keys(EstimatesubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                          if (EstimatesubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                            let EstimatesubmissionDataClone = Object.assign({}, EstimatesubmissionData);
                            EstimatesubmissionDataClone.Budgets[i] = Object.assign({}, { "product_name": EstimatesubmissionData?.Budgets[i]?.product_name, "LocalID": EstimatesubmissionData?.Budgets[i]?.LocalID, "monthly_budget": EstimatesubmissionData["Budgets"][i]?.monthly_budget?.replace(/[^0-9.]/g, ""), "budget_cycles_product_id": EstimatesubmissionData?.Budgets[i]?.budget_cycles_product_id, product_id: EstimatesubmissionData?.Budgets[i]?.product_id })
                            setEstimatesubmissionData({
                              ...EstimatesubmissionDataClone
                            });
                          }
                        })
                        : ""
                    }
                    }
                    style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
                  />
                </div>
              )
            })
            : ""
          }
          {EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.filter(CurrentProduct =>
              CurrentProduct?.indexOf("OrganicSearch") > -1 ||
              CurrentProduct?.indexOf("PaidSearch") > -1 || CurrentProduct?.indexOf("GeoIntention®") > -1
            )?.map((CurrentProduct, index) => {
              if (index === 0) {
                return (
                  <>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <label style={{ textAlign: "left", width: "150px" }}>Keyword Ideas:</label>
                      <textarea
                        id="keyword_ideas"
                        maxLength={255}
                        type="text"
                        value={EstimatesubmissionData?.keyword_ideas || ""}
                        onChange={updateEstimateFieldHandler("keyword_ideas")}
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", height: "50px" }}
                      /></div>
                    <div style={{ clear: "both", marginTop: "30px" }}></div>
                  </>
                )
              }
            }) : ""}
          <div style={{ clear: "both", height: "30px" }}></div>
          {EstimatesubmissionData && EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.filter(CurrentProduct =>
              CurrentProduct?.indexOf("GeoDemo®") > -1 ||
              CurrentProduct?.indexOf("GeoIntention®") > -1 || CurrentProduct?.indexOf("ReEngage®") > -1)?.map((CurrentProduct, index) => {
                if (index === 0) {
                  return (
                    <>
                      <div style={{ display: "flex", marginTop: "30px" }}>
                        <label style={{ textAlign: "left", width: "150px" }}>Ideal Audiences or Demographics:</label>
                        <textarea
                          id="ideal_customer"
                          maxLength={255}
                          type="text"
                          value={EstimatesubmissionData?.ideal_customer || ""}
                          onChange={updateEstimateFieldHandler("ideal_customer")}
                          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
                        /></div>
                      <div style={{ clear: "both", marginTop: "30px" }}></div>
                    </>
                  )
                }
              }) : ""}
          {EstimatesubmissionData?.Budgets && Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)?.map((CurrentBudget, index) => {
              if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)
                return (
                  <>
                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{EstimatesubmissionData?.Budgets[CurrentBudget]?.product_name}</b></h4>
                    {index !== 0 ?
                      <div style={{ display: "inline-block" }}>
                        <label style={{ verticalAlign: "middle" }}>
                          <Checkbox
                            checked={duplicateTopProducts}
                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                            onChange={() => {
                              DuplicateTopProducts(EstimatesubmissionData?.Budgets[0]?.product_id)
                            }}>
                          </Checkbox><span>{duplicateTopProducts === false ? "duplicate first product targeting" : "Duplicated"}</span></label>
                      </div>
                      : ""}
                    <div style={{ clear: "both", height: "30px" }}></div>
                    <label style={{ color: "#363636", fontSize: "16px" }}><b>What are the top products or services we should market?</b></label>
                    <br />
                    <br />
                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                      <Table stickyHeader>
                        <TableRow>
                          {top_products_columns.map((datacolumns) => (
                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                          ))}
                        </TableRow>
                        {productData ? Object.keys(productData).map((keyName, Innerindex) => {
                          if (productData[keyName]?.product_id === EstimatesubmissionData?.Budgets[index]?.product_id)
                            return (
                              <TableRow key={productData[keyName]?.id}>
                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                  <CustomInput
                                    id={`customers_top_products${productData[keyName].id}${index + Innerindex}`}
                                    maxLength="455"
                                    type="text"
                                    key={`customers_top_products${productData[keyName].id}${index + Innerindex}`}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: event => { updateTopProducts(Innerindex, event, EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id) },
                                      defaultValue: productData[keyName]?.product_focus ? productData[keyName].product_focus : ''
                                    }}
                                  />
                                </TableCell>
                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                  <a style={{ cursor: "pointer", color: ProductsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveProduct(productData[keyName]?.id)}><FontAwesomeIcon icon={ProductsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setProductsHover({ ...ProductsHover, [Innerindex]: true }) }} onMouseLeave={() => { setProductsHover({ ...ProductsHover, [Innerindex]: false }) }} /></a>
                                </TableCell>
                              </TableRow>
                            )
                        }
                        ) : ""}
                      </Table>
                    </TableContainer>
                    <br />
                    <a style={{ cursor: "pointer" }} onClick={() => AddProduct(EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                    <div style={{ clear: "both", height: "30px" }}></div>
                  </>
                )
            })}
          {EstimatesubmissionData?.Budgets && Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) => EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)?.map((CurrentBudget, index) => {
              if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === Hydrate_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OutOfHome_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
                EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id)
                return (
                  <>
                    <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{EstimatesubmissionData?.Budgets[CurrentBudget]?.product_name}</b></h4>
                    {index !== 0 ?
                      <div style={{ display: "inline-block" }}>
                        <label style={{ verticalAlign: "middle" }}>
                          <Checkbox
                            checked={duplicateNoFocusProducts}
                            style={{ marginRight: "20px", verticalAlign: "middle" }}
                            onChange={() => {
                              DuplicateNoFocusProducts(EstimatesubmissionData?.Budgets[0]?.product_id)
                            }}>
                          </Checkbox><span>{duplicateNoFocusProducts === false ? "duplicate first no focus products" : "Duplicated"}</span></label>
                      </div>
                      : ""}
                    <div style={{ clear: "both", height: "30px" }}></div>
                    <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>What products or services we should not market?</b></label>
                    <br />
                    <br />
                    <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                      <Table stickyHeader>
                        <TableRow>
                          {no_focus_products_columns.map((datacolumns) => (
                            <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                          ))}
                        </TableRow>
                        {noFocusProductData ? Object.keys(noFocusProductData).map((keyName, Innerindex) => {
                          if (noFocusProductData[keyName]?.product_id === EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id)
                            return (
                              <TableRow key={noFocusProductData[keyName]?.id}>
                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                  <CustomInput
                                    id={`customers_no_focus_products${noFocusProductData[keyName].id}${index + Innerindex}`}
                                    maxLength="455"
                                    type="text"
                                    key={`customers_no_focus_products${noFocusProductData[keyName].id}${index + Innerindex}`}
                                    formControlProps={{
                                      fullWidth: true,
                                    }}
                                    inputProps={{
                                      onChange: event => { updateNoFocusProducts(Innerindex, event, EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id) },
                                      defaultValue: noFocusProductData[keyName]?.no_product_focus ? noFocusProductData[keyName].no_product_focus : ''
                                    }}
                                  />
                                </TableCell>
                                <TableCell style={{ padding: 5, textAlign: "center" }}>
                                  <a style={{ cursor: "pointer", color: NoFocusProductHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveNoFocusProduct(noFocusProductData[keyName]?.id)}><FontAwesomeIcon icon={NoFocusProductHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setNoFocusProductHover({ ...NoFocusProductHover, [Innerindex]: true }) }} onMouseLeave={() => { setNoFocusProductHover({ ...NoFocusProductHover, [Innerindex]: false }) }} /></a>
                                </TableCell>
                              </TableRow>
                            )
                        }
                        ) : ""}
                      </Table>
                    </TableContainer>
                    <br />
                    <a style={{ cursor: "pointer" }} onClick={() => AddNoFocusProduct(EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                  </>
                )
            })}
          <div style={{ clear: "both", height: "30px" }}></div>
          {EstimatesubmissionData?.Budgets && Object.keys(EstimatesubmissionData?.Budgets)?.filter((CurrentBudget) =>
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id ||
            EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LinearCRM_id
          )?.map((CurrentBudget, index) => {
            if (EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === PaidSearch_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchTwo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchFour_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchRegional_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OrganicSearchAdditionalBusinessListingManagement_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === BannerAdsReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OnlineVideoGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesign_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === WebsiteDesignWooCommerce_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoDemo_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === OCAGeoIntention_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LocalServicesAds_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === SocialAdsReEngage_id ||
              EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id === LinearCRM_id)
              return (
                <>
                  <div style={{ clear: "both", height: "40px" }}></div>
                  <label style={{ color: "#363636", fontSize: "16px" }}><b>High Priority Locations | (Zip Codes, Cities, States, Counties or Radius)</b></label>
                  <div style={{ clear: "both", height: "40px" }}></div>
                  <h4 style={{ color: "#363636", fontSize: "16px" }}>Product: <b>{EstimatesubmissionData?.Budgets[CurrentBudget]?.product_name}</b></h4>
                  {index !== 0 ?
                    <div style={{ display: "inline-block" }}>
                      <label style={{ verticalAlign: "middle" }}>
                        <Checkbox
                          checked={duplicateHighPriorityLocations}
                          style={{ marginRight: "20px", verticalAlign: "middle" }}
                          onChange={() => {
                            DuplicateHighPriorityLocations(EstimatesubmissionData?.Budgets[0]?.product_id)
                          }}>
                        </Checkbox><span>{duplicateHighPriorityLocations === false ? "duplicate first location targeting" : "Duplicated"}</span></label>
                    </div>
                    : ""}
                  <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
                    <Table stickyHeader>
                      <TableRow>
                        {target_areas_columns.map((datacolumns) => (
                          <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                        ))}
                      </TableRow>
                      {targetAreaData ? Object.keys(targetAreaData).map((keyName, innerIndex) => {
                        if (targetAreaData[keyName]?.product_id === EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id)
                          return (
                            <TableRow key={targetAreaData[keyName]?.id + index}>
                              <TableCell style={{ padding: 5 }}>
                                <CustomInput
                                  id={`customers_target_areas${targetAreaData[keyName].id}${innerIndex + index}`}
                                  maxLength="455"
                                  type="text"
                                  key={`customers_target_areas${targetAreaData[keyName].id}${innerIndex + index}`}
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{
                                    onChange: event => { updateTargetAreas(innerIndex, event, EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id) },
                                    defaultValue: targetAreaData[keyName]?.customer_top_target_area ? targetAreaData[keyName].customer_top_target_area : '',
                                    style: { verticalAlign: "middle" }
                                  }}
                                />
                              </TableCell>
                              <TableCell style={{ padding: 5, textAlign: "center" }}>
                                <Select
                                  id={`customers_target_type${targetAreaData[keyName].id}${innerIndex + index}`}
                                  inputProps={{
                                    onChange: event => { updateTargetType(innerIndex, event, EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id) },
                                    value: targetAreaData[keyName]?.customer_top_target_type ? targetAreaData[keyName].customer_top_target_type : "Select"
                                  }}
                                >
                                  <MenuItem value="Select" label="Select">Select</MenuItem>
                                  <MenuItem value="Zip Codes">Zip Codes</MenuItem>
                                  <MenuItem value="Cities">Cities</MenuItem>
                                  <MenuItem value="States">States</MenuItem>
                                  <MenuItem value="Counties">Counties</MenuItem>
                                </Select>
                              </TableCell>
                              <TableCell style={{ padding: 0, textAlign: "center" }}>
                                <a style={{ cursor: "pointer", color: HighPriorityLocationsHover[innerIndex + index] === true ? "red" : "#000" }} onClick={() => RemoveTargetArea(targetAreaData[keyName]?.id)}><FontAwesomeIcon icon={HighPriorityLocationsHover[innerIndex + index] ? faDumpsterFire : faDumpster} onMouseOver={() => { setHighPriorityLocationsHover({ ...HighPriorityLocationsHover, [innerIndex + index]: true }) }} onMouseLeave={() => { setHighPriorityLocationsHover({ ...HighPriorityLocationsHover, [innerIndex + index]: false }) }} /></a>
                              </TableCell>
                            </TableRow>
                          )
                      }
                      ) : ""}
                    </Table>
                  </TableContainer>
                  <br />
                  <a style={{ cursor: "pointer" }} onClick={() => AddTargetArea(EstimatesubmissionData?.Budgets[CurrentBudget]?.product_id)}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
                </>
              )
          })}
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ color: "#363636", fontSize: "16px" }}>&nbsp;&nbsp;<b>Who are the top competitors?</b></label>
          <br />
          <br />
          <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
            <Table stickyHeader>
              <TableRow>
                {top_competitors_columns.map((datacolumns) => (
                  <TableCell style={{ textAlign: "center" }}><label>{datacolumns?.Header}</label></TableCell>
                ))}
              </TableRow>
              {competitorData ? Object.keys(competitorData).map((keyName, Innerindex) => (
                <TableRow key={competitorData[keyName]?.id}>
                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                    <CustomInput
                      id={`customers_top_competitors${competitorData[keyName].id}${Innerindex}`}
                      maxLength="455"
                      type="text"
                      key={`customers_top_competitors${competitorData[keyName].id}${Innerindex}`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => { updateTopCompetitors(Innerindex, "customers_top_competitors", event) },
                        defaultValue: competitorData[keyName]?.customers_top_competitors ? competitorData[keyName].customers_top_competitors : ''
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ padding: 5, textAlign: "center" }}>
                    <CustomInput
                      id={`competitorData[keyName]${competitorData[keyName].id}${Innerindex}`}
                      maxLength="455"
                      type="text"
                      key={`competitorData[keyName]${competitorData[keyName].id}${Innerindex}`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: event => { updateTopCompetitors(Innerindex, "top_competitors_url", event) },
                        defaultValue: competitorData[keyName]?.top_competitors_url ? competitorData[keyName].top_competitors_url : ''
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ padding: 0, textAlign: "center" }}>
                    <a style={{ cursor: "pointer", color: TopCompetitorsHover[Innerindex] === true ? "red" : "#000" }} onClick={RemoveCompetitor(competitorData[keyName]?.id)}><FontAwesomeIcon icon={TopCompetitorsHover[Innerindex] ? faDumpsterFire : faDumpster} onMouseOver={() => { setTopCompetitorsHover({ ...TopCompetitorsHover, [Innerindex]: true }) }} onMouseLeave={() => { setTopCompetitorsHover({ ...TopCompetitorsHover, [Innerindex]: false }) }} /></a>
                  </TableCell>
                </TableRow>
              )) : ""}
            </Table>
          </TableContainer>
          <br />
          <a style={{ cursor: "pointer" }} onClick={() => AddCompetitor()}><AddCircleOutlineRoundedIcon style={{ align: "center" }} />&nbsp;&nbsp;Add More</a>
          {user_level === "master" || user_level === "paid_ad_manager" ?
            <>
              <div style={{ clear: "both", height: "30px" }}></div>
              <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, float: "left", width: "125px" }}>Estimate Status:</label>
              <StatusSelect
                selected={typeof EstimatesubmissionData?.estimate_status === 'string' ? EstimateStatuses?.map(({ label, value }) => { if (label === EstimatesubmissionData?.estimate_status) return value })?.filter(CurrentStatus => CurrentStatus !== undefined) : EstimatesubmissionData?.estimate_status ? EstimatesubmissionData?.estimate_status : 0}
                onSelect={updateEstimateFieldHandler("estimate_status")}
                options={EstimateStatuses?.map(({ label, value }) => ({
                  key: value,
                  label: label
                }))}
                style={{ marginBottom: 20, float: "left", height: "40px" }}
              />
            </>
            : ""}
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          {window.location.href.indexOf("crmpersondetail") === -1 && (
            <>
              <div style={{ marginTop: "20px" }}>
                <label
                  style={{ marginTop: "8px", width: "125px", float: "left" }}>People:</label>
                {Update === true ?
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="people_name"
                      style={{ float: "left", borderRadius: "5px", width: "100%" }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          updatePeopleNameFieldHandler(newValue.label, "Estimate")
                        } else if (newValue) {
                          updatePeopleNameFieldHandler(newValue.label, "Estimate")
                        }
                      }}
                      value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === EstimatesubmissionData?.people_id)?.map((CurrentPerson) => {
                        return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                      })[0] || { label: EstimatesubmissionData?.people_name ? EstimatesubmissionData?.people_name : "" }}
                      options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                        key: id,
                        label: first_name + " " + last_name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { padding: 5, textAlign: "center" }
                          }}
                          onChange={updatePeopleFieldHandler("people_name", "Estimate")}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                  :
                  <Stack spacing={2}>
                    <Autocomplete
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableClearable
                      id="people_name"
                      style={{ float: "left", borderRadius: "5px", width: "100%" }}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          updatePeopleNameFieldHandler(newValue.label, "Estimate")
                        } else if (newValue) {
                          updatePeopleNameFieldHandler(newValue.label, "Estimate")
                        }
                      }}
                      options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                        key: id,
                        label: first_name + " " + last_name
                      })) || []}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Regular option
                        return option.label;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.label}</li>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { padding: 5, textAlign: "center" }
                          }}
                          onChange={updatePeopleFieldHandler("people_name", "Estimate")}
                          maxLength={255}
                        />
                      )}
                    />
                  </Stack>
                }
                {PersonFound ? "" :
                  <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
              </div>
            </>
          )}
          {CRMJob?.length > 0 && (
            <>
              <div style={{ clear: "both", marginTop: "30px" }}></div>
              <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Job:</label>
              <StatusSelect
                options={CRMJob?.map(({ id, job_name }) => ({
                  key: id,
                  label: job_name
                }))}
                selected={EstimatesubmissionData?.job_id || 0}
                onSelect={updateEstimateFieldHandler("job_id")}
                style={{ marginBottom: 20, float: "left", height: "40px" }}
              />
            </>
          )}
          {CRMPhotoGallery?.length > 0 && (
            <>
              <div style={{ clear: "both", marginTop: "30px" }}></div>
              <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Gallery:</label>
              <StatusSelect
                options={CRMPhotoGallery?.map(({ id, photo_gallery_name }) => ({
                  key: id,
                  label: photo_gallery_name
                }))}
                selected={EstimatesubmissionData?.photo_gallery_id || 0}
                onSelect={updateEstimateFieldHandler("photo_gallery_id")}
                style={{ marginBottom: 20, float: "left", height: "40px" }}
              />
            </>
          )}
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div>
            <label style={{ marginTop: "8px", width: "125px", float: "left" }}>Assign To:</label>
            <StatusSelect
              options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
                key: id,
                label: first_name + " " + last_name
              }))}
              selected={EstimatesubmissionData?.assigned_to || ""}
              onSelect={updateEstimateFieldHandler("assigned_to")}
              style={{ marginBottom: 20, height: "40px", float: "left" }}
            />
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left", width: "150px" }}>Additional Comments:</label>
            <textarea
              id="additional_comments"
              maxLength={255}
              type="text"
              value={EstimatesubmissionData?.additional_comments || ""}
              onChange={updateEstimateFieldHandler("additional_comments")}
              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
            /></div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
            updateEstimateDatabase();
          }}>
            Save
          </Button>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          {(customer_id === 38 || customer_id === 165) && EstimatesubmissionData?.estimate_status === "Complete" && (
            <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
              CreateProposalfromEstimate(EstimatesubmissionData?.id);
            }}>
              Create Proposal from Estimate
            </Button>
          )}
        </>
        : <>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left" }}>Estimate Name:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.estimate_name || ""}</b></label></label>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left" }}>Ad Budget:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.estimate_ad_budget || ""}</b></label></label>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ textAlign: "left" }}>Needed by Date:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.estimate_needed_by_date}</b></label></label>
          </div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left" }}>Service(s):&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.services?.map((category_text) => { return category_text })?.join(", ")}</b></label></label>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <label style={{ textAlign: "left" }}>Product(s):&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.products?.map((category_text) => { return category_text })?.join(", ")}</b></label></label>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          {EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.map((CurrentProduct, index) => {
              return (
                <div style={{ display: "flex", marginTop: "30px" }}>
                  <label style={{ textAlign: "left" }}>{CurrentProduct === "WebsiteDesign" ||
                    CurrentProduct === "WebsiteDesign | WooCommerce" || CurrentProduct === "OCA | Production"
                    || CurrentProduct === "OCA | Production Two" || CurrentProduct === "OCA | Production Three" ? CurrentProduct + " Total" : CurrentProduct + " Monthly"} Budget $:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.Budgets !== undefined && Object.keys(EstimatesubmissionData["Budgets"])?.length ? Object.keys(EstimatesubmissionData["Budgets"])?.map((item, i) => { if (EstimatesubmissionData["Budgets"][i]?.LocalID === CurrentProduct?.toLowerCase()?.replaceAll(" ", "")) return EstimatesubmissionData["Budgets"][i]?.monthly_budget })?.filter(CurrentBudget => CurrentBudget !== undefined) : ""}</b></label></label>
                </div>
              )
            })
            : ""
          }
          {EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.filter((CurrentProduct) => {
              CurrentProduct?.indexOf("OrganicSearch") > -1 ||
                CurrentProduct?.indexOf("PaidSearch") > -1 || CurrentProduct?.indexOf("GeoIntention®") > -1
            })?.map((CurrentProduct, index) => {
              if (index === 0) {
                return (
                  <>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <label style={{ textAlign: "left" }}>Keyword Ideas:</label>&nbsp;&nbsp;&nbsp;
                      <label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.keyword_ideas || ""}</b></label></div>
                    <div style={{ clear: "both", marginTop: "30px" }}></div>
                  </>
                )
              }
            }) : ""}
          {EstimatesubmissionData?.products && typeof EstimatesubmissionData?.products !== 'string' ?
            EstimatesubmissionData?.products?.map((CurrentProduct, index) => {
              if (CurrentProduct?.indexOf("GeoDemo®") > -1 ||
                CurrentProduct?.indexOf("GeoIntention®") > -1 || CurrentProduct?.indexOf("ReEngage®") > -1)
                return (
                  <>
                    <div style={{ display: "flex", marginTop: "30px" }}>
                      <label style={{ textAlign: "left" }}>Ideal Audiences or Demographics:</label>&nbsp;&nbsp;&nbsp;
                      <label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.ideal_customer || ""}</b></label>
                    </div>
                    <div style={{ clear: "both", marginTop: "30px" }}></div>
                  </>
                )
            }) : ""}
          <label style={{ fontSize: "16px" }}>What are the top products or services we should market?</label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ color: "#363636", fontSize: "16px" }}><b>{productData ? Object.keys(productData).map((keyName, index) => {
            return (productData[keyName]?.product_focus)
          })?.join(", ") : ""}</b></label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ fontSize: "16px" }}>What products or services we should not market?</label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ color: "#363636", fontSize: "16px" }}><b>{noFocusProductData ? Object.keys(noFocusProductData).map((keyName, index) => {
            return (noFocusProductData[keyName]?.no_product_focus)
          })?.join(", ") : ""}</b></label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ fontSize: "16px" }}>What area should we market? (Zip Codes, Cities, States, Counties or Radius)</label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ color: "#363636", fontSize: "16px" }}><b>{targetAreaData ? Object.keys(targetAreaData).map((keyName, index) => {
            return (targetAreaData[keyName]?.customer_top_target_area + " Type: " + targetAreaData[keyName]?.customer_top_target_type)
          })?.join(", ") : ""}</b></label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ fontSize: "16px" }}>Who are the top competitors?</label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ color: "#363636", fontSize: "16px" }}><b>{competitorData ? Object.keys(competitorData).map((keyName, index) => {
            return (competitorData[keyName]?.customers_top_competitors + " URL: " + competitorData[keyName]?.top_competitors_url)
          })?.join(", ") : ""}</b></label>
          <div style={{ clear: "both", height: "30px" }}></div>
          <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginRight: "15px", float: "left" }}>Estimate Status:&nbsp;&nbsp;&nbsp;
            <label style={{ color: "#363636", fontSize: "16px" }}><b>{EstimateStatuses?.map(({ label, value }) => { if (label === EstimatesubmissionData?.estimate_status) return label })?.filter(CurrentStatus => CurrentStatus !== undefined)}</b></label></label>
          <div style={{ clear: "both", height: "30px" }}></div>
          {window.location.href.indexOf("crmpersondetail") === -1 && (
            <>
              <div style={{ marginTop: "20px" }}>
                <label
                  style={{ marginTop: "8px", float: "left" }}>People:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === EstimatesubmissionData?.people_id)?.map((CurrentPerson) => {
                    return (CurrentPerson?.first_name + " " + CurrentPerson?.last_name)
                  })}</b></label></label>
              </div>
            </>
          )}
          {CRMJob?.length > 0 && EstimatesubmissionData?.job_id && (
            <>
              <div style={{ clear: "both", marginTop: "30px" }}></div>
              <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Job:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{CRMJob?.filter((CurrentJob) => CurrentJob?.id === EstimatesubmissionData?.job_id)?.map(({ id, job_name }) => {
                return (
                  job_name
                )
              })}</b></label></label>
            </>
          )}
          {CRMPhotoGallery?.length > 0 && EstimatesubmissionData?.photo_gallery_id && (
            <>
              <div style={{ clear: "both", marginTop: "30px" }}></div>
              <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Gallery:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{CRMPhotoGallery?.filter((CurrentGallery) => CurrentGallery?.id === EstimatesubmissionData?.photo_gallery_id)?.map(({ id, photo_gallery_name }) => {
                return (
                  photo_gallery_name
                )
              })}</b></label></label>

            </>
          )}
          <div style={{ clear: "both", marginTop: "30px" }}></div>
          <div>
            <label style={{ marginTop: "8px", float: "left" }}>Assign To:&nbsp;&nbsp;&nbsp;<label style={{ color: "#363636", fontSize: "16px" }}><b>{CRMAssignedToUsers?.filter((CurrenAssignedToUsers) => CurrenAssignedToUsers?.id === EstimatesubmissionData?.assigned_to)?.map(({ id, first_name, last_name }) => {
              return (
                first_name + " " + last_name
              )
            })}</b></label></label>
          </div>
          <div style={{ clear: "both", height: "30px" }}></div>
          <div style={{ marginTop: "30px" }}>
            <label style={{ textAlign: "left" }}>Additional Comments:</label>
            <div style={{ clear: "both", marginTop: "30px" }}></div>
            <label style={{ textAlign: "left", color: "#363636", fontSize: "16px" }}><b>{EstimatesubmissionData?.additional_comments || ""}</b></label>
          </div>
          <div style={{ clear: "both", marginTop: "30px" }}></div>
        </>
      }
    </div>
  )
};

export default AddUpdateEstimate;
