import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import Dialog from '@mui/material/Dialog';


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_SOCIAL_ASSETS } from "queries/social";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const SocialAssets = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  const facebook_id = state.customers?.selected?.facebook_id;
  let { loading, data } = useQuery(LIST_SOCIAL_ASSETS, {
    skip: !facebook_id || !state.customers?.dateRangeString,
    variables: {
      facebook_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = facebook_id ? data?.listSocialAssets || [] : [];
  const classes = useStyles();
  const columns = [
    {
      Header: "Ad Name",
      accessor: "ad_name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "View Instagram Ad",
      accessor: "instagram_permalink_url"
    },
    {
      Header: "Call to Action",
      accessor: "call_to_action_type"
    },
    {
      Header: "View Facebook Ad",
      accessor: "image_url"
    },
    {
      Header: "Thumbnail",
      accessor: "thumbnail_url",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        !original?.thumbnail_url ? (
          "Unknown"
        ) : (
          <a
            href="#"
            rel="noopener noreferrer"
            onClick={e => {
              setImageUrl(original?.thumbnail_url);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            View
          </a>
        )
    },
    {
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Body",
      accessor: "body"
    },
    {
      Header: "Story Call to Action",
      accessor: "object_story_spec_link_data_call_to_action_type"
    },
    {
      Header: "Story Name",
      accessor: "object_story_spec_link_data_name"
    },
    {
      Header: "Story Message",
      accessor: "object_story_spec_link_data_message"
    },
    {
      Header: "Story Landing Page",
      accessor: "object_story_spec_link_data_link"
    }
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Links to SocialAds Assets</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <Dialog
        open={Boolean(imageUrl)}
        onClose={() => setImageUrl(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paperScrollPaper: classes.paperScrollPaper
        }}
      >
        <img src={imageUrl} alt="" />
      </Dialog>
    </GridContainer>
  );
};

export default SocialAssets;
