import React from "react";

// @mui/icons-material
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(styles);

const CRMDataStructures = () => {
  const classes = useStyles();

  return (
    <div>
      <GridContainer marginTop="20px" justify="center" alignItems="center">
        <GridItem xs={16} sm={16} md={16}>

          <Card>
            <CardHeader color="rose" icon>
              <CardIcon>
                <FlagOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                LinearCRM Lead Structure
              </h4>
            </CardHeader>
            <CardBody>
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Attribute</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4 style={{ fontSize: "21px"}}><b>Value</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Length</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Guardians of the Galaxy</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Phone</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>800-876-5309</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>20 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 1</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 Milano</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 2</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Milky Way</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>455 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business City</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>St. Charles</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business State</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Missouri</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>30 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Country</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>USA</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>60 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Zip Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>6310</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Website</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b><a target="_blank" href="https://www.marvel.com/movies/guardians-of-the-galaxy">https://www.marvel.com/movies/guardians-of-the-galaxy</a></b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>2,000 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Status</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>0 is a lead 1 is a customer.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>1 Digit</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Industry</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Saving The Universe</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>500 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Revenue</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1,000,000.00</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>17 Digits</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>SIC Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1234</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>NAICS Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>112344</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>6 Digits</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div id="Customer" style={{height : "1px"}}>&nbsp;</div>
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Number of Employees</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>13</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>12 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Established</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1969</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              
            </CardBody>
          </Card>          
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon>
                <HomeWorkOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                LinearCRM Customer Structure
              </h4>
            </CardHeader>
            <CardBody>              
            <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Attribute</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4 style={{ fontSize: "21px"}}><b>Value</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Length</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Guardians of the Galaxy</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Phone</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>800-876-5309</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>20 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 1</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 Milano</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 2</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Milky Way</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>455 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business City</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>St. Charles</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business State</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Missouri</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>30 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Country</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>USA</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>60 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Zip Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>6310</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Website</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b><a target="_blank" href="https://www.marvel.com/movies/guardians-of-the-galaxy">https://www.marvel.com/movies/guardians-of-the-galaxy</a></b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>2,000 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Status</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>0 is a lead 1 is a customer.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>1 Digit</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Industry</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Saving The Universe</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>500 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Revenue</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1,000,000.00</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>17 Digits</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>SIC Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1234</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>NAICS Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>112344</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>6 Digits</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div id="People" style={{height : "1px"}}>&nbsp;</div>
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Number of Employees</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>13</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>12 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Established</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1969</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              </CardBody>
          </Card>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon>
                <PermIdentityOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                LinearCRM People Structure
              </h4>
            </CardHeader>
            <CardBody>
            <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Attribute</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4 style={{ fontSize: "21px"}}><b>Value</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4 style={{ fontSize: "21px"}}><b>Length</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>First Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Peter</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>45 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Last Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Quill</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>45 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Email</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>peter@quill.com</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Cell</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>5558765309</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>20 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal Address Line 1</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 Guardians</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal Address Line 2</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>PO Box</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal City</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>St. Charles</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal State</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Missouri</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>30 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal Zip Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>63101</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Personal Country</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>USA</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>60 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Opted in Email</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 yes 0 no.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Opted in Text</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1 yes 0 no</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Opted in Voice</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 yes 0 no.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Contact Business Only</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1 yes 0 no</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Unsubscribe</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1 yes 0 no.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>List Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Marvel. You can segment your lists here by name.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>65 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Status</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>0 is a lead 1 is a customer.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>1 Character</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Last Sale Value</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1500</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>455 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Lifetime Value</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>3000</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>455 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Notes</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>He's a Guardian</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>4,294,967,295 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>First Contact</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>12/25/2023</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Last Contact</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1/1/2024</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Job Title</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Star-Lord</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Email</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>peterquill@marvel.com</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Phone</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>5558765309</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>20 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Extension</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>321</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>15 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Name</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Guarians of the Galexy</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 1</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1 Milano</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>255 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Address Line 2</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Suite 2</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>455 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business City</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>St. Charles</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>155 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Zip Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>63101</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>10 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business State</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Missouri</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>30 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Business Country</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>USA</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>60 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Website</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b><a target="_blank" href="https://www.marvel.com/movies/guardians-of-the-galaxy">https://www.marvel.com/movies/guardians-of-the-galaxy</a></b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>2,000 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Industry</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Superhero</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>500 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Revenue</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>10,000,000</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>17 Digits</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>SIC Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>1234</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>NAICS Code</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>654321</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Number of Employees</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>13</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>12 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Established</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>1969</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>4 Characters</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Quality</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Only to be used during exporting and reimporting into LinearCRM.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>1 Digit</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Type</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Only to be used during exporting and reimporting into LinearCRM.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>1 Digit</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>CRM Customer ID</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Only to be used during exporting and reimporting into LinearCRM.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>Numeric</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Representative ID</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px" }}><h4><b>Only to be used during exporting and reimporting into LinearCRM.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px" }}><h4><b>Numeric</b></h4></div></div>
              <div style={{ clear: "both"}} />
              <div style={{textAlign: "left", width: "100%", margin: "0 auto" }} ><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b style={{ color: "#58bdfd", backgroundColor: "#f4f4f5"}}>Assigned User ID</b></h4></div><div style={{display: "inline-block", float: "left", width: "550px", backgroundColor: "#eeeeee" }}><h4><b>Only to be used during exporting and reimporting into LinearCRM.</b></h4></div><div style={{display: "inline-block", float: "left", width: "300px", backgroundColor: "#eeeeee" }}><h4><b>Numeric</b></h4></div></div>
              <div style={{ clear: "both"}} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default CRMDataStructures;