import React, { useContext } from "react";
import { Context } from "../../redux/store";
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody";

const LastestUpdates = () => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;

  return (
    <>
      <Card>
        <CardBody>
          <h3 style={{fontWeight: "bold"}}>Latest Updates</h3>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>06-04-2024</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Add stronger password requirements.</p>
          <div style={{ clear: "both" }}></div>
          <p>Passwords for the Dashboard must now be at least 12 characters, contain upper and lower case alphabet characters, plus at least 1 symbol.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>05-28-2024</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Added a new Lead Status called System Rated.</p>
          <div style={{ clear: "both" }}></div>
          <p>Enabled call tracking feature to automatically rate calls with a quality as Good, type as Lead, and status as System Rated based on content found in the call.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>07-09-2024</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Added Referrer to Leads, Customers and People in LinearCRM.</p>
          <div style={{ clear: "both" }}></div>
          <p>You may now search for Leads, Customers and People to set as a Referrer.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>07-09-2024</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Added Autocomplete fields for assigning City, State, Zip, Country, SIC Codes and NAICS Codes in LinearCRM.</p>
          <div style={{ clear: "both" }}></div>
          <p>You may now search for City, State, Zip, Country, SIC Codes and NAICS Codes.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>08-13-2024</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Added "Assign To" for customers, leads, people, jobs, estimates, proposals and contracts.</p>
          <div style={{ clear: "both" }}></div>
          <p>Added ability to insert screenshots or images into rich text format fields such as Notes on detail pages.</p>
          <div style={{ clear: "both" }}></div>
          <p>Created Notifications bell in menu for CRM Events.</p>
          <div style={{ clear: "both" }}></div>
          <p>Customers can now see tracking information page under the Tool Bag section.</p>
          <div style={{ clear: "both" }}></div>
          <p>Added ability to select Social Channel and insert url for customer, lead and people on detail pages.</p>
          <div style={{ clear: "both" }}></div>
          <p>We have switched the Tracked Leads to Conversions drop-down to improve user experience and reduce naming friction between Tracked leads in the dashboard and Leads in the CRM.</p>
          <div style={{ clear: "both", height: "20px" }}></div>
        </CardBody>
      </Card>
    </>
  );
};

export default LastestUpdates;
