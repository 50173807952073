import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import AwarenessNoContentWrapper from "components/AwarenessNoContentWrapper";

import Icon from "@mui/material/Icon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";
import AllCampaigns from "./AllCampaigns";
import AdsGroup from "./AdsGroup";
import ViewAds from "./ViewAds";
import ViewVideoAds from "./ViewVideoAds.js";
import AdBreakdown from "./AdBreakdown";
import MyMapComponent from "../Googlemap";
import PaidSearchCampaignAdSchedules from "./PaidSearchCampaignAdSchedules";
import { READ_ME } from "queries/users";
import {
  LIST_BANNERADS_CAMPAIGN_AD_LOCATION,
  LIST_BANNERADS_CAMPAIGN_AD_PROXIMITY
} from "queries/google";
import { Context } from "../../../redux/store";

const useStyles = makeStyles(styles);

const Awareness = ({
  filter,
  type_filter,
  productLine,
  product,
  title,
  campaignsTitle,
  adsGroupTitle,
  adsHeading,
  adsTitle,
  isVideo,
  hideAdsLinks = false,
  hideAdsDetail = false
}) => {
  const [state] = useContext(Context);
  const [campaignMap, setCampaignMap] = React.useState("All Campaigns");
  const [seenegative, setSeeNegative] = React.useState(0);
  const { data: me } = useQuery(READ_ME);
  const dateRangeString = state.customers?.dateRangeString;

  let { loading: Locationloading, data: Locationdata } = useQuery(LIST_BANNERADS_CAMPAIGN_AD_LOCATION, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      negative: seenegative,
      filter: filter
    }
  });

  let { loading: Proximityloading, data: Proximitydata } = useQuery(LIST_BANNERADS_CAMPAIGN_AD_PROXIMITY, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      filter: filter
    }
  });

  Locationdata = Locationdata?.listBannerAdsCampaignAdLocation || [];
  Proximitydata = Proximitydata?.listBannerAdsCampaignAdProximity || [];

  const uniqueCampaigns = [];
  Proximitydata.forEach((item) => {
    var findItem = uniqueCampaigns.find((x) => x.campaign_name === item.campaign_name);
    if (!findItem) uniqueCampaigns.push(item);
  });

  const handleSeeNegative = (event) => {
    setSeeNegative(parseInt(event));
  };

  const masterRole = me?.readMe?.user_level === "master" ||
    me?.readMe?.user_level === "campaign_consultant" ||
    me?.readMe?.user_level === "content_manager" ||
    me?.readMe?.user_level === "paid_ad_manager";

  const classes = useStyles();

  let locationcolumns = ""

  locationcolumns = [
    {
      Header: "Campaign Name",
      accessor: "campaign_name"
    },
    {
      Header: "Status",
      accessor: "campaign_status"
    },
    {
      Header: "Target Level",
      accessor: "google_location_lookup.target_type",
      Cell: ({ original }) => (
        <>
          {original?.google_campaigns_targets?.target_type.replaceAll(",", ", ") ||
            original?.google_location_lookup?.target_type.replaceAll(",", ", ")}
        </>
      )
    },
    {
      Header: "Target Location",
      accessor: "google_location_lookup.canonical_name",
      Cell: ({ original }) => (
        <>
          {original?.google_campaigns_targets?.canonical_name.replaceAll(",", ", ") ||
            original?.google_location_lookup?.canonical_name.replaceAll(",", ", ")}
        </>
      )
    }
  ];

  return (
    <>
      {me?.readMe?.user_level !== "master" && me?.readMe?.user_level !== "campaign_consultant" && me?.readMe?.user_level !== "ad_consultant" && me?.readMe?.user_level !== "paid_ad_manager" && me?.readMe?.user_level !== "content_manager" ?
        <AwarenessNoContentWrapper
          product={product === "banner_ads" ? "BannerAds" : "OnlineVideo"}
          title={campaignsTitle}
          filter={filter}
          type_filter={type_filter}
          productLine={productLine}
        >
          <div>
            <h2 className={classes.pageHeader}>{campaignsTitle}</h2>
            <AllCampaigns
              filter={filter}
              type_filter={type_filter}
              productLine={productLine}
              isVideo={isVideo}
            />
            <br />
            {hideAdsDetail && masterRole ?
              <>
                <PaidSearchCampaignAdSchedules
                  filter="Awareness"
                  type_filter="DISPLAY"
                  title="Banner Ads GeoDemo®"
                  heading="Banner Ads GeoDemo® Campaign Ad Schedules"
                />
                <PaidSearchCampaignAdSchedules
                  filter="GeoIntention"
                  type_filter="DISPLAY"
                  title="Banner Ads GeoIntention®"
                  heading="Banner Ads GeoIntention® Campaign Ad Schedules"
                />
                <PaidSearchCampaignAdSchedules
                  filter="ReEngage"
                  type_filter="DISPLAY"
                  title="Banner Ads ReEngage®"
                  heading="Banner Ads ReEngage® Campaign Ad Schedules"
                />
              </>
              : ""}
            {hideAdsDetail ?
              <GridContainer>
                <GridItem xs={12}>
                  <div>
                    {Proximitydata.length > 0 && (
                      <div>
                        <h3><b>Radius Targeting</b></h3>
                        <CardBody>
                          <select
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            onChange={event => { setCampaignMap(event.target.value) }}
                            value={campaignMap}
                          >
                            <option value="All Campaigns">All Campaigns</option>
                            {uniqueCampaigns?.map((CurrentCampaign) => {
                              return (
                                <option value={CurrentCampaign?.campaign_name}>{CurrentCampaign?.campaign_name}</option>
                              )
                            })}
                          </select>
                          <MyMapComponent
                            Proximitydata={Proximitydata}
                            campaignMap={campaignMap}
                          />
                        </CardBody>
                      </div>
                    )}
                    <select
                      style={{ marginTop: "20px" }}
                      id="see_negative"
                      onChange={event => { handleSeeNegative(event.target.value) }}
                      value={seenegative}
                    >
                      <option value="0">Targeted Locations</option>
                      <option value="1">Negative Locations</option>
                    </select>
                    <Card>
                      <CardHeader icon>
                        <CardIcon>
                          <Icon>list</Icon>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                          {product === "banner_ads" ? "BannerAds" : "OnlineVideo"} Location Targeting
                          <CSVDownloader
                            data={Locationdata}
                            columns={locationcolumns}
                            filename={`PaidSearch Location Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                          />
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <ReactTable
                          key={Locationdata?.length}
                          data={Locationdata}
                          filterable
                          loading={Locationloading}
                          columns={locationcolumns}
                          defaultPageSize={Math.min(10, Locationdata?.length || 2)}
                          showPaginationTop
                          showPaginationBottom={false}
                          className="-striped -highlight"
                        />
                      </CardBody>
                    </Card>
                  </div>
                </GridItem>
              </GridContainer> : ""}
            {hideAdsDetail && Proximitydata.length !== 0 ?
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader icon>
                      <CardIcon>
                        <Icon>list</Icon>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        Banner Ads Radius Targeting
                        <CSVDownloader
                          data={Proximitydata}
                          columns={locationcolumns}
                          filename={`PaidSearch Radius Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                        />
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        key={Proximitydata?.length}
                        data={Proximitydata}
                        filterable
                        loading={Proximityloading}
                        columns={locationcolumns}
                        defaultPageSize={Math.min(10, Proximitydata?.length || 2)}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer> : ""}
            <br />
            {!hideAdsDetail && (
              <>
                <h2 className={classes.pageHeader}>Tracked {title} Details</h2>
                <AdsGroup
                  filter={filter}
                  type_filter={type_filter}
                  title={adsGroupTitle}
                  isVideo={isVideo}
                />
              </>
            )}
            {!hideAdsLinks && (
              <>
                <br />
                {isVideo ? (
                  <>
                    <h2 className={classes.pageHeader}>Ad Breakdown</h2>
                    <AdBreakdown filter={filter} title={title} />
                    <h2 className={classes.pageHeader}>
                      {adsHeading ||
                        `Links to ${title} ${isVideo ? " Assets" : ""}`}
                    </h2>
                    <ViewVideoAds
                      filter={filter}
                      type_filter={type_filter}
                      title={adsTitle}
                    />
                  </>
                ) : (
                  <>
                    <h2 className={classes.pageHeader}>
                      {adsHeading ||
                        `Links to ${title} ${isVideo ? " Assets" : ""}`}
                    </h2>
                    <ViewAds
                      filter={filter}
                      type_filter={type_filter}
                      title={adsTitle}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </AwarenessNoContentWrapper>
        :
        <div>
          <h2 className={classes.pageHeader}>{campaignsTitle}</h2>
          <AllCampaigns
            filter={filter}
            type_filter={type_filter}
            productLine={productLine}
            isVideo={isVideo}
          />
          <br />
          {hideAdsDetail && masterRole ?
            <>
              <PaidSearchCampaignAdSchedules
                filter="Awareness"
                type_filter="DISPLAY"
                title="Banner Ads GeoDemo®"
                heading="Banner Ads GeoDemo® Campaign Ad Schedules"
              />
              <PaidSearchCampaignAdSchedules
                filter="GeoIntention"
                type_filter="DISPLAY"
                title="Banner Ads GeoIntention®"
                heading="Banner Ads GeoIntention® Campaign Ad Schedules"
              />
              <PaidSearchCampaignAdSchedules
                filter="ReEngage"
                type_filter="DISPLAY"
                title="Banner Ads ReEngage®"
                heading="Banner Ads ReEngage® Campaign Ad Schedules"
              />
            </>
            : ""}
          {hideAdsDetail ?
            <GridContainer>
              <GridItem xs={12}>
                <div>
                  {Proximitydata.length > 0 && (
                    <div>
                      <h3><b>Radius Targeting</b></h3>
                      <CardBody>
                        <select
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                          onChange={event => { setCampaignMap(event.target.value) }}
                          value={campaignMap}
                        >
                          <option value="All Campaigns">All Campaigns</option>
                          {uniqueCampaigns?.map((CurrentCampaign) => {
                            return (
                              <option value={CurrentCampaign?.campaign_name}>{CurrentCampaign?.campaign_name}</option>
                            )
                          })}
                        </select>
                        <MyMapComponent
                          isMarkerShown
                          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBxhBrA71Kx4JR2A68eS0uOpGDiOu-8rYc"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px` }} />}
                          mapElement={<div style={{ height: `100%` }} />}
                          Proximitydata={Proximitydata}
                          campaignMap={campaignMap}
                        />
                      </CardBody>
                    </div>
                  )}
                  <select
                    style={{ marginTop: "20px" }}
                    id="see_negative"
                    onChange={event => { handleSeeNegative(event.target.value) }}
                    value={seenegative}
                  >
                    <option value="0">Targeted Locations</option>
                    <option value="1">Negative Locations</option>
                  </select>
                  <Card>
                    <CardHeader icon>
                      <CardIcon>
                        <Icon>list</Icon>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {product === "banner_ads" ? "BannerAds" : "OnlineVideo"} Location Targeting
                        <CSVDownloader
                          data={Locationdata}
                          columns={locationcolumns}
                          filename={`PaidSearch Location Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                        />
                      </h4>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        key={Locationdata?.length}
                        data={Locationdata}
                        filterable
                        loading={Locationloading}
                        columns={locationcolumns}
                        defaultPageSize={Math.min(10, Locationdata?.length || 2)}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                </div>
              </GridItem>
            </GridContainer> : ""}
          {hideAdsDetail && Proximitydata.length !== 0 ?
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader icon>
                    <CardIcon>
                      <Icon>list</Icon>
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>
                      Banner Ads Radius Targeting
                      <CSVDownloader
                        data={Proximitydata}
                        columns={locationcolumns}
                        filename={`PaidSearch Radius Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                      />
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      key={Proximitydata?.length}
                      data={Proximitydata}
                      filterable
                      loading={Proximityloading}
                      columns={locationcolumns}
                      defaultPageSize={Math.min(10, Proximitydata?.length || 2)}
                      showPaginationTop
                      showPaginationBottom={false}
                      className="-striped -highlight"
                      CustomStyle={true}
                    />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer> : ""}
          <br />
          {!hideAdsDetail && (
            <>
              <h2 className={classes.pageHeader}>Tracked {title} Details</h2>
              <AdsGroup
                filter={filter}
                type_filter={type_filter}
                title={adsGroupTitle}
                isVideo={isVideo}
              />
            </>
          )}
          {!hideAdsLinks && (
            <>
              <br />
              {isVideo ? (
                <>
                  <h2 className={classes.pageHeader}>Ad Breakdown</h2>
                  <AdBreakdown filter={filter} title={title} />
                  <h2 className={classes.pageHeader}>
                    {adsHeading ||
                      `Links to ${title} ${isVideo ? " Assets" : ""}`}
                  </h2>
                  <ViewVideoAds
                    filter={filter}
                    type_filter={type_filter}
                    title={adsTitle}
                  />
                </>
              ) : (
                <>
                  <h2 className={classes.pageHeader}>
                    {adsHeading ||
                      `Links to ${title} ${isVideo ? " Assets" : ""}`}
                  </h2>
                  <ViewAds
                    filter={filter}
                    type_filter={type_filter}
                    title={adsTitle}
                  />
                </>
              )}
            </>
          )}
        </div>}
    </>
  );
};

Awareness.propTypes = {
  filter: PropTypes.string,
  type_filter: PropTypes.string,
  title: PropTypes.string,
  campaignsTitle: PropTypes.string,
  adsGroupTitle: PropTypes.string,
  adsHeading: PropTypes.string,
  adsTitle: PropTypes.string,
  isVideo: PropTypes.bool,
  hideAdsLinks: PropTypes.bool,
  hideAdsDetail: PropTypes.bool,
  productLine: PropTypes.string
};

export default Awareness;
