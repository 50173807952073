import React, { useState } from "react";

import Dialog from '@mui/material/Dialog';
import { makeStyles } from "@mui/styles";

import CustomInput from "components/CustomInput/CustomInput";
import Dropzone from "components/Dropzone";
import pageStyle from "assets/jss/material-dashboard-pro-react/views/marketingGoalsStyle";

const styles = theme => ({
  ...pageStyle(theme),
  loading: {
    opacity: 0.5,
    pointerEvents: "none"
  },
  heading: {
    fontSize: 18
  },
  maxLength: {
    fontSize: 13,
    marginLeft: 10
  },
  sublabel: {
    marginLeft: 10,
    fontSize: 13
  },
  headingArea: {
    display: "inline-block"
  }
});

const useStyles = makeStyles(styles);

const UploadInput = ({
  label,
  sublabel,
  required,
  maxLength,
  path,
  onChange,
  value,
  readOnly,
  accept = "*",
  maxSize,
  sizeHelper,
  setuploadPercentage,
  style
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const classes = useStyles();
  const headingArea = (
    <div className={classes.heading}>
      {label}
      {required && "*"}
      {maxLength && (
        <span className={classes.maxLength}>
          ({value?.length || 0} / {maxLength})
        </span>
      )}
    </div>
  );
  const onView = e => {
    if (value) {
      e.stopPropagation();
    } else {
      e.preventDefault();
    }
  };
  const input = (
    <CustomInput
      formControlProps={{
        fullWidth: true,
        required: true
      }}
      inputProps={{
        type: "string",
        maxLength,
        readOnly: true,
        value: value || ""
      }}
    />
  );

  return (
    <div>
      <Dropzone
        setuploadPercentage={setuploadPercentage}
        path={path}
        onChange={onChange}
        accept={accept}
        maxSize={maxSize}
        sizeHelper={sizeHelper}
      >
        {({ loading }) => (
          <div className={loading ? classes.loading : ""}>
            <a
              href={value || "/#"}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onView}
              className={classes.headingArea}
            >
              {headingArea}
            </a>
            {sublabel && <span className={classes.sublabel}>{sublabel}</span>}
            {readOnly ? (
              <a
                href={value || "/#"}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onView}
              >
                {input}
              </a>
            ) : input}
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default UploadInput;
