import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from "components/CustomButtons/Button";
import { renderInputGroup } from "utils/renders";

const UpdateDialog = ({
  handleClose,
  current,
  updated,
  inputs,
  title = "Customer",
  maxWidth = "sm"
}) => {
  const [data, setData] = useState(updated);
  const updateFieldHandler = (key, convert) => e => {
    setData({
      ...data,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };
  return (
    <Dialog
      open
      onClose={handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {data?.id ? `Update ${title}` : `Add ${title}`}
      </DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleClose(data)();
        }}
      >
        <DialogContent>
          {inputs.map(renderInputGroup(current, data, updateFieldHandler))}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            type="submit"
            disabled={inputs.some(
              ({ field, defaultValue, optional }) =>
                !(data[field] || defaultValue) && !optional
            )}
          >
            Submit
          </Button>
          <Button onClick={handleClose()}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UpdateDialog;
