import gql from "graphql-tag";

export const LIST_HYDRATE_TEXT_REPORT = gql`
  query listHydrateTextReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateTextReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date
      message_parts
      first_name
      last_name
      from
      to
      body
      list_name
      message_price
      status
      status_text
      error_text
    }
  }
`;

export const LIST_HYDRATE_MMS_REPORT = gql`
  query listHydrateMMSReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateMMSReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date
      message_parts
      first_name
      last_name
      from
      to
      body
      list_name
      message_price
      status
      media_file_url
      error_text
    }
  }
`;

export const LIST_HYDRATE_VOICE_REPORT = gql`
  query listHydrateVoiceReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateVoiceReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date_created
      from_formatted
      to_formatted
      status
      direction
      duration
      message_price
      annotation
      price_unit
    }
  }
`;

export const LIST_HYDRATE_EMAIL_REPORT = gql`
  query listHydrateEmailReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateEmailReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      customer_id
      clicks
      opens
      bounces
      delivered
      unsubscribes
      date
    }
  }
`;

export const LIST_HYDRATE_EMAIL_DETAIL_REPORT = gql`
  query listHydrateEmailDetailsReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateEmailDetailsReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      from_email
      to_email
      subject
      status
      opens_count
      clicks_count
      last_event_time
      date
    }
  }
`;

export const LIST_HYDRATE_LETTER_REPORT = gql`
  query listHydrateLetterReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydrateLetterReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date
      address_name
      address_line_1
      address_line_2
      address_city
      address_state
      address_postal_code
      address_country
      post_pages
      source
      colour
      duplex
      post_price
      status
      status_text
      list_name
    }
  }
`;

export const LIST_HYDRATE_POSTCARD_REPORT = gql`
  query listHydratePostcardReport(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
  ) {
    listHydratePostcardReport(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    ) {
      date
      address_name
      address_line_1
      address_line_2
      address_city
      address_state
      address_postal_code
      address_country
      letter_file_name
      _file_url
      source
      post_price
      priority
      status
      list_name
    }
  }
`;

export const GET_HYDRATE_TEXT = gql`
  query getHydrateText($customer_id: Int!, $list_name: Int!, $message_number: Int!, $get_template: Int) {
    getHydrateText(customer_id: $customer_id, list_name: $list_name, message_number: $message_number, get_template: $get_template) {
      sms_message_1
      sms_message_2
      sms_message_3
      sms_message_4
      sms_message_5
      sms_message_6
      sms_message_7
      sms_message_8
    }
  }
`;

export const UPDATE_HYDRATE_TEXT = gql`
  mutation updateHydrateText(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydrateTextInput
    $get_template: Int
  ) {
    updateHydrateText(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
      get_template: $get_template
    ) {
      sms_message_1
      sms_message_2
      sms_message_3
      sms_message_4
      sms_message_5
      sms_message_6
      sms_message_7
      sms_message_8
    }
  }
`;

export const GET_HYDRATE_MMS = gql`
  query getHydrateMMS($customer_id: Int!, $list_name: Int!, $message_number: Int!, $get_template: Int) {
    getHydrateMMS(customer_id: $customer_id, list_name: $list_name, message_number: $message_number, get_template: $get_template) {
      body
      media_file
    }
  }
`;

export const UPDATE_HYDRATE_MMS = gql`
  mutation updateHydrateMMS(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydrateMMSInput
    $get_template: Int
  ) {
    updateHydrateMMS(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
      get_template: $get_template
    ) {
      subject
      body
      media_file
    }
  }
`;

export const GET_HYDRATE_VOICE_MESSAGE = gql`
  query getHydrateVoiceMessage($customer_id: Int!, $list_name: Int!, $message_number: Int!) {
    getHydrateVoiceMessage(customer_id: $customer_id, list_name: $list_name, message_number: $message_number) {
      voice_message_url
    }
  }
`;

export const GET_LEAD_STATUS = gql`
  query getHydrateLeadStatus($customer_id: Int!, $skip_message_to_all: Boolean!) {
    getHydrateLeadStatus(customer_id: $customer_id, skip_message_to_all: $skip_message_to_all) {
      id
      lead_status
      lead_status_text
      lead_type
    }
  }
`;

export const GET_MESSAGE_NAMES = gql`
  query getHydrateMessageNames($customer_id: Int!, $lead_status: Int!, $message_status: Int) {
    getHydrateMessageNames(customer_id: $customer_id, lead_status: $lead_status, message_status: $message_status) {
      message_id
      message_status
      message_name
    }
  }
`;

export const GET_LEAD_TYPE = gql`
  query getHydrateLeadType($customer_id: Int!) {
    getHydrateLeadType(customer_id: $customer_id) {
      id
      lead_type
      lead_type_text
    }
  }
`;

export const ADD_LEAD_TYPE = gql`
mutation addLeadTypeHydrate(
  $customer_id: Int!
  $data: HydrateAddLeadTypeInput!
) {
  addLeadTypeHydrate(
    customer_id: $customer_id
    data: $data
  )
}
`;

export const ADD_LEAD_STATUS = gql`
  mutation addLeadStatusHydrate(
    $customer_id: Int!
    $data: HydrateAddLeadStatusInput!
  ) {
    addLeadStatusHydrate(
      customer_id: $customer_id
      data: $data
    )
  }
`;

export const DELETE_LEAD_STATUS = gql`
  mutation deleteLeadStatusHydrate(
    $customer_id: Int!
    $data: HydrateDeleteLeadStatusInput!
  ) {
    deleteLeadStatusHydrate(
      customer_id: $customer_id
      data: $data
    )
  }
`;

export const EDIT_MESSAGE_NAMES = gql`
  mutation editMessageNamesHydrate(
    $customer_id: Int!
    $data: HydrateEditMessageNameInput!
  ) {
    editMessageNamesHydrate(
      customer_id: $customer_id
      data: $data
    )
  }
`;

export const UPDATE_HYDRATE_VOICE_MESSAGE = gql`
  mutation updateHydrateVoiceMessage(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydrateVoiceMessageInput
  ) {
    updateHydrateVoiceMessage(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
    ) {
      voice_message_url
    }
  }
`;

export const GET_HYDRATE_EMAIL = gql`
  query getHydrateEmail($customer_id: Int!, $list_name: Int!, $message_number: Int!, $get_template: Int) {
    getHydrateEmail(customer_id: $customer_id, list_name: $list_name, message_number: $message_number, get_template: $get_template) {
      subject
      body
    }
  }
`;

export const UPDATE_HYDRATE_EMAIL = gql`
  mutation updateHydrateEmail(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydrateEmailInput
    $get_template: Int
  ) {
    updateHydrateEmail(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
      get_template: $get_template
    ) {
      subject
      body
    }
  }
`;

export const GET_HYDRATE_LETTER = gql`
  query getHydrateLetter($customer_id: Int!, $list_name: Int!, $message_number: Int!) {
    getHydrateLetter(customer_id: $customer_id, list_name: $list_name, message_number: $message_number) {
      priority_post
      letter_two_sided
      letter_color
      letter_file_url
    }
  }
`;

export const UPDATE_HYDRATE_LETTER = gql`
  mutation updateHydrateLetter(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydrateLetterInput
  ) {
    updateHydrateLetter(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
    ) {
      priority_post
      letter_two_sided
      letter_color
      letter_file_url
    }
  }
`;

export const GET_HYDRATE_POSTCARD = gql`
  query getHydratePostcard($customer_id: Int!, $list_name: Int!, $message_number: Int!) {
    getHydratePostcard(customer_id: $customer_id, list_name: $list_name, message_number: $message_number) {
      file_url
    }
  }
`;

export const UPDATE_HYDRATE_POSTCARD = gql`
  mutation updateHydratePostcard(
    $customer_id: Int!
    $list_name: Int!
    $message_number: Int!
    $data: HydratePostcardInput
  ) {
    updateHydratePostcard(
      customer_id: $customer_id
      list_name: $list_name
      message_number: $message_number
      data: $data
    ) {
      file_url
    }
  }
`;

export const GET_HYDRATE_CAMPAIGN = gql`
  query getHydrateCampaign($customer_id: Int!, $list_name: Int!) {
    getHydrateCampaign(customer_id: $customer_id, list_name: $list_name) {
      id
      customer_id
      list_name
      sms_text
      mms_text
      email
      postcard
      letter
      voice_message
      sms_text_day
      mms_text_day
      email_day
      letter_day
      postcard_day
      voice_message_day
      frequency_template
    }
  }
`;

export const UPDATE_HYDRATE_CAMPAIGN = gql`
  mutation updateHydrateCampaign($data: HydrateCampaignInput) {
    updateHydrateCampaign(data: $data) {
      id
      customer_id
      list_name
      sms_text
      mms_text
      email
      postcard
      letter
      voice_message
      sms_text_day
      mms_text_day
      email_day
      letter_day
      postcard_day
      voice_message_day
      frequency_template
    }
  }
`;
