import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";


import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";

// import Icon from "@mui/material/Icon";

// @mui/icons-material
import InputLabel from "@mui/material/InputLabel";
import MailOutline from "@mui/icons-material/MailOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// core components
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
// import TextareaAutosize from "@mui/material/TextareaAutosize";
import { SUBMIT_FORM } from "queries/formSubmission";
import withSubmissions from "hocs/withSubmissions";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

const useStyles = makeStyles(styles);

const SubmitForm = ({ toEmail, formSubmitted }) => {
  const classes = useStyles();
  const [submissionData, setSubmissionData] = useState({});

  const [submitForm, { loading: submitting }] = useMutation(SUBMIT_FORM, {
    variables: {
      data: {
        ...submissionData,
        toEmail,
        additionalInfo: submissionData.additionalInfo?.split("\n").join("<br>")
      }
    },
    onCompleted: () => {
      setSubmissionData({});
      formSubmitted();
    }
  });

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  return (
    <GridContainer loading={submitting}>
      <Card>
        <CardHeader color="myTeam" icon>
          <CardIcon>
            <MailOutline />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={e => {
              e.preventDefault();
              submitForm();
            }}
          >
            <CustomInput
              name="first_last_name"
              labelText="First and Last Name"
              id="first_last_name"
              formControlProps={{
                fullWidth: true,
                required: true
              }}
              inputProps={{
                type: "string",
                value: submissionData?.fullName || "",
                onChange: updateFieldHandler("fullName")
              }}
            />
            <CustomInput
              labelText="Phone Number"
              id="phone_number"
              formControlProps={{
                fullWidth: true,
                required: true
              }}
              inputProps={{
                type: "phone_number",
                value: submissionData?.phone || "",
                onChange: updateFieldHandler("phone")
              }}
            />
            <CustomInput
              labelText="Email"
              id="email"
              formControlProps={{
                fullWidth: true,
                required: true
              }}
              inputProps={{
                type: "email",
                value: submissionData?.email || "",
                onChange: updateFieldHandler("email")
              }}
            />
            <FormControl fullWidth className={classes.formControl}>
              <label>
                What&apos;s the best way to contact you?{" "}
              </label>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={submissionData?.contactMethod}
                onChange={updateFieldHandler("contactMethod")}
                inputProps={{
                  name: "chrisContact",
                  id: "chris-contact"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  What&apos;s the best way to contact you?{" "}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Phone"
                >
                  Phone
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Email"
                >
                  Email
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Text"
                >
                  Text
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            <FormControl fullWidth className={classes.selectFormControl}>
              <label>
                What do you need help with?{" "}
              </label>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.chrisHelp
                }}
                value={submissionData?.helpRequired}
                onChange={updateFieldHandler("helpRequired")}
                inputProps={{
                  name: "chrisHelp",
                  id: "chris-help"
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  What do you need help with?{" "}
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Increasing Ad Budget"
                >
                  Increasing Ad Budget
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Adding a New Product"
                >
                  Adding a New Product
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Billing Question/Updating Payment Method"
                >
                  Billing Question/Updating Payment Method
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Tracking Question"
                >
                  Tracking Question
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Referring a Friend to us"
                >
                  Referring a Friend to us
                </MenuItem>
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected
                  }}
                  value="Other"
                >
                  Other{" "}
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <CustomInput
              labelText="Please give additional information"
              id="contact-area"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                multiline: true,
                rows: 5,
                value: submissionData?.additionalInfo || "",
                onChange: updateFieldHandler("additionalInfo")
              }}
            />
            <Button color="orange" type="submit">
              Submit
            </Button>
          </form>
        </CardBody>
      </Card>
    </GridContainer>
  );
};

SubmitForm.propTypes = {
  toEmail: PropTypes.string
};

export default withSubmissions(SubmitForm);
