import React, { useContext, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { API_URL } from "config";
import { pick } from "lodash";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";

import { UPDATE_CUSTOMER } from "queries/customers";
import { UPDATE_CUSTOMER_SELECT } from "../../../redux/constants";
import { Context } from "../../../redux/store";

import styles from "assets/jss/material-dashboard-pro-react/views/myTeamStyle";

import Trackers from "./Trackers";

const useStyles = makeStyles(styles);

let timeout = null;

const TrackingCodes = () => {
  const [state, dispatch] = useContext(Context);
  const [accountInfo, setAccountInfo] = useState({});
  const selectedCustomer = state.customers?.selected;
  const [refresh, setRefresh] = useState(0);
  const [clipboardCopied, setClipboardCopied] = useState();
  const classes = useStyles();
  // const user_level = state?.auth?.tokenInfo?.user?.user_level;

  const updateFieldHandler = (key, convert) => e => {
    setAccountInfo({
      ...accountInfo,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const [updateCustomer, { loading: updatingCustomer, error }] = useMutation(
    UPDATE_CUSTOMER,
    {
      variables: {
        id: selectedCustomer?.id,
        data: {
          ...accountInfo,
          id: undefined,
          __typename: undefined
        }
      },
      onCompleted: () => {
        dispatch({
          type: UPDATE_CUSTOMER_SELECT,
          payload: {
            ...selectedCustomer,
            ...accountInfo
          }
        });
      },
      
    }
  );

  useEffect(() => {
    setAccountInfo(
      pick(selectedCustomer, ["tag_manger_head_code", "tag_manger_body_code"])
    );
    setRefresh(r => r + 1);
  }, [selectedCustomer]);

  const copyCode = id => {
    var copyText = document.getElementById(id);

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/

    /* Copy the text inside the text field */
    document.execCommand("copy");

    setClipboardCopied(id);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setClipboardCopied("");
    }, 1500);
  };

  const copyTagManagerHeadCode = () => copyCode("tag_manger_head_code");
  const copyTagManagerBodyCode = () => copyCode("tag_manger_body_code");

  const loading =
  //  updatingCustomer ||
   !selectedCustomer?.id;
  /* const readOnly =
    ["Customer", "PARA Consultant", "PARA"].indexOf(user_level) !== -1; */
  const readOnly = true;

  return (
    <div className={classes.container}>
      <h2 className={classes.pageHeader}>WordPress Tracking Plugin V 1.8</h2>
      <a href={`${API_URL}/tracking-plugin`}>
        <Button color="primary" type="button">
          Download Now
        </Button>
      </a>
      <h2 className={classes.pageHeader}>Tracking Numbers</h2>
      <Trackers />
      <h2 className={classes.pageHeader}>Tracking Codes</h2>
      <form
        onSubmit={e => {
          e.preventDefault();
          updateCustomer();
        }}
      >
        <GridContainer justify="flex-end" loading={loading} key={refresh}>
          <GridItem xs={12} md={6}>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <h4>Install this tracking code in the global header code</h4>
                <CustomInput
                  labelText="Tag Manager Head Code"
                  id="tag_manger_head_code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 10,
                    value: accountInfo?.tag_manger_head_code || "",
                    onClick: copyTagManagerHeadCode,
                    onChange: updateFieldHandler("tag_manger_head_code"),
                    readOnly
                  }}
                />
              </CardBody>
            </Card>
            {readOnly && (
              <>
                <Button
                  color="orange"
                  type="button"
                  onClick={copyTagManagerHeadCode}
                >
                  Copy
                </Button>
                {clipboardCopied === "tag_manger_head_code" && (
                  <div>Copied To Clipboard</div>
                )}
              </>
            )}
          </GridItem>
          <GridItem xs={12} md={6}>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <h4>Install this code manually in the global body code</h4>
                <CustomInput
                  labelText="Tag Manager Body Code"
                  id="tag_manger_body_code"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 10,
                    value: accountInfo?.tag_manger_body_code || "",
                    onClick: copyTagManagerBodyCode,
                    onChange: updateFieldHandler("tag_manger_body_code"),
                    readOnly
                  }}
                />
              </CardBody>
            </Card>
            {readOnly && (
              <>
                <Button
                  color="orange"
                  type="button"
                  onClick={copyTagManagerBodyCode}
                >
                  Copy
                </Button>
                {clipboardCopied === "tag_manger_body_code" && (
                  <div>Copied To Clipboard</div>
                )}
              </>
            )}
          </GridItem>
          {!readOnly && (
            <GridItem>
              <Button color="primary" type="submit">
                Submit
              </Button>
            </GridItem>
          )}
        </GridContainer>
      </form>
    </div>
  );
};

export default TrackingCodes;
