import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  orangeCardHeader,
  roseCardHeader,
  googleCardHeader,
  bingCardHeader,
  leadsCardHeader,
  bannerCardHeader,
  emailCardHeader,
  businessListingCardHeader,
  postOfficeCardHeader,
  myTeamCardHeader,
  marketingGoalsCardHeader,
  trackedKeywordsCardHeader,
  wbmBlueCardHeader,
  greyCardHeader,
  grayCardHeader,
  wbmMoneyCardHeader,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";
const cardIconStyle = {
  cardIcon: {
    borderRadius: "3px",
    backgroundColor: grayColor[0],
    padding: "15px",
    marginTop: "-20px",
    marginRight: "15px",
    float: "left"
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  orangeCardHeader,
  roseCardHeader,
  googleCardHeader,
  bingCardHeader,
  leadsCardHeader,
  bannerCardHeader,
  emailCardHeader,
  businessListingCardHeader,
  postOfficeCardHeader,
  myTeamCardHeader,
  marketingGoalsCardHeader,
  trackedKeywordsCardHeader,
  wbmBlueCardHeader,
  greyCardHeader,
  grayCardHeader,
  redCardHeader: dangerCardHeader,
  wbmMoneyCardHeader
};

export default cardIconStyle;
