import gql from "graphql-tag";

export const GET_HEAT_MAP_DATA = gql`
  query getHeatMapData($customer_id: Int!
    $start_date: String
    $end_date: String) {
    getHeatMapData(customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date) {
      page_list_title
      page_list_display_url
      page_list_views
      page_list_visit_time
      page_list_clicks
      page_list_scroll
      page_list_render_time
      customer_id
      website_id
      page_list_uri
      token
      date
    }
  }
`;