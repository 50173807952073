import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';

const EstimateList = (row, index, CRMEstimate, object_key, CRMRepresentative, isEstimateOpenCollapse, customer_id) => {
  return CRMEstimate?.filter((CurrentEstimate) => CurrentEstimate[object_key] === row?.id || CurrentEstimate?.id === row?.estimate_id || (object_key === "ticket_id" && CurrentEstimate?.id === row?.estimate_id)).map(CurrentEstimate => {
    return (
      <>
        {isEstimateOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isEstimateOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Estimate Name</b><br /> <a href={"/admin/crmestimatedetail?customer=" + customer_id + "&id=" + CurrentEstimate?.crm_customers_id + "&peopleid=" + CurrentEstimate?.people_id + "&estimateid=" + CurrentEstimate?.id}>{CurrentEstimate?.estimate_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Estimate Status</b><br /> {CurrentEstimate?.estimate_status}</div>
                </div>
              </Collapse>
            </TableCell >
          </TableRow >
        )}
      </>
    )
  })
};

export default EstimateList;
