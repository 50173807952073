import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_BIRDEYE_REVIEWS_TRACKING } from "queries/birdeye";
import { Context } from "../../../redux/store";

const ReviewsTracking = () => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;
  const { loading, data } = useQuery(GET_BIRDEYE_REVIEWS_TRACKING, {
    skip: !customer_id,
    variables: {
      customer_id
    }
  });
  const summary = data?.getBirdeyeReviewsTracking;

  return (
    <GridContainer>
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Lifetime Total Reviews"
        value={summary?.AllTimeReviewCountCount || 0}
        render="integer"
      />
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Lifetime Rating Average"
        value={summary?.AllTimeAvgRatingRating || 0}
        footer={`Last 30 Days: ${summary?.Last30DaysAvgRatingRating || 0}`}
        render="integer"
      />
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Lifetime Good Reviews"
        value={summary?.AllTimeSentimentsPositive || 0}
        footer={`Last 30 Days: ${summary?.Last30DaysSentimentsPositive || 0}`}
        render="integer"
      />
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Lifetime Neutral Reviews"
        value={summary?.AllTimeSentimentsNeutral || 0}
        footer={`Last 30 Days: ${summary?.Last30DaysSentimentsNeutral || 0}`}
        render="integer"
      />
      <StatsCard
        icon="dynamic_feed"
        loading={loading}
        label="Lifetime Bad Reviews"
        value={summary?.AllTimeSentimentsNegative || 0}
        footer={`Last 30 Days: ${summary?.Last30DaysSentimentsNegative || 0}`}
        render="integer"
      />
      <StatsCard
        icon="email"
        loading={loading}
        label="Lifetime Emails Sent"
        value={summary?.EmailSentInfoTotal || 0}
        render="integer"
      />
      <StatsCard
        icon="phone"
        loading={loading}
        label="Lifetime Texts Sent"
        value={summary?.SmsDetailMsgSent || 0}
        render="integer"
      />
    </GridContainer>
  );
};

export default ReviewsTracking;
