import { combineReducers } from "redux";

import ui from "./reducers/ui";
import auth from "./reducers/auth";
import customers from "./reducers/customers";
import users from "./reducers/users";

export default combineReducers({
  ui,
  auth,
  customers,
  users
});
