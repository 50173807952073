import React, { useContext } from "react";
import { Context } from "../../redux/store";
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody";

const CRMTableofContents = () => {
  const [state] = useContext(Context);
  const customer_id = state.customers?.selected?.id;

  return (
    <>
      <Card>
        <CardBody>
          <h3 style={{fontWeight: "bold"}}>LinearCRM Table of Contents</h3>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>CRM B2B/B2C Setting</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>B2B enables people to be assigned to leads and or customers. This is the ideal setting when your customer is an organization and can have multiple people at the organization.</p>
          <div style={{ clear: "both" }}></div>
          <p>B2C keeps it simple - people are your customers and you don't often have organizations as your customers.</p>
          <div style={{ clear: "both", height: "20px" }}></div>
          
          <h4 style={{fontWeight: "bold"}}>Users</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>A user is someone who has access to LinearCRM. They can create, view, or delete anything in LinearCRM based on their user permissions. <a href={"/admin/crm_settings?customer=" + customer_id}>LinearCRM Settings</a>.</p>
          <div style={{ clear: "both", height: "20px" }}></div>
          
          <h4 style={{fontWeight: "bold"}}>People</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>With B2B settings People are someone within an Lead or Customer.</p>
          <div style={{ clear: "both" }}></div>
          <p>If you are using B2C Settings, People can act as customers or leads.</p>
          <div style={{ clear: "both" }}></div>
          <p>When using Water Bear Marketing's advertising solutions, new tracked conversions are automatically populated within LinearCRM's people section. If you are using the B2B settings, you can create or assign the person to a lead or customer.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Preferences</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Preferences are communication preferences for individual people. You can set unsubscribe, contact the busienss only, opt in preferences for email, text, voice, and assign them to a communication list.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Leads</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>A lead is for B2B only. This is designed to be companies you are not currently working with.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Customers</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>A Customer is for B2B only. This is designed to be people you have or are currently doing business with.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          
          <h4 style={{fontWeight: "bold"}}>Activities</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Schedule current or future activities such as call, text, email, meetings, Tasks, or Deadlines. These can be assigned to you or other team members.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Contracts</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>You can upload your contracts here. Assign values, products or services, assign start and end dates, assign a status, assign it to people, leads or customers, team members and representatives. You can also add a note.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Photo Galleries</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>A photo gallery contains photos. These can be individually labeled and are a great way to take before and after photos of work being completed through all phases.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Photos</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Photos are individual pictures that belong to a gallery. They can be assigned to a lead, a customer, a person, proposal, contract, or a job. You can edit the photo name, alt text, and description.</p>
          <div style={{ clear: "both", height: "20px" }}></div>


          <h4 style={{fontWeight: "bold"}}>Documents</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Documents are deisgned to be used for internal documents such as SOP's or checklists that you can upload or download.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Jobs</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Jobs are deisgned to schedule future events such a a pest control company doing quarterly service or a  home remodeling company setting up dates and times to do different aspects of a remodel such as demolition, installing a floor, kitchen cabinets or whatever you setup.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Notes</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>Notes are where you can write an internal note for an lead, person, customer.</p>
          <div style={{ clear: "both", height: "20px" }}></div>

          <h4 style={{fontWeight: "bold"}}>Representatives</h4>
          <div style={{ clear: "both", height: "20px" }}></div>
          <p>A representative is someone who is forward-facing in your company and communicates directly with a lead, person, or customer. They can be a technician, salespeople, customer success, or customer service type of role in your organization. Create Representative profiles so they can be tagged in notes, leads, customers, people, jobs, activities, documents, contracts, proposals, photos or galleries, and more. When using Hydrate, there are additional customizable communication capabilities.</p>
          <div style={{ clear: "both", height: "20px" }}></div>          
        </CardBody>
      </Card>
    </>
  );
};

export default CRMTableofContents;
