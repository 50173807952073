import {
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = {
  footer: {
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    padding: "20px",
    width: "100%",
    backgroundColor: "#222222",
    color: whiteColor,
    textAlign: "center",
    fontFamily: "\"Roboto\", sans-serif",
    fontSize: "15px",
    fontWeight: 400,
    zIndex: 4
  },
};
export default footerStyle;
