import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { compact } from 'lodash';
// react component for creating dynamic tables


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Icon from "@mui/material/Icon";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import { LIST_GOOGLE_ADS_GROUPS_BY_FILTER } from "queries/google";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const AdsGroup = ({ filter, type_filter, title, isVideo }) => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data } = useQuery(LIST_GOOGLE_ADS_GROUPS_BY_FILTER, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      filter,
      type_filter
    }
  });
  const classes = useStyles();
  data = data?.listGoogleAdsGroupsByFilter || [];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="bing" icon>
            <CardIcon>
              <Icon>list</Icon>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {title || "Ads Group Breakdown"}
              <HelpTooltip>
                {`An ad group contains one or more ads that share similar targets. Ad groups are used to organize your ads by a common theme.`}
              </HelpTooltip>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={compact([
                {
                  Header: "Name",
                  accessor: "ad_group_name"
                },
                {
                  Header: "Total Spent",
                  accessor: "cost",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(addMargin((original?.cost || 0) / 1000000, state))
                },
                isVideo && {
                  Header: "Views",
                  accessor: "views",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US").format(
                      original?.views || 0
                    )
                },
                isVideo && {
                  Header: "View Rate",
                  accessor: "view_rate",
                  Cell: ({ original }) =>
                    `${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(
                      (original?.views || 0) /
                        (original?.impressions || 1) /
                        0.01
                    )}%`
                },
                {
                  Header: "Impressions",
                  accessor: "impressions",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US").format(
                      original?.impressions || 0
                    )
                },
                {
                  Header: "Clicks",
                  accessor: "clicks",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US").format(original?.clicks || 0)
                },
                {
                  Header: "CTR",
                  accessor: "ctr",
                  Cell: ({ original }) =>
                    `${new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }).format(
                      (original?.clicks || 0) /
                        (original?.impressions || 1) /
                        0.01
                    )}%`
                },
                !isVideo && {
                  Header: "CPC",
                  accessor: "avg_cpc",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(
                      addMargin(
                        (original?.cost || 0) /
                          (original?.clicks || 1) /
                          1000000,
                        state
                      )
                    )
                },
                !isVideo && {
                  Header: "CPM",
                  accessor: "avg_cpm",
                  Cell: ({ original }) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD"
                    }).format(
                      addMargin(
                        (original?.cost || 0) /
                          (original?.impressions || 1) /
                          1000,
                        state
                      )
                    )
                }
              ])}
              defaultPageSize={Math.min(10, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

AdsGroup.propTypes = {
  filter: PropTypes.string,
  type_filter: PropTypes.string,
  title: PropTypes.string
};

export default AdsGroup;
