import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact } from "lodash";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_GOOGLE_RESPONSIVE_SEARCH_ADS } from "queries/google";
import { SUBMIT_UPDATE_REQUEST } from "queries/formSubmission";
import { Context } from "../../../redux/store";
import { addMargin } from "utils/margin";
import { renderInputGroup, renderUpdateTable } from "utils/renders";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const ResponsiveSearchAds = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [currentAds, setCurrentAds] = useState(null);
  const [updatedAds, setUpdatedAds] = useState(null);
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_GOOGLE_RESPONSIVE_SEARCH_ADS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = data?.listGooglerResponsiveSearchAds || [];

  data = data.map((row) => ({    
    ...row,
    ctr: Number(row.impressions) !== 0 ? Number(((row.clicks / row.impressions) * 100).toFixed(2)): 0
  }));

  const classes = useStyles();
  const updateFieldHandler = (key, convert) => e => {
    setUpdatedAds({
      ...updatedAds,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const inputs = [
    {
      field: "responsive_headlines",
      label: "Headline 1",
      inputProps: {
        maxLength: 30,
        validation: v => `${v.length}/30 Characters`
      }
    },
    {
      field: "responsive_descriptions",
      label: "Description 1",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_2",
      label: "Description 2",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_3",
      label: "Description 3",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_4",
      label: "Description 4",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_5",
      label: "Description 5",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_6",
      label: "Description 6",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_7",
      label: "Description 7",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_8",
      label: "Description 8",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_9",
      label: "Description 9",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_10",
      label: "Description 10",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_11",
      label: "Description 11",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_12",
      label: "Description 12",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_13",
      label: "Description 13",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_14",
      label: "Description 14",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_descriptions_15",
      label: "Description 15",
      inputProps: {
        maxLength: 90,
        validation: v => `${v.length}/90 Characters`
      }
    },
    {
      field: "responsive_path_1",
      label: "Path 1",
      inputProps: {
        maxLength: 15,
        validation: v => `${v.length}/15 Characters`
      }
    },
    {
      field: "responsive_path_2",
      label: "Path 2",
      inputProps: {
        maxLength: 15,
        validation: v => `${v.length}/15 Characters`
      }
    },
    { field: "final_url", label: "Landing Page", inputProps: { type: "url" } },
    { field: "notes", label: "Notes", optional: true }
  ];

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    SUBMIT_UPDATE_REQUEST,
    {
      variables: {
        subject: `Update Responsive Search Ads | ${state.customers?.selected?.name}`,
        updateTable: renderUpdateTable(currentAds, updatedAds, inputs),
        customerId: customer_id
      },
      onCompleted: () => {
        formSubmitted();
      }
    }
  );

  const handleOpen = row => {
    setCurrentAds(row);
    setUpdatedAds(row);
  };

  const handleClose = (accept = false) => () => {
    if (accept) {
      submitUpdateRequest();
    }
    setCurrentAds(null);
    setUpdatedAds(null);
  };

  const hasUpdate =
    user_level !== "customer" && user_level !== "agency_customer";

  const columns = compact([
    {
      Header: "Responsive Search Ads",
      accessor: "responsive_search_ads",
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <a
            style={{clear:'left'}}
            href={original?.final_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original?.responsive_headlines}
          </a>
            <div
              style={{clear:'left'}}
            >{original?.responsive_headlines_2}</div>
          {(() => {
                    if (original?.responsive_headlines_3 !== null && original?.responsive_headlines_3.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                        >{original?.responsive_headlines_3}</div>
                      )
                    } 
          })()}
          {(() => {
                    if (original?.responsive_headlines_4 !== null && original?.responsive_headlines_4.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                        >{original?.responsive_headlines_4}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_5 !== null && original?.responsive_headlines_5.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                          >{original?.responsive_headlines_5}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_6 !== null && original?.responsive_headlines_6.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_6}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_7 !== null && original?.responsive_headlines_7.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_7}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_8 !== null && original?.responsive_headlines_8.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                        >{original?.responsive_headlines_8}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_9 !== null && original?.responsive_headlines_9.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_9}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_10 !== null && original?.responsive_headlines_10.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_10}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_11 !== null && original?.responsive_headlines_11.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                        >{original?.responsive_headlines_11}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_12 !== null && original?.responsive_headlines_12.length) {
                      return (
                        <div
                          style={{clear:'left'}}
                        >{original?.responsive_headlines_12}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_13 !== null && original?.responsive_headlines_13.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_13}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_14 !== null && original?.responsive_headlines_14.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_14}</div>
                      )
                    }
          })()}
          {(() => {
                    if (original?.responsive_headlines_15 !== null && original?.responsive_headlines_15.length) {
                      return (
                        <div
                            style={{clear:'left'}}
                          >{original?.responsive_headlines_15}</div>
                      )
                    } 
                  })()}
          <div>{original?.headline_pt_2}</div>
          <div>{original?.headline_pt_3}</div>
          <div style={{ color: "green" }}>
            https://{original?.display_url?.replace("https://", "")}/
            {original?.responsive_path_1}/{original?.responsive_path_2}
          </div>
          <div>
            {original?.responsive_descriptions}<br />
            {original?.responsive_descriptions_2}<br />
            {original?.responsive_descriptions_3}<br />
            {original?.responsive_descriptions_4}<br />
          </div>
        </>
      ),
      renderCSV: ({ original }) =>
        [
          original?.responsive_headlines,
          `https://${original?.display_url?.replace("https://", "")}/${
            original?.responsive_path_1
          }/${original?.responsive_path_2}`,
          `${original?.responsive_descriptions}`
        ].join("\n")
    },
    {
      Header: "Ad Group Name",
      accessor: "ad_group_name",
      width: 200
    },
    {
      Header: "Status",
      accessor: "status",
      width: 100
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(addMargin((original?.cost || 0) / 1000000, state))
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      width: 100
    },
    {
      Header: "Clicks",
      accessor: "clicks",
      width: 100
    },
    {
      Header: "CTR",
      accessor: "ctr",
      width: 100,
      Cell: ({ original }) =>
        original?.ctr + "%"
    },
    {
      Header: "CPC",
      accessor: "average_cpc",
      width: 100,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) =>
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD"
        }).format(
          addMargin(
            (original?.cost || 0) / (original?.clicks || 1) / 1000000,
            state
          )
        )
    }/*,
    hasUpdate && {
      Header: "",
      accessor: "update",
      width: 100,
      sortable: false,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <a
          href="#"
          rel="noopener noreferrer"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleOpen(original);
          }}
        >
          Update
        </a>
      ),
      noCSV: false
    }*/
  ]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Responsive Search Ads on Google
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`Google Responsive Search Ads (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading || updating}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
            {currentAds && (
              <Dialog
                open={Boolean(currentAds)}
                onClose={handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">Update Ads</DialogTitle>
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleClose(true)();
                  }}
                >
                  <DialogContent>
                    {inputs.map(
                      renderInputGroup(
                        currentAds,
                        updatedAds,
                        updateFieldHandler
                      )
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      autoFocus
                      type="submit"
                      disabled={inputs.some(
                        ({ field, optional }) => !updatedAds[field] && !optional
                      )}
                    >
                      Submit
                    </Button>
                    <Button onClick={handleClose()}>Cancel</Button>
                  </DialogActions>
                </form>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(ResponsiveSearchAds);
