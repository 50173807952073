import React from "react";


import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import PaidSearchNoContentWrapper from "components/PaidSearchNoContentWrapper";

import BingCampaignSummary from "./BingCampaignSummary";
import BingAdsGroup from "./BingAdsGroup";
import TextAds from "./TextAds";
import TopKeywordsBing from "./TopKeywordsBing";
import { READ_ME } from "queries/users";

const useStyles = makeStyles(styles);

const BingCampaigns = () => {
  const classes = useStyles();
  const { data: me } = useQuery(READ_ME);

  // show historic data from employees but not customers if the product is not running
  return (
    <>
    {me?.readMe?.user_level !== "master" && me?.readMe?.user_level !== "campaign_consultant" && me?.readMe?.user_level !== "ad_consultant" && me?.readMe?.user_level !== "paid_ad_manager" && me?.readMe?.user_level !== "content_manager" ?
    <PaidSearchNoContentWrapper title="Bing">
      <div>
        <h2 className={classes.pageHeader}>Bing</h2>
        <BingCampaignSummary />
        <BingAdsGroup />
        <h2 className={classes.pageHeader}>Text Ads</h2>
        <TextAds />
        <h2 className={classes.pageHeader}>Tracked Keyword Details</h2>
        <TopKeywordsBing />
      </div>
    </PaidSearchNoContentWrapper>
    :
    <div>
      <h2 className={classes.pageHeader}>Bing</h2>
      <BingCampaignSummary />
      <BingAdsGroup />
      <h2 className={classes.pageHeader}>Text Ads</h2>
      <TextAds />
      <h2 className={classes.pageHeader}>Tracked Keyword Details</h2>
      <TopKeywordsBing />
    </div>
    }
  </>   
  );
};

export default BingCampaigns;
