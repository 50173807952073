import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import { LIST_HOME_DETAILS } from "queries/neuron";
import { Context } from "../../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const CampaignBreakdown = () => {
  const [state] = useContext(Context);
  const dateRangeString = state.customers?.dateRangeString;
  // const customer_id = state.customers?.selected?.id;
  const neuron_id = state.customers?.selected?.neuron_id;
  let { loading, data } = useQuery(LIST_HOME_DETAILS, {
    skip: !neuron_id || !state.customers?.dateRangeString,
    variables: {
      neuron_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });
  data = neuron_id ? data?.listHomeDetails || [] : [];
  const classes = useStyles();
  const columns = [
    {
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Start Date",
      accessor: "startDate",
      Cell: ({ original }) => moment(original.startDate).format("YYYY-MM-DD")
    },
    {
      Header: "End Date",
      accessor: "endDate",
      Cell: ({ original }) => moment(original.endDate).format("YYYY-MM-DD")
    },
    {
      Header: "Impressions",
      accessor: "impressions",
      Cell: ({ original }) => original.impressions || 0
    },
    {
      Header: "Playouts",
      accessor: "playouts",
      Cell: ({ original }) => original.playouts || 0
    },
    {
      Header: "ECPM",
      accessor: "ecpm",
      Cell: ({ original }) =>
        `${
          original.impressions
            ? ((original.spent || 0) * 1000) / original.impressions
            : 0
        }`
    }
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Tracked Out of Home Details
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CampaignBreakdown;
