import React from "react";
import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import UploadInput from "components/UploadInput";
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";
const moment = require("moment");

const filter = createFilterOptions();

const LinearDeterminate = ({ uploadPercentage }) => {
  return (
    <div>
      <LinearProgress variant="determinate" value={uploadPercentage.percent} />
      <Typography variant="caption" component="div" color="textSecondary">{uploadPercentage ? `${Math.round(
        uploadPercentage.percent,
      )}% Please wait for the upload to complete.` : ""}</Typography>
    </div>
  );
}


const AddUpdateProposal = (CRMActivitySubmission, updateActivityFieldHandler, updateProposalDatabase,
  CRMPeople, ProposalsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, updateProposalFieldHandler, PersonFound, uploadPercentage, setuploadPercentage,
  customer_id, CRMProducts, CRMServices, Update = false, CRMActivity = [], CRMPhotoGallery, CRMJob,
  setProposalsubmissionData, proposalvalue, setproposalvalue, ProposalStatuses, CreateContractfromProposal, CRMContract) => {

  const FormatCurrency = (value) => {
    let newValue = parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setProposalsubmissionData({
      ...ProposalsubmissionData,
      ["proposal_value"]: newValue
    });
  }

  if (CRMProducts && ProposalsubmissionData?.products && typeof ProposalsubmissionData?.products === 'string') {
    let Product_Data = [];
    ProposalsubmissionData?.products?.split(",")?.forEach(async (SelectedProduct) => {
      CRMProducts?.forEach(async (ProposalsubmissionDataProduct) => {
        if (SelectedProduct?.toString()?.trim() === ProposalsubmissionDataProduct?.id?.toString()) {
          Product_Data.push(ProposalsubmissionDataProduct?.product_name);
        }
      })
    })

    if (Product_Data?.length) {
      ProposalsubmissionData.products = Product_Data;
    }
  }

  if (CRMServices && ProposalsubmissionData?.services && typeof ProposalsubmissionData?.services === 'string') {
    let Service_Data = [];
    ProposalsubmissionData?.services?.split(",")?.forEach(async (SelectedCategory) => {
      CRMServices?.forEach(async (ProposalsubmissionDataCategory) => {
        if (SelectedCategory?.toString()?.trim() === ProposalsubmissionDataCategory?.id?.toString()) {
          Service_Data.push(ProposalsubmissionDataCategory?.service_name);
        }
      })
    })

    if (Service_Data?.length) {
      ProposalsubmissionData.services = Service_Data;
    }
  }

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Upload A"} Proposal</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px" }}>Proposal Name:</label>
        <input
          id="proposal_name"
          maxLength={255}
          type="text"
          value={ProposalsubmissionData?.proposal_name || ""}
          onChange={updateProposalFieldHandler("proposal_name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px" }}>Service(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateProposalFieldHandler("services")}
          inputProps={{
            name: "services",
            id: "services",
            value: (typeof ProposalsubmissionData?.services === 'string' || ProposalsubmissionData?.services instanceof String) ? ProposalsubmissionData?.services?.split(",").map((category_text) => { return category_text }) || [] : (typeof ProposalsubmissionData?.services !== 'undefined') ? ProposalsubmissionData?.services || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMServices?.map((CurrentService) => {
            return (
              <MenuItem key={CurrentService?.id} value={CurrentService?.service_name}>
                <CustomCheckbox
                  checked={
                    ProposalsubmissionData?.services?.indexOf(CurrentService?.service_name) >
                    -1
                  }
                />
                <ListItemText primary={CurrentService?.service_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px" }}>Product(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateProposalFieldHandler("products")}
          inputProps={{
            name: "products",
            id: "products",
            value: (typeof ProposalsubmissionData?.products === 'string' || ProposalsubmissionData?.products instanceof String) ? ProposalsubmissionData?.products?.split(",").map((product_name) => { return product_name }) || [] : (typeof ProposalsubmissionData?.products !== 'undefined') ? ProposalsubmissionData?.products || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMProducts?.map((CurrentProduct) => {
            return (
              <MenuItem key={CurrentProduct?.id} value={CurrentProduct?.product_name}>
                <CustomCheckbox
                  checked={
                    ProposalsubmissionData?.products?.indexOf(CurrentProduct?.product_name) >
                    -1
                  }
                />
                <ListItemText primary={CurrentProduct?.product_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {/*  only visible to water bears and tom's testing company */}
      {(customer_id === 38 || customer_id === 165) && typeof ProposalsubmissionData?.products !== 'string' ?
        ProposalsubmissionData?.products?.map((CurrentProduct, index) => {
          return (
            <div style={{ display: "flex", marginTop: "20px" }}>
              <label style={{ textAlign: "left", width: "150px" }}>{CurrentProduct === "WebsiteDesign" ||
                CurrentProduct === "WebsiteDesign | WooCommerce" || CurrentProduct === "OCA | Production"
                || CurrentProduct === "OCA | Production Two" || CurrentProduct === "OCA | Production Three" ? CurrentProduct + " Total" : CurrentProduct + " Monthly"} Budget $:</label>
              <input
                id={`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`}
                maxLength={255}
                type="text"
                value={ProposalsubmissionData?.Budgets !== undefined && Object.keys(ProposalsubmissionData["Budgets"])?.length ? Object.keys(ProposalsubmissionData["Budgets"])?.map((item, i) => { if (ProposalsubmissionData["Budgets"][i]?.LocalID === CurrentProduct?.toLowerCase()?.replaceAll(" ", "")) return ProposalsubmissionData["Budgets"][i]?.monthly_budget })?.filter(CurrentBudget => CurrentBudget !== undefined) : ""}
                onBlur={(e) => {
                  ProposalsubmissionData?.Budgets && Object.keys(ProposalsubmissionData?.Budgets)?.length ?
                    Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                      if (ProposalsubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                        let ProposalsubmissionDataClone = Object.assign({}, ProposalsubmissionData);
                        ProposalsubmissionDataClone.Budgets[i] = Object.assign({}, {
                          "product_name": ProposalsubmissionData?.Budgets[i]?.product_name, "LocalID": ProposalsubmissionData?.Budgets[i]?.LocalID, "monthly_budget": parseFloat(ProposalsubmissionData["Budgets"][i]?.monthly_budget?.replaceAll(/,/g, '')).toLocaleString('en-US', {
                            style: 'decimal',
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          }),
                          "budget_cycles_product_id": ProposalsubmissionData?.Budgets[i]?.budget_cycles_product_id, proposal_id: ProposalsubmissionData?.Budgets[i]?.proposal_id, product_id: ProposalsubmissionData?.Budgets[i]?.product_id
                        })
                        setProposalsubmissionData({
                          ...ProposalsubmissionDataClone
                        });
                      }
                    })
                    : ""
                }
                }
                onChange={updateProposalFieldHandler(`${CurrentProduct?.toLowerCase()?.replaceAll(" ", "")}_monthlybudget`)}
                onFocus={(e) => {
                  ProposalsubmissionData?.Budgets && Object.keys(ProposalsubmissionData?.Budgets)?.length ?
                    Object.keys(ProposalsubmissionData?.Budgets)?.map((CurrentBudget, i) => {
                      if (ProposalsubmissionData?.Budgets[i]?.LocalID === e?.target?.id?.toLowerCase()?.replaceAll(" ", "")?.split("_")[0]) {
                        let ProposalsubmissionDataClone = Object.assign({}, ProposalsubmissionData);
                        ProposalsubmissionDataClone.Budgets[i] = Object.assign({}, { "product_name": ProposalsubmissionData?.Budgets[i]?.product_name, "LocalID": ProposalsubmissionData?.Budgets[i]?.LocalID, "monthly_budget": ProposalsubmissionData["Budgets"][i]?.monthly_budget?.replace(/[^0-9.]/g, ""), "budget_cycles_product_id": ProposalsubmissionData?.Budgets[i]?.budget_cycles_product_id, proposal_id: ProposalsubmissionData?.Budgets[i]?.proposal_id, product_id: ProposalsubmissionData?.Budgets[i]?.product_id })
                        setProposalsubmissionData({
                          ...ProposalsubmissionDataClone
                        });
                      }
                    })
                    : ""
                }
                }
                style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
              />
            </div>
          )
        })
        : ""
      }
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {uploadPercentage && (
        <LinearDeterminate uploadPercentage={uploadPercentage} />
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ marginTop: "20px", display: "flex" }}>
        <label style={{ textAlign: "left", width: "155px", float: "left" }}>Upload Proposal:</label>
        <div style={{ width: "100%", height: "40px !important", float: "left" }}>
          <UploadInput
            path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
            onChange={value => updateProposalFieldHandler("proposal_url")({ target: { value } })}
            value={ProposalsubmissionData["proposal_url"] || ""}
            label={ProposalsubmissionData["proposal_url"] ? "View" : "Upload"}
            setuploadPercentage={setuploadPercentage}
            accept={customer_id === 38 ? {"application/pdf": [".pdf"]} : "*"}
          />
        </div>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {customer_id !== 38 && customer_id !== 165 && (
        <>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <label style={{ textAlign: "left", width: "155px", float: "left" }}>Proposal Value:</label>
            <input
              id="proposal_value"
              maxLength={15}
              type="text"
              value={ProposalsubmissionData?.proposal_value}
              onBlur={() => FormatCurrency(ProposalsubmissionData?.proposal_value)}
              onFocus={() => {
                setProposalsubmissionData({
                  ...ProposalsubmissionData,
                  ["proposal_value"]: ProposalsubmissionData?.proposal_value?.replace(/[^0-9.]/g, "")
                })
              }}
              onChange={updateProposalFieldHandler("proposal_value")}
              style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
            /></div>
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px", float: "left" }}>Est Close Date:</label>
        <TextField
          id="proposal_estimated_close_date"
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={ProposalsubmissionData?.proposal_estimated_close_date?.indexOf("-") === -1 ? moment(parseInt(ProposalsubmissionData?.proposal_estimated_close_date)).format("YYYY-MM-DDTHH:mm") : ProposalsubmissionData?.proposal_estimated_close_date ? ProposalsubmissionData?.proposal_estimated_close_date : ""}
          onChange={
            updateProposalFieldHandler("proposal_estimated_close_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px", float: "left" }}>Proposal Start Date:</label>
        <TextField
          id="proposal_start_date"
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={ProposalsubmissionData?.proposal_start_date?.indexOf("-") === -1 ? moment(parseInt(ProposalsubmissionData?.proposal_start_date)).format("YYYY-MM-DDTHH:mm") : ProposalsubmissionData?.proposal_start_date ? ProposalsubmissionData?.proposal_start_date : ""}
          onChange={
            updateProposalFieldHandler("proposal_start_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "155px", float: "left" }}>Proposal End Date:</label>
        <TextField
          id="proposal_end_date"
          type="date"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={ProposalsubmissionData?.proposal_end_date?.indexOf("-") === -1 ? moment(parseInt(ProposalsubmissionData?.proposal_end_date)).format("YYYY-MM-DDTHH:mm") : ProposalsubmissionData?.proposal_end_date ? ProposalsubmissionData?.proposal_end_date : ""}
          onChange={
            updateProposalFieldHandler("proposal_end_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginBottom: "20px" }}></div>
      <label style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, marginTop: "35px", marginRight: "15px", float: "left" }}>Proposal Status:</label>
      <Select
        selected={typeof ProposalsubmissionData?.proposal_status === 'string' ? ProposalStatuses?.map(({ label, value }) => { if (label === ProposalsubmissionData?.proposal_status) return value })?.filter(CurrentStatus => CurrentStatus !== undefined) : ProposalsubmissionData?.proposal_status ? ProposalsubmissionData?.proposal_status : 0}
        onSelect={updateProposalFieldHandler("proposal_status")}
        options={ProposalStatuses?.map(({ label, value }) => ({
          key: value,
          label: label
        }))}
        style={{ marginBottom: 20, float: "left", height: "40px" }}
      />
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {window.location.href.indexOf("crmpersondetail") === -1 && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", width: "129px", float: "left" }}>People:</label>
            {Update === true ?
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === ProposalsubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: ProposalsubmissionData?.people_name ? ProposalsubmissionData?.people_name : "" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Proposal")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Proposal")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0 }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Proposal")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
              :
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Proposal")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Proposal")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0 }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Proposal")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
            }
            {PersonFound ? "" :
              <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
          </div>
        </>
      )}
      {CRMJob?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Job:</label>
          <Select
            options={CRMJob?.map(({ id, job_name }) => ({
              key: id,
              label: job_name
            }))}
            selected={ProposalsubmissionData?.job_id || 0}
            onSelect={updateProposalFieldHandler("job_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMPhotoGallery?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Gallery:</label>
          <Select
            options={CRMPhotoGallery?.map(({ id, photo_gallery_name }) => ({
              key: id,
              label: photo_gallery_name
            }))}
            selected={ProposalsubmissionData?.photo_gallery_id || 0}
            onSelect={updateProposalFieldHandler("photo_gallery_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div>
        <label style={{ marginTop: "8px", width: "129px", float: "left" }}>Assign To:</label>
        <Select
          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
            key: id,
            label: first_name + " " + last_name
          }))}
          selected={ProposalsubmissionData?.assigned_to || ""}
          onSelect={updateProposalFieldHandler("assigned_to")}
          style={{ marginBottom: 20, height: "40px", float: "left" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.proposal_id === ProposalsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.proposal_id === ProposalsubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.proposal_id === ProposalsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.proposal_id === ProposalsubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px", width: "100%" }} /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateProposalDatabase();
      }}>
        Save
      </Button>
      <div style={{ clear: "both", marginTop: "30px" }}></div>
      {(customer_id === 38 || customer_id === 165) && ProposalsubmissionData?.proposal_status === "Sent for Review" && ProposalsubmissionData?.id &&
            CRMContract.filter((CurrentContract) => CurrentContract.proposal_id === ProposalsubmissionData?.id)?.length === 0 && (
        <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
          CreateContractfromProposal(ProposalsubmissionData?.id);
        }}>
          Create Contract from Proposal
        </Button>
      )}
    </div>
  )
};

export default AddUpdateProposal;
