import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import { CSVLink } from "react-csv";
import { startCase, snakeCase } from "lodash";
import { useSnackbar } from "notistack";
import { omit } from "lodash";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Button from "components/CustomButtons/Button";
import Select from "components/Select/Select";
import CustomInput from "components/CustomInput/CustomInput";
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import ArchiveIcon from '@mui/icons-material/Archive';

// core components
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import {
  LIST_TRACKED_LEADS_FORMS,
  UPDATE_TRACKED_FORM
} from "queries/trackedLeads";
import { LIST_CUSTOMER_EMAILS, UPDATE_CUSTOMER_EMAIL, GET_PRODUCT_CATEGORIES, LIST_PRODUCT } from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { GET_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";

import withSubmissions from "hocs/withSubmissions";
import { READ_ME } from "queries/users";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const styles = {
  ...formStyles,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  leftAlign: {
    "& > div": {
      textAlign: "left !important"
    }
  }
};

const leadQualities = [
  { label: "Good", key: 1 },
  { label: "Bad", key: 2 },
  { label: "Other", key: 3 }
];

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setsubmissionTime, setPhone, setSource } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ whiteSpace: "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.length}
            sortDirection={orderBy === column.accessor ? order : false}
            align={'left'}
            style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
          >
            {column.Header !== "Select" && column.Header !== "Page" && column.Header !== "Landing Page" && column.Header !== "Submission Time" ?
              <>
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
              </> :
              <>
                {column.Header === "Select" && (
                  <Tooltip title="*Rate multiple forms that have the same result at the same time." placement="top">{column.Header}</Tooltip>
                )}
                {column.Header !== "Select" && (
                  column.Header
                )}
              </>
            }
            <br />
            {column.Header !== "Select" && column.Header !== "Rate Form" && column.Header !== "Page" && column.Header !== "Landing Page" && column.Header !== "Submission Time" ?
              <Input style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                column.Header === "Customer Name" ? setName(event.target.value) :
                  column.Header === "Customer Phone" ?
                    setPhone(event.target.value) : column.Header === "Source" ? setSource(event.target.value) : setSource(event.target.value)
              }}></Input> : ""}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


const FormSubmissions = ({ formSubmitted }) => {
  const [state] = useContext(Context);
  const [selectedForm, setSelectedForm] = useState(null);
  const [MultiSelectvalue, setMultiSelectvalue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('submitted_at');
  const [name, setName] = React.useState('');
  const [submissiontime, setsubmissionTime] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [source, setSource] = React.useState('');
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [CategoryData, setCategoryData] = React.useState(null);
  const [ProductData, setProductData] = React.useState(null);
  const [Formdata, setFormdata] = React.useState([]);
  const customer_id = state.customers?.selected?.id;
  const [customers, setCustomers] = useState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dateRangeString = state.customers?.dateRangeString;
  const { data: me } = useQuery(READ_ME);
  let { data, refetch } = useQuery(LIST_TRACKED_LEADS_FORMS, {
    fetchPolicy: "no-cache",
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      keywords: 2,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: name ? name : "",
      submissiontime: submissiontime ? submissiontime : "",
      phone: phone ? phone : "",
      source: source ? source : "",
      count: 0
    }
  });

  let { data: all_data } = useQuery(LIST_TRACKED_LEADS_FORMS, {
    fetchPolicy: "no-cache",
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      keywords: 2,
      page: page,
      rows_per_page: rowsPerPage,
      order_by: orderBy + " " + order,
      name: name ? name : "",
      submissiontime: submissiontime ? submissiontime : "",
      phone: phone ? phone : "",
      source: source ? source : "",
      count: 0
    }
  });

  const { data: budgetData } = useQuery(GET_BUDGET, {
    fetchPolicy: "no-cache",
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
      product_line: "hydrate"
    }
  });

  const { data: Category_Data } = useQuery(GET_PRODUCT_CATEGORIES, {
    fetchPolicy: "no-cache",
    skip: !state.customers?.selected?.id,
    variables: {
      customer_id: state.customers?.selected?.id,
    }
  });


  const { data: Product_Data } = useQuery(LIST_PRODUCT, {
    fetchPolicy: "no-cache",
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { refetch: refetchCustomerEmail } = useQuery(LIST_CUSTOMER_EMAILS, {
    fetchPolicy: "no-cache",
    skip: !customer_id,
    variables:
    {
      customer_id,
      count: 0
    }
  });

  all_data = all_data?.listTrackedLeadsForms || [];

  useEffect(() => {
    setCategoryData(Category_Data?.listProductCategories || []);
    setProductData(Product_Data?.ListProduct || []);
    setFormdata((data?.listTrackedLeadsForms || []).map(
      ({ form_data: form_data_str, ...rest }) => {
        const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
        const form_data_obj_normalized = {};
        Object.keys(form_data_obj).forEach(key => {
          const title = startCase(key.split("<")[0]);
          if (title === "G Recaptcha Response") {
            form_data_obj_normalized[title] = form_data_obj[key];
            return;
          }
          form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
        });
        return {
          form_data: form_data_obj_normalized,
          ...rest
        };
      }
    ));
  }, [Category_Data, Product_Data, data]);

  let { data: keywordata } = useQuery(LIST_TRACKED_LEADS_FORMS, {
    skip: !customer_id || !state.customers?.dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      keywords: 1
    },
    onCompleted: () => {
      setPage(0);
    }
  });

  let { loading, data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, key: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, key: obj.lead_type })
    })
  }

  const updateFieldHandler = (key, convert) => e => {
    const value = e?.target ? e?.target.value : e;
    setSelectedForm({
      ...selectedForm,
      [key]: convert ? convert(value) : value
    });
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = customers.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setCustomers(newCustomers);
      }
    }
  );

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
    refetch();
  };

  const updateSelectFieldHandler = (key, id, e) => {
    if (e?.target.checked) {
      setMultiSelectvalue({
        ...MultiSelectvalue,
        [key]: MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect + "," + id : id
      });
    } else {
      setMultiSelectvalue({
        ...MultiSelectvalue,
        [key]: MultiSelectvalue?.multiselect?.indexOf(",") ? MultiSelectvalue?.multiselect?.replace("," + id, "")?.replace(id, "")?.replace(",,", ",") : ""
      });
    }
  };

  const leadType = selectedForm?.lead_type;

  const handleOpenEdit = async (row) => {
    let CustomerEmail = await refetchCustomerEmail({
      customer_id,
      cell_phone_number: row?.formatted_customer_phone_number ? row?.formatted_customer_phone_number?.replaceAll("-", "") : "",
      email: row?.form_data?.email ? row?.form_data?.email : row?.form_data?.data_email ? row?.form_data?.data_email : ""
    })

    if (CustomerEmail?.data?.listCustomerEmails?.length > 0) {
      setUpdatedCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0] || {});
      setCurrentCustomerEmail(CustomerEmail?.data?.listCustomerEmails[0]?.id ? CustomerEmail?.data?.listCustomerEmails[0] : null);
    } else {
      setUpdatedCustomerEmail({
        first_name: row?.formatted_customer_name?.split(" ")[0],
        last_name: row?.formatted_customer_name?.split(" ")[1],
        cell_phone_number: row?.formatted_customer_phone_number?.replaceAll("-", ""),
        email: row?.form_data?.email ? row?.form_data?.email : row?.form_data?.data_email ? row?.form_data?.data_email : "",
        Formid: row?.id
      });
      setCurrentCustomerEmail({
        first_name: row?.formatted_customer_name?.split(" ")[0],
        last_name: row?.formatted_customer_name?.split(" ")[1],
        cell_phone_number: row?.formatted_customer_phone_number?.replaceAll("-", ""),
        email: row?.form_data?.email ? row?.form_data?.email : row?.form_data?.data_email ? row?.form_data?.data_email : "",
        Formid: row?.id
      });
    }
  };

  const [updateForm, { loading: updatingForm }] = useMutation(
    UPDATE_TRACKED_FORM,
    {
      variables: {
        id: selectedForm?.form_id,
        customer_id,
        lead_status: parseInt(selectedForm?.lead_status),
        value: Number(selectedForm?.value),
        note: selectedForm?.note,
        tags: selectedForm?.tags,
        lead_type: selectedForm?.lead_type,
        lead_quality: selectedForm?.lead_quality,
        products: selectedForm?.products && typeof selectedForm?.products !== 'string' ? selectedForm?.products?.map((SelectedProduct) => { const ReturnedValue = ProductData?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : selectedForm?.products ? selectedForm?.products : "",
        categories: selectedForm?.categories && typeof selectedForm?.categories !== 'string' ? selectedForm?.categories?.map((SelectedCategory) => { const ReturnedValue = CategoryData?.map((CurrentCategory) => { if (SelectedCategory === CurrentCategory?.category_text) { return CurrentCategory?.id } })?.filter(CurrentCategory => CurrentCategory !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : selectedForm?.categories ? selectedForm?.categories : "",
        multiselect: MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect : null
      },
      onCompleted: () => {
        setMultiSelectvalue('');
        formSubmitted();
        refetch();
      }
    }
  );

  const classes = useStyles();

  keywordata = (keywordata?.listTrackedLeadsForms || []).map(
    ({ form_data: form_data_str, ...rest }) => {
      const form_data_obj = form_data_str ? JSON.parse(form_data_str) : {};
      const form_data_obj_normalized = {};
      Object.keys(form_data_obj).forEach(key => {
        const title = startCase(key.split("<")[0]);
        if (title === "G Recaptcha Response") {
          form_data_obj_normalized[title] = form_data_obj[key];
          return;
        }
        form_data_obj_normalized[snakeCase(title)] = form_data_obj[key];
      });
      return {
        form_data: form_data_obj_normalized,
        ...rest
      };
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const Keywordcolumns = [
    {
      Header: "Keyword",
      accessor: "keywords"
    }
  ];

  let columns = "";

  columns = [
    {
      Header: "Select",
      noCSV: true
    },
    {
      Header: "Rate Form",
      accessor: "lead_type",
      noCSV: true
    },
    {
      Header: "Submission Time",
      accessor: "submitted_at",
      width: 150
    },
    {
      Header: "Customer Name",
      accessor: "formatted_customer_name",
      width: 100
    },
    {
      Header: "Customer Phone",
      accessor: "formatted_customer_phone_number",
      width: 100
    },
    {
      Header: "Source",
      accessor: "source",
      width: 90
    },
    {
      Header: "Page",
      accessor: "form_url",
      width: 110
    },
    {
      Header: "Landing Page",
      accessor: "landing_page_url",
      width: 110
    }
  ];
  const csvColumns = columns.filter(({ noCSV }) => !noCSV);

  const csvData = [
    csvColumns.map(({ Header }) => Header),
    ...Formdata?.map(row => {
      return csvColumns.map(({ accessor, Cell, CSVRender }) => {
        if (CSVRender || Cell) {
          return (CSVRender || Cell)({ original: row });
        }
        return row[accessor];
      });
    })
  ];

  const csvKeywordData = [
    Keywordcolumns.map(({ Header }) => Header),
    ...keywordata?.map(row => {
      return Keywordcolumns.map(({ accessor, Cell }) => {
        if (Cell) {
          return Cell({ original: row });
        }
        return row[accessor];
      });
    })
  ];

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);

    const handleOpen = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
      }
    };

    const classes = useRowStyles();

    return (
      <React.Fragment>
        {data.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(index)}>
                  {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={MultiSelectvalue?.multiselect ? MultiSelectvalue?.multiselect?.split(",")?.filter(CurrentCall => CurrentCall === row?.id)?.length : false}
                  onChange={e => updateSelectFieldHandler("multiselect", row?.id, e)}
                >
                </Checkbox>
              </TableCell>
              <TableCell>
                <>
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      setSelectedForm({ ...row, submit: true });
                    }}
                  >
                    Rate Form
                  </a>
                  <div>{leadTypes.find(
                    ({ key }) => key === row?.lead_type
                  )?.label}</div>
                  <div>{leadStatuses.find(
                    ({ key }) => key === row?.lead_status
                  )?.label}</div>
                </>
              </TableCell>
              <TableCell align="left" style={{ whiteSpace: "nowrap" }}><div>{row?.submitted_at ? moment(new Date(parseInt(row.submitted_at))).format("YYYY-MM-DD hh:mm:ss a") : ""}</div>
              </TableCell>
              <TableCell align="left"><div>
                <a
                  href="#"
                  rel="noopener noreferrer"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOpenEdit(row);
                  }}
                >
                  {row?.formatted_customer_name}
                </a>
              </div>
              </TableCell>
              <TableCell align="left"><div>{row?.formatted_customer_phone_number}</div>
              </TableCell>
              <TableCell align="left"><div>{row?.source}</div>
              </TableCell>
              <TableCell align="left"><div>{!row?.form_url || row?.form_url === "unknown" ? (
                "Unknown"
              ) : (
                <a
                  href={row?.form_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              )}</div>
              </TableCell>
              <TableCell align="left"><div>{!row?.landing_page_url || row?.landing_page_url === "unknown" ? (
                "Unknown"
              ) : (
                <a
                  href={row?.landing_page_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              )}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ padding: 0 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                      <TableCell align="left"><div>{Object.keys(row.form_data).map(key => {
                        const title = startCase(key.split("<")[0]);
                        if (title === "G Recaptcha Response") {
                          return null;
                        }
                        return (
                          <div
                            key={key}
                            style={{
                              whiteSpace: "nowrap",
                              textAlign: "left",
                              marginBottom: 5,
                              display: "flex"
                            }}
                          >
                            <div
                              style={{
                                minWidth: 180,
                                maxWidth: 180,
                                marginRight: 10,
                                whiteSpace: "pre-line",
                                lineHeight: "18px"
                              }}
                              title={title}
                            >
                              <b>{title}</b>
                            </div>
                            <div
                              style={{
                                whiteSpace: "pre-line",
                                flex: 1
                              }}
                            >
                              {row.form_data[key]}
                            </div>
                          </div>
                        );
                      })
                      }</div>
                      </TableCell>
                      <TableCell><b>Lead Status:</b>{leadStatuses.find(
                        ({ key }) => key === row?.lead_status
                      )?.label}</TableCell>
                      <TableCell><b>Tags:</b><br />{row?.tags}</TableCell>
                    </TableRow>
                    {me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager" ||
                      me?.readMe?.user_level === "paid_ad_manager" || me?.readMe?.user_level === "content_manager" ?
                      <Table size="medium">
                        <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                          <TableCell><b>Keywords:</b><br />{row?.keywords}</TableCell>
                          <TableCell><div><b>Medium:</b><br />{row?.medium}</div></TableCell>
                          <TableCell><div><b>Campaign:</b><br />{row?.campaign}</div></TableCell>
                          <TableCell><b>UTM Source:</b><br />{row?.utm_source}</TableCell>
                          <TableCell><b>UTM Medium:</b><br />{row?.utm_medium}</TableCell>
                          <TableCell><b>UTM Campaign:</b><br />{row?.utm_campaign}</TableCell>
                          <TableCell><b>Notes:</b><br />{row?.note}</TableCell>
                        </TableRow>
                      </Table>
                      : ""}
                  </Table>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        ))}
      </React.Fragment>
    );
  }

  const masterRole = me?.readMe?.user_level === "master" || me?.readMe?.user_level === "paid_ad_manager";

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="leads" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Form Submissions
              <CSVLink
                data={csvData}
                filename={`Tracked Submissions (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                style={{ float: "right", margin: 0 }}
              >
                <ArchiveIcon />
              </CSVLink>
              {masterRole && (
                <CSVLink
                  data={csvKeywordData}
                  filename={`Form_Keywords.csv`}
                  style={{ float: "right", margin: 0 }}
                >
                  <Button
                    color="primary"
                    style={{ float: "right", marginRight: 10 }}
                  >
                    Download Keywords
                  </Button>
                </CSVLink>)}
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_data ? all_data?.length : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              style={{ display: all_data?.length > 0 ? "" : "none"}}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={Formdata}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setsubmissionTime={setsubmissionTime}
                  setSource={setSource}
                  setPhone={setPhone}
                />
                <TableBody>
                  <Row data={Formdata} />
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              open={Boolean(selectedForm)}
              onClose={() => setSelectedForm(null)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="md"
            >
              <DialogTitle id="alert-dialog-title">Form Submission</DialogTitle>
              <DialogContent>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Lead Quality:</div>
                  <Select
                    options={leadQualities}
                    selected={selectedForm?.lead_quality}
                    onSelect={updateFieldHandler("lead_quality")}
                    style={{ flex: 1 }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Type</div>
                  <Select
                    options={leadTypes}
                    selected={leadType}
                    onSelect={updateFieldHandler("lead_type")}
                    style={{ flex: 1 }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Lead Status:</div>
                  <Select
                    options={leadStatuses.filter(
                      ({ types }) => types?.indexOf(leadType) !== -1
                    )}
                    selected={selectedForm?.lead_status}
                    onSelect={updateFieldHandler("lead_status")}
                    style={{ flex: 1 }}
                  />
                </div>
                {CategoryData?.length ?
                  <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 15, marginTop: 15 }}>
                    <div style={{ width: 120 }}>Category</div>
                    <MultiSelect
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      multiple
                      input={<Input />}
                      renderValue={selected => selected.join(", ")}
                      onChange={updateFieldHandler("categories")}
                      inputProps={{
                        name: "categories",
                        id: "categories",
                        value: (typeof selectedForm?.categories === 'string' || selectedForm?.categories instanceof String) ? selectedForm?.categories?.split(",")?.map((category_name) => { return CategoryData?.filter((CurrentCategory) => { if (CurrentCategory?.id.toString() === category_name.toString()) { return true } else { return false } })?.map((CurrentCategory) => { return CurrentCategory?.category_text }).join(", ") }) || [] : (typeof selectedForm?.categories !== 'undefined') ? selectedForm?.categories || [] : []
                      }}
                      style={{ flex: 1, border: "1px solid grey" }}
                    >
                      {CategoryData?.map((CurrentCategory) => {
                        var checked = false;
                        if ((typeof selectedForm?.categories === 'string' || selectedForm?.categories instanceof String)) {
                          var temp = selectedForm?.categories?.split(",");

                          for (var index = 0; index <= temp?.length; index++) {
                            if (temp[index]?.toString() === CurrentCategory?.id?.toString() || temp[index]?.toString() === CurrentCategory?.category_text?.toString()) { checked = true }
                          }
                        }
                        if (selectedForm?.categories instanceof Object) {
                          for (var index = 0; index <= selectedForm?.categories?.length; index++) {
                            if (selectedForm?.categories[index]?.toString() === CurrentCategory?.category_text?.toString() || selectedForm?.categories[index]?.toString() === CurrentCategory?.id?.toString()) { checked = true }
                          }
                        }

                        return (
                          <MenuItem key={CurrentCategory?.id} value={CurrentCategory?.category_text}>
                            <CustomCheckbox
                              checked={checked}
                            />
                            <ListItemText primary={CurrentCategory?.category_text} />
                          </MenuItem>
                        )
                      })
                      }
                    </MultiSelect>
                  </div>
                  : ""}
                {ProductData?.length ?
                  <div style={{ display: "flex", alignItems: "flex-end", marginBottom: 15, marginTop: 15 }}>
                    <div style={{ width: 120 }}>Product</div>
                    <MultiSelect
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      multiple
                      input={<Input />}
                      renderValue={selected => selected.join(", ")}
                      onChange={updateFieldHandler("products")}
                      inputProps={{
                        name: "products",
                        id: "products",
                        value: (typeof selectedForm?.products === 'string' || selectedForm?.products instanceof String) ? selectedForm?.products?.split(",")?.map((product_name) => { return ProductData?.filter((CurrentProduct) => { if (CurrentProduct?.id.toString() === product_name.toString()) { return true } else { return false } })?.map((CurrentProduct) => { return CurrentProduct?.product_name }).join(", ") }) || [] : (typeof selectedForm?.products !== 'undefined') ? selectedForm?.products || [] : []
                      }}
                      style={{ flex: 1, border: "1px solid grey" }}
                    >
                      {ProductData?.map((CurrentProduct) => {
                        var checked = false;
                        if ((typeof selectedForm?.products === 'string' || selectedForm?.products instanceof String)) {
                          var temp = selectedForm?.products?.split(",");

                          for (var index = 0; index <= temp?.length; index++) {
                            if (temp[index]?.toString() === CurrentProduct?.id?.toString() || temp[index]?.toString() === CurrentProduct?.product_name?.toString()) { checked = true }
                          }
                        }
                        if (selectedForm?.products instanceof Object) {
                          for (var index = 0; index <= selectedForm?.products?.length; index++) {
                            if (selectedForm?.products[index]?.toString() === CurrentProduct?.product_name?.toString() || selectedForm?.products[index]?.toString() === CurrentProduct?.id?.toString()) { checked = true }
                          }
                        }

                        return (
                          <MenuItem key={"products" + CurrentProduct?.id} value={CurrentProduct?.product_name}>
                            <CustomCheckbox
                              checked={checked}
                            />
                            <ListItemText primary={CurrentProduct?.product_name} />
                          </MenuItem>
                        )
                      })}
                    </MultiSelect>
                  </div>
                  : ""}
                <div style={{ display: "flex", alignItems: "flex-end" }}>
                  <div style={{ width: 120 }}>Sale/Customer Value:</div>
                  <CustomInput
                    labelText="0.0"
                    inputProps={{
                      value: selectedForm?.value || "",
                      onChange: updateFieldHandler("value"),
                      type: "number"
                    }}
                  />
                </div>
                <CustomInput
                  labelText="Note"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: selectedForm?.note || "",
                    onChange: updateFieldHandler("note")
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (selectedForm === null || selectedForm?.lead_quality === "") {
                      alert("Please select lead quality.")
                      return;
                    }
                    if (leadType === undefined) {
                      alert("Please select lead type.")
                      return;
                    }
                    if (selectedForm === null || selectedForm?.lead_status === "") {
                      alert("Please select lead status.")
                      return;
                    }
                    updateForm();
                    setSelectedForm(null);
                  }}
                  color="primary"
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setCustomers={setCustomers}
                    customers={customers}
                    TrackedLead={1}
                    source={"Hydrate"}
                  />
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(FormSubmissions);
