import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { useOutletContext } from 'react-router-dom';
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


// @mui/icons-material
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import PropTypes from 'prop-types';
// core components
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import UpdateCRMCustomerProfile from "views/Dashboard/UpdateCRMCustomerProfile";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CRM_CUSTOMERS,
  DELETE_CRM_CUSTOMER,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForEstimates from "./CheckForEstimates";
import EstimateList from "./EstimateList";
import CheckForTickets from "./CheckForTickets";
import TicketList from "./TicketList";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns, setName, setPhone, setAddress, setassigned_to, crm_user_level, setIndustry, setLead_Status } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }}>
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          return (
            <TableCell
              key={column.length}
              sortDirection={orderBy === column.accessor ? order : false}
              align={'left'}
              style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
            >
              <TableSortLabel
                active={orderBy === column.accessor}
                direction={orderBy === column.accessor ? order : 'asc'}
                onClick={createSortHandler(column.accessor)}
              >
                {column.Header}
              </TableSortLabel>
              <br />
              {column.Header !== "" && column.Header !== "Delete" ?
                <Input
                  placeholder={"Search"}
                  style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                    column.Header === "Business Name" ? setName(event.target.value) :
                      column.Header === "Business Phone" ? setPhone(event.target.value?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll(" ", "")) :
                        column.Header === "Address" ? setAddress(event.target.value) :
                          column.Header === "Industry" ? setIndustry(event.target.value) :
                            column.Header === "Lead Status" ? setLead_Status(event.target.value) :
                              setassigned_to(event.target.value)
                  }}></Input> : ""}
            </TableCell>
          )
        })}
        <TableCell>&nbsp;</TableCell>
      </TableRow>
    </TableHead>
  );
}

const CRMCustomersPage = (props) => {
  const [state] = useContext(Context);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('update_time');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [industry, setIndustry] = React.useState('');
  const [Lead_Status, setLead_Status] = React.useState('');
  const [assigned_to, setassigned_to] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [all_customers_data, setall_customers_data] = useState(null);

  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMEstimate, setCRMEstimate] = useState([]);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const crm_user_level = me?.readMe?.crm_user_level;
  const { LoadNewNotifications } = useOutletContext();

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: Contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: Job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { loading, data, refetch } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      address,
      assigned_to,
      name,
      phone,
      industry,
      lead_status: Lead_Status,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      status: 1
    }
  });


  let { data: all_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1,
      status: 1,
      address,
      assigned_to,
      name,
      phone,
      industry,
      lead_status: Lead_Status,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    }
  });

  const [getpeople_data] = useLazyQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id || CRMCustomers?.lenth === 0,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      customer_ids: CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id)?.map((CurrentCustomer) => CurrentCustomer?.id)
    },
    onCompleted: (people_data) => {
      setCRMPeople(people_data?.listCustomerEmails || []);
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  useEffect(() => {
    setall_customers_data(all_data?.CRMCustomers || []);
    setCRMCustomers(data?.CRMCustomers);
    setCRMProposal(Proposal_data?.ListProposal || []);
    setCRMContract(Contract_data?.ListContract || []);
    setCRMJob(Job_data?.ListJobs || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);
    getpeople_data();
  }, [data, Representative_data, Estimate_data, all_ticket_data,
    Proposal_data, Contract_data, Job_data, Photo_Gallery_data,
    document_data, Photo_data]);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      onCompleted: async () => {
        refetch();
        LoadNewNotifications
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_CRM_CUSTOMER,
    {
      onCompleted: () => {
        refetch();

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = async (BusinessData) => {
    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (BusinessData) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(BusinessData, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status"]),
            office_phone_number: `${BusinessData?.office_phone_number !== undefined ? BusinessData?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: BusinessData?.revenue ? BusinessData?.revenue?.replace(/[^0-9.]+/g, "") : "",
            status: BusinessData?.status === undefined ? "1" : `${BusinessData?.status}`,
            lead_status: BusinessData?.lead_status && typeof BusinessData?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === BusinessData?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : BusinessData?.lead_status ? BusinessData?.lead_status : null
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  const columns = compact([
    {
      Header: "Business Name",
      accessor: "business_name"
    },
    {
      Header: "Business Phone",
      accessor: "office_phone_number"
    },
    {
      Header: "Address",
      accessor: "office_address_line_one"
    },
    {
      Header: "Industry",
      accessor: "industry"
    },
    {
      Header: "Lead Status",
      accessor: "lead_status"
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to"
    }
  ]);

  function Row(props) {
    const { data, handleOpen } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handlePhotosExpand = (clickedIndex) => {
      if (isPhotosOpenCollapse === clickedIndex) {
        setIsPhotosOpenCollapse(null);
      } else {
        setIsPhotosOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                {(CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand) ||
                  CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand) ||
                  CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand) ||
                  CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand) ||
                  CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand) ||
                  CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand) ||
                  CheckForEstimates(row, index, "crm_customers_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand) ||
                  CheckForTickets(row, index, "crm_customers_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)
                ) && (
                    <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                      {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  )}
              </TableCell>
              <TableCell style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                <>
                  <a href={"/admin/crmcustomerdetail?customer=" + customer_id + "&id=" + row?.id}>
                    {row?.business_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{FormatPhone(row?.office_phone_number)}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.office_address_line_one} {row?.office_address_line_two} {row?.office_city} {row?.office_state} {row?.office_zip} {row?.office_country}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.industry}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.lead_status}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.assigned_to_first_name} {row?.assigned_to_last_name}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length} style={{ padding: 0, margin: 0 }}>
              <TableCell style={{ padding: 0, margin: 0 }} colSpan={100}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium" aria-label="collapsible table" style={{ padding: 0, margin: 0 }}>
                      {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                      {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                      {CheckForJobs(row, index, "crm_customers_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "crm_customers_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "crm_customers_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, CRMPhotos, "crm_customers_id", CRMRepresentative, isPhotoOpenCollapse, isPhotosOpenCollapse, handlePhotosExpand, customer_id)}
                      {CheckForDocuments(row, index, "crm_customers_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "crm_customers_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForEstimates(row, index, "crm_customers_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                      {EstimateList(row, index, CRMEstimate, "crm_customers_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "crm_customers_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "crm_customers_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "crm_customers_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "crm_customers_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                      {CheckForTickets(row, index, "crm_customers_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                      {TicketList(row, index, CRMTickets, "crm_customers_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  const isLoading = loading || deleting || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <HomeWorkOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
              >
                Add Customer
              </Button>
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_customers_data ? all_customers_data[0]?.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              style={{ display: all_customers_data?.length > 0 ? "" : "none" }}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table
                stickyHeader
                aria-label="collapsible table"
                columns={columns}
                data={data}
                key="CustomerTable1" >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setPhone={setPhone}
                  setAddress={setAddress}
                  setIndustry={setIndustry}
                  setLead_Status={setLead_Status}
                  setassigned_to={setassigned_to}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={CRMCustomers} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCRMCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    customers={CRMCustomers}
                    type={"add"}
                  />
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

CRMCustomersPage.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default CRMCustomersPage;
