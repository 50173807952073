import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";


import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// @mui/icons-material
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteButton from '@mui/material/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumpster, faDumpsterFire } from '@fortawesome/pro-regular-svg-icons'

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import CheckForEstimates from "./CheckForEstimates";
import EstimateList from "./EstimateList";
import CheckForTickets from "./CheckForTickets";
import TicketList from "./TicketList";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  DELETE_GALLERY,
  CRM_CUSTOMERS,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  LIST_TICKETS,
  LIST_TICKET_STATUSES
} from "queries/customers";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setName, setDescription, setAssignedto, crm_user_level } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => {
          if (column.Header !== "Image")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'left'}
                style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
              >
                <TableSortLabel
                  active={orderBy === column.accessor}
                  direction={orderBy === column.accessor ? order : 'asc'}
                  onClick={createSortHandler(column.accessor)}
                >
                  {column.Header}
                </TableSortLabel>
                <br />
                {column.Header !== "Image" && column.Header !== "Updated" ?
                  <Input
                    placeholder={"Search"}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                      column.Header === "Name" ? setName(event.target.value) :
                        column.Header === "Description" ? setDescription(event.target.value) :
                          setAssignedto(event.target.value)
                    }}></Input> : ""}
              </TableCell>
            )
          else if (column.Header === "Image")
            return (
              <TableCell
                key={column.length}
                sortDirection={orderBy === column.accessor ? order : false}
                align={'left'}
                style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
              >{column.Header}</TableCell>)
        })}
      </TableRow>
    </TableHead>
  );
}

const Photos = () => {
  const [state] = useContext(Context);
  const [CRMPhotoGalleries, setCRMPhotoGalleries] = useState();
  const [all_gallery_data, setall_gallery_data] = useState([]);
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [assignedto, setAssignedto] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMTickets, setCRMTickets] = useState([]);
  const [CRMTicketStatuses, setCRMTicketStatuses] = useState([]);
  const [CRMEstimate, setCRMEstimate] = useState([]);
  const [Hover, setHover] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const crm_user_level = me?.readMe?.crm_user_level;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { loading, data: people_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.people_id)?.map((CurrentJob) => CurrentJob?.people_id)
    }
  });

  let { data: proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      proposal_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.proposal_id)?.map((CurrentGallery) => CurrentGallery?.proposal_id)
    }
  });

  let { data: all_ticket_data, refetch: refetchTickets } = useQuery(LIST_TICKETS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      ticket_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
    }
  });

  let { data: ticket_statuses_data } = useQuery(LIST_TICKET_STATUSES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      estimate_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.estimate_id)?.map((CurrentGallery) => CurrentGallery?.estimate_id)
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      document_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.document_id)?.map((CurrentGallery) => CurrentGallery?.document_id)
    }
  });

  let { data: contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      contract_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.contract_id)?.map((CurrentGallery) => CurrentGallery?.contract_id)
    }
  });

  let { data: job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0,
      job_gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.id)?.map((CurrentGallery) => CurrentGallery?.id),
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.job_id)?.map((CurrentGallery) => CurrentGallery?.job_id)
    }
  });

  let { data: data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      description,
      assignedto,
      count: 0
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: all_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      name,
      description,
      assignedto,
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1,
      gallery_ids: CRMPhotoGalleries?.filter((CurrentGallery) => CurrentGallery?.crm_customers_id)?.map((CurrentJob) => CurrentJob?.crm_customers_id)
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  useEffect(() => {
    setall_gallery_data(all_data?.ListPhotoGallery || [])
    setCRMPhotoGalleries(data?.ListPhotoGallery || [])
    setCRMJob(job_data?.ListJobs || []);
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMProposal(proposal_data?.ListProposal || []);
    setCRMContract(contract_data?.ListContract || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMTickets(all_ticket_data?.ListTickets || []);
    setCRMTicketStatuses(ticket_statuses_data?.ListTicketStatuses || []);
  }, [people_data, crm_data, people_data,
    data, job_data, proposal_data, contract_data, document_data,
    Photo_data, service_data, all_ticket_data, Estimate_data]);

  const classes = useStyles();

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (CRMPhotoGalleries?.length) {
    csvData.push(
      ...CRMPhotoGalleries.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = CRMPhotoGalleries.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setCRMPhotoGalleries(newCustomers);
      }
    }
  );

  const [submitDeleteRequest, { loading: deleting }] = useMutation(
    DELETE_GALLERY,
    {
      onCompleted: ({ deleteGallery }) => {
        const newGalleries = CRMPhotoGalleries.filter(({ id }) => id !== deleteGallery);
        setCRMPhotoGalleries(newGalleries);

        const notify = options => {
          const key = enqueueSnackbar(options?.text, {
            variant: options?.variant,
            persist: true
          });
          setTimeout(() => {
            closeSnackbar(key);
          }, options?.delay || 2000);
        }
        notify({
          text: "Information has been successfully submitted",
          variant: "success"
        });
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  const columns = compact([
    {
      Header: "Image",
      accessor: "photo_gallery_url"
    },
    {
      Header: "Name",
      accessor: "photo_gallery_name"
    },
    {
      Header: "Description",
      accessor: "photo_gallery_description"
    },
    {
      Header: "Updated",
      accessor: "photo_gallery_last_updated"
    },
    {
      Header: "Assigned To",
      accessor: "assigned_to"
    }
  ]);

  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

  function Row(props) {
    const { data } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);
    const [IsPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                  {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><img src={row?.photo_gallery_url} width="250px"></img></TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}>
                <>
                  <a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.people_id + "&photoid=" + row?.id}>
                    {row?.photo_gallery_name}
                  </a>
                </>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.photo_gallery_description}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.photo_gallery_last_updated !== null ? moment(new Date(parseInt(row?.photo_gallery_last_updated))).utc().format("MM-DD-YYYY, h:mm:ss A") : ""}</div>
              </TableCell>
              <TableCell align="left" style={{ paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><div>{row?.first_name} {row?.last_name}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length} style={{ padding: 0, margin: 0 }}>
              <TableCell style={{ padding: 0, margin: 0 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    {CRMPhotos?.filter((CurrentIndividualPhoto) => CurrentIndividualPhoto?.photo_gallery_id === row?.id).map(CurrentIndividualPhoto => {
                      return (
                        <TableRow style={{ backgroundColor: "#F5F5F5", padding: 0, margin: 0 }}>
                          <TableCell style={{ padding: 0, margin: 0 }}>
                            <Table size="medium" style={{ padding: 0, margin: 0 }}>
                              <TableRow style={{ backgroundColor: "#F5F5F5", padding: 0, margin: 0 }}>
                                <TableCell style={{ padding: 0, margin: 0, width: "75px" }}>&nbsp;
                                </TableCell>
                                <TableCell style={{ padding: 0, margin: 0 }}>
                                  <div style={{ width: "250px", float: "left", marginRight: "10px", paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><img src={CurrentIndividualPhoto?.photo_url} width="250px"></img></div>
                                  <div style={{ width: "300px", float: "left", paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><b>Photo Name:</b> <a href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.people_id + "&photoid=" + row?.id}>{CurrentIndividualPhoto?.photo_name}</a></div>
                                  <div style={{ width: "250px", float: "left", paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><b>Description:</b> {CurrentIndividualPhoto?.photo_description}</div>
                                </TableCell>
                              </TableRow>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                      return (
                        <>
                          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                            <TableCell style={{ padding: 0, margin: 0 }}>
                              <Table size="medium">
                                <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                  <TableCell style={{ width: "100px" }}>
                                    <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                      {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell><div style={{ float: "left" }}>Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                </TableRow>
                              </Table>
                              <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ padding: 0, margin: 0, width: "175px" }}>&nbsp;
                                    </TableCell>
                                    <TableCell style={{ padding: 0, margin: 0 }}>
                                      <div style={{ width: "300px", float: "left", paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                      <div style={{ width: "175px", float: "left", paddingRight: "5px", paddingLeft: "5px", paddingBottom: "5px", paddingTop: "5px" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                    </TableCell>
                                  </TableRow>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </>)
                    })}
                    {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                    {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                    {CheckForJobs(row, index, "photo_gallery_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                    {JobList(row, index, CRMJob, "photo_gallery_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                    {CheckForDocuments(row, index, "photo_gallery_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                    {DocumentList(row, index, CRMDocument, "photo_gallery_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                    {CheckForEstimates(row, index, "photo_gallery_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                    {EstimateList(row, index, CRMEstimate, "photo_gallery_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                    {CheckForProposals(row, index, "photo_gallery_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                    {ProposalList(row, index, CRMProposal, "photo_gallery_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                    {CheckForContracts(row, index, "photo_gallery_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                    {ContractList(row, index, CRMContract, "photo_gallery_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                    {CheckForTickets(row, index, "photo_gallery_id", CRMTickets, isTicketOpenCollapse, handleTicketExpand)}
                    {TicketList(row, index, CRMTickets, "photo_gallery_id", CRMRepresentative, isTicketOpenCollapse, customer_id, CRMTicketStatuses)}
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <PhotoLibraryOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={CRMPhotoGalleries || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_gallery_data?.length ? all_gallery_data[0]?.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              style={{ display: all_gallery_data?.length > 0 ? "" : "none" }}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setName={setName}
                  setDescription={setDescription}
                  setAssignedto={setAssignedto}
                  crm_user_level={crm_user_level}
                />
                <TableBody>
                  <Row data={CRMPhotoGalleries} handleOpen={handleOpen} />
                </TableBody>
              </Table>
            </TableContainer>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setCRMPhotoGalleries={setCRMPhotoGalleries}
                    customers={customers}
                    source={"Linear"}
                  />
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Photos;
