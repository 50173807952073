import React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import FormatPhone from "../Format/FormatPhone";

const PeopleList = (row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id) => {
  return CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === row?.people_id || CurrentPerson?.id === row?.people_id || CurrentPerson?.crm_customers_id === row?.id || (row?.__typename === "CRMTicket" && CurrentPerson?.id === row?.people_id)).map(CurrentPerson => {
    return (
      <>
        {isPeopleOpenCollapse === index && (
          <TableRow style={{ backgroundColor: "#F5F5F5" }}>
            <TableCell style={{ paddingTop: 10, paddingBottom: 10, textAlign: "center" }} colSpan={2}>
              <Collapse in={isPeopleOpenCollapse === index} timeout="auto" unmountOnExit style={{ backgroundColor: "#F5F5F5", width: "100%" }}>
                <div style={{ display: "inline-block", textAlign: "center" }}>
                  <div style={{ width: "175px", float: "left" }}><b>Name</b><br /> <a href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + CurrentPerson?.crm_customers_id + "&peopleid=" + CurrentPerson?.id}>{CurrentPerson?.first_name}&nbsp;{CurrentPerson?.last_name}</a></div>
                  <div style={{ width: "175px", float: "left" }}><b>Cell</b><br /> {FormatPhone(CurrentPerson?.cell_phone_number)}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br /> {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === CurrentPerson?.crm_customers_id).map(CurrentCustomer => { return <>{FormatPhone(CurrentCustomer?.office_phone_number)}</> })}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Address</b><br /> {CurrentPerson?.address_line_one} {CurrentPerson?.address_line_two} {CurrentPerson?.complete} {CurrentPerson?.state} {CurrentPerson?.zip_code} {CurrentPerson?.country}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Lead Status</b><br /> {leadStatuses?.filter((CurrentStatus) => CurrentStatus?.value === CurrentPerson?.lead_status).map(CurrentStatus => { return <>{CurrentStatus?.label}</> })}</div>
                  <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRep) => CurrentRep?.id === CurrentPerson?.crm_representative_id).map(CurrentRep => { return <>{CurrentRep?.first_name} {CurrentRep?.last_name}</> })}</div>
                </div>
              </Collapse>
            </TableCell>
          </TableRow >
        )}
      </>
    )
  })
};

export default PeopleList;
