import React from "react";
import Button from '@mui/material/Button';
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Tooltip from '@mui/material/Tooltip';

import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TaskOutlined from '@mui/icons-material/TaskOutlined';
import CloseIcon from '@mui/icons-material/Close';

import FormatPhone from "../Format/FormatPhone";

const SearchResults = (handleSearchClose, updateSearchALL, setShowAll, ShowAll, setShowCustomers, setShowLeads, setShowPeople, setShowJobs,
  setShowGalleries, setShowPhotos, setShowProducts, setShowServices, setShowContracts, setShowDocuments,
  setShowProposals, setShowEstimates, setShowTickets, CustomerSearchQuery, LeadSearchQuery, PeopleSearchQuery, JobSearchQuery,
  ProductSearchQuery, ServiceSearchQuery, GallerySearchQuery, DocumentSearchQuery, PhotosSearchQuery,
  TicketSearchQuery, EstimateSearchQuery, ProposalSearchQuery, ContractSearchQuery,
  ShowCustomers, ShowLeads, ShowPeople, ShowJobs, ShowGalleries, ShowPhotos, ShowProducts, ShowServices, ShowContracts,
  ShowDocuments, ShowProposals, ShowEstimates, ShowTickets,
  searchstring, CRMCustomersSearch, CRMLeadsSearch, CRMPeopleSearch, CRMJobSearch, CRMGallerySearch,
  CRMDocumentSearch, CRMContractSearch, CRMProposalSearch, CRMProductSearch, CRMServiceSearch, CRMTicketsSearch, CRMEstimatesSearch,
  CRMPhotosSearch, name, value, start, end, status, category, SKU, price, description, phone, address, officephone,
  email, type, service, setCRMCustomersSearch, setCRMLeadsSearch, setCRMPeopleSearch, setCRMJobSearch, setCRMGallerySearch,
  setCRMPhotosSearch, setCRMDocumentSearch, setCRMContractSearch, setCRMProposalSearch, setCRMProductSearch,
  setCRMServiceSearch, setCRMTicketsSearch, setCRMEstimatesSearch, Customerloading, Leadsloading, Peopleloading,
  Jobsloading, Galleryloading, Photosloading, Documentsloading, Contractsloading, Proposalsloading, Productsloading,
  Servicesloading, Ticketsloading, Estimatesloading, customer_id,
  handleProductOpen, handleServiceOpen, OpenPhotoEdit, B2B, CRMSettings) => {

  const Highlighted = ({ text = '', highlight = '' }) => {
    if (highlight === '' || text === '' || highlight === null || text === null)
      return
    if (!highlight.trim()) {
      return <span>{text}</span>
    }
    const regex = new RegExp(`(${_.escapeRegExp(highlight)})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <mark style={{ padding: "0px" }} key={i}>{part}</mark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  }

  return (
    <>
      {searchstring?.length > 1 ?
        <Card style={{ overflowY: "auto", margin: "20px", padding: "0px", width: "800px", border: "1px grey solid", position: "absolute", zIndex: 10, top: "50px", left: "300px", height: "640px" }}>
          <CardBody style={{ margin: "0px", padding: "0px" }}>
            <div style={{ float: "right" }}><Button onClick={handleSearchClose()}><CloseIcon /></Button></div>
            {/* left column */}
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ width: "20%", float: "left", backgroundColor: "#EEE", padding: "0px", flex: 1 }}>
                <div style={{ clear: "both", height: "20px" }} />
                <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <div style={{ color: ShowAll === true ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                    updateSearchALL();
                    setShowAll(true);
                    setShowCustomers(false);
                    setShowLeads(false);
                    setShowPeople(false);
                    setShowJobs(false);
                    setShowGalleries(false);
                    setShowPhotos(false);
                    setShowProducts(false);
                    setShowServices(false);
                    setShowContracts(false);
                    setShowDocuments(false);
                    setShowProposals(false);
                    setShowEstimates(false);
                    setShowTickets(false);
                  }}><Tooltip title="Show All" placement="top"><MenuBookOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Show All</b></div>
                  <div style={{ clear: "both", height: "20px" }} />
                  {B2B === "B2B" && (
                    <>
                      <div style={{ color: ShowCustomers ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(true);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        CustomerSearchQuery({
                          variables: {
                            customer_id,
                            address,
                            name,
                            phone,
                            page: 0,
                            rows_per_page: 100,
                            status: 1,
                            search: 1
                          }
                        })
                      }} >
                        <Tooltip title="Filter Customers" placement="top"><HomeWorkOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Customers</b></div>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowLeads ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(true);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        LeadSearchQuery({
                          variables: {
                            customer_id,
                            address,
                            name,
                            phone,
                            page: 0,
                            rows_per_page: 100,
                            status: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Leads" placement="top"><FlagOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Leads</b></div>
                      <div style={{ clear: "both", height: "20px" }} />
                    </>
                  )}
                  <div style={{ color: ShowPeople ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                    setShowAll(false);
                    setShowCustomers(false);
                    setShowLeads(false);
                    setShowPeople(true);
                    setShowJobs(false);
                    setShowGalleries(false);
                    setShowPhotos(false);
                    setShowProducts(false);
                    setShowServices(false);
                    setShowContracts(false);
                    setShowDocuments(false);
                    setShowProposals(false);
                    setShowEstimates(false);
                    setShowTickets(false);

                    setCRMCustomersSearch([]);
                    setCRMLeadsSearch([]);
                    setCRMJobSearch([]);
                    setCRMGallerySearch([]);
                    setCRMPhotosSearch([]);
                    setCRMDocumentSearch([]);
                    setCRMContractSearch([]);
                    setCRMProposalSearch([]);
                    setCRMProductSearch([]);
                    setCRMServiceSearch([]);
                    setCRMTicketsSearch([]);
                    setCRMEstimatesSearch([]);

                    PeopleSearchQuery({
                      variables: {
                        customer_id,
                        count: 0,
                        page: 0,
                        rows_per_page: 100,
                        cell_phone_number: phone,
                        name,
                        email,
                        address,
                        search: 1
                      }
                    })
                  }}><Tooltip title="Filter People" placement="top"><PermIdentityOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>People</b></div>
                  {CRMSettings?.length && !CRMSettings[0]?.hide_jobs && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />

                      <div style={{ color: ShowJobs ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(true);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        JobSearchQuery({
                          variables: {
                            customer_id,
                            count: 0,
                            type,
                            name,
                            service,
                            page: 0,
                            rows_per_page: 100,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Jobs" placement="top"><WorkOutlineOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Jobs</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_products && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowProducts ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(true);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        ProductSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            category,
                            SKU,
                            price,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Products" placement="top"><Inventory2OutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Products</b></div>
                    </>)}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_services && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowServices ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(true);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        ServiceSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 3,
                            name,
                            category,
                            SKU,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Services" placement="top"><HandymanOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Services</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_gallery && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowGalleries ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(true);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        GallerySearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            description,
                            count: 0,
                            search: 1
                          }
                        })
                      }}><Tooltip title="Filter Galleries" placement="top"><PhotoLibraryOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Photo Gallery</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_gallery && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowPhotos ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(true);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        PhotosSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            description,
                            count: 0,
                            search: 1
                          }
                        })
                      }}><Tooltip title="Filter Photos" placement="top"><CameraAltOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Photos</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_documents && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowDocuments ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(true);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        DocumentSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Documents" placement="top"><UploadFileOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Documents</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_estimates && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowEstimates ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowTickets(false);
                        setShowEstimates(true);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);

                        EstimateSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Estimates" placement="top"><TaskOutlined style={{ marginRight: "10px" }} /></Tooltip><b>Estimates</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_estimates && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowProposals ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(true);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        ProposalSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            value,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Proposals" placement="top"><DescriptionOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Proposals</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_contracts && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowContracts ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(true);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(false);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMTicketsSearch([]);
                        setCRMEstimatesSearch([]);

                        ContractSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            value,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Contracts" placement="top"><RequestPageOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Contracts</b></div>
                    </>
                  )}
                  {CRMSettings?.length && !CRMSettings[0]?.hide_tickets && (
                    <>
                      <div style={{ clear: "both", height: "20px" }} />
                      <div style={{ color: ShowTickets ? "#0071CE" : "#000", cursor: "pointer" }} onClick={() => {
                        setShowAll(false);
                        setShowCustomers(false);
                        setShowLeads(false);
                        setShowPeople(false);
                        setShowJobs(false);
                        setShowGalleries(false);
                        setShowPhotos(false);
                        setShowProducts(false);
                        setShowServices(false);
                        setShowContracts(false);
                        setShowDocuments(false);
                        setShowProposals(false);
                        setShowEstimates(false);
                        setShowTickets(true);

                        setCRMCustomersSearch([]);
                        setCRMLeadsSearch([]);
                        setCRMPeopleSearch([]);
                        setCRMJobSearch([]);
                        setCRMGallerySearch([]);
                        setCRMPhotosSearch([]);
                        setCRMDocumentSearch([]);
                        setCRMContractSearch([]);
                        setCRMProposalSearch([]);
                        setCRMProductSearch([]);
                        setCRMServiceSearch([]);
                        setCRMEstimatesSearch([]);

                        TicketSearchQuery({
                          variables: {
                            customer_id,
                            page: 0,
                            rows_per_page: 100,
                            name,
                            count: 0,
                            search: 1
                          }
                        })
                      }}>
                        <Tooltip title="Filter Tickets" placement="top"><ConfirmationNumberOutlinedIcon style={{ marginRight: "10px" }} /></Tooltip><b>Tickets</b></div>
                    </>
                  )}
                  <div style={{ clear: "both", height: "20px" }} />
                </div>
              </div>
              {/* right column */}
              <div style={{ width: "75%", float: "left", marginLeft: "20px", minHeight: "455px" }}>
                {CRMCustomersSearch?.map((CurrentCustomer, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Customers</b><br /><br /></div> : ""}
                      <div>
                        <a
                          href={"/admin/crmcustomerdetail?customer=" + customer_id + "&id=" + CurrentCustomer?.id}
                          rel="noopener noreferrer"
                        >
                          {CurrentCustomer?.profile_image ? <img height="24px" width="24px" src={CurrentCustomer?.profile_image} style={{ borderRadius: "50%" }} /> : <HomeWorkOutlinedIcon />} <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentCustomer?.business_name} highlight={name} /></font> <Highlighted text={FormatPhone(CurrentCustomer?.office_phone_number)} highlight={name} /><br />
                          <div style={{ marginLeft: "28px" }}><Highlighted text={CurrentCustomer?.office_address_line_one} highlight={name} /> <Highlighted text={CurrentCustomer?.office_address_line_two} highlight={name} />  <Highlighted text={CurrentCustomer?.office_city} highlight={name} /> <Highlighted text={CurrentCustomer?.office_state} highlight={name} />  | Rep: <Highlighted text={CurrentCustomer?.first_name} highlight={name} /> <Highlighted text={CurrentCustomer?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMLeadsSearch?.map((CurrentCustomer, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Leads</b><br /><br /></div> : ""}
                      <div>
                        <a
                          href={"/admin/crmleaddetail?customer=" + customer_id + "&id=" + CurrentCustomer?.id}
                          rel="noopener noreferrer"
                        >
                          {CurrentCustomer?.profile_image ? <img height="24px" width="24px" src={CurrentCustomer?.profile_image} style={{ borderRadius: "50%" }} /> : <FlagOutlinedIcon />} <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentCustomer?.business_name} highlight={name} /></font> <Highlighted text={FormatPhone(CurrentCustomer?.office_phone_number)} highlight={name} /><br />
                          <div style={{ marginLeft: "28px" }}><Highlighted text={CurrentCustomer?.office_address_line_one} highlight={name} /> <Highlighted text={CurrentCustomer?.office_address_line_two} highlight={name} />  <Highlighted text={CurrentCustomer?.office_city} highlight={name} /> <Highlighted text={CurrentCustomer?.office_state} highlight={name} />  | Rep: <Highlighted text={CurrentCustomer?.first_name} highlight={name} /> <Highlighted text={CurrentCustomer?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMPeopleSearch?.map((CurrentPerson, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>People</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href={"/admin/crmpersondetail?customer=" + customer_id + "&id=" + CurrentPerson?.crm_customers_id + "&peopleid=" + CurrentPerson?.id}
                          rel="noopener noreferrer"
                        >
                          {CurrentPerson?.profile_image ? <img height="24px" width="24px" src={CurrentPerson?.profile_image} style={{ borderRadius: "50%" }} /> : <PermIdentityOutlinedIcon />} <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentPerson?.first_name} highlight={name} /> <Highlighted text={CurrentPerson?.last_name} highlight={name} /></font> <Highlighted text={FormatPhone(CurrentPerson?.cell_phone_number)} highlight={name} /> <Highlighted text={CurrentPerson?.email} highlight={name} /><br />
                          <div style={{ marginLeft: "28px" }}><Highlighted text={CurrentPerson?.address_line_one} highlight={name} /> <Highlighted text={CurrentPerson?.address_line_two} highlight={name} />  <Highlighted text={CurrentPerson?.city} highlight={name} /> <Highlighted text={CurrentPerson?.state} highlight={name} />  | Rep: <Highlighted text={CurrentPerson?.rep_first_name} highlight={name} /> <Highlighted text={CurrentPerson?.rep_last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_jobs && CRMJobSearch?.map((CurrentJob, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Jobs</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href={"/admin/crmjobdetail?customer=" + customer_id + "&id=" + CurrentJob?.crm_customers_id + "&peopleid=" + CurrentJob?.people_id + "&jobid=" + CurrentJob?.id}
                          rel="noopener noreferrer"
                        >
                          <WorkOutlineOutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentJob?.job_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>$<Highlighted text={CurrentJob?.job_value} highlight={name} /> | Rep: <Highlighted text={CurrentJob?.first_name} highlight={name} /> <Highlighted text={CurrentJob?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_products && CRMProductSearch?.map((CurrentProduct, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Products</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleProductOpen(CurrentProduct);
                          }}
                        >
                          <Inventory2OutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentProduct?.product_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>Category: <Highlighted text={CurrentProduct?.category_text} highlight={name} /> | SKU: <Highlighted text={CurrentProduct?.product_SKU} highlight={name} /> | $: <Highlighted text={CurrentProduct?.product_price} highlight={name} /> </div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_services && CRMServiceSearch?.map((CurrentService, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Services</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href="#"
                          rel="noopener noreferrer"
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleServiceOpen(CurrentService);
                          }}
                        >
                          <HandymanOutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentService?.service_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>Category: <Highlighted text={CurrentService?.category_text} highlight={name} /> | SKU: <Highlighted text={CurrentService?.service_sku} highlight={name} /> | $: <Highlighted text={CurrentService?.service_price} highlight={name} /> </div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_gallery && CRMGallerySearch?.map((CurrentGallery, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Photo Gallery</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href={"/admin/crmphotodetail?customer=" + customer_id + "&id=" + CurrentGallery?.crm_customers_id + "&peopleid=" + CurrentGallery?.people_id + "&photoid=" + CurrentGallery?.id}
                          rel="noopener noreferrer"
                        >
                          {CurrentGallery?.photo_gallery_url ? <img height="24px" width="24px" src={CurrentGallery?.photo_gallery_url} style={{ borderRadius: "50%" }} /> : <PhotoLibraryOutlinedIcon />} <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentGallery?.photo_gallery_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>| Rep: <Highlighted text={CurrentGallery?.first_name} highlight={name} /> <Highlighted text={CurrentGallery?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_gallery && CRMPhotosSearch?.map((CurrentPhoto, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Photos</b><br /><br /></div> : ""}

                      <div>
                        <a
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            OpenPhotoEdit(CurrentPhoto?.id);
                          }}
                          href={"#"}
                          rel="noopener noreferrer"
                        >
                          {CurrentPhoto?.photo_url ? <img height="24px" width="24px" src={CurrentPhoto?.photo_url} style={{ borderRadius: "50%" }} /> : <CameraAltOutlinedIcon />} <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentPhoto?.photo_name} highlight={name} /></font><br />
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_documents && CRMDocumentSearch?.map((CurrentDocument, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Documents</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href={"/admin/crmdocumentdetail?customer=" + customer_id + "&id=" + CurrentDocument?.crm_customers_id + "&peopleid=" + CurrentDocument?.people_id + "&documentid=" + CurrentDocument?.id}
                          rel="noopener noreferrer"
                        >
                          <UploadFileOutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentDocument?.documents_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>| Rep: <Highlighted text={CurrentDocument?.first_name} highlight={name} /> <Highlighted text={CurrentDocument?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_estimates && CRMEstimatesSearch?.map((CurrentEstimate, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Estimates</b><br /><br /></div> : ""}
                      <div>
                        <a
                          href={"/admin/crmestimatedetail?customer=" + customer_id + "&id=" + CurrentEstimate?.crm_customers_id + "&peopleid=" + CurrentEstimate?.people_id + "&estimateid=" + CurrentEstimate?.id}
                          rel="noopener noreferrer"
                        >
                          <TaskOutlined /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentEstimate?.estimate_name} highlight={name} /></font>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_proposals && CRMProposalSearch?.map((CurrentProposal, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Proposals</b><br /><br /></div> : ""}
                      <div>
                        <a
                          href={"/admin/crmproposaldetail?customer=" + customer_id + "&id=" + CurrentProposal?.crm_customers_id + "&peopleid=" + CurrentProposal?.people_id + "&proposalid=" + CurrentProposal?.id}
                          rel="noopener noreferrer"
                        >
                          <DescriptionOutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentProposal?.proposal_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>$<Highlighted text={CurrentProposal?.proposal_value} highlight={name} /> | Rep: <Highlighted text={CurrentProposal?.first_name} highlight={name} /> <Highlighted text={CurrentProposal?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_contracts && CRMContractSearch?.map((CurrentContract, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Contracts</b><br /><br /></div> : ""}

                      <div>
                        <a
                          href={"/admin/crmcontractdetail?customer=" + customer_id + "&id=" + CurrentContract?.crm_customers_id + "&peopleid=" + CurrentContract?.people_id + "&contractid=" + CurrentContract?.id}
                          rel="noopener noreferrer"
                        >
                          <RequestPageOutlinedIcon /><font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentContract?.contract_name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}>$<Highlighted text={CurrentContract?.contract_value} highlight={name} /> | Rep: <Highlighted text={CurrentContract?.first_name} highlight={name} /> <Highlighted text={CurrentContract?.last_name} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {CRMSettings?.length && !CRMSettings[0]?.hide_tickets && CRMTicketsSearch?.map((CurrentTicket, index) => {
                  return (
                    <>
                      {index === 0 ? <div><br /><b>Tickets</b><br /><br /></div> : ""}
                      <div>
                        <a
                          href={"/admin/crmticketdetail?customer=" + customer_id + "&id=" + CurrentTicket?.crm_customers_id + "&peopleid=" + CurrentTicket?.people_id + "&ticketid=" + CurrentTicket?.id}
                          rel="noopener noreferrer"
                        >
                          <ConfirmationNumberOutlinedIcon /> <font style={{ fontSize: "15px", fontWeight: "bold" }} ><Highlighted text={CurrentTicket?.name} highlight={name} /></font><br />
                          <div style={{ marginLeft: "28px" }}><Highlighted text={`ID: ${CurrentTicket?.id}`} highlight={name} /></div>
                          <div style={{ clear: "both", height: "15px" }} />
                        </a>
                      </div>
                    </>
                  )
                })}
                {(searchstring?.length > 1 && CRMCustomersSearch?.length === 0 && CRMLeadsSearch?.length === 0 && CRMPeopleSearch?.length === 0 && CRMJobSearch?.length === 0 && CRMGallerySearch?.length === 0
                  && CRMDocumentSearch?.length === 0 && CRMContractSearch?.length === 0 && CRMProposalSearch?.length === 0 && CRMProductSearch?.length === 0
                  && CRMServiceSearch?.length === 0 && CRMTicketsSearch?.length === 0 && CRMEstimatesSearch?.length === 0 && CRMPhotosSearch?.length === 0 && !Customerloading
                  && !Leadsloading && !Peopleloading && !Jobsloading && !Galleryloading && !Photosloading && !Documentsloading && !Contractsloading
                  && !Proposalsloading && !Productsloading && !Servicesloading &&
                  !Ticketsloading && !Estimatesloading) ?
                  <>
                    <div style={{ clear: "both", height: "15px" }} />
                    <div style={{ fontSize: "22px", fontFamily: 'Inter' }}>  No Results</div>
                  </>
                  : ""
                }
              </div>
            </div>
          </CardBody>
        </Card >
        : ""}
    </>
  )
};

export default SearchResults;
