import React from "react";
import { API_URL } from "config";

import {
  GET_HYDRATE_TEXT,
  UPDATE_HYDRATE_TEXT,
  GET_HYDRATE_MMS,
  UPDATE_HYDRATE_MMS,
  GET_HYDRATE_VOICE_MESSAGE,
  UPDATE_HYDRATE_VOICE_MESSAGE,
  GET_HYDRATE_EMAIL,
  UPDATE_HYDRATE_EMAIL,
  GET_HYDRATE_LETTER,
  UPDATE_HYDRATE_LETTER,
  GET_HYDRATE_POSTCARD,
  UPDATE_HYDRATE_POSTCARD
} from "queries/hydrate";

export default [
  {
    key: "sms_text",
    title: "Text",
    getQuery: GET_HYDRATE_TEXT,
    getQueryKey: "getHydrateText",
    updateQuery: UPDATE_HYDRATE_TEXT,
    hasPlaceholders: true,
    fields: [
      {
        name: "sms_message_1",
        label: "Message",
        type: "long-text-input",
        maxLength: 1190,
        required: true,
        parts: [160, 306, 459, 612, 765, 918, 1071, 1224],
        append: 26
      }
    ]
  },
  {
    key: "mms_text",
    title: "MMS",
    getQuery: GET_HYDRATE_MMS,
    getQueryKey: "getHydrateMMS",
    updateQuery: UPDATE_HYDRATE_MMS,
    hasPlaceholders: true,
    help: (
      <>
        You can send a single attachment with a size of up to 250 kB. * Some older devices can only accept attachments with up to 30 kB.
      </>
    ),
    fields: [
      {
        name: "body",
        label: "MMS Message",
        type: "long-text-input",
        maxLength: 1500,
        required: true
      },
      {
        name: "media_file",
        label: "View File",
        type: "image",
        maxSize: 250000,
        sizeHelper: "Please upload an image smaller than 250 kB."
      }
    ]
  },
  {
    key: "voice_message",
    title: "Voice Message",
    getQuery: GET_HYDRATE_VOICE_MESSAGE,
    getQueryKey: "getHydrateVoiceMessage",
    updateQuery: UPDATE_HYDRATE_VOICE_MESSAGE,
    hasPlaceholders: false,
    fields: [
      {
        name: "voice_message_url",
        label: "Voice Message",
      },
    ]
  },
  {
    key: "letter",
    title: "Letter",
    getQuery: GET_HYDRATE_LETTER,
    getQueryKey: "getHydrateLetter",
    updateQuery: UPDATE_HYDRATE_LETTER,
    help: (
      <>
        PDF Specifications<br />
        <br />
        All your fonts are embedded.<br />
        The PDF is PDF/A compliant.<br />
        The PDF should be flattened.<br />
        The file should be in A4 paper size.<br />
        All pages should be in portrait orientation.<br />
        The File size is 10 MB max<br />
        <br />
        <b>Envelopes</b><br />
        For small letters (4 pages simplex / 8 pages duplex) we use DLX (tri-fold windowed envelope).<br />
        For large letters (5+ pages simplex / 9+ pages duplex) we use C4 (flat windowed envelope with no folds).<br />
      </>
    ),
    fields: [
      {
        name: "priority_post",
        label: "Priority Post",
        type: "select",
        options: [{ value: 1, label: "Yes" }, { value: 0, label: "No" }]
      },
      {
        name: "letter_two_sided",
        label: "Letter Two Sided",
        type: "select",
        options: [{ value: 1, label: "Yes" }, { value: 0, label: "No" }]
      },
      {
        name: "letter_color",
        label: "Color",
        type: "select",
        options: [{ value: 1, label: "Yes" }, { value: 0, label: "No" }]
      },
      {
        name: "letter_file_url",
        label: "View Letter",
        type: "link-input",
        accept:"application/pdf",
        maxSize: 10000000,
        sizeHelper: "Please upload an image smaller than 10 MB."
      }
    ]
  },
  {
    key: "email",
    title: "Email",
    getQuery: GET_HYDRATE_EMAIL,
    getQueryKey: "getHydrateEmail",
    updateQuery: UPDATE_HYDRATE_EMAIL,
    hasPlaceholders: true,
    fields: [
      {
        name: "subject",
        label: "Subject",
        maxLength: 145,
        required: true
      },
      {
        name: "body",
        label: "Body",
        type: "long-text-input",
        maxLength: 1500,
        required: true
      }
    ]
  },
  {
    key: "postcard",
    title: "Postcard",
    getQuery: GET_HYDRATE_POSTCARD,
    getQueryKey: "getHydratePostcard",
    updateQuery: UPDATE_HYDRATE_POSTCARD,
    help: (
      <>
        A postcard is 2 sided (front and back), gloss, and 300 gsm. The back is automatically stamped with the recipient's address.<br/>
        <br/>
        If you only supply 1 page, we will put this on the front. The back will be stamped with the address.<br/>
        <br/>
        File formats<br/>
        We support the following formats:<br/>
        <br/>
        PDF (Required*)<br/>
        <br/>
        Artwork size<br/>
        The postcard size should be A5 (210 mm x 148 mm or 8.3 x 5.8 inches) with a 5 mm bleed and no border.<br/>
        <br/>
        The first page is the front. The second page is the back. If you only supply 1 page, we will put this on the front. The back will be stamped with the address.<br/>
        Failure to adhere to these specifications may result in the letter being canceled or charged at a higher rate for us to adjust the document.
      </>
    ),
    actions: (
      <h4>
        <a href={`${API_URL}/postcard-template`}>
          Download Template
        </a>
      </h4>
    ),
    fields: [
      {
        name: "file_url",
        label: "View Postcard",
        type: "link-input",
        accept: "application/pdf"
      }
    ]
  }
];
