/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";


import { makeStyles } from "@mui/styles";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

import WbmOrangeLogo from "assets/img/WBM2_Whitenav bar.png";

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <img src={WbmOrangeLogo} alt="logo"/><br />
      © {1900 + new Date().getYear()} Water Bear Marketing<br />
      Advertising With Intelligence®, GeoDemo®, GeoIntetion®, ReEngage®, AdPantry®<br />
      are Registered Trademarks of Water Bear Marketing LTD Cleveland, Ohio.
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};
