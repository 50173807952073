import React from "react";
import { makeStyles } from "@mui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faAddressBook,
  faMugHot,
  faChild,
  faFire
} from "@fortawesome/free-solid-svg-icons";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import AdvertisingConsultant from "views/Dashboard/AdConsultant";
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

import "./index.scss";

const useStyles = makeStyles(styles);

const features = [
  {
    title: "Transparency",
    description:
      "With our reporting dashboard, you get 24/7 access to your campaigns in one place.",
    icon: faDoorOpen
  },
  {
    title: "Proprietary Targeting",
    description:
      "We leverage our Advertising With Intelligence™ technology to get our clients the best results for their advertising dollars.",
    icon: faAddressBook
  },
  {
    title: "Experience",
    description:
      "Our team of highly skilled water bears has decades of combined experience. We have run thousands of ad campaigns in almost every vertical imaginable.",
    icon: faMugHot
  },
  {
    title: "Dedicated Consultant",
    description:
      "Work with an Advertising Consultant from beginning to end. They continuously collaborate with a Campaign Consultant to ensure maximum results. We do not transfer you from one person to the next.",
    icon: faChild
  },
  {
    title: "Proven Results",
    description:
      "We have managed thousands of ad campaigns across almost every type of industry. We are good at what we do and have the data to back it up.",
    icon: faFire
  }
];

const NoContent = ({ product, isAn, title, children, loading }) => {
  const classes = useStyles();
  if (loading) {
    return (
      <div>
        <h2 className={classes.pageHeader}>{title || product}</h2>
        <h4>Loading ...</h4>
      </div>
    );
  }
  return (
    <div>
      <h2 className={classes.pageHeader}>{title || product}</h2>
      <h4 style={{ fontSize: 30, fontWeight: "bold", color: "black" }}>
        { product === "LinearCRM" ?          
          `It appears that you do not have ${product}`
          :
          `It appears that you do not have ${isAn ? "an" : "a"} ${product} campaign.`}
      </h4>
      {children && (
        <h4
          style={{
            padding: 30,
            border: "1px solid rgba(0, 0, 0, 0.1)",
            whiteSpace: "pre-line"
          }}
        >
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </h4>
      )}
      <div className="why-waterbear-marketing">
        <div className="leading">WHY WATER BEAR MARKETING?</div>
        <div className="features-container">
          {features.map(({ title, description, icon }) => (
            <div className="feature" key={title}>
              <div className="icon-wrapper">
                <FontAwesomeIcon icon={icon} />
              </div>
              <div className="feature-title">{title}</div>
              <div className="feature-description">{description}</div>
            </div>
          ))}
        </div>
      </div>
      <AdvertisingConsultant title="Contact Your Advertising Consultant" />
    </div>
  );
};

export default NoContent;
