import React from "react";
import ReactTable from "react-table";

const filterMethod = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true;
};

const filterRender = ({ filter, onChange }) => (
  <input
    type="text"
    placeholder="Search"
    value={filter ? filter.value : ""}
    onChange={(event) => onChange(event.target.value)}
  />
);

const CustomReactTable = ({ columns, CustomStyle, ...props }) => {
  columns[0] = {
    ...columns[0],
    Filter: filterRender,
  };
  return (
    <ReactTable
      columns={columns}
      defaultFilterMethod={filterMethod}
      style={CustomStyle}
      {...props}
    />
  );
};

export default CustomReactTable;
