import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useQuery, useMutation } from "@apollo/client";
import { omit } from "lodash";
import { useOutletContext } from 'react-router-dom';


import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CustomInput from "components/CustomInput/CustomInput";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Dropzone from "components/Dropzone";
import Avatar from "components/Avatar";
import CardAvatar from "components/Card/CardAvatar";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import { useSnackbar } from "notistack";
import PropTypes from 'prop-types';

import { READ_ME } from "queries/users";
import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";

const moment = require("moment");

import {
  UPDATE_CUSTOMER_EMAIL,
  CRM_CUSTOMERS,
  UPDATE_CRM_ACTIVITY,
  UPDATE_CRM_CUSTOMERS,
  LIST_PRODUCT,
  GET_PRODUCT_CATEGORIES,
  GET_STATES,
  GET_COUNTRIES,
  GET_POSTAL_CODE,
  GET_CITIES
} from "queries/customers";
import { Context } from "../../../redux/store";

const filter = createFilterOptions({
  matchFrom: 'start',
  limit: 10
});

import FormatPhone from "../Format/FormatPhone";

const styles = theme => ({
  stepIcon: {
    color: "#0071ce"
  },
  sidebyside: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  paper: { minWidth: "60%" },
  cursor: { cursor: 'pointer' }
});

const leadQualities = [
  { label: "Good", value: 1 },
  { label: "Bad", value: 2 },
  { label: "Other", value: 3 }
];

const useStyles = makeStyles(styles);

const listNames = [
  "Water Bear Marketing",
  "Employment",
  "From Website",
  "From CMS/ERP",
  "Purchased",
  "Tradeshows",
  "Database",
  "Lead Generation",
  "Walk-In",
  "Customize"
].map(v => ({
  label: v,
  value: v
}));

const UpdateCustomerProfile = ({ handleClose, setCustomers, customers, current, TrackedLead = 0, source, crm_customer_id }) => {
  const { LoadNewNotifications } = useOutletContext();
  const [state] = useContext(Context);
  const [uploadPercentage, setuploadPercentage] = useState("");
  const me = useQuery(READ_ME)?.data?.readMe;
  const classes = useStyles(styles);
  const [activeStep, setActiveStep] = useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const steps = getSteps();
  const [submissionData, setSubmissionData] = useState(null);
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [BusinessData, setBusinessData] = useState(null);
  const [CategoryData, setCategoryData] = useState(null);
  const [ProductData, setProductData] = useState(null);
  const [States, setStates] = useState(null);
  const [Countries, setCountries] = useState(null);
  const [PostalCode, setPostalCode] = useState(null);
  const [City, setCity] = useState(null);

  function getSteps() {
    if (source === "Linear" && crm_customer_id)
      return ['Person Info', 'Personal Address'];
    else if (source === "Linear")
      return ['Person Info', 'Personal Address', 'Business Address'];
    else
      return ['Person Info', 'Personal Address', 'Business Address', 'Preferences', 'Sales Funnel'];
  }


  const customer_id = state.customers?.selected?.id;

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  const [submitUpdateCRMActivity] = useMutation(UPDATE_CRM_ACTIVITY,
    {
      onCompleted: () => {
        LoadNewNotifications();
      }
    });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  const { data: Category_Data } = useQuery(GET_PRODUCT_CATEGORIES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  const { data: Product_Data } = useQuery(LIST_PRODUCT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: States_data } = useQuery(GET_STATES);
  let { data: Country_data } = useQuery(GET_COUNTRIES);
  useQuery(GET_POSTAL_CODE,
    {
      skip: !BusinessData?.office_zip,
      variables: {
        name: BusinessData?.office_zip
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !submissionData?.city,
      variables: {
        name: submissionData?.city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  useQuery(GET_POSTAL_CODE,
    {
      skip: !submissionData?.zip_code,
      variables: {
        name: submissionData?.zip_code
      },
      onCompleted: (data) => {
        setPostalCode(data?.getPostalCode || []);
      }
    }
  );

  useQuery(GET_CITIES,
    {
      skip: !BusinessData?.office_city,
      variables: {
        name: BusinessData?.office_city
      },
      onCompleted: (data) => {
        setCity(data?.getCity || []);
      }
    }
  );

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  const updateFieldHandler = (key, convert) => e => {
    setSubmissionData({
      ...submissionData,
      [key]: convert ? convert(e.target.value) : e.target.value
    });
  };

  const updateBusinessFieldHandler = (key, convert) => e => {
    if (key === "status") {
      setBusinessData({
        ...BusinessData,
        [key]: e.target.checked === true ? 1 : 0
      });
    }
    else {
      setBusinessData({
        ...BusinessData,
        [key]: convert ? convert(e.target.value) : e.target.value
      });
    }
  };

  const updateBusinessNameFieldHandler = (key, value) => {
    setBusinessData({ [key]: value });

    CRMCustomers?.forEach((CurrentCustomer) => {
      if (CurrentCustomer?.business_name === value)
        setBusinessData(CurrentCustomer);
    })
  };

  useEffect(() => {
    setCRMCustomers(crm_data?.CRMCustomers || []);

    setStates(States_data?.getStates || []);
    setCountries(Country_data?.getCountries || []);

    CRMCustomers?.forEach((CurrentCustomer) => {
      if (CurrentCustomer?.id === crm_customer_id)
        setBusinessData(CurrentCustomer);
    })
  }, [crm_data, CRMCustomers]);

  useEffect(() => {
    if (current) {
      setSubmissionData(omit(current, ["office_phone_number", "office_phone_number", "office_address_line_one", "office_address_line_two",
        "office_city", "office_state", "office_zip", "office_country", "business_name"]));
    }
  }, [ProductData, CategoryData]);

  const updateCountryFieldHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updateStateHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updatePostalCodeHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updateCityHandler = (key, e) => {
    setBusinessData({
      ...BusinessData,
      [key]: e
    });
  };

  const updatePersonCountryFieldHandler = (key, e) => {
    setSubmissionData({
      ...submissionData,
      [key]: e
    });
  };

  const updatePersonStateHandler = (key, e) => {
    setSubmissionData({
      ...submissionData,
      [key]: e
    });
  };

  const updatePersonPostalCodeHandler = (key, e) => {
    setSubmissionData({
      ...submissionData,
      [key]: e
    });
  };

  const updatePersonCityHandler = (key, e) => {
    setSubmissionData({
      ...submissionData,
      [key]: e
    });
  };

  const handleSave = async (fromgotoStep) => {
    if (activeStep === 0) {
      if (submissionData?.first_name === undefined || submissionData?.first_name === "") {
        alert("First Name is required");
        return false;
      }
      if (submissionData?.last_name === undefined || submissionData?.last_name === "") {
        alert("Last Name is required");
        return false;
      }
      if ((submissionData?.cell_phone_number === undefined || submissionData?.cell_phone_number === "") &&
        (submissionData?.email === undefined || submissionData?.email === "")) {
        alert("Phone or Email is required");
        return false;
      }
    }

    if (activeStep === 2) {
      if (BusinessData && BusinessData?.business_name !== undefined && BusinessData?.business_name !== "" &&
        (BusinessData?.office_phone_number === undefined || BusinessData?.office_phone_number === "")) {
        alert("Office Phone Number is required");
        return false;
      }
    }

    //Check if BusinessData date has changes or adding a new business and set new crm_customers_id: crm_customers_id
    if (BusinessData) {
      await submitUpdateCRMRequest({
        variables: {
          customer_id,
          BusinessData: {
            ...omit(BusinessData, ["__typename", "update_time", "first_name", "last_name", "status", "revenue", "lead_status"]),
            office_phone_number: `${BusinessData?.office_phone_number !== undefined ? BusinessData?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            revenue: CRMCustomers?.revenue ? CRMCustomers?.revenue?.replace(/[^0-9.]+/g, "") : "",
            people_id: submissionData?.id ? submissionData?.id : null,
            status: BusinessData?.status ? `${BusinessData?.status}` : "0",
            lead_status: BusinessData?.lead_status && typeof BusinessData?.lead_status === 'string' ? parseInt(LeadStatus_data.filter((CurrentLeadStatus) => CurrentLeadStatus?.lead_status_text === BusinessData?.lead_status)?.map((CurrentLeadStatus) => { return CurrentLeadStatus?.lead_status })?.join("")) : BusinessData?.lead_status ? BusinessData?.lead_status : null
          }
        }
      });
    } else {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(submissionData, ["__typename", "products", "categories", "count"]),
            cell_phone_number: `${submissionData?.cell_phone_number !== undefined ? submissionData?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            products: submissionData?.products?.length && typeof current?.products !== 'string' ? submissionData?.products?.map((SelectedProduct) => { const ReturnedValue = ProductData?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : (current?.products !== null && typeof current?.products !== 'string') ? current?.products : "",
            categories: submissionData?.categories?.length && typeof current?.categories !== 'string' ? submissionData?.categories?.map((SelectedCategory) => { const ReturnedValue = CategoryData?.map((CurrentCategory) => { if (SelectedCategory === CurrentCategory?.category_text) { return CurrentCategory?.id } })?.filter(CurrentCategory => CurrentCategory !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : (current?.categories !== null && typeof current?.categories !== 'string') ? current?.categories : "",
            crm_customers_id: crm_customer_id
          },
          TrackedLead: TrackedLead,
        }
      });
    }

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    if (fromgotoStep === false) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    return true;
  };

  const gotoStep = async (index = -1) => {
    if (await handleSave(true) === true && index != -1)
      setActiveStep(index);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [submitUpdateRequest] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: async (CustomerEmail) => {
        if (CustomerEmail?.updateCustomerEmail?.created === 1) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                users_id: me?.id,
                people_id: CustomerEmail?.updateCustomerEmail?.id,
                activity_type: "Person",
                activity_description: "Created Person " + CustomerEmail?.updateCustomerEmail?.first_name + " " + CustomerEmail?.updateCustomerEmail?.last_name,
                representative_id: CustomerEmail?.updateCustomerEmail?.representative_id,
                crm_customers_id: crm_customer_id ? crm_customer_id : null,
                activity_date: moment().format("YYYY-MM-DD hh:mm:ss"),
              }
            }
          });
        }
      }
    }
  );

  const [submitUpdateCRMRequest] = useMutation(
    UPDATE_CRM_CUSTOMERS,
    {
      fetchPolicy: "no-cache",
      onCompleted: async (CRMCustomer) => {
        if (CRMCustomer?.updateCRMCustomers?.created === 1) {
          await submitUpdateCRMActivity({
            variables: {
              customer_id,
              ActivityData: {
                users_id: me?.id,
                activity_type: CRMCustomer?.updateCRMCustomers?.status ? "Customer" : "Lead",
                activity_description: CRMCustomer?.updateCRMCustomers?.status ? "Created Customer " + CRMCustomer?.updateCRMCustomers?.business_name : "Created Lead " + CRMCustomer?.updateCRMCustomers?.business_name,
                representative_id: CRMCustomer?.updateCRMCustomers?.representative_id,
                crm_customers_id: CRMCustomer?.updateCRMCustomers?.id,
                activity_date: moment().format("YYYY-MM-DD hh:mm:ss"),
              }
            }
          });
        }

        await submitUpdateRequest({
          variables: {
            customer_id,
            customerEmail: {
              ...omit(submissionData, ["__typename", "products", "categories", "count"]),
              "crm_customers_id": CRMCustomer?.updateCRMCustomers.id,
              cell_phone_number: `${submissionData?.cell_phone_number !== undefined ? submissionData?.cell_phone_number : ""}`
                ?.replaceAll("(", "")
                ?.replaceAll(")", "")
                ?.replaceAll(" ", "")
                ?.split("-")
                .join(""),
              products: submissionData?.products?.length && typeof current?.products !== 'string' ? submissionData?.products?.map((SelectedProduct) => { const ReturnedValue = ProductData?.map((CurrentProduct) => { if (SelectedProduct === CurrentProduct?.product_name) { return CurrentProduct?.id } })?.filter(CurrentProduct => CurrentProduct !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : (current?.products !== null && typeof current?.products !== 'string') ? current?.products : "",
              categories: submissionData?.categories?.length && typeof current?.categories !== 'string' ? submissionData?.categories?.map((SelectedCategory) => { const ReturnedValue = CategoryData?.map((CurrentCategory) => { if (SelectedCategory === CurrentCategory?.category_text) { return CurrentCategory?.id } })?.filter(CurrentCategory => CurrentCategory !== undefined); if (ReturnedValue) { return ReturnedValue; } })?.join(",") : (current?.categories !== null && typeof current?.categories !== 'string') ? current?.categories : "",
            },
            TrackedLead: TrackedLead
          }
        });
      }
    }
  );

  return (
    <div style={{ minWidth: "60%" }}>
      <Dialog open onClose={handleClose()} classes={{ paper: classes.paper }}>
        <DialogContent>
          <div style={{ float: "right" }}><Button onClick={handleClose()}><CloseIcon /></Button></div>
          <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><b>{submissionData?.first_name || current?.first_name || ""}&nbsp;{submissionData?.last_name || current?.last_name || ""}</b></h2>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} classes={{ root: classes.cursor }}>
                <StepLabel StepIconProps={{ classes: { root: classes.sidebyside, root: classes.cursor } }} classes={{ root: classes.cursor }} onClick={() => gotoStep(index)}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length && (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleClose()}>Close</Button>
              </div>
            )}
            {activeStep === 0 && (
              <>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "30px" }}>
                    <Card profile>
                      <CardAvatar profile>
                        <Dropzone
                          path={`customer_creatives/assets/${customer_id}/${new Date().getTime()}`}
                          accept={{
                            'image/jpeg': [".jpeg"],
                            'image/png': [".png"]
                          }}
                          onChange={value => updateFieldHandler("profile_image")({ target: { value } })}
                          setuploadPercentage={setuploadPercentage}
                        >
                          {() => (
                            <Avatar
                              url={submissionData?.profile_image || current?.profile_image || ""}
                              size={130}
                            />
                          )}
                        </Dropzone>
                      </CardAvatar>
                      <CardBody>
                        <h6 className={classes.cardCategory}>
                          Ideal image upload size is 400 x 400
                        </h6>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}
                    >First Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="first_name"
                      maxLength={45}
                      type="text"
                      defaultValue={submissionData?.first_name || current?.first_name || ""}
                      onChange={updateFieldHandler("first_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Last Name&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="last_name"
                      maxLength={45}
                      type="text"
                      defaultValue={submissionData?.last_name || current?.last_name || ""}
                      onChange={updateFieldHandler("last_name")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Email&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="email"
                      maxLength={155}
                      type="text"
                      defaultValue={submissionData?.email || current?.email || ""}
                      onChange={updateFieldHandler("email")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Phone&nbsp;<div style={{ color: "red", float: "right" }}>*</div></label>
                    <input
                      id="cell_phone_number"
                      type={"string"}
                      maxLength={20}
                      value={submissionData?.cell_phone_number || ""}
                      onChange={updateFieldHandler("cell_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                      onBlur={() => {
                        setSubmissionData({
                          ...submissionData,
                          ["cell_phone_number"]: FormatPhone(submissionData?.cell_phone_number)
                        });
                      }}
                      onFocus={() => {
                        setSubmissionData({
                          ...submissionData,
                          ["cell_phone_number"]: submissionData?.cell_phone_number?.replace(/[^0-9]/g, "")
                        });
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave(false) }}>
                    Save
                  </Button>
                </div>
              </>

            )}
            {activeStep === 1 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Address Line 1</label>
                    <input
                      id="address_line_one"
                      maxLength={255}
                      type="text"
                      defaultValue={submissionData?.address_line_one || current?.address_line_one || ""}
                      onChange={updateFieldHandler("address_line_one")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Address Line 2</label>
                    <input
                      id="address_line_two"
                      maxLength={255}
                      type="text"
                      defaultValue={submissionData?.address_line_two || current?.address_line_two || ""}
                      onChange={updateFieldHandler("address_line_two")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal City</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="city"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePersonCityHandler("city", newValue.key);
                        }}
                        value={submissionData?.city ? { key: submissionData?.city, label: submissionData?.city } :
                          current?.city ? { key: current?.city, label: current?.city } :
                            { key: "", label: "" }}
                        options={City?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("city")(e); }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal State</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="state"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePersonStateHandler("state", newValue.key);
                        }}
                        value={submissionData?.state ? { key: submissionData?.state, label: submissionData?.state } :
                          current?.state ? { key: current?.state, label: current?.state } :
                            { key: "", label: "" }}
                        options={States?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("state")(e); } }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Zip Code</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="zip_code"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePersonPostalCodeHandler("zip_code", newValue.key);
                        }}
                        value={submissionData?.zip_code ? { key: submissionData?.zip_code, label: submissionData?.zip_code } :
                          current?.zip_code ? { key: current?.zip_code, label: current?.zip_code } :
                            { key: "", label: "" }}
                        options={PostalCode?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateFieldHandler("zip_code")(e); }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Personal Country</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="country"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePersonCountryFieldHandler("country", newValue.key);
                        }}
                        value={submissionData?.country ? { key: submissionData?.country, label: submissionData?.country } :
                          current?.country ? { key: current?.country, label: current?.country } :
                            { key: "", label: "" }}
                        options={Countries?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateFieldHandler("country")(e); } }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave(false) }}>
                    Save
                  </Button>
                </div>
              </>
            )}
            {activeStep === 2 && !crm_customer_id && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Name</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="business_name"
                        inputValue={BusinessData?.business_name || ""}
                        onChange={(event, newValue) => {
                          if (typeof newValue === 'string') {
                            updateBusinessNameFieldHandler("business_name", newValue.label)
                          } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setBusinessData(null);
                            updateBusinessNameFieldHandler("business_name", newValue.inputValue)
                          } else if (newValue) {
                            updateBusinessNameFieldHandler("business_name", newValue.label)
                          }
                        }}
                        options={CRMCustomers?.map(({ business_name }) => ({
                          label: business_name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          // Suggest the creation of a new value
                          const isExisting = options.some((option) => inputValue === option.label);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push({
                              inputValue,
                              label: `Add "${inputValue}"`,
                            });
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { padding: 0 }
                            }}
                            onChange={updateBusinessFieldHandler("business_name")}
                            value={submissionData?.business_name || BusinessData?.business_name}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Email</label>
                    <input
                      id="office_email"
                      maxLength={155}
                      type="email"
                      defaultValue={submissionData?.office_email || current?.office_email || ""}
                      onChange={updateFieldHandler("office_email")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Phone</label>
                    <input
                      id="office_phone_number"
                      maxLength={20}
                      type="text"
                      value={BusinessData?.office_phone_number || ""}
                      onChange={updateBusinessFieldHandler("office_phone_number")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                      onBlur={() => {
                        setBusinessData({
                          ...BusinessData,
                          ["office_phone_number"]: FormatPhone(BusinessData?.office_phone_number)
                        });
                      }}
                      onFocus={() => {
                        setBusinessData({
                          ...BusinessData,
                          ["office_phone_number"]: BusinessData?.office_phone_number?.replace(/[^0-9]/g, "")
                        });
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Extension</label>
                    <input
                      id="office_extension"
                      type="text"
                      maxLength={12}
                      defaultValue={submissionData?.office_extension || ""}
                      onChange={updateFieldHandler("office_extension")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Address Line 1</label>
                    <input
                      id="office_address_line_one"
                      maxLength={255}
                      type="text"
                      value={BusinessData?.office_address_line_one || ""}
                      onChange={updateBusinessFieldHandler("office_address_line_one")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Address Line 2</label>
                    <input
                      id="office_address_line_two"
                      maxLength={455}
                      type="text"
                      value={BusinessData?.office_address_line_two || ""}
                      onChange={updateBusinessFieldHandler("office_address_line_two")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business City</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_city"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateCityHandler("office_city", newValue.key);
                        }}
                        value={BusinessData?.office_city ? { key: BusinessData?.office_city, label: BusinessData?.office_city } :
                          { key: "", label: "" }}
                        options={City?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateBusinessFieldHandler("office_city")(e); }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business State</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_state"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateStateHandler("office_state", newValue.key);
                        }}
                        value={BusinessData?.office_state ? { key: BusinessData?.office_state, label: BusinessData?.office_state } :
                          { key: "", label: "" }}
                        options={States?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateBusinessFieldHandler("office_state")(e); } }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Zip Code</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_zip"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updatePostalCodeHandler("office_zip", newValue.key);
                        }}
                        value={BusinessData?.office_zip ? { key: BusinessData?.office_zip, label: BusinessData?.office_zip } :

                          { key: "", label: "" }}
                        options={PostalCode?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { updateBusinessFieldHandler("office_zip")(e); }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Business Country</label>
                    <Stack spacing={2}>
                      <Autocomplete
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableClearable
                        id="office_country"
                        style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px", float: "right" }}
                        onChange={(event, newValue) => {
                          updateCountryFieldHandler("office_country", newValue.key);
                        }}
                        value={BusinessData?.office_country ? { key: BusinessData?.office_country, label: BusinessData?.office_country } :
                          { key: "", label: "" }}
                        options={Countries?.map(({ name }) => ({
                          key: name,
                          label: name
                        })) || []}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          if (option === "")
                            return "";
                          // Regular option
                          return option.label;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.label}</li>}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { textAlign: "center", width: "100%" }
                            }}
                            onChange={e => { if (e.target.value === "") { updateBusinessFieldHandler("office_country")(e); } }}
                            maxLength={255}
                          />
                        )}
                      />
                    </Stack>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Job Title</label>
                    <input
                      id="job_title"
                      maxLength={255}
                      type="text"
                      defaultValue={submissionData?.job_title || current?.job_title || ""}
                      onChange={updateFieldHandler("job_title")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "20px" }}
                    />
                  </GridItem>
                  {(current?.status === undefined || current?.status === 0 || BusinessData?.status === 0) && (
                    <GridItem xs={12} sm={12} md={6}>
                      <label
                        style={{ marginTop: "30px" }}>Convert Lead to Customer</label>
                      <Checkbox
                        value={BusinessData?.status || 0}
                        onChange={updateBusinessFieldHandler("status")}
                        inputProps={{
                          name: "status",
                          id: "status"
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave(false) }}>
                    Save
                  </Button>
                </div>
              </>
            )}
            {activeStep === 3 && source === "Hydrate" && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Unsubscribe</label>
                    <CustomSelect
                      value={submissionData?.unsubscribe || current?.unsubscribe || 0}
                      onChange={updateFieldHandler("unsubscribe")}
                      data={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Contact Business Only</label>
                    <CustomSelect
                      value={submissionData?.contact_business_only || current?.contact_business_only || 0}
                      onChange={updateFieldHandler("contact_business_only")}
                      data={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Opted in Email</label>
                    <CustomSelect
                      value={submissionData?.opt_int_email || current?.opt_int_email || 1}
                      onChange={updateFieldHandler("opt_int_email")}
                      data={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Opted in Text</label>
                    <CustomSelect
                      value={submissionData?.opt_in_text || current?.opt_in_text || 1}
                      onChange={updateFieldHandler("opt_in_text")}
                      data={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Opted in Voice</label>
                    <CustomSelect
                      value={submissionData?.opt_in_voice || current?.opt_in_voice || 1}
                      onChange={updateFieldHandler("opt_in_voice")}
                      data={[{ label: "Yes", value: "1" }, { label: "No", value: "0" }]}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>List Name</label>
                    <CustomSelect
                      value={submissionData?.list_name || current?.list_name || 0}
                      onChange={updateFieldHandler("list_name")}
                      data={listNames}
                      defaultValue="Water Bear Marketing"
                      customOption="Customize"
                    />
                  </GridItem>
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave(false) }}>
                    Save
                  </Button>
                </div>
              </>
            )}
            {activeStep === 4 && source === "Hydrate" && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>First Contact</label>
                    <CustomInput
                      id="first_contacted"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "date",
                        value: submissionData?.first_contacted || current?.first_contacted || "",
                        onChange: updateFieldHandler("first_contacted")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Last Contact</label>
                    <CustomInput
                      id="last_contacted"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "date",
                        value: submissionData?.last_contacted || current?.last_contacted || "",
                        onChange: updateFieldHandler("last_contacted")
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Lead Type</label>
                    <CustomSelect
                      value={submissionData?.lead_type || current?.lead_type || ""}
                      onChange={updateFieldHandler("lead_type")}
                      data={leadTypes}
                      defaultValue="Water Bear Marketing"
                      customOption="Customize"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Lead Status</label>
                    <CustomSelect
                      value={submissionData?.lead_status || current?.lead_status || ""}
                      onChange={updateFieldHandler("lead_status")}
                      data={leadStatuses}
                    />
                  </GridItem>
                  {CategoryData?.length ?
                    <GridItem xs={12} sm={12} md={6}>
                      <label
                        style={{ marginTop: "15px" }}>Category</label>
                      <div style={{ clear: "both" }}></div>
                      <MultiSelect
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.chrisHelp
                        }}
                        multiple
                        input={<Input />}
                        renderValue={selected => selected.join(", ")}
                        onChange={updateFieldHandler("categories")}
                        inputProps={{
                          name: "categories",
                          id: "categories",
                          value: (typeof submissionData?.categories === 'string' || submissionData?.categories instanceof String) ? submissionData?.categories?.split(",").map((category_text) => { return category_text }) : (submissionData?.categories !== null && typeof submissionData?.categories !== 'undefined') ? submissionData?.categories : (typeof current?.categories === 'string' || current?.categories instanceof String) ? current?.categories?.split(",").map((category_text) => { return category_text }) : (current?.categories !== null && typeof current?.categories !== 'undefined') ? current?.categories : []
                        }}
                        style={{ width: "100%", border: "1px solid grey", marginTop: "15px" }}
                      >
                        {CategoryData?.map((CurrentCategory) => {
                          return (
                            <MenuItem key={CurrentCategory?.id} value={CurrentCategory?.category_text}>
                              <CustomCheckbox
                                checked={
                                  submissionData?.categories?.indexOf(CurrentCategory?.category_text) >
                                  -1
                                }
                              />
                              <ListItemText primary={CurrentCategory?.category_text} />
                            </MenuItem>
                          )
                        })}
                      </MultiSelect>
                    </GridItem>
                    : ""}
                  {ProductData?.length ?
                    <GridItem xs={12} sm={12} md={6}>
                      <label
                        style={{ marginTop: "15px" }}>Product</label>
                      <div style={{ clear: "both" }}></div>
                      <MultiSelect
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.chrisHelp
                        }}
                        multiple
                        input={<Input />}
                        renderValue={selected => selected.join(", ")}
                        onChange={updateFieldHandler("products")}
                        inputProps={{
                          name: "products",
                          id: "products",
                          value: (typeof submissionData?.products === 'string' || submissionData?.products instanceof String) ? submissionData?.products?.split(",").map((product_name) => { return product_name }) : (submissionData?.products !== null && typeof submissionData?.products !== 'undefined') ? submissionData?.products : (typeof current?.products === 'string' || current?.products instanceof String) ? current?.products?.split(",").map((product_name) => { return product_name }) : (current?.products !== null && typeof current?.products !== 'undefined') ? current?.products : []
                        }}
                        style={{ width: "100%", border: "1px solid grey", marginTop: "15px" }}
                      >
                        {ProductData?.map((CurrentProduct) => {
                          return (
                            <MenuItem key={"products" + CurrentProduct?.id} value={CurrentProduct?.product_name}>
                              <CustomCheckbox
                                checked={
                                  submissionData?.products?.indexOf(CurrentProduct?.product_name) >
                                  -1 || current?.products?.indexOf(CurrentProduct?.product_name) >
                                  -1
                                }
                              />
                              <ListItemText primary={CurrentProduct?.product_name} />
                            </MenuItem>
                          )
                        })}
                      </MultiSelect>
                    </GridItem>
                    : ""}
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Last Sale Value</label>
                    <input
                      id="sale_value"
                      maxLength={30}
                      type="text"
                      defaultValue={submissionData?.sale_value || current?.sale_value || ""}
                      onChange={updateFieldHandler("sale_value")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <label
                      style={{ marginTop: "30px" }}>Lifetime Value</label>
                    <input
                      id="lifetime_value"
                      maxLength={30}
                      type="text"
                      defaultValue={submissionData?.lifetime_value || current?.lifetime_value || ""}
                      onChange={updateFieldHandler("lifetime_value")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <label
                      style={{ marginTop: "30px" }}>Notes</label>
                    <textarea
                      id="notes"
                      type="text"
                      defaultValue={submissionData?.notes || current?.notes || ""}
                      onChange={updateFieldHandler("notes")}
                      style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%", marginTop: "10px" }}
                    />
                  </GridItem>
                </GridContainer>
                <div style={{ marginTop: "20px" }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => { handleSave(false) }}>
                    Save
                  </Button>
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

UpdateCustomerProfile.propTypes = {
  LoadNewNotifications: PropTypes.func
};

export default UpdateCustomerProfile;