import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, omit } from "lodash";
import { useSnackbar } from "notistack";

import { makeStyles } from "@mui/styles";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import UpdateCustomerProfile from "views/Dashboard/UpdateCustomerProfile";
import CheckForPeople from "./CheckForPeople";
import PeopleList from "./PeopleList";
import CheckForJobs from "./CheckForJobs";
import JobList from "./JobList";
import CheckForPhotos from "./CheckForPhotos";
import PhotoList from "./PhotoList";
import CheckForDocuments from "./CheckForDocuments";
import DocumentList from "./DocumentList";
import CheckForContracts from "./CheckForContracts";
import ContractList from "./ContractList";
import CheckForProposals from "./CheckForProposals";
import ProposalList from "./ProposalList";
import Checkbox from '@mui/material/Checkbox';
import CheckForEstimates from "./CheckForEstimates";
import EstimateList from "./EstimateList";

import {
  LIST_CUSTOMER_EMAILS,
  UPDATE_CUSTOMER_EMAIL,
  CRM_CUSTOMERS,
  LIST_ACTIVITY,
  LIST_PROPOSAL,
  LIST_CONTRACTS,
  LIST_JOBS,
  LIST_PHOTO_GALLERY,
  LIST_PHOTOS,
  LIST_CUSTOMER_REPRESENTATIVES,
  LIST_DOCUMENT,
  LIST_ESTIMATES,
  LIST_SERVICES,
  UPDATE_CRM_ACTIVITY
} from "queries/customers";
import { LIST_CUSTOMER_USERS } from "queries/users";

import { GET_LEAD_STATUS, GET_LEAD_TYPE } from "queries/hydrate";
import { READ_ME } from "queries/users";
import { Context } from "../../../redux/store";
import FormatPhone from "../Format/FormatPhone";

const moment = require("moment");

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, setType, setDescription, setRepresentative } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow style={{ "whiteSpace": "nowrap" }} >
        <TableCell>&nbsp;</TableCell>
        {columns.map((column) => (
          <TableCell
            key={column.length}
            sortDirection={orderBy === column.accessor ? order : false}
            align={'left'}
            style={{ verticalAlign: "top", paddingLeft: "5px", paddingRight: "5px" }}
          >
            {column.Header !== "Complete" ?
              <TableSortLabel
                active={orderBy === column.accessor}
                direction={orderBy === column.accessor ? order : 'asc'}
                onClick={createSortHandler(column.accessor)}
              >
                {column.Header}
              </TableSortLabel>
              : column.Header}
            <br />
            {column.Header !== "Date" && column.Header !== "Complete" ?
              <Input style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderBottom: "6px" }} type="text" onChange={event => {
                column.Header === "Type" ? setType(event.target.value) :
                  column.Header === "Description" ? setDescription(event.target.value) :
                    setRepresentative(event.target.value)
              }}></Input> : ""}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Activity = () => {
  const [state] = useContext(Context);
  const [tabledata, setTabledata] = useState();
  const [currentCustomerEmail, setCurrentCustomerEmail] = useState(null);
  const [updatedCustomerEmail, setUpdatedCustomerEmail] = useState(null);
  const [page, setPage] = useState(0);
  const [CRMEstimate, setCRMEstimate] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('activity_date');
  const [type, setType] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [representative, setRepresentative] = React.useState('');
  const [CRMCustomers, setCRMCustomers] = useState(null);
  const [CRMJob, setCRMJob] = useState(null);
  const [CRMProposal, setCRMProposal] = useState(null);
  const [CRMContract, setCRMContract] = useState(null);
  const [CRMPeople, setCRMPeople] = useState(null);
  const [CRMPhotoGallery, setCRMPhotoGallery] = useState(null);
  const [CRMDocument, setCRMDocument] = useState(null);
  const [CRMServices, setCRMServices] = useState(null);
  const [CRMPhotos, setCRMPhotos] = useState(null);
  const [CRMRepresentative, setCRMRepresentative] = useState(null);
  const [CRMAssignedToUsers, setCRMAssignedToUsers] = useState([]);
  const [all_activity_data, setall_activity_data] = useState([]);
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [submitUpdateCRMActivity] = useMutation(
    UPDATE_CRM_ACTIVITY
  );


  let { data: service_data } = useQuery(LIST_SERVICES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  const { data: users } = useQuery(LIST_CUSTOMER_USERS,
    {
      skip: !customer_id,
      fetchPolicy: "no-cache",
      variables: {
        customer_id,
      }
    });

  let { data: Estimate_data, refetch: refetchEsimates } = useQuery(LIST_ESTIMATES, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { loading, data: people_data } = useQuery(LIST_CUSTOMER_EMAILS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: proposal_data } = useQuery(LIST_PROPOSAL, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data, refetch } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      type,
      description,
      representative,
      count: 0,
      filterCompletable: 1,
      completed: 0
    }
  });

  let { data: all_data } = useQuery(LIST_ACTIVITY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 1,
      order_by: orderBy + " " + order,
      page: page,
      rows_per_page: rowsPerPage,
      type,
      description,
      representative,
      filterCompletable: 1,
      completed: 0
    }
  });

  let { data: document_data } = useQuery(LIST_DOCUMENT, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: contract_data } = useQuery(LIST_CONTRACTS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: job_data } = useQuery(LIST_JOBS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      count: 0
    }
  });

  let { data: Photo_Gallery_data } = useQuery(LIST_PHOTO_GALLERY, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: Photo_data } = useQuery(LIST_PHOTOS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id
    }
  });

  let { data: LeadStatus_data } = useQuery(GET_LEAD_STATUS, {
    skip: !customer_id,
    variables: {
      customer_id: customer_id,
      skip_message_to_all: false
    }
  });

  let { data: crm_data } = useQuery(CRM_CUSTOMERS, {
    skip: !customer_id,
    fetchPolicy: "no-cache",
    variables: {
      customer_id,
      status: -1
    }
  });

  let { data: Representative_data } = useQuery(LIST_CUSTOMER_REPRESENTATIVES, {
    skip: !customer_id,
    variables: {
      customer_id,
      count: 0
    }
  });

  LeadStatus_data = LeadStatus_data?.getHydrateLeadStatus || [];

  let leadStatuses = [];

  if (LeadStatus_data) {
    LeadStatus_data.forEach(obj => {
      leadStatuses.push({ label: obj.lead_status_text, value: obj.lead_status, types: obj.lead_type })
    })
  }

  let { data: LeadType_data } = useQuery(GET_LEAD_TYPE, {
    skip: !customer_id,
    variables: { customer_id: customer_id }
  });

  LeadType_data = LeadType_data?.getHydrateLeadType || [];

  let leadTypes = [];

  if (LeadType_data) {
    LeadType_data.forEach(obj => {
      leadTypes.push({ label: obj.lead_type_text, value: obj.lead_type })
    })
  }

  useEffect(() => {
    setall_activity_data(all_data?.ListActivity || []);
    setCRMAssignedToUsers(users?.listCustomerUsers || []);
    setTabledata(data?.ListActivity || [])
    setCRMEstimate(Estimate_data?.ListEstimates || []);
    setCRMJob(job_data?.ListJobs || []);
    setCRMCustomers(crm_data?.CRMCustomers || []);
    setCRMProposal(proposal_data?.ListProposal || []);
    setCRMContract(contract_data?.ListContract || []);
    setCRMPeople(people_data?.listCustomerEmails || []);
    setCRMPhotoGallery(Photo_Gallery_data?.ListPhotoGallery || []);
    setCRMPhotos(Photo_data?.ListPhotos || []);
    setCRMDocument(document_data?.ListDocuments || []);
    setCRMServices(service_data?.ListServices || []);
    setCRMRepresentative(Representative_data?.listCustomerRepresentatives || []);
  }, [people_data, crm_data, Representative_data, Estimate_data, data, all_data,
    proposal_data, contract_data, job_data, Photo_Gallery_data, document_data, Photo_data]);

  const classes = useStyles();

  const csvData = [
    [
      "Email",
      "First Name",
      "Last Name",
      "Country",
      "Zip",
      "Email",
      "Zip",
      "Phone",
      "Phone"
    ]
  ];
  if (tabledata?.length) {
    csvData.push(
      ...tabledata.map(row => [
        row?.email ? row?.email : "",
        row?.first_name ? row?.first_name : "",
        row?.last_name ? row?.last_name : "",
        row?.country ? row?.country : "",
        row?.zip_code ? row?.zip_code : "",
        row?.office_email ? row?.office_email : "",
        "",
        row?.cell_phone_number ? "1" + row?.cell_phone_number : "",
        row?.office_phone_number ? "1" + row?.office_phone_number : ""
      ])
    );
  }

  const updateActivityDatabase = async (id, e) => {
    await submitUpdateCRMActivity({
      variables: {
        customer_id,
        ActivityData: {
          id,
          activity_completed: e.target.checked === true ? 1 : 0
        }
      }
    });

    const notify = options => {
      const key = enqueueSnackbar(options?.text, {
        variant: options?.variant,
        persist: true
      });
      setTimeout(() => {
        closeSnackbar(key);
      }, options?.delay || 2000);
    }
    notify({
      text: "Information has been successfully submitted",
      variant: "success"
    });

    refetch();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_CUSTOMER_EMAIL,
    {
      onCompleted: ({ updateCustomerEmail }) => {
        const newCustomers = tabledata.slice();
        const oldIndex = newCustomers.findIndex(({ id }) => id === updateCustomerEmail.id);
        if (oldIndex !== -1) {
          newCustomers[oldIndex] = updateCustomerEmail;
        } else {
          newCustomers.push(updateCustomerEmail);
        }
        setTabledata(newCustomers);
      }
    }
  );

  const handleOpen = row => {
    setUpdatedCustomerEmail(row || {});
    setCurrentCustomerEmail(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          customerEmail: {
            ...omit(updated, ["__typename", "last_contacted", "first_contacted", "count"]),
            cell_phone_number: `${updated?.cell_phone_number !== undefined ? updated?.cell_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            office_phone_number: `${updated?.office_phone_number !== undefined ? updated?.office_phone_number : ""}`
              ?.replaceAll("(", "")
              ?.replaceAll(")", "")
              ?.replaceAll(" ", "")
              ?.split("-")
              .join(""),
            first_contacted: updated?.first_contacted === 'null' ? null : updated?.first_contacted,
            last_contacted: updated?.last_contacted === 'null' ? null : updated?.last_contacted
          }
        }
      });

      const notify = options => {
        const key = enqueueSnackbar(options?.text, {
          variant: options?.variant,
          persist: true
        });
        setTimeout(() => {
          closeSnackbar(key);
        }, options?.delay || 2000);
      }
      notify({
        text: "Information has been successfully submitted",
        variant: "success"
      });
    }
    setUpdatedCustomerEmail(null);
    setCurrentCustomerEmail(null);
  };

  const columns = compact([
    {
      Header: "Complete",
    },
    {
      Header: "Type",
      accessor: "activity_type"
    },
    {
      Header: "Description",
      accessor: "activity_description"
    },
    {
      Header: "Date",
      accessor: "activity_date"
    },
    {
      Header: "Created By",
      accessor: "users_id"
    },
    {
      Header: "Assigned To",
      accessor: "assigned_id"
    }
  ]);


  function Row(props) {
    const [state, dispatch] = useContext(Context);
    const { data, handleOpen, customer_id } = props;
    const [isOpenCollapse, setIsOpenCollapse] = useState(null);
    const [isCustomerOpenCollapse, setIsCustomerOpenCollapse] = useState(null);
    const [isContractOpenCollapse, setIsContractOpenCollapse] = useState(null);
    const [isPeopleOpenCollapse, setIsPeopleOpenCollapse] = useState(null);
    const [isPhotoOpenCollapse, setIsPhotoOpenCollapse] = useState(null);
    const [isPhotosOpenCollapse, setIsPhotosOpenCollapse] = useState(null);
    const [isDocumentOpenCollapse, setIsDocumentOpenCollapse] = useState(null);
    const [isProposalOpenCollapse, setIsProposalOpenCollapse] = useState(null);
    const [isJobOpenCollapse, setIsJobOpenCollapse] = useState(null);
    const [isEstimateOpenCollapse, setIsEstimateOpenCollapse] = useState(null);
    const [isTicketOpenCollapse, setIsTicketOpenCollapse] = useState(null);

    const handleExpand = (clickedIndex) => {
      if (isOpenCollapse === clickedIndex) {
        setIsOpenCollapse(null);
      } else {
        setIsOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleTicketExpand = (clickedIndex) => {
      if (isTicketOpenCollapse === clickedIndex) {
        setIsTicketOpenCollapse(null);
      } else {
        setIsTicketOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleEstimateExpand = (clickedIndex) => {
      if (isEstimateOpenCollapse === clickedIndex) {
        setIsEstimateOpenCollapse(null);
      } else {
        setIsEstimateOpenCollapse(clickedIndex);
        setIsPhotosOpenCollapse(null);
      }
    };

    const handleJobExpand = (clickedIndex) => {
      if (isJobOpenCollapse === clickedIndex) {
        setIsJobOpenCollapse(null);
      } else {
        setIsJobOpenCollapse(clickedIndex);
      }
    };

    const handleProposalExpand = (clickedIndex) => {
      if (isProposalOpenCollapse === clickedIndex) {
        setIsProposalOpenCollapse(null);
      } else {
        setIsProposalOpenCollapse(clickedIndex);
      }
    };

    const handleCustomerExpand = (clickedIndex) => {
      if (isCustomerOpenCollapse === clickedIndex) {
        setIsCustomerOpenCollapse(null);
      } else {
        setIsCustomerOpenCollapse(clickedIndex);
      }
    };

    const handleContractExpand = (clickedIndex) => {
      if (isContractOpenCollapse === clickedIndex) {
        setIsContractOpenCollapse(null);
      } else {
        setIsContractOpenCollapse(clickedIndex);
      }
    };

    const handlePeopleExpand = (clickedIndex) => {
      if (isPeopleOpenCollapse === clickedIndex) {
        setIsPeopleOpenCollapse(null);
      } else {
        setIsPeopleOpenCollapse(clickedIndex);
      }
    };

    const handlePhotoExpand = (clickedIndex) => {
      if (isPhotoOpenCollapse === clickedIndex) {
        setIsPhotoOpenCollapse(null);
      } else {
        setIsPhotoOpenCollapse(clickedIndex);
      }
    };

    const handleDocumentExpand = (clickedIndex) => {
      if (isDocumentOpenCollapse === clickedIndex) {
        setIsDocumentOpenCollapse(null);
      } else {
        setIsDocumentOpenCollapse(clickedIndex);
      }
    };

    return (
      <React.Fragment>
        {data?.map((row, index) => (
          <>
            <TableRow key={row?.length}>
              <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => handleExpand(index)}>
                  {isOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell align="left">
                <div>
                  <Checkbox
                    style={{ marginRight: "30px" }}
                    checked={row?.activity_completed ? true : false}
                    onChange={e => updateActivityDatabase(row?.id, e)}
                  >
                  </Checkbox>
                </div>
              </TableCell>
              <TableCell align="left">
                <>
                  {row?.activity_type === "Estimate" ?
                    <a
                      href={"/admin/crmestimatedetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&estimateid=" + row?.estimate_id}
                    >
                      {row?.activity_type}
                    </a>
                    :
                    <a
                      href={"/admin/crmactivitydetail?customer=" + customer_id + "&id=" + row?.crm_customers_id + "&peopleid=" + row?.people_id + "&activityid=" + row?.id}
                    >
                      {row?.activity_type}
                    </a>}
                </>
              </TableCell>
              <TableCell align="left"><div>{row?.activity_description?.length > 30 ? row?.activity_description.substring(0, 29) : row?.activity_description}</div>
              </TableCell>
              <TableCell align="left"><div>{row?.activity_date !== null ? moment(new Date(parseInt(row?.activity_date))).utc().format("MM-DD-YYYY, h:mm:ss A") : ""}</div>
              </TableCell>
              <TableCell align="left"><div>{CRMAssignedToUsers?.filter(CurrentUser => CurrentUser?.id === row?.users_id)?.map(({ first_name, last_name }) => { return first_name + " " + last_name })}</div>
              </TableCell>
              <TableCell align="left"><div>{CRMAssignedToUsers?.filter(CurrentUser => CurrentUser?.id === row?.assigned_to)?.map(({ first_name, last_name }) => { return first_name + " " + last_name })}</div>
              </TableCell>
            </TableRow>
            <TableRow key={row?.length}>
              <TableCell style={{ padding: 0 }} colSpan={50}>
                <Collapse in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                  <Table size="medium">
                    <tbody>
                      {CRMCustomers?.filter((CurrentCustomer) => CurrentCustomer?.id === row?.crm_customers_id).map(CurrentCustomer => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                              <TableCell>
                                <Table size="medium">
                                  <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                    <TableCell style={{ width: "100px" }}>
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleCustomerExpand(index)}>
                                        {isCustomerOpenCollapse === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>
                                    <TableCell><div style={{ float: "left" }}>Customer&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Business Name</b> <a href={"/admin/" + (CurrentCustomer?.status === 1 ? "crmcustomerdetail" : "crmleaddetail") + "?customer=" + customer_id + "&id=" + CurrentCustomer?.id}>{CurrentCustomer?.business_name}</a></div></TableCell>
                                  </TableRow>
                                </Table>
                                <Collapse in={isCustomerOpenCollapse === index} timeout="auto" unmountOnExit>
                                  <Table size="medium">
                                    <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                                      <TableCell style={{ paddingTop: 10, paddingBottom: 10, width: "175px" }}>&nbsp;
                                      </TableCell>
                                      <TableCell>
                                        <div style={{ width: "300px", float: "left" }}><b>Address</b><br />{CurrentCustomer?.office_address_line_one} {CurrentCustomer?.office_address_line_two} {CurrentCustomer?.office_city} {CurrentCustomer?.office_state} {CurrentCustomer?.office_country}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Office Phone</b><br />{FormatPhone(CurrentCustomer?.office_phone_number)}</div>
                                        <div style={{ width: "175px", float: "left" }}><b>Representative</b><br /> {CRMRepresentative?.filter((CurrentRep) => CurrentRep?.id === CurrentCustomer?.representative_id).map(CurrentRep => { return <>{CurrentRep?.first_name} {CurrentRep?.last_name}</> })}</div>
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          </>)
                      })}
                      {CheckForPeople(row, index, CRMPeople, isPeopleOpenCollapse, handlePeopleExpand)}
                      {PeopleList(row, index, CRMPeople, CRMCustomers, isPeopleOpenCollapse, leadStatuses, CRMRepresentative, customer_id)}
                      {CheckForJobs(row, index, "activity_id", CRMJob, isJobOpenCollapse, handleJobExpand)}
                      {JobList(row, index, CRMJob, "activity_id", CRMRepresentative, isJobOpenCollapse, CRMServices, customer_id)}
                      {CheckForPhotos(row, index, "activity_id", CRMPhotoGallery, isPhotoOpenCollapse, handlePhotoExpand)}
                      {PhotoList(row, index, CRMPhotoGallery, "activity_id", CRMRepresentative, isPhotoOpenCollapse, customer_id)}
                      {CheckForDocuments(row, index, "activity_id", CRMDocument, isDocumentOpenCollapse, handleDocumentExpand)}
                      {DocumentList(row, index, CRMDocument, "activity_id", CRMRepresentative, isDocumentOpenCollapse, customer_id)}
                      {CheckForEstimates(row, index, "activity_id", CRMEstimate, isEstimateOpenCollapse, handleEstimateExpand)}
                      {EstimateList(row, index, CRMEstimate, "activity_id", CRMRepresentative, isEstimateOpenCollapse, customer_id)}
                      {CheckForProposals(row, index, "activity_id", CRMProposal, isProposalOpenCollapse, handleProposalExpand)}
                      {ProposalList(row, index, CRMProposal, "activity_id", CRMRepresentative, isProposalOpenCollapse, customer_id)}
                      {CheckForContracts(row, index, "activity_id", CRMContract, isContractOpenCollapse, handleContractExpand)}
                      {ContractList(row, index, CRMContract, "activity_id", CRMRepresentative, isContractOpenCollapse, customer_id)}
                    </tbody>
                  </Table>
                </Collapse>
              </TableCell >
            </TableRow >
          </>
        ))
        }
      </React.Fragment >
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              <CSVDownloader
                data={tabledata || []}
                columns={columns}
                filename={`Customer Email List (${state.customers?.selected?.type}).csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <TablePagination
              component="div"
              count={all_activity_data?.length ? all_activity_data[0]?.count : 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                "& .MuiTablePagination-input": {
                  marginBottom: 1,
                }
              }}
              labelDisplayedRows={({ from, to, count }) => count ? `${from}-${to} of ${count?.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}`: ""}
              slotProps={{
                pagination: { labelDisplayedRows: ({ from, to, count }) => `${from.toLocaleString('en')}-${to.toLocaleString('en')}`},
              }}
              style={{ display: all_activity_data?.length > 0 ? "" : "none" }}
            />
            <TableContainer component={Paper} style={{ overflowY: "auto", maxHeight: "600px" }}>
              <Table stickyHeader aria-label="collapsible table" columns={columns} data={data}>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  columns={columns}
                  setType={setType}
                  setDescription={setDescription}
                  setRepresentative={setRepresentative}
                />
                <TableBody>
                  <Row data={tabledata} handleOpen={handleOpen} customer_id={customer_id} />
                </TableBody>
              </Table>
            </TableContainer>
            {updatedCustomerEmail && (
              <Dialog
                open={Boolean(updatedCustomerEmail)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
              >
                <DialogContent>
                  <UpdateCustomerProfile
                    current={currentCustomerEmail}
                    updated={updatedCustomerEmail}
                    handleClose={handleClose}
                    setTabledata={setTabledata}
                    customers={customers}
                    source={"Linear"}
                  />
                </DialogContent>
              </Dialog>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Activity;
