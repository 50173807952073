import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import Refresh from "@mui/icons-material/Refresh";

// core components
import Button from "components/CustomButtons/Button";

import styles from "./styles";

const useStyles = makeStyles(styles);

const Page404 = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <h1 className={classes.heading}>OH NO! YOU JUST GOT 404{"'"}D</h1>
        <Link to="/">
          <Button className={classes.button}>
            <Refresh className={classes.buttonIcon} />
            Try a different page
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default Page404;
