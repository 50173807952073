import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { sumBy } from "lodash";

import BudgetCard from "components/BudgetCard";
import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_GOOGLE_ADS_INFO_SUMMARY, GET_GOOGLE_VIDEO_ADS_INFO_SUMMARY } from "queries/google";
import { Context } from "../../../redux/store";
import { getPrevious } from "utils/format";
import { addMargin } from "utils/margin";

const AllCampaigns = ({ type_filter, filter, productLine, isVideo }) => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const dateRangeString = state.customers?.dateRangeString;
  const compareDateRange = getPrevious(dateRangeString, compareOption);
  const customer_id = state.customers?.selected?.id;
  const { loading: summaryLoading, data } = useQuery(
    GET_GOOGLE_ADS_INFO_SUMMARY,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate,
        filter,
        type_filter
      }
    }
  );
  const { loading: summaryLoadingCompare, data: dataCompare } = useQuery(
    GET_GOOGLE_ADS_INFO_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate,
        filter,
        type_filter
      }
    }
  );
  const summary = data?.getGoogleAdsInfoSummary;
  const summaryCompare = dataCompare?.getGoogleAdsInfoSummary;

  const loading = summaryLoading;
  const loadingCompare = summaryLoadingCompare;
  return (
    <GridContainer>
      <StatsCard
        icon="attach_money"
        loading={loading}
        label="Total Spent"
        value={addMargin((summary?.cost || 0) / 1000000, state)}
        render="currency"
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? addMargin((summaryCompare?.cost || 0) / 1000000, state)
            : ""
        }
        previous
      />
      <StatsCard
        icon="dvr"
        loading={loading}
        label="Impressions"
        value={summary?.impressions || 0}
        render="integer"
        help="Impressions are the number of times that your advertisement has been displayed."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.impressions || 0
            : ""
        }
        previous
      />
      {isVideo && (
        <StatsCard
          icon="dvr"
          loading={loading}
          label="Views"
          value={summary?.views || 0}
          render="integer"
          help="Views are the number of times your video was viewed."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? summaryCompare?.views || 0
              : ""
          }
          previous
        />
      )}
      {isVideo && (
        <StatsCard
          icon="dvr"
          loading={loading}
          label="View Rate"
          value={(summary?.views || 0) / (summary?.impressions || 1) * 100}
          render="percentage"
          help="View Rate is the number of views of the video ad to the number of video impressions."
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? (summaryCompare?.views || 0) / (summaryCompare?.impressions || 1) * 100
              : ""
          }
          previous
        />
      )}
      <StatsCard
        icon="donut_small"
        loading={loading}
        label="Clicks"
        value={summary?.clicks || 0}
        render="integer"
        help="Clicks are the count of the number of times users have clicked on your advertisement."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? summaryCompare?.clicks || 0
            : ""
        }
        previous
      />
      <StatsCard
        icon="scatter_plot"
        loading={loading}
        label="CTR"
        value={(summary?.clicks || 0) / (summary?.impressions || 1) / 0.01}
        render="percentage"
        /*milestones={[
          {
            value: 0.1,
            color: "info",
            icon: "fitness_center",
            message: `Not met "Working on it.."`
          },
          {
            value: 0.55,
            color: "grey",
            icon: "sentiment_satisfied",
            message: "Average"
          },
          {
            value: 0.98,
            color: "orange",
            icon: "sentiment_very_satisfied",
            message: "Above Average"
          },
          {
            color: "wbmMoney",
            icon: "star",
            message: "Best Performing"
          }
        ]}*/
        help="CTR stands for Click-Through Rate. This number is the percentage of people who view your ad (impressions) and then click the ad (clicks)."
        footerRender
        footer={
          loadingCompare
            ? "..."
            : compareOption
            ? (summaryCompare?.clicks || 0) /
              (summaryCompare?.impressions || 1) /
              0.01
            : ""
        }
        previous
      />
      {!isVideo && (
        <StatsCard
          icon="image_aspect_ratio"
          loading={loading}
          label={isVideo ? "CPV" : "CPC"}
          value={addMargin(
            isVideo
              ? (summary?.cost || 0) / (summary?.views || 1) / 1000000
              : (summary?.cost || 0) / (summary?.clicks || 1) / 1000000,
            state
          )}
          render="currency"
          descending
          /*milestones={[
            {
              value: 5,
              color: "info",
              icon: "fitness_center",
              message: `Not met "Working on it.."`
            },
            {
              value: 1,
              color: "grey",
              icon: "sentiment_satisfied",
              message: "Average"
            },
            {
              value: 0.5,
              color: "orange",
              icon: "sentiment_very_satisfied",
              message: "Above Average"
            },
            {
              color: "wbmMoney",
              icon: "star",
              message: "Best Performing"
            }
          ]}*/
          help={
            isVideo
              ? "CPV stands for Cost Per View. A view is counted when someone watches 30 seconds of your video ad or interacts with the ad, whichever comes first."
              : "CPC stands for Cost Per Click. This is the actual price you pay for each click in your online advertising campaigns."
          }
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? addMargin(
                  isVideo
                    ? (summaryCompare?.cost || 0) /
                        (summaryCompare?.views || 1) /
                        1000000
                    : (summaryCompare?.cost || 0) /
                        (summaryCompare?.clicks || 1) /
                        1000000,
                  state
                )
              : ""
          }
          previous
        />
      )}
      {!isVideo && (
        <StatsCard
          icon="grain"
          loading={loading}
          label="CPM"
          value={addMargin(
            (summary?.cost || 0) / (summary?.impressions || 1) / 1000,
            state
          )}
          render="currency"
          descending
          /*milestones={[
            {
              value: 5,
              color: "info",
              icon: "fitness_center",
              message: `Not met "Working on it.."`
            },
            {
              value: 3,
              color: "grey",
              icon: "sentiment_satisfied",
              message: "Industry Average"
            },
            {
              value: 1,
              color: "orange",
              icon: "sentiment_very_satisfied",
              message: "Better Than Average"
            },
            {
              color: "wbmMoney",
              icon: "star",
              message: "Best Performing"
            }
          ]}*/
          help={`CPM stands for Cost per Thousand Impressions. This numbers is refers the cost of displaying an advertisement one thousand times (known as "impressions")`}
          footerRender
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? addMargin(
                  (summaryCompare?.cost || 0) /
                    (summaryCompare?.impressions || 1) /
                    1000,
                  state
                )
              : ""
          }
          previous
        />
      )}
      {productLine &&
        productLine.split(",").map(product => {
          const [line, title] = product.split(":");
          return <BudgetCard productLine={line} title={title} key={product} />;
        })}
    </GridContainer>
  );
};

AllCampaigns.propTypes = {
  filter: PropTypes.string,
  type_filter: PropTypes.string,
  productLine: PropTypes.string,
  isVideo: PropTypes.bool
};

export default AllCampaigns;
