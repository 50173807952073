import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const theme = createTheme()

import Button from "components/CustomButtons/Button";

const HelpDialog = ({ open: propOpen, onClose, trigger, children }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(propOpen);
  }, [propOpen]);

  const closeHandler = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };
  return (
    <>
      <span onClick={() => setOpen(true)}>{trigger}</span>
      <Dialog
        open={open}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent><ThemeProvider theme={theme}>{children}</ThemeProvider></DialogContent>
        <DialogActions>
          <Button
            color="primary"
            autoFocus
            onClick={closeHandler}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

HelpDialog.propTypes = {
  trigger: PropTypes.node,
  children: PropTypes.node
};

HelpDialog.defaultProps = {
  trigger: null,
  children: null
};

export default HelpDialog;
