import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { CSVLink } from "react-csv";


import { makeStyles } from "@mui/styles";

// @mui/icons-material
import Assignment from "@mui/icons-material/Assignment";
import ArchiveIcon from '@mui/icons-material/Archive';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/CustomReactTable";
import { LIST_TRACKERS } from "queries/trackedLeads";
import { Context } from "../../../redux/store";
import { formatPhone } from "utils/format";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  leftAlign: {
    "& > div": {
      textAlign: "left !important"
    }
  }
};

const useStyles = makeStyles(styles);

const Trackers = () => {
  const [state] = useContext(Context);
  const call_rail_id = state.customers?.selected?.call_rail_id;
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data, error, networkStatus } = useQuery(LIST_TRACKERS, {
    skip: !call_rail_id || !state.customers?.dateRangeString,
    variables: {
      call_rail_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    },
    onCompleted: (...fdskjal) => {
      console.log("completed", fdskjal);
    },
    onError: (...err) => {
      console.log("error", err);
    },
  });

  const classes = useStyles();
  data = data?.listTrackers || [];
  const columns = [
    {
      Header: "What is Being Tracked",
      accessor: "name"
    },
    {
      Header: "Tracking Number",
      accessor: "tracking_numbers",
      // eslint-disable-next-line react/display-name
      Cell: ({ original: { tracking_numbers } }) => {
        return (
          <div style={{ whiteSpace: "pre-line" }}>
            {tracking_numbers
              .map(phone => formatPhone(phone))
              .join("\r\n")}
          </div>
        );
      },
      CSVRender: ({ original: { tracking_numbers } }) => {
        return tracking_numbers
          .map(phone => formatPhone(phone))
          .join("\r\n");
      }
    },
    {
      Header: "Rings to",
      accessor: "destination_number",
      Cell: ({ original: { destination_number } }) => {
        return formatPhone(destination_number);
      },
      CSVRender: ({ original: { destination_number } }) => {
        return formatPhone(destination_number);
      }
    },
    {
      Header: "Status",
      accessor: "status",
      sortable: false
    }
  ];

  const csvColumns = columns.filter(({ noCSV }) => !noCSV);

  const csvData = [
    csvColumns.map(({ Header }) => Header),
    ...data?.map(row => {
      return csvColumns.map(({ accessor, Cell, CSVRender }) => {
        if (CSVRender || Cell) {
          return (CSVRender || Cell)({ original: row });
        }
        return row[accessor];
      });
    })
  ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="leads" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Tracking Numbers
              <CSVLink
                data={csvData}
                filename={`Tracking Numbers (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                style={{ float: "right", margin: 0 }}
              >
                <ArchiveIcon />
              </CSVLink>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(20, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Trackers;
