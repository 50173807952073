import gql from "graphql-tag";

export const GET_TOTAL_BUDGET_SPENT = gql`
  query getTotalBudgetSpent(
    $customer_id: Int!
    $start_date: String
    $end_date: String
  ) {
    getTotalBudgetSpent(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
    )
  }
`;

export const GET_BUDGET = gql`
  query getBudget(
    $customer_id: Int!
    $product_line: String
    $product: Int
    $last: Boolean
  ) {
    getBudget(
      customer_id: $customer_id
      product_line: $product_line
      product: $product
      last: $last
    ) {
      id
      customer_id
      product_line
      product
      daily_budget
      monthly_budget
      start_date
      budget_change_date
      last_billed_date
      new_monthly_budget
      status
      cycle_start_time
      cycle_end_time
      current_cycle
    }
  }
`;

export const CREATE_BUDGET = gql`
  mutation($customer_id: Int, $data: BudgetInput!) {
    createBudget(customer_id: $customer_id, data: $data)
  }
`;

export const UPDATE_BUDGET = gql`
  mutation($data: BudgetInput!) {
    updateBudget(data: $data)
  }
`;

export const LIST_BUDGETS = gql`
  {
    listBudgets {
      id
      customer_name
      customer_id
      status
      product
      product_line
      budget_spent
      monthly_budget
      cycle_start_time
      cycle_end_time
      current_cycle
      CPL
      CPLG
      totalLeads
      monthly_leads_goal
    }
  }
`;

export const GET_GOOGLE_CAMPAIGNS = gql`
    query getGoogleCampaigns($customer_id: Int) {
      getGoogleCampaigns(
        customer_id: $customer_id
      ) {
        id
        customer_name
        customer_id
        campaign_id
        campaign_name
        campaign_type
        status
        monthly_budget
        budget_remaining
        budget_optimization
        keyword_optimization
        max_cost_per_click
        cost_per_lead_goal
        conversion_rate
        banner_ad_optimization
        share_budget
        cycle_start_time
        campaign_pacing
      }
    }
`;