import React from "react";
import Select from "components/Select/Select";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from "@mui/material/MenuItem";
import CustomCheckbox from "components/CustomCheckbox";
import ListItemText from "@mui/material/ListItemText";
import MultiSelect from "@mui/material/Select";
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import RichTextEditor from "views/Dashboard/RichTextEditor";
import "assets/css/richtext_styles.css";
const moment = require("moment");

const filter = createFilterOptions();

const AddUpdateJob = (CRMActivitySubmission, updateActivityFieldHandler, updateJobDatabase,
  CRMPeople, JobsubmissionData, CRMAssignedToUsers, CRMRepresentative, updatePeopleFieldHandler,
  updatePeopleNameFieldHandler, CRMProducts, CRMServices, updateJobFieldHandler, PersonFound,
  Update = false, CRMActivity, CRMContract, CRMProposal, CRMPhotoGallery, setJobsubmissionData,
  jobvalue, setjobvalue, JobStatuses) => {

  const FormatCurrency = (value) => {
    let newValue = parseFloat(value?.replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });

    setJobsubmissionData({
      ...JobsubmissionData,
      ["job_value"]: newValue
    });

    setjobvalue(newValue);
  }

  return (
    <div>
      <label style={{ textAlign: "center", width: "100%" }}>{Update ? "Update" : "Create A"} Job</label>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "140px" }}>Job Name:</label>
        <input
          autoFocus
          id="job_name"
          maxLength={255}
          type="text"
          value={JobsubmissionData?.job_name || ""}
          onChange={updateJobFieldHandler("job_name")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "140px" }}>Service(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateJobFieldHandler("services")}
          inputProps={{
            name: "services",
            id: "services",
            value: (typeof JobsubmissionData?.services === 'string' || JobsubmissionData?.services instanceof String) ? JobsubmissionData?.services?.split(",")?.map((service_name) => { return CRMServices?.filter((CurrentService) => { if (CurrentService?.id.toString() === service_name.toString()) { return true } else { return false } })?.map((CurrentService) => { return CurrentService?.service_name }).join(", ") }) || [] : (typeof JobsubmissionData?.services !== 'undefined') ? JobsubmissionData?.services || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMServices?.map((CurrentService) => {
            var checked = false;
            if ((typeof JobsubmissionData?.services === 'string' || JobsubmissionData?.services instanceof String)) {
              var temp = JobsubmissionData?.services?.split(",");

              for (var index = 0; index < temp?.length; index++) {
                if (temp[index]?.toString() === CurrentService?.id?.toString()) { checked = true }
              }
            }
            if (typeof JobsubmissionData?.services === 'object') {
              for (var index = 0; index < JobsubmissionData?.services?.length; index++) {
                if (JobsubmissionData?.services[index]?.toString() === CurrentService?.service_name?.toString()) { checked = true }
              }
            }
            return (
              <MenuItem key={CurrentService?.id} value={CurrentService?.service_name}>
                <CustomCheckbox
                  checked={checked}
                />
                <ListItemText primary={CurrentService?.service_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "140px" }}>Product(s):</label>
        <MultiSelect
          multiple
          input={<Input />}
          renderValue={selected => selected.join(", ").substring(0, 82)}
          onChange={updateJobFieldHandler("products")}
          inputProps={{
            name: "products",
            id: "products",
            value: (typeof JobsubmissionData?.products === 'string' || JobsubmissionData?.products instanceof String) ? JobsubmissionData?.products?.split(",")?.map((product_name) => { return CRMProducts?.filter((CurrentProduct) => { if (CurrentProduct?.id.toString() === product_name.toString()) { return true } else { return false } })?.map((CurrentProduct) => { return CurrentProduct?.product_name }).join(", ") }) || [] : (typeof JobsubmissionData?.products !== 'undefined') ? JobsubmissionData?.products || [] : []
          }}
          style={{ width: "100%", border: "1px solid grey", borderRadius: "5px" }}
        >
          {CRMProducts?.map((CurrentProduct) => {
            var checked = false;
            if ((typeof JobsubmissionData?.products === 'string' || JobsubmissionData?.products instanceof String)) {
              var temp = JobsubmissionData?.products?.split(",");

              for (var index = 0; index <= temp?.length; index++) {
                if (temp[index]?.toString() === CurrentProduct?.id?.toString() || temp[index]?.toString() === CurrentProduct?.product_name?.toString()) { checked = true }
              }
            }
            if (JobsubmissionData?.products instanceof Object) {
              for (var index = 0; index <= JobsubmissionData?.products?.length; index++) {
                if (JobsubmissionData?.products[index]?.toString() === CurrentProduct?.product_name?.toString() || JobsubmissionData?.products[index]?.toString() === CurrentProduct?.id?.toString()) { checked = true }
              }
            }

            return (
              <MenuItem key={CurrentProduct?.id} value={CurrentProduct?.product_name}>
                <CustomCheckbox
                  checked={checked}
                />
                <ListItemText primary={CurrentProduct?.product_name} />
              </MenuItem>
            )
          })}
        </MultiSelect>
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ textAlign: "left", width: "140px" }}>Job Value:</label>
        <input
          id="job_value"
          maxLength={15}
          type="text"
          value={JobsubmissionData?.job_value}
          onBlur={() => FormatCurrency(JobsubmissionData?.job_value)}
          onFocus={() => {
            setJobsubmissionData({
              ...JobsubmissionData,
              ["job_value"]: JobsubmissionData?.job_value?.replace(/[^0-9.]/g, "")
            })
          }}
          onChange={updateJobFieldHandler("job_value")}
          style={{ borderColor: "#b7b7b7", borderBottom: "1px !", borderTop: 0, borderLeft: 0, borderRight: 0, width: "100%" }}
        /></div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ float: "left", width: "140px" }}>Scheduled Date:</label>
        <TextField
          id="job_scheduled_date"
          type="datetime-local"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={JobsubmissionData?.job_scheduled_date?.indexOf("-") === -1 ? moment(parseInt(JobsubmissionData?.job_scheduled_date)).format("YYYY-MM-DDTHH:mm") : JobsubmissionData?.job_scheduled_date ? JobsubmissionData?.job_scheduled_date : ""}
          onChange={
            updateJobFieldHandler("job_scheduled_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ left: "left", width: "140px" }}>Reschedule Date:</label>
        <TextField
          id="job_rescheduled_date"
          type="datetime-local"
          sx={{ width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
          value={JobsubmissionData?.job_rescheduled_date?.indexOf("-") === -1 ? moment(parseInt(JobsubmissionData?.job_rescheduled_date)).format("YYYY-MM-DDTHH:mm") : JobsubmissionData?.job_rescheduled_date ? JobsubmissionData?.job_rescheduled_date : ""}
          onChange={
            updateJobFieldHandler("job_rescheduled_date")
          }
          size={"small"}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <label style={{ left: "left", width: "115px" }}>Job Status:</label>
        <Select
          selected={typeof JobsubmissionData?.job_status === 'string' ? JobStatuses?.map(({ label, value }) => { if (label === JobsubmissionData?.job_status) return value })?.filter(CurrentStatus => CurrentStatus !== undefined) : JobsubmissionData?.job_status ? JobsubmissionData?.job_status : 0}
          onSelect={updateJobFieldHandler("job_status")}
          options={JobStatuses?.map(({ label, value }) => ({
            key: value,
            label: label
          }))}
          style={{ marginBottom: 20, float: "left", height: "40px" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      {window.location.href.indexOf("crmpersondetail") === -1 && (
        <>
          <div style={{ marginTop: "20px" }}>
            <label
              style={{ marginTop: "8px", float: "left", width: "115px" }}>People:</label>
            {Update === true ?
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  value={CRMPeople?.filter((CurrentPerson) => CurrentPerson?.id === JobsubmissionData?.people_id)?.map((CurrentPerson) => {
                    return { key: CurrentPerson?.id, label: CurrentPerson?.first_name + " " + CurrentPerson?.last_name }
                  })[0] || { label: JobsubmissionData?.people_name ? JobsubmissionData?.people_name : "" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Job")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Job")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option?.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Job")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
              :
              <Stack spacing={2}>
                <Autocomplete
                  freeSolo
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  disableClearable
                  id="people_name"
                  style={{ float: "left", borderRadius: "5px", width: "100%" }}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      updatePeopleNameFieldHandler(newValue.label, "Job")
                    } else if (newValue) {
                      updatePeopleNameFieldHandler(newValue.label, "Job")
                    }
                  }}
                  options={CRMPeople?.map(({ first_name, last_name, id }) => ({
                    key: id,
                    label: first_name + " " + last_name
                  })) || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Regular option
                    return option?.label;
                  }}
                  renderOption={(props, option) => <li {...props}>{option.label}</li>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { padding: 0, width: "100%" }
                      }}
                      onChange={updatePeopleFieldHandler("people_name", "Job")}
                      maxLength={255}
                    />
                  )}
                />
              </Stack>
            }
          </div>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
        </>
      )}
      {PersonFound ? "" :
        <label style={{ marginLeft: "65px", color: "red" }}>Person not found</label>}
      {CRMPhotoGallery?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Gallery:</label>
          <Select
            options={CRMPhotoGallery?.map(({ id, photo_gallery_name }) => ({
              key: id,
              label: photo_gallery_name
            }))}
            selected={JobsubmissionData?.photo_gallery_id || 0}
            onSelect={updateJobFieldHandler("photo_gallery_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMContract?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Contract:</label>
          <Select
            options={CRMContract?.map(({ id, contract_name }) => ({
              key: id,
              label: contract_name
            }))}
            selected={JobsubmissionData?.contract_id || 0}
            onSelect={updateJobFieldHandler("contract_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      {CRMProposal?.length > 0 && (
        <>
          <div style={{ clear: "both", marginTop: "20px" }}></div>
          <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Proposal:</label>
          <Select
            options={CRMProposal?.map(({ id, proposal_name }) => ({
              key: id,
              label: proposal_name
            }))}
            selected={JobsubmissionData?.proposal_id || 0}
            onSelect={updateJobFieldHandler("proposal_id")}
            style={{ marginBottom: 20, float: "left", height: "40px" }}
          />
        </>
      )}
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div>
        <label style={{ marginTop: "8px", width: "115px", float: "left" }}>Assign To:</label>
        <Select
          options={CRMAssignedToUsers?.map(({ id, first_name, last_name }) => ({
            key: id,
            label: first_name + " " + last_name
          }))}
          selected={JobsubmissionData?.assigned_to || ""}
          onSelect={updateJobFieldHandler("assigned_to")}
          style={{ marginBottom: 20, height: "40px", float: "left" }}
        />
      </div>
      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <div><RichTextEditor key={CRMActivity?.filter(CurrentActivity => CurrentActivity?.job_id === JobsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.job_id === JobsubmissionData?.id)[0]["activity_type"]?.length : null} current={CRMActivity?.filter(CurrentActivity => CurrentActivity?.job_id === JobsubmissionData?.id)?.length ? CRMActivity?.filter(CurrentActivity => CurrentActivity?.job_id === JobsubmissionData?.id)[0] : null} updateFieldHandler={updateActivityFieldHandler} field={"activity_notes"} readonly={false} style={{ border: "1px solid grey", borderRadius: "5px" }} /></div>

      <div style={{ clear: "both", marginTop: "20px" }}></div>
      <Button variant="contained" style={{ backgroundColor: "#0071ce", color: "#ffffff" }} onClick={() => {
        updateJobDatabase();
      }}>
        Save
      </Button>
    </div>
  )
};

export default AddUpdateJob;
