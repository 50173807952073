import gql from "graphql-tag";

export const GET_TRACKED_LEADS_SUMMARY = gql`
  query getTrackedLeadsSummary(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
    $source: String
  ) {
    getTrackedLeadsSummary(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      source: $source
    ) {
      total_leads
      total_calls
      total_forms
    }
  }
`;

export const LIST_TRACKED_LEADS_CALLS_PAID_SEARCH = gql`
query listTrackedLeadsCallsPaidSearch(
  $customer_id: Int!
  $start_date: String!
  $end_date: String!
  $filter: String
) {
  listTrackedLeadsCallsPaidSearch(
    customer_id: $customer_id
    start_date: $start_date
    end_date: $end_date
    filter: $filter
  ) {
    total_leads
    total_calls
    total_forms
  }
}
`;

export const LIST_TRACKED_LEADS_CALLS_ORGANIC_SEARCH = gql`
query listTrackedLeadsCallsOrganicSearch(
  $customer_id: Int!
  $start_date: String!
  $end_date: String!
  $filter: String
) {
  listTrackedLeadsCallsOrganicSearch(
    customer_id: $customer_id
    start_date: $start_date
    end_date: $end_date
    filter: $filter
  ) {
    total_leads
    total_calls
    total_forms
  }
}
`;

export const LIST_TRACKED_LEADS_CALLS = gql`
  query listTrackedLeadsCalls(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
    $filter: String
    $keywords: Int
    $page: Int,
    $rows_per_page: Int
    $order_by: String
    $name: String
    $phone: String
    $city: String
    $state: String
    $device: String
    $source: String
    $rings: String
    $count: Int
  ) {
    listTrackedLeadsCalls(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      filter: $filter
      keywords: $keywords
      page: $page
      rows_per_page: $rows_per_page
      order_by: $order_by
      name: $name
      phone: $phone
      city: $city
      state: $state
      device: $device
      source: $source
      rings: $rings
      count: $count
    ) {
      id
      call_id
      formatted_customer_name
      formatted_customer_phone_number
      customer_city
      customer_state
      device_type
      formatted_duration
      start_time
      recording
      answered
      business_phone_number
      tracking_phone_number
      recording_player
      formatted_tracking_phone_number
      formatted_business_phone_number
      formatted_tracking_source
      source
      note
      value
      lead_status
      lead_type
      lead_quality
      tags
      keywords
      formatted_call_type
      landing_page_url
      last_requested_url
      first_call
      prior_calls
      direction
      voicemail
      recording_duration
      created_at
      formatted_value
      medium
      campaign
      utm_source
      utm_medium
      utm_campaign
      utm_term
      utm_content
      milestones
      products
      categories
      call_summary
    }
  }
`;

export const LIST_TRACKED_LEADS_CALLS_COUNT = gql`
  query listTrackedLeadsCallsCount(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
    $filter: String
    $keywords: Int
  ) {
    listTrackedLeadsCallsCount(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      filter: $filter
      keywords: $keywords
    ) 
  }
`;

export const LIST_TRACKED_LEADS_FORMS = gql`
  query listTrackedLeadsForms(
    $customer_id: Int!
    $start_date: String!
    $end_date: String!
    $keywords: Int
    $page: Int,
    $rows_per_page: Int
    $order_by: String
    $name: String
    $submissiontime: String
    $phone: String
    $source: String
    $count: Int
  ) {
    listTrackedLeadsForms(
      customer_id: $customer_id
      start_date: $start_date
      end_date: $end_date
      keywords: $keywords
      page: $page
      rows_per_page: $rows_per_page
      order_by: $order_by
      name: $name
      submissiontime: $submissiontime
      phone: $phone
      source: $source
      count: $count
    ) {
      id
      company_id
      form_data
      form_id
      submitted_at
      landing_page_url
      source
      note
      value
      lead_status
      lead_type
      lead_quality
      tags
      keywords
      medium
      campaign
      utm_medium
      utm_campaign
      utm_source
      note
      keywords
      tags
      formatted_customer_phone_number
      formatted_customer_name
      form_url
      products
      categories
    }
  }
`;

export const LIST_TRACKERS = gql`
  query listTrackers(
    $call_rail_id: String!
    $start_date: String!
    $end_date: String!
  ) {
    listTrackers(
      call_rail_id: $call_rail_id
      start_date: $start_date
      end_date: $end_date
    ) {
      id
      status
      tracking_numbers
      destination_number
      swap_targets
      name
    }
  }
`;

export const UPDATE_TRACKED_CALL = gql`
  mutation(
    $id: String
    $customer_id: Int
    $lead_status: Int
    $lead_type: Int
    $lead_quality: Int
    $value: Float
    $note: String
    $tags: [String]
    $products: String
    $categories: String
    $multiselect: String
  ) {
    updateTrackedCall(
      id: $id
      customer_id: $customer_id
      lead_status: $lead_status
      lead_type: $lead_type
      lead_quality: $lead_quality
      value: $value
      note: $note
      tags: $tags
      products: $products
      categories: $categories
      multiselect: $multiselect
    )
  }
`;

export const UPDATE_TRACKED_FORM = gql`
  mutation(
    $id: String
    $customer_id: Int
    $lead_status: Int
    $lead_type: Int
    $lead_quality: Int
    $value: Float
    $note: String
    $tags: [String]
    $products: String
    $categories: String
    $multiselect: String
  ) {
    updateTrackedForm(
      id: $id
      customer_id: $customer_id
      lead_status: $lead_status
      lead_type: $lead_type
      lead_quality: $lead_quality
      value: $value
      note: $note
      tags: $tags
      products: $products
      categories: $categories
      multiselect: $multiselect
    )
  }
`;
