import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, pick, omit, uniqBy, orderBy } from "lodash";
import moment from "moment";

import Assignment from "@mui/icons-material/Assignment";
import { makeStyles } from "@mui/styles";

import { dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import ReactTable from "components/CustomReactTable";
import UpdateDialog from "components/UpdateDialog";
import {
  LIST_EMAIL_ADDRESSES,
  UPDATE_EMAIL_ADDRESS,
  VERIFY_EMAIL_ADDRESS,
  REMOVE_EMAIL_ADDRESS
} from "queries/clicksend";
import { Context } from "../../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  link: {
    "&:hover": {
      fontWeight: "bold"
    }
  },
  removeLink: {
    color: `${dangerColor[0]} !important`
  }
};

const useStyles = makeStyles(styles);

const EmailAddresses = ({ formSubmitted, formSubmissionFailed }) => {
  const classes = useStyles();
  const [state] = useContext(Context);
  const [currentData, setCurrentData] = useState([]);
  const [currentEmailAddress, setCurrentEmailAddress] = useState(null);
  const [updatedEmailAddress, setUpdatedEmailAddress] = useState(null);
  const customer_id = state.customers?.selected?.id;
  const user_level = state?.auth?.tokenInfo?.user?.user_level;
  let { loading, data } = useQuery(LIST_EMAIL_ADDRESSES, {
    skip: !customer_id,
    variables: {
      customer_id
    }
  });
  data = data?.listEmailAddresses || [];

  useEffect(() => {
    if (loading) {
      setCurrentData([]);
    } else {
      setCurrentData(data);
    }
  }, [loading]);

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_EMAIL_ADDRESS,
    {
      onCompleted: data => {
        const updated = data.updateEmailAddress;
        if (updated?.id) {
          setCurrentData(
            orderBy(uniqBy([updated, ...currentData], "id"), ["id"], ["asc"])
          );
          formSubmitted();
        } else {
          formSubmissionFailed(
            updated?.error ? { text: updated?.error } : undefined
          );
        }
      }
    }
  );

  const [verifyEmailAddress, { loading: verifying }] = useMutation(
    VERIFY_EMAIL_ADDRESS,
    {
      onCompleted: data => {
        const email = data.verifyEmailAddress;
        if (email?.verified) {
          formSubmitted({
            text: "Verified Successfully"
          });
          setCurrentData(
            orderBy(uniqBy([email, ...currentData], "id"), ["id"], ["asc"])
          );
        }
        if (!email) {
          formSubmissionFailed({
            text: "Something went wrong!"
          });
        }
      }
    }
  );

  const [removeEmailAddress, { loading: removing }] = useMutation(
    REMOVE_EMAIL_ADDRESS,
    {
      onCompleted: data => {
        formSubmitted({
          text: "Successfully removed"
        });
        const removed = data.removeEmailAddress;
        setCurrentData(
          orderBy(currentData.filter(({ id }) => id !== removed.id))
        );
      }
    }
  );

  const handleOpen = row => {
    setUpdatedEmailAddress(row || {});
    setCurrentEmailAddress(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          emailAddress: {
            ...pick(updated, ["name", "email_address", "address", "address2", "nickname", "state", "city", "country", "zip"])
          }
        }
      });
    }
    setUpdatedEmailAddress(null);
    setCurrentEmailAddress(null);
  };

  const handleRemove = removing => {
    const confirmRemoval = window.confirm(
      `You can not undo this action. Do you really want to remove this address (${removing.email_address ||
        `id: ${removing.id}`})?`
    );
    if (!confirmRemoval) return;
    removeEmailAddress({
      variables: {
        id: removing.id
      }
    });
  };

  const columns = compact([
    {
      Header: "Email Address",
      accessor: "email_address",
    },
    {
      Header: "Full Name",
      accessor: "name"
    },
    {
      Header: "Nick Name",
      accessor: "nickname"
    },
    {
      Header: "Address",
      accessor: "address"
    },
    {
      Header: "Address2",
      accessor: "address2"
    },
    {
      Header: "State",
      accessor: "state"
    },
    {
      Header: "City",
      accessor: "city"
    },
    {
      Header: "Country",
      accessor: "country"
    },
    {
      Header: "Zip Code",
      accessor: "zip"
    },
    {
      Header: "Verified",
      accessor: "verified",
      Cell: ({ original }) => (
        original.verified ? "Yes" : "No"
      )
    },
    {
      Header: "Date Added",
      accessor: "date_added",
      Cell: ({ original }) => (
        original.date_added ? moment(original.date_added * 1000).format("YYYY-MM-DD") : "-"
      )
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ original }) => (
        <>
          {!original.verified && user_level === "master" && (
            <>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  verifyEmailAddress({
                    variables: {
                      id: original.id
                    }
                  });
                }}
              >
                Verify
              </a>
              <br />
            </>
          )}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              handleRemove(original);
            }}
          >
            Remove
          </a>
        </>
      )
    }
  ]);

  const inputs = [
    {
      label: "Email Address",
      field: "email_address"
    },
    {
      label: "Full Name",
      field: "name"
    },
    {
      label: "Nick Name",
      field: "nickname"
    },
    {
      label: "Address",
      field: "address"
    },
    {
      label: "Address2",
      field: "address2",
      optional: true
    },
    {
      label: "State",
      field: "state",
      optional: true
    },
    {
      label: "City",
      field: "city"
    },
    {
      label: "Country",
      field: "country"
    },
    {
      label: "Zip Code",
      field: "zip",
      optional: true
    },
  ];

  const isLoading = loading || updating || verifying || removing || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Sender Email Addresses
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
                disabled={isLoading}
              >
                Add Email Address
              </Button>
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={currentData?.length}
              data={currentData}
              filterable
              loading={isLoading}
              columns={columns}
              defaultPageSize={5}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight height-limited"
            />
            {updatedEmailAddress && (
              <UpdateDialog
                current={currentEmailAddress}
                updated={updatedEmailAddress}
                handleClose={handleClose}
                inputs={inputs}
                title="Email Address"
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(EmailAddresses);
