import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";

import GridContainer from "components/Grid/GridContainer";
import StatsCard from "components/StatsCard";
import { GET_BIRDEYE_CUSTOMERS_AND_LEADS } from "queries/birdeye";
import { GET_MARKETING_GOAL } from "queries/marketingGoals";
import { GET_TRACKED_LEADS_SUMMARY } from "queries/trackedLeads";
import { LIST_TRACKED_LEADS_CALLS_PAID_SEARCH } from "queries/trackedLeads";
import { LIST_TRACKED_LEADS_CALLS_ORGANIC_SEARCH } from "queries/trackedLeads";

import { Context } from "../../redux/store";
import { getPrevious } from "utils/format";
import { READ_ME } from "queries/users";

const TrackedLeadsSummary = ({ offline, filter }) => {
  const [state] = useContext(Context);
  const compareOption = state.ui?.compareOption;
  const call_rail_id = state.customers?.selected?.call_rail_id;
  const dateRangeString = state.customers?.dateRangeString;
  const customer_id = state.customers?.selected?.id;
  const { data: me } = useQuery(READ_ME);
  const { loading, data } = useQuery(GET_TRACKED_LEADS_SUMMARY, {
    skip: !call_rail_id || !dateRangeString,
    variables: {
      customer_id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      source: offline ? filter : ""
    }
  });

  const compareDateRange = getPrevious(dateRangeString, compareOption);

  const { loading: loadingCompare, data: dataCompare } = useQuery(
    GET_TRACKED_LEADS_SUMMARY,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate,
        source: offline ? filter : ""
      }
    }
  );

  const { loading: loadingMarketingGoal, data: marketingGoalData } = useQuery(
    GET_MARKETING_GOAL,
    {
      skip: !customer_id,
      variables: { customer_id }
    }
  );

  const marketingGoal = marketingGoalData?.getMarketingGoal;
  const milestones = [
    {
      value: marketingGoal?.monthly_leads_needed_breakeven || 0,
      icon: "fitness_center",
      color: "info",
      message: `Not met "Working on it.."`
    },
    {
      value: marketingGoal?.leads_needed_increase_budget || 0,
      icon: "sentiment_satisfied",
      color: "grey",
      message: "Achieved Leads needed to get an ROI"
    },
    {
      value: marketingGoal?.leads_needed_write_review || 0,
      icon: "sentiment_very_satisfied",
      color: "wbmMoney",
      message: "Achieved Leads needed to increase your budget"
    },
    {
      value: marketingGoal?.leads_needed_referral || 0,
      icon: "create",
      color: "orange",
      message: "Achieved Leads needed until you write a reivew",
      link: "/admin/write-review"
    },
    {
      icon: "people",
      color: "myTeam",
      message: "Achieved Leads needed until you refer your friends",
      link: "/admin/sweet-alert"
    }
  ];
  const { loading: loadingBirdeye, data: birdeye } = useQuery(
    GET_BIRDEYE_CUSTOMERS_AND_LEADS,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate
      }
    }
  );

  const { loading: loadingBirdeyedataCompare, data: birdeyedataCompare } = useQuery(
    GET_BIRDEYE_CUSTOMERS_AND_LEADS,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate
      }
    }
  );

  const { loading: loadingPaidSearch, data: paidsearch } = useQuery(
    LIST_TRACKED_LEADS_CALLS_PAID_SEARCH,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate,
        filter: offline ? filter : ""
      }
    }
  );

  const { loading: loadingPaidSearchCompare, data: paidsearchCompare } = useQuery(
    LIST_TRACKED_LEADS_CALLS_PAID_SEARCH,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate,
        filter: offline ? filter : ""
      }
    }
  );
  const { loading: loadingOrganicSearch, data: organicsearch } = useQuery(
    LIST_TRACKED_LEADS_CALLS_ORGANIC_SEARCH,
    {
      skip: !customer_id || !state.customers?.dateRangeString,
      variables: {
        customer_id,
        start_date: dateRangeString?.startDate,
        end_date: dateRangeString?.endDate,
        filter: offline ? filter : ""
      }
    }
  );
  const { loading: loadingOrganicSearchCompare, data: organicsearchCompare } = useQuery(
    LIST_TRACKED_LEADS_CALLS_ORGANIC_SEARCH,
    {
      skip: !customer_id || !compareDateRange,
      variables: {
        customer_id,
        start_date: compareDateRange?.startDate,
        end_date: compareDateRange?.endDate,
        filter: offline ? filter : ""
      }
    }
  );
  let i;
  for (i = 0; i < milestones.length; i++) {
    if (
      milestones[i].value !== undefined &&
      (data?.getTrackedLeadsSummary?.total_leads || 0) >= milestones[i].value
    ) {
      continue;
    } else {
      break;
    }
  }

  const loadingAll = loading || loadingMarketingGoal || loadingBirdeye || loadingBirdeyedataCompare || loadingPaidSearch || loadingOrganicSearch;

  return (
    <>
      <GridContainer>
        {!offline && (
          <StatsCard
            icon="dynamic_feed"
            loading={loadingAll}
            label="Total Conversions"
            value={
              (data?.getTrackedLeadsSummary?.total_leads || 0) +
              (birdeye?.getBirdeyeCustomersAndLeads?.total || 0)
            }
            render="integer"
            // milestones={milestones}
            footer={
              loadingCompare
                ? "..."
                : compareOption
                ? (dataCompare?.getTrackedLeadsSummary?.total_leads || 0) +
                  (birdeyedataCompare?.getBirdeyeCustomersAndLeads?.total || 0)

                : ""
            }
            previous
          />
        )}
        <StatsCard
          icon="phone"
          loading={loadingAll}
          label="Total Calls"
          value={data?.getTrackedLeadsSummary?.total_calls || 0}
          render="integer"
          footer={
            loadingCompare
              ? "..."
              : compareOption
              ? dataCompare?.getTrackedLeadsSummary?.total_calls || 0
              : ""
          }
          previous
        />
        {!offline && (
          <StatsCard
            icon="ballot"
            loading={loadingAll}
            label="Total Form Submissions"
            value={data?.getTrackedLeadsSummary?.total_forms || 0}
            render="integer"
            footer={
              loadingCompare
                ? "..."
                : compareOption
                ? dataCompare?.getTrackedLeadsSummary?.total_forms || 0
                : ""
            }
            previous
          />
        )}
        <StatsCard
          icon="chat"
          loading={loadingAll}
          label="Total Chat Conversions"
          value={birdeye?.getBirdeyeCustomersAndLeads?.total || 0}
          render="integer"
          footer={
            loadingBirdeyedataCompare
              ? "..."
              : compareOption
              ? birdeyedataCompare?.getBirdeyeCustomersAndLeads?.total || 0
              : ""
          }
          previous
        />
         { me?.readMe?.user_level === "master"  ?
        //|| me?.readMe?.user_level === "paid_ad_manager" ||
        //  me?.readMe?.user_level === "content_manager" || me?.readMe?.user_level === "campaign_consultant" ||
        //  me?.readMe?.user_level === "ad_consultant" ?
        <StatsCard
          icon="attach_money"
          loading={loadingAll}
          label="PaidSearch"
          value={
            (paidsearch?.listTrackedLeadsCallsPaidSearch?.total_leads || 0)
          }
          render="integer"
          footer={
            loadingPaidSearchCompare
              ? "..."
              : compareOption
              ? paidsearchCompare?.listTrackedLeadsCallsPaidSearch?.total_leads || 0
              : ""
          }
          previous
        />
        : ""}
        { me?.readMe?.user_level === "master"  ?
        //|| me?.readMe?.user_level === "paid_ad_manager" ||
        //  me?.readMe?.user_level === "content_manager" || me?.readMe?.user_level === "campaign_consultant" ||
        //  me?.readMe?.user_level === "ad_consultant" ?
        <StatsCard
          icon="search"
          loading={loadingAll}
          label="OrganicSearch"
          value={organicsearch?.listTrackedLeadsCallsOrganicSearch?.total_leads || 0}
          render="integer"
          footer={
            loadingOrganicSearchCompare
              ? "..."
              : compareOption
              ? organicsearchCompare?.listTrackedLeadsCallsOrganicSearch?.total_leads || 0
              : ""
          }
          previous
        />
      : "" }
      </GridContainer>
    </>
  );
};

TrackedLeadsSummary.propTypes = {
  offline: PropTypes.bool,
  filter: PropTypes.string
};

export default TrackedLeadsSummary;
