import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomSelect from "views/Dashboard/Accounts/CustomSelect";
import CustomInput from "components/CustomInput/CustomInput";

const ACCOUNT_TYPE_OPTIONS = [
  { value: "PERSONAL_CHECKING", label: "Personal Checking" },
  { value: "PERSONAL_SAVINGS", label: "Personal Savings" },
  { value: "BUSINESS_CHECKING", label: "Business Checking" },
  { value: "BUSINESS_SAVINGS", label: "Business Savings" }
];

const BankAccountForm = ({ info, onUpdate, readOnly, error }) => {
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomInput
          labelText="First & Last Name"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.name,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, name: value })
          }}
          error={error === "name"}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomInput
          labelText="Account Number"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.accountNumber,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, accountNumber: value })
          }}
          error={error === "accountNumber"}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomInput
          labelText="Phone (10 digits)"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.phone,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, phone: value })
          }}
          error={error === "phone"}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomSelect
          label="Account Type"
          name="account_type"
          value={info?.accountType}
          data={ACCOUNT_TYPE_OPTIONS}
          onChange={({ target: { value } }) => {
            onUpdate({ ...info, accountType: value });
          }}
          defaultValue=""
          error={error === "accountType"}
          readOnly={readOnly}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomInput
          labelText="Routing Number"
          disabled={readOnly}
          readOnly={readOnly}
          formControlProps={{
            fullWidth: true,
            disabled: readOnly,
            readOnly: readOnly
          }}
          inputProps={{
            value: info?.routingNumber,
            type: "string",
            onChange: ({ target: { value } }) =>
              onUpdate({ ...info, routingNumber: value })
          }}
          error={error === "routingNumber"}
        />
      </GridItem>
    </GridContainer>
  );
};

export default BankAccountForm;
