import {
  // title,
  defaultFont,
  // whiteColor,
  blackColor
} from "assets/jss/material-dashboard-pro-react.js";

const errorPageStyles = () => ({
  contentCenter: {
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: "3",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: blackColor,
    padding: "0 15px",
    width: "70%",
    maxWidth: "880px"
  },
  title: {
    ...defaultFont,
    fontSize: "7.5em",
    color: blackColor,
    letterSpacing: "10px",
    fontWeight: 600
  },
  subTitle: {
    fontSize: "1.5rem",
    marginTop: "0",
    marginBottom: "8px"
  },
  description: {
    fontSize: "1.125rem",
    marginTop: "0",
    marginBottom: "8px"
  }
});

export default errorPageStyles;
