import React, { useContext } from "react";
import { useQuery } from "@apollo/client";


import { makeStyles } from "@mui/styles";
// @mui/icons-material
import Icon from "@mui/material/Icon";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import CSVDownloader from "components/CSVDownloader";
import HelpTooltip from "components/HelpTooltip";
import ReactTable from "components/CustomReactTable";
import MyMapComponent from "./Googlemap";


import { LIST_PAIDSEARCH_CAMPAIGN_AD_SCHEDULES,
         LIST_PAIDSEARCH_CAMPAIGN_AD_LOCATION,
         LIST_PAIDSEARCH_CAMPAIGN_AD_PROXIMITY } from "queries/google";
import { Context } from "../../redux/store";

import { cardTitle } from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

const PaidSearchCampaignAdSchedules = () => {
  const [state] = useContext(Context);
  const [seenegative, setSeeNegative] = React.useState(0);
  const [campaignMap, setCampaignMap] = React.useState("All Campaigns");
  const dateRangeString = state.customers?.dateRangeString;
  let { loading, data } = useQuery(LIST_PAIDSEARCH_CAMPAIGN_AD_SCHEDULES, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      type: "AD_SCHEDULE",
      channel_type: "SEARCH"
    }
  });

  let { loading: Locationloading, data: Locationdata } = useQuery(LIST_PAIDSEARCH_CAMPAIGN_AD_LOCATION, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate,
      negative: seenegative
    }
  });

  let { loading: Proximityloading, data: Proximitydata } = useQuery(LIST_PAIDSEARCH_CAMPAIGN_AD_PROXIMITY, {
    skip: !state.customers?.selected?.id || !state.customers?.dateRangeString,
    variables: {
      customer_id: state.customers?.selected?.id,
      start_date: dateRangeString?.startDate,
      end_date: dateRangeString?.endDate
    }
  });  

  const classes = useStyles();
  data = data?.listPaidSearchCampaignAdSchedules || [];

  Locationdata = Locationdata?.listPaidSearchCampaignAdLocation || [];
  Proximitydata = Proximitydata?.listPaidSearchCampaignAdProximity || [];

  const uniqueCampaigns = [];
  Proximitydata.forEach((item) => {
    var findItem = uniqueCampaigns.find((x) => x.campaign_name === item.campaign_name);
    if (!findItem) uniqueCampaigns.push(item);
  });

  const handleSeeNegative = (event) => {
    setSeeNegative(parseInt(event));
  };
  
  let columns = ""

  columns = [
    {
      Header: "Campaign Name",
      accessor: "campaign_name"
    },
    {
      Header: "Status",
      accessor: "campaign_status"
    },
    {
      Header: "Day",
      accessor: "day_of_week"
    },
    {
      Header: "Start Time",
      accessor: "start_hour",
      Cell: ({ original }) => (
        `${original?.start_hour}:${original?.start_minute === "ZERO" ? "00" :
        original?.start_minute === "FIFTEEN" ? "15" :
        original?.start_minute === "THIRTY" ? "30" :
        original?.start_minute === "FORTY_FIVE" ? "45" : original?.start_minute
        }`
      )
    },
    {
      Header: "End Time",
      accessor: "end_hour",
      Cell: ({ original }) => (
        `${original?.end_hour}:${original?.end_minute === "ZERO" ? "00" :
        original?.end_minute === "FIFTEEN" ? "15" :
        original?.end_minute === "THIRTY" ? "30" :
        original?.end_minute === "FORTY_FIVE" ? "45" : original?.end_minute
        }`
      )
    },
    {
      Header: "Time Zone",
      accessor: "google_account_info.time_zone",
      Cell: ({ original }) => (
        `${original?.google_account_info.time_zone.replace("/", " ").replace("_", " ")}`
        )
    }
  ];

  let locationcolumns = ""

  locationcolumns = [
    {
      Header: "Campaign Name",
      accessor: "campaign_name"
    },
    {
      Header: "Status",
      accessor: "campaign_status"
    },
    {
      Header: "Target Level",
      accessor: "google_location_lookup.target_type",
      Cell: ({ original }) => ( 
        <>
        {original?.google_campaigns_targets?.target_type.replaceAll(",", ", ") ||
        original?.google_location_lookup?.target_type.replaceAll(",", ", ")}
        </>
        )
    },
    {
      Header: "Target Location",
      accessor: "google_location_lookup.canonical_name",
      Cell: ({ original }) => ( 
        <>
        {original?.google_campaigns_targets?.canonical_name.replaceAll(",", ", ") ||
        original?.google_location_lookup?.canonical_name.replaceAll(",", ", ")}
        </>
        )
    }
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
      <Card>
          <CardHeader color="google" icon>
            <CardIcon>
              <Icon>list</Icon>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              PaidSearch Campaign Ad Schedules
              <HelpTooltip>
                {`Ad scheduling allows us to display your ads during certain times to maximize the leads you are getting.`}
              </HelpTooltip>
              <CSVDownloader
                data={data}
                columns={columns}
                filename={`PaidSearch Campaign Ad Schedules (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={data?.length}
              data={data}
              filterable
              loading={loading}
              columns={columns}
              defaultPageSize={Math.min(10, data?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
             </CardBody>
        </Card>
        {Proximitydata.length > 0 && (
          <div>
          <h3><b>Radius Targeting</b></h3>
        <Card>
        <CardBody>
        <select
            style={{marginTop: "10px",marginBottom: "10px"}}
            onChange={event => { setCampaignMap(event.target.value) }}
            value={campaignMap}
          >
            <option value="All Campaigns">All Campaigns</option>
            {uniqueCampaigns?.map((CurrentCampaign) => {
                return (
                  <option value={CurrentCampaign?.campaign_name}>{CurrentCampaign?.campaign_name}</option>
                  )
            })}
        </select>        
        <MyMapComponent
          Proximitydata={Proximitydata}
          campaignMap={campaignMap}
        />        
        </CardBody>
        </Card>
        </div>
        )}
        
        { Locationdata.length !== 0 ?
        <div>
          <select
              style={{marginTop: "20px"}}
              id="see_negative"
              onChange={event => { handleSeeNegative(event.target.value) }}
              value={seenegative}
            >
              <option value="0">Targeted Locations</option>
              <option value="1">Negative Locations</option>
            </select>
          <Card>
            <CardHeader color="google" icon>
              <CardIcon>
                <Icon>list</Icon>
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                PaidSearch Location Targeting
                <CSVDownloader
                  data={Locationdata}
                  columns={locationcolumns}
                  filename={`PaidSearch Location Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
                />
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                key={Locationdata?.length}
                data={Locationdata}
                filterable
                loading={Locationloading}
                columns={locationcolumns}
                defaultPageSize={Math.min(10, Locationdata?.length || 2)}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </div>: "" }
        { Proximitydata.length !== 0 ?
        <Card>
          <CardHeader color="google" icon>
            <CardIcon>
              <Icon>list</Icon>
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              PaidSearch Radius Targeting
              <CSVDownloader
                data={Proximitydata}
                columns={locationcolumns}
                filename={`PaidSearch Radius Targeting (${state.customers?.selected?.name}) ${dateRangeString?.startDate} ~ ${dateRangeString?.endDate}.csv`}
              />
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={Proximitydata?.length}
              data={Proximitydata}
              filterable
              loading={Proximityloading}
              columns={locationcolumns}
              defaultPageSize={Math.min(10, Proximitydata?.length || 2)}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>: "" }
      </GridItem>
    </GridContainer>
  );
};

export default PaidSearchCampaignAdSchedules;
