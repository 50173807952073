import React, { useContext, useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { compact, omit, uniqBy, orderBy } from "lodash";

import Assignment from "@mui/icons-material/Assignment";
import { makeStyles } from "@mui/styles";

import { dangerColor } from "assets/jss/material-dashboard-pro-react.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";
import UpdateDialog from "components/UpdateDialog";
import ReactTable from "components/CustomReactTable";
import {
  LIST_RETURN_ADDRESSES,
  UPDATE_RETURN_ADDRESS,
  REMOVE_RETURN_ADDRESS
} from "queries/clicksend";
import { Context } from "../../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  link: {
    "&:hover": {
      fontWeight: "bold"
    }
  },
  removeLink: {
    color: `${dangerColor[0]} !important`
  }
};

const useStyles = makeStyles(styles);

const ReturnAddresses = ({ formSubmitted, formSubmissionFailed }) => {
  const classes = useStyles();
  const [state] = useContext(Context);
  const [currentData, setCurrentData] = useState([]);
  const [currentReturnAddress, setCurrentReturnAddress] = useState(null);
  const [updatedReturnAddress, setUpdatedReturnAddress] = useState(null);
  const customer_id = state.customers?.selected?.id;
  let { loading, data } = useQuery(LIST_RETURN_ADDRESSES, {
    skip: !customer_id,
    variables: {
      customer_id
    }
  });
  data = data?.listReturnAddresses || [];

  useEffect(() => {
    if (loading) {
      setCurrentData([]);
    } else {
      setCurrentData(data);
    }
  }, [loading]);

  const [submitUpdateRequest, { loading: updating }] = useMutation(
    UPDATE_RETURN_ADDRESS,
    {
      onCompleted: data => {
        const updated = data.updateReturnAddress;
        if (updated?.id) {
          setCurrentData(
            orderBy(uniqBy([updated, ...currentData], "id"), ["id"], ["asc"])
          );
          formSubmitted();
        } else {
          formSubmissionFailed(
            updated?.error ? { text: updated?.error } : undefined
          );
        }
      }
    }
  );

  const [removeReturnAddress, { loading: removing }] = useMutation(
    REMOVE_RETURN_ADDRESS,
    {
      onCompleted: data => {
        formSubmitted({
          text: "Successfully removed"
        });
        const removed = data.removeReturnAddress;
        setCurrentData(
          orderBy(currentData.filter(({ id }) => id !== removed.id))
        );
      }
    }
  );

  const handleOpen = row => {
    setUpdatedReturnAddress(row || {});
    setCurrentReturnAddress(row?.id ? row : null);
  };

  const handleClose = updated => () => {
    if (updated) {
      submitUpdateRequest({
        variables: {
          customer_id,
          returnAddress: {
            ...omit(updated, ["__typename"])
          }
        }
      });
    }
    setUpdatedReturnAddress(null);
    setCurrentReturnAddress(null);
  };

  const handleRemove = removing => {
    const confirmRemoval = window.confirm(
      `You can not undo this action. Do you really want to remove this address (${removing.address_name ||
        `id: ${removing.id}`})?`
    );
    if (!confirmRemoval) return;
    removeReturnAddress({
      variables: {
        customer_id,
        id: removing.id
      }
    });
  };

  const columns = compact([
    {
      Header: "Address Name",
      accessor: "address_name"
    },
    {
      Header: "Line 1",
      accessor: "address_line_1"
    },
    {
      Header: "Line 2",
      accessor: "address_line_2"
    },
    {
      Header: "City",
      accessor: "address_city"
    },
    {
      Header: "Postal Code",
      accessor: "address_postal_code"
    },
    {
      Header: "State",
      accessor: "address_state"
    },
    {
      Header: "Country",
      accessor: "address_country"
    },
    {
      Header: "Business Name",
      accessor: "business_name"
    },
    {
      Header: "First Name",
      accessor: "first_name"
    },
    {
      Header: "Last Name",
      accessor: "last_name"
    },
    {
      Header: "Email",
      accessor: "email_address"
    },
    {
      Header: "CC",
      accessor: "cc_email"
    },
    {
      Header: "BCC",
      accessor: "bcc_email"
    },
    {
      Header: "LinkedIn",
      accessor: "linkedin_url"
    },
    {
      Header: "Twitter",
      accessor: "twitter_url"
    },
    {
      Header: "Instagram",
      accessor: "instagram_url"
    },
    {
      Header: "Facebook",
      accessor: "facebook_url"
    },
    {
      Header: "Pinterest",
      accessor: "pinterest_url"
    },
    {
      Header: "Youtube",
      accessor: "youtube_url"
    },
    {
      Header: "Review Link",
      accessor: "review_url"
    },
    {
      Header: "Landing Page",
      accessor: "landing_page_url"
    },
    {
      Header: "Hydrate Phone",
      accessor: "hydrate_phone"
    },
    {
      Header: "Web Site",
      accessor: "website_url"
    },
    {
      Header: "",
      accessor: "update",
      width: 100,
      sortable: false,
      // eslint-disable-next-line react/display-name,react/prop-types
      Cell: ({ original }) => (
        <>
          <a
            href="#"
            rel="noopener noreferrer"
            className={classes.link}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleOpen(original);
            }}
          >
            Update
          </a>
          <br />
          <a
            href="#"
            rel="noopener noreferrer"
            className={[classes.link, classes.removeLink].join(" ")}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove(original);
            }}
          >
            Remove
          </a>
        </>
      ),
      noCSV: true
    }
  ]);

  const inputs = [
    {
      label: "Address Name",
      field: "address_name"
    },
    {
      label: "Line 1",
      field: "address_line_1"
    },
    {
      label: "Line 2",
      field: "address_line_2",
      optional: true
    },
    {
      label: "City",
      field: "address_city"
    },
    {
      label: "Postal Code",
      field: "address_postal_code"
    },
    {
      label: "State",
      field: "address_state",
      optional: true
    },
    {
      label: "Country",
      field: "address_country"
    },
    {
      label: "Business Name",
      field: "business_name"
    },
    {
      label: "First Name",
      field: "first_name"
    },
    {
      label: "Last Name",
      field: "last_name"
    },
    {
      label: "Email",
      field: "email_address",
      inputProps: { type: "email" }
    },
    {
      label: "CC",
      field: "cc_email",
      inputProps: { type: "email" },
      optional: true
    },
    {
      label: "BCC",
      field: "bcc_email",
      inputProps: { type: "email" },
      optional: true
    },
    {
      label: "LinkedIn",
      field: "linkedin_url",
      optional: true
    },
    {
      label: "Twitter",
      field: "twitter_url",
      optional: true
    },
    {
      label: "Instagram",
      field: "instagram_url",
      optional: true
    },
    {
      label: "Facebook",
      field: "facebook_url",
      optional: true
    },
    {
      label: "Pinterest",
      field: "pinterest_url",
      optional: true
    },
    {
      label: "Youtube",
      field: "youtube_url",
      optional: true
    },
    {
      label: "Review Link",
      field: "review_url",
      optional: true
    },
    {
      label: "Landing Page",
      field: "landing_page_url",
      optional: true
    },
    {
      label: "Hydrate Phone",
      field: "hydrate_phone",
      optional: true
    },
    {
      label: "Web Site",
      field: "website_url",
      optional: true
    }
  ];

  const isLoading = loading || updating || removing || !customer_id;

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="trackedKeywords" icon>
            <CardIcon>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Sender Return Addresses & Placeholders
              { currentData.length === 0 && (
              <Button
                onClick={() => handleOpen()}
                color="primary"
                style={{ marginLeft: 20 }}
                disabled={isLoading}
              >
                Add Return Address
              </Button>
              )}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              key={currentData?.length}
              data={currentData}
              filterable
              loading={isLoading}
              columns={columns}
              defaultPageSize={5}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight height-limited"
            />
            {updatedReturnAddress && (
              <UpdateDialog
                current={currentReturnAddress}
                updated={updatedReturnAddress}
                handleClose={handleClose}
                inputs={inputs}
                title="Return Address & Placeholders"
                maxWidth="md"
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withSubmissions(ReturnAddresses);
