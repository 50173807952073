import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { compact, noop, orderBy } from "lodash";
import queryString from "query-string";

import Select from "components/Select/Select";
import { LIST_CUSTOMERS } from "queries/customers";
import { SET_CUSTOMERS, SELECT_CUSTOMER } from "../../redux/constants";
import { Context } from "../../redux/store";

const UserSelect = ({ onSelect }) => {
  const [state, dispatch] = useContext(Context);
  const { loading, error, data } = useQuery(LIST_CUSTOMERS);
  const history = useLocation();
  const navigate = useNavigate()
  const { customer: customer_id } = queryString.parse(history.search);

  useEffect(() => {
    if (data?.listCustomers) {
      dispatch({
        type: SET_CUSTOMERS,
        payload: orderBy(compact(
          data.listCustomers?.filter(({ status }) => status !== 3)
        ), ["name"], ["asc"]),
      });
    }
  }, [data, dispatch]);
  const customers = state.customers?.data;
  useEffect(() => {
    if (!customers?.length) {
      return;
    }
    let value = null;
    if (customer_id) {
      value = Number(customer_id);
    } else if(customers?.filter((CurrentCustomer) => CurrentCustomer?.id === 38)?.length > 0)
    {
      value = 38;
    }
    else {
      value = Number(customers[0].id);
    }
    dispatch({
      type: SELECT_CUSTOMER,
      payload: value
    });
    onSelect(value);
  }, [customers, customer_id]);
  const customersMapped = customers?.map(({ id, name, status }) => ({
    key: id,
    label: name,
    fontStyle: status === 2 ? "italic" : ""
  }));
  return (
    <Select
      options={customersMapped}
      selected={state.customers?.selected?.id || ""}
      onSelect={value => {
        dispatch({ type: SELECT_CUSTOMER, payload: value });
        navigate(`${history.pathname}?customer=${value}`,{replace:true});
      }}
      loading={loading || !state.customers?.data}
      error={error ? `Error! ${error.message}` : ""}
      style={{ marginLeft: 30, marginBottom: 20 }}
      value=""
    />
  );
};

UserSelect.propTypes = {
  onSelect: PropTypes.func
};

UserSelect.defaultProps = {
  onSelect: noop
};

export default UserSelect;
