import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { pick } from "lodash";
import moment from "moment";


import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

// @mui/icons-material
import MailOutline from "@mui/icons-material/MailOutline";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CustomInput from "components/CustomInput/CustomInput";
import { CREATE_BUDGET } from "queries/budget";
import { Context } from "../../../redux/store";
import withSubmissions from "hocs/withSubmissions";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/modifyMarketingGoalsStyle";

const useStyles = makeStyles(styles);

export const adsType = [
  { label: "PaidSearch", value: 1 },
  { label: "Local Services Ads", value: 6 },
  { label: "OrganicSearch", value: 4 },
  { label: "Chat", value: 11 },
  { label: "BannerAds | GeoDemo®", value: 14 },
  { label: "BannerAds | GeoIntention®", value: 15 },
  { label: "BannerAds | ReEngage®", value: 16 },
  { label: "SocialAds | Boosted Posts", value: 22 },
  { label: "SocialAds | GeoDemo®", value: 20 },
  { label: "Social Ads | ReEngage®", value: 21 },
  { label: "OnlineVideo | GeoDemo®", value: 17 },
  { label: "OnlineVideo | GeoIntention®", value: 18 },
  { label: "OnlineVideo | ReEngage®", value: 19 },
  { label: "Out of Home", value: 10 },
  { label: "OTT", value: 12 },
  { label: "OfflineTracking", value: 9 },
  { label: "WebsiteDesign", value: 8 },
  { label: "Hosting", value: 5 },
  { label: "Hydrate", value: 23 },
  { label: "Linear CRM", value: 24 }
];

const CreateBudget = ({ formSubmitted, formSubmissionFailed }) => {
  const [state, dispatch] = useContext(Context);
  const [budget, setBudget] = useState({});
  const disabled =
    !state.customers?.selected?.id ||
    !budget?.product ||
    !budget?.monthly_budget ||
    !budget?.cycle_start_time;
  const [createBudget, { loading: creatingBudget }] = useMutation(
    CREATE_BUDGET,
    {
      variables: {
        customer_id: state.customers?.selected?.id,
        data: {
          ...pick(budget, ["monthly_budget", "product", "cycle_start_time"])
        }
      },
      onCompleted: ({ createBudget: success }) => {
        if (success) {
          formSubmitted({
            text: (
              <>
                {`Please update your Client's Marketing Goals`}
                <a
                  style={{ color: "white", fontWeight: "bold", marginLeft: 10 }}
                  href="/admin/update-marketing-goal"
                >
                  here
                </a>
              </>
            ),
            delay: 7000
          });
        } else {
          formSubmissionFailed({
            text: "Product exists already!"
          });
        }
      }
    }
  );
  const classes = useStyles();

  const updateFieldHandler = (key, convert) => e => {
    const value = e?.target ? e?.target.value : e;

    setBudget({
      ...budget,
      [key]: convert ? convert(value) : value
    });
  };

  return (
    <div className={classes.container} key={budget?.id}>
      <h2 className={classes.pageHeader}>Add New Product</h2>
      <GridContainer justify="center" loading={creatingBudget}>
        <GridItem xs={12} sm={6} md={5} lg={12}>
          <GridContainer>
            <Card>
              <CardHeader color="myTeam" icon>
                <CardIcon>
                  <MailOutline />
                </CardIcon>
              </CardHeader>
              <CardBody>
                <form onSubmit={e => e.preventDefault()}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <label>Product</label>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.chrisHelp
                      }}
                      value={budget?.product || "Choose Product"}
                      onChange={updateFieldHandler("product")}
                      inputProps={{
                        name: "product",
                        id: "product"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value="Choose Product"
                      >
                        Choose Product{" "}
                      </MenuItem>
                      {adsType.map(({ label, value }) => (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={value}
                          key={value}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                    style={{ marginTop: 20 }}
                  >
                    <TextField
                      id="date"
                      label="Start Date"
                      type="date"
                      value={budget?.cycle_start_time}
                      style={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={e => {
                        updateFieldHandler("cycle_start_time")(
                          moment(e.target.value).format("YYYY-MM-DD")
                        );
                      }}
                    />
                  </FormControl>
                  {budget && (
                    <CustomInput
                      labelText="Budget"
                      id="monthly_budget"
                      formControlProps={{
                        fullWidth: true,
                        required: true
                      }}
                      inputProps={{
                        type: "integer",
                        defaultValue: budget?.monthly_budget,
                        onChange: updateFieldHandler("monthly_budget", Number)
                      }}
                    />)}
                </form>
                <Button
                  color="orange"
                  type="submit"
                  onClick={() => {
                    createBudget();
                  }}
                  disabled={disabled}
                >
                  Submit
                </Button>
              </CardBody>
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withSubmissions(CreateBudget);
