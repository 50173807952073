import React from "react";


import { makeStyles } from "@mui/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import errorBackgroundImg from "assets/img/ISS_9875_05132.jpg";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";

const useStyles = makeStyles(styles);

var backgroundStyle = {
  backgroundImage: "url(" + { errorBackgroundImg } + ")"
};

export default function ErrorPage() {
  const classes = useStyles();
  return (
    <div style={backgroundStyle}>
      <div className={classes.contentCenter}>
        <GridContainer>
          <GridItem md={12} lg={12}>
            <h4 className={classes.title}>OH NO! You got 404&apos;d</h4>
            <h3 className={classes.subTitle}>
              Click the button below to go to the dashboard
            </h3>
            <br />
            <GridContainer justify="center">
              <Button size="lg" href="/admin/account-overview" color="orange">
                Go to Dashboard
              </Button>
            </GridContainer>{" "}
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
