import React from "react";


import { makeStyles } from "@mui/styles";
// import Icon from "@mui/material/Icon";

// @mui/icons-material
// import Weekend from "@mui/icons-material/Weekend";
// import Home from "@mui/icons-material/Home";
// import Business from "@mui/icons-material/Business";
// import AccountBalance from "@mui/icons-material/AccountBalance";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/LandingPageStyle.js";

const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={8}>
          <h1 className={classes.title}>
            <b>Welcome to Water Bear Marketing&apos;s Reporting Dashboard!</b>
          </h1>
          <h4 className={classes.description}>
            Below are some quick, useful bits of information to navigate our
            reporting dashboard.{" "}
          </h4>
          <br />
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={3} lg={12}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h2 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                <b>Accounts Overview Page</b>
              </h2>
              <p className={classes.cardCategory}>
                From the account overview section, you can see quick information
                about all of your tracked conversions and results by a publisher at a
                high level.{" "}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} lg={12}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h2 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                <b>Marketing Goals Page</b>
              </h2>
              <p className={classes.cardCategory}>
                Before signing a contract with us, our Advertising Consultant
                discussed your specific goals and objectives for your ad
                campaigns. The accuracy of this information is critical to the
                success of your campaign. We use this information to ensure we
                are getting you the best ROI we can. Please take a moment to
                review it and let us know if anything needs to be tweaked.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} lg={12}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h2 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                <b>Tracked Conversions Page</b>
              </h2>
              <p className={classes.cardCategory}>
                Get detailed information on our tracked conversions pages which
                include: caller ID information, call recording (when available)
                recording dates, and the ability to rate a phone call. We track
                information on form submissions which include: contact name,
                phone number, email address, and information in the message part
                of the form and the ability to rate the lead.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} lg={12}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h2 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                <b>Campaign Pages</b>
              </h2>
              <p className={classes.cardCategory}>
                Under Campaigns (Search, Display) You can get a more granular
                look at how we are advertising your business. You have access to
                campaign performance by the publisher, spend, budget, visits,
                impressions, click-through rates, landing pages, ad group
                breakdowns, keywords, and text or banner ads that are currently
                running.
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3} lg={12}>
          <Card pricing plain>
            <CardBody pricing plain>
              <h2 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                <b>My Team Pages</b>
              </h2>
              <p className={classes.cardCategory}>
                Get your Advertising Consultant’s contact information or the
                person who is covering for them while they are on vacation. You
                can also communicate directly with our Support team to make
                adjustments to your advertising campaigns. You can also write
                reviews.{" "}
              </p>
              <br />
            </CardBody>
          </Card>
          <GridContainer justify="center">
            <Button size="lg" href="/admin/account-overview" color="orange">
              Continue
            </Button>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
